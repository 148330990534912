import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { EditM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useDimensionTypes } from "@/hooks/useDimensionTypes";
import { useEntities } from "@/hooks/useEntities";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { dataManagementData } from "../data-management.data";

type CreateDimensionTypeFormValues = {
  title: string;
  description: string;
  is_entity: boolean;
  entity_id?: number;
};

const createDimensionTypeSchema = yup.object().shape({
  title: yup.string().required("Title required"),
  description: yup.string(),
  is_entity: yup.boolean(),
  entity_id: yup.number().when("is_entity", {
    is: true,
    then: yup.number().required("Entity required"),
  }),
});

export function DimensionTypesFormCreate() {
  const navigate = useNavigate();
  const validate = useValidationSchema(createDimensionTypeSchema);
  const { createDimensionType } = useDimensionTypes();
  const { allEntities } = useEntities();

  async function onSubmit(values: CreateDimensionTypeFormValues) {
    function onSuccess() {
      message.success("Dimension has been created");
      navigate(-1);
    }

    function onError(text: string) {
      message.error(text);
    }

    await createDimensionType.mutateAsync({
      dimensionType: values,
      onSuccess,
      onError,
    });
  }

  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate(dataManagementData.dimensionTypes.path)}
          title="Create new dimension"
        />
      }
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Form<CreateDimensionTypeFormValues>
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Card
                    background="white-shadow"
                    lively
                    title="Basic information"
                    withHeader
                    icon={<EditM />}
                    iconColor={"#AEAEAE"}
                    headerSideChildren={
                      <ModuleStyled.HeaderSideChildrenContainer>
                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                        <Button
                          onClick={handleSubmit}
                          type="primary"
                          disabled={createDimensionType.isLoading}
                          loading={createDimensionType.isLoading}
                        >
                          Save
                        </Button>
                      </ModuleStyled.HeaderSideChildrenContainer>
                    }
                  >
                    <ModuleStyled.InputWrapper>
                      <Field
                        name="title"
                        render={({ input, meta }) => (
                          <Input {...input} label="Title" errorText={meta.touched && meta.error} />
                        )}
                      />

                      <Field
                        name="description"
                        render={({ input, meta }) => (
                          <Input
                            {...input}
                            label="Description"
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      />
                      <ModuleStyled.SwitchWrapper>
                        <Field
                          name="is_entity"
                          render={({ input }) => <Switch {...input} label="Is Entity" />}
                        />
                      </ModuleStyled.SwitchWrapper>

                      {values.is_entity && (
                        <Field
                          name="entity_id"
                          render={({ input, meta }) => (
                            <Select
                              onChange={input.onChange}
                              label="Entity"
                              errorText={meta.touched && meta.error}
                            >
                              {allEntities.map((entity) => (
                                <Select.Option key={entity.id} value={entity.id}>
                                  {entity.name}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        />
                      )}
                    </ModuleStyled.InputWrapper>
                  </Card>
                </form>
              );
            }}
          />
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
