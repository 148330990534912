import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import DSFlag from "@synerise/ds-flag";
import Icon, { CompanyM, EditM } from "@synerise/ds-icon";
import InlineEdit from "@synerise/ds-inline-edit";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";

import { useEntities } from "@/hooks/useEntities";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";
import { formatHexSymbolsToUnicode } from "@/utils/formatHexSymbolsToUnicode";

import { dataManagementData } from "../data-management.data";
import * as Styled from "./entities.styles";

type EntityCreateParams = {
  id: string;
};

type EntityEditFormValues = {
  id: number;
  name: string;
  description: string;
  legal_name?: string;
  document_id?: string;
  fiscal_year?: string;
  isic_code?: number;
  industry_code?: number;
  country_code?: string;
  currency_code?: string;
};

const editEntitySchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  country_code: yup.string(),
  currency_code: yup.string(),
});

export const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-left: 8px;
  }
`;

export function EntityEdit() {
  const navigate = useNavigate();
  const validate = useValidationSchema(editEntitySchema);
  const { id } = useParams<EntityCreateParams>();
  const [basicInformationsCardOpened, setBasicInformationsCardOpened] = useState(true);
  const [classificationCardOpened, setClassificationCardOpened] = useState(false);
  const [financialInformationCardOpened, setFinancialInformationCardOpened] = useState(false);
  const { updateEntity, getEntityById, updateEntityLoading } = useEntities();
  const {
    countries,
    industriesISIC,
    industriesISICGroups,
    industriesGICS,
    industriesGICSGroups,
    currencies,
  } = useSupportInfos();

  if (!id) {
    navigate(dataManagementData.entities.path);
  }

  const entity = getEntityById(Number(id));

  const onSubmit = async (values: EntityEditFormValues) => {
    if (!id) return;

    const onError = (text: string) => message.error(text);

    const onSuccess = () => {
      message.success("Entity has been updated");
      navigate(dataManagementData.entities.path);
    };

    await updateEntity({
      id: Number(id),
      onSuccess,
      onError,
      payload: values,
    });
  };

  const initialFormValues = {
    id: Number(id),
    name: entity?.name,
    description: entity?.description,
    legal_name: entity?.legal_name,
    document_id: entity?.document_id,
    fiscal_year: entity?.fiscal_year,
    isic_code: entity?.isic_code,
    industry_code: entity?.industry_code,
    country_code: entity?.country_code ?? "",
    currency_code: entity?.currency_code ?? "",
  };

  return (
    <Form<EntityEditFormValues>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialFormValues}
    >
      {({ handleSubmit }) => (
        <Layout
          header={
            <PageHeader
              onGoBack={() => navigate(dataManagementData.entities.path)}
              title="Create new entity"
              rightSide={
                <Styled.PageHeaderRightSideContainer>
                  <Button
                    onClick={() => navigate(dataManagementData.entities.path)}
                    disabled={updateEntityLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    loading={updateEntityLoading}
                    disabled={updateEntityLoading}
                  >
                    Save changes
                  </Button>
                </Styled.PageHeaderRightSideContainer>
              }
            />
          }
        >
          <ModuleStyled.MaxWidthContent>
            <ModuleStyled.CardsWrapper>
              <ModuleStyled.AvatarContainer>
                <Icon component={<CompanyM />} size={50} color={"#006BF8"} />
                <ModuleStyled.AvatarInputsWrapper>
                  <Field name="name">
                    {({ input, meta }) => (
                      <InlineEdit
                        input={{
                          ...input,
                          placeholder: "Entity name",
                        }}
                        size="normal"
                        error={meta.touched && meta.error}
                        disabled={false}
                        hideIcon={false}
                      />
                    )}
                  </Field>
                  <Field name="description">
                    {({ input, meta }) => (
                      <InlineEdit
                        input={{
                          ...input,
                          placeholder: "Entity description",
                        }}
                        style={{ maxWidth: "100%" }}
                        size="small"
                        error={meta.touched && meta.error}
                        disabled={false}
                        hideIcon={false}
                      />
                    )}
                  </Field>
                </ModuleStyled.AvatarInputsWrapper>
              </ModuleStyled.AvatarContainer>

              <Card
                background="white-shadow"
                lively
                hideContent={!basicInformationsCardOpened}
                onHeaderClick={() => setBasicInformationsCardOpened(!basicInformationsCardOpened)}
                title="Basic Information"
                withHeader
                icon={<EditM />}
                iconColor="#AEAEAE"
              >
                <ModuleStyled.InputWrapper>
                  <Field name="legal_name">
                    {({ input }) => (
                      <Input
                        {...input}
                        label="Legal Name"
                        description="The entity legal name according to legal records"
                      />
                    )}
                  </Field>

                  <Field name="document_id">
                    {({ input }) => (
                      <Input
                        {...input}
                        label="Document ID"
                        description="The official document ID"
                      />
                    )}
                  </Field>

                  <Field
                    name="country_code"
                    render={({ input, meta }) => (
                      <Select
                        {...input}
                        label="Country"
                        description="Home country"
                        placeholder={"Country"}
                        errorText={meta.touched && meta.error}
                      >
                        {countries.map((country) => (
                          <Select.Option key={country.code} value={country.code}>
                            <Styled.FlagSelectOption>
                              <DSFlag country={country.alpha2} />
                              <p>{country.lang_en}</p>
                            </Styled.FlagSelectOption>
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  />

                  {/* TODO: load states from support infos */}
                  {/* TODO: address should be saved as a string? */}
                  {/* <Input label="Province" description="The entity state/province" /> */}
                  {/* <Input label="Address" description="The entity full address" /> */}
                </ModuleStyled.InputWrapper>
              </Card>

              <Card
                background="white-shadow"
                lively
                hideContent={!classificationCardOpened}
                onHeaderClick={() => setClassificationCardOpened(!classificationCardOpened)}
                title="Classification"
                withHeader
                icon={<EditM />}
                iconColor="#AEAEAE"
              >
                <ModuleStyled.InputWrapper>
                  <Field
                    name="isic_code"
                    render={({ input, meta }) => (
                      <Select
                        {...input}
                        label="Sector"
                        description="The entity sector according to ISIC standards"
                        placeholder="Industry ISIC Sector"
                        errorText={meta.touched && meta.error}
                      >
                        {industriesISICGroups.map((group) => (
                          <>
                            <Select.OptGroup key={group.code} label={group.name}>
                              {industriesISIC
                                .filter((industry) => {
                                  return industry.section_code === group.code;
                                })
                                .map((industry) => (
                                  <Select.Option key={industry.id} value={industry.id}>
                                    {industry.class_name}
                                  </Select.Option>
                                ))}
                            </Select.OptGroup>
                          </>
                        ))}
                      </Select>
                    )}
                  />

                  <Field
                    name="industry_code"
                    render={({ input, meta }) => (
                      <Select
                        {...input}
                        label="Industry"
                        description="The entity industry according to GICS standards"
                        placeholder="Industry"
                        errorText={meta.touched && meta.error}
                      >
                        {industriesGICSGroups.map((group) => (
                          <>
                            <Select.OptGroup key={group.code} label={group.name}>
                              {industriesGICS
                                .filter((industry) => {
                                  return industry.sector_code === group.code;
                                })
                                .map((industry) => (
                                  <Select.Option key={industry.id} value={industry.id}>
                                    {industry.subindustry_name}
                                  </Select.Option>
                                ))}
                            </Select.OptGroup>
                          </>
                        ))}
                      </Select>
                    )}
                  />
                </ModuleStyled.InputWrapper>
              </Card>

              <Card
                background="white-shadow"
                lively
                hideContent={!financialInformationCardOpened}
                onHeaderClick={() =>
                  setFinancialInformationCardOpened(!financialInformationCardOpened)
                }
                title="Financial Information"
                withHeader
                icon={<EditM />}
                iconColor="#AEAEAE"
              >
                <ModuleStyled.InputWrapper>
                  <Field
                    name="fiscal_year"
                    render={({ input }) => (
                      <Select
                        {...input}
                        label="Fiscal Year"
                        description="Month of the fiscal year start"
                      >
                        <Select.Option value={"jan"}>January</Select.Option>
                        <Select.Option value={"feb"}>February</Select.Option>
                        <Select.Option value={"mar"}>March</Select.Option>
                        <Select.Option value={"aprl"}>April</Select.Option>
                        <Select.Option value={"may"}>May</Select.Option>
                        <Select.Option value={"jun"}>June</Select.Option>
                        <Select.Option value={"jul"}>July</Select.Option>
                        <Select.Option value={"aug"}>August</Select.Option>
                        <Select.Option value={"sep"}>September</Select.Option>
                        <Select.Option value={"oct"}>October</Select.Option>
                        <Select.Option value={"nov"}>November</Select.Option>
                        <Select.Option value={"dec"}>December</Select.Option>
                      </Select>
                    )}
                  />

                  <Field
                    name="currency_code"
                    render={({ input, meta }) => (
                      <Select
                        {...input}
                        showSearch
                        label="Currency"
                        description="Currency"
                        placeholder={"Currency"}
                        errorText={meta.touched && meta.error}
                        options={currencies.map((currency) => ({
                          value: currency.code,
                          name: currency.name,
                          label: (
                            <p>
                              {currency.name} (
                              {currency &&
                                currency.symbol_hex &&
                                formatHexSymbolsToUnicode(currency.symbol_hex)}
                              )
                            </p>
                          ),
                        }))}
                        filterOption={(inputValue, option) => {
                          return (
                            option!.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                          );
                        }}
                      />
                    )}
                  />
                </ModuleStyled.InputWrapper>
              </Card>
            </ModuleStyled.CardsWrapper>
          </ModuleStyled.MaxWidthContent>
        </Layout>
      )}
    </Form>
  );
}
