import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { ChartOfAccountsTableData } from "@/modules/data-management/accounts/ChartOfAccounts/chart-of-accounts-table-columns";
import { ChartServices } from "@/modules/data-management/accounts/ChartOfAccounts/chart.services";
import {
  ChartCreatePayload,
  ChartResponse,
  ChartType,
  ChartUpdatePayload,
} from "@/modules/data-management/accounts/ChartOfAccounts/chart.types";
import { queryClient } from "@/utils/queryClient";

export const CHART_OF_ACCOUNTS_QUERY_KEY = "chart-of-accounts";

const chartOfAccountsTypes = {
  accounting: "Accounting",
  managerial: "Managerial",
  simplified: "Simplified",
};

function mapChartOfAccountsToTable(chartOfAccounts: ChartResponse): ChartOfAccountsTableData {
  return {
    id: chartOfAccounts.id,
    name: chartOfAccounts.title,
    type: chartOfAccountsTypes[chartOfAccounts.chart_type],
    currency: chartOfAccounts.currency_code ?? "",
  };
}

export function mapChartOfAccountsListToTable(
  chartOfAccounts: ChartResponse[] = []
): ChartOfAccountsTableData[] {
  return chartOfAccounts.map(mapChartOfAccountsToTable);
}

type UseChartOfAccountsReturn = {
  allChartOfAccounts: ChartResponse[];
  chartOfAccountsLoading: boolean;
  chartOfAccountsTypes: Record<ChartType, string>;
  createChartOfAccounts: (props: CreateChartOfAccountsProps) => Promise<void>;
  updateChartOfAccounts: (props: UpdateChartOfAccountsProps) => Promise<void>;
  updateChartOfAccountsLoading: boolean;
  removeChartOfAccounts: (props: RemoveChartOfAccountsProps) => Promise<void>;
};

async function handleCreateChartOfAccounts(payload: ChartCreatePayload) {
  return await ChartServices.create(payload);
}

async function handleUpdateChartOfAccounts(id: number, payload: ChartUpdatePayload) {
  return await ChartServices.update(id, payload);
}

async function handleRemoveChartOfAccounts(id: number) {
  return await ChartServices.delete(id);
}

type CreateChartOfAccountsProps = {
  payload: ChartCreatePayload;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

type UpdateChartOfAccountsProps = {
  id: number;
  payload: ChartUpdatePayload;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

type RemoveChartOfAccountsProps = {
  id: number;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

export function useChartOfAccounts(): UseChartOfAccountsReturn {
  const allChartOfAccountsFromQuery = useQuery(CHART_OF_ACCOUNTS_QUERY_KEY, () =>
    ChartServices.fetchAll()
  );

  const createChartOfAccountsMutation = useMutation(
    async ({ payload }: CreateChartOfAccountsProps) => await handleCreateChartOfAccounts(payload),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(CHART_OF_ACCOUNTS_QUERY_KEY);
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.detail) {
          onError(e.response.data.detail);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const updateChartOfAccountsMutation = useMutation(
    async ({ id, payload }: UpdateChartOfAccountsProps) =>
      await handleUpdateChartOfAccounts(id, payload),
    {
      onSuccess: (_, { id, onSuccess }) => {
        queryClient.invalidateQueries(CHART_OF_ACCOUNTS_QUERY_KEY);
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.detail) {
          onError(e.response.data.detail);
          return;
        }

        onError("Something went wrong");
      },
    }
  );

  const removeChartOfAccountsMutation = useMutation(
    ({ id }: RemoveChartOfAccountsProps) => handleRemoveChartOfAccounts(id),

    {
      onMutate: async ({ id, onSuccess }) => {
        await queryClient.cancelQueries(CHART_OF_ACCOUNTS_QUERY_KEY);

        const previousChartOfAccounts = queryClient.getQueryData<ChartResponse[]>(
          CHART_OF_ACCOUNTS_QUERY_KEY
        );

        if (!previousChartOfAccounts) return;

        const newChartOfAccountsList = previousChartOfAccounts.filter((chart) => chart.id !== id);

        queryClient.setQueryData(CHART_OF_ACCOUNTS_QUERY_KEY, newChartOfAccountsList);

        onSuccess();

        return {
          previousChartOfAccounts,
        };
      },
      onError: (e: any, { onError }, context: any) => {
        if (context.previousChartOfAccounts) {
          queryClient.setQueryData(CHART_OF_ACCOUNTS_QUERY_KEY, context.previousChartOfAccounts);
        } else {
          queryClient.invalidateQueries(CHART_OF_ACCOUNTS_QUERY_KEY);
        }

        if (e.response && e.response.data && e.response.data.detail) {
          onError(e.response.data.detail);
          return;
        }

        onError("Something went wrong");
      },
    }
  );

  const removeChartOfAccounts = async (props: RemoveChartOfAccountsProps) => {
    await removeChartOfAccountsMutation.mutateAsync(props);
  };

  const updateChartOfAccounts = async (props: UpdateChartOfAccountsProps) => {
    await updateChartOfAccountsMutation.mutateAsync(props);
  };

  const createChartOfAccounts = async (props: CreateChartOfAccountsProps) => {
    await createChartOfAccountsMutation.mutateAsync(props);
  };

  const allChartOfAccounts = useMemo(() => {
    return allChartOfAccountsFromQuery.data ?? [];
  }, [allChartOfAccountsFromQuery.data]);

  return {
    allChartOfAccounts,
    chartOfAccountsLoading: allChartOfAccountsFromQuery.isLoading,
    chartOfAccountsTypes,
    createChartOfAccounts,
    updateChartOfAccounts,
    updateChartOfAccountsLoading: updateChartOfAccountsMutation.isLoading,
    removeChartOfAccounts,
  };
}

type UseSingleChartOfAccountsReturn = {
  chartOfAccounts: ChartResponse | null;
  chartOfAccountsLoading: boolean;
};

const handleGetChartOfAccounts = async (id: number) => {
  return await ChartServices.fetchOne(id);
};

export function useSingleChartOfAccounts(
  id: number,
  onError: (text: string) => void
): UseSingleChartOfAccountsReturn {
  const chartOfAccountsFromQuery = useQuery(
    [CHART_OF_ACCOUNTS_QUERY_KEY, id, false],
    async () => await handleGetChartOfAccounts(id),
    {
      onError(error: any) {
        if (error.response && error.response.data && error.response.data.detail) {
          onError(error.response.data.detail);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const chartOfAccounts = chartOfAccountsFromQuery.data ?? null;

  return {
    chartOfAccounts,
    chartOfAccountsLoading: chartOfAccountsFromQuery.isLoading,
  };
}
