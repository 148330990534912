import axios from "axios";
import { Form, Field } from "react-final-form";
import { useMutation } from "react-query";
import * as Yup from "yup";

import Button from "@synerise/ds-button";
import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";
import Switch from "@synerise/ds-switch";

import { useValidationSchema } from "@/hooks/useValidationSchema";
import { queryClient } from "@/utils/queryClient";

import { KpiGroupService } from "../kpi-group.services";
import { KpiGroupResponse, KpiGroupUpdatePayload } from "../kpi-group.types";
import { KPIS_QUERY_KEY, KPI_GROUPS_QUERY_KEY } from "../library/hooks/kpi.config";

const formId = "kpi-group-edit-form";

type KPIGroupEditModalProps = {
  editingKpiGroup: KpiGroupResponse;
  visible: boolean;
  onClose: () => void;
};

type KPIGroupEditModalFormValues = {
  title: string;
  description?: string;
  is_shared: boolean;
};

const validateSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string(),
  is_shared: Yup.boolean(),
});

export function KPIGroupEditModal({ editingKpiGroup, visible, onClose }: KPIGroupEditModalProps) {
  const updateKpiGroupMutation = useMutation(
    [KPI_GROUPS_QUERY_KEY],
    (payload: KpiGroupUpdatePayload) => KpiGroupService.update(editingKpiGroup.id, payload),
    {
      onSuccess: () => {
        message.success("KPI Group updated");
        queryClient.invalidateQueries(KPIS_QUERY_KEY);
        queryClient.invalidateQueries(KPI_GROUPS_QUERY_KEY);
      },
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          message.error(error.response?.data.detail);
        } else {
          message.error("Something went wrong");
        }
      },
    }
  );

  const validate = useValidationSchema(validateSchema);

  const handleSubmit = async (values: KPIGroupEditModalFormValues) => {
    const payload: KpiGroupUpdatePayload = values;
    await updateKpiGroupMutation.mutateAsync(payload);
    onClose();
  };

  const initialValues: KPIGroupEditModalFormValues = {
    title: editingKpiGroup.title,
    description: editingKpiGroup.description,
    is_shared: editingKpiGroup.is_shared || false,
  };

  return (
    <Modal
      title="Update KPI Group"
      visible={visible}
      onCancel={() => {
        onClose();
      }}
      size="small"
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          form={formId}
          htmlType="submit"
          loading={updateKpiGroupMutation.isLoading}
        >
          Update
        </Button>,
      ]}
    >
      <Form<KPIGroupEditModalFormValues>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id={formId}>
            <Field name="title">
              {({ input, meta }) => (
                <Input {...input} label="Title" errorText={meta.touched && meta.error} />
              )}
            </Field>

            <Field name="description">
              {({ input, meta }) => (
                <Input {...input} label="Description" errorText={meta.touched && meta.error} />
              )}
            </Field>
            <Field name="is_shared">
              {({ input, meta }) => (
                <Switch
                  {...input}
                  checked={input.value}
                  label="Shared Group"
                  errorText={meta.touched && meta.error}
                />
              )}
            </Field>
          </form>
        )}
      </Form>
    </Modal>
  );
}
