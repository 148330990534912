import styled from "styled-components";

export const TenantsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 24px;
  gap: 24px;
`;

export const TenantCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  span.status {
    color: ${({ theme }) => theme.palette["red-400"]};
  }
`;

export const Card = styled.div<{ disabled: boolean; selected: boolean }>`
  border: 1px solid ${({ theme, selected }) => (selected ? theme.palette["blue-500"] : theme.palette["grey-300"])};
  padding: 16px;
  border-radius: 4px;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.1rem;
    color: ${({ disabled, theme }) =>
      disabled ? theme.palette["grey-600"] : theme.palette["grey-900"]};
  }

  p {
    margin: 0;
  }

  .check-button {
    position: absolute;
    top: 5px;
    right: 5px;
    size: 24px;
  }

  .checked {
    fill: ${({ theme }) => theme.palette["green-500"]};
  }

  .unchecked {
    fill: ${({ theme }) => theme.palette["grey-300"]};
  }

  & + & {
    margin-top: 16px;
  }
`;
