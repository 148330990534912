import { useMemo } from "react";
import { useQuery } from "react-query";

import { KpiGroupService } from "../../kpi-group.services";
import { KPI_GROUPS_QUERY_KEY } from "./useKpiGroups.config";
import { UseKpiGroupsApiReturn } from "./useKpiGroups.types";

export function useKpiGroupsApi(): UseKpiGroupsApiReturn {
  const { data, isLoading } = useQuery(
    [KPI_GROUPS_QUERY_KEY],
    async () => await KpiGroupService.fetchAll()
  );

  const groups = useMemo(() => {
    return data || [];
  }, [data]);

  return {
    kpiGroups: groups,
    isLoading,
  };
}
