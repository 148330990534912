import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";

import { DSProvider } from "@synerise/ds-core";

import { queryClient } from "@/utils/queryClient";

import App from "./App";
import { AppProvider, AppContext } from "./contexts/app/AppProvider";
import { AuthProvider } from "./contexts/auth/AuthProvider";
import messages from "./i18n/messages";
import { GlobalStyles } from "./styles/GlobalStyles";

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <AppContext.Consumer>
          {({ selectedLanguage }) => (
            <DSProvider locale={selectedLanguage} messages={messages}>
              <GlobalStyles />
              <AuthProvider>
                <App />
              </AuthProvider>
            </DSProvider>
          )}
        </AppContext.Consumer>
      </AppProvider>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
