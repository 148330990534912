import { ReportResponse } from "../reports.types";
import { ReportLayout } from "./report-builder.types";

export function mapReportResponseToReportLayout(report: ReportResponse): ReportLayout {
  return {
    id: String(report.id),
    name: report.name,
    description: report.description,
    tags: report.tags.map((tag) => tag.id),
    layout: report.components || [],
  };
}
