import styled from "styled-components";

export const Select = styled.select`
  width: 130px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 0.9rem;
  background-color: #fff;
  color: #555;
`;

export const PageHeaderActionsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const PageHeaderRightSideContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const DropdownWrapper = styled.div`
  // TODO: Fix styles
`;

export const EntityTypesContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
`;

export const FlagSelectOption = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  p {
    margin: 0;
  }
`;
