import Button from "@synerise/ds-button";
import DSDrawer from "@synerise/ds-drawer";
import Icon, { CloseM } from "@synerise/ds-icon";
import { Title } from "@synerise/ds-typography";

import { AdjustmentDrawerItem } from "../AdjustmentDrawerItem";
import * as Styled from "./styles";

import "./styles.css";

type AdjustmentsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function AdjustmentsDrawer({ isOpen, onClose }: AdjustmentsDrawerProps) {
  return (
    <DSDrawer
      visible={isOpen}
      width={400}
      title="Adjustments"
      maskStyle={{
        backgroundColor: "transparent",
      }}
      className="adjustents-drawer"
    >
      <DSDrawer.DrawerHeader>
        <Styled.DrawerHeaderWrapper>
          <Title level={4}>Adjustments</Title>
          <Button type="ghost" mode="single-icon" onClick={onClose}>
            <Icon component={<CloseM />} />
          </Button>
        </Styled.DrawerHeaderWrapper>
      </DSDrawer.DrawerHeader>
      <DSDrawer.DrawerBody>
        <DSDrawer.DrawerContent>
          <Styled.DrawerContentListWrapper>
            <AdjustmentDrawerItem
              label="Reverse R&amp;D Capitalization"
              description="Make collapsable accounts available for further analysis"
              checked={true}
            />
            <AdjustmentDrawerItem
              label="SOP Compensation Adjustment"
              description="Make sum totals like Gross Margin, Ebitda e Net Income more visible"
              checked={false}
            />
            <AdjustmentDrawerItem
              label="Extraordinary Expenses"
              description="Make sum totals like Gross Margin, Ebitda e Net Income more visible"
              checked={false}
            />
            <AdjustmentDrawerItem
              label="Other Adjustment Set"
              description="Make sum totals like Gross Margin, Ebitda e Net Income more visible"
              checked={false}
            />
            <AdjustmentDrawerItem
              label="New Adjustment Entry"
              description="Make sum totals like Gross Margin, Ebitda e Net Income more visible."
              checked={true}
            />
            <AdjustmentDrawerItem
              label="Single Adjustment"
              description="Make sum totals like Gross Margin, Ebitda e Net Income more visible."
              checked={false}
            />
          </Styled.DrawerContentListWrapper>
        </DSDrawer.DrawerContent>
      </DSDrawer.DrawerBody>
    </DSDrawer>
  );
}
