import { useNavigate } from "react-router-dom";

import Card from "@synerise/ds-card";
import Icon, { WthSnowM, UserS, CalendarS } from "@synerise/ds-icon";

import { BoardCardActions } from "../BoardCardActions";
import * as Styled from "./styles";

const periodicityHelper = {
  monthly: "Monthly",
};

type BoardCardProps = {
  id: number;
  title: string;
  membersCount: number;
  periodicity: keyof typeof periodicityHelper;
};

export function BoardCard({ id, title, membersCount, periodicity }: BoardCardProps) {
  const navigate = useNavigate();
  return (
    <Card style={{ width: 310, cursor: "pointer" }} withoutPadding lively>
      <Styled.Container onClick={() => navigate(`/governance/boards/${id}`)}>
        <Styled.Header>
          <span className="card-title card-line">
            <Icon component={<WthSnowM />} color="#A7A7A7" />
            {title}
          </span>
          <span className="card-title card-line">
            <BoardCardActions boardId={id} />
          </span>
        </Styled.Header>
        <span className="card-line">
          <Icon component={<UserS />} color="#A7A7A7" />
          {membersCount} Members
        </span>
        <span className="card-line">
          <Icon component={<CalendarS />} color="#A7A7A7" />
          {periodicityHelper[periodicity]} Meetings
        </span>
      </Styled.Container>
    </Card>
  );
}
