import Button from "@synerise/ds-button";
import ManageableList from "@synerise/ds-manageable-list";
import { ItemProps } from "@synerise/ds-manageable-list/dist/Item/Item.types";

import { DashboardUpdatePayload } from "../../dashboard.types";

type DashboardViewsDrawerListProps = {
  isDashboardsLoading: boolean;
  dashboards: ItemProps[];
  selectedDashboardId: number | null;
  removeDashboard: (id: number) => Promise<void>;
  selectDashboard: (id: number) => void;
  addDashboard: () => void;
  editDashboard: (id: number, payload: DashboardUpdatePayload) => Promise<void>;
};

export function DashboardViewsDrawerList({
  dashboards,
  isDashboardsLoading,
  selectedDashboardId,
  removeDashboard,
  selectDashboard,
  addDashboard,
  editDashboard,
}: DashboardViewsDrawerListProps) {
  return (
    <>
      <ManageableList
        maxToShowItems={5}
        onItemAdd={() => {}}
        onItemRemove={async ({ id }) => {
          removeDashboard(Number(id));
        }}
        selectedItemId={String(selectedDashboardId) || ""}
        onItemSelect={({ id }) => {
          selectDashboard(Number(id));
        }}
        onItemEdit={({ id, name }) => {
          editDashboard(Number(id), { name });
        }}
        items={dashboards}
        loading={isDashboardsLoading}
        type="filter"
        texts={{
          addItemLabel: "Add dashboard view",
          itemActionDelete: "Delete",
          deleteConfirmationDescription: "",
          activate: "Activate",
          activateItemTitle: "Activate dashboard view",
          cancel: "Cancel",
          deleteConfirmationTitle: "Delete dashboard view",
          deleteConfirmationYes: "Delete",
          deleteConfirmationNo: "Cancel",
        }}
      />
      <Button
        type="primary"
        style={{ margin: "0 15px", width: "100%", maxWidth: "320px" }}
        onClick={addDashboard}
      >
        Add Dashboard View
      </Button>
    </>
  );
}
