import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from "chart.js";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

import "chartjs-plugin-dragdata";
import { Periodicity } from "../kpi.types";
import { ValueType } from "./kpi-table.types";
import { formatDateToColumnHeader } from "./utils/formatDateToColumnHeader";

const ChartWrapper = styled.div`
  & > .chart-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      grid: { display: false },
    },
  },
  plugins: {
    dragData: {
      round: 1,
      showTooltip: true,
      onDragStart: () => console.log("started"),
      onDrag: () => console.log("started"),
      onDragEnd: () => console.log("started"),
    },
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

type KPIOverviewChartProps = {
  values: {
    id: number;
    value: number | null;
    datetime: string;
    kpi_id: number;
    type: ValueType;
    hasComments: boolean;
  }[];
  periodicity: Periodicity;
};

export function KPIOverviewChart({ values, periodicity }: KPIOverviewChartProps) {
  const actualData = useMemo(() => {
    return values.map((value) => value.value);
  }, [values]);

  const actualLabels = useMemo(() => {
    return values.map((value) => formatDateToColumnHeader(periodicity, new Date(value.datetime)));
  }, [values, periodicity]);

  const chartData = {
    labels: actualLabels,
    lineAtIndex: [12],
    datasets: [
      {
        label: "Actual",
        data: actualData,
        borderColor: "rgb(0, 81, 210)",
        backgroundColor: "rgb(0, 81, 210)",
        tension: 0.4,
        dragData: false,
      },
    ],
  };

  return (
    <ChartWrapper>
      <Line options={options} data={chartData} />
    </ChartWrapper>
  );
}
