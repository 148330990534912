import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRowSelect, useTable } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";

import { EmptyState } from "@/components/EmptyState";
import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import * as Styled from "@/components/Table/styles";
import { useDimensionTypes } from "@/hooks/useDimensionTypes";

import { consistencyOptions, flaggedOptions, LEDGERS_QUERY_KEY } from "../ledgers.config";
import * as LedgerStyles from "../ledgers.styles";
import { FetchOneParams, TrialBalanceServices } from "../trialbalance.services";
import { mapTrialBalanceDetailsToTable } from "../useLedger";
import { useTrialBalanceLines } from "../useTrialBalanceLines";
import { LedgerCommentsDrawer } from "./components/LedgerCommentDrawer";
import { LedgerJournalModal } from "./components/LedgerJournalModal";
import { tableColumns } from "./ledger-details.columns";
import { LedgerDetailsTableData } from "./ledger-details.types";
import LedgerDetailsInfos from "./LedgerDetailsInfosBar";
import { LedgerDetailsReviewCloseDialog } from "./LedgerDetailsReviewCloseDialog";
import { LedgerDetailsReviewOpenDialog } from "./LedgerDetailsReviewOpenDialog";

import "./ledger-details.styles.css";

async function showLedger(trialBalanceId: number, params: FetchOneParams) {
  return await TrialBalanceServices.fetchOne(trialBalanceId, params);
}

export function LedgerDetails() {
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [consistencyFilter, setConsistencyFilter] = useState<"all" | "true" | "false">("all");
  const navigate = useNavigate();
  const { trialbalanceid } = useParams<{
    trialbalanceid: string;
  }>();
  const [versionIdSelected, setVersionIdSelected] = useState<number>();
  const [tableData, setTableData] = useState<LedgerDetailsTableData[]>([]);
  const [dimensionsSelected, setDimensionsSelected] = useState<number[]>([]);
  const [flaggedFilter, setFlaggedFilter] = useState<"all" | "true" | "false">("all");
  const { allDimensionValues } = useDimensionTypes();
  const [isLedgerCommentLineDrawerOpen, setIsLedgerCommentLineDrawerOpen] = useState(false);
  const [isJournalModalOpen, setIsJournalModalOpen] = useState(false);
  const [lineIdCommentSelected, setLineIdCommentSelected] = useState<number | null>(null);
  const [versionNumberSelected, setVersionNumberSelected] = useState<string>();
  const [versionOptions, setVersionOptions] = useState<string[]>([]);

  function handleCloseJournalModal() {
    setIsJournalModalOpen(false);
  }

  function handleAccountClick() {
    setIsJournalModalOpen(true);
  }

  async function openLedgerLineCommentDrawer(lineId: number) {
    setIsLedgerCommentLineDrawerOpen(true);
    setLineIdCommentSelected(lineId);
  }

  function closeLedgerLineCommentDrawer() {
    setIsLedgerCommentLineDrawerOpen(false);
    setLineIdCommentSelected(null);
  }

  const { data: trialBalance } = useQuery(
    [LEDGERS_QUERY_KEY, trialbalanceid, dimensionsSelected, versionNumberSelected],
    async () => {
      return await showLedger(Number(trialbalanceid), {
        dimension_value_id: dimensionsSelected,
        version_number: versionNumberSelected,
      });
    },
    {
      onSuccess: (data) => {
        let versionOptions: string[] = [];
        data?.versions?.forEach((version) => {
          if (version.version_number) {
            versionOptions.push(version.version_number);
          }
        });
        setVersionOptions(versionOptions);
      },
    }
  );

  const trialBalanceVersion = useMemo(() => {
    return trialBalance && trialBalance.versions
      ? trialBalance.versions.find((version) => version.id === versionIdSelected)
      : null;
  }, [trialBalance, versionIdSelected]);

  const [reviewOpenDialogVisible, setReviewOpenDialogVisible] = useState(false);
  const [reviewCloseDialogVisible, setReviewCloseDialogVisible] = useState(false);

  useEffect(() => {
    if (trialBalance && trialBalance.versions && trialBalance.versions.length > 0) {
      setVersionIdSelected(trialBalance.versions[0].id);
    } else {
      setVersionIdSelected(undefined);
    }
  }, [trialBalance]);

  const {
    trialBalanceLines,
    isTrialBalanceLinesLoading,
    markAsReviewed,
    isMarkAsReviwedLoading,
    isOpenReviewLoading,
    openReview,
  } = useTrialBalanceLines(Number(trialbalanceid), versionIdSelected);

  useEffect(() => {
    setTableData(
      mapTrialBalanceDetailsToTable(trialBalanceLines, consistencyFilter, flaggedFilter)
    );
  }, [trialBalanceLines, consistencyFilter, flaggedFilter]);

  const handleFlagLine = async (lineId: number, isFlagged: boolean) => {
    setSkipPageReset(true);
    setTableData((old) =>
      old.map((line) => {
        if (line.id === lineId) {
          line.isFlagged = isFlagged;
        }
        return line;
      })
    );
  };

  const handleSubmitReviewed = async (reviewNote: string) => {
    const onSuccess = () => {
      message.success("Review submitted");
    };
    await markAsReviewed({
      onSuccess,
      reviewNote,
    });
  };

  const handleSubmitOpenNewReview = async (reviewNote: string) => {
    const onSuccess = () => {
      message.success("Review submitted");
      setReviewOpenDialogVisible(false);
    };
    const onError = () => {
      message.error("Error submitting review");
    };
    await openReview({
      reviewNote,
      flaggedLineIds: tableData.filter((line) => line.isFlagged).map((line) => line.id),
      onSuccess,
      onError,
    });
  };

  const handleSelectVersion = (versionSelected?: string) => {
    if (!versionSelected) {
      setVersionNumberSelected(undefined);
      return;
    }
    setVersionNumberSelected(versionSelected);
  };

  const columns = useMemo(() => {
    return tableColumns({
      handleFlagLine,
      openLineCommentsDrawer: openLedgerLineCommentDrawer,
      onAccountClick: handleAccountClick,
    });
  }, []);

  useEffect(() => {
    setSkipPageReset(false);
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
    {
      columns,
      data: tableData,
      autoResetPage: !skipPageReset,
    },
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate("/data/ledgers")}
          title="Trial Balance"
          rightSide={
            <>
              <Button onClick={() => setReviewOpenDialogVisible(true)}>Open review</Button>
              {!trialBalanceVersion?.is_reviewed && (
                <Button type="primary" onClick={() => setReviewCloseDialogVisible(true)}>
                  Mark as reviewed
                </Button>
              )}
            </>
          }
        />
      }
    >
      <LedgerDetailsInfos
        company={trialBalance?.entity?.name || "-"}
        isConsistent={trialBalanceVersion?.is_consistent || false}
        periodicity={trialBalance?.periodicity || "-"}
        isReviewed={trialBalanceVersion?.is_reviewed || false}
        dimensionsSelected={dimensionsSelected}
        setDimensionsSelected={setDimensionsSelected}
        dimensionsOptions={allDimensionValues}
        versions={versionOptions}
        setVersionSelected={handleSelectVersion}
        versionSelected={versionNumberSelected}
      />
      <Card
        title={
          <pre>
            <code>{JSON.stringify(state.filters, null, 2)}</code>
          </pre>
        }
        withHeader
        headerSideChildren={
          <LedgerStyles.TableOptionsContainer>
            <Select
              value={flaggedFilter}
              onChange={(value: any) => setFlaggedFilter(value)}
              style={{ minWidth: "150px" }}
            >
              {flaggedOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
            <Select
              value={consistencyFilter}
              onChange={(value: any) => setConsistencyFilter(value)}
              style={{ minWidth: "150px" }}
            >
              {consistencyOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </LedgerStyles.TableOptionsContainer>
        }
      >
        {isTrialBalanceLinesLoading ? (
          <Loader label="Loading ledger details. Just a second" labelPosition="bottom" />
        ) : trialBalanceLines.length < 1 ? (
          <>
            <EmptyState
              title="No ledger accounts found"
              text="Seems that your ledger is empty"
              showButton={false}
            />
          </>
        ) : (
          <Styled.Table {...getTableProps()}>
            <Styled.TableHead>
              {headerGroups.map((headerGroup) => (
                <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    if (column.align === "right") {
                      return (
                        <Styled.TableHeaderRight {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </Styled.TableHeaderRight>
                      );
                    }

                    if (column.align === "center") {
                      return (
                        <Styled.TableHeaderCenter {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </Styled.TableHeaderCenter>
                      );
                    }

                    return (
                      <Styled.TableHeader {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </Styled.TableHeader>
                    );
                  })}
                </Styled.TableHeaderRow>
              ))}
            </Styled.TableHead>
            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Styled.TableBodyRow {...row.getRowProps()} isSelected={row.isSelected}>
                    {row.cells.map((cell) => {
                      return (
                        <Styled.TableCell {...cell.getCellProps()} style={{ fontWeight: 400 }}>
                          {cell.render("Cell")}
                        </Styled.TableCell>
                      );
                    })}
                  </Styled.TableBodyRow>
                );
              })}
            </Styled.TableBody>
          </Styled.Table>
        )}
      </Card>
      {trialbalanceid && trialBalanceVersion && lineIdCommentSelected && (
        <LedgerCommentsDrawer
          isVisible={isLedgerCommentLineDrawerOpen}
          onClose={closeLedgerLineCommentDrawer}
          trialBalanceId={Number(trialbalanceid)}
          lineId={lineIdCommentSelected}
          versionId={trialBalanceVersion.id}
        />
      )}
      <LedgerDetailsReviewOpenDialog
        onSubmit={handleSubmitOpenNewReview}
        isVisible={reviewOpenDialogVisible}
        setDialogVisible={setReviewOpenDialogVisible}
        isSubmitLoading={isOpenReviewLoading}
      />
      <LedgerDetailsReviewCloseDialog
        isSubmitLoading={isMarkAsReviwedLoading}
        onSubmitReview={handleSubmitReviewed}
        isVisible={reviewCloseDialogVisible}
        setDialogVisible={setReviewCloseDialogVisible}
      />
      <LedgerJournalModal onClose={handleCloseJournalModal} isVisible={isJournalModalOpen} />
    </Layout>
  );
}
