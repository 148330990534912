import { message } from 'antd';

export function successNotification(text: string) {
  message.success(text);
}

export function errorNotification(text: string) {
  message.error(text);
}

export function warningNotification(text: string) {
  message.warning(text);
}
