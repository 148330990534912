import { ReportResponseShort } from "../reports.types";
import { ReportsListTableData } from "./ReportsLibraryTableColumns";
import { FilterOption } from "./ReportsList.types";

export class ReportListUtils {
  static mapReportsResponseToList(reportsResponse: ReportResponseShort[]): ReportsListTableData[] {
    return reportsResponse.map((report) => ({
      id: report.id,
      name: report.name,
      description: report.description ?? "",
      markedTags: report.tags.map((tag) => tag.title),
    }));
  }

  static filterPrivateReports(reports: ReportResponseShort[]): ReportResponseShort[] {
    return reports.filter((report) => report.is_private);
  }

  static filterPublicReports(reports: ReportResponseShort[]): ReportResponseShort[] {
    return reports.filter((report) => !report.is_private);
  }

  static filterRecentReports(reports: ReportResponseShort[]): ReportResponseShort[] {
    const reportsOrderedByDate = reports.sort((reportA, reportB) => {
      const reportADate = new Date(reportA.updated_at ? reportA.updated_at : reportA.created_at);
      const reportBDate = new Date(reportB.updated_at ? reportB.updated_at : reportB.created_at);

      return reportBDate.getTime() - reportADate.getTime();
    });
    return reportsOrderedByDate.slice(0, 5);
  }

  private static filterTypeHelper(
    reports: ReportResponseShort[],
    filterType: FilterOption
  ): ReportResponseShort[] {
    switch (filterType) {
      case "public":
        return ReportListUtils.filterPublicReports(reports);
      case "private":
        return ReportListUtils.filterPrivateReports(reports);
      case "recent":
        return ReportListUtils.filterRecentReports(reports);
      default:
        return reports;
    }
  }

  private static filterByTagsHelper(
    reports: ReportResponseShort[],
    tags: number[]
  ): ReportResponseShort[] {
    return reports.filter((report) => {
      const reportTags = report.tags.map((tag) => tag.id);
      return tags.every((tag) => reportTags.includes(tag));
    });
  }

  static filterReports(
    reports: ReportResponseShort[],
    filterType: FilterOption[],
    tags: number[]
  ): ReportResponseShort[] {
    const reportsByFilterType = filterType.reduce(
      (acc, filter) => this.filterTypeHelper(acc, filter),
      reports
    );
    const reportsByTags = this.filterByTagsHelper(reportsByFilterType, tags);
    return reportsByTags;
  }
}
