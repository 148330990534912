import { useCallback } from "react";
import { useMutation } from "react-query";

import message from "@synerise/ds-message";

import { GovernanceServices } from "../governance.services";
import { useRefreshPosts } from "./useRefreshPosts";

type UseRemovePostProps = {
  boardId: number;
  postId: number;
};

export const useRemovePost = ({ boardId, postId }: UseRemovePostProps) => {
  const refreshPosts = useRefreshPosts();

  const removePostMutation = useMutation(() => GovernanceServices.removePost(boardId, postId), {
    onSuccess: () => {
      message.success("Post removed");
      refreshPosts();
    },
    onError: (error: any) => {
      message.error(error.message);
    },
  });

  const removePost = useCallback(async () => {
    removePostMutation.mutate();
  }, []);

  return {
    removePost,
    isRemovePostLoading: removePostMutation.isLoading,
  };
};
