import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  padding: 12px;
`;

export const SectionTitle = styled.span`
  font-size: 14px;
`;
