import { AxiosResponse } from "axios";

import { UserPatch } from "@/modules/settings/users/users.types";

import { api } from "../../utils/http";
import { Tenant } from "../tenants/tenants.types";
import { User } from "./users.types";

class Users {
  async patchUser(userID: number, userPatch: UserPatch) {
    const response: AxiosResponse = await api.patch(`/users/${userID}`, userPatch);
    const user: User = response.data;
    return user;
  }

  async getTenantsFromUser(userID: number) {
    const response: AxiosResponse = await api.get(`/users/${userID}/tenants`);
    const tenants: Tenant[] = response.data;
    return tenants;
  }
}

const UsersService = new Users();
export default UsersService;
