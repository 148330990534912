import { Input } from "@synerise/ds-input";

import { ItemLayout } from "../../LayoutBuilder.types";

type EditItemDrawerRichTextContentProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>) => void;
};

export function EditItemDrawerRichTextContent({
  editingItem,
  updateItem,
}: EditItemDrawerRichTextContentProps) {
  function handleUpdateTitle(newTitle: string) {
    if (!editingItem) return;
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        title: newTitle,
      },
    };

    updateItem(newItem);
  }

  return (
    <>
      <Input
        label="Title"
        value={editingItem?.data.title}
        onChange={(event: any) => handleUpdateTitle(event.target.value)}
      />
    </>
  );
}
