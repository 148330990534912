import { useMutation, useQuery } from "react-query";

import { queryClient } from "@/utils/queryClient";

import { KPIService } from "../../kpi.services";
import { KpiResponse, KpiUpdatePayload } from "../../kpi.types";
import { KPIS_QUERY_KEY } from "./kpi.config";

type UseKpiReturn = {
  kpi: KpiResponse | null;
  kpiLoading: boolean;
  updateKpi: (
    kpiId: number,
    kpiPayload: KpiUpdatePayload,
    onSuccess: () => void,
    onError: (msg: string) => void
  ) => Promise<void>;
  updateKpiLoading: boolean;
  removeKpi: (id: number, onSuccess: () => void, onError: (msg: string) => void) => Promise<void>;
  removeKpiLoading: boolean;
};

type UpdateKpiProps = {
  kpiId: number;
  payload: KpiUpdatePayload;
};

export function useKpi(id?: number): UseKpiReturn {
  const { data, isLoading } = useQuery(
    [KPIS_QUERY_KEY, id],
    () => {
      if (!id) {
        return Promise.resolve(null);
      }

      return KPIService.fetchOne(id);
    },
    {
      enabled: !!id,
    }
  );

  const updateKpiMutation = useMutation(
    [KPIS_QUERY_KEY, id],
    ({ kpiId, payload }: UpdateKpiProps) => KPIService.update(kpiId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KPIS_QUERY_KEY);
      },
    }
  );

  const removeKpiMutation = useMutation(
    [KPIS_QUERY_KEY, id],
    (id: number) => KPIService.delete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KPIS_QUERY_KEY);
      },
    }
  );

  const updateKpi = async (
    kpiId: number,
    kpiPayload: KpiUpdatePayload,
    onSuccess: () => void,
    onError: (msg: string) => void
  ) => {
    await updateKpiMutation.mutateAsync(
      { kpiId, payload: kpiPayload },
      {
        onSuccess: () => {
          onSuccess();
        },
        onError: (error: any) => {
          onError(error.message);
        },
      }
    );
  };

  const removeKpi = async (id: number, onSuccess: () => void, onError: (msg: string) => void) => {
    await removeKpiMutation.mutateAsync(id, {
      onSuccess,
      onError: (error: any) => {
        onError(error.message);
      },
    });
  };

  return {
    kpi: data ?? null,
    kpiLoading: isLoading,
    updateKpi,
    updateKpiLoading: updateKpiMutation.isLoading,
    removeKpi,
    removeKpiLoading: removeKpiMutation.isLoading,
  };
}
