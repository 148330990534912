import { format } from "date-fns";

import { UserAvatar } from "@synerise/ds-avatar";
import Button from "@synerise/ds-button";
import Icon, { TrashS } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";
import Typography from "@synerise/ds-typography";

import * as Styled from "./styles";

type CommentProps = {
  avatarSrc?: string;
  userName: string;
  commentText: string;
  date: Date;
  canRemove: boolean;
  onRemove: () => void;
};

export function Comment({
  avatarSrc,
  userName,
  commentText,
  date,
  canRemove,
  onRemove,
}: CommentProps) {
  const formattedDate = format(date, "d/MMM/yy 'at' h'h'mm");

  return (
    <Styled.CommentContainer>
      <UserAvatar size="small" src={avatarSrc} />
      <Styled.CommentMainContent>
        <Styled.CommentHeader>
          <Typography.Text strong>{userName}</Typography.Text>
          {canRemove && (
            <Popconfirm
              title="Remove comment"
              description="Are you sure you want to remove this comment?"
              onConfirm={onRemove}
              cancelText="Cancel"
              okText="Remove"
              okButtonProps={{
                danger: true,
              }}
            >
              <Button
                mode="single-icon"
                type="ghost"
                size="small"
                icon={<Icon component={<TrashS />} />}
              />
            </Popconfirm>
          )}
        </Styled.CommentHeader>
        <Typography.Text className="comment-text">{commentText}</Typography.Text>
        <Typography.Text className="comment-date">{formattedDate}</Typography.Text>
      </Styled.CommentMainContent>
    </Styled.CommentContainer>
  );
}
