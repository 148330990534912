import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { LockM, UnlockM, UserPrivateAccessM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import { Title } from "@synerise/ds-typography";

import { useAccountUser } from "@/hooks/useAccountUser";
import { useAuth } from "@/hooks/useAuth";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";
import * as Styled from "./account.styles";




type ChangePasswordFormValues = {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
};

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const editUserPasswordSchema = yup.object().shape({
  current_password: yup.string().required("Current password is required"),
  new_password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(40, "Password must be less than 40 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      "Password must contain at least one special character"
    ),
  new_password_confirmation: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Password confirmation don't match")
    .required("New password confirmation is required"),
});

export function AccountSecurityPolicy() {
  const navigate = useNavigate();
  const [inEditMode, setInEditMode] = useState(false);
  const { user } = useAuth();
  const validate = useValidationSchema(editUserPasswordSchema);
  const { changePassword, changePasswordLoading } = useAccountUser();

  async function onSubmit(values: ChangePasswordFormValues) {
    if (!user) return;

    function onSuccess() {
      message.success("Password has been changed successfully");
      setInEditMode(false);
    }

    function onError(errorMessage: string) {
      message.error(errorMessage);
    }

    await changePassword({
      id: user.id,
      payload: values,
      onSuccess,
      onError,
    });
  }

  const initialFormValues = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  return (
    <Layout
      left={LeftLayoutContent}
      header={<PageHeader onGoBack={() => navigate("/settings/home")} title="Account Security" />}
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Title level={3}>Security Policy</Title>
          <Form<ChangePasswordFormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialFormValues}
          >
            {({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Card
                  background="white-shadow"
                  title="Change Password"
                  withHeader
                  icon={<LockM />}
                  iconColor={"#AEAEAE"}
                  headerSideChildren={
                    !inEditMode ? (
                      <Styled.SideButtonsContainer>
                        <Button onClick={() => setInEditMode(true)} type="secondary">
                          Edit
                        </Button>
                      </Styled.SideButtonsContainer>
                    ) : (
                      <Styled.SideButtonsContainer>
                        <Button
                          onClick={() => {
                            form.restart();
                            setInEditMode(false);
                          }}
                          type="secondary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          type="primary"
                          loading={changePasswordLoading}
                          disabled={changePasswordLoading}
                        >
                          Save
                        </Button>
                      </Styled.SideButtonsContainer>
                    )
                  }
                >
                  <ModuleStyled.InputWrapper>
                    <Field name="current_password">
                      {({ input, meta }) => (
                        <Input
                          {...input}
                          label="Current Password"
                          type="password"
                          placeholder="Type your current password"
                          disabled={!inEditMode}
                          errorText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                    <Field name="new_password">
                      {({ input, meta }) => (
                        <Input
                          {...input}
                          label="New Password"
                          type="password"
                          placeholder="Type your new password"
                          disabled={!inEditMode}
                          errorText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                    <Styled.PasswordRequirementsContainer>
                      <li>At least 8 characters long</li>
                      <li>Maximum 40 characters long</li>
                      <li>At least one uppercase letter</li>
                      <li>At least one lowercase letter</li>
                      <li>At least one lowercase letter</li>
                    </Styled.PasswordRequirementsContainer>

                    <Field name="new_password_confirmation">
                      {({ input, meta }) => (
                        <Input
                          {...input}
                          label="New Password Confirmation"
                          placeholder="Confirm your new password"
                          type="password"
                          disabled={!inEditMode}
                          errorText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </ModuleStyled.InputWrapper>
                </Card>
              </form>
            )}
          </Form>

          <Card
            background="white-shadow"
            title="Two Factor Authentication"
            description="If enabled, we will send you a token by email every time you log in. This greatly increases security and is recommended at all times."
            withHeader
            icon={<UnlockM />}
            iconColor={"#AEAEAE"}
            headerSideChildren={
              <Button onClick={function noRefCheck() {}} type="secondary" disabled>
                Enable
              </Button>
            }
          ></Card>
          <Card
            background="white-shadow"
            title="Remember Me"
            description="This will remember your current computer for 60 days (as identified by your IP address)"
            withHeader
            icon={<UserPrivateAccessM />}
            iconColor={"#AEAEAE"}
            headerSideChildren={
              <Button onClick={function noRefCheck() {}} type="secondary" disabled>
                Enable
              </Button>
            }
          ></Card>
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
