import * as Styled from "./periodicity-cell.styles";

export type Periodicity = "daily" | "weekly" | "monthly" | "quarterly" | "yearly";

type PeriodicityCellProps = {
  periodicity: Periodicity;
};

export function PeriodicityCell({ periodicity }: PeriodicityCellProps) {
  return (
    <Styled.Container>
      <Styled.PeriodicityIcon active={periodicity === "monthly"}>m</Styled.PeriodicityIcon>
      <Styled.PeriodicityIcon active={periodicity === "quarterly"}>q</Styled.PeriodicityIcon>
      <Styled.PeriodicityIcon active={periodicity === "yearly"}>y</Styled.PeriodicityIcon>
    </Styled.Container>
  );
}
