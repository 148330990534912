import styled from "styled-components";

export const ComponentsListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  padding: 12px;
`;

export const DrawerItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: left;
  box-shadow: 0px 4px 10px rgba(151, 151, 151, 0.25);
  border: 1px solid #ebebeb;
  text-align: left;
  cursor: pointer;
  padding: 12px;

  & > span {
    margin-top: 7px;
    color: #7c7c7c;
  }

  .right-content {
    display: flex;
    flex-direction: column;
  }
`;
