import { useEffect } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";

import { useValidationSchema } from "@/hooks/useValidationSchema";

import { useAuth } from "../../../hooks/useAuth";
import * as Styled from "../login.styles";

type LoginFormValues = {
  email: string;
  password: string;
};

export function Login() {
  const { formatMessage } = useIntl();

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .required(formatMessage({ id: "login.validation.emailRequired" }))
      .email(formatMessage({ id: "login.validation.emailInvalid" })),
    password: yup.string().required(formatMessage({ id: "login.validation.passwordRequired" })),
  });

  const { authenticateUser, isAuthenticated, isAuthenticationLoading } = useAuth();
  const validate = useValidationSchema(loginSchema);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate("/tenants");
  }, [isAuthenticated]);

  function onSubmit(values: LoginFormValues) {
    const formData = new FormData();
    formData.append("username", values.email);
    formData.append("password", values.password);
    try {
      authenticateUser(formData);
    } catch (error: any) {
      message.error(error.message);
    }
  }

  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        return (
          <Styled.Form onSubmit={handleSubmit}>
            <Styled.CardContainer>
              <Card title="Login" withHeader>
                <Field
                  name="email"
                  render={({ input, meta }) => (
                    <Input
                      {...input}
                      label={formatMessage({ id: "login.email" })}
                      placeholder={formatMessage({ id: "login.email" })}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                />

                <Field
                  name="password"
                  type="password"
                  render={({ input, meta }) => (
                    <Input
                      {...input}
                      label={formatMessage({ id: "login.password" })}
                      placeholder={formatMessage({ id: "login.password" })}
                      errorText={meta.touched && meta.error}
                    />
                  )}
                />

                <Styled.FooterButtonsContainer>
                  <Button onClick={() => navigate("/forgot-password")}>
                    {formatMessage({ id: "login.forgotPassword" })}
                  </Button>
                  <Button type="primary" htmlType="submit" loading={isAuthenticationLoading}>
                    {formatMessage({ id: "login.signIn" })}
                  </Button>
                </Styled.FooterButtonsContainer>
              </Card>
            </Styled.CardContainer>
          </Styled.Form>
        );
      }}
    />
  );
}
