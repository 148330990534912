import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #a7a7a7;

  & .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.3rem;

    & .title {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  & .submit-button {
    align-self: flex-end;
  }
`;

export const PostInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.3rem;

  & .post-text-length {
    align-self: flex-end;
  }
`;
