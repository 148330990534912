import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { UserPrivateAccessM } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";
import { Title } from "@synerise/ds-typography";

import { TenantPatch } from "@/core/tenants/tenants.types";
import { useAuth } from "@/hooks/useAuth";
import { useTenant } from "@/hooks/useTenant";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import * as Styled from "../settings.styles";
import { LeftLayoutContent } from "../SettingsLeftLayoutContent";


export type OrganizationPreferencesFormValues = {
  date_format?: string;
  number_format?: string;
};

const UserPreferencesObject = yup.object().shape({
  date_format: yup.string(),
  number_format: yup.string(),
});

export function OrganizationPreferences() {
  const navigate = useNavigate();
  const { user, currentTenant } = useAuth();
  const validate = useValidationSchema(UserPreferencesObject);
  const [inEditMode, setInEditMode] = useState(false);

  const onGetTenantError = (errorMessage: string) => {
    message.error(errorMessage);
    navigate("/settings/home");
  };

  const { updateTenant, tenant } = useTenant(onGetTenantError, currentTenant?.id);

  async function onSubmit(values: OrganizationPreferencesFormValues) {
    const onSuccess = () => {
      message.success("Organization settings updated successfully");
      setInEditMode(false);
    };

    const onError = (errorMessage: string) => {
      message.error(errorMessage);
    };

    const payload: TenantPatch = {
      preferences: {
        date_format: values.date_format,
        number_format: values.number_format,
      },
    };

    await updateTenant({
      payload,
      onSuccess,
      onError,
    });
  }

  const formInitialValues = {
    date_format: tenant?.preferences?.date_format,
    number_format: tenant?.preferences?.number_format,
  };

  return (
    <Layout
      left={LeftLayoutContent}
      header={<PageHeader onGoBack={() => navigate("/settings/home")} title="Preferences" />}
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Title level={3}>Global Preferences</Title>
          <Form<OrganizationPreferencesFormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={formInitialValues}
          >
            {({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Card
                  background="white-shadow"
                  lively
                  title="Select yout organization's preferences"
                  withHeader
                  icon={<UserPrivateAccessM />}
                  iconColor={"#AEAEAE"}
                  headerSideChildren={
                    !inEditMode ? (
                      <Styled.SideButtonsContainer>
                        <Button onClick={() => setInEditMode(true)} type="secondary">
                          Edit
                        </Button>
                      </Styled.SideButtonsContainer>
                    ) : (
                      <Styled.SideButtonsContainer>
                        <Button
                          onClick={() => {
                            form.restart();
                            form.reset();
                            setInEditMode(false);
                          }}
                          type="secondary"
                        >
                          Cancel
                        </Button>
                        <Button onClick={handleSubmit} type="primary">
                          Save
                        </Button>
                      </Styled.SideButtonsContainer>
                    )
                  }
                >
                  <ModuleStyled.InputWrapper>
                    <Field name="date_format" initialValue={user?.preferences?.date_format}>
                      {({ input }) => (
                        <Select
                          label="Date Format"
                          disabled={!inEditMode}
                          description="The way dates will be shown across the application"
                          {...input}
                        >
                          <Select.Option value="DD/MM/YYYY">DD/MM/YYYY</Select.Option>
                          <Select.Option value="MM/DD/YYYY">MM/DD/YYYY</Select.Option>
                          <Select.Option value="YYYY/MM/DD">YYYY/MM/DD</Select.Option>
                        </Select>
                      )}
                    </Field>
                    <Field name="number_format" initialValue={user?.preferences?.number_format}>
                      {({ input }) => (
                        <Select
                          label="Number Format"
                          disabled={!inEditMode}
                          description="The way numbers will be shown across the application"
                          {...input}
                        >
                          <Select.Option value="1.000,00">1.000,00</Select.Option>
                          <Select.Option value="1,000.00">1,000.00</Select.Option>
                        </Select>
                      )}
                    </Field>
                  </ModuleStyled.InputWrapper>
                </Card>
              </form>
            )}
          </Form>
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
