import { AxiosResponse } from "axios";

import { UserData } from "@/contexts/auth/auth.types";

import { api } from "../../utils/http";
import { Tenant } from "../tenants/tenants.types";
import { RegisterUserPayload, UserPost } from "./login.types";

type ForgotPasswordResponse = {
  status: boolean;
  detail: string;
};

type ConfirmResetResponse = {
  status: boolean;
  msg: string;
};
class LoginService {
  async getTenant(tenantID: number) {
    const response: AxiosResponse = await api.get(`/tenants/${tenantID}`);
    const tenant: Tenant = response.data;
    return tenant;
  }

  async postUser(userData: UserPost) {
    const response: AxiosResponse = await api.post("/users", userData);
    const user: UserData = response.data;
    return user;
  }

  async confirmReset(token: string) {
    const response: AxiosResponse = await api.get(`/users/password/reset?token=${token}`);
    const data: ConfirmResetResponse = response.data;
    return data;
  }

  async forgotPassword(email: string) {
    const response: AxiosResponse = await api.post(`/users/password/reset?email=${email}`);
    const data: ForgotPasswordResponse = response.data;
    return data;
  }

  async resetPassword(token: string, password: string) {
    const response: AxiosResponse = await api.post(`/users/password?token=${token}`, password, {
      params: {
        token,
      },
    });
    const user: UserData = response.data;
    return user;
  }

  async registerUser(payload: RegisterUserPayload, token: string) {
    const response: AxiosResponse = await api.patch("/users/activate/register", payload, {
      params: {
        token,
      },
    });
    const user: UserData = response.data;
    return user;
  }

  async activateEmail(email: string, token: string) {
    const response: AxiosResponse = await api.post(
      `/users/activate/email?email=${email}&token=${token}`
    );
    const data: ApiStatusResponse = response.data;
    return data;
  }
}

const loginService = new LoginService();
export default loginService;
