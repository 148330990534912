import { Column, Row } from "react-table";

import Button from "@synerise/ds-button";
import Icon, {
  ChildRowLeftDownM,
  ArrowDownCircleM,
  ArrowRightCircleM,
  FileDownloadM,
  EditM,
  TrashM,
} from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { StatusBadge } from "@/components/StatusBadge";

import { AccountsTableData } from "./accounts-table.types";

type AccountsTableColumnsProps = {
  onRemoveAccount: (id: number, accountName: string) => void;
  onUpdateAccount: (accountId: number) => void;
  isRemoveAccountLoading: boolean;
};

export function accountsTableColumns({
  isRemoveAccountLoading,
  onRemoveAccount,
  onUpdateAccount,
}: AccountsTableColumnsProps): Column<AccountsTableData>[] {
  return [
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Classification",
      accessor: "classification",
    },
    {
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? (
            <Icon component={<ArrowDownCircleM />} color="#ccc" />
          ) : (
            <Icon component={<ArrowRightCircleM />} color="#ccc" />
          )}{" "}
          Name
        </span>
      ),
      accessor: "name",
      Cell: ({ row }) => {
        return (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {!row.canExpand ? (
              row.original.level === 1 ? (
                ""
              ) : (
                <Icon component={<ChildRowLeftDownM />} color="#ccc" />
              )
            ) : row.isExpanded ? (
              <Icon component={<ArrowDownCircleM />} color="#ccc" />
            ) : (
              <Icon component={<ArrowRightCircleM />} color="#ccc" />
            )}
            {row.original.name}
          </span>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: ({ value }) => <StatusBadge active={value} />,
    },
    {
      Header: "",
      id: "actions",
      width: 100,
      Cell: ({ row }: { row: Row<AccountsTableData> }) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button htmlType="button" disabled mode="single-icon">
            <Icon component={<FileDownloadM />} />
          </Button>
          <Button
            onClick={() => onUpdateAccount(row.original.id)}
            type="ghost-white"
            mode="single-icon"
          >
            <Icon color="grey" component={<EditM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ${row.original.name}?`}
            onConfirm={() => onRemoveAccount(row.original.id, row.original.name)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button mode="single-icon" type="ghost-white" loading={isRemoveAccountLoading}>
              <Icon color="red" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
}
