import { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { ReportOrientation } from "@/components/LayoutBuilder/LayoutBuilder.types";

import { ReportsTagService } from "../../reports-tags.services";
import { TagResponse } from "../../reports-tags.types";
import { REPORTS_TAGS_QUERY_KEY } from "../../reports.config";
import { ReportResponse } from "../../reports.types";
import { reportBuilderGridLayoutStylesInitialConfig } from "../report-builder.config";

type ReportBuilderContextData = {
  gridItemsDrawerOpened: boolean;
  editItemDrawerOpened: boolean;
  isReportCreateModalOpened: boolean;
  isPrivate: boolean;
  tags: CurrentReportTag[];
  toggleGridItemsDrawer: (opened?: boolean) => void;
  toggleEditItemDrawer: (opened?: boolean) => void;
  toggleReportCreateModal: (opened?: boolean) => void;
  reportBuilderGridLayoutStyles: React.CSSProperties;
  layoutOrientation: "landscape" | "portrait";
  changeLayoutOrientation: (orientation: "landscape" | "portrait") => void;
  changeIsPrivate: (isPrivate: boolean) => void;
  handleChangeTagCheck: (id: number, checked: boolean) => void;
};

const ReportBuilderContext = createContext({} as ReportBuilderContextData);

type ReportBuilderProviderProps = {
  children: React.ReactNode;
  loadedReport?: ReportResponse;
};

export type CurrentReportTag = {
  id: number;
  title: string;
  checked: boolean;
}

function mapTagsResponse(tags: TagResponse[]): CurrentReportTag[] {
  return tags.map(tag => ({
    id: tag.id,
    title: tag.title,
    checked: false,
  }))
}

export function ReportBuilderProvider({ children, loadedReport }: ReportBuilderProviderProps) {
  const [gridItemsDrawerOpened, setGridItemsDrawerOpened] = useState(!loadedReport);
  const [editItemDrawerOpened, setEditItemDrawerOpened] = useState(false);
  const [isReportCreateModalOpened, setIsReportCreateModalOpened] = useState(false);
  const [reportBuilderGridLayoutStyles, setReportBuilderGridLayoutStyles] =
    useState<React.CSSProperties>(reportBuilderGridLayoutStylesInitialConfig);
  const [layoutOrientation, setLayoutOrientation] = useState<ReportOrientation>("landscape");
  const [isPrivate, setIsPrivate] = useState(false);
  const [tags, setTags] = useState<CurrentReportTag[]>([]);
  const {data: reportTags} = useQuery([REPORTS_TAGS_QUERY_KEY], ReportsTagService.fetchAll);

  useEffect(() => {
    const newTags = mapTagsResponse(reportTags || []);
    const newTagsWithChecked = newTags.map((tag) => {
      if (loadedReport?.tags.some(tagFromReport => tagFromReport.id === tag.id)) {
        return ({
          ...tag,
          checked: true,
        })
      }
      return tag;
    })
    setTags(newTagsWithChecked);
  }, [reportTags, loadedReport])

  useEffect(() => {
    setGridItemsDrawerOpened(!loadedReport);
    setIsPrivate(loadedReport?.is_private ?? false);

    if (loadedReport && loadedReport.extra) {
      setLayoutOrientation(loadedReport.extra.orientation || "landscape")

    }

  }, [loadedReport]);

  function changeIsPrivate(privateReport: boolean) {
    setIsPrivate(privateReport);
  }

  const handleChangeTagCheck = (id: number, checked:  boolean) => {
    const newTags = tags.map(tag => {
      if(tag.id === id) {
        return ({
          ...tag,
          checked,
        })
      }
      return tag
    })
    setTags(newTags);
  }

  const toggleReportCreateModal = (opened?: boolean) => {
    if (opened === undefined) {
      setIsReportCreateModalOpened((prevState) => !prevState);
      return;
    }
    setIsReportCreateModalOpened(opened);
  };

  const toggleGridItemsDrawer = (opened?: boolean) => {
    if (opened === undefined) {
      setGridItemsDrawerOpened((prevState) => !prevState);
      return;
    }
    setGridItemsDrawerOpened(opened);
  };

  const toggleEditItemDrawer = (opened?: boolean) => {
    if (opened === undefined) {
      setEditItemDrawerOpened((prevState) => !prevState);
      return;
    }

    setEditItemDrawerOpened(opened);
  };

  // it fix <ResponsiveReactGridLayout /> don't resizing when <ComponentsListDrawer /> or <EditItemDrawer /> is toggled
  useEffect(() => {
    for (let t = 0; t <= 300; t += 10) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, t);
    }
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [gridItemsDrawerOpened, editItemDrawerOpened, layoutOrientation]);

  const changeLayoutOrientation = (orientation: "landscape" | "portrait") => {
    setLayoutOrientation(orientation);
  };

  useEffect(() => {
    if (layoutOrientation === "landscape") {
      setReportBuilderGridLayoutStyles((prevState) => ({
        ...prevState,
        width: "100%",
      }));
    }

    if (layoutOrientation === "portrait") {
      setReportBuilderGridLayoutStyles((prevState) => ({
        ...prevState,
        width: "1000px",
      }));
    }
  }, [layoutOrientation]);

  return (
    <ReportBuilderContext.Provider
      value={{
        tags,
        isPrivate,
        changeIsPrivate,
        gridItemsDrawerOpened,
        editItemDrawerOpened,
        isReportCreateModalOpened,
        toggleGridItemsDrawer,
        toggleEditItemDrawer,
        toggleReportCreateModal,
        reportBuilderGridLayoutStyles,
        layoutOrientation,
        changeLayoutOrientation,
        handleChangeTagCheck,
      }}
    >
      {children}
    </ReportBuilderContext.Provider>
  );
}

export const useReportBuilder = () => {
  const context = useContext(ReportBuilderContext);

  if (context === undefined) {
    throw new Error("useReportBuilder must be used within a ReportBuilderProvider");
  }

  return context;
};
