import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #e4e4e4;

  & .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  & .card-logo {
    width: 30px;
    height: 30px;
  }

  & .card-title {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  & .card-description {
    color: #949494;
    text-align: center;
  }
`;
