import { api } from "@/utils/http";

import {
  DimensionType,
  DimensionTypeCreatePayload,
  DimensionTypeUpdatePayload,
} from "./dimension-types.types";

export class DimensionTypesService {
  static async list(entityID?: number, ids?: number[]) {
    const response = await api.get<DimensionType[]>(`/dimensions`, {
      params: {
        entity_id: entityID,
        ids: ids ? ids.toString() : undefined,
      },
    });
    return response.data;
  }

  static async show(id: number) {
    const response = await api.get<DimensionType>(`/dimensions/${id}`);
    return response.data;
  }

  static async create(payload: DimensionTypeCreatePayload) {
    const response = await api.post<DimensionType>("/dimensions", payload);
    return response.data;
  }

  static async delete(id: number) {
    const response = await api.delete<ApiStatusResponse>(`/dimensions/${id}`);
    return response.data;
  }

  static async update(id: number, payload: DimensionTypeUpdatePayload) {
    const response = await api.patch<DimensionType>(`/dimensions/${id}`, payload);
    return response.data;
  }
}
