import { useState } from "react";
import { Outlet } from "react-router-dom";

import Layout from "@synerise/ds-layout";

import { ForecastLeftSidebar } from "./components/ForecastLeftSidebar";

import "./styles.css";

export function ForecastOutlet() {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);

  return (
    <Layout
      className="forecast-layout"
      fullPage
      renderLeftSidebarControls
      left={{
        opened: isLeftSidebarOpen,
        width: 250,
        content: <ForecastLeftSidebar />,
        onChange: () => setIsLeftSidebarOpen((prevState) => !prevState),
      }}
    >
      <Outlet />
    </Layout>
  );
}
