import Modal from "@synerise/ds-modal";
import Result from "@synerise/ds-result";

type UserInvitedModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  inviteNewUser: () => void;
};

export function UserInvitedModal({ visible, setVisible, inviteNewUser }: UserInvitedModalProps) {
  return (
    <Modal
      visible={visible}
      onOk={() => inviteNewUser()}
      onCancel={() => setVisible(false)}
      title="User Invited"
      texts={{
        okButton: "Invite new user",
        cancelButton: "Close",
      }}
    >
      <Result type="success" title="User has been invited" />
    </Modal>
  );
}
