import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Layout } from "react-grid-layout";
import { v4 as uuidv4 } from "uuid";

import message from "@synerise/ds-message";

import { getItemsConfig } from "@/components/LayoutBuilder/GridItems/GridItems.config";
import { ItemConfig, ItemLayout, ItemType } from "@/components/LayoutBuilder/LayoutBuilder.types";

import { ReportLayout } from "../report-builder.types";
import { useReportBuilder } from "./useReportBuilder";

export type UpdateDashboardViewProps = {
  id: string;
  name: string;
  isDefault: boolean;
};

type GridLayoutContextData = {
  items: ItemLayout<any>[];
  indexItemSelected: string | null;
  draggingElementType: ItemType | null;
  configItemSelected: ItemConfig<any> | null;
  showCancelEditModal: boolean;
  toggleShowCancelEditModal: (show?: boolean) => void;
  removeItem: (i: string) => void;
  selectItem: (i: string) => void;
  saveLayout: () => void;
  updateLayoutItem: (data: any, index: string, type: ItemType, layout?: Partial<Layout>) => void;
  unselectItem: () => void;
  findItem: (i: string) => ItemLayout<any> | undefined;
  handleDragStart: (event: React.DragEvent<HTMLDivElement>, elementType: ItemType) => void;
  handleDrop: (item: ReactGridLayout.Layout) => void;
  handleLayoutChange: (layout: ReactGridLayout.Layout[]) => void;
  setCurrentReport: (report: ReportLayout) => void;
  editingItem: ItemLayout<any> | null;
  itemHasBeenModified: boolean;
  cancelEdit: () => void;
  confirmCancelEdit: () => void;
  updateEditingItem: (newItem: ItemLayout<any>, layout?: Partial<Layout>) => void;
  saveTemporaryEditChanges: () => void;
  closeCancelEditModal: () => void;
  inPreviewMode: boolean;
  toggleInPreviewMode: (inPreview?: boolean) => void;
};

const GridLayoutContext = createContext({} as GridLayoutContextData);

type GridLayoutProviderProps = {
  children: React.ReactNode;
  loadedReport?: ReportLayout;
};

export function GridLayoutProvider({ children, loadedReport }: GridLayoutProviderProps) {
  const [items, setItems] = useState<ItemLayout<any>[]>([]);
  const [, setCurrentReport] = useState<ReportLayout | null>(null);
  const [indexItemSelected, setIndexItemSelected] = useState<string | null>(null);
  const [configItemSelected, setConfigItemSelected] = useState<ItemConfig<any> | null>(null);
  const [draggingElementType, setDraggingElementType] = useState<ItemType | null>(null);
  const { toggleEditItemDrawer, toggleReportCreateModal } = useReportBuilder();
  const [showCancelEditModal, setShowCancelEditModal] = useState(false);
  const [editingItem, setEditingItem] = useState<ItemLayout<any> | null>(null);
  const editingItemBackup = useRef<ItemLayout<any> | null>(null);
  const [itemHasBeenModified, setItemHasBeenModified] = useState(false);
  const [inPreviewMode, setInPreviewMode] = useState(false);

  const itemsConfig = getItemsConfig({
    updateItem: updateEditingItem,
    editingItem,
  });

  useEffect(() => {
    if (loadedReport) {
      setCurrentReport(loadedReport);
      setItems(loadedReport.layout);
    }
  }, [loadedReport]);

  useEffect(() => {
    if (!indexItemSelected) {
      setConfigItemSelected(null);
      const newItems = items.map((item) => ({
        ...item,
        resizeHandles: [],
      }));
      setItems(newItems);
      return;
    }
    const itemSelected = findItem(indexItemSelected);
    if (!itemSelected) {
      setConfigItemSelected(null);
      return;
    }
    const itemConfigMatch = itemsConfig[itemSelected.type];
    if (!itemConfigMatch) {
      setConfigItemSelected(null);
      return;
    }

    const newItems: ItemLayout<any>[] = items.map((item) => {
      if (item.i === indexItemSelected) {
        return {
          ...item,
          resizeHandles: itemConfigMatch.resizeHandles,
        };
      }
      return {
        ...item,
        resizeHandles: [],
      };
    });
    setItems(newItems);
    setConfigItemSelected(itemConfigMatch);
  }, [indexItemSelected]);

  useEffect(() => {
    if (!indexItemSelected) {
      setEditingItem(null);
      return;
    }
    const item = findItem(indexItemSelected);
    if (!item) return;
    setEditingItem({ ...item });
    editingItemBackup.current = { ...item };
  }, [indexItemSelected]);

  function updateEditingItem(newItem: ItemLayout<any>, layout?: Partial<Layout>) {
    updateLayoutItem({ ...newItem.data }, newItem.i, newItem.type, layout);
    setEditingItem({ ...newItem });
    setItemHasBeenModified(true);
  }

  function saveTemporaryEditChanges() {
    editingItemBackup.current = editingItem;
    setItemHasBeenModified(false);
  }

  function closeCancelEditModal() {
    saveTemporaryEditChanges();
    setShowCancelEditModal(false);
  }

  function confirmCancelEdit() {
    if (!editingItemBackup.current) return;
    updateLayoutItem(
      { ...editingItemBackup.current.data },
      editingItemBackup.current.i,
      editingItemBackup.current.type
    );
    setEditingItem(editingItemBackup.current);
    setShowCancelEditModal(false);
    setItemHasBeenModified(false);
    message.info("Canceled");
  }

  function cancelEdit() {
    if (JSON.stringify(editingItemBackup.current) === JSON.stringify(editingItem)) return;
    setShowCancelEditModal(true);
  }

  function addItem(item: ItemLayout<any>) {
    const newItems = [...items, item];
    setItems(newItems);
  }

  function removeItem(i: string) {
    const newItems = items.filter((item) => item.i !== i);
    setItems(newItems);
    setIndexItemSelected(null);
  }

  function selectItem(i: string) {
    if (indexItemSelected !== i && !inPreviewMode) {
      setIndexItemSelected(i);
    }
    toggleEditItemDrawer(true);
  }

  function unselectItem() {
    cancelEdit();
    setIndexItemSelected(null);
  }

  function saveLayout() {
    toggleReportCreateModal();
  }

  function toggleShowCancelEditModal(show?: boolean) {
    if (show === undefined) {
      setShowCancelEditModal((prevState) => !prevState);
      return;
    }
    setShowCancelEditModal(show);
  }

  function updateLayoutItem(
    data: Record<string, any>,
    index: string,
    type: ItemType,
    layout?: Partial<Layout>
  ) {
    const newItems = items.map((item) => {
      if (item.i === index) {
        let newItem = {
          ...item,
          type,
          data,
        };
        if (layout) {
          newItem = {
            ...newItem,
            ...layout,
          };
        }
        return newItem;
      }
      return item;
    });
    setItems(newItems);
  }

  function findItem(i: string) {
    const itemFound = items.find((item) => item.i === i) || ({} as ItemLayout<any>);
    return itemFound;
  }

  function handleDragStart(event: React.DragEvent<HTMLDivElement>, elementType: ItemType) {
    event.dataTransfer.setData("text/plain", ""); // For Firefox
    setDraggingElementType(elementType);
  }

  function handleDrop(item: ReactGridLayout.Layout) {
    if (!draggingElementType) return;

    const itemFromConfig = itemsConfig[draggingElementType];
    if (!itemFromConfig) return;

    const initialItemLayout = itemFromConfig.initialLayout;

    if (!initialItemLayout) return;

    const newItem = {
      ...initialItemLayout,
      i: uuidv4(),
      x: item.x,
      y: item.y,
    };
    addItem(newItem);
  }

  function handleLayoutChange(layout: ReactGridLayout.Layout[]) {
    const newLayout = [...layout];
    const itemsFilteredByState = newLayout.filter((item) => !!findItem(item.i).i);

    const itemsMapped = itemsFilteredByState.map((item) => {
      const itemMatched = findItem(item.i);

      return {
        ...item,
        data: itemMatched.data,
        type: itemMatched.type,
      };
    });
    setItems(itemsMapped);
  }

  function toggleInPreviewMode(inPreview?: boolean) {
    if (inPreview === undefined) {
      setInPreviewMode((prevState) => {
        if (!prevState) {
          setIndexItemSelected(null);
        }
        return !prevState;
      });
      return;
    }
    if (!inPreview) {
      setIndexItemSelected(null);
    }
    setInPreviewMode(inPreview);
  }

  useEffect(() => {
    for (let t = 0; t <= 300; t += 10) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, t);
    }
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [inPreviewMode, indexItemSelected]);

  return (
    <GridLayoutContext.Provider
      value={{
        items,
        indexItemSelected,
        draggingElementType,
        configItemSelected,
        showCancelEditModal,
        toggleShowCancelEditModal,
        removeItem,
        selectItem,
        saveLayout,
        updateLayoutItem,
        unselectItem,
        findItem,
        handleDragStart,
        handleDrop,
        handleLayoutChange,
        setCurrentReport,
        itemHasBeenModified,
        editingItem,
        cancelEdit,
        confirmCancelEdit,
        updateEditingItem,
        saveTemporaryEditChanges,
        closeCancelEditModal,
        inPreviewMode,
        toggleInPreviewMode,
      }}
    >
      {children}
    </GridLayoutContext.Provider>
  );
}

export const useGridLayout = () => {
  const context = useContext(GridLayoutContext);

  if (context === undefined) {
    throw new Error("useGridLayout must be used within a GridLayoutProvider");
  }

  return context;
};
