import { ReactNode } from "react";

import { Text } from "@synerise/ds-typography";

import * as Styled from "./ledger-details.styles";

type InfoDetailProps = {
  icon?: ReactNode;
  title: string;
  children: React.ReactNode;
};

export function InfoDetailComponent({ icon, title, children }: InfoDetailProps) {
  return (
    <Styled.DetailInfoContainer>
      {icon}
      <div>
        <Text size={"xsmall"}>{title}</Text>
        <div className="value">{children}</div>
      </div>
    </Styled.DetailInfoContainer>
  );
}
