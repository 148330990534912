import Button from "@synerise/ds-button";
import Icon, { NotificationsActiveM } from "@synerise/ds-icon";
import message from "@synerise/ds-message";
import { default as DSNavbar } from "@synerise/ds-navbar";

import LogoWhite from "@/assets/LogoWhite.png";
import { useAuth } from "@/hooks/useAuth";
import { useTenant } from "@/hooks/useTenant";

import { HelpItem } from "./HelpCenter/HelpCenterItem";
import { NewItemAdd } from "./NewItem/NewItemAdd";
import { ProfileItem } from "./Profile/ProfileItem";
import { ReferenceItem } from "./Reference/ReferenceItem";

// TODO: Add search component
// https://design.synerise.com/storybook-static/?path=/story/components-search-search--withdropdown

export function Navbar() {
  const { currentTenant } = useAuth();

  const onGetTenantError = (errorMessage: string) => {
    message.error(errorMessage);
  };

  const { tenant } = useTenant(onGetTenantError, currentTenant?.id);

  return (
    <DSNavbar
      logo={LogoWhite}
      description=""
      color={tenant?.preferences?.color1 || "#0C1727"}
      actions={null}
      additionalNodes={[
        <>
          <NewItemAdd />
          <ReferenceItem />
          <HelpItem />
          <Button mode="single-icon" key={4} type="ghost-white">
            <Icon color="#ffffff" component={<NotificationsActiveM />} />
          </Button>
        </>,
      ]}
      alertNotification={null}
    >
      <ProfileItem />
    </DSNavbar>
  );
}
