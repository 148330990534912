import { Props } from "@synerise/ds-select/dist/Select.types";

import * as Styled from "./select.styles";

type DSSelectProps = Props;

type InputProps = {
  children: React.ReactNode;
  label: string;
} & Omit<DSSelectProps, "label">;

export function Select({ label, children, ...rest }: InputProps) {
  return (
    <Styled.Container>
      <label>{label}</label>
      <Styled.StyledDSSelect {...rest} label="">
        {children}
      </Styled.StyledDSSelect>
    </Styled.Container>
  );
}
