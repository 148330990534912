import { useState } from "react";

import Tabs from "@synerise/ds-tabs";

import { mockChartData } from "../../../GridItems/GridItemsChart.mock.data";
import { ChartType, ItemLayout } from "../../../LayoutBuilder.types";
import { EditItemDrawerChartContentDataTab } from "./EditItemDrawerChartContentDataTab";
import { EditItemDrawerChartContentSettingsTab } from "./EditItemDrawerChartContentSettingsTab";

type EditItemDrawerChartContentProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>) => void;
};

export function EditItemDrawerChartContent({
  updateItem,
  editingItem,
}: EditItemDrawerChartContentProps) {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  if (!editingItem) return null;

  function handleUpdateTitle(newTitle: string) {
    if (!editingItem) return;
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        title: newTitle,
      },
    };

    updateItem(newItem);
  }

  function handleUpdateSeries(seriesName: string) {
    if (!editingItem) return;

    const series = mockChartData.series.find((s) => s.name === seriesName);
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        series: [series],
      },
    };
    updateItem(newItem);
  }

  function handleUpdateChartType(chartType: ChartType) {
    if (!editingItem) return;

    const newItem = {
      ...editingItem,
      type: chartType,
      data: {
        ...editingItem.data,
        type: chartType,
      },
    };
    updateItem(newItem);
  }

  return (
    <>
      <Tabs
        tabs={[{ label: "Settings" }, { label: "Data" }]}
        activeTab={selectedTabIndex}
        handleTabClick={(index) => {
          setSelectedTabIndex(index);
        }}
      />

      {selectedTabIndex === 0 && (
        <EditItemDrawerChartContentSettingsTab
          onChangeChartType={handleUpdateChartType}
          selectedChartType={editingItem?.type as ChartType}
        />
      )}

      {selectedTabIndex === 1 && (
        <EditItemDrawerChartContentDataTab
          selectedChartTitle={editingItem.data.title}
          onChangeChartTitle={handleUpdateTitle}
          onChangeChartFirstSeriesName={handleUpdateSeries}
          selectedChartFirstSeriesName={editingItem.data.series[0]?.name}
        />
      )}
    </>
  );
}
