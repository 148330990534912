import { MeetingMattersItem } from "../MeetingMattersItem";
import * as Styled from "./styles";

export function MeetingMatters() {
  return (
    <Styled.Container>
      <MeetingMattersItem />
      <MeetingMattersItem />
    </Styled.Container>
  );
}
