import { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";

import message from "@synerise/ds-message";

import { KPIValueCommentService } from "@/modules/kpis/kpi-value-comment.services";
import { KpiValueCommentResponse } from "@/modules/kpis/kpi-value-comment.types";
import { KPIValueService } from "@/modules/kpis/kpi-value.services";
import { KpiValueResponse } from "@/modules/kpis/kpi-value.types";
import { KPIService } from "@/modules/kpis/kpi.services";
import { KpiResponse } from "@/modules/kpis/kpi.types";
import { KPIS_QUERY_KEY } from "@/modules/kpis/library/hooks/kpi.config";
import { queryClient } from "@/utils/queryClient";

const KPI_VALUE_COMMENTS_QUERY_KEY = "kpi-value-comments";

type UseKPICommentsProps = {
  kpiId: number;
  valueId: number;
};

async function createComment(kpiId: number, valueId: number, { text }: { text: string }) {
  const payload = {
    comment: {
      text,
    },
  };

  return await KPIValueCommentService.create(kpiId, valueId, payload);
}

async function removeComment(kpiId: number, valueId: number, commentId: number) {
  return await KPIValueCommentService.delete(kpiId, valueId, commentId);
}

export function useKPIComments({ kpiId, valueId }: UseKPICommentsProps) {
  const [kpi, setKpi] = useState<KpiResponse>();
  const [kpiValue, setKpiValue] = useState<KpiValueResponse>();

  const {
    data,
    isLoading: isLoadingComments,
    error,
  } = useQuery<KpiValueCommentResponse[], AxiosError>(
    [KPI_VALUE_COMMENTS_QUERY_KEY, kpiId, valueId],
    async () => await KPIValueCommentService.fetchAll(kpiId, valueId),
    {
      initialData: [],
    }
  );

  useEffect(() => {
    async function fetchKpiData() {
      const kpiResponse = await KPIService.fetchOne(kpiId);
      setKpi(kpiResponse);
      const kpiValueResponse = await KPIValueService.fetchOne(kpiId, valueId);
      setKpiValue(kpiValueResponse);
    }
    fetchKpiData();
  }, []);

  const comments = useMemo(() => {
    if (error && error.response && error.response.status === 404) return [];
    return data || [];
  }, [data, error]);

  const createCommentMutation = useMutation(
    [KPI_VALUE_COMMENTS_QUERY_KEY, kpiId, valueId],
    async (payload: { text: string }) => await createComment(kpiId, valueId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KPI_VALUE_COMMENTS_QUERY_KEY, kpiId, valueId]);
        queryClient.invalidateQueries(KPIS_QUERY_KEY);
      },
    }
  );

  const removeCommentMutation = useMutation(
    [KPI_VALUE_COMMENTS_QUERY_KEY, kpiId, valueId],
    async ({ commentId }: { commentId: number }) => await removeComment(kpiId, valueId, commentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KPI_VALUE_COMMENTS_QUERY_KEY, kpiId, valueId]);
        queryClient.invalidateQueries(KPIS_QUERY_KEY);
        message.info("Comment removed");
      },
    }
  );

  const handleCreateComment = async (text: string) => {
    await createCommentMutation.mutateAsync({ text });
  };

  const handleRemoveComment = async (commentId: number) => {
    await removeCommentMutation.mutateAsync({
      commentId,
    });
  };

  return {
    comments,
    handleRemoveComment,
    handleCreateComment,
    isLoadingComments,
    kpi,
    kpiValue,
  };
}
