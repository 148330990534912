import { useMemo } from "react";
import { useQuery } from "react-query";

import { GOVERNANCE_QUERY_KEYS } from "../governance.query.keys";
import { GovernanceServices } from "../governance.services";
import { BoardResponse } from "../governance.services.types";

type UseGetBoardsReturn = {
  boards: BoardResponse[];
  isBoardsLoading: boolean;
};

export function useGetBoards(): UseGetBoardsReturn {
  const { data, isLoading } = useQuery(GOVERNANCE_QUERY_KEYS.Boards, GovernanceServices.fetchAll);

  const boards = useMemo(() => {
    return data || [];
  }, [data]);

  const isBoardsLoading = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  return {
    boards,
    isBoardsLoading,
  };
}
