import Button from "@synerise/ds-button";
import FileUploader from "@synerise/ds-file-uploader";
import { FileWithContent } from "@synerise/ds-file-uploader/dist/FileUploader.types";
import Icon, { FileM, CloseM } from "@synerise/ds-icon";

import * as LibraryStyled from "../kpi-library.styles";
import * as Styled from "./KpiImportValuesFileStep.styles";

type KPIFormCreateImportValuesFileStepProps = {
  valuesFile: FileWithContent | null;
  setValuesFile: (valuesFile: FileWithContent | null) => void;
};

export function KPIImportValueFileStep({
  valuesFile,
  setValuesFile,
}: KPIFormCreateImportValuesFileStepProps) {
  return (
    <Styled.KPIImportValueFileStepContainer>
      {!valuesFile && (
        <div>
          <FileUploader
            mode="single"
            filesAmount={1}
            files={[]}
            texts={{
              buttonLabel: "Import Ledger",
              buttonDescription: "or drag one here",
              size: "Size:",
              fileWeight: "Weight:",
              cancelText: "Cancel",
              okText: "OK",
              removeConfirmTitle: "Remove file",
              percent: 0,
              retryLabel: "Retry",
              buttonLabelLarge: "Import Ledger",
            }}
            onUpload={(files) => {
              if (files && files.length > 0) {
                setValuesFile(files[0]);
              }
            }}
          />
        </div>
      )}

      {valuesFile && (
        <LibraryStyled.ImportedValuesFileContainer>
          <div>
            <Icon component={<FileM />} size={24} color="#888" />
            {valuesFile.name}
          </div>
          <div className="actions">
            <Button
              mode="single-icon"
              type="custom-color-ghost"
              color="red"
              onClick={() => setValuesFile(null)}
            >
              <Icon component={<CloseM />} size={24} color="#888" />
            </Button>
          </div>
        </LibraryStyled.ImportedValuesFileContainer>
      )}
    </Styled.KPIImportValueFileStepContainer>
  );
}
