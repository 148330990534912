import { Text } from "@synerise/ds-typography";

import * as Styled from "./accounts-header-summary-item.styles";

type DetailInfoProps = {
  icon: React.ReactNode;
  title: string;
  text: string | number;
};

export function AccountsHeaderSummaryItem({ icon, title, text }: DetailInfoProps) {
  return (
    <Styled.Container>
      {icon}
      <div>
        <Text size="xsmall">{title}</Text>
        <Text size="small">
          <strong>{text}</strong>
        </Text>
      </div>
    </Styled.Container>
  );
}
