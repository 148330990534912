import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import AvatarUploader from "@synerise/ds-file-uploader";
import { BucketFillM } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import { Title } from "@synerise/ds-typography";

import { ColorPicker } from "@/components/ColorPicker";
import { TenantPatch } from "@/core/tenants/tenants.types";
import { useAuth } from "@/hooks/useAuth";
import { useTenant } from "@/hooks/useTenant";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";


export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export function OrganizationAppearance() {
  const navigate = useNavigate();
  const { currentTenant } = useAuth();
  const [editingSectionOne, setEditingSectionOne] = useState(false);

  const onGetTenantError = (errorMessage: string) => {
    message.error(errorMessage);
    navigate("/settings/home");
  };

  const { tenant, updateTenant } = useTenant(onGetTenantError, currentTenant?.id);
  const [primaryColorPicked, setPrimaryColorPicked] = useState<string>(
    tenant?.preferences?.color1 || ""
  );

  useEffect(() => {
    setPrimaryColorPicked(tenant?.preferences?.color1 || "");
  }, [tenant?.preferences?.color1]);

  const [updatingSectionOne, setUpdatingSectionOne] = useState(false);

  const onSubmit = async () => {
    setUpdatingSectionOne(true);
    const onSuccess = () => {
      setUpdatingSectionOne(false);
      message.success("New organization appearance saved");
      setEditingSectionOne(false);
    };

    const onError = (errorMessage: string) => {
      setUpdatingSectionOne(false);
      message.error(errorMessage);
    };

    const payload: TenantPatch = {
      preferences: {
        color1: primaryColorPicked,
      },
    };

    await updateTenant({
      payload,
      onSuccess,
      onError,
    });
  };

  return (
    <Layout
      left={LeftLayoutContent}
      header={<PageHeader onGoBack={() => navigate("/settings/home")} title="Appearance" />}
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Title level={3}>Organization Appearance Settings</Title>
          <Card
            background="white-shadow"
            lively
            title="Logo & Colors"
            withHeader
            icon={<BucketFillM />}
            iconColor={"#AEAEAE"}
            headerSideChildren={
              !editingSectionOne ? (
                <SideButtonsContainer>
                  <Button onClick={() => setEditingSectionOne(true)} type="secondary">
                    Edit
                  </Button>
                </SideButtonsContainer>
              ) : (
                <SideButtonsContainer>
                  <Button onClick={() => setEditingSectionOne(false)} type="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => onSubmit()}
                    type="primary"
                    loading={updatingSectionOne}
                    disabled={updatingSectionOne}
                  >
                    Save
                  </Button>
                </SideButtonsContainer>
              )
            }
          >
            <ModuleStyled.InputWrapper>
              Select Logo
              <AvatarUploader
                accept={["image/png, image/jpeg"]}
                description="Maximum file size is 1MB, resolution 512x512px, acceptable format png, jpg, gif."
                files={[]}
                filesAmount={1}
                mode="single"
                onRemove={function noRefCheck() {}}
                onUpload={function noRefCheck() {}}
                disabled={!editingSectionOne}
                removable
                texts={{
                  removeTooltip: "Clear",
                  size: "File size should be less than 5MB",
                  cancelText: "Cancel",
                  okText: "OK",
                  removeConfirmTitle: "Are you sure you want to remove this file?",
                  fileWeight: "File weight",
                  percent: 1,
                  buttonLabel: "Upload file",
                  buttonDescription: "fdjskl",
                  retryLabel: "fjdkl",
                  buttonLabelLarge: "fjdkl",
                }}
              />
              <ColorPicker
                disabled={!editingSectionOne}
                label="Select primary color"
                description="Select the main color to be used in the application."
                colorPicked={primaryColorPicked}
                handleColorPicked={(hexColor) => {
                  setPrimaryColorPicked(hexColor);
                }}
              />
            </ModuleStyled.InputWrapper>
          </Card>
          <Card
            background="white-shadow"
            lively
            title="Favicon"
            withHeader
            icon={<BucketFillM />}
            iconColor={"#AEAEAE"}
            headerSideChildren={
              <SideButtonsContainer>
                <Button onClick={function noRefCheck() {}} type="seconday">
                  Show
                </Button>
              </SideButtonsContainer>
            }
          >
            <ModuleStyled.InputWrapper>
              Upload Favicon
              <AvatarUploader
                accept={["image/png, image/jpeg"]}
                description="Maximum file size is 1MB, resolution 512x512px, acceptable format png, jpg, gif."
                files={[]}
                filesAmount={1}
                mode="single"
                onRemove={function noRefCheck() {}}
                onUpload={function noRefCheck() {}}
                removable
                texts={{
                  removeTooltip: "Clear",
                  size: "File size should be less than 5MB",
                  cancelText: "Cancel",
                  okText: "OK",
                  removeConfirmTitle: "Are you sure you want to remove this file?",
                  fileWeight: "File weight",
                  percent: 1,
                  buttonLabel: "Upload file",
                  buttonDescription: "fdjskl",
                  retryLabel: "fjdkl",
                  buttonLabelLarge: "fjdkl",
                }}
              />
            </ModuleStyled.InputWrapper>
          </Card>
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
