import Chart from "react-apexcharts";

import { ChartData } from "../../LayoutBuilder.types";
import * as Styled from "../GridItems.styles";
import { columnChartOptions } from "./ColumnChart.options";

type ColumnChartProps = ChartData & {
  isSelected: boolean;
};

export function ColumnChart({ title, series, isSelected }: ColumnChartProps) {
  return (
    <Styled.GridItemContainer isSelected={isSelected}>
      <h3>{title}</h3>
      <Chart
        type="bar"
        series={series}
        height={"100%"}
        options={columnChartOptions(series && series.length > 0 ? series[0].labels : [])}
      />
    </Styled.GridItemContainer>
  );
}
