import { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";

import { useValidationSchema } from "@/hooks/useValidationSchema";

import * as Styled from "../login.styles";
import { useLogin } from "../useLogin";

type ResetPasswordFormValues = {
  password: string;
  password_confirmation: string;
};

const resetPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(40, "Password must be less than 40 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      "Password must contain at least one special character"
    ),
  password_confirmation: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export function ResetPassword() {
  const { resetPassword, resetPasswordLoading } = useLogin();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const validate = useValidationSchema(resetPasswordFormSchema);

  const queryToken = params.get("token");

  useEffect(() => {
    if (!queryToken) {
      message.error("Token is missing");
      navigate("/login");
    }
  }, [queryToken]);

  async function onSubmit(values: ResetPasswordFormValues) {
    if (!queryToken) return;

    const onSuccess = () => {
      message.success("Password has been reset successfully");
      navigate("/login");
    };

    const onError = (text: string) => {
      message.error(text);
      navigate("/forgot-password");
    };

    await resetPassword({
      payload: {
        token: queryToken,
        password: values.password,
      },
      onSuccess,
      onError,
    });
  }

  return (
    <Form<ResetPasswordFormValues> onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit }) => (
        <Styled.Form onSubmit={handleSubmit}>
          <Styled.CardContainer>
            <Card title="Reset password" withHeader>
              <Field name="password">
                {({ input, meta }) => (
                  <Input
                    {...input}
                    type="password"
                    label="Password"
                    placeholder="Password"
                    errorText={meta.touched && meta.error}
                  />
                )}
              </Field>

              <Field name="password_confirmation">
                {({ input, meta }) => (
                  <Input
                    {...input}
                    type="password"
                    label="Confirm password"
                    placeholder="Confirm password"
                    errorText={meta.touched && meta.error}
                  />
                )}
              </Field>

              <Styled.FooterButtonsContainer>
                <Button onClick={() => navigate("/login")}>Back to login</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={resetPasswordLoading}
                  disabled={resetPasswordLoading}
                >
                  Reset Password
                </Button>
              </Styled.FooterButtonsContainer>
            </Card>
          </Styled.CardContainer>
        </Styled.Form>
      )}
    </Form>
  );
}
