import { Layout } from "react-grid-layout";

import { Input } from "@synerise/ds-input";
import Select from "@synerise/ds-select";

import { mockOptions } from "../../GridItems/GridItemsTable.mock.data";
import { ItemLayout } from "../../LayoutBuilder.types";

type EditItemDrawerTableContentProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>, layout?: Partial<Layout>) => void;
};

export function EditItemDrawerTableContent({
  editingItem,
  updateItem,
}: EditItemDrawerTableContentProps) {
  function handleUpdateTitle(title: string) {
    if (!editingItem) return;
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        title,
      },
    };

    updateItem(newItem);
  }

  function handleUpdateDataSource(id: string) {
    const matchingOption = mockOptions.find((option) => option.id === id);
    if (!matchingOption) return;

    if (!editingItem) return;
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        tableData: matchingOption,
      },
    };

    updateItem(newItem);
  }

  return (
    <>
      <Input
        label="Title"
        value={editingItem?.data.title}
        onChange={(event: any) => {
          handleUpdateTitle(event.target.value);
        }}
      />
      <Select
        label="Data Source"
        value={editingItem?.data.tableData?.id}
        onChange={(id: any) => {
          handleUpdateDataSource(id);
        }}
      >
        {mockOptions.map((option) => (
          <Select.Option key={option.id} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
}
