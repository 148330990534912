import { EditItemDrawerChartContent } from "../../EditItemDrawer/contents/EditItemDrawerChartContent/EditItemDrawerChartContent";
import { ChartData, ItemConfig, ItemLayout } from "../../LayoutBuilder.types";

type GetLineChartConfigProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>) => void;
};

export function getLineChartConfig({
  editingItem,
  updateItem,
}: GetLineChartConfigProps): ItemConfig<ChartData> {
  return {
    name: "lineChart",
    editDrawerComponent: (
      <EditItemDrawerChartContent editingItem={editingItem} updateItem={updateItem} />
    ),
    resizeHandles: ["s", "sw", "se"],
    initialLayout: {
      w: 5,
      h: 5,
      type: "lineChart",
      minW: 3,
      minH: 5,
      resizeHandles: [],
      data: {
        title: "Chart Title",
        series: [],
      },
    },
  };
}
