import styled from "styled-components";

export const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const Title = styled.h3`
  text-align: center;
  margin-bottom: 0;
  font-size: 1.2rem;
`;
