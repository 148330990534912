import { Link, useLocation } from "react-router-dom";

import AppMenu from "@synerise/ds-app-menu";
import { ChatColorM, ChatGreyM } from "@synerise/ds-icon";

import { governanceRoutes } from "./governance.routes";

export function GovernanceMenu() {
  const { pathname } = useLocation();

  return (
    <AppMenu.Item
      name={governanceRoutes.home.name}
      id="governance"
      className="app-menu-item"
      subMenu={
        <AppMenu.SubMenu>
          <Link to={governanceRoutes.home.fullPath}>
            <AppMenu.SubMenu.Title>{governanceRoutes.home.name}</AppMenu.SubMenu.Title>
          </Link>

          {governanceRoutes.sections.map((section) => (
            <>
              <AppMenu.SubMenu.SubTitle>Main</AppMenu.SubMenu.SubTitle>
              {section.links.map((link) => (
                <Link to={link.fullPath}>
                  <AppMenu.SubMenu.Item active={pathname === link.fullPath}>
                    {link.name}
                  </AppMenu.SubMenu.Item>
                </Link>
              ))}
            </>
          ))}
        </AppMenu.SubMenu>
      }
    >
      <AppMenu.Item.Icon active={<ChatColorM />} inActive={<ChatGreyM />} />
    </AppMenu.Item>
  );
}
