import { Column } from "react-table";
import { v4 as uuidV4 } from "uuid";

import { TextCell } from "@/components/Table/Cells/HeaderCell";
import { formatDateToColumnHeader } from "@/modules/kpis/table/utils/formatDateToColumnHeader";

import * as Styled from "../../kpis/table/kpi-table.styles";
import { FinancialsISMockLine, FinancialsISMockValue } from "./mock/mockLines";
import { formatValue } from "./utils/formatValue";

function getValueColumns(
  periodColumnsSchema: FinancialsISMockValue[],
  onValueCellClick: (line: FinancialsISMockLine, value: FinancialsISMockValue) => void
): Column<FinancialsISMockLine>[] {
  return periodColumnsSchema.map((period) => ({
    Header: (
      <Styled.CenteredCellContent>
        {formatDateToColumnHeader("monthly", new Date(period.date))}
      </Styled.CenteredCellContent>
    ),
    id: period.date,
    columns: [
      {
        Header: <Styled.CenteredCellContent>Actual</Styled.CenteredCellContent>,
        id: uuidV4(),
        width: 90,
        style: {
          marginBottom: "1px solid black",
        },
        accessor: (row) => {
          const valueObject = row.values.find((v: any) => v.datetime === period.datetime);
          return (
            <div
              onClick={
                !row.is_metric && !row.is_calculated && valueObject
                  ? () => onValueCellClick(row, valueObject)
                  : () => {}
              }
            >
              <TextCell
                cursor={!row.is_metric && !row.is_calculated ? "pointer" : "auto"}
                fontWeight={row.is_metric ? "normal" : row.level === 0 ? "bold" : "normal"}
                textAlign="right"
              >
                {valueObject ? formatValue(valueObject.value) : "-"}
              </TextCell>
            </div>
          );
        },
      },
      {
        Header: <Styled.CenteredCellContent>vs. Budget</Styled.CenteredCellContent>,
        id: Math.floor(Math.random() * 10000000),
        width: 90,
        isBudgetVariationColumn: true,
        accessor: (row) => {
          return (
            <TextCell
              textAlign="right"
              fontWeight={row.is_metric ? "normal" : row.level === 0 ? "bold" : "normal"}
            >
              {formatValue(Math.floor(Math.random() * 30000))}
            </TextCell>
          );
        },
      },
    ],
  }));
}

export function getFinancialsIncomeStatementsColumns(
  periodColumnsSchema: FinancialsISMockValue[],
  onValueCellClick: (line: FinancialsISMockLine, value: FinancialsISMockValue) => void
) {
  const columns: Column<FinancialsISMockLine>[] & any[] = [
    {
      Header: "",
      accessor: "line_name",
      width: 400,
      Cell: ({ value, row }: any) => {
        return (
          <span
            style={{
              paddingLeft: row.original.level > 2 ? "2rem" : `${row.original.level}rem`,
              fontWeight: row.original.is_metric
                ? "normal"
                : row.original.level === 0
                ? "bold"
                : "normal",
            }}
          >
            {value}
          </span>
        );
      },
    },
    ...getValueColumns(periodColumnsSchema, onValueCellClick),
  ];

  return columns;
}
