import Button from "@synerise/ds-button";
import Modal from "@synerise/ds-modal";

import { Header } from "./Header";
import { JournalTable } from "./JournalTable";
import * as Styled from "./styles";

type LedgerJournalModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export function LedgerJournalModal({ isVisible, onClose }: LedgerJournalModalProps) {
  return (
    <Modal
      title="Journal"
      visible={isVisible}
      footer={
        <Button type="primary" onClick={onClose}>
          Ok
        </Button>
      }
      size="medium"
      onCancel={onClose}
    >
      <Styled.Container>
        <Header />
        <JournalTable />
      </Styled.Container>
    </Modal>
  );
}
