import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  & .content {
    label {
      font-weight: bold;
      font-size: 0.8rem;
    }

    p {
      margin-top: 0.5rem;
      font-size: 0.7rem;
      color: #b9b9b9;
    }
  }
`;
