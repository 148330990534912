import { useMemo } from "react";
import { useQuery } from "react-query";

import { ChartServices } from "@/modules/data-management/accounts/ChartOfAccounts/chart.services";
import { ChartResponse } from "@/modules/data-management/accounts/ChartOfAccounts/chart.types";

const ACCOUNTS_QUERY_KEY = "accounts";

type UseAccountsByChartIDReturn = {
  chartOfAccounts?: ChartResponse;
  chartOfAccountsLoading: boolean;
};

async function handleGetAccounts(id: number) {
  return await ChartServices.fetchOne(id);
}

export function useAccountsByChartID(chartID?: number): UseAccountsByChartIDReturn {
  const allAccountsFromQuery = useQuery(
    [ACCOUNTS_QUERY_KEY, chartID],
    () => {
      if (!chartID) return;
      return handleGetAccounts(chartID);
    },
    {
      enabled: !!chartID,
    }
  );

  const chartOfAccounts = useMemo(() => {
    return allAccountsFromQuery.data;
  }, [allAccountsFromQuery.data]);

  const chartOfAccountsLoading = useMemo(() => {
    return allAccountsFromQuery.isLoading;
  }, [allAccountsFromQuery.isLoading]);

  return {
    chartOfAccounts,
    chartOfAccountsLoading,
  };
}
