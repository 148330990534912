import Button from "@synerise/ds-button";
import Icon, { SortAzM, SortZaM, ArrangeM } from "@synerise/ds-icon";

type SortIconProps = {
  isSorted?: boolean;
  isSortedDesc?: boolean;
};

export function SortIcon({ isSorted, isSortedDesc }: SortIconProps) {
  if (!isSorted) {
    return (
      <Button mode="single-icon" type="ghost-white">
        <Icon color="#C4C4C4" component={<ArrangeM />} />
      </Button>
    );
  }

  if (isSortedDesc) {
    return (
      <Button mode="single-icon" type="ghost-white">
        <Icon color="#C4C4C4" component={<SortAzM />} />
      </Button>
    );
  }

  return (
    <Button mode="single-icon" type="ghost-white">
      <Icon color="#C4C4C4" component={<SortZaM />} />
    </Button>
  );
}
