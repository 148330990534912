import { api } from "@/utils/http";

import {
  TrialBalanceLineCreatePayload,
  TrialBalanceLineResponse,
  TrialBalanceLineUpdatePayload,
} from "./trialbalance-version-line.types";

export class TrialBalanceVersionLineServices {
  static async fetchAll(
    trialBalanceId: number,
    versionId: number
  ): Promise<TrialBalanceLineResponse[]> {
    const response = await api.get<TrialBalanceLineResponse[]>(
      `/trialbalances/${trialBalanceId}/versions/${versionId}/lines`
    );
    return response.data;
  }

  static async fetchOne(
    trialBalanceId: number,
    versionId: number,
    lineId: number
  ): Promise<TrialBalanceLineResponse> {
    const response = await api.get<TrialBalanceLineResponse>(
      `/trialbalance/${trialBalanceId}/versions/${versionId}/lines/${lineId}`
    );
    return response.data;
  }

  static async create(
    trialBalanceId: number,
    versionId: number,
    payload: TrialBalanceLineCreatePayload
  ): Promise<TrialBalanceLineResponse> {
    const response = await api.post<TrialBalanceLineResponse>(
      `/trialbalances/${trialBalanceId}/versions/${versionId}/lines`,
      payload
    );
    return response.data;
  }

  static async update(
    trialBalanceId: number,
    versionId: number,
    lineId: number,
    payload: TrialBalanceLineUpdatePayload
  ): Promise<TrialBalanceLineResponse> {
    const response = await api.patch<TrialBalanceLineResponse>(
      `/trialbalances/${trialBalanceId}/versions/${versionId}/lines/${lineId}`,
      payload
    );
    return response.data;
  }

  static async delete(
    trialBalanceId: number,
    versionId: number,
    lineId: number
  ): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(
      `/trialbalances/${trialBalanceId}/versions/${versionId}/lines/${lineId}`
    );
    return response.data;
  }
}
