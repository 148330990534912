import { useState } from "react";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { TextArea, Input } from "@synerise/ds-input";

import { useCreatePost } from "@/modules/governance/hooks/useCreatePost";
import { getCharactersCount } from "@/modules/governance/utils/getCharactersCount";

import * as Styled from "./styles";

type NewPostProps = {
  boardId: number;
};

const POST_MAX_LENGTH = 300;

export function NewPost({ boardId }: NewPostProps) {
  const [postContent, setPostContent] = useState("");
  const [postTitle, setPostTitle] = useState("");

  const { createPost } = useCreatePost({ boardId });

  const handleCreatePost = async () => {
    createPost({
      title: postTitle,
      post: {
        content: postContent,
      },
    });
    setPostTitle("");
    setPostContent("");
  };

  return (
    <Card>
      <Styled.Container>
        <div className="header">
          <span className="title">Create a new post</span>
        </div>
        <Styled.PostInfo>
          <Input
            placeholder="Post title..."
            resetMargin
            value={postTitle}
            onChange={(event: any) => setPostTitle(event.target.value)}
          />
          <span className="post-text-length">
            {getCharactersCount(postContent)}/{POST_MAX_LENGTH}
          </span>
        </Styled.PostInfo>
        <TextArea
          maxLength={POST_MAX_LENGTH}
          placeholder="Write your post here..."
          style={{
            whiteSpace: "pre-wrap",
          }}
          value={postContent}
          onChange={(event: any) => {
            setPostContent(event.target.value);
          }}
        />
        <Button
          type="primary"
          className="submit-button"
          onClick={() => handleCreatePost()}
          disabled={!postTitle || !postContent}
        >
          Post
        </Button>
      </Styled.Container>
    </Card>
  );
}
