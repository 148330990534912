import { Form, Field } from "react-final-form";
import * as yup from "yup";

import { Input } from "@synerise/ds-input";
import Modal from "@synerise/ds-modal";
import Switch from "@synerise/ds-switch";

import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { useDashboard } from "../../hooks/useDashboard";
import { useGridLayout } from "../../hooks/useGridLayout";

type CreateDashboardViewFormValues = {
  name: string;
  description?: string;
  isDefault: boolean;
};

const createDimensionTypeSchema = yup.object().shape({
  name: yup.string().required("Name required"),
  description: yup.string(),
  isDefault: yup.boolean(),
});

const initialValues = {
  name: "",
  description: "",
  isDefault: false,
};

const formId = "dashboard-view-modal-form"

export function DashboardViewModalFormCreate() {
  const validate = useValidationSchema(createDimensionTypeSchema);
  const { createDashboardViewModalOpened, toggleCreateDashboardViewModal } = useDashboard();
  const { createDashboardView } = useGridLayout();

  async function onSubmit(values: CreateDashboardViewFormValues) {
    await createDashboardView(values.name, values.isDefault);
    toggleCreateDashboardViewModal();
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => {
        return (
          <form onSubmit={handleSubmit} id={formId}>
            <Modal
            title="Create new dashboard view"
            onOk={() => handleSubmit()?.then(form.restart)}
            visible={createDashboardViewModalOpened}
            onCancel={() => toggleCreateDashboardViewModal()}
            okText="Create"
            bodyStyle={{
              padding: "16px 64px",
            }}
          >
            <Field
              name="name"
              render={({ input, meta }) => (
                <Input {...input} label="Name" errorText={meta.touched && meta.error} />
              )}
            />
            <Field
              name="description"
              render={({ input, meta }) => (
                <Input {...input} label="Description" errorText={meta.touched && meta.error} />
              )}
            />
            <ModuleStyled.SwitchWrapper>
              <Field
                name="isDefault"
                render={({ input }) => <Switch {...input} label="Default view" />}
              />
            </ModuleStyled.SwitchWrapper>
          </Modal>
          </form>
          
        );
      }}
    />
  );
}
