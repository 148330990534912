import Loader from "@synerise/ds-loader";

import * as Styled from "./styles";

export function LoadingApp() {
  return (
    <Styled.Container>
      <Loader
        fontSize="small"
        label="Please wait a second to proceed."
        labelPosition="bottom"
        size="L"
        text="You will be redirected to Compass in a moment."
      />
    </Styled.Container>
  );
}
