import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { LockM } from "@synerise/ds-icon";
import InlineEdit from "@synerise/ds-inline-edit";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Result from "@synerise/ds-result";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useAccessGroups } from "@/hooks/useAccessGroups";
import { useAuth } from "@/hooks/useAuth";
import { useEntities } from "@/hooks/useEntities";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { useMembers } from "../users/useMembers";
import { accessModulesPermissionsResources } from "./access-groups.config";
import * as Styled from "./access-groups.styles";
import { SideButtonsContainer } from "./access-groups.styles";
import { AccessGroupPost } from "./access-groups.types";
import { AppActions } from "./permissions.types";

type AccessGroupCreateFormValues = {
  account_name: string;
  account_description: string;
  members: number[];
  entities: number[];
} & Omit<PermissionsResources, "*">;

type PermissionsResources = {
  [key in AppActions]: boolean;
};

const AccessGroupCreateFormId = "access-group-create-form";

const AccessGroupCreateFormSchema = yup.object().shape({
  account_name: yup.string().required("Name is required"),
  account_description: yup.string().required("Description is required"),
});

// TODO: Add colector to select entities / users
// Referência: https://design.synerise.com/storybook-static/?path=/story/components-collector--default

export function AccessGroupCreate() {
  const { currentTenant } = useAuth();
  const navigate = useNavigate();
  const validate = useValidationSchema(AccessGroupCreateFormSchema);
  const { allEntities } = useEntities();
  const [showFirstCard, setShowFirstCard] = useState(true);
  const [showSecondCard, setShowSecondCard] = useState(true);
  const [showThirdCard, setShowThirdCard] = useState(true);
  const onGetMembersError = (errorMessage: string) => {
    message.error(errorMessage);
  };
  const { members } = useMembers({ onError: onGetMembersError });

  const { createAccessGroup, createAccessGroupLoading } = useAccessGroups();

  async function onSubmit(values: AccessGroupCreateFormValues) {
    if (!currentTenant?.id) {
      message.error("Tenant not found");
      return;
    }

    const { account_name, account_description, entities, members, ...resources } = values;

    const resourcesPermissions = Object.entries(resources)
      .filter(([key, value]) => value === true)
      .map(([resourceKey]) => resourceKey) as AppActions[];

    const payload: AccessGroupPost = {
      tenant_id: currentTenant.id,
      title: account_name,
      description: account_description,
      permissions: resourcesPermissions,
      entities: ["*"],
    };

    const onSuccess = () => {
      message.success("Access group created");
      navigate("/settings/access");
    };

    const onError = (errorMessage: string) => {
      message.error(errorMessage);
      navigate("/settings/access");
    };

    await createAccessGroup({
      onError,
      onSuccess,
      payload,
    });

    message.success("Access group saved.");
  }

  const formInitialValues = {
    account_name: "",
    account_description: "",
    entities: [],
    members: [],
  };

  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate("/settings/access")}
          title="Create New Access Group"
          rightSide={
            <SideButtonsContainer>
              <Button
                type="primary"
                htmlType="submit"
                form={AccessGroupCreateFormId}
                disabled={createAccessGroupLoading}
                loading={createAccessGroupLoading}
              >
                Create
              </Button>
            </SideButtonsContainer>
          }
        />
      }
    >
      <Form<AccessGroupCreateFormValues>
        onSubmit={onSubmit}
        validate={validate}
        initialValues={formInitialValues}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id={AccessGroupCreateFormId}>
            <ModuleStyled.MaxWidthContent>
              <ModuleStyled.CardsWrapper>
                <ModuleStyled.AvatarContainer>
                  <ModuleStyled.AvatarInputsWrapper>
                    <Field name="account_name">
                      {({ input, meta }) => (
                        <InlineEdit
                          input={{
                            ...input,
                            placeholder: "Access group name",
                          }}
                          size="large"
                          error={meta.touched && meta.error}
                        />
                      )}
                    </Field>

                    <Field name="account_description">
                      {({ input, meta }) => (
                        <InlineEdit
                          input={{
                            ...input,
                            placeholder: "Access group description",
                          }}
                          size="normal"
                          error={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </ModuleStyled.AvatarInputsWrapper>
                </ModuleStyled.AvatarContainer>
                <Card
                  background="white-shadow"
                  title="Resources & Actions"
                  withHeader
                  description="Select resources & actions that will be available for members of this access group"
                  icon={<LockM />}
                  iconColor={"#AEAEAE"}
                  hideContent={!showFirstCard}
                  headerSideChildren={
                    <SideButtonsContainer>
                      <Button onClick={() => setShowFirstCard(!showFirstCard)} type="secondary">
                        {showFirstCard ? "Hide" : "Show"}
                      </Button>
                    </SideButtonsContainer>
                  }
                >
                  <Styled.AccessModulesSwitchTable>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Create</td>
                        <td>Read</td>
                        <td>Update</td>
                        <td>Delete</td>
                      </tr>
                      {Object.entries(accessModulesPermissionsResources).map(
                        ([resourceKey, resourceValue]) => (
                          <>
                            <tr key={resourceKey}>
                              <td>{resourceValue.name}</td>
                              {Object.entries(resourceValue.permissions).map(
                                ([permissionKey, permissionValue]) => (
                                  <>
                                    <td>
                                      <Field name={`${resourceKey}:${permissionKey}`}>
                                        {({ input }) => <Switch {...input} label="" />}
                                      </Field>
                                    </td>
                                  </>
                                )
                              )}
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  </Styled.AccessModulesSwitchTable>
                </Card>
                <Card
                  background="white-shadow"
                  title="Members"
                  description="Add members to this access group"
                  withHeader
                  hideContent={!showSecondCard}
                  icon={<LockM />}
                  iconColor={"#AEAEAE"}
                  headerSideChildren={
                    <SideButtonsContainer>
                      <Button onClick={() => setShowSecondCard(!showSecondCard)} type="secondary">
                        {showSecondCard ? "Hide" : "Show"}
                      </Button>
                    </SideButtonsContainer>
                  }
                >
                  <ModuleStyled.InputWrapper>
                    <Field name="members">
                      {({ input }) => (
                        <Select
                          {...input}
                          mode="multiple"
                          dropdownStyle={Styled.dropdownStyle}
                          optionFilterProp="children"
                          notFoundContent={
                            <Result description="No results" noSearchResults type="no-results" />
                          }
                          placeholder="Select members"
                        >
                          {members.map((member) => (
                            <Select.Option key={member.id} value={member.id}>
                              {member.fname} {member.lname}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </ModuleStyled.InputWrapper>
                </Card>
                <Card
                  background="white-shadow"
                  title="Access by Entity"
                  withHeader
                  hideContent={!showThirdCard}
                  description="Defines which entities this access group will have access to"
                  icon={<LockM />}
                  iconColor={"#AEAEAE"}
                  headerSideChildren={
                    <SideButtonsContainer>
                      <Button onClick={() => setShowThirdCard(!showThirdCard)} type="secondary">
                        {showThirdCard ? "Hide" : "Show"}
                      </Button>
                    </SideButtonsContainer>
                  }
                >
                  <ModuleStyled.InputWrapper>
                    <Field name="entities">
                      {({ input }) => (
                        <Select
                          {...input}
                          mode="multiple"
                          optionFilterProp="children"
                          dropdownStyle={Styled.dropdownStyle}
                          notFoundContent={
                            <Result description="No results" noSearchResults type="no-results" />
                          }
                          placeholder="Select entities"
                        >
                          {allEntities.map((entity) => (
                            <Select.Option key={entity.id} value={entity.id}>
                              {entity.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </ModuleStyled.InputWrapper>
                </Card>
              </ModuleStyled.CardsWrapper>
            </ModuleStyled.MaxWidthContent>
          </form>
        )}
      </Form>
    </Layout>
  );
}
