import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding-bottom: 0.5rem;
`;

export const Event = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const EventDetail = styled.div``;

export const EventAuthor = styled.span`
  font-weight: bold;
`;

export const EventDescription = styled.span``;

export const EventDate = styled.span`
  color: #a7a7a7;
`;
