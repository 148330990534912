import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import * as Styled from "@/components/Table/styles";

import { KpiGroupService } from "../kpi-group.services";
import { KpiGroupResponse } from "../kpi-group.types";
import { kpiRoutes } from "../kpi.routes";
import { useKpiGroups } from "../library/hooks/useKpiGroups";
import { tableColumns } from "./kpi-groups.columns";
import { KPIGroupCreateModal } from "./KPIGroupCreateModal";
import { KPIGroupEditModal } from "./KPIGroupEditModal";
import { KPIGroupsTableUtils } from "./utils/KPIGroupsTable.utils";

export function KPIGroups() {
  const { groups, isRemoveKpiGroupLoading, removeKpiGroup } = useKpiGroups();
  const [isKPIGroupModalCreateOpen, setIsKPIGroupModalCreateOpen] = useState(false);
  const [isKPIGroupModalEditOpen, setIsKPIGroupModalEditOpen] = useState(false);
  const [editingKpiGroup, setEditingKpiGroup] = useState<KpiGroupResponse>();

  const handleRemoveKpiGroup = async (id: number) => {
    const onSuccess = () => {
      message.success("KPI Group removed");
    };

    const onError = (text: string) => {
      message.error(text);
    };

    await removeKpiGroup({
      id,
      onError,
      onSuccess,
    });
  };

  const handleEditKpiGroup = async (id: number) => {
    const kpiGroup = await KpiGroupService.fetchOne(id, false);
    setEditingKpiGroup(kpiGroup);
    setIsKPIGroupModalEditOpen(true);
  };

  const handleCloseEditKpiGroupModal = () => {
    setEditingKpiGroup(undefined);
    setIsKPIGroupModalEditOpen(false);
  };

  const handleCloseCreateKpiGroupModal = () => {
    setIsKPIGroupModalCreateOpen(false);
  };

  const tableData = useMemo(() => {
    return KPIGroupsTableUtils.mapKPIGroupsResponseToTableData(groups);
  }, [groups]);

  const columns = useMemo(() => {
    return tableColumns({
      isRemovingKPIGroup: isRemoveKpiGroupLoading,
      onRemoveKPIGroup: handleRemoveKpiGroup,
      onEditKPIGroup: handleEditKpiGroup,
    });
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: tableData,
  });

  const navigate = useNavigate();

  return (
    <>
      <Layout
        header={
          <PageHeader
            title="KPI Groups"
            onGoBack={() => navigate(kpiRoutes.dashboard)}
            rightSide={
              <>
                <Button type="primary" onClick={() => setIsKPIGroupModalCreateOpen(true)}>
                  Create KPI Group
                </Button>
              </>
            }
          />
        }
      >
        <Card>
          <Styled.Table {...getTableProps()}>
            <Styled.TableHead>
              {headerGroups.map((headerGroup) => (
                <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Styled.TableHeader {...column.getHeaderProps()}>
                      <Styled.TableCellWithFiltersWrapper>
                        {column.render("Header")}
                      </Styled.TableCellWithFiltersWrapper>
                    </Styled.TableHeader>
                  ))}
                </Styled.TableHeaderRow>
              ))}
            </Styled.TableHead>
            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Styled.TableBodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Styled.TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Styled.TableCell>
                      );
                    })}
                  </Styled.TableBodyRow>
                );
              })}
            </Styled.TableBody>
          </Styled.Table>
        </Card>
        <KPIGroupCreateModal
          visible={isKPIGroupModalCreateOpen}
          onClose={handleCloseCreateKpiGroupModal}
        />
        {editingKpiGroup && (
          <KPIGroupEditModal
            editingKpiGroup={editingKpiGroup}
            visible={isKPIGroupModalEditOpen}
            onClose={handleCloseEditKpiGroupModal}
          />
        )}
      </Layout>
    </>
  );
}
