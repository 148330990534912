import { Field, Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";

import { useAuth } from "@/hooks/useAuth";
import { useValidationSchema } from "@/hooks/useValidationSchema";

import * as Styled from "../login.styles";
import { useLogin } from "../useLogin";

type RegisterFormValues = {
  fname: string;
  lname: string;
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
};

type RegisterParams = {
  token: string;
};

const loginSchema = yup.object().shape({
  fname: yup
    .string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(30, "First name is too long"),
  lname: yup
    .string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters")
    .max(30, "Last name is too long"),
  username: yup
    .string()
    .required("Username is required")
    .min(3, "Username must be at least 3 characters")
    .max(20, "Username is too long"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(40, "Password must be less than 40 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      "Password must contain at least one special character"
    ),
  password_confirmation: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export function Register() {
  const { token } = useParams<RegisterParams>();
  const validate = useValidationSchema(loginSchema);
  const navigate = useNavigate();
  const { logoutUser } = useAuth();
  const { registerUser, registerUserLoading } = useLogin();

  if (!token) {
    message.error("Token not found");
    navigate("/login");
  }

  async function onSubmit(values: RegisterFormValues) {
    if (!token) {
      message.error("Token not found");
      navigate("/login");
      return;
    }

    const onSuccess = () => {
      message.success("User registered successfully");
      navigate("/login");
    };

    const onError = (text: string) => {
      message.error(text);
      logoutUser();
      navigate("/login");
    };

    await registerUser({
      payload: values,
      token,
      onSuccess,
      onError,
    });
  }

  return (
    <Form<RegisterFormValues>
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        return (
          <Styled.Form onSubmit={handleSubmit}>
            <Styled.CardContainer>
              <Card title="Register" withHeader>
                <Field
                  name="fname"
                  render={({ input, meta }) => (
                    <Input {...input} label="First name" errorText={meta.touched && meta.error} />
                  )}
                />

                <Field
                  name="lname"
                  render={({ input, meta }) => (
                    <Input {...input} label="Last name" errorText={meta.touched && meta.error} />
                  )}
                />

                <Field
                  name="username"
                  render={({ input, meta }) => (
                    <Input {...input} label="Username" errorText={meta.touched && meta.error} />
                  )}
                />

                <Field
                  name="email"
                  render={({ input, meta }) => (
                    <Input
                      type="email"
                      {...input}
                      label="Email"
                      errorText={meta.touched && meta.error}
                    />
                  )}
                />

                <Field
                  name="password"
                  render={({ input, meta }) => (
                    <Input
                      type="password"
                      {...input}
                      label="Password"
                      errorText={meta.touched && meta.error}
                    />
                  )}
                />

                <Field
                  name="password_confirmation"
                  render={({ input, meta }) => (
                    <Input
                      type="password"
                      {...input}
                      label="Confirm Password"
                      errorText={meta.touched && meta.error}
                    />
                  )}
                />

                <Styled.FooterButtonsContainer>
                  <Button onClick={() => navigate("/login")}>Back to Login</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={registerUserLoading}
                    disabled={registerUserLoading}
                  >
                    Register
                  </Button>
                </Styled.FooterButtonsContainer>
              </Card>
            </Styled.CardContainer>
          </Styled.Form>
        );
      }}
    />
  );
}
