import styled from "styled-components";

export const Container = styled.div`
  padding: 1.5rem;
  overflow-y: scroll;
`;

export const TitleContainer = styled.div`
  display: flex;
  padding: 3rem 0;
`;

export const CardsGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
`;

export const ModulesContainer = styled.div`
  margin-bottom: 4rem;
`;

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
