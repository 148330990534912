import Icon, { CompanyM, GroupM, FolderArchiveM } from "@synerise/ds-icon";

import * as Styled from "@/components/Table/styles";

export type EntityTypes = "Company" | "Collection" | "Group";

export type EntityTypeCellProps = {
  type: EntityTypes | null;
};

function selectComponent(type: EntityTypes | null) {
  switch (type) {
    case "Company":
      return CompanyM;
    case "Collection":
      return GroupM;
    case "Group":
      return FolderArchiveM;
    default:
      return null;
  }
}

export function EntityTypeCell({ type }: EntityTypeCellProps) {
  const EntityComponent = selectComponent(type);

  return (
    <Styled.TableCellWithIcon>
      <Icon component={EntityComponent && <EntityComponent />} size={30} color="#006BF8" />
      {type}
    </Styled.TableCellWithIcon>
  );
}
