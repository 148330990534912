import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import produce from "immer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "@synerise/ds-card";
import PageHeader from "@synerise/ds-page-header";

import * as TableStyled from "@/components/Table/styles";
import { theme } from "@/styles/theme";

import { ForecastDrawer } from "../../components/ForecastDrawer";
import { columns } from "./columns";
import { ForecastMetric } from "./components/ForecastMetric";
import { generateTableData } from "./mock/table";
import * as Styled from "./styles";
import { Group, ID } from "./types";

export function ForecastView() {
  const [tableData, setTableData] = useState<Group[]>([...generateTableData().groups]);
  const [isForecastDrawerOpen, setIsForecastDrawerOpen] = useState(false);
  const [rowSelected, setRowSelected] = useState<ID | null>(null);

  const [expanded, setExpanded] = useState<ExpandedState>(() => {
    if (!tableData) return {};
    const hash: Record<string, boolean> = {};
    if (tableData.length > 0) {
      tableData.forEach((_: any, index: number) => {
        hash[index] = true;
      });
    }
    return hash;
  });

  const navigate = useNavigate();

  const handleTitleClick = (rowId: ID) => {
    setRowSelected(rowId);
    setIsForecastDrawerOpen(true);
  };

  const handleForecastDrawerClose = () => {
    setRowSelected(null);
    setIsForecastDrawerOpen(false);
  };

  const handleMetricChange = (id: ID, oldPercentValue: number, newPercentValue: number) => {
    const index = tableData.findIndex((group) => group.id === id);

    if (index < 0) return;

    const nextState = produce(tableData, (draftState) => {
      draftState[index].periods = draftState[index].periods.map((period) => {
        if (!period.isForecast) return period;
        return {
          ...period,
          value: Number(
            ((period.value / (1 + oldPercentValue / 100)) * (1 + newPercentValue / 100)).toFixed(2)
          ),
        };
      });
    });

    setTableData(nextState);
  };

  const tableInstance = useReactTable({
    data: tableData,
    columns: columns({
      periods: generateTableData().groups[0].periods,
      onTitleClick: handleTitleClick,
    }),
    state: {
      expanded,
    },
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <>
      <PageHeader title="Forecast" onGoBack={() => navigate("/forecast")} />
      <Styled.Container className="forecast-overview-wrapper">
        <Card>
          <Styled.MetricsContainer>
            {/* Row Level */}
            {tableData
              .filter((group) => group.subRows && group.subRows.length > 0)
              .map((group) => (
                <ForecastMetric
                  id={group.id}
                  label={group.title}
                  onMetricChange={handleMetricChange}
                />
              ))}
          </Styled.MetricsContainer>
        </Card>
        <Card
          style={{
            overflowY: "scroll",
            maxHeight: "calc(100vh - 300px)",
            padding: theme.spacing.m,
            paddingTop: 0,
          }}
          withoutPadding
        >
          <TableStyled.Table style={{ position: "relative" }}>
            <TableStyled.TableHead
              style={{ position: "sticky", top: "0", backgroundColor: theme.colors.white }}
            >
              {tableInstance.getHeaderGroups().map((headerGroup) => (
                <TableStyled.TableHeaderRow key={headerGroup.id}>
                  {console.log(headerGroup)}
                  {headerGroup.headers.map((header) => (
                    <TableStyled.TableHeaderCenter
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        textAlign: header.id === "title" ? "left" : "center",
                        ...(headerGroup.depth === 0
                          ? { paddingTop: theme.spacing.s }
                          : { paddingBottom: theme.spacing.s }),
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableStyled.TableHeaderCenter>
                  ))}
                </TableStyled.TableHeaderRow>
              ))}
            </TableStyled.TableHead>
            <TableStyled.TableBody style={{ marginTop: "0.5rem" }}>
              {tableInstance.getRowModel().rows.map((row) => (
                <TableStyled.TableBodyRow
                  key={row.id}
                  style={{
                    borderBottom:
                      rowSelected === row.id
                        ? `2px solid ${theme.colors.blue_500}`
                        : "2px solid #f2f2f2",
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const meta: any | undefined = cell.column.columnDef.meta;
                    return (
                      <TableStyled.TableCell
                        key={cell.id}
                        style={{
                          textAlign: cell.column.id === "title" ? "left" : "center",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          fontWeight:
                            cell.column.id === "title" && cell.row.depth < 1 ? "bold" : "normal",
                          paddingLeft:
                            cell.column.id === "title" ? `${cell.row.depth * 16 + 4}px` : "4px",
                        }}
                        darker={meta?.isForecast}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableStyled.TableCell>
                    );
                  })}
                </TableStyled.TableBodyRow>
              ))}
            </TableStyled.TableBody>
          </TableStyled.Table>
        </Card>
        <ForecastDrawer isOpen={isForecastDrawerOpen} onClose={() => handleForecastDrawerClose()} />
      </Styled.Container>
    </>
  );
}
