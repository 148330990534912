import styled from "styled-components";

export const Container = styled.label`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadContainer = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 60px;
  height: 100%;
  max-height: 60px;
`;
