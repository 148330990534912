import { KPITableContent } from "./KPITableContent";
import { KPIsTableProvider } from "./useKPIsTable";

export function KPITable() {
  return (
    <KPIsTableProvider>
      <KPITableContent />
    </KPIsTableProvider>
  );
}
