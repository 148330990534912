import { EditItemDrawerStatisticCardContent } from "../../EditItemDrawer/contents/EditItemDrawerStatisticCardContent";
import { ItemConfig, ItemLayout } from "../../LayoutBuilder.types";

export type StatisticCardData = {
  title: string;
  subtitle: string;
  total: number;
  goal: number;
};

type GetStatisticCardConfig = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>) => void;
};

export function getStatisticCardConfig({
  editingItem,
  updateItem,
}: GetStatisticCardConfig): ItemConfig<StatisticCardData> {
  return {
    name: "statisticCard",
    editDrawerComponent: (
      <EditItemDrawerStatisticCardContent editingItem={editingItem} updateItem={updateItem} />
    ),
    resizeHandles: ["e"],
    initialLayout: {
      w: 3,
      h: 4,
      type: "statisticCard",
      minW: 3,
      minH: 4,
      maxW: 4,
      maxH: 4,
      resizeHandles: [],
      data: {
        title: "Statistic Card Title",
        subtitle: "This Quarter",
        total: 251800,
        goal: 271100,
      },
    },
  };
}
