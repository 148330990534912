import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import message from "@synerise/ds-message";

import { queryClient } from "@/utils/queryClient";

import { LEDGERS_LINE_COMMENTS_QUERY_KEY } from "../../../ledgers.config";
import { TrialBalanceVersionLineCommentsServices } from "../../../trialbalance-version-line-comments.services";
import { TrialBalanceCommentResponse } from "../../../trialbalance-version-line-comments.types";

async function createComment(
  trialBalanceId: number,
  versionId: number,
  lineId: number,
  { text }: { text: string }
) {
  const payload = {
    comment: {
      text,
    },
  };

  return await TrialBalanceVersionLineCommentsServices.create(
    trialBalanceId,
    versionId,
    lineId,
    payload
  );
}

type UseTrialBalanceCommentsProps = {
  trialBalanceId: number;
  versionId: number;
  lineId: number;
};

export function useTrialBalanceComments({
  lineId,
  trialBalanceId,
  versionId,
}: UseTrialBalanceCommentsProps) {
  const {
    data,
    isLoading: isLoadingComments,
    error,
  } = useQuery<TrialBalanceCommentResponse[], any>(
    [LEDGERS_LINE_COMMENTS_QUERY_KEY, trialBalanceId, versionId, lineId],
    async () =>
      await TrialBalanceVersionLineCommentsServices.fetchAll(trialBalanceId, versionId, lineId)
  );

  const comments = useMemo(() => {
    if (error && error.response && error.response.status === 404) return [];
    return data || [];
  }, [data, error]);

  const createCommentMutation = useMutation(
    [LEDGERS_LINE_COMMENTS_QUERY_KEY, trialBalanceId, versionId, lineId],
    async (payload: { text: string }) =>
      await createComment(trialBalanceId, versionId, lineId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          LEDGERS_LINE_COMMENTS_QUERY_KEY,
          trialBalanceId,
          versionId,
          lineId,
        ]);
        queryClient.invalidateQueries([
          LEDGERS_LINE_COMMENTS_QUERY_KEY,
          trialBalanceId,
          versionId,
          lineId,
        ]);
      },
    }
  );

  const removeCommentMutation = useMutation(
    [LEDGERS_LINE_COMMENTS_QUERY_KEY, trialBalanceId, versionId, lineId],
    async ({ commentId }: { commentId: number }) =>
      await TrialBalanceVersionLineCommentsServices.delete(
        trialBalanceId,
        versionId,
        lineId,
        commentId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          LEDGERS_LINE_COMMENTS_QUERY_KEY,
          trialBalanceId,
          versionId,
          lineId,
        ]);
        queryClient.invalidateQueries([
          LEDGERS_LINE_COMMENTS_QUERY_KEY,
          trialBalanceId,
          versionId,
          lineId,
        ]);
        message.info("Comment removed");
      },
    }
  );

  const handleCreateComment = async (text: string) => {
    await createCommentMutation.mutateAsync({ text });
  };

  const handleRemoveComment = async (commentId: number) => {
    await removeCommentMutation.mutateAsync({
      commentId,
    });
  };

  return {
    handleCreateComment,
    handleRemoveComment,
    comments,
    isLoadingComments,
  };
}
