import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { queryClient } from "@/utils/queryClient";

import { AccountMappingServices } from "../account-mapping.services";
import { ChartMapCreatePayload, ChartMapResponse } from "../account-mapping.types";

const ACCOUNTS_MAPPING_QUERY_KEY = "accounts-mapping";

type UseAccountsMappingReturn = {
  allAccountsMapping: ChartMapResponse[];
  accountsMappingLoading: boolean;
  createAccountMapping: (props: CreateAccountMappingProps) => Promise<void>;
  createAccountMappingLoading: boolean;
  removeAccountMapping: (props: RemoveAccountMappingProps) => Promise<void>;
  removeAccountMappingLoading: boolean;
};

type CreateAccountMappingProps = {
  payload: ChartMapCreatePayload;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

type RemoveAccountMappingProps = {
  chartMappingId: number;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

async function getAllAccountsMapping() {
  try {
    return await AccountMappingServices.fetchAllMappedCharts();
  } catch (err: any) {
    if (err.response?.status === 404) {
      return [];
    }
    throw new Error("Error while fetching accounts mapping");
  }
}

async function handleCreateAccountMapping(payload: ChartMapCreatePayload) {
  try {
    return await AccountMappingServices.createMappedChart(payload);
  } catch (err: any) {
    throw new Error("Error while creating accounts mapping");
  }
}

async function handleRemoveAccountMapping(chartMappingId: number) {
  try {
    return await AccountMappingServices.deleteMappedChart(chartMappingId);
  } catch (err: any) {
    if (err.response?.status === 404) {
      return null;
    }
    throw new Error("Error while removing accounts mapping");
  }
}

export function useAccountsMapping(): UseAccountsMappingReturn {
  const allAccountsMappingFromQuery = useQuery(
    ACCOUNTS_MAPPING_QUERY_KEY,
    async () => await getAllAccountsMapping()
  );

  const createAccountMappingMutation = useMutation(
    ACCOUNTS_MAPPING_QUERY_KEY,
    async ({ payload }: CreateAccountMappingProps) => await handleCreateAccountMapping(payload),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(ACCOUNTS_MAPPING_QUERY_KEY);
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        onError(err.message);
      },
    }
  );

  const removeAccountMappingMutation = useMutation(
    [ACCOUNTS_MAPPING_QUERY_KEY],
    async ({ chartMappingId }: RemoveAccountMappingProps) => {
      return await handleRemoveAccountMapping(chartMappingId);
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(ACCOUNTS_MAPPING_QUERY_KEY);
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        queryClient.invalidateQueries(ACCOUNTS_MAPPING_QUERY_KEY);
        onError(err.message);
      },
    }
  );

  const removeAccountMapping = async (props: RemoveAccountMappingProps) => {
    await removeAccountMappingMutation.mutateAsync(props);
  };

  const allAccountsMapping = useMemo(() => {
    return allAccountsMappingFromQuery.data ?? [];
  }, [allAccountsMappingFromQuery.data]);

  const accountsMappingLoading = useMemo(() => {
    return allAccountsMappingFromQuery.isLoading;
  }, [allAccountsMappingFromQuery.isLoading]);

  const createAccountMapping = async (props: CreateAccountMappingProps) => {
    await createAccountMappingMutation.mutateAsync(props);
  };

  const createAccountMappingLoading = useMemo(() => {
    return createAccountMappingMutation.isLoading;
  }, [createAccountMappingMutation.isLoading]);

  return {
    allAccountsMapping,
    accountsMappingLoading,
    createAccountMapping,
    createAccountMappingLoading,
    removeAccountMapping,
    removeAccountMappingLoading: removeAccountMappingMutation.isLoading,
  };
}
