import { ChartResponse } from "../../ChartOfAccounts/chart.types";
import { ChartMapResponse } from "../account-mapping.types";
import { AccountsMappingTableData } from "../table-columns";

export function mapMappedChartsToTable(
  chartMaps: ChartMapResponse[],
  allCharts: ChartResponse[]
): AccountsMappingTableData[] {
  let mappedTableData: AccountsMappingTableData[] = [];

  chartMaps.forEach((chartMap) => {
    const mappedChart = allCharts.find((chart) => chart.id === chartMap.mapped_chart_id);
    const baseChart = allCharts.find((chart) => chart.id === chartMap.source_chart_id);

    if (mappedChart && baseChart) {
      mappedTableData.push({
        chartMappingId: chartMap.id,
        baseAccount: {
          id: baseChart.id,
          name: baseChart.title,
        },
        mappedAccount: {
          id: mappedChart.id,
          name: mappedChart.title,
        },
      });
    }
  });

  return mappedTableData;
}
