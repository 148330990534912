import { useQuery } from "react-query";

import { EntityTypesService } from "@/modules/data-management/entities/entities.services";
import { EntityType } from "@/modules/data-management/entities/entities.types";

const ENTITY_TYPES_QUERY_KEY = "entity-types";

async function getAllEntityTypes() {
  return await EntityTypesService.list();
}

type UseEntityTypesReturn = {
  allEntityTypes: EntityType[];
  allEntityTypesLoading: boolean;
};

export function useEntityTypes(): UseEntityTypesReturn {
  const allEntityTypesFromQuery = useQuery(ENTITY_TYPES_QUERY_KEY, () => getAllEntityTypes());

  const allEntityTypes = allEntityTypesFromQuery.data;

  return {
    allEntityTypes: allEntityTypes ?? [],
    allEntityTypesLoading: allEntityTypesFromQuery.isLoading,
  };
}
