import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRowSelect, useSortBy, useTable, useFilters } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { FilterM } from "@synerise/ds-icon";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";

import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import { EmptyTable } from "@/components/Table/EmptyTable";
import { SortIcon } from "@/components/Table/SortIcon";
import * as Styled from "@/components/Table/styles";
import {
  mapChartOfAccountsListToTable,
  useChartOfAccounts,
} from "@/hooks/useChartOfAccounts";

import { dataManagementData } from "../../data-management.data";
import { tableColumns } from "./chart-of-accounts-table-columns";

export function ChartOfAccountsTable() {
  const navigate = useNavigate();
  const { allChartOfAccounts, chartOfAccountsLoading, removeChartOfAccounts } =
    useChartOfAccounts();

  const onEditChartOfAccounts = (id: number) => {
    navigate(dataManagementData.chartOfAccounts.editPath(id));
  };

  const onRemoveChartOfAccounts = async (id: number, name: string) => {
    function onSuccess() {
      message.success(`Chart of accounts: ${name} was removed!`);
    }

    function onError(text: string) {
      message.error(text);
    }

    await removeChartOfAccounts({ id, onSuccess, onError });
  };

  const data = useMemo(() => {
    return mapChartOfAccountsListToTable(allChartOfAccounts);
  }, [allChartOfAccounts]);

  const columns = useMemo(() => {
    return tableColumns({
      onEditChartOfAccounts,
      onRemoveChartOfAccounts,
    });
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultCanSort: false,
      defaultCanFilter: false,
    },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  return (
    <Card
      title="Chart of Accounts"
      withHeader
      headerSideChildren={
        <Button mode="single-icon" type="ghost-white">
          <Icon color="#C4C4C4" component={<FilterM />} />
        </Button>
      }
    >
      {chartOfAccountsLoading ? (
        <Loader label="Loading chart of accounts. Just a second" labelPosition="bottom" />
      ) : rows.length < 1 ? (
        <EmptyTable
          path={dataManagementData.chartOfAccounts.createPath}
          text="No chart of accounts found"
          buttonText="Create a new chart of accounts"
        />
      ) : (
        <Styled.Table {...getTableProps()}>
          <Styled.TableHead>
            {headerGroups.map((headerGroup) => (
              <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Styled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                      {column.canFilter ? column.render("Filter") : null}
                      {column.canSort && (
                        <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                      )}
                    </Styled.TableCellWithFiltersWrapper>
                  </Styled.TableHeader>
                ))}
              </Styled.TableHeaderRow>
            ))}
          </Styled.TableHead>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Styled.TableBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Styled.TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Styled.TableCell>
                    );
                  })}
                </Styled.TableBodyRow>
              );
            })}
          </Styled.TableBody>
        </Styled.Table>
      )}
    </Card>
  );
}
