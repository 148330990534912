import Button from "@synerise/ds-button";
import Icon, { DirectionDownM, DirectionUpM, ArrangeM } from "@synerise/ds-icon";

type SortIconProps = {
  isSorted?: boolean;
  isSortedDesc?: boolean;
};

export function SortDateIcon({ isSorted, isSortedDesc }: SortIconProps) {
  if (!isSorted) {
    return (
      <Button mode="single-icon" type="ghost-white">
        <Icon color="#C4C4C4" component={<ArrangeM />} />
      </Button>
    );
  }

  if (isSortedDesc) {
    return (
      <Button mode="single-icon" type="ghost-white">
        <Icon color="#C4C4C4" component={<DirectionDownM />} />
      </Button>
    );
  }

  return (
    <Button mode="single-icon" type="ghost-white">
      <Icon color="#C4C4C4" component={<DirectionUpM />} />
    </Button>
  );
}
