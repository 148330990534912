import { Link } from "react-router-dom";

import Button from "@synerise/ds-button";
import Icon, { TrashM, EditM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { DynamicTags } from "@/components/Table/Cells/DynamicTags";
import { UserCell } from "@/components/Table/Cells/UserCell";
import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as TableStyled from "@/components/Table/styles";

export type ReportsListTableData = {
  id: number;
  name: string;
  description: string;
  markedTags: string[];
};

type CellProps = {
  row: {
    original: ReportsListTableData;
  };
};

type TableColumnProps = {
  onRemove: (id: number, name: string) => Promise<void>;
  onEdit: (id: number) => void;
  onView: (id: number) => void;
};

export const tableColumns = ({ onRemove, onEdit, onView }: TableColumnProps) => [
  {
    Header: "Name",
    accessor: "name",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }: CellProps) => (
      <Link to={`/reports/builder/${row.original.id}`}>
        <UserCell name={row.original.name} />
      </Link>
    ),
  },
  {
    Header: "Description",
    accessor: "description",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }: CellProps) => <UserTypeCell type={row.original.description} />,
  },
  {
    Header: "Tags",
    accessor: "markedTags",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }: CellProps) => (
      <DynamicTags noContentMessage="No Tags" tags={row.original.markedTags} />
    ),
  },
  {
    Header: "",
    accessor: "actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }: CellProps) => (
      <TableStyled.RowCellActionButtons>
        <Button onClick={() => onView(row.original.id)} type="ghost">
          View
        </Button>
        <Button mode="single-icon" type="ghost" onClick={() => onEdit(row.original.id)}>
          <Icon color="#C4C4C4" component={<EditM />} />
        </Button>
        <Popconfirm
          title={`Are you sure you want to delete ${row.original.name}?`}
          onConfirm={() => onRemove(row.original.id, row.original.name)}
          cancelText="No"
          okText="Yes"
          okButtonProps={{
            danger: true,
          }}
        >
          <Button mode="single-icon" type="custom-color-ghost" color="red">
            <Icon color="#C4C4C4" component={<TrashM />} />
          </Button>
        </Popconfirm>
      </TableStyled.RowCellActionButtons>
    ),
  },
];
