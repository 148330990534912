import { Input } from "@synerise/ds-input";
import DSSelect from "@synerise/ds-select";

import { mockChartGroups } from "@/components/LayoutBuilder/GridItems/GridItemsChart.mock.data";

type EditItemDrawerChartContentDataTabProps = {
  selectedChartFirstSeriesName: string;
  onChangeChartFirstSeriesName: (seriesName: string) => void;
  selectedChartTitle: string;
  onChangeChartTitle: (title: string) => void;
};

export function EditItemDrawerChartContentDataTab({
  selectedChartFirstSeriesName,
  selectedChartTitle,
  onChangeChartFirstSeriesName,
  onChangeChartTitle,
}: EditItemDrawerChartContentDataTabProps) {
  return (
    <>
      <span style={{ fontWeight: "bold" }}>Data Chart</span>
      <Input
        label="Title"
        value={selectedChartTitle}
        onChange={(event: any) => onChangeChartTitle(event.target.value)}
      />
      <DSSelect
        label="Series 1"
        value={selectedChartFirstSeriesName}
        showSearch
        allowClear
        optionFilterProp=""
        onSelect={(value: any) => onChangeChartFirstSeriesName(value)}
      >
        {mockChartGroups.map((group) => (
          <DSSelect.OptGroup key={group.groupName} label={group.groupName}>
            {group.groupOptions.map((option) => (
              <DSSelect.Option key={option.optionName} value={option.optionValue}>
                {option.optionName}
              </DSSelect.Option>
            ))}
          </DSSelect.OptGroup>
        ))}
      </DSSelect>
    </>
  );
}
