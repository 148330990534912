import { KPITabsTable } from "./KPITabsTable";
import { KpisTabTableProvider } from "./useKpiTabsTable";

export function KpiTabs() {
  return (
    <KpisTabTableProvider>
      <KPITabsTable />
    </KpisTabTableProvider>
  );
}
