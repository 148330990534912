import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useRowSelect, useSortBy, Column } from "react-table";
import styled from "styled-components";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { FilterM } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import { EmptyTable } from "@/components/Table/EmptyTable";
import { SortIcon } from "@/components/Table/SortIcon";
import * as Styled from "@/components/Table/styles";
import { useCan } from "@/core/control/useCan";
import { useAccessGroups } from "@/hooks/useAccessGroups";
import { dataManagementData } from "@/modules/data-management/data-management.data";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";
import { mapMembersToTableData, useMembers } from "./useMembers";
import { MembersTableTableData, tableColumns } from "./users.table.columns";
import { UserInvitationModal } from "./UsersInvitation";
import { UserInvitedModal } from "./UsersInvitedModal";



export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export function Users() {
  const navigate = useNavigate();
  const [invitationModalVisible, setInvitationModalVisible] = useState(false);
  const [userInvitedModalVisible, setUserInvitedModalVisible] = useState(false);

  const onGetMembersError = (errorMessage: string) => {
    message.error(errorMessage);
    navigate("/settings/home");
  };

  const { members, membersLoading, removeMember } = useMembers({
    onError: onGetMembersError,
  });

  const { allAccessGroups } = useAccessGroups();
  const canRemoveUser = useCan({
    permissions: ["users:delete"],
  });

  const data = useMemo(() => {
    return mapMembersToTableData(members, allAccessGroups);
  }, [members, allAccessGroups]);

  const onRemoveMember = async (id: number, name: string) => {
    const onSuccess = () => {
      message.success(`User ${name} has been removed.`);
    };

    const onError = (errorMessage: string) => {
      message.error(errorMessage);
    };

    removeMember({
      id,
      onSuccess,
      onError,
    });
  };

  const columns = useMemo(() => {
    return tableColumns({
      canRemove: canRemoveUser,
      onRemoveMember,
    }) as Column<MembersTableTableData>[];
  }, [canRemoveUser]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy,
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  const handleInviteNewUser = () => {
    setInvitationModalVisible(true);
    setUserInvitedModalVisible(false);
  };

  const userInvitedSuccess = () => {
    // TODO: Atualizar lista de users (otimista?)
    message.success("User has been invited");
    setInvitationModalVisible(false);
    setUserInvitedModalVisible(true);
  };

  return (
    <Layout
      left={LeftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate("/settings/home")}
          title="Users"
          rightSide={
            <>
              <SideButtonsContainer>
                <Button onClick={() => setInvitationModalVisible(true)} type="primary">
                  Invite New User
                </Button>
              </SideButtonsContainer>
            </>
          }
        />
      }
    >
      <Card
        title="Users"
        withHeader
        headerSideChildren={
          <Button mode="single-icon" type="ghost-white">
            <Icon color="#C4C4C4" component={<FilterM />} />
          </Button>
        }
      >
        {membersLoading ? (
          <Loader label="Loading members..." labelPosition="bottom" />
        ) : rows.length < 1 ? (
          <EmptyTable
            path={dataManagementData.entities.createPath}
            text="No members found"
            buttonText="Invite new members"
          />
        ) : (
          <Styled.Table {...getTableProps()}>
            <Styled.TableHead>
              {headerGroups.map((headerGroup) => (
                <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <Styled.TableCellWithFiltersWrapper>
                        {column.render("Header")}
                        {column.canSort && (
                          <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                        )}
                      </Styled.TableCellWithFiltersWrapper>
                    </Styled.TableHeader>
                  ))}
                </Styled.TableHeaderRow>
              ))}
            </Styled.TableHead>

            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Styled.TableBodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Styled.TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Styled.TableCell>
                      );
                    })}
                  </Styled.TableBodyRow>
                );
              })}
            </Styled.TableBody>
          </Styled.Table>
        )}
      </Card>
      <UserInvitationModal
        visible={invitationModalVisible}
        setVisible={setInvitationModalVisible}
        onSuccess={userInvitedSuccess}
      />
      <UserInvitedModal
        visible={userInvitedModalVisible}
        setVisible={setUserInvitedModalVisible}
        inviteNewUser={handleInviteNewUser}
      />
    </Layout>
  );
}
