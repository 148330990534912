import { formatDistanceToNow } from "date-fns";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { queryClient } from "@/utils/queryClient";

import { AccessGroup } from "../access-groups/access-groups.types";
import { MembersService } from "./users.services";
import { MembersTableTableData } from "./users.table.columns";
import { Member, MemberInvitationPayload } from "./users.types";

export type MemberView = Member & {
  fullName?: string;
};

type UseMembersReturn = {
  members: MemberView[];
  membersLoading: boolean;
  inviteMember: (props: InviteMemberProps) => Promise<void>;
  inviteMemberLoading: boolean;
  removeMember: (props: RemoveMemberProps) => Promise<void>;
  removeMemberLoading: boolean;
};

type UseMembersProps = {
  onError: (errorMessage: string) => void;
};

type InviteMemberProps = {
  payload: MemberInvitationPayload;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

type RemoveMemberProps = {
  id: number;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

export const MEMBERS_QUERY_KEY = "members";

export function mapMembersToTableData(
  members: Member[],
  accessGroupList: AccessGroup[]
): MembersTableTableData[] {
  return members.map((member) => mapMemberToTableData(member, accessGroupList));
}

function mapMemberToTableData(
  member: Member,
  accessGroupList: AccessGroup[]
): MembersTableTableData {
  const mappedRoles =
    member.access_groups_ids?.map((id) => {
      const access_group = accessGroupList.find((accessGroup) => accessGroup.id === id);
      return access_group?.title ?? "";
    }) ?? [];

  return {
    id: member.id,
    name: member.fname || "(no name)",
    avatar: member.preferences?.logo_url,
    email: member.email,
    roles: mappedRoles,
    status: member.is_active ?? false,
    created_at: `${formatDistanceToNow(new Date(member.created_at))} ago`,
  };
}

async function handleGetMembers() {
  return MembersService.getMembers();
}

async function handleInviteMember(payload: MemberInvitationPayload) {
  try {
    return await MembersService.inviteMembers(payload);
  } catch (err: any) {
    if (err && err.response && err.response.data && err.response.data.detail) {
      throw new Error(err.response.data.detail);
    }

    throw new Error("Error removing member");
  }
}

async function handleRemoveMember(id: number) {
  try {
    return await MembersService.deleteMember(id);
  } catch (err: any) {
    if (err && err.response && err.response.data && err.response.data.detail) {
      throw new Error(err.response.data.detail);
    }

    throw new Error("Error removing member");
  }
}

export const useMembers: (props?: UseMembersProps) => UseMembersReturn = (props) => {
  const membersFromQuery = useQuery(MEMBERS_QUERY_KEY, handleGetMembers, {
    onError: (error: any) => {
      if (props?.onError) {
        if (error.response && error.response.data && error.response.data.message) {
          props.onError(error.message);
          return;
        }
        props.onError("There was an error fetching the members.");
      }
    },
  });

  const inviteMemberMutation = useMutation(
    MEMBERS_QUERY_KEY,
    async ({ payload }: InviteMemberProps) => await handleInviteMember(payload),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(MEMBERS_QUERY_KEY);
        onSuccess();
      },
      onError: (error: any, { onError }) => {
        onError(error.message);
      },
    }
  );

  const removeMemberMutation = useMutation(
    MEMBERS_QUERY_KEY,
    async ({ id }: RemoveMemberProps) => {
      await handleRemoveMember(id);
    },
    {
      onMutate: ({ id, onSuccess }) => {
        queryClient.cancelQueries(MEMBERS_QUERY_KEY);

        const previousMembers = queryClient.getQueryData<Member[]>(MEMBERS_QUERY_KEY);

        if (!previousMembers) {
          onSuccess();
          return;
        }

        const newMembers = previousMembers.filter((member) => member.id !== id);

        queryClient.setQueryData(MEMBERS_QUERY_KEY, newMembers);

        onSuccess();

        return {
          previousMembers,
        };
      },
      onError: (error: any, { onError }, context: any) => {
        if (context.previousMembers) {
          queryClient.setQueryData(MEMBERS_QUERY_KEY, context.previousMembers);
        } else {
          queryClient.invalidateQueries(MEMBERS_QUERY_KEY);
        }

        onError(error.message);
      },
    }
  );

  const members = useMemo(() => {
    return membersFromQuery.data
      ? membersFromQuery.data.map((member) => {
          const fullName = `${member.fname} ${member.lname}`;
          return {
            ...member,
            fullName: fullName,
          };
        })
      : [];
  }, [membersFromQuery.data]);

  const inviteMember = async (props: InviteMemberProps) => {
    await inviteMemberMutation.mutateAsync(props);
  };

  const removeMember = async (props: RemoveMemberProps) => {
    await removeMemberMutation.mutateAsync(props);
  };

  return {
    members,
    membersLoading: membersFromQuery.isLoading,
    inviteMember,
    inviteMemberLoading: inviteMemberMutation.isLoading,
    removeMember,
    removeMemberLoading: removeMemberMutation.isLoading,
  };
};
