import { KpiGroupResponse } from "../../kpi-group.types";
import { KPIGroupsTableData } from "../kpi-groups.columns";

export class KPIGroupsTableUtils {
  static mapKPIGroupsResponseToTableData(groups: KpiGroupResponse[]): KPIGroupsTableData[] {
    return groups.map((group) => ({
      id: group.id,
      title: group.title,
      description: group.description || "",
      is_shared: group.is_shared || false,
    }));
  }
}
