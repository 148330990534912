import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "@synerise/ds-card";

import * as TableStyled from "@/components/Table/styles";
import { theme } from "@/styles/theme";

import { driversMockData } from "../../mock/drivers";
import { inputsMockData } from "../../mock/inputs";
import { columns } from "./columns";
import * as Styled from "./styles";
import { Group } from "./types";

export function ForecastOverview() {
  const [driversData] = useState<Group[]>([...driversMockData.groups]);
  const [inputsData] = useState<Group[]>([...inputsMockData.groups]);
  const navigate = useNavigate();

  const tableInputs = useReactTable({
    data: inputsData,
    columns: columns({
      periods: driversMockData.groups[0].periods,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  const tableDrivers = useReactTable({
    data: driversData,
    columns: columns({
      periods: driversMockData.groups[0].periods,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  const handleBlockHeaderClick = (header: string) => {
    navigate(`/forecast/${header}`);
  };

  return (
    <Styled.Container className="forecast-overview-wrapper">
      <Card
        title={<span style={{ fontSize: theme.fontSize.m }}>INPUTS</span>}
        withHeader
        onHeaderClick={() => handleBlockHeaderClick("inputs")}
      >
        <TableStyled.Table>
          <TableStyled.TableHead>
            {tableInputs.getHeaderGroups().map((headerGroup) => (
              <TableStyled.TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableStyled.TableHeaderCenter
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ textAlign: header.id === "title" ? "left" : "center" }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableStyled.TableHeaderCenter>
                ))}
              </TableStyled.TableHeaderRow>
            ))}
          </TableStyled.TableHead>
          <TableStyled.TableBody style={{ marginTop: "0.5rem" }}>
            {tableInputs.getRowModel().rows.map((row) => (
              <TableStyled.TableBodyRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const meta: any | undefined = cell.column.columnDef.meta;
                  return (
                    <TableStyled.TableCell
                      key={cell.id}
                      style={{
                        textAlign: cell.column.id === "title" ? "left" : "center",
                        borderBottom: "1px solid #f2f2f2",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                      darker={meta?.isForecast}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableStyled.TableCell>
                  );
                })}
              </TableStyled.TableBodyRow>
            ))}
          </TableStyled.TableBody>
        </TableStyled.Table>
      </Card>
      <Card
        title={<span style={{ fontSize: theme.fontSize.m }}>DRIVERS</span>}
        withHeader
        onHeaderClick={() => handleBlockHeaderClick("drivers")}
      >
        <TableStyled.Table>
          <TableStyled.TableHead>
            {tableDrivers.getHeaderGroups().map((headerGroup) => (
              <TableStyled.TableHeaderRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableStyled.TableHeaderCenter
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ textAlign: header.id === "title" ? "left" : "center" }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableStyled.TableHeaderCenter>
                ))}
              </TableStyled.TableHeaderRow>
            ))}
          </TableStyled.TableHead>
          <TableStyled.TableBody style={{ marginTop: "0.5rem" }}>
            {tableDrivers.getRowModel().rows.map((row) => (
              <TableStyled.TableBodyRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const meta: any | undefined = cell.column.columnDef.meta;
                  return (
                    <TableStyled.TableCell
                      key={cell.id}
                      style={{
                        textAlign: cell.column.id === "title" ? "left" : "center",
                        borderBottom: "1px solid #f2f2f2",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                      }}
                      darker={meta?.isForecast}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableStyled.TableCell>
                  );
                })}
              </TableStyled.TableBodyRow>
            ))}
          </TableStyled.TableBody>
        </TableStyled.Table>
      </Card>
    </Styled.Container>
  );
}
