import { LOCALES } from "../locales";

const ptBR = {
  [LOCALES.PORTUGUESE]: {
    login: {
      email: "E-mail",
      password: "Senha",
      forgotPassword: "Esqueci minha senha",
      signIn: "Entrar",
      validation: {
        emailRequired: "E-mail é obrigatório",
        emailInvalid: "E-mail inválido",
        passwordRequired: "Senha é obrigatória",
      },
    },
  },
};

export default ptBR;
