import { CellProps, Hooks } from "react-table";

import Button from "@synerise/ds-button";
import DSDropdown from "@synerise/ds-dropdown";
import Icon, { OptionHorizontalM } from "@synerise/ds-icon";

import { KPITableData } from "../kpi-table.types";
import { KPIDropdownActions } from "../KPIitemDropdownActions";

export function addOptionsButtonToTable<D extends KPITableData>(hooks: Hooks<D>) {
  hooks.visibleColumns.push((columns) => [
    {
      Header: "",
      id: "options",
      disableFilters: true,
      defaultCanSort: false,
      defaultCanGroupBy: false,
      width: 0,
      Cell: ({ row }: CellProps<D>) => {
        if (row.isGrouped) return null;

        return (
          <DSDropdown
            overlay={<KPIDropdownActions kpi={row.original} />}
            overlayStyle={{
              boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.25)",
            }}
          >
            <Button mode="single-icon" type="ghost">
              <Icon component={<OptionHorizontalM />} />
            </Button>
          </DSDropdown>
        );
      },
    },
    ...columns,
  ]);
}
