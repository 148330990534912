import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { KPIService } from "../../kpi.services";
import { DataSource, KpiCreatePayload, KpiResponse, Periodicity } from "../../kpi.types";
import { KPITableData } from "../../table/kpi-table.types";
import { mapKPISToTableData } from "../../table/utils/mapKPIsToTableData";
import { KPIS_QUERY_KEY } from "./kpi.config";

type CreateKpiProps = {
  payload: KpiCreatePayload;
  onSuccess?: () => void;
  onError: (msg: string) => void;
};

type UseKpisReturn = {
  kpis: KpiResponse[];
  kpisToTableData: KPITableData[];
  kpisLoading: boolean;
  createKpi: (props: CreateKpiProps) => Promise<KpiResponse>;
  createKpiLoading: boolean;
};

type UseKpisProps = {
  show_values: boolean;
  periodicity?: Periodicity;
  start_date?: string;
  end_date?: string;
  data_sources?: DataSource[];
};

export function useKpis({
  show_values,
  periodicity,
  start_date,
  end_date,
  data_sources,
}: UseKpisProps): UseKpisReturn {
  const { data, isLoading } = useQuery(
    [KPIS_QUERY_KEY, show_values, periodicity, start_date, end_date, ...(data_sources || [])],
    () =>
      KPIService.fetchAll({
        show_values,
        periodicity,
        start_date,
        end_date,
        data_sources,
      })
  );

  const createKpiMutation = useMutation([KPIS_QUERY_KEY], async (payload: KpiCreatePayload) =>
    KPIService.create(payload)
  );

  const createKpi = async ({ payload, onError, onSuccess }: CreateKpiProps) => {
    return await createKpiMutation.mutateAsync(payload, {
      onError: (error: any) => onError(error.message),
      onSuccess,
    });
  };

  const kpis = useMemo(
    () => (data && data_sources ? mapKPISToTableData(data, data_sources) : []),
    [data]
  );

  return {
    kpis: data || [],
    kpisToTableData: kpis,
    kpisLoading: isLoading,
    createKpi,
    createKpiLoading: createKpiMutation.isLoading,
  };
}
