import { Field, Form } from "react-final-form";

import { Input } from "@synerise/ds-input";
import Modal from "@synerise/ds-modal";
import Select from "@synerise/ds-select";

import { useAuth } from "@/hooks/useAuth";
import { useMembers } from "@/modules/settings/users/useMembers";

import { useCreateBoard } from "../hooks/useCreateBoard";

type BoardModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

type FormFields = {
  title: string;
  description: string;
  members_ids: number[];
};

const formId = "form-board";

const initialValues: FormFields = {
  title: "",
  description: "",
  members_ids: [],
};

export function BoardModal({ isVisible, onClose }: BoardModalProps) {
  const { createBoard, isCreateBoardLoading } = useCreateBoard();
  const { selectedMembership } = useAuth();
  const { members } = useMembers();
  const membersOptions = members.filter((member) => member.id !== selectedMembership?.id);

  const onSubmit = async (values: FormFields) => {
    await createBoard(values);
    onClose();
  };

  return (
    <Form<FormFields> onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={(event) => handleSubmit(event)?.then(form.restart)} id={formId}>
          <Modal
            visible={isVisible}
            onCancel={() => {
              form.restart();
              onClose();
            }}
            okText="Create"
            title="Create a new board"
            onOk={() => {}}
            confirmLoading={isCreateBoardLoading}
            cancelButtonProps={{
              disabled: isCreateBoardLoading,
            }}
            okButtonProps={{
              form: formId,
              htmlType: "submit",
              disabled: isCreateBoardLoading,
            }}
          >
            <Field name="title" render={({ input }) => <Input {...input} label="Title" />} />
            <Field
              name="description"
              render={({ input }) => <Input {...input} label="Description" />}
            />
            <Field
              name="members_ids"
              render={({ input }) => (
                <Select {...input} label="Members" mode="multiple">
                  {membersOptions.map((member) => {
                    return <Select.Option value={member.id}>{member.fullName}</Select.Option>;
                  })}
                </Select>
              )}
            />
          </Modal>
        </form>
      )}
    </Form>
  );
}
