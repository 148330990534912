import styled from "styled-components";

import { theme } from "@/styles/theme";

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  width: max-content;
  gap: ${theme.spacing.s};
  text-align: right;

  .label {
    font-weight: bold;
  }

  .value {
    cursor: pointer;
  }
`;
