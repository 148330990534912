import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Column, useRowSelect, useSortBy, useTable, useFilters } from "react-table";

import Card from "@synerise/ds-card";
import Loader from "@synerise/ds-loader";

import { EmptyState } from "@/components/EmptyState";
import { SortIcon } from "@/components/Table/SortIcon";
import * as Styled from "@/components/Table/styles";

import { reportsData } from "../reports.config";
import { ReportsListTableData, tableColumns } from "./ReportsLibraryTableColumns";

type ReportsListTableProps = {
  reports: ReportsListTableData[];
  isLoading: boolean;
  onRemoveReport: (id: number) => Promise<void>;
  onEditReport: (id: number) => void;
  onViewReport: (id: number) => void;
};

export function ReportsListTable({
  reports,
  isLoading,
  onRemoveReport,
  onEditReport,
  onViewReport,
}: ReportsListTableProps) {
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return tableColumns({
      onRemove: onRemoveReport,
      onEdit: onEditReport,
      onView: onViewReport,
    }) as Column<ReportsListTableData>[];
  }, [onRemoveReport, onEditReport]);

  const data: ReportsListTableData[] = useMemo(() => reports, [reports]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultCanSort: false,
      defaultCanFilter: false,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  return (
    <Card title="All reports" withHeader>
      {isLoading ? (
        <Loader label="Loading Reports" labelPosition="bottom" />
      ) : rows.length < 1 ? (
        <EmptyState
          title="Create new report"
          text="Currently you have no Reports saved. Get started with a new one"
          buttonLabel="New report"
          onClick={() => navigate(reportsData.list.createPath)}
        />
      ) : (
        <Styled.Table {...getTableProps()}>
          <Styled.TableHead>
            {headerGroups.map((headerGroup) => (
              <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Styled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                      {column.canFilter ? column.render("Filter") : null}
                      {column.canSort && (
                        <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                      )}
                    </Styled.TableCellWithFiltersWrapper>
                  </Styled.TableHeader>
                ))}
              </Styled.TableHeaderRow>
            ))}
          </Styled.TableHead>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Styled.TableBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Styled.TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Styled.TableCell>
                    );
                  })}
                </Styled.TableBodyRow>
              );
            })}
          </Styled.TableBody>
        </Styled.Table>
      )}
    </Card>
  );
}
