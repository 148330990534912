import { Navigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Result from "@synerise/ds-result";

import * as Styled from "../login.styles";

export function InvitationStatusMembershipEmailNotAccepted() {
  return (
    <>
      <Result
        type="info"
        title="Membership email not accepted"
        description="You still have not accepted your membership email. Please check your email and click on the link to accept it."
      />

      <Styled.FooterButtonsContainer>
        <Button
          onClick={() =>
            Navigate({
              to: "/login",
            })
          }
        >
          Back to Login
        </Button>
      </Styled.FooterButtonsContainer>
    </>
  );
}
