/* eslint-disable */
import { Periodicity } from "../../financials.types";

export type FinancialsISMockValue = {
  id: number;
  date: string;
  value: number;
  line_id: number;
  period: Periodicity;
  [key: string]: any;
};

export type FinancialsISMockLine = {
  id: number;
  statement_id: number;
  account_id: number;
  line_name: string;
  is_calculated: boolean;
  level: number;
  is_metric: boolean | null;
  [key: string]: any;
  values: FinancialsISMockValue[];
};

export const financialsISMockLines: FinancialsISMockLine[] = [
  {
    id: 659628768708486,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555911,
    line_name: "Receitas",
    line_ref: "[1449]",
    line_code: "[3]",
    index: null,
    level: 0,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260872,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 21452.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678988,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 27798.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918277904,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 56459.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909012,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 58541.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949848,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 23489.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154972,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 22244.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975801888,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 34712.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072548,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 38317.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163560,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 42251.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016155244,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 33115.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029197104,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 96462.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779060,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -6129.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059475098,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 189048.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072238358,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 75450.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967704,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 123156.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796069,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 189519.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111493812,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 277496.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124502853,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 205591.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200604,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 139781.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152732792,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 125863.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169035034,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 227104.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189564357,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 166226.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629204997241,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 168184.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217875252,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 164582.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440839,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 159871.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247317708,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 160332.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261129620,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 187608.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275333731,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 189838.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981810,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 193059.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304169987,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 193532.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145752,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 216961.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332121520,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 303298.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346849930,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 303559.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990114,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 307493.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373949499,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 289986.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434901,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 434709.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400033263,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 331031.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975786,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 338277.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424625004,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 129169.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437437489,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 137142.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450200814,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 245947.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465896879,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 256294.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480461425,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 263534.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552434,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 308258.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676213,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 323280.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524174523,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 328849.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541640064,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 331088.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557367881,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 334115.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724871,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555912,
    line_name: "Receitas Operacionais",
    line_ref: "[1456]",
    line_code: "[3-1]",
    index: null,
    level: 1,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260873,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 22980.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678989,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 29778.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294289,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 60481.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909013,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 62712.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949849,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 25163.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154973,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 23828.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975801889,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 37185.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072549,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 41047.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163561,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 45261.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016155245,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 35474.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213489,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 96462.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779061,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 42587.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491483,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 202515.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254743,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 80825.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967705,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 131929.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796070,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 203020.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111493813,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 297265.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519238,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 220237.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200605,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 149738.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749177,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 134830.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051419,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 243282.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580742,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 177970.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013626,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 179969.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891637,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 176405.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440840,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 184498.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334093,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 182715.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146005,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 213798.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350116,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 216340.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981811,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 220010.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186372,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 220550.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145753,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 247250.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137905,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 345640.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866315,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 345937.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990115,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 350420.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965884,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 331516.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434902,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 495396.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049648,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 377244.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975787,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 385502.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641389,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 147201.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453874,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 156761.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217199,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 280398.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913264,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 292397.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477810,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 300336.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552435,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 351603.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676214,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 369369.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190908,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 375564.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656449,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 377307.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384266,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724871,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 380757.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724872,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555913,
    line_name: "Receitas de Serviços",
    line_ref: "[1484]",
    line_code: "[3-1-02]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260874,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 22980.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678990,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 29778.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294290,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 60481.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909014,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 62712.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949850,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 25163.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154974,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 23828.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975801890,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 37185.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072550,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 41047.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163562,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 45261.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171630,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 35474.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213490,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 96462.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779062,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 42587.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491484,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 202515.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254744,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 80825.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967706,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 131929.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796071,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 203020.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111493814,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 297265.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519239,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 220237.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200606,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 149738.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749178,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 134830.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051420,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 243282.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580743,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 177970.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013627,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 179969.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891638,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 176405.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440841,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 184498.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334094,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 182715.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146006,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 213798.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350117,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 216340.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981812,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 220010.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186373,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 220550.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145754,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 247250.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137906,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 345640.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866316,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 345937.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990116,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 350420.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965885,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 331516.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434903,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 495396.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049649,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 377244.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975788,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 385502.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641390,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 147201.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453875,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 156761.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217200,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 280398.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913265,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 292397.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477811,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 300336.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552436,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 351603.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676215,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 369369.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190909,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 375564.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656450,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 377307.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384267,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724872,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 380757.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724873,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555914,
    line_name: "Receitas de Serviços de Publicidade",
    line_ref: "[6103]",
    line_code: "[3-1-02-03]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260875,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 22980.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678991,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 29778.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294291,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 60481.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909015,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 62712.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949851,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 25163.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154975,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 23828.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818275,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 37185.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072551,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 41047.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163563,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 45261.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171631,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 35474.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213491,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 96462.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779063,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 42587.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491485,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 202515.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254745,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 80825.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967707,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 131929.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796072,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 203020.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111493815,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 297265.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519240,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 220237.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200607,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 149738.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749179,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 134830.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051421,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 243282.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580744,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 177970.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013628,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 179969.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891639,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 176405.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440842,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 184498.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334095,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 182715.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146007,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 213798.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350118,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 216340.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981813,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 220010.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186374,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 220550.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145755,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 247250.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137907,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 345640.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866317,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 345937.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990117,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 350420.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965886,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 331516.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434904,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 495396.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049650,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 377244.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975789,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 385502.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641391,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 147201.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453876,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 156761.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217201,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 280398.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913266,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 292397.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477812,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 300336.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552437,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 351603.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676216,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 369369.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190910,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 340106.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656451,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 334604.27,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384268,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724873,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 353321.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724874,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555915,
    line_name: "Receitas Financeiras",
    line_ref: "[1526]",
    line_code: "[3-1-03]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260876,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678992,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294292,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909016,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949852,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154976,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818276,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072552,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163564,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171632,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213492,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779064,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724874,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724875,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555916,
    line_name: "Receitas de Aplicações de Renda Fixa",
    line_ref: "[1533]",
    line_code: "[3-1-03-01]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260877,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678993,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294293,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909017,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949853,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171361,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818277,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072553,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163565,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171633,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213493,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779065,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491486,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 22.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254746,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967708,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 46.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796073,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510200,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519241,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200608,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749180,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051422,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580745,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013629,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891640,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350119,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 485.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981814,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186375,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145756,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137908,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866318,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990118,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 2.38,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965887,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 4.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434905,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 1.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049651,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 1.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975790,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 0.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641392,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 0.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453877,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217202,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913267,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477813,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552438,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676217,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190911,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 1.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656452,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384269,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724875,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724876,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555917,
    line_name: "Deduções de Receitas",
    line_ref: "[1610]",
    line_code: "[3-2]",
    index: null,
    level: 1,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260878,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -1528.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678994,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1980.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294294,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -4021.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909018,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -4170.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949854,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -1673.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171362,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -1584.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818278,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -2472.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072554,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -2729.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163566,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -3009.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171634,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -2359.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213494,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779066,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -48717.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491487,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -13467.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254747,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -5374.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967709,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -8773.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796074,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -13500.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510201,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -19768.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519242,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -14645.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200609,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -9957.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749181,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -8966.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051423,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -16178.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580746,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -11744.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013630,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -11785.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891641,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -11823.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440843,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -24626.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334096,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -22382.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146008,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -26190.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350120,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -26501.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981815,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -26951.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186376,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -27017.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145757,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -30288.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137909,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -42341.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866319,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -42377.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990119,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -42926.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965888,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -41529.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434906,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -60686.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049652,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -46212.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992175,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -47224.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641393,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -18032.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453878,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -19619.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217203,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -34451.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913268,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -36102.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477814,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -36801.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552439,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -43345.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676218,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -46088.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190912,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -46715.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656453,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -46219.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384270,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724876,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -46641.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724877,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555918,
    line_name: "Deduções de Receitas",
    line_ref: "[1638]",
    line_code: "[3-2-02]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260879,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -1528.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678995,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1980.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294295,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -4021.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909019,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -4170.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949855,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -1673.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171363,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -1584.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818279,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -2472.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072555,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -2729.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163567,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -3009.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171635,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -2359.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213495,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779067,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -48717.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491488,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -13467.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254748,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -5374.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967710,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -8773.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796075,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -13500.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510202,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -19768.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519243,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -14645.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200610,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -9957.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749182,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -8966.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051424,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -16178.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580747,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -11744.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013631,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -11785.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891642,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -11823.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440844,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -24626.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334097,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -22382.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146009,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -26190.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350121,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -26501.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981816,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -26951.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186377,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -27017.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145758,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -30288.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137910,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -42341.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866320,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -42377.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990120,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -42926.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965889,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -41529.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434907,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -60686.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049653,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -46212.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992176,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -47224.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641394,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -18032.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453879,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -19619.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217204,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -34451.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913269,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -36102.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477815,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -36801.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552440,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -43345.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676219,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -46088.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190913,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -46715.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656454,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -46219.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384271,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724877,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -46641.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724878,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555919,
    line_name: "Pis s/ faturamento",
    line_ref: "[1645]",
    line_code: "[3-2-02-01]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260880,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -149.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695380,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -193.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294296,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -393.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909020,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -407.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949856,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -163.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171364,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -154.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818280,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -241.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072556,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -266.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163568,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -294.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171636,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -230.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213496,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779068,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -4761.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491489,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -1316.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254749,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -525.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967711,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -857.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796076,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -1319.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510203,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -1932.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519244,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -1431.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200611,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -973.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749183,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -876.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051425,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -1581.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580748,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -1138.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013632,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -1151.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891643,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -1146.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440845,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -3405.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334098,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -3014.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146010,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -3527.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350122,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -3569.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981817,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -3630.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186378,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -3639.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145759,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -4079.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137911,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -5703.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866321,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -5708.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990121,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -5782.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965890,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -5470.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434908,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -8174.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049654,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -6224.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992177,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -6361.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641395,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -2428.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453880,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2586.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217205,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -4626.97,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913270,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -4825.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477816,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -4955.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552441,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -5801.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676220,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -6094.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190914,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -6197.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656455,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -6225.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384272,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724878,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -6282.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724879,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555920,
    line_name: "Cofins s/ faturamento",
    line_ref: "[2443]",
    line_code: "[3-2-02-02]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260881,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -689.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695381,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -893.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294297,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -1814.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909021,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -1881.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949857,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -754.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171365,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -714.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818281,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -1115.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072557,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1231.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163569,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -1357.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171637,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -1064.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213497,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779069,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -21977.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491490,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -6075.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254750,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -2424.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967712,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -3957.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796077,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -6090.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510204,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -8917.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519245,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -6607.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200612,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -4492.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749184,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -4044.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051426,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -7298.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580749,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -5256.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013633,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -5316.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891644,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -5292.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440846,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -15686.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334099,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -13886.38,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146011,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -16248.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350123,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -16441.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981818,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -16720.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186379,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -16761.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145760,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -18791.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137912,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -26268.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866322,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -26291.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990122,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -26631.92,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965891,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -25195.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434909,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -37650.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049655,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -28670.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992178,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -29298.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641396,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -11187.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453881,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -11913.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217206,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -21310.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913271,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -22222.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477817,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -22825.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552442,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -26721.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676221,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -28072.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190915,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -28542.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656456,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -28675.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384273,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724879,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -28937.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724880,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555921,
    line_name: "ISS s/ faturamento",
    line_ref: "[2450]",
    line_code: "[3-2-02-03]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260882,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -689.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695382,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -893.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294298,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -1814.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909022,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -1881.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966242,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -754.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171366,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -714.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818282,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -1115.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072558,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1231.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163570,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -1357.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171638,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -1064.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213498,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795454,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -21977.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491491,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -6075.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254751,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -2424.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984097,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -3957.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796078,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -6090.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510205,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -8917.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519246,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -6607.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200613,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -4492.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749185,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -4044.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051427,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -7298.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580750,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -5349.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013634,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -5316.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891645,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -5384.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457231,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -5534.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334100,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -5481.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146012,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -6413.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350124,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -6490.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981819,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -6600.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186380,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -6616.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162145,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -7417.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137913,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -10369.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866323,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -10378.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990123,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -10512.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965892,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -9945.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434910,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -14861.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049656,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -11317.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992179,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -11565.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641397,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -4416.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453882,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -4702.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217207,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -8513.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913272,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -9055.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477818,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -9010.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552443,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -10821.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676222,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -11080.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190916,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -11975.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656457,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -11317.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384274,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724880,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -11421.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768708001,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555911,
    line_name: "(=) Gross Profit",
    line_ref: "[1449]",
    line_code: "[3]",
    index: null,
    level: 0,
    formula: null,
    is_custom: false,
    is_calculated: true,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260872,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 21452.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678988,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 27798.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918277904,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 56459.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909012,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 58541.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949848,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 23489.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154972,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 22244.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975801888,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 34712.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072548,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 38317.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163560,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 42251.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016155244,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 33115.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029197104,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 96462.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779060,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -6129.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059475098,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 189048.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072238358,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 75450.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967704,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 123156.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796069,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 189519.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111493812,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 277496.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124502853,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 205591.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200604,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 139781.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152732792,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 125863.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169035034,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 227104.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189564357,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 166226.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629204997241,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 168184.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217875252,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 164582.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440839,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 159871.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247317708,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 160332.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261129620,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 187608.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275333731,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 189838.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981810,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 193059.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304169987,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 193532.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145752,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 216961.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332121520,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 303298.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346849930,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 303559.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990114,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 307493.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373949499,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 289986.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434901,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 434709.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400033263,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 331031.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975786,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 338277.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424625004,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 129169.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437437489,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 137142.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450200814,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 245947.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465896879,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 256294.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480461425,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 263534.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552434,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 308258.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676213,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 323280.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524174523,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 328849.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541640064,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 331088.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557367881,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 334115.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768708002,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555911,
    line_name: "% Gross Margin",
    line_ref: "[1449]",
    line_code: "[3]",
    index: null,
    level: 0,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: true,
    extra: null,
    values: [
      {
        id: 659628890260872,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 21452.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678988,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 27798.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918277904,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 56459.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909012,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 58541.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949848,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 23489.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154972,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 22244.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975801888,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 34712.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072548,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 38317.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163560,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 42251.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016155244,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 33115.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029197104,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 96462.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779060,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -6129.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059475098,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 189048.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072238358,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 75450.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967704,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 123156.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796069,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 189519.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111493812,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 277496.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124502853,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 205591.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200604,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 139781.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152732792,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 125863.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169035034,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 227104.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189564357,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 166226.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629204997241,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 168184.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217875252,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 164582.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440839,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 159871.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247317708,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 160332.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261129620,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 187608.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275333731,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 189838.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981810,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 193059.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304169987,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 193532.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145752,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 216961.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332121520,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 303298.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346849930,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 303559.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990114,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 307493.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373949499,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 289986.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434901,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 434709.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400033263,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 331031.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975786,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 338277.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424625004,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 129169.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437437489,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 137142.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450200814,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 245947.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465896879,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 256294.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480461425,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 263534.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552434,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 308258.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676213,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 323280.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524174523,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 328849.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541640064,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 331088.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557367881,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 334115.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724881,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555922,
    line_name: "Despesas",
    line_ref: "[1652]",
    line_code: "[4]",
    index: null,
    level: 0,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260883,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -69198.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695383,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -117115.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294299,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -122753.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909023,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -176107.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966243,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -227263.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171367,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -105639.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818283,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -236660.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072559,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -113701.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163571,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -120863.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171639,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -214850.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213499,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -130964.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795455,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1983186.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491492,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -196798.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254752,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -194668.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984098,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -262589.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796079,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -148408.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510206,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -173305.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519247,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -177175.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200614,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -165245.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749186,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -276829.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051428,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -211313.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580751,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -444743.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013635,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -163273.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891646,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -920396.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457232,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -531144.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334101,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -271635.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146013,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -281839.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350125,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -276175.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998204,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -253186.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186381,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -236355.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162146,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -328594.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137914,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -213689.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866324,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -309642.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990124,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -322961.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965893,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -322251.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434911,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -1059698.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049657,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -440712.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992180,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -354707.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641398,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -370935.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453883,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -266480.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217208,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -263565.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913273,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -270626.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477819,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -262783.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552444,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -391356.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676223,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -423082.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190917,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -451780.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656458,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -389487.97,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384275,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724881,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -466180.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724882,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572307,
    line_name: "Despesas Operacionais",
    line_ref: "[1659]",
    line_code: "[4-1]",
    index: null,
    level: 1,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260884,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -69198.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695384,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -117115.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294300,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -112291.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909024,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -176107.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966244,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -227263.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171368,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -97060.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818284,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -236660.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072560,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -113701.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179956,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -111379.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171640,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -214850.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213500,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -130964.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795456,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1279426.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491493,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -195432.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254753,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -190788.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984099,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -226643.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796080,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -146531.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510207,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -171557.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519248,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -173588.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200615,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -113994.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749187,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -259930.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051429,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -202426.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580752,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -443212.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013636,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -161309.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891647,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -796873.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457233,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -506793.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334102,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -250308.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146014,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -257652.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350126,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -255477.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998205,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -249455.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186382,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -230420.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162147,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -226593.97,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137915,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -192894.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866325,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -285516.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990125,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -302860.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965894,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -304179.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434912,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -1017368.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049658,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -417717.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992181,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -335229.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641399,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -351232.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453884,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -292614.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217209,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -240462.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913274,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -267502.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477820,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -257821.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552445,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -350157.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676224,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -406608.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190918,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -408735.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656459,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -389464.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384276,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724882,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -413427.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724883,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572308,
    line_name: "Despesas de Serviços",
    line_ref: "[1687]",
    line_code: "[4-1-02]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260885,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -29166.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695385,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -30470.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294301,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -43297.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909025,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -91203.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966245,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -96226.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171369,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -71699.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818285,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -67833.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072561,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -84472.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179957,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -63918.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171641,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -48564.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213501,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -25548.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795457,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724883,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -44077.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724884,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572309,
    line_name: "Despesas de Serviços de Pessoa Física",
    line_ref: "[4101]",
    line_code: "[4-1-02-01]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260886,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -18300.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695386,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -26900.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294302,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -40870.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909026,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -73289.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966246,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -88680.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171370,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -68306.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818286,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -56387.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072562,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -59479.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179958,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -63400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171642,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -23302.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213502,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -18500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795458,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -19186.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491494,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -83128.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254754,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -79028.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984100,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -74228.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796081,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -10749.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510208,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -37026.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519249,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -22585.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200616,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -16600.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749188,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051430,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580753,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -1925.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013637,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891648,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457234,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334103,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146015,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350127,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998206,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -600.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186383,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162148,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137916,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866326,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990126,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -800.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965895,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -800.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434913,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -12550.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049659,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992182,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641400,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453885,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217210,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913275,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477821,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552446,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676225,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190919,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541656460,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384277,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724884,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724885,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572310,
    line_name: "Despesas de Serviços de Pessoa Jurídica",
    line_ref: "[4102]",
    line_code: "[4-1-02-02-02]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260887,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -10866.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695387,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -3570.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294303,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -2427.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909027,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -17914.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966247,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -7546.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171371,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -3393.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818287,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -11446.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072563,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -24993.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179959,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -518.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171643,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -25261.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213503,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -7048.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795459,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -24891.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491495,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -18970.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254755,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -12044.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984101,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -33940.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796082,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -13191.27,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510209,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -12178.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519250,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -7808.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200617,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -14735.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749189,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -15181.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051431,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -52706.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580754,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -272302.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013638,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -3335.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891649,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -495029.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457235,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -9755.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334104,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -2900.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146016,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -2900.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350128,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -7511.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998207,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -3488.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186384,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -588.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162149,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -294.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137917,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -147.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866327,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990127,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -3854.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965896,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -588.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434914,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -9672.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049660,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -588.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992183,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -4588.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641401,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -441.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453886,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -294.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217211,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -334.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913276,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -294.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477822,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -294.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552447,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -294.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676226,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -8294.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190920,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -294.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672845,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -294.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384278,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724885,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724886,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572311,
    line_name: "Despesas de Serviços de Pessoa Jurídica",
    line_ref: "[1701]",
    line_code: "[4-1-02-05]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260888,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695388,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294304,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909028,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966248,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171372,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818288,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072564,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179960,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171644,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213504,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795460,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724886,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724887,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572312,
    line_name: "Despesas de Serviços de Pessoa Física",
    line_ref: "[1694]",
    line_code: "[4-1-02-07]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260889,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695389,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294305,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909029,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966249,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171373,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818289,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072565,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179961,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171645,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213505,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795461,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724887,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724888,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572313,
    line_name: "Despesas Administrativas",
    line_ref: "[1722]",
    line_code: "[4-1-03]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260890,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -38322.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695390,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -84347.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294306,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -68341.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909030,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -81517.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966250,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -128225.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171374,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -20911.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818290,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -165650.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072566,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -27681.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179962,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -44910.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171646,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -161586.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213506,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -96910.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795462,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1233074.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491496,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -93334.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254756,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -99715.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984102,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -118474.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796083,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -122591.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510210,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -122352.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519251,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -142346.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200618,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -81189.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749190,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -244322.38,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051432,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -146586.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580755,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -168056.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013639,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -151782.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891650,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -294060.27,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457236,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -117368.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334105,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -126362.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146017,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -142773.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350129,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -132808.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998208,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -130923.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186385,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -135403.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162150,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -129874.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137918,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -114449.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866328,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -163409.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990128,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -112757.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965897,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -138795.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434915,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -794616.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049661,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -128378.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992184,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -123639.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641402,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -120940.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453887,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -96226.27,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217212,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -49001.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913277,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -46591.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477823,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -50707.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552448,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -72999.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676227,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -101213.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190921,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -94765.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672846,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -96104.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384279,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724888,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -110201.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724889,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572314,
    line_name: "Despesas Trabalhistas",
    line_ref: "[1729]",
    line_code: "[4-1-03-01]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260891,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -25245.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695391,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -20875.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294307,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -28090.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909031,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -40286.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966251,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -20035.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171375,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -3958.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818291,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -45222.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072567,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -14830.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179963,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -31394.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171647,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -48689.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213507,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -24716.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795463,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -27600.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491497,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -53177.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254757,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -71369.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984103,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -90122.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796084,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -63516.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510211,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -71884.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519252,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -60649.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200619,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -49078.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152749191,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -81017.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051433,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -79856.92,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580756,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -63232.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013640,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -79483.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217891651,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -84037.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457237,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -38284.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334106,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -52057.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146018,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -63747.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350130,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -72057.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998209,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -71955.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186386,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -60186.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162151,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -59016.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137919,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -54554.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866329,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -51690.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990129,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -59848.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965898,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -58550.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434916,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -56888.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049662,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -47500.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992185,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -56780.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641403,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -62646.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453888,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -55454.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217213,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -34481.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913278,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -35008.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477824,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -38848.27,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552449,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -40539.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676228,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -63097.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190922,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -46894.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672847,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -60128.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384280,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724889,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -62985.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768724890,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572315,
    line_name: "Despesas com Salários",
    line_ref: "[1743]",
    line_code: "[4-1-03-01-02]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260892,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -4216.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695392,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -3286.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294308,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -2247.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909032,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -1123.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966252,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -3371.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171376,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -3371.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818292,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -4931.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072568,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -9971.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179964,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -9971.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171648,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -9971.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213508,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -11771.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795464,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -13262.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491498,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -15756.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254758,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -16934.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984104,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -19098.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796085,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -23230.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510212,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -25234.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519253,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -25234.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200620,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -26034.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765576,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -28034.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051434,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -24563.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580757,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -32126.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013641,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -28171.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908036,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -23802.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457238,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -20075.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334107,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -29931.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146019,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -31466.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350131,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -36642.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998210,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -30460.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186387,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -29044.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162152,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -29835.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137920,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -28429.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866330,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -25731.97,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990130,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -27221.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965899,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -30083.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434917,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -31733.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400049663,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -29905.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992186,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -30904.92,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641404,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -32748.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453889,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -28705.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217214,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -16849.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913279,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -17347.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477825,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -21008.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552450,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -21136.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676229,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -22662.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190923,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -21237.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672848,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -32539.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384281,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768724890,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -26657.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741275,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572316,
    line_name: "Despesas com Honorários",
    line_ref: "[4203]",
    line_code: "[4-1-03-01-03]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260893,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695393,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294309,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909033,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966253,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171377,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818293,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072569,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179965,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171649,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213509,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795465,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519254,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -29.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200621,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765577,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051435,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -17600.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580758,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013642,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908037,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -21443.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457239,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -160397.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334108,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -8755.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146020,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -7030.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350132,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -3355.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998211,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -2305.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186388,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -2305.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162153,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -10180.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137921,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -4380.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866331,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -15618.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990131,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -16609.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965900,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -9526.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434918,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -11756.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048640,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -12872.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992187,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -4287.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641405,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453890,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -6411.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217215,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -1484.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913280,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -12159.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477826,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -4247.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552451,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -37549.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676230,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -7714.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190924,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -2454.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672849,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -7199.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384282,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741275,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -27411.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741276,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572317,
    line_name: "Despesas com Férias",
    line_ref: "[1764]",
    line_code: "[4-1-03-01-05]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260894,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695394,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294310,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -4495.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909034,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966254,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171378,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818294,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072570,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179966,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171650,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213510,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795466,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491499,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -1771.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254759,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -13998.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984105,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -3246.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796086,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -7447.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510213,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -2803.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519255,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200622,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -2803.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765578,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -3114.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051436,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -2780.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580759,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013643,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908038,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457240,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -2558.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334109,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -3198.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146021,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -3304.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350133,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -4293.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998212,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -2892.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186389,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -3281.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162154,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -2926.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137922,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -3274.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866332,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -4718.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990132,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -3494.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965901,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -3696.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434919,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -3591.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048641,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -3290.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992188,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -3488.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641406,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -3987.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453891,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -3987.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217216,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -3986.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913281,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -3986.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477827,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -3986.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552452,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -3750.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692615,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -3904.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190925,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -3980.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672850,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -3410.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384283,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741276,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -3946.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741277,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572318,
    line_name: "Despesas com Décimo Terceiro Salário",
    line_ref: "[1771]",
    line_code: "[4-1-03-01-06]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260895,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695395,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294311,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909035,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966255,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171379,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818295,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072571,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179967,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171651,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213511,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -3020.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795467,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -3884.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059491500,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -1328.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254760,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -1493.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984106,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -1827.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796087,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -2477.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510214,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -2102.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519256,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200623,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -2102.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765579,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -2336.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051437,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -2085.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580760,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013644,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908039,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -20525.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457241,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -1703.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334110,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -2709.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146022,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -2478.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350134,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -3220.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998213,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -2169.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186390,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -2461.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162155,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -2194.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137923,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -2456.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866333,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -362.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990133,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -2579.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965902,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -1893.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434920,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -443.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048642,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -2463.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992189,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -2504.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641407,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -2821.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453892,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2821.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217217,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -2821.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913282,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -2821.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477828,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -2821.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568837,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -2778.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692616,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -2978.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190926,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -3017.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672851,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -2933.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384284,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741277,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -6696.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741278,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572319,
    line_name: "Despesas com Treinamento",
    line_ref: "[1785]",
    line_code: "[4-1-03-01-08]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260896,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -3500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695396,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294312,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925420,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966256,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171380,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818296,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072572,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179968,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171652,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213512,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795468,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051438,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -658.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580761,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -396.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013645,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908040,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384285,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741278,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -178.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741279,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572320,
    line_name: "Despesas com Estagiarios",
    line_ref: "[1792]",
    line_code: "[4-1-03-01-09]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260897,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -800.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695397,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -800.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294313,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -1200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925421,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -1200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966257,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -1200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171381,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -1200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818297,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -1200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088957,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1320.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179969,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -2560.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171653,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -4766.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213513,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -5900.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795469,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -5900.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507885,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -5650.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254761,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -4700.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984107,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -4700.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796088,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -3500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510215,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -4466.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519257,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -3500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200624,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -5000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765580,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -3000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051439,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -3000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580762,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -4000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013646,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -3953.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908041,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -3500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457242,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -4500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334111,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -4166.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146023,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -5500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350135,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -4500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998214,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -4500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186391,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -4500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162156,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -4500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137924,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -4500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866334,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -4500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990134,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -3749.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965903,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -2200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434921,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -3000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048643,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -3000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992190,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -2066.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641408,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -2000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453893,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217218,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -2000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913283,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -2000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477829,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -2000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568838,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -2000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692617,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -1366.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524190927,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -1000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672852,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -1900.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384286,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741279,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -2000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741280,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572321,
    line_name: "Despesas com Vale Transporte",
    line_ref: "[1799]",
    line_code: "[4-1-03-01-10]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260898,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -159.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695398,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -65.92,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294314,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -295.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925422,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -97.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966258,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -129.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171382,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 172.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818298,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -867.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088958,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -3396.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179970,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -505.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171654,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -2313.97,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213514,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -975.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795470,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1522.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507886,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 507.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254762,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -2822.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984108,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -1036.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796089,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -1333.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510216,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -1456.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519258,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 1035.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200625,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -4446.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765581,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -833.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051440,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -809.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580763,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -3514.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013647,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -1022.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908042,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -1488.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457243,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -2591.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334112,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -2435.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146024,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -3332.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350136,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -2962.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998215,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -5230.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186392,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -2110.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162157,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -2520.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137925,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -1632.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866335,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -3371.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990135,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -1902.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965904,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -4211.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434922,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -3116.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048644,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 1241.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992191,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -1521.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641409,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -3475.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453894,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217219,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913284,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -28.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477830,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568839,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -1851.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692618,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -4948.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524207312,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672853,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384287,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741280,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741281,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572322,
    line_name: "Despesas com Vale Refeição",
    line_ref: "[1806]",
    line_code: "[4-1-03-01-11]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260899,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -655.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695399,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -809.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294315,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -690.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925423,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -798.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966259,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -762.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171383,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 4.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818299,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -2497.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088959,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -578.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179971,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -4032.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171655,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -3406.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213515,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -3486.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795471,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -3468.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507887,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -560.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254763,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -8050.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984109,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -5188.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796090,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 32.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510217,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -10713.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519259,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -5577.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200626,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -5843.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765582,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 36.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051441,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -10457.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580764,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -4856.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013648,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -4348.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908043,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -4778.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457244,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -7114.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334113,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -6283.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146025,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -9122.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350137,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -8962.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998216,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -3088.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186393,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -8957.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162158,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -8287.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137926,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -6975.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866336,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -8393.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990136,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -8781.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965905,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -8780.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434923,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -9218.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048645,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 69.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992192,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -7004.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641410,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -9989.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453895,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -8624.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217220,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 60.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913285,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 60.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477831,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 60.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568840,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 69.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692619,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -18717.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524207313,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -8643.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672854,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -9187.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384288,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741281,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -8035.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741282,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572323,
    line_name: "Despesas com Plano de Saúde",
    line_ref: "[1813]",
    line_code: "[4-1-03-01-12]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260900,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -15914.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695400,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -15914.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294316,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -17189.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925424,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -37066.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966260,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -14571.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171384,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 436.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818300,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -35725.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088960,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 436.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179972,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -14323.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171656,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -28230.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213516,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 436.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795472,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 436.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507888,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -28617.38,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254764,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -21398.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984110,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -51525.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796091,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -25558.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510218,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -25106.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519260,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -23950.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200627,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 258.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765583,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -43736.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051442,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -17902.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580765,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -18339.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013649,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -41987.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908044,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -4747.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457245,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 258.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334114,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -3332.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146026,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -8543.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350138,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -11477.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998217,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -10499.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186394,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -9535.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162159,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -7482.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137927,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -7286.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866337,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -8112.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990137,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -7981.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965906,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -5674.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434924,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -5784.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048646,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -10632.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992193,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -8470.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641411,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -7624.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453896,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -9316.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217221,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -8883.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913286,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -8883.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477832,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -9091.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568841,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -9092.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692620,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -9092.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524207314,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -8627.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672855,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -13185.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384289,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741282,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -12959.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741283,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572324,
    line_name: "Contribuição Sindical",
    line_ref: "[1834]",
    line_code: "[4-1-03-01-15]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260901,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695401,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294317,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -1972.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925425,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966261,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171385,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818301,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088961,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179973,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171657,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213517,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795473,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254765,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -1972.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984111,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796092,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510219,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519261,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200628,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765584,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051443,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580766,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013650,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908045,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186395,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -297.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162160,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137928,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866338,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990138,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -300.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965907,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434925,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048647,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -819.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992194,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -819.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641412,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453897,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217222,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913287,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477833,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568842,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692621,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524207315,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672856,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384290,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741283,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741284,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572325,
    line_name: "Comissoes",
    line_ref: "[2562]",
    line_code: "[4-1-03-01-18]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260902,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695402,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294318,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925426,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966262,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171386,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818302,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088962,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179974,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171658,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213518,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795474,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984112,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -3500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812477,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510220,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519262,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -3392.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200629,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -3106.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765585,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051444,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580767,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013651,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908046,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692622,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -2500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524207316,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672857,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384291,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741284,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741285,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572326,
    line_name: "Encargos Sociais",
    line_ref: "[1855]",
    line_code: "[4-1-03-02]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260903,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -1467.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695403,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1143.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294319,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -2346.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925427,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -391.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966263,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -1173.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171387,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -1173.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818303,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -1716.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088963,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -3470.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179975,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -3470.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171659,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -3470.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213519,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -4338.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795475,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -5369.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507889,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -6277.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254766,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -18470.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984113,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -7217.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812478,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -11372.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510221,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -10338.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519263,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -8655.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200630,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -10338.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765586,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -11550.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051445,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -12213.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580768,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -9581.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013652,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -9694.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908047,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -17773.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457246,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -11448.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334115,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -14239.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261146027,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -9834.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350139,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -16065.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998218,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -22377.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186396,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -11687.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162161,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -12266.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137929,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -11717.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866339,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -9977.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990139,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -11903.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965908,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -13484.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434926,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -13166.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048648,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -12803.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992195,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -12656.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641413,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -13569.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453898,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -11146.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217223,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -7796.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913288,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -7845.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477834,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -9302.92,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568843,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -8840.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692623,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -7585.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524207317,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -9639.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672858,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -9851.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384292,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741285,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -12622.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741286,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572327,
    line_name: "Despesas com INSS",
    line_ref: "[1862]",
    line_code: "[4-1-03-02-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260904,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -1129.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695404,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -880.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294320,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -1807.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925428,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -301.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966264,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -903.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171388,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -903.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818304,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -1321.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088964,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -2672.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179976,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -2672.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171660,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -2672.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029213520,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -3154.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795476,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -3998.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507890,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -4799.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254767,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -15876.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984114,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -5376.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812479,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -8720.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510222,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -7927.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519264,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -6636.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200631,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -7927.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765587,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -8872.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051446,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -9378.42,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580769,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -7346.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013653,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -6752.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908048,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -7316.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457247,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -6698.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334116,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -10918.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162412,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -9796.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350140,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -12318.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998219,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -11240.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186397,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -8883.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162162,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -9405.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137930,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -8984.27,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866340,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -7780.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006524,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -8333.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965909,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -9270.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434927,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -9964.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048649,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -9831.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992196,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -9704.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641414,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -10404.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453899,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -8305.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217224,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -5897.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913289,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -5946.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477835,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -7133.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568844,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -6778.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692624,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -5765.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524207318,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -7383.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672859,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -6536.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384293,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741286,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -10685.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741287,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572328,
    line_name: "Despesas com FGTS",
    line_ref: "[1869]",
    line_code: "[4-1-03-02-02]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260905,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -337.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695405,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -262.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294321,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -539.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925429,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -89.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966265,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -269.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171389,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -269.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818305,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -394.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088965,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -797.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179977,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -797.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171661,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -797.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229905,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -1183.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795477,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1371.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507891,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -1478.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254768,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -2594.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984115,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -1841.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812480,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -2652.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510223,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -2411.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519265,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -2018.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200632,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -2411.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765588,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -2678.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051447,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -2834.97,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580770,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -2234.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013654,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -2941.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908049,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -10456.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457248,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -4750.67,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334117,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -3321.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162413,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 217.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350141,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -3746.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998220,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -11137.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186398,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -2803.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162163,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -2860.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137931,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -2732.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866341,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -2197.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006525,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -3569.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965910,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -4214.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434928,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -3201.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048650,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -2972.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992197,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -2951.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641415,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -3165.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453900,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2840.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217225,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -1898.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913290,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -1898.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477836,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -2169.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568845,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -2061.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692625,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -1820.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524207319,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -2256.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672860,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -3315.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384294,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741287,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -1937.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741288,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572329,
    line_name: "Despesas Prediais",
    line_ref: "[1883]",
    line_code: "[4-1-03-03]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260906,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695406,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294322,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -28670.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925430,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -11863.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966266,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -44580.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171390,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -14021.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818306,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -31672.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088966,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1931.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179978,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -1931.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171662,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -48706.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229906,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -18300.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795478,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -15218.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507892,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -192.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254769,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984116,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -394.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812481,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -19695.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510224,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -14244.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519266,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -26273.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200633,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -1654.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765589,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -56587.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051448,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580771,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -60704.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013655,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -20138.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908050,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -20050.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457249,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -23531.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334118,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -22411.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162414,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -22150.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350142,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -22510.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998221,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -23107.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186399,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -20772.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162164,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -21031.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137932,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -19568.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866342,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -21000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006526,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -21000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965911,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -42000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434929,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -1000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048651,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -21038.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992198,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -21500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641416,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -21000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453901,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -12000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217226,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913291,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477837,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568846,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -4838.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692626,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -14528.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223704,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -13780.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672861,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384295,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741288,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -600.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741289,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572330,
    line_name: "Despesas com Aluguéis",
    line_ref: "[1904]",
    line_code: "[4-1-03-03-03]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260907,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695407,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294323,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -28670.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925431,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -11863.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966267,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -12156.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171391,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818307,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -28178.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088967,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179979,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171663,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -46774.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229907,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -18300.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795479,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -15218.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812482,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -19695.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510225,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -14244.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519267,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -16273.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200634,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765590,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -56587.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051449,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189580772,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -11558.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013656,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908051,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568847,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -4838.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692627,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -14528.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223705,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -12780.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672862,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384296,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741289,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741290,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572331,
    line_name: "Despesas com Manutenção e Conservação",
    line_ref: "[1925]",
    line_code: "[4-1-03-03-06]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260908,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695408,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294324,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925432,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966268,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -32423.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171392,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -14021.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818308,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -3494.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088968,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1931.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179980,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -1931.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171664,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -1931.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229908,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795480,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507893,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -192.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254770,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984117,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -394.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812483,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510226,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519268,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -10000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200635,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -1654.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765591,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051450,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597157,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013657,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908052,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998222,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -750.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186400,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162165,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137933,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866343,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006527,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965912,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434930,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -1000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048652,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -38.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992199,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641417,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453902,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217227,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913292,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477838,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568848,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692628,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223706,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -1000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672863,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384297,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741290,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -400.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741291,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572332,
    line_name: "Despesas com Veículos",
    line_ref: "[1939]",
    line_code: "[4-1-03-04]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260909,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695409,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294325,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925433,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966269,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171393,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818309,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088969,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179981,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171665,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229909,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795481,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741291,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741292,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572333,
    line_name: "Despesas com Pedágios e Estacionamentos",
    line_ref: "[1988]",
    line_code: "[4-1-03-04-07]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277294,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695410,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294326,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925434,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966270,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171394,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818310,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088970,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179982,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171666,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229910,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795482,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984118,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -29.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812484,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -56.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510227,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519269,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200636,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -15.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765592,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -1347.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051451,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -53.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597158,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -964.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013658,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908053,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457250,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -314.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334119,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -419.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162415,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -368.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350143,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -673.38,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998223,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -620.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186401,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162166,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137934,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866344,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -2190.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006528,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -310.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965913,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -310.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434931,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -360.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048653,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -310.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992200,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -310.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641418,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -377.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453903,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217228,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913293,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477839,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568849,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692629,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223707,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672864,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384298,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741292,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741293,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572334,
    line_name: "Despesas de Viagens",
    line_ref: "[1995]",
    line_code: "[4-1-03-05]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277295,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695411,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -126.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294327,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925435,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966271,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171395,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818311,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -99.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088971,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1856.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179983,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171667,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -31081.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229911,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -2703.92,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795483,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741293,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741294,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572335,
    line_name: "Despesas com Estadias, Refeições e Passagens",
    line_ref: "[2002]",
    line_code: "[4-1-03-05-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277296,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695412,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -126.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294328,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925436,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966272,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171396,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818312,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -99.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088972,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1856.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179984,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171668,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -31081.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229912,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -2703.92,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795484,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507894,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -4212.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254771,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984119,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -629.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812485,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -12867.27,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510228,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -17798.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519270,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -5253.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200637,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -517.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765593,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -40626.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051452,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -18639.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597159,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -12101.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013659,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908054,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -3438.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457251,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -6596.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334120,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162416,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350144,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998224,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186402,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162167,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137935,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866345,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006529,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965914,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434932,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048654,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -9562.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992201,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641419,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453904,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217229,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913294,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477840,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568850,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692630,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223708,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672865,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384299,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741294,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -2642.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741295,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572336,
    line_name: "Despesas de Comunicação",
    line_ref: "[2009]",
    line_code: "[4-1-03-06]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277297,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -13743.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695413,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -7115.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294329,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -6723.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925437,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -2080.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966273,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -18921.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171397,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -174.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818313,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -27938.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088973,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -4212.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179985,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -4203.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171669,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -3995.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229913,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -4154.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795485,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741295,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1149233.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741296,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572337,
    line_name: "Despesas com Telefone",
    line_ref: "[4701]",
    line_code: "[4-1-03-06-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277298,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -1743.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695414,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -2115.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294330,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -1723.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925438,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -2080.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966274,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -1921.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171398,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818314,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -3387.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088974,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -2360.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179986,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -2163.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171670,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -2820.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229914,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -2639.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795486,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -2363.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507895,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -1904.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254772,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -2737.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984120,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -2444.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812486,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -2293.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510229,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -2243.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519271,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -1863.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200638,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -2286.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765594,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -5251.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051453,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -2404.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597160,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -2397.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013660,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -2367.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908055,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457252,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -2440.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334121,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -2481.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162417,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -2000.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350145,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -1747.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998225,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -1314.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186403,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -830.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162168,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -2668.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137936,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -2243.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866346,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -2963.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006530,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -1444.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965915,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -2148.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434933,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -1240.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048655,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -1240.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992202,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -1271.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641420,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -1288.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453905,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -1283.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217230,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -2342.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913295,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -1504.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477841,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -588.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568851,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -40.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692631,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -1312.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223709,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -1675.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672866,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -1461.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384300,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741296,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -1493.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741297,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572338,
    line_name: "Despesas com Publicações e Divulgações",
    line_ref: "[2030]",
    line_code: "[4-1-03-06-03]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277299,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -12000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904695415,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -5000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294331,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -5000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932925439,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966275,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -5000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171399,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818315,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -5000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088975,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179987,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171671,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229915,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795487,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200639,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -3047.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765595,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051454,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -745.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597161,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013661,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908056,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334122,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -1000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162418,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350146,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998226,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186404,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162169,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332137937,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866347,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006531,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965916,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434934,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741297,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741298,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572339,
    line_name: "Despesas com Correios e Malotes",
    line_ref: "[2037]",
    line_code: "[4-1-03-06-04]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277300,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711800,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918294332,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924416,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966276,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171400,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818316,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088976,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179988,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -185.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171672,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -35.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229916,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795488,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -95.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254773,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -235.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984121,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -75.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812487,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510230,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519272,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200640,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765596,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051455,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597162,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -75.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013662,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908057,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457253,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -370.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334123,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -50.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162419,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -40.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350147,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -30.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998227,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -130.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186405,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -27.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162170,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -245.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154322,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -40.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346866348,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -60.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006532,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -315.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965917,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -110.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451319,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -215.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048656,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -25.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992203,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -40.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641421,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453906,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217231,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913296,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -25.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477842,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568852,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692632,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -150.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223710,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -390.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672867,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384301,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741298,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741299,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572340,
    line_name: "Despesas com Manutenção e Reparos",
    line_ref: "[2044]",
    line_code: "[4-1-03-06-05]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277301,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711801,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310717,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924417,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966277,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171401,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818317,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088977,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179989,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171673,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229917,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795489,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507896,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -32.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254774,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984122,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812488,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -1685.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510231,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519273,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -3078.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200641,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -1934.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765597,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -2338.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051456,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597163,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -700.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013663,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908058,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457254,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -1506.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334124,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -1012.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162420,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -250.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350148,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998228,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186406,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162171,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154323,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -831.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882733,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006533,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965918,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -1827.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451320,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641422,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -120.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453907,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217232,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913297,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477843,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568853,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692633,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223711,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672868,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384302,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741299,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741300,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572341,
    line_name: "Internet",
    line_ref: "[2058]",
    line_code: "[4-1-03-06-07]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277302,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711802,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310718,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924418,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966278,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -12000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171402,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -174.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818318,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -19283.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088978,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1852.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179990,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -1644.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171674,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -1140.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229918,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -1304.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795490,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1351.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507897,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -1656.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254775,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -1253.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984123,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -1275.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812489,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -3033.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510232,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -1275.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519274,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -113.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200642,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765598,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -2760.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051457,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -2136.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597164,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -4966.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013664,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -556.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908059,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -3272.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457255,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -1500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334125,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -1613.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162421,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -113.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350149,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -1700.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998229,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -603.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186407,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -1500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162172,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -1500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154324,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -3192.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882734,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -3338.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006534,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965919,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -3374.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451321,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -182.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400048657,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -1960.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992204,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -2249.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641423,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -1960.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453908,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2234.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217233,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -1595.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913298,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477844,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568854,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -1595.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692634,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -1743.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223712,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -1743.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672869,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -1743.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384303,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741300,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -1743.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741301,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572342,
    line_name: "TV a cabo",
    line_ref: "[2478]",
    line_code: "[4-1-03-06-08]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277303,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711803,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310719,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924419,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966279,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171403,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975818319,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -268.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088979,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179991,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -209.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171675,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229919,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -209.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795491,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -209.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507898,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -209.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254776,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -209.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984124,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -209.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812490,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -322.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510233,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -364.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519275,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200643,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -372.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765599,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -237.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169051458,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -291.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597165,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013665,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908060,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -466.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334126,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -194.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162422,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -194.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350150,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -445.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998230,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -262.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186408,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162173,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -461.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154325,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -412.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882735,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -250.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006535,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -445.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373965920,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -313.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451322,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -313.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992205,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -543.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641424,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453909,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217234,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913299,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477845,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568855,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692635,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223713,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672870,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384304,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741301,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741302,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572343,
    line_name: "Despesas com Marketing",
    line_ref: "[2632]",
    line_code: "[4-1-03-06-09]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277304,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711804,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310720,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924420,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966280,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171404,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834704,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088980,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179992,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171676,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229920,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795492,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1145213.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765600,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -33077.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067843,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -22837.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597166,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 19212.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013666,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908061,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -94345.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641425,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -4000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453910,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217235,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913300,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477846,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568856,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692636,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -10000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223714,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -14000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672871,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -19000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384305,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741302,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -14000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741303,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572344,
    line_name: "Despesas Legais",
    line_ref: "[2065]",
    line_code: "[4-1-03-07]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277305,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711805,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310721,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924421,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966281,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171405,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834705,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -166.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088981,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179993,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -78.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171677,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229921,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795493,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217028,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -149.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765601,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067844,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -2980.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597167,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -151.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013667,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908062,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -367.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457256,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -878.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334127,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -902.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162423,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -137.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350151,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -495.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998231,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186409,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162174,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154326,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882736,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -20295.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006536,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982305,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -2480.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451323,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -2220.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065042,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -17.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992206,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -36.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641426,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -32.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453911,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2406.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450217236,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913301,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477847,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568857,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -9614.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692637,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223715,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -3595.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672872,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384306,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741303,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -2048.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741304,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572345,
    line_name: "Despesas Cartorárias",
    line_ref: "[2072]",
    line_code: "[4-1-03-07-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277306,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711806,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310722,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924422,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966282,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171406,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834706,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -166.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088982,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179994,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -78.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016171678,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229922,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795494,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217029,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -149.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765602,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067845,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597168,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -151.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013668,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908063,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -367.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457257,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -878.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334128,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -902.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162424,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -137.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350152,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998232,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186410,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162175,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154327,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882737,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006537,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982306,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451324,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -31.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641427,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -32.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453912,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2406.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233621,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913302,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477848,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568858,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692638,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223716,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -3595.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672873,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384307,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741304,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -2048.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741305,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572346,
    line_name: "Despesas de Transportes",
    line_ref: "[2086]",
    line_code: "[4-1-03-08]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277307,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -252.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711807,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1502.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310723,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -1959.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924423,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966283,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171407,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834707,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -1412.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088983,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -40.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179995,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -106.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188063,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -4408.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229923,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -2958.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795495,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741305,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -3948.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741306,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572347,
    line_name: "Despesas com Taxi (Voucher)",
    line_ref: "[2093]",
    line_code: "[4-1-03-08-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277308,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711808,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1502.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310724,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -1959.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924424,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966284,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171408,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834708,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -1322.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088984,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179996,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -106.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188064,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -4408.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229924,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -2958.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795496,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -2350.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507899,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -2102.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072254777,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -2311.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984125,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -2425.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812491,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -5134.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510234,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -2838.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519276,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -3335.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217030,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -76.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765603,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -7589.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067846,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -3210.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597169,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -4430.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013669,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908064,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -268.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457258,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -7269.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334129,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -4393.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162425,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -3229.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350153,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -3737.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998233,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -590.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186411,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -2793.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162176,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -6350.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154328,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -2954.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882738,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -7231.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006538,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -4230.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982307,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -668.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451325,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -2711.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065043,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -8503.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992207,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -2899.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641428,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -532.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453913,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2503.66,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233622,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913303,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -84.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477849,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -143.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568859,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -2750.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692639,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -371.38,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223717,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -234.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672874,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -496.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384308,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741306,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -403.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741307,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572348,
    line_name: "Despesas com Passagens Aéreas",
    line_ref: "[2100]",
    line_code: "[4-1-03-08-02]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277309,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711809,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310725,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924425,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966285,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171409,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834709,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088985,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179997,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188065,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229925,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795497,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1598.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507900,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -21943.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271162,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -2880.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984126,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -12069.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812492,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510235,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519277,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217031,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765604,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067847,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597170,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -10657.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013670,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908065,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457259,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -13678.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334130,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -13087.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162426,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -10501.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350154,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -4484.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998234,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -6932.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304186412,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -10774.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162177,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -18889.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154329,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -14144.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882739,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -12419.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006539,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -3548.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982308,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -8874.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451326,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -21769.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065044,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -5702.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992208,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -12693.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641429,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -4849.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453914,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -462.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233623,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913304,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477850,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568860,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692640,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223718,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -543.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672875,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384309,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741307,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741308,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572349,
    line_name: "Despesas com Fretes e Carretos",
    line_ref: "[2114]",
    line_code: "[4-1-03-08-04]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277310,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -252.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711810,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310726,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924426,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966286,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171410,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834710,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -90.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088986,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -40.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179998,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188066,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229926,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795498,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507901,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -190.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271163,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984127,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812493,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510236,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -205.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519278,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -170.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217032,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -635.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765605,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067848,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597171,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013671,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908066,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162427,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -15.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350155,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998235,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202797,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162178,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154330,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882740,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006540,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982309,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451327,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233624,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913305,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -300.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477851,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568861,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692641,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223719,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -292.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672876,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -600.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384310,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741308,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -1360.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741309,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572350,
    line_name: "Outras Despesas",
    line_ref: "[2121]",
    line_code: "[4-1-03-09]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277311,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 2386.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711811,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -53583.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310727,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -550.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924427,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -26896.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966287,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -43515.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171411,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -1582.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834711,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -57423.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088987,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1340.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003179999,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -3725.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188067,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -3285.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229927,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -39601.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795499,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741309,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -31565.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741310,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572351,
    line_name: "Despesas com Material de Expediente",
    line_ref: "[2128]",
    line_code: "[4-1-03-09-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277312,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -665.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711812,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310728,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -517.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924428,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -227.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966288,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960171412,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834712,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088988,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003180000,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -1022.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188068,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229928,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -652.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795500,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1939.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507902,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -815.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271164,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984128,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -585.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812494,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -209.06,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510237,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -828.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519279,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -6315.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217033,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -6665.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765606,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -39.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067849,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -870.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597172,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -1439.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013672,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908067,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457260,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -2059.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334131,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -2458.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162428,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -164.1,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350156,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -199.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998236,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -259.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202798,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -120.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162179,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -517.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154331,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -444.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882741,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -1008.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006541,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982310,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -159.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451328,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -229.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065045,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -5739.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992209,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -221.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641430,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -33.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453915,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -12.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233625,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913306,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477852,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568862,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -2754.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692642,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -340.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223720,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672877,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -484.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384311,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741310,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741311,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572352,
    line_name: "Despesas com Copa",
    line_ref: "[2142]",
    line_code: "[4-1-03-09-03]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277313,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -397.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711813,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310729,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924429,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966289,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -446.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187797,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834713,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088989,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003180001,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188069,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229929,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795501,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217034,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -755.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765607,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -580.01,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067850,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597173,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -1292.31,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013673,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908068,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457261,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -842.62,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334132,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -565.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162429,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -600.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350157,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -584.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288998237,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202799,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -1350.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162180,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -728.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154332,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -509.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882742,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -30.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006542,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -1080.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982311,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -367.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451329,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -584.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065046,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -2278.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992210,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -1052.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641431,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -830.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453916,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233626,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913307,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477853,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568863,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692643,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223721,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541672878,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384312,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741311,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768741312,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572353,
    line_name: "Outras Despesas Diversas",
    line_ref: "[2149]",
    line_code: "[4-1-03-09-09]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277314,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711814,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1950.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310730,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924430,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -447.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966290,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187798,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -509.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834714,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -2352.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088990,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003180002,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -1886.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188070,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -2657.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229930,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795502,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -7810.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507903,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -482.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271165,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -109.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984129,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -832.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812495,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -74.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510238,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519280,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217035,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -2350.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765608,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -1002.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067851,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -120.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597174,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -4799.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013674,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908069,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -8396.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457262,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -585.38,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334133,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162430,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -291.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350158,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014622,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202800,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162181,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -689.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154333,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882743,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -2652.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006543,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -4300.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982312,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -480.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451330,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 7151.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568864,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -199.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692644,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223722,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -94.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689263,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384313,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768741312,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757697,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572354,
    line_name: "Cartão de crédito",
    line_ref: "[2156]",
    line_code: "[4-1-03-09-10]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277315,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 3649.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711815,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -51633.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310731,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924431,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -24938.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966291,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -40348.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187799,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834715,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -50610.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088991,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003180003,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188071,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229931,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -38530.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795503,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757697,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -19374.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757698,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572355,
    line_name: "REEMBOLSO DE DESPESA",
    line_ref: "[7351]",
    line_code: "[4-1-03-09-11]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277316,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711816,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310732,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -32.9,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924432,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -1284.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966292,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -2720.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187800,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -1073.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834716,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -4460.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088992,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1340.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196388,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -817.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188072,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -628.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229932,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -417.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795504,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757698,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -2441.49,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757699,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572356,
    line_name: "Despesas com Depreciação e Amortização",
    line_ref: "[2163]",
    line_code: "[4-1-03-10]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277317,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711817,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310733,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924433,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966293,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187801,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834717,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088993,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196389,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188073,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -86.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229933,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -137.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795505,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -137.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507904,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -137.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271166,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -137.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984130,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -165.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812496,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -165.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510239,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -165.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519281,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -165.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217036,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -165.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765609,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -165.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067852,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -228.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597175,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -228.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013675,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -228.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908070,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -451.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457263,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -2429.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247334134,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -706.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162431,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -21693.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350159,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -876.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014623,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -876.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202801,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -21693.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162182,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -876.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154334,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -876.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882744,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -21716.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006544,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -947.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982313,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -1107.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451331,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -21954.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065047,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -8214.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992211,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -8214.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641432,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -8235.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453917,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -8235.83,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233627,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -1297.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913308,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -1337.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477854,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -1337.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568865,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -1337.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692645,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -1394.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223723,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -1394.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689264,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -1451.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384314,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768757699,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -1941.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757700,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572357,
    line_name: "Despesas com Deprec. de Máq, Equipamentos e MU",
    line_ref: "[2184]",
    line_code: "[4-1-03-10-03]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277318,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711818,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310734,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924434,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966294,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187802,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834718,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088994,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196390,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188074,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -86.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229934,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -137.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795506,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757700,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -137.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757701,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572358,
    line_name: "Despesas com Prestação de Serviços Terceirizados",
    line_ref: "[2212]",
    line_code: "[4-1-03-11]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277319,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711819,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310735,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924435,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966295,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187803,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834719,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088995,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196391,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188075,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -17862.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229935,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795507,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757701,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757702,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572359,
    line_name: "Desp c/ Prest de Serv de Pessoa Jurídica",
    line_ref: "[2226]",
    line_code: "[4-1-03-11-02]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277320,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711820,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310736,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924436,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966296,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187804,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834720,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088996,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196392,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188076,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -17862.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229936,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795508,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350519,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -852.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162432,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -877.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350160,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014624,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202802,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162183,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154335,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882745,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006545,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982314,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451332,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757702,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757703,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572360,
    line_name: "Despesas Tributárias",
    line_ref: "[2240]",
    line_code: "[4-1-04]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277321,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -378.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711821,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1123.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310737,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924437,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -2490.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966297,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187805,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -13.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834721,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -108.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088997,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -14.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196393,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -283.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188077,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -2150.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229937,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -6476.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795509,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -69.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519282,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -847.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217037,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -1469.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765610,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -26.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067853,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -2734.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597176,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -928.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013676,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -5790.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908071,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -7383.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457264,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -1205.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350520,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -1114.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162433,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -763.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350161,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -1761.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014625,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -849.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202803,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162184,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154336,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882746,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006546,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982315,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451333,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065048,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -1404.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992212,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641433,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -3176.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453918,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233628,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913309,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -376.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477855,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -1320.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568866,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -28.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692646,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223724,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689265,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384315,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768757703,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757704,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572361,
    line_name: "Impostos, Taxas e Contribuições",
    line_ref: "[2247]",
    line_code: "[4-1-04-01]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277322,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -378.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711822,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1123.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310738,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924438,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -2490.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966298,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187806,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -13.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834722,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -108.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088998,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -3.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196394,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -65.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188078,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -1652.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229938,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -6476.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795510,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -69.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519283,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -847.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217038,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -1469.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765611,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -26.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067854,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -2734.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597177,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -928.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013677,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -5790.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908072,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -7383.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457265,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -1205.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350521,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -1114.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162434,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -763.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350162,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -1761.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014626,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -849.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202804,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162185,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154337,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882747,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006547,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982316,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451334,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065049,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -1404.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992213,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641434,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -3176.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453919,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233629,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913310,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -376.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477856,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -1320.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568867,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -28.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692647,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223725,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689266,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384316,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768757704,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757705,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572362,
    line_name: "Despesas com PIS",
    line_ref: "[5301]",
    line_code: "[4-1-04-01-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277323,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711823,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310739,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924439,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966299,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187807,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834723,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990088999,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196395,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188079,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229939,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -627.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795511,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757705,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757706,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572363,
    line_name: "Despesas com COFINS",
    line_ref: "[5302]",
    line_code: "[4-1-04-01-02]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277324,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711824,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310740,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924440,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966300,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187808,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834724,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089000,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196396,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188080,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229940,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -2893.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795512,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757706,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757707,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572364,
    line_name: "Despesas com ISS",
    line_ref: "[5303]",
    line_code: "[4-1-04-01-03]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277325,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711825,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310741,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924441,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966301,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187809,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834725,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089001,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196397,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188081,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229941,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -2893.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043795513,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908073,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -340.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457266,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -340.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350522,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -340.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162435,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -340.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275350163,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -210.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014627,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202805,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162186,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154338,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882748,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006548,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982317,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451335,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757707,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757708,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572365,
    line_name: "Despesas com Impostos e Taxas Diversas",
    line_ref: "[2275]",
    line_code: "[4-1-04-01-04]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277326,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711826,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -745.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310742,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924442,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -2490.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966302,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187810,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834726,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089002,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196398,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188082,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -1605.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229942,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811898,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519284,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -847.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217039,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -1469.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765612,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067855,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -826.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597178,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -399.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013678,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908074,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -79.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457267,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -674.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350523,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -735.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162436,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -353.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275366548,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -1551.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014628,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -500.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202806,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162187,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154339,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882749,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006549,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982318,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451336,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065050,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -1404.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992214,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641435,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -3176.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453920,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233630,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913311,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -376.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477857,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568868,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -28.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692648,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223726,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689267,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384317,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768757708,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757709,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572366,
    line_name: "Despesas com CPMF/IOF / IOC",
    line_ref: "[2282]",
    line_code: "[4-1-04-01-05]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277327,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -378.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711827,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -378.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310743,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932924443,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966303,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187811,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -13.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834727,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -108.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089003,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -3.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196399,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -65.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188083,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -47.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229943,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -61.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811899,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -69.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507905,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -36.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271167,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -69.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984131,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -55.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812497,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -44.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510240,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519285,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -1873.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217040,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -6967.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765613,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -399.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067856,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -326.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597179,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -326.92,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013679,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -424.23,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908075,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -976.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457268,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -807.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350524,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -421.68,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162437,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -2037.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275366549,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -625.97,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014629,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -474.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202807,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -399.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162188,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -247.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154340,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -339.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882750,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -459.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006550,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -1013.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982319,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -293.11,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451337,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -2030.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065051,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -475.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992215,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -194.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641436,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -186.38,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453921,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -364.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233631,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -326.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913312,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -287.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477858,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -278.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568869,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -349.93,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692649,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -372.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223727,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -491.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689268,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384318,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768757709,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -609.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757710,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572367,
    line_name: "Multas Fiscais",
    line_ref: "[2331]",
    line_code: "[4-1-04-02]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277328,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711828,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310744,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940828,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966304,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187812,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834728,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089004,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -11.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196400,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -218.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188084,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -497.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229944,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811900,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757710,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757711,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572368,
    line_name: "Despesas com Multas Compensatórias",
    line_ref: "[2338]",
    line_code: "[4-1-04-02-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277329,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711829,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310745,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940829,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966305,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187813,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834729,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089005,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -11.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196401,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188085,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -409.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029229945,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811901,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067857,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -1907.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597180,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -529.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013680,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908076,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -27.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477859,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -1320.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568870,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692650,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223728,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689269,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384319,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768757711,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757712,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572369,
    line_name: "Despesas com Multas de Infração",
    line_ref: "[2345]",
    line_code: "[4-1-04-02-02]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277330,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711830,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310746,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940830,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966306,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187814,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834730,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089006,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196402,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -200.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188086,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -88.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246330,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811902,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765614,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -26.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067858,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597181,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013681,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908077,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457269,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -191.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350525,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -39.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162438,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -70.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275366550,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014630,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -349.07,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202808,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162189,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154341,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882751,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006551,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982320,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451338,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757712,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757713,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208572370,
    line_name: "Juros sobre parcelamentos",
    line_ref: "[2352]",
    line_code: "[4-1-04-02-03]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277331,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711831,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310747,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940831,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966307,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187815,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834731,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089007,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196403,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -18.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188087,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246331,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811903,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271168,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -181.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984132,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -50.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812498,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -60.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510241,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -937.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519286,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -947.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217041,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -1326.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765615,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -1460.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067859,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -866.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597182,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -866.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013682,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -866.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908078,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757713,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -866.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757714,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588755,
    line_name: "Despesas Financeiras",
    line_ref: "[2359]",
    line_code: "[4-1-05]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277332,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -1330.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711832,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -1173.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310748,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -653.79,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940832,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -895.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945966308,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -2811.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187816,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -4435.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834732,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -3067.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089008,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -1531.44,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196404,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -2266.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188088,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -2549.86,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246332,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -2029.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811904,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757714,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -2205.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757715,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588756,
    line_name: "Despesas com Juros",
    line_ref: "[2373]",
    line_code: "[4-1-05-02]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277333,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -983.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711833,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -847.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310749,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -258.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940833,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -379.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982693,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187817,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834733,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -2298.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089009,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -996.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196405,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -1731.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188089,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -1839.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246333,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -1435.74,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811905,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -1488.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507906,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -1068.17,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271169,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -3485.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984133,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -2480.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812499,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -1030.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510242,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519287,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217042,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -3307.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765616,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -12425.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067860,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: -7803.28,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189597183,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -78.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013683,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -371.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908079,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -41854.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457270,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -21568.32,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350526,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -19055.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162439,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -18797.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275366551,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -18007.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014631,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202809,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -62.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162190,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -210.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154342,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882752,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -402.18,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006552,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -269.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982321,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -261.64,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451339,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992216,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -12.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641437,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -121.09,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453922,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -46.21,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233632,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -467.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913313,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477860,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -2337.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568871,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692651,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223729,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689270,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384320,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768757715,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -47256.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757716,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588757,
    line_name: "Despesas com Tarifas Bancárias",
    line_ref: "[5002]",
    line_code: "[4-1-05-04]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277334,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: -347.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711834,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: -326.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310750,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -395.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940834,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: -516.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982694,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: -2811.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187818,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -4435.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834734,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -729.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089010,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: -534.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196406,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -535.35,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188090,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: -710.45,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246334,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: -593.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811906,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -716.6,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059507907,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: -282.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072271170,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: -143.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984134,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -761.95,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812500,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: -740.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510243,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: -810.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519288,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: -765.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217043,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -232.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765617,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: -2612.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067861,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 99.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189596160,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: -662.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013684,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: -403.7,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908080,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -844.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232457271,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: -659.55,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247350527,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: -484.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261162440,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: -780.04,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275366552,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: -757.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629289014632,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: -695.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304202810,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: -955.87,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318162191,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: -1061.84,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332154343,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: -4455.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346882753,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: -3347.58,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629360006553,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: -2977.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373982322,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: -2685.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451340,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: -4464.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400065052,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: -3067.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412992217,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: -3449.98,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424641438,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: -3493.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437453923,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: -2291.96,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450233633,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: -1890.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465913314,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: -1973.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480477861,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: -1763.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493568872,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: -2098.97,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506692652,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: -2202.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524223730,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: -1782.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541689271,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: -1115.02,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557384321,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768757716,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: -1764.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757717,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588758,
    line_name: "Tarifa com Emprestimos",
    line_ref: "[2135]",
    line_code: "[4-1-05-05]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277335,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711835,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310751,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940835,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982695,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187819,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834735,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: -40.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089011,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196407,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188091,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246335,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811907,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757717,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757718,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588759,
    line_name: "Resultado Não Operacional",
    line_ref: "[2401]",
    line_code: "[4-2]",
    index: null,
    level: 1,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277336,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711836,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310752,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940836,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982696,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187820,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834736,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089012,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196408,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188092,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246336,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811908,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757718,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -690000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757719,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588760,
    line_name: "Outras Perdas Não Operacionais",
    line_ref: "[2513]",
    line_code: "[4-2-02]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277337,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711837,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310753,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940837,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982697,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187821,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834737,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089013,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196409,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188093,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246337,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811909,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757719,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -690000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757720,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588761,
    line_name: "Perda no Recebimento de Credito",
    line_ref: "[2499]",
    line_code: "[4-2-02-01]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277338,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711838,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310754,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940838,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982698,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187822,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834738,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089014,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196410,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188094,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246338,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811910,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757720,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -690000.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757721,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588762,
    line_name: "Provisão de IRPJ e CSLL",
    line_ref: "[2506]",
    line_code: "[4-3]",
    index: null,
    level: 1,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277339,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711839,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310755,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -10461.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940839,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982699,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187823,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -8578.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834739,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089015,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196411,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -9484.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188095,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246339,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811911,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -13759.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984135,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -32644.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812501,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510244,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519289,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217044,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -39417.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765618,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067862,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189596161,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013685,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908081,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -79786.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451341,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757721,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 1256.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757722,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588763,
    line_name: "IRPJ E CSLL",
    line_ref: "[2408]",
    line_code: "[4-3-01]",
    index: null,
    level: 2,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277340,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711840,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310756,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -10461.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940840,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982700,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187824,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -8578.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834740,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089016,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196412,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -9484.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188096,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246340,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811912,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -13759.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984136,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -32644.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812502,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510245,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519290,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217045,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -39417.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765619,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067863,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189596162,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205013686,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908082,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -79786.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451342,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757722,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 1256.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757723,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588764,
    line_name: "IRPJ E CSLL",
    line_ref: "[2471]",
    line_code: "[4-3-01-01]",
    index: null,
    level: 3,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277341,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904711841,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310757,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -10461.72,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940841,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982701,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187825,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -8578.88,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834741,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089017,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196413,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -9484.4,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188097,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246341,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811913,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -13759.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984137,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -32644.19,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812503,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510246,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519291,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217046,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -39417.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765620,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067864,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189596163,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205030071,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908083,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -79786.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385451343,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768757723,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 1256.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768757724,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588765,
    line_name: "Despesas com IRPJ",
    line_ref: "[2303]",
    line_code: "[4-3-01-01-01]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277342,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904728226,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310758,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -6538.57,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940842,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982702,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187826,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -5361.8,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834742,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990089018,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196414,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -5927.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188098,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246342,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811914,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -8733.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984138,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -22414.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812504,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510247,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519292,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217047,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -27395.2,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152765621,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067865,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189596164,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205030072,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908084,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768757724,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -55477.03,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768904313,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208588766,
    line_name: "Despesas com CSLL",
    line_ref: "[2310]",
    line_code: "[4-3-01-01-02]",
    index: null,
    level: 4,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890277343,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904728227,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918310759,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: -3923.15,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932940843,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945982703,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960187827,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: -3217.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975834743,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990105403,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003196415,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: -3556.65,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016188099,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029246343,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043811915,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -5026.3,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086984139,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: -10229.34,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098812505,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111510248,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124519293,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137217048,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: -12022.27,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152782006,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169067866,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189596165,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629205030073,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 0.0,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217908085,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768904313,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: -24309.73,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768708004,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555911,
    line_name: "(=) EBITDA",
    line_ref: "[1449]",
    line_code: "[3]",
    index: null,
    level: 0,
    formula: null,
    is_custom: false,
    is_calculated: true,
    is_cash: false,
    is_total: null,
    is_metric: null,
    extra: null,
    values: [
      {
        id: 659628890260872,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 21452.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678988,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 27798.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918277904,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 56459.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909012,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 58541.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949848,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 23489.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154972,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 22244.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975801888,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 34712.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072548,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 38317.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163560,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 42251.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016155244,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 33115.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029197104,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 96462.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779060,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -6129.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059475098,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 189048.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072238358,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 75450.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967704,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 123156.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796069,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 189519.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111493812,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 277496.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124502853,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 205591.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200604,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 139781.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152732792,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 125863.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169035034,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 227104.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189564357,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 166226.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629204997241,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 168184.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217875252,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 164582.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440839,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 159871.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247317708,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 160332.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261129620,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 187608.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275333731,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 189838.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981810,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 193059.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304169987,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 193532.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145752,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 216961.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332121520,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 303298.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346849930,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 303559.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990114,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 307493.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373949499,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 289986.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434901,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 434709.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400033263,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 331031.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975786,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 338277.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424625004,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 129169.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437437489,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 137142.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450200814,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 245947.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465896879,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 256294.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480461425,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 263534.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552434,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 308258.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676213,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 323280.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524174523,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 328849.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541640064,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 331088.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557367881,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 334115.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
  {
    id: 659628768708005,
    created_at: "2022-09-02T00:29:04.363632",
    updated_at: null,
    tenant_id: 659627653269487,
    statement_id: 659628751456084,
    parent_line_id: null,
    account_id: 659628208555911,
    line_name: "% EBITDA Margin",
    line_ref: "[1449]",
    line_code: "[3]",
    index: null,
    level: 0,
    formula: null,
    is_custom: false,
    is_calculated: false,
    is_cash: false,
    is_total: null,
    is_metric: true,
    extra: null,
    values: [
      {
        id: 659628890260872,
        created_at: "2022-09-02T00:29:12.378833",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-01-01",
        start_date: null,
        end_date: null,
        value: 21452.13,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628904678988,
        created_at: "2022-09-02T00:29:13.254391",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-02-01",
        start_date: null,
        end_date: null,
        value: 27798.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628918277904,
        created_at: "2022-09-02T00:29:14.088742",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-03-01",
        start_date: null,
        end_date: null,
        value: 56459.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628932909012,
        created_at: "2022-09-02T00:29:14.981770",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-04-01",
        start_date: null,
        end_date: null,
        value: 58541.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628945949848,
        created_at: "2022-09-02T00:29:15.778300",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-05-01",
        start_date: null,
        end_date: null,
        value: 23489.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628960154972,
        created_at: "2022-09-02T00:29:16.643828",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-06-01",
        start_date: null,
        end_date: null,
        value: 22244.24,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628975801888,
        created_at: "2022-09-02T00:29:17.599162",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-07-01",
        start_date: null,
        end_date: null,
        value: 34712.85,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659628990072548,
        created_at: "2022-09-02T00:29:18.470772",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-08-01",
        start_date: null,
        end_date: null,
        value: 38317.56,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629003163560,
        created_at: "2022-09-02T00:29:19.270219",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-09-01",
        start_date: null,
        end_date: null,
        value: 42251.91,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629016155244,
        created_at: "2022-09-02T00:29:20.061041",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-10-01",
        start_date: null,
        end_date: null,
        value: 33115.69,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629029197104,
        created_at: "2022-09-02T00:29:20.859264",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-11-01",
        start_date: null,
        end_date: null,
        value: 96462.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629043779060,
        created_at: "2022-09-02T00:29:21.748409",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2017-12-01",
        start_date: null,
        end_date: null,
        value: -6129.71,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629059475098,
        created_at: "2022-09-02T00:29:22.705781",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-01-01",
        start_date: null,
        end_date: null,
        value: 189048.29,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629072238358,
        created_at: "2022-09-02T00:29:23.485346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-02-01",
        start_date: null,
        end_date: null,
        value: 75450.25,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629086967704,
        created_at: "2022-09-02T00:29:24.385467",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-03-01",
        start_date: null,
        end_date: null,
        value: 123156.08,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629098796069,
        created_at: "2022-09-02T00:29:25.106792",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-04-01",
        start_date: null,
        end_date: null,
        value: 189519.94,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629111493812,
        created_at: "2022-09-02T00:29:25.882715",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-05-01",
        start_date: null,
        end_date: null,
        value: 277496.99,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629124502853,
        created_at: "2022-09-02T00:29:26.676897",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-06-01",
        start_date: null,
        end_date: null,
        value: 205591.48,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629137200604,
        created_at: "2022-09-02T00:29:27.449936",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-07-01",
        start_date: null,
        end_date: null,
        value: 139781.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629152732792,
        created_at: "2022-09-02T00:29:28.396771",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-08-01",
        start_date: null,
        end_date: null,
        value: 125863.78,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629169035034,
        created_at: "2022-09-02T00:29:29.393367",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-09-01",
        start_date: null,
        end_date: null,
        value: 227104.41,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629189564357,
        created_at: "2022-09-02T00:29:30.645070",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-10-01",
        start_date: null,
        end_date: null,
        value: 166226.05,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629204997241,
        created_at: "2022-09-02T00:29:31.587882",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-11-01",
        start_date: null,
        end_date: null,
        value: 168184.16,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629217875252,
        created_at: "2022-09-02T00:29:32.375580",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2018-12-01",
        start_date: null,
        end_date: null,
        value: 164582.37,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629232440839,
        created_at: "2022-09-02T00:29:33.263242",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-01-01",
        start_date: null,
        end_date: null,
        value: 159871.39,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629247317708,
        created_at: "2022-09-02T00:29:34.172035",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-02-01",
        start_date: null,
        end_date: null,
        value: 160332.81,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629261129620,
        created_at: "2022-09-02T00:29:35.012762",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-03-01",
        start_date: null,
        end_date: null,
        value: 187608.46,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629275333731,
        created_at: "2022-09-02T00:29:35.882568",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-04-01",
        start_date: null,
        end_date: null,
        value: 189838.47,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629288981810,
        created_at: "2022-09-02T00:29:36.711011",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-05-01",
        start_date: null,
        end_date: null,
        value: 193059.22,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629304169987,
        created_at: "2022-09-02T00:29:37.612388",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-06-01",
        start_date: null,
        end_date: null,
        value: 193532.61,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629318145752,
        created_at: "2022-09-02T00:29:38.493821",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-07-01",
        start_date: null,
        end_date: null,
        value: 216961.59,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629332121520,
        created_at: "2022-09-02T00:29:39.347825",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-08-01",
        start_date: null,
        end_date: null,
        value: 303298.82,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629346849930,
        created_at: "2022-09-02T00:29:40.247419",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-09-01",
        start_date: null,
        end_date: null,
        value: 303559.63,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629359990114,
        created_at: "2022-09-02T00:29:41.048093",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-10-01",
        start_date: null,
        end_date: null,
        value: 307493.12,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629373949499,
        created_at: "2022-09-02T00:29:41.901032",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-11-01",
        start_date: null,
        end_date: null,
        value: 289986.51,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629385434901,
        created_at: "2022-09-02T00:29:42.599826",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2019-12-01",
        start_date: null,
        end_date: null,
        value: 434709.76,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629400033263,
        created_at: "2022-09-02T00:29:43.492395",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-01-01",
        start_date: null,
        end_date: null,
        value: 331031.52,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629412975786,
        created_at: "2022-09-02T00:29:44.283346",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-02-01",
        start_date: null,
        end_date: null,
        value: 338277.89,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629424625004,
        created_at: "2022-09-02T00:29:44.994719",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-03-01",
        start_date: null,
        end_date: null,
        value: 129169.36,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629437437489,
        created_at: "2022-09-02T00:29:45.776947",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-04-01",
        start_date: null,
        end_date: null,
        value: 137142.26,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629450200814,
        created_at: "2022-09-02T00:29:46.552473",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-05-01",
        start_date: null,
        end_date: null,
        value: 245947.5,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629465896879,
        created_at: "2022-09-02T00:29:47.512677",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-06-01",
        start_date: null,
        end_date: null,
        value: 256294.77,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629480461425,
        created_at: "2022-09-02T00:29:48.402459",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-07-01",
        start_date: null,
        end_date: null,
        value: 263534.33,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629493552434,
        created_at: "2022-09-02T00:29:49.199869",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-08-01",
        start_date: null,
        end_date: null,
        value: 308258.54,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629506676213,
        created_at: "2022-09-02T00:29:50.001399",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-09-01",
        start_date: null,
        end_date: null,
        value: 323280.14,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629524174523,
        created_at: "2022-09-02T00:29:51.064020",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-10-01",
        start_date: null,
        end_date: null,
        value: 328849.43,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629541640064,
        created_at: "2022-09-02T00:29:52.125080",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-11-01",
        start_date: null,
        end_date: null,
        value: 331088.53,
        dimensions: null,
        extra: null,
        notes: [],
      },
      {
        id: 659629557367881,
        created_at: "2022-09-02T00:29:53.095763",
        updated_at: null,
        line_id: 659628768708486,
        period: "monthly",
        data_source: null,
        version: null,
        date: "2020-12-01",
        start_date: null,
        end_date: null,
        value: 334115.75,
        dimensions: null,
        extra: null,
        notes: [],
      },
    ],
    comments: [],
  },
];
