import Select from "@synerise/ds-select";
import { Props } from "@synerise/ds-select/dist/Select.types";

import { Currency } from "@/core/support-infos/support-infos.types";
import { formatHexSymbolsToUnicode } from "@/utils/formatHexSymbolsToUnicode";

type SelectCurrencyProps = {
  currencies: Currency[];
  description?: string;
  showErrorText: boolean;
  errorText?: string;
} & Props;

export function SelectCurrency({
  currencies,
  description,
  showErrorText,
  errorText,
  ...rest
}: SelectCurrencyProps) {
  return (
    <Select
      {...rest}
      label="Currency"
      showSearch
      description={description}
      placeholder={"Currency"}
      errorText={showErrorText ? errorText : undefined}
      options={currencies.map((currency) => ({
        value: currency.code,
        name: currency.name,
        label: (
          <p>
            {currency.name} (
            {currency && currency.symbol_hex && formatHexSymbolsToUnicode(currency.symbol_hex)})
          </p>
        ),
      }))}
      filterOption={(inputValue, option) => {
        return option!.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
      }}
    />
  );
}
