import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

import { useDimensions } from "@/hooks/useDimensions";
import { useDimensionTypes } from "@/hooks/useDimensionTypes";

import { dataManagementData } from "../data-management.data";
import { leftLayoutContent } from "../DataManagementLeftLayoutContent";
import { DimensionsTable } from "./DimensionsTable";

export function Dimensions() {
  const navigate = useNavigate();
  const { allDimensionValues } = useDimensionTypes();
  const { mapDimensionsListToTable } = useDimensions();

  return (
    <Layout
      left={leftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate(dataManagementData.home.path)}
          title="Dimensions Values"
          rightSide={
            <Button
              type="primary"
              onClick={() => navigate(dataManagementData.dimensions.createPath)}
            >
              New dimension value
            </Button>
          }
        />
      }
    >
      <DimensionsTable dimensions={mapDimensionsListToTable(allDimensionValues)} />
    </Layout>
  );
}
