import styled from "styled-components";

export const MaxWidthContent = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const AvatarInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  gap: 12px;
`;

export const HeaderSideChildrenContainer = styled.div`
  display: flex;
  gap: 12px;
`;
