import { Field } from "react-final-form";

import Avatar from "@synerise/ds-avatar";
import Card from "@synerise/ds-card";
import { LockM } from "@synerise/ds-icon";
import InlineEdit from "@synerise/ds-inline-edit";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";
import Typography from "@synerise/ds-typography";

import { InputFormula } from "@/components/InputFormula/InputFormula";
import { StarRate } from "@/components/StarRate";
import { useEntities } from "@/hooks/useEntities";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { Importance } from "../kpi.types";
import { useKpiGroups } from "./hooks/useKpiGroups";
import { useKpis } from "./hooks/useKpis";

const { Title, Text } = Typography;

type KPIFormCreateDetailsStepProps = {
  importance: Importance;
  setImportance: (importance: Importance) => void;
  isEntity: boolean;
  isCalculated: boolean;
};

export function KPIFormCreateDetailsStep({
  importance,
  isEntity,
  isCalculated,
  setImportance,
}: KPIFormCreateDetailsStepProps) {
  const { groups } = useKpiGroups();
  const { units } = useSupportInfos();
  const { allEntities } = useEntities();
  const { kpis } = useKpis({ show_values: false });

  console.log("kpis: ", kpis);

  return (
    <>
      <ModuleStyled.AvatarContainer>
        <Avatar shape="square" size="large" backgroundColor="purple" backgroundColorHue="500">
          AS
        </Avatar>
        <ModuleStyled.AvatarInputsWrapper>
          <Field name="title">
            {({ input, meta }) => (
              <InlineEdit
                input={{ ...input, placeholder: "KPI Title" }}
                size="normal"
                disabled={false}
                hideIcon={false}
                error={meta.touched && meta.error}
              />
            )}
          </Field>
          <Field name="description">
            {({ input, meta }) => (
              <InlineEdit
                input={{ ...input, placeholder: "KPI Description" }}
                size="small"
                disabled={false}
                hideIcon={false}
                error={meta.touched && meta.error}
              />
            )}
          </Field>
        </ModuleStyled.AvatarInputsWrapper>
      </ModuleStyled.AvatarContainer>
      <Card
        background="white-shadow"
        title="Main Information"
        withHeader
        description="The KPIs main information"
        icon={<LockM />}
        iconColor={"#AEAEAE"}
      >
        <ModuleStyled.InputWrapper>
          <Field
            name="periodicity"
            render={({ input, meta }) => (
              <Select
                {...input}
                label="Periodicity"
                description="Periodic interval for the KPI values"
                errorText={meta.touched && meta.error}
              >
                <Select.Option value="daily">Day</Select.Option>
                <Select.Option value="weekly">Week</Select.Option>
                <Select.Option value="monthly">Month</Select.Option>
                <Select.Option value="quarterly">Quarter</Select.Option>
                <Select.Option value="yearly">Year</Select.Option>
              </Select>
            )}
          />
          <Field
            name="group_id"
            render={({ input, meta }) => (
              <Select
                {...input}
                label="Group"
                description="Select the group this KPI will be a part of"
                errorText={meta.touched && meta.error}
                allowClear
              >
                {groups.map((group) => (
                  <Select.Option key={group.id} value={group.id}>
                    {group.title}
                  </Select.Option>
                ))}
              </Select>
            )}
          />

          <Field
            name="units_id"
            render={({ input, meta }) => (
              <Select
                {...input}
                label="Unit"
                description="Select the unit type that will be used in this KPI"
                errorText={meta.touched && meta.error}
              >
                {units.map((unit) => (
                  <Select.Option key={unit.id} value={unit.id}>
                    {unit.title}
                  </Select.Option>
                ))}
              </Select>
            )}
          />

          <Field name="aggregation">
            {({ input, meta }) => (
              <Select
                {...input}
                label="Aggregation"
                description="How to aggregate the KPI for different periods"
                errorText={meta.touched && meta.error}
              >
                <Select.Option value="sum">Sum</Select.Option>
                <Select.Option value="average">Average</Select.Option>
              </Select>
            )}
          </Field>

          <Title level={4}>Importance</Title>
          <StarRate rate={importance} setRate={setImportance} />
          <Text style={{ marginBottom: "15px" }}>Set the importance of this KPI</Text>

          <Field name="is_calculated">
            {({ input, meta }) => (
              <Switch
                {...input}
                label="Calculated"
                description="Select if this KPI is calculated from another"
                checked={input.value}
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>
          {isCalculated && (
            <div
              style={{
                marginTop: "7px",
                marginBottom: "15px",
              }}
            >
              <InputFormula label="KPI Formula" onChange={(expression) => {}} suggestions={kpis} />
            </div>
          )}

          <Field name="is_higher">
            {({ input, meta }) => (
              <Switch
                {...input}
                label="Higher is better"
                description="Select if the values of this KPI are meant to be interpreted as higher values better performance"
                checked={input.value}
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>

          <Field name="is_shared">
            {({ input, meta }) => (
              <Switch
                {...input}
                label="Shared"
                description="Select if this KPI will be shared across entities"
                checked={input.value}
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>

          <Field name="is_entity">
            {({ input, meta }) => (
              <Switch
                {...input}
                label="Is a entity"
                description="Select if this KPI is a entity"
                checked={input.value}
                errorText={meta.touched && meta.error}
              />
            )}
          </Field>

          {isEntity && (
            <Field name="entity_id">
              {({ input, meta }) => (
                <Select {...input} label="Entity" errorText={meta.touched && meta.error}>
                  {allEntities.map((entity) => (
                    <Select.Option key={entity.id} value={entity.id}>
                      {entity.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Field>
          )}
        </ModuleStyled.InputWrapper>
      </Card>
    </>
  );
}
