import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import ButtonGroup from "@synerise/ds-button-group";
import Dropdown from "@synerise/ds-dropdown";
import Icon, { AngleDownS, FolderArchiveM } from "@synerise/ds-icon";
import Menu from "@synerise/ds-menu";
import PageHeader from "@synerise/ds-page-header";

import * as Styled from "./styles";

type KPILibraryHeaderProps = {
  onToggleKPIGroupModal: () => void;
  onImportKpiClick: () => void;
};

const dropdownItems = (onImportKpiClick: () => void) => {
  return (
    <Dropdown.Wrapper style={{ width: "220px" }}>
      <Menu style={{ width: "100%" }} asDropdownMenu>
        <Menu.Item onClick={onImportKpiClick} prefixel={<Icon component={<FolderArchiveM />} />}>
          Import KPI from file
        </Menu.Item>
      </Menu>
    </Dropdown.Wrapper>
  );
};

export function KPILibraryHeader({
  onToggleKPIGroupModal,
  onImportKpiClick,
}: KPILibraryHeaderProps) {
  const navigate = useNavigate();

  return (
    <PageHeader
      title="KPI Library"
      onGoBack={() => navigate("/kpis/dashboard")}
      rightSide={
        <>
          <Button onClick={onToggleKPIGroupModal}>Create KPI Group</Button>
          <Styled.RightSideContainer>
            <ButtonGroup buttonsPosition="center" splitMode>
              <Button type="primary" onClick={() => navigate("/kpis/library/create")}>
                Create New Kpi
              </Button>
              <Button mode="single-icon" type="primary">
                <Dropdown
                  overlay={() => dropdownItems(onImportKpiClick)}
                  overlayStyle={{
                    borderRadius: "3px",
                  }}
                  placement="bottomLeft"
                >
                  <Icon component={<AngleDownS />} />
                </Dropdown>
              </Button>
            </ButtonGroup>
          </Styled.RightSideContainer>
        </>
      }
    />
  );
}
