export const options: ApexCharts.ApexOptions = {
  grid: {
    padding: {
      right: -30,
      left: -30,
      top: -15,
      bottom: -15,
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: 0,
      endAngle: 360,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24,
        },
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
      },

      dataLabels: {
        name: {
          show: false,
          color: "#888",
          fontSize: "13px",
        },
        value: {
          formatter: function (val: any) {
            return `${val}%`;
          },
          color: "#888",
          fontSize: "24px",
          show: true,
          offsetY: 8,
        },
      },
    },
  },
};
