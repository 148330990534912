import { AxiosResponse } from "axios";

import { api } from "@/utils/http";

import { AccessGroup, AccessGroupPost, AccessGroupUpdate } from "./access-groups.types";

class AccessGroups {
  async getAllAccessGroups() {
    const response: AxiosResponse = await api.get("/accessGroups");
    const allAccessGroups: AccessGroup[] = response.data;
    return allAccessGroups;
  }

  async postAccessGroup(accessGroupPost: AccessGroupPost) {
    const response: AxiosResponse = await api.post("/accessGroups", accessGroupPost);
    const accessGroup: AccessGroup = response.data;
    return accessGroup;
  }

  async getAccessGroup(accessGroupID: string) {
    const response: AxiosResponse = await api.get(`/accessGroups/${accessGroupID}`);
    const accessGroup: AccessGroup[] = response.data;
    return accessGroup;
  }

  async deleteAccessGroup(accessGroupID: number) {
    const response: AxiosResponse = await api.delete(`/accessGroups/${accessGroupID}`);
    const data: ApiStatusResponse = response.data;
    return data;
  }

  async updateAccessGroup(accessGroupID: string, accessGroupUpdate: AccessGroupUpdate) {
    const response: AxiosResponse = await api.patch(
      `/accessGroups/${accessGroupID}`,
      accessGroupUpdate
    );
    const accessGroup: AccessGroup = response.data;
    return accessGroup;
  }
}

const AccessGroupsService = new AccessGroups();
export default AccessGroupsService;
