import Button from "@synerise/ds-button";

type SelectChartOptionProps = {
  children: React.ReactNode;
  selected: boolean;
};

export function SelectChartOption({ selected, children }: SelectChartOptionProps) {
  return (
    <Button
      type={selected ? "primary" : "secondary"}
      style={{
        padding: "12px 16px",
      }}
    >
      {children}
    </Button>
  );
}
