import { AccountsMappingContent } from "./AccountsMappingContent";
import { AccountsMappingProvider } from "./hooks/useAccountsMappingContext";

export function AccountsMapping() {
  return (
    <AccountsMappingProvider>
      <AccountsMappingContent />
    </AccountsMappingProvider>
  );
}
