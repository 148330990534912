import styled from "styled-components";

export const ImageUploadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  justify-content: space-between;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;
