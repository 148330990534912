import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import message from "@synerise/ds-message";
import Result from "@synerise/ds-result";

import * as Styled from "../login.styles";
import { InvitationStatusMembershipEmailNotAccepted } from "./InvitationStatusMembershipEmailNotAccepted";
import { InvitationStatusMembershipNotActive } from "./InvitationStatusMembershipNotActive";

export function InvitationStatus() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tokenQuery = searchParams.get("token");
  const acceptedQuery = searchParams.get("accepted");
  const confirmedQuery = searchParams.get("confirmed");
  const activeQuery = searchParams.get("active");
  const registeredQuery = searchParams.get("registered");

  const tokenExists = tokenQuery !== null;
  const isAccepted = acceptedQuery !== null && acceptedQuery.toLocaleLowerCase() === "true";
  const isConfirmed = confirmedQuery !== null && confirmedQuery.toLocaleLowerCase() === "true";
  const isActive = activeQuery !== null && activeQuery.toLocaleLowerCase() === "true";
  const isRegistered = registeredQuery !== null && registeredQuery.toLocaleLowerCase() === "true";

  useEffect(() => {
    if (!tokenExists) {
      message.error("Token not found");
      navigate("/");
      return;
    }

    if (!isConfirmed) {
      message.info("You still need to confirm your email address");
      navigate("/activate-email/" + tokenQuery);
      return;
    }

    if (isAccepted && isActive && !isRegistered) {
      message.info("You invite has been accepted. You need to register your account");
      navigate("/register/" + tokenQuery);
      return;
    }
  }, [tokenQuery, confirmedQuery]);

  return (
    <Styled.CardContainer>
      <Card title="Invitation" withHeader>
        {!isAccepted ? (
          <InvitationStatusMembershipEmailNotAccepted />
        ) : !isActive ? (
          <InvitationStatusMembershipNotActive />
        ) : (
          <>
            <Result
              type="success"
              title="Invite Accepted"
              description="You accepted the organization invite. You can now login and access the organization."
            />
            <Styled.FooterButtonsContainer>
              <Button onClick={() => navigate("/login")}>Back to Login</Button>
            </Styled.FooterButtonsContainer>
          </>
        )}
      </Card>
    </Styled.CardContainer>
  );
}
