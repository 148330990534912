import { useState } from "react";

import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";
import Tabs from "@synerise/ds-tabs";

import { FinancialsBalanceSheet } from "./FinancialsBS/FinancialsBS";
import { FinancialsCashFlow } from "./FinancialsCF/FinancialsCF";
import { FinancialsIncomeStatements } from "./FinancialsIS/FinancialsIS";
import { FinancialsRatios } from "./FinancialsRatios/FinancialsRatios";

export function Financials() {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      label: "Income Statement",
    },
    {
      label: "Balance Sheet",
    },
    {
      label: "Cash Flow",
    },
    {
      label: "Ratios",
    },
  ];

  function getFinancialComponent(activeTab: number) {
    switch (activeTab) {
      case 0:
        return <FinancialsIncomeStatements />;
      case 1:
        return <FinancialsBalanceSheet />;
      case 2:
        return <FinancialsCashFlow />;
      case 3:
        return <FinancialsRatios />;
    }
  }
  return (
    <Layout
      header={
        <PageHeader
          title="Financials"
          tabs={
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              handleTabClick={(index: number) => setActiveTab(index)}
            />
          }
        />
      }
    >
      {getFinancialComponent(activeTab)}
    </Layout>
  );
}
