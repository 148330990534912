import { Link, useLocation } from "react-router-dom";

import AppMenu from "@synerise/ds-app-menu";
import { AutomationColorM, AutomationGreyM } from "@synerise/ds-icon";

import kpiMenuOptions from "./kpi-menu-options";
export function KPIMenu() {
  const { pathname } = useLocation();

  return (
    <AppMenu.Item
      id="kpis"
      name="KPIs"
      className="app-menu-item"
      subMenu={
        <AppMenu.SubMenu>
          <Link to={kpiMenuOptions.link}>
            <AppMenu.SubMenu.Title>{kpiMenuOptions.title}</AppMenu.SubMenu.Title>
          </Link>

          {kpiMenuOptions.groups.map((group) => (
            <>
              <AppMenu.SubMenu.SubTitle>{group.title}</AppMenu.SubMenu.SubTitle>
              {group.items.map((item) => (
                <Link to={item.link}>
                  <AppMenu.SubMenu.Item active={pathname === item.link}>
                    {item.title}
                  </AppMenu.SubMenu.Item>
                </Link>
              ))}
            </>
          ))}
        </AppMenu.SubMenu>
      }
    >
      <AppMenu.Item.Icon active={<AutomationColorM />} inActive={<AutomationGreyM />} />
    </AppMenu.Item>
  );
}
