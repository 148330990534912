import { AxiosResponse } from "axios";

import { api } from "@/utils/http";

import {
  Entity,
  EntityCreatePayload,
  EntityType,
  EntityTypeCreatePayload,
  EntityTypeUpdatePayload,
  EntityUpdatePayload,
} from "./entities.types";

class EntityTypes {
  async list() {
    const response: AxiosResponse = await api.get("/entitiesTypes");
    const entityTypes: EntityType[] = response.data;
    return entityTypes;
  }

  async show(id: number) {
    const response: AxiosResponse = await api.get(`/entitiesTypes/${id}`);
    const entityType: EntityType = response.data;
    return entityType;
  }

  async create(payload: EntityTypeCreatePayload) {
    const response: AxiosResponse = await api.post("/entitiesTypes", payload);
    const entityType: EntityType = response.data;
    return entityType;
  }

  async delete(id: number) {
    const response: AxiosResponse = await api.delete(`/entitiesTypes/${id}`);
    const status: ApiStatusResponse = response.data;
    return status;
  }

  async update(id: number, payload: EntityTypeUpdatePayload) {
    const response: AxiosResponse = await api.patch(`/entitiesTypes/${id}`, payload);
    const entityType: EntityType = response.data;
    return entityType;
  }
}

class Entities {
  async list() {
    const response: AxiosResponse = await api.get("/entities");
    const data: Entity[] = response.data;
    return data;
  }

  async show(id: number) {
    const response: AxiosResponse = await api.get(`/entities/${id}`);
    const data: Entity = response.data;
    return data;
  }

  async create(payload: EntityCreatePayload) {
    const response: AxiosResponse = await api.post("/entities", payload);
    const data: Entity = response.data;
    return data;
  }

  async delete(id: number) {
    const response: AxiosResponse = await api.delete(`/entities/${id}`);
    const status: ApiStatusResponse = response.data;
    return status;
  }

  async update(id: number, payload: EntityUpdatePayload) {
    const response: AxiosResponse = await api.patch(`/entities/${id}`, payload);
    const data: Entity = response.data;
    return data;
  }
}

export const EntityTypesService = new EntityTypes();
export const EntityService = new Entities();
