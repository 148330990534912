import { Form, Field } from "react-final-form";
import {  useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";
import { REPORTS_QUERY_KEY, REPORTS_TAGS_QUERY_KEY } from "@/modules/reports/reports.config";
import { ReportService } from "@/modules/reports/reports.services";
import {
  ReportCreatePayload,
  ReportResponse,
  ReportUpdatePayload,
} from "@/modules/reports/reports.types";
import { queryClient } from "@/utils/queryClient";

import { useGridLayout } from "../../hooks/useGridLayout";
import { useReportBuilder } from "../../hooks/useReportBuilder";

type CreateDashboardViewFormValues = {
  name: string;
  description?: string;
  is_private: boolean;
  tags: number[];
};

const createDashboardSchema = yup.object().shape({
  name: yup.string().required("Name required"),
  description: yup.string(),
});

type ReportBuilderModalFormProps = {
  editingReport?: ReportResponse;
};

export function ReportBuilderModalForm({ editingReport }: ReportBuilderModalFormProps) {
  const validate = useValidationSchema(createDashboardSchema);
  const { items, toggleInPreviewMode } = useGridLayout();
  const { isReportCreateModalOpened, toggleReportCreateModal, isPrivate, changeIsPrivate, tags: selectedTags, handleChangeTagCheck, layoutOrientation } =
    useReportBuilder();
  const navigate = useNavigate();

  const createReportMutation = useMutation(
    [REPORTS_QUERY_KEY],
    (payload: ReportCreatePayload) => ReportService.create(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REPORTS_QUERY_KEY);
      },
    }
  );

  const updateReportMutation = useMutation(
    [REPORTS_QUERY_KEY],
    async ({ id, payload }: { id: number; payload: ReportUpdatePayload }) =>
      await ReportService.update(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REPORTS_QUERY_KEY);
      },
    }
  );

  const removeTagMutation = useMutation(
    [REPORTS_TAGS_QUERY_KEY],
    async ({ reportId, tagId }: { reportId: number, tagId: number }) =>
      await ReportService.deleteReportTag(reportId, tagId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REPORTS_QUERY_KEY);
        queryClient.invalidateQueries(REPORTS_TAGS_QUERY_KEY);
      },
    }
  );

  async function onSubmit(values: CreateDashboardViewFormValues) {
    const { name, is_private, description, tags } = values;

    const savingReport: ReportCreatePayload = {
      name,
      is_private,
      description,
      tags: tags,
      components: items,
      extra: {
        orientation: layoutOrientation
      }
    };
    
    selectedTags.forEach((selectedTag) => {
      handleChangeTagCheck(selectedTag.id, tags.includes(selectedTag.id))
    })

    if (editingReport) {
      const { id } = editingReport;
      await updateReportMutation.mutateAsync({ id, payload: savingReport });
      message.success(`${name} report saved!`);
      toggleReportCreateModal(false);
      changeIsPrivate(is_private);
      toggleInPreviewMode(true);

      if(tags.length < 1 && editingReport.tags.length > 0) {
        editingReport.tags.forEach(async (tag) => {
          await removeTagMutation.mutateAsync({
            reportId: editingReport.id,
            tagId: tag.id
          });
        })
      }
    } else {
      await createReportMutation.mutateAsync(savingReport);
      message.success(`${name} report created!`);
      toggleReportCreateModal(false);
      changeIsPrivate(is_private);
      navigate("/reports/list");
    }
  }

  const initialValues = {
    name: editingReport?.name || "",
    description: editingReport?.description || "",
    is_private: isPrivate,
    tags: selectedTags.filter(tag => tag.checked).map(tag => tag.id),
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit }) => {
        return (
          <Modal
            title="Save Report"
            destroyOnClose={true}
            onOk={() => handleSubmit()}
            visible={isReportCreateModalOpened}
            onCancel={() => toggleReportCreateModal()}
            okText="Create"
            bodyStyle={{
              padding: "16px 64px",
            }}
          >
            <Field
              name="name"
              render={({ input, meta }) => (
                <Input {...input} label="Name" errorText={meta.touched && meta.error} />
              )}
            />
            <Field
              name="description"
              render={({ input, meta }) => (
                <Input {...input} label="Description" errorText={meta.touched && meta.error} />
              )}
            />
            <Field
              name="tags"
              render={({ input, meta }) => (
                <Select
                  {...input}
                  label="Tags"
                  errorText={meta.touched && meta.error}
                  mode="tags"
                >
                  {selectedTags?.map((tag) => (
                    <Select.Option key={tag.id} value={tag.id}>
                      {tag.title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
            <ModuleStyled.SwitchWrapper>
              <Field
                name="is_private"
                render={({ input }) => {
                  return <Switch {...input} label="Private Report" checked={input.value} />;
                }}
              />
            </ModuleStyled.SwitchWrapper>
          </Modal>
        );
      }}
    />
  );
}
