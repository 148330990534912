import { Input } from "@synerise/ds-input";
import Select from "@synerise/ds-select";

type DatePickerProps = {
  month: number;
  year: number;
  onChangeMonth: (value: number) => void;
  onChangeYear: (value: number) => void;
};

export function DatePicker({ month, year, onChangeMonth, onChangeYear }: DatePickerProps) {
  return (
    <>
      <div>
        <Select label="Month" value={month} onChange={(value: any) => onChangeMonth(value)}>
          <Select.Option value={1}>January</Select.Option>
          <Select.Option value={2}>February</Select.Option>
          <Select.Option value={3}>March</Select.Option>
          <Select.Option value={4}>April</Select.Option>
          <Select.Option value={5}>May</Select.Option>
          <Select.Option value={6}>June</Select.Option>
          <Select.Option value={7}>July</Select.Option>
          <Select.Option value={8}>August</Select.Option>
          <Select.Option value={9}>September</Select.Option>
          <Select.Option value={10}>October</Select.Option>
          <Select.Option value={11}>November</Select.Option>
          <Select.Option value={12}>December</Select.Option>
        </Select>
      </div>

      <Input
        defaultValue={year}
        min={1900}
        value={year}
        label="Year"
        type="number"
        onChange={(event: any) => {
          onChangeYear(event.target.value);
        }}
      />
    </>
  );
}
