import { useMemo, useState } from "react";
import { Column, useRowSelect, useSortBy, useTable, useFilters } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { FilterM } from "@synerise/ds-icon";
import Loader from "@synerise/ds-loader";

import { EmptyState } from "@/components/EmptyState";
import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import { SortIcon } from "@/components/Table/SortIcon";
import * as Styled from "@/components/Table/styles";

import { ReviewsListTableData, tableColumns } from "./ReviewsTableColumns";

export function ReviewsListTable() {
  const [loading] = useState(false);

  async function handleRemoveReview(id: number, name: string) {
    console.log("handleRemoveReview", id, name);
  }

  function handleEditReview(id: number) {
    console.log("editing report with id: ", id);
  }

  const columns = useMemo(() => {
    return tableColumns({
      onRemove: handleRemoveReview,
      onEdit: handleEditReview,
    }) as Column<ReviewsListTableData>[];
  }, []);

  const data: ReviewsListTableData[] = useMemo(() => {
    return [];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultCanSort: false,
      defaultCanFilter: false,
    },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  return (
    <Card
      title="All reviews"
      withHeader
      headerSideChildren={
        <Button mode="single-icon" type="ghost-white">
          <Icon color="#C4C4C4" component={<FilterM />} />
        </Button>
      }
    >
      {loading ? (
        <Loader label="Loading Reviews" labelPosition="bottom" />
      ) : rows.length < 1 ? (
        <EmptyState
          title="Create new review"
          text="Currently you have no Reviews saved. Get started with a new one"
          buttonLabel="New review"
          onClick={() => console.log("New review!")}
        />
      ) : (
        <Styled.Table {...getTableProps()}>
          <Styled.TableHead>
            {headerGroups.map((headerGroup) => (
              <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Styled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                      {column.canFilter ? column.render("Filter") : null}
                      {column.canSort && (
                        <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                      )}
                    </Styled.TableCellWithFiltersWrapper>
                  </Styled.TableHeader>
                ))}
              </Styled.TableHeaderRow>
            ))}
          </Styled.TableHead>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Styled.TableBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Styled.TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Styled.TableCell>
                    );
                  })}
                </Styled.TableBodyRow>
              );
            })}
          </Styled.TableBody>
        </Styled.Table>
      )}
    </Card>
  );
}
