import Chart from "react-apexcharts";

import { ChartData } from "../../LayoutBuilder.types";
import * as Styled from "../GridItems.styles";
import { lineChartOptions } from "./LineChart.options";

type LineChartProps = ChartData & {
  isSelected: boolean;
};

export function LineChart({ title, series, isSelected }: LineChartProps) {
  return (
    <Styled.GridItemContainer isSelected={isSelected}>
      <h3>{title}</h3>
      <Chart type="line" series={series} height={"100%"} options={lineChartOptions} />
    </Styled.GridItemContainer>
  );
}
