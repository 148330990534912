import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .budget-type--select {
    width: 220px;
  }

  .input-prefix {
    padding: 0 15px;
    font-size: 15px;
    color: #888;
    text-align: center;
  }

  .prefix-text {
    font-size: 12px;
  }
`;

export const DateFieldsRow = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;

  & + & {
    margin-top: 0;
  }

  > div {
    width: 150px;
  }

  label.date-fields-label {
    font-size: 0.9rem;
    margin-right: 1rem;
    width: 50px;
  }
`;

export const AverageBudgetRow = styled.div`
  display: flex;
  gap: 16px;
`;
