import { useLocation, useNavigate } from "react-router-dom";

import Divider from "@synerise/ds-divider";
import DSMenu from "@synerise/ds-menu";

import { settingsMenuData } from "./settings.menu.data";

export function SettingsInnerMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <DSMenu
      style={{
        padding: "4px 10px",
      }}
    >
      {settingsMenuData.menuGroups.map((group) => (
        <>
          <DSMenu.ItemGroup key={group.title} title={group.title}>
            {group.routes.map((route) => (
              <DSMenu.Item
                key={route.id}
                onClick={() => navigate(route.path)}
                text={route.title}
                style={{ color: pathname === route.path ? "#1890ff" : "#384356" }}
              />
            ))}
          </DSMenu.ItemGroup>
          <Divider
            style={{ padding: "10px 10px 10px 10px", margin: "10px 0px 5px 5px" }}
            dashed={false}
          />
        </>
      ))}
    </DSMenu>
  );
}
