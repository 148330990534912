import Select from "@synerise/ds-select";

import { useKPICreateBudget } from "../useKPICreateBudget";
import { KPISimpleBudgetAmount } from "./KPISimpleBudgetAmount";
import { KPISimpleBudgetAverage } from "./KPISimpleBudgetAverage";
import { KPISimpleBudgetConstant } from "./KPISimpleBudgetConstant";
import { KPISimpleBudgetLinearAmount } from "./KPISimpleBudgetLinearAmount";
import { KPISimpleBudgetPercentageAmount } from "./KPISimpleBudgetPercentage";

export function KPISimpleBudget() {
  const { budgetType, changeBudgetType } = useKPICreateBudget();

  return (
    <>
      <Select
        className="budget-type--select"
        defaultValue={budgetType}
        onChange={(value: any) => changeBudgetType(value)}
      >
        <Select.Option value="amount">Amount</Select.Option>
        <Select.Option value="constant">Constant</Select.Option>
        <Select.Option value="average">Average of past months</Select.Option>
        <Select.Option value="linear-amount">Linear growth (amount)</Select.Option>
        <Select.Option value="linear-percentage">Linear growth (percentage)</Select.Option>
      </Select>

      {budgetType === "amount" && <KPISimpleBudgetAmount />}
      {budgetType === "constant" && <KPISimpleBudgetConstant />}
      {budgetType === "average" && <KPISimpleBudgetAverage />}
      {budgetType === "linear-amount" && <KPISimpleBudgetLinearAmount />}
      {budgetType === "linear-percentage" && <KPISimpleBudgetPercentageAmount />}
    </>
  );
}
