import { Layout } from "react-grid-layout";

import { ItemConfig, ItemLayout, ItemType } from "@/components/LayoutBuilder/LayoutBuilder.types";

import { getColumnChartConfig } from "./ColumnChart/ColumnChart.config";
import { getDividerConfig } from "./Divider/Divider.config";
import { getDonutChartConfig } from "./DonutChart/DonutChart.config";
import { getImageConfig } from "./Image/Image.config";
import { getLineChartConfig } from "./LineChart/LineChart.config";
import { getRichTextConfig } from "./RichText/RichText.config";
import { getStatisticCardConfig } from "./StatisticCard/StatisticCard.config";
import { getTableConfig } from "./Table/Table.config";
import { getTitleConfig } from "./Title/Title.config";

type GetItemsConfigProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>, layout?: Partial<Layout>) => void;
};
export function getItemsConfig({
  editingItem,
  updateItem,
}: GetItemsConfigProps): Record<ItemType, ItemConfig<any>> {
  return {
    columnChart: getColumnChartConfig({ updateItem, editingItem }),
    donutChart: getDonutChartConfig({ updateItem, editingItem }),
    lineChart: getLineChartConfig({ updateItem, editingItem }),
    richText: getRichTextConfig({ updateItem, editingItem }),
    statisticCard: getStatisticCardConfig({ updateItem, editingItem }),
    divider: getDividerConfig({ updateItem, editingItem }),
    title: getTitleConfig({ updateItem, editingItem }),
    table: getTableConfig({ updateItem, editingItem }),
    image: getImageConfig({ updateItem, editingItem }),
  };
}
