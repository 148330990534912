import { useMemo } from "react";
import { useQuery } from "react-query";

import { GOVERNANCE_QUERY_KEYS } from "../governance.query.keys";
import { GovernanceServices } from "../governance.services";
import { BoardPostResponse } from "../governance.services.types";

type UseGetPostsByBoardProps = {
  boardId: number;
};

type UseGetPostsByBoardReturn = {
  posts: BoardPostResponse[];
  isPostsLoading: boolean;
};

export function useGetPostsByBoard({ boardId }: UseGetPostsByBoardProps): UseGetPostsByBoardReturn {
  const { data, isLoading } = useQuery([GOVERNANCE_QUERY_KEYS.Posts, boardId], () =>
    GovernanceServices.fetchAllPostsByBoard(boardId)
  );

  const posts = useMemo(() => {
    return data || [];
  }, [data]);

  const isPostsLoading = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  return {
    posts,
    isPostsLoading,
  };
}
