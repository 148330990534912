import { BudgetValue } from "../useKPICreateBudget";

export function calculatePercentageAmountBudgetValues(
  kpiValues: number[],
  monthsQuantity: number,
  percentageAmount: number,
  initialDate: Date
): BudgetValue[] {
  let lastValue = kpiValues[kpiValues.length - 1] || 0;

  const factor = (100 + percentageAmount) / 100;

  const percentageAmountBudgetValues: BudgetValue[] = [...Array(monthsQuantity)].map((_, index) => {
    const fixedAmount = Number((lastValue * factor).toFixed(2));

    const budgetValue = {
      amount: fixedAmount,
      date: new Date(initialDate.getFullYear(), initialDate.getMonth() + index - 1, 1),
    };

    lastValue = fixedAmount;

    return budgetValue;
  });

  return percentageAmountBudgetValues;
}
