import Button from "@synerise/ds-button";

type AccountModalUpdateFooterProps = {
  formId: string;
  isSubmitting: boolean;
  onClose: () => void;
};

export function AccountModalUpdateFooter({
  formId,
  isSubmitting,
  onClose,
}: AccountModalUpdateFooterProps) {
  return (
    <>
      <Button type="secondary" htmlType="button" onClick={() => onClose()} disabled={isSubmitting}>
        Cancel
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        form={formId}
        loading={isSubmitting}
        disabled={isSubmitting}
      >
        Update account
      </Button>
    </>
  );
}
