import { DateRangePickerPeriodicity } from "@/components/DateRangePicker/DateRangePicker";

import { Periodicity } from "../kpi.types";

type KpiPeriodicityToRangeDatePickerHelper = Record<Periodicity, DateRangePickerPeriodicity>;

export const kpiPeriodicityToRangeDatePickerHelper: KpiPeriodicityToRangeDatePickerHelper = {
  monthly: "month",
  quarterly: "quarter",
  yearly: "year",
};
