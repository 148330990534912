import Icon from "@synerise/ds-icon";

import * as Styled from "./item-controls.styles";

type ControlProps = {
  icon: JSX.Element;
  onClick: () => void;
};

export function ItemControlsControl({ icon, onClick }: ControlProps) {
  return (
    <Styled.Control
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <Icon component={icon} color="#fff" />
    </Styled.Control>
  );
}
