import Card from "@synerise/ds-card";
import Icon, { LikeM } from "@synerise/ds-icon";

import { useAuth } from "@/hooks/useAuth";
import { BoardPostResponse } from "@/modules/governance/governance.services.types";
import { useRemovePost } from "@/modules/governance/hooks/useRemovePost";

import { PostCommentCreate } from "../PostCommentCreate";
import { PostComments } from "../PostComments";
import { PostHeader } from "../PostHeader";
import * as Styled from "./styles";

type PostProps = {
  data: BoardPostResponse;
};

export function Post({ data }: PostProps) {
  const { selectedMembership } = useAuth();
  const postContentLines = data.post.content.split("\n");
  const { removePost } = useRemovePost({ boardId: data.board_id, postId: data.id });

  return (
    <Card
      title={
        <PostHeader
          data={data}
          onRemove={removePost}
          canRemove={selectedMembership?.id === data.author.id}
        />
      }
      withHeader
    >
      <Styled.PostContent>
        {postContentLines.map((line: any, index: number) => (
          <p key={index} className="post-text">
            {line}
          </p>
        ))}

        <Icon
          component={<LikeM />}
          size={22}
          color="#A7A7A7"
          className="like-button"
          style={{ marginTop: "0.5rem" }}
        />
        <PostCommentCreate boardId={data.board_id} postId={data.id} />
        {data.comments.length > 0 && <PostComments comments={data.comments} />}
      </Styled.PostContent>
    </Card>
  );
}
