import Button from "@synerise/ds-button";
import Modal from "@synerise/ds-modal";

type ModalRemoveAccessGroupProps = {
  accessGroupName: string;
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function ModalRemoveAccessGroup({
  accessGroupName,
  visible,
  onConfirm,
  onCancel,
}: ModalRemoveAccessGroupProps) {
  return (
    <Modal
      onCancel={onCancel}
      title={`Remove ${accessGroupName}`}
      visible={visible}
      footer={
        <>
          <Button type="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="custom-color" color="red" onClick={onConfirm}>
            Delete
          </Button>
        </>
      }
    >
      <p>Are you sure you want to remove {accessGroupName} access group?</p>
    </Modal>
  );
}
