import { UserAvatar } from "@synerise/ds-avatar";

import { BoardPostCommentShortResponse } from "@/modules/governance/governance.services.types";

import { formatPostDate } from "../../../utils/formatPostDate";
import { makeFullName } from "../../../utils/makeFullName";
import * as Styled from "./styles";

type PostCommentProps = {
  data: BoardPostCommentShortResponse;
};

export function PostComment({ data }: PostCommentProps) {
  const commentLines = data.comment.content.split("\n");

  return (
    <Styled.Container>
      <Styled.Header>
        <UserAvatar
          size="small"
          user={{
            firstName: data.author.fname,
            lastName: data.author.lname,
            avatar: data.author.avatar,
          }}
        />
        <Styled.CommentInfo>
          <span>{makeFullName(data.author.fname, data.author.lname)}</span>
          <span>{formatPostDate(data.created_at)}</span>
        </Styled.CommentInfo>
      </Styled.Header>
      <Styled.Content>
        {commentLines.map((line: any, index: number) => (
          <p key={index}>{line}</p>
        ))}
      </Styled.Content>
    </Styled.Container>
  );
}
