import { Input } from "@synerise/ds-input";
import DSSelect from "@synerise/ds-select";

import { Select } from "@/components/Select/Select";

import { ItemLayout } from "../../LayoutBuilder.types";

type EditItemDrawerStatisticCardContentProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>) => void;
}

export function EditItemDrawerStatisticCardContent({editingItem, updateItem} : EditItemDrawerStatisticCardContentProps) {

  function handleUpdateTitle(newTitle: string) {
    if (!editingItem) return;
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        title: newTitle,
      },
    };

    updateItem(newItem);
  }

  function handleUpdateSubtitle(subtitle: string) {
    if (!editingItem) return;
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        subtitle,
      },
    };

    updateItem(newItem);
  }

  return (
    <>
      <Input
        label="Title"
        value={editingItem?.data.title}
        onChange={(event: any) => {
          handleUpdateTitle(event.target.value);
        }}
      />
      <Select
        label="Period"
        value={editingItem?.data.subtitle}
        onChange={(value: any) => {
          handleUpdateSubtitle(value);
        }}
      >
        <DSSelect.Option value={"This Quarter"}>This Quarter</DSSelect.Option>
        <DSSelect.Option value={"This Month"}>This Month</DSSelect.Option>
        <DSSelect.Option value={"This Year"}>This Year</DSSelect.Option>
      </Select>
    </>
  );
}
