import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Icon, { Settings2S } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";
import Select from "@synerise/ds-select";

import { useKpi } from "../../hooks/useKpi";
import { useKpiGroups } from "../../hooks/useKpiGroups";
import * as Styled from "./styles";
import { KPIEditMainInfoFormValues } from "./types";

type Kpi = {
  id: number;
  title: string;
  description?: string;
  group_id?: number;
};

type KPIEditModalProps = {
  isVisible: boolean;
  onClose: () => void;
  kpi: Kpi;
};

const formId = "kpi-edit-modal-form";

export function KPIEditModal({ isVisible, kpi, onClose }: KPIEditModalProps) {
  const navigate = useNavigate();
  const { groups } = useKpiGroups();
  const { updateKpi } = useKpi(kpi.id);

  const onSubmit = async (values: KPIEditMainInfoFormValues) => {
    const onSuccess = () => {
      message.success("KPI updated");
      onClose();
    };

    const onError = (msg: string) => {
      message.error(msg);
    };

    await updateKpi(kpi.id, values, onSuccess, onError);
  };

  const handleAdvancedSettings = () => {
    navigate(`/kpis/library/${kpi.id}/edit`);
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      title={`Editing ${kpi.title}`}
      footer={
        <Styled.FooterContainer>
          <Button icon={<Icon component={<Settings2S />} />} onClick={handleAdvancedSettings}>
            Advanced Settings
          </Button>
          <Button type="primary" htmlType="submit" form={formId}>
            Save Changes
          </Button>
        </Styled.FooterContainer>
      }
    >
      <Form<KPIEditMainInfoFormValues> onSubmit={onSubmit} initialValues={kpi}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id={formId}>
            <Field name="title">
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="KPI Title"
                  disabled={false}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="description">
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="KPI Description"
                  disabled={false}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field
              name="group_id"
              render={({ input, meta }) => (
                <Select
                  {...input}
                  label="Group"
                  description="Select the group this KPI will be a part of"
                  errorText={meta.touched && meta.error}
                  allowClear
                >
                  {groups.map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                      {group.title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
}
