import Button from "@synerise/ds-button";
import EmptyStates from "@synerise/ds-empty-states";
import { ClickM } from "@synerise/ds-icon";

import * as Styled from "./styles";

type EmptyStateProps = {
  title: string;
  text: string;
  showButton?: boolean;
  buttonLabel?: string;
  onClick?: () => void;
  icon?: React.ReactElement;
} & React.HTMLAttributes<HTMLDivElement>;

export function EmptyState({
  title,
  text,
  showButton = true,
  buttonLabel,
  icon,
  onClick,
  ...rest
}: EmptyStateProps) {
  return (
    <Styled.Container {...rest}>
      <EmptyStates
        text={title}
        label={text}
        button={
          showButton && (
            <Button
              mode="label"
              type="primary"
              onClick={() => {
                onClick && onClick();
              }}
            >
              {buttonLabel}
            </Button>
          )
        }
        labelPosition="bottom"
        customIcon={icon ? icon : <ClickM />}
      />
    </Styled.Container>
  );
}
