import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const InfosWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const DetailInfoContainer = styled.div`
  display: flex;
  gap: 15px;
  min-width: 100px;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
  }

  & div.value {
    font-weight: bold;
  }

  & div.version-value--container {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;
