import * as Styled from "./styles";

type StarProps = {
  isActive: boolean;
  canSelect?: boolean;
  onClick?: () => void;
};

export function Star({ isActive, canSelect = false, onClick }: StarProps) {
  return (
    <Styled.Icon
      onClick={onClick}
      component={
        isActive ? (
          <Styled.StarFillS className={canSelect ? "star-rate__icon-component--selectable" : ""} />
        ) : (
          <Styled.StarS className={canSelect ? "star-rate__icon-component--selectable" : ""} />
        )
      }
    />
  );
}
