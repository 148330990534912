import styled from "styled-components";

import Card from "@synerise/ds-card";
import { Text } from "@synerise/ds-typography";

const DetailContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  > span {
    font-size: 1.6rem;
  }

  & > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
  }
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-bottom: 15px 0;
`;

type DetailProps = {
  number: number;
  text: string;
};

type DetailsProps = {
  total: number;
};

function Detail({ number, text }: DetailProps) {
  return (
    <DetailContainer>
      <span>{number}</span>
      <div>
        <Text size={"small"}>{text}</Text>
      </div>
    </DetailContainer>
  );
}

export function LedgerListDetails({ total }: DetailsProps) {
  return (
    <Card style={{ marginBottom: "20px" }}>
      <DetailsWrapper>
        <Detail number={total} text="Ledgers" />
      </DetailsWrapper>
    </Card>
  );
}
