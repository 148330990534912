import { Hash } from "phosphor-react";

import * as Styled from "./styles";

export function MeetingMattersItem() {
  return (
    <Styled.Container>
      <Styled.Header>
        <Hash size={16} color="#a7a7a7" weight="fill" />
        <Styled.Title>Stock Options for New Hires</Styled.Title>
      </Styled.Header>
      <Styled.Description>
        We are planning on bringing an experienced Sales Director w/ generous Stock Options Plan
      </Styled.Description>
    </Styled.Container>
  );
}
