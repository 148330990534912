import { useDragOver } from "@minoru/react-dnd-treeview";

import Icon, { AngleRightS } from "@synerise/ds-icon";

import { TypeIcon } from "./TypeIcon";

export const CustomNode = (props: any) => {
  const { id, droppable } = props.node;

  const handleToggle = (e: any) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  return (
    <div
      {...dragOverProps}
      style={{
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "auto auto 1fr auto",
        height: "32px",
        paddingInline: "0 !important",
        gap: "2px",
      }}
    >
      <div
        style={{
          alignItems: "center",
          fontSize: 0,
          cursor: "pointer",
          display: "flex",
          height: "24px",
          justifyContent: "center",
          width: "24px",
          transition: "transform linear 0.1s",
          transform: props.isOpen ? "rotate(90deg)" : "rotate(0deg)",
        }}
      >
        {props.node.droppable && (
          <div onClick={handleToggle}>
            <Icon component={<AngleRightS />} color={"#CDCDCD"} />
          </div>
        )}
      </div>
      <div>
        <TypeIcon isDroppable={droppable} />
      </div>
      <div>{props.node.text}</div>
    </div>
  );
};
