import { AxiosResponse } from "axios";

import { api } from "../../utils/http";
import { Tenant, TenantPatch, TenantPost } from "./tenants.types";

class Tenants {
  async getAllTenants() {
    const response: AxiosResponse = await api.get("/tenants");
    const allTenants: Tenant[] = response.data;
    return allTenants;
  }

  async postTenant(tenantPost: TenantPost) {
    const response: AxiosResponse = await api.post("/tenants", tenantPost);
    const tenant: Tenant = response.data;
    return tenant;
  }

  async getTenant(tenantID: number) {
    const response: AxiosResponse = await api.get(`/tenants/${tenantID}`);
    const tenant: Tenant = response.data;
    return tenant;
  }

  async deleteTenant(tenantID: number) {
    const response: AxiosResponse = await api.delete(`/tenants/${tenantID}`);
    const data: ApiStatusResponse = response.data;
    return data;
  }

  async updateTenant(tenantID: number, tenantUpdate: TenantPatch) {
    const response: AxiosResponse = await api.patch(`/tenants/${tenantID}`, tenantUpdate);
    const tenant: Tenant = response.data;
    return tenant;
  }
}

const TenantsService = new Tenants();
export default TenantsService;
