import { AppActions } from "@/modules/settings/access-groups/permissions.types";

type User = {
  permissions: AppActions[];
};

type ValidateUserAccessParams = {
  user: User;
  permissions?: AppActions[];
};

export function validateUserAccess({ user, permissions = [] }: ValidateUserAccessParams) {
  if (user.permissions.includes("*")) {
    return true;
  }

  if (permissions.length > 0) {
    const hasAllPermissions = permissions.every((permission) =>
      user.permissions.includes(permission)
    );

    if (!hasAllPermissions) return false;
  }

  return true;
}
