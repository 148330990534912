import { format } from "date-fns";
import { useMemo } from "react";
import { useMutation } from "react-query";

import { queryClient } from "@/utils/queryClient";

import { KPIValueService } from "../../kpi-value.services";
import { KpiValueCreateManyPayload, KpiValueCreatePayload } from "../../kpi-value.types";
import { Periodicity } from "../../kpi.types";
import { KPIS_QUERY_KEY } from "./kpi.config";

type UseKpisValuesProps = {
  onError: (error: string) => void;
};

type UseKpisValuesReturn = {
  addNewKpiValue: (props: AddNewKpiValueProps) => Promise<void>;
  addNewKpiValueLoading: boolean;
};

export type AddNewKpiValuePayload = {
  values: {
    amount: number;
    date: Date;
  }[];
  periodicity: Periodicity;
};

type AddNewKpiValueProps = {
  kpiId: number;
  payload: AddNewKpiValuePayload;
  onSuccess?: () => void;
};

async function handleAddNewKPIValue(kpiId: number, payload: AddNewKpiValuePayload) {
  const values: KpiValueCreatePayload[] = payload.values.map((value) => ({
    value: value.amount,
    date: format(value.date, "yyyy-MM-dd"),
    period: payload.periodicity,
  }));

  const servicePayload: KpiValueCreateManyPayload = {
    periodicity: payload.periodicity,
    data_source: "budget",
    values,
  };

  return await KPIValueService.create(kpiId, servicePayload);
}

export function useKpiValues({ onError }: UseKpisValuesProps): UseKpisValuesReturn {
  const addNewKPIValueMutation = useMutation(
    [KPIS_QUERY_KEY],
    async (props: AddNewKpiValueProps) => {
      return await handleAddNewKPIValue(props.kpiId, props.payload);
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries([KPIS_QUERY_KEY]);
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (err: any) => {
        onError(err.message);
      },
    }
  );

  async function addNewKpiValue(props: AddNewKpiValueProps) {
    await addNewKPIValueMutation.mutateAsync(props);
  }

  const addNewKpiValueLoading = useMemo(() => {
    return addNewKPIValueMutation.isLoading;
  }, [addNewKPIValueMutation.isLoading]);

  return {
    addNewKpiValue,
    addNewKpiValueLoading,
  };
}
