import { Divider } from "@/components/Divider";

import { KPISourcesCard } from "../KPISourcesCard";
import * as Styled from "./styles";

type Card = {
  title: string;
  description: string;
  imgSrc: string;
};

type KPISourcesSectionProps = {
  title: string;
  cards: Card[];
  onRequestNewDataSource: (title: string) => void;
};

export function KPISourcesSection({
  title,
  cards,
  onRequestNewDataSource,
}: KPISourcesSectionProps) {
  return (
    <Styled.Container>
      <Divider
        text={title}
        color="#555"
        borderColor="#DBDBDB"
        fontWeight="bold"
        fontSize="0.75rem"
      />
      <Styled.SourceCardsContainer>
        {cards.map((card) => (
          <KPISourcesCard
            title={card.title}
            description={card.description}
            imsSrc={card.imgSrc}
            onRequest={onRequestNewDataSource}
          />
        ))}
      </Styled.SourceCardsContainer>
    </Styled.Container>
  );
}
