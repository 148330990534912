import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import Button from "@synerise/ds-button";
import Checkbox from "@synerise/ds-checkbox";
import Icon, { TagM, Check2S } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import DSMenu from "@synerise/ds-menu";
import message from "@synerise/ds-message";

import { queryClient } from "@/utils/queryClient";

import { RemoveTagButton } from "./RemoveTagButton";
import { ReportsTagService } from "./reports-tags.services";
import { TagCreatePayload, TagResponse } from "./reports-tags.types";
import { REPORTS_QUERY_KEY, REPORTS_TAGS_QUERY_KEY } from "./reports.config";
import { ReportService } from "./reports.services";
import * as Styled from "./ReportsSidebarMenuTagsListSection.styles";
import { mapTagsResponseToItem } from "./ReportsSidebarMenuTagsListSectionUtils";

export type Item = {
  id: number;
  text: string;
  prefixel: React.ReactNode;
  checked: boolean;
};

type ReportsSidebarMenuTagsListSectionProps = {
  tagsSelected: {id: number, canDelete: boolean}[];
  onTagSelectChange: (id: number, checked: boolean) => void;
};

export function ReportsSidebarMenuTagsListSection({
  tagsSelected,
  onTagSelectChange,
}: ReportsSidebarMenuTagsListSectionProps) {
  const [itemsOnHover, setItemsOnHover] = useState<Item[]>([]);
  const [addingTagText, setAddingTagText] = useState<string>("");
  const { data: tagsResponse } = useQuery([REPORTS_TAGS_QUERY_KEY], ReportsTagService.fetchAll);
  const [tags, setTags] = useState<Item[]>(tagsResponse ? mapTagsResponseToItem(tagsResponse) : []);
  const { data: allReports } = useQuery([REPORTS_QUERY_KEY], ReportService.fetchAll);

  useEffect(() => {
    if (!tagsResponse) return;
    setTags(mapTagsResponseToItem(tagsResponse));
  }, [tagsResponse]);

  const createTagMutation = useMutation(
    [REPORTS_TAGS_QUERY_KEY],
    async (payload: TagCreatePayload[]) => await ReportsTagService.create(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REPORTS_TAGS_QUERY_KEY);
      },
    }
  );

  const removeTagMutation = useMutation(
    [REPORTS_TAGS_QUERY_KEY],
    async (id: number) => await ReportsTagService.delete(id),
    {
      onMutate: (id) => {
        queryClient.invalidateQueries(REPORTS_QUERY_KEY);
        queryClient.cancelQueries(REPORTS_TAGS_QUERY_KEY);

        const previousTags = queryClient.getQueryData<TagResponse[]>(REPORTS_TAGS_QUERY_KEY);

        if (previousTags) {
          const newTags = previousTags.filter((tag) => tag.id !== id);
          queryClient.setQueryData(REPORTS_TAGS_QUERY_KEY, newTags);
          setTags(mapTagsResponseToItem(newTags));
        }

        return {
          previousTags,
        };
      },
      onError(
        err: any,
        _,
        context:
          | {
              previousTags: TagResponse[] | undefined;
            }
          | undefined
      ) {
        queryClient.setQueryData(REPORTS_TAGS_QUERY_KEY, context?.previousTags);
        message.error("Error while deleting tag");
      },
    }
  );

  const handleTagClick = (item: Item) => {
    onTagSelectChange(item.id, !item.checked);
  };

  useEffect(() => {
    const newTagsState = tags.map((tag) => {
      if (tagsSelected.some(t => t.id === tag.id)) {
        return { ...tag, checked: true };
      } else {
        return { ...tag, checked: false };
      }
    });
    setTags(newTagsState);
  }, [tagsSelected]);

  const handleMouseOverItem = (item: Item) => {
    setItemsOnHover((prevState) => [...prevState, item]);
  };

  const handleMouseOutItem = (item: Item) => {
    setItemsOnHover((prevState) => prevState.filter((i) => i.id !== item.id));
  };

  const handleAddTag = async () => {
    if (addingTagText.length < 1) return;
    if (tags.some(tag => tag.text.toLowerCase().trim() === addingTagText.toLowerCase().trim())) {
      message.warning("A tag with this name already exists");
      return;
    };
    const newTag = { title: addingTagText };
    await createTagMutation.mutateAsync([newTag]);
    setAddingTagText("");
  };

  const handleRemoveTag = async (id: number) => {
    await removeTagMutation.mutateAsync(id);
  };

  const checkIfTagIsBeenUsed = (tagId: number): boolean | null => {
    if (!allReports) return true;
    return allReports.some(report => report.tags.some(tag => tag.id === tagId));
  }

  return (
    <Styled.Container>
      <DSMenu>
        {tags.map((tag) => (
          <DSMenu.Item
            key={tag.id}
            suffixel={
             <RemoveTagButton onRemoveTag={handleRemoveTag} tagId={tag.id} tagIsBeenUsed={checkIfTagIsBeenUsed(tag.id)} />
            }
            prefixel={
              tag.checked ? (
                <Checkbox checked />
              ) : itemsOnHover.includes(tag) ? (
                <Checkbox />
              ) : (
                <Icon component={<TagM />} />
              )
            }
            text={tag.text}
            onMouseOver={() => handleMouseOverItem(tag)}
            onMouseOut={() => handleMouseOutItem(tag)}
            onClick={() => handleTagClick(tag)}
          />
        ))}
      </DSMenu>
      <Styled.FooterContainer>
        <Input
          value={addingTagText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddingTagText(e.target.value)}
          placeholder="Add Tag"
          style={{
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
          suffix={
            addingTagText.length > 0 ? (
              <Button
                htmlType="button"
                type="custom-color-ghost"
                mode="single-icon"
                color="primary"
                icon={<Icon component={<Check2S />} color="#777" />}
                onClick={handleAddTag}
              />
            ) : (
              <div style={{ width: "24px", height: "32px" }} />
            )
          }
        />
      </Styled.FooterContainer>
    </Styled.Container>
  );
}
