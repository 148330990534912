import { AxiosResponse } from "axios";

import { api } from "../../utils/http";
import {
  DashboardCreatePayload,
  DashboardLayout,
  DashboardResponse,
  DashboardResponseShort,
  DashboardUpdatePayload,
} from "./dashboard.types";

export class DashboardService {

  static async fetchAll(): Promise<DashboardResponseShort[]> {
    const response: AxiosResponse = await api.get("/dashboards");
    const dashboards: DashboardResponseShort[] = response.data;
    return dashboards;
  }

  static async fetchOne(id: number): Promise<DashboardResponse> {
    const response: AxiosResponse = await api.get(`/dashboards/${id}`);
    const dashboard: DashboardResponse = response.data;
    return dashboard;
  }

  static async create(payload: DashboardCreatePayload): Promise<DashboardResponseShort> {
    const response: AxiosResponse = await api.post("/dashboards", payload);
    const dashboard: DashboardResponseShort = response.data;
    return dashboard;
  }

  static async update(id: number, payload: DashboardUpdatePayload): Promise<DashboardResponseShort> {
    const response: AxiosResponse = await api.patch(`/dashboards/${id}`, payload);
    const dashboard: DashboardResponseShort = response.data;
    return dashboard;
  }

  static async delete(id: number): Promise<ApiStatusResponse> {
    const response: AxiosResponse = await api.delete(`/dashboards/${id}`);
    const status: ApiStatusResponse = response.data;
    return status;
  }

  static mapDashboardResponseToDashboardLayout(
    dashboardResponse: DashboardResponse | DashboardResponseShort
  ): DashboardLayout {
    const dashboard: DashboardLayout = {
      id: dashboardResponse.id,
      isDefault: dashboardResponse.is_default,
      layout: dashboardResponse.components || [],
      name: dashboardResponse.name,
    };

    return dashboard;
  }
}
