import styled from "styled-components";

export const Container = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

export const SourceCardsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;
