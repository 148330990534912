import Divider from "@synerise/ds-divider";
import Radio from "@synerise/ds-radio";

import { useReportBuilder } from "../../hooks/useReportBuilder";
import * as Styled from "./ReportSettingsDrawer.styles";
import { ReportsSettingsTagsSection } from "./ReportsSettingsTagsSection";

export function ReportSettingsDrawer() {
  const { layoutOrientation, changeLayoutOrientation, isPrivate, changeIsPrivate, handleChangeTagCheck, tags } =
    useReportBuilder();

  function handleChangeLayoutOrientation(orientation: "landscape" | "portrait") {
    changeLayoutOrientation(orientation);
  }

  return (
    <Styled.Container>
      <Styled.SectionTitle>Layout</Styled.SectionTitle>
      <Radio.Group
        optionType="button"
        value={layoutOrientation}
        onChange={(e) => {
          handleChangeLayoutOrientation(e.target.value);
        }}
      >
        <Radio value="landscape">Landscape</Radio>
        <Radio value="portrait">Portrait</Radio>
      </Radio.Group>

      <Divider />

      <Styled.SectionTitle>Privacy</Styled.SectionTitle>
      <Radio.Group
        optionType="button"
        value={isPrivate}
        onChange={(e) => changeIsPrivate(e.target.value)}
      >
        <Radio value={true}>Private</Radio>
        <Radio value={false}>Public</Radio>
      </Radio.Group>

      <Divider />

      <Styled.SectionTitle>Classification</Styled.SectionTitle>
      <ReportsSettingsTagsSection onTagSelectChange={handleChangeTagCheck} tags={tags} />

    </Styled.Container>
  );
}
