import { format } from "date-fns";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { EditM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useCompanies } from "@/hooks/useCompanies";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { dataManagementData } from "../../data-management.data";
import { Periodicity, TrialBalanceUpdatePayload } from "../trialbalance.types";
import { useLedger } from "../useLedger";
import * as Styled from "./ledger.styles";

type LedgerEditParams = {
  id: string;
};

type LedgerEditFormValues = {
  entity_id?: number;
  month?: number;
  year?: number;
  periodicity?: Periodicity;
  chart_id?: number;
  is_closed?: boolean;
};

const validationSchema = yup.object().shape({
  entity_id: yup.number(),
  month: yup.number(),
  year: yup.number(),
  periodicity: yup.string(),
  chart_id: yup.number(),
  is_closed: yup.boolean(),
});

export function LedgerEditForm() {
  const { id } = useParams<LedgerEditParams>();
  const navigate = useNavigate();
  const validate = useValidationSchema(validationSchema);
  const { updateLedger, trialBalance } = useLedger(Number(id));
  const { allCompanies } = useCompanies();

  async function onSubmit(values: LedgerEditFormValues) {
    function onSuccess() {
      message.success("Ledger has been updated");
      navigate(dataManagementData.ledgers.path);
    }
    function onError(text: string) {
      message.error(text);
    }
    const payload: TrialBalanceUpdatePayload = {
      chart_id: values.chart_id,
      entity_id: values.entity_id,
      is_closed: values.is_closed,
      periodicity: values.periodicity,
    };
    if (values.year && values.month) {
      payload.date = format(new Date(values.year, values.month), "yyyy-MM-dd");
    }
    await updateLedger({
      payload,
      onSuccess,
      onError,
    });
  }

  const initialValues: LedgerEditFormValues = {
    chart_id: trialBalance?.chart_id,
    entity_id: trialBalance?.entity_id,
    is_closed: trialBalance?.is_closed || false,
    periodicity: trialBalance?.periodicity,
    month: trialBalance?.date ? new Date(trialBalance.date).getMonth() + 1 : undefined,
    year: trialBalance?.date ? new Date(trialBalance.date).getFullYear() : undefined,
  };

  return (
    <Layout header={<PageHeader onGoBack={() => navigate("/data/ledgers")} title="Edit ledger" />}>
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Form<LedgerEditFormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Card
                    background="white-shadow"
                    lively
                    title="Basic Information"
                    withHeader
                    icon={<EditM />}
                    iconColor={"#AEAEAE"}
                    headerSideChildren={
                      <Styled.SideButtonsContainer>
                        <Button onClick={() => navigate("/data/ledgers")}>Cancel</Button>
                        <Button type="primary" htmlType="submit">
                          Update Ledger
                        </Button>
                      </Styled.SideButtonsContainer>
                    }
                  >
                    <ModuleStyled.InputWrapper>
                      <Styled.SelectFieldRow>
                        <Field
                          name="entity_id"
                          render={({ input, meta }) => (
                            <Select
                              {...input}
                              onChange={input.onChange}
                              value={input.value}
                              label="Company"
                              errorText={meta.touched && meta.error}
                            >
                              {allCompanies?.map((company) => (
                                <Select.Option key={company.id} value={company.id}>
                                  {company.name}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        />
                      </Styled.SelectFieldRow>

                      <Styled.SelectFieldRow>
                        <Styled.DateFieldsRow>
                          <div>
                            <Field name="month">
                              {({ input, meta }) => (
                                <Select
                                  {...input}
                                  label="Month"
                                  errorText={meta.touched && meta.error}
                                >
                                  <Select.Option value={1}>January</Select.Option>
                                  <Select.Option value={2}>February</Select.Option>
                                  <Select.Option value={3}>March</Select.Option>
                                  <Select.Option value={4}>April</Select.Option>
                                  <Select.Option value={5}>May</Select.Option>
                                  <Select.Option value={6}>June</Select.Option>
                                  <Select.Option value={7}>July</Select.Option>
                                  <Select.Option value={8}>August</Select.Option>
                                  <Select.Option value={9}>September</Select.Option>
                                  <Select.Option value={10}>October</Select.Option>
                                  <Select.Option value={11}>November</Select.Option>
                                  <Select.Option value={12}>December</Select.Option>
                                </Select>
                              )}
                            </Field>
                          </div>

                          <Field name="year">
                            {({ input, meta }) => (
                              <Input
                                {...input}
                                maxLength={4}
                                min={1900}
                                defaultValue={2020}
                                label="Year"
                                type="number"
                                errorText={meta.touched && meta.error}
                              />
                            )}
                          </Field>
                        </Styled.DateFieldsRow>
                      </Styled.SelectFieldRow>

                      <Field
                        name="periodicity"
                        render={({ input, meta }) => (
                          <Select
                            {...input}
                            label="Periodicity"
                            onChange={input.onChange}
                            errorText={meta.touched && meta.error}
                            options={[
                              { label: "Daily", value: "daily" },
                              { label: "Monthly", value: "monthly" },
                              { label: "Weekly", value: "weekly" },
                              { label: "Quarterly", value: "quarterly" },
                              { label: "Yearly", value: "yearly" },
                            ]}
                          />
                        )}
                      />
                      <ModuleStyled.SwitchWrapper>
                        <Field
                          name="is_consistent"
                          render={({ input, meta }) => (
                            <Switch
                              {...input}
                              checked={input.value}
                              label="Consistency"
                              description="Check if this ledger is consistent"
                              errorText={meta.touched && meta.error}
                            />
                          )}
                        />

                        <Field
                          name="is_closed"
                          render={({ input, meta }) => (
                            <Switch
                              {...input}
                              label="Closed"
                              description="Check if this ledger is closed"
                              errorText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </ModuleStyled.SwitchWrapper>
                    </ModuleStyled.InputWrapper>
                  </Card>
                </form>
              );
            }}
          />
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
