import { Layout } from "react-grid-layout";

import { Input } from "@synerise/ds-input";

import { rowHeight } from "../../LayoutBuilder.config";
import { ItemLayout } from "../../LayoutBuilder.types";

type EditItemDrawerTitleContentProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>, layout?: Partial<Layout>) => void;
};

export function EditItemDrawerTitleContent({
  editingItem,
  updateItem,
}: EditItemDrawerTitleContentProps) {
  function handleUpdateTitle(newTitle: string) {
    if (!editingItem) return;
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        title: newTitle,
      },
    };

    updateItem(newItem);
  }

  function handleUpdateFontSize(newFontSize: number) {
    if (!editingItem) return;
    const titleElement = document.getElementById("title-grid-item");
    if (!titleElement) return;
    const elementHeight = titleElement.clientHeight;
    const newHeight = Math.ceil(elementHeight / rowHeight);
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        fontSize: newFontSize,
      },
    };
    updateItem(newItem, {
      h: Math.max(newHeight - 1, 2),
    });
  }

  return (
    <>
      <Input
        label="Title"
        value={editingItem?.data.title}
        onChange={(event: any) => {
          handleUpdateTitle(event.target.value);
        }}
      />
      <Input
        label="Font size"
        type="number"
        value={editingItem?.data.fontSize}
        onChange={(event: any) => {
          handleUpdateFontSize(event.target.value);
        }}
      />
    </>
  );
}
