import { Users } from "phosphor-react";
import { CheckCircle } from "phosphor-react";
import { Link } from "react-router-dom";

import * as Styled from "./styles";

export function DocumentsItem() {
  return (
    <Styled.Container>
      <header>
        <Styled.Title>3Q2022</Styled.Title>
        <Styled.Status>
          <span>Signed</span>
          <CheckCircle size={16} color="#00cc83" weight="fill" />
        </Styled.Status>
      </header>
      <div className="content">
        <Styled.Attendees>
          <Users size={20} color="#c7c7c7" weight="fill" />
          <span>4/5</span>
        </Styled.Attendees>
        <Styled.Actions>
          <Link to="#">View Document</Link>
        </Styled.Actions>
      </div>
    </Styled.Container>
  );
}
