import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { LockM } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useAccessGroups } from "@/hooks/useAccessGroups";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";
import { accessModulesPermissionsResources } from "./access-groups.config";
import * as Styled from "./access-groups.styles";
import { AccessGroup } from "./access-groups.types";

function hasPermission(permissions: string[], permission: string): boolean {
  return permissions.indexOf(permission) !== -1;
}

export function AccessGroupDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getAccessGroupById, allAccessGroupsLoading, allAccessGroups } = useAccessGroups();
  const [accessGroup, setAccessGroup] = useState<AccessGroup | null>(null);
  const [showFirstCard, setShowFirstCard] = useState(true);
  const [showSecondCard, setShowSecondCard] = useState(true);

  if (!id) {
    navigate("/settings/access");
  }

  useEffect(() => {
    if (!allAccessGroupsLoading) {
      const accessGroup = getAccessGroupById(Number(id));
      setAccessGroup(accessGroup);
    } else {
      setAccessGroup(null);
      message.error("Access group not found");
      navigate("/settings/access");
    }
  }, [allAccessGroups, allAccessGroupsLoading]);

  return (
    <Layout
      left={LeftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate("/settings/access")}
          title={`${accessGroup?.title} Access Group`}
          description={accessGroup?.description}
        />
      }
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Card
            background="white-shadow"
            title="Resources & Actions"
            withHeader
            icon={<LockM />}
            iconColor={"#AEAEAE"}
            hideContent={!showFirstCard}
            headerSideChildren={
              <Styled.SideButtonsContainer>
                <Button onClick={() => setShowFirstCard(!showFirstCard)} type="secondary">
                  {showFirstCard ? "Hide" : "Show"}
                </Button>
              </Styled.SideButtonsContainer>
            }
          >
            <Styled.AccessModulesSwitchTable>
              <tbody>
                <tr>
                  <td></td>
                  <td>Create</td>
                  <td>Read</td>
                  <td>Update</td>
                  <td>Delete</td>
                </tr>
                {Object.entries(accessModulesPermissionsResources).map(
                  ([resourceKey, resourceValue]) => (
                    <>
                      <tr key={resourceKey}>
                        <td>{resourceValue.name}</td>
                        {Object.entries(resourceValue.permissions).map(([permissionKey]) => (
                          <td>
                            <Switch
                              disabled={true}
                              label=""
                              checked={hasPermission(
                                accessGroup?.permissions ?? [],
                                `${resourceKey}:${permissionKey}`
                              )}
                            />
                          </td>
                        ))}
                      </tr>
                    </>
                  )
                )}
              </tbody>
            </Styled.AccessModulesSwitchTable>
          </Card>
          <Card
            icon={<LockM />}
            iconColor={"#AEAEAE"}
            background="white-shadow"
            title="Access by Entity"
            withHeader
            description="Defines which entities this access group will have access to"
            hideContent={!showSecondCard}
            headerSideChildren={
              <Styled.SideButtonsContainer>
                <Button onClick={() => setShowSecondCard(!showSecondCard)} type="secondary">
                  {showSecondCard ? "Hide" : "Show"}
                </Button>
              </Styled.SideButtonsContainer>
            }
          >
            <ModuleStyled.InputWrapper>
              <Select
                mode="multiple"
                disabled
                value={accessGroup?.entities}
                defaultValue={["None"]}
              >
                {accessGroup?.entities.map((entity) => (
                  <Select.Option key={entity} value={entity}>
                    {entity}
                  </Select.Option>
                ))}
              </Select>
            </ModuleStyled.InputWrapper>
          </Card>
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
