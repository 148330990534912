import styled from "styled-components";

export const TableOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    align-items: center;

    display: flex;
    gap: 15px;
  }
`;

export const DateRangePickerContainer = styled.div`
  display: flex;
  gap: 5px;

  .clear-range-button {
    cursor: pointer;
  }
`;

export const TitleCell = styled.div`
  cursor: pointer;
`;

type TableBodyRowProps = {
  isGrouped: boolean;
};

export const TableBodyRow = styled.tr`
  border-bottom: ${({ isGrouped }: TableBodyRowProps) =>
    isGrouped ? "1px solid #f2f2f2" : "none"};
  & + &.group-row {
    td {
      padding: 64px 5px 5px 5px;
    }
  }
`;

export const TableCell = styled.td`
  padding: 5px;
  color: #616161;
  font-weight: 500;
`;

export const PlaceHolderCell = styled.div`
  padding-left: 15px;
  font-weight: 400;
`;
