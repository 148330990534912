import styled from "styled-components";

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > h2 {
    margin: 0;
  }
`;
