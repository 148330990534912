import { useNavigate } from "react-router-dom";

import { GroupM, FolderArchiveM, AddM, LockM } from "@synerise/ds-icon";
import { Title } from "@synerise/ds-typography";

import { Divider } from "@/components/Divider";
import { ModuleCard } from "@/components/ModuleCard";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import * as Styled from "./kpis.styles";

export function KPIHome() {
  const navigate = useNavigate();

  return (
    <Styled.Container>
      <ModuleStyled.MaxWidthContent>
        <Styled.TitleContainer>
          <Title level={1}>KPIs</Title>
        </Styled.TitleContainer>

        <Styled.ModulesContainer>
          <Divider text="Shortcuts" />
          <Styled.CardsGroup>
            <ModuleCard
              title="View your KPIs"
              description="See all your KPIs in one place"
              icon={<AddM />}
              onClick={() => {}}
            />
            <ModuleCard
              title="Create new KPI"
              description="Add a new KPI to the library"
              icon={<AddM />}
              onClick={() => {
                navigate("/settings/access/create");
              }}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>

        <Styled.ModulesContainer>
          <Divider text="Your KPIs Account" />
          <Styled.CardsGroup>
            <ModuleCard
              title="Profile"
              description="Your personal account"
              icon={<GroupM />}
              onClick={() => navigate("/settings/account")}
            />
            <ModuleCard
              title="Security"
              description="Protect your account"
              icon={<LockM />}
              onClick={() => navigate("/settings/account/security")}
            />
            <ModuleCard
              title="Notifications"
              description="Control your notifications"
              icon={<FolderArchiveM />}
              onClick={() => navigate("/settings/account/notifications")}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>

        <Styled.ModulesContainer>
          <Divider text="Targets & KPIs Forecast" />
          <Styled.CardsGroup>
            <ModuleCard
              title="Global"
              description="Organization global settings"
              icon={<GroupM />}
              onClick={() => navigate("/settings/organization")}
            />
            <ModuleCard
              title="Preferences"
              description="System preferences"
              icon={<LockM />}
              onClick={() => navigate("/settings/organization/preferences")}
            />
            <ModuleCard
              title="Notifications"
              description="Control your notifications"
              icon={<FolderArchiveM />}
              onClick={() => navigate("/settings/organization/notifications")}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>

        <Styled.ModulesContainer>
          <Divider text="Data Sources" />
          <Styled.CardsGroup>
            <ModuleCard
              title="Users"
              description="Control the organization's users"
              icon={<GroupM />}
              onClick={() => navigate("/settings/users")}
            />
            <ModuleCard
              title="Access Groups"
              description="Granular access control"
              icon={<LockM />}
              onClick={() => navigate("/settings/access")}
            />
            <ModuleCard
              title="Identity Providers"
              description="Add alternative sign in methods"
              icon={<FolderArchiveM />}
              onClick={() => navigate("/settings/providers")}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>
      </ModuleStyled.MaxWidthContent>
    </Styled.Container>
  );
}
