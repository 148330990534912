import { ItemLayout } from "@/components/LayoutBuilder/LayoutBuilder.types";

import { DASHBOARD_LAYOUT_KEY } from "../dashboard.config";
import { LayoutInSessionStorage } from "../dashboard.types";
import { loadAllLayoutsFromSessionStorage } from "./loadAllLayoutsFromSessionStorage";

type SaveLayoutInSessionStorageProps = {
  id: number;
  name: string;
  layout: ItemLayout<any>[];
  isDefault: boolean;
};

export function saveLayoutInSessionStorage({
  id,
  name,
  layout,
  isDefault,
}: SaveLayoutInSessionStorageProps) {
  if (!sessionStorage) return;

  const layoutWithMetaData: LayoutInSessionStorage = {
    id,
    name,
    layout,
    isDefault,
  };

  const allLayouts = loadAllLayoutsFromSessionStorage();
  const layoutMatched = allLayouts.find((layout) => layout.id === id);

  if (layoutMatched) {
    allLayouts.splice(allLayouts.indexOf(layoutMatched), 1, layoutWithMetaData);
  } else {
    allLayouts.push(layoutWithMetaData);
  }

  sessionStorage.setItem(DASHBOARD_LAYOUT_KEY, JSON.stringify(allLayouts));
}
