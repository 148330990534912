import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { EditM } from "@synerise/ds-icon";

import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as Styled from "@/components/Table/styles";

import { JournalTableTableData } from "./types";

export const tableColumns: () => Column<JournalTableTableData>[] = () => {
  const columns: Column<JournalTableTableData>[] = [
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }) => <UserTypeCell type={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => {
        return <UserTypeCell type={value} />;
      },
    },
    {
      Header: "Value",
      accessor: "value",
      Cell: ({ value }) => {
        return <UserTypeCell type={value} />;
      },
    },
    {
      Header: "Document ID",
      accessor: "document_id",
      Cell: ({ value }) => {
        return <UserTypeCell type={value} />;
      },
    },
    {
      Header: "Reclass",
      accessor: "id",
      Cell: () => (
        <Styled.RowActionButtons>
          <Button mode="single-icon" type="ghost-white">
            <Icon color="#C4C4C4" component={<EditM />} />
          </Button>
        </Styled.RowActionButtons>
      ),
    },
  ];

  return columns;
};
