import { useCallback } from "react";
import { useMutation } from "react-query";

import message from "@synerise/ds-message";

import { GovernanceServices } from "../governance.services";
import { BoardCreatePayload } from "../governance.services.types";
import { useRefreshBoards } from "./useRefreshBoards";

export const useCreateBoard = () => {
  const refreshBoards = useRefreshBoards();

  const createBoardMutation = useMutation(GovernanceServices.create, {
    onSuccess: () => {
      message.success("Board created");
      refreshBoards();
    },
    onError: (error: any) => {
      message.error(error.message);
    },
  });

  const createBoard = useCallback(async (payload: BoardCreatePayload) => {
    createBoardMutation.mutate(payload);
  }, []);

  return {
    createBoard,
    isCreateBoardLoading: createBoardMutation.isLoading,
  };
};
