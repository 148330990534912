import { ChartLine, ChartBar, ChartPie } from "phosphor-react";

import ButtonGroup from "@synerise/ds-button-group";

import { ChartType } from "@/components/LayoutBuilder/LayoutBuilder.types";

import { SelectChartOption } from "./SelectChartOption";

type SelectChartProps = {
  selectedChart: ChartType | null;
  changeSelectedChart: (chart: ChartType) => void;
};

export function SelectChart({ selectedChart, changeSelectedChart }: SelectChartProps) {
  return (
    <div>
      <ButtonGroup>
        <SelectChartOption selected={selectedChart === "lineChart"}>
          <ChartLine
            color={selectedChart === "lineChart" ? "white" : "#1F5E95"}
            weight="light"
            size={24}
            onClick={() => changeSelectedChart("lineChart")}
          />
        </SelectChartOption>
        <SelectChartOption selected={selectedChart === "columnChart"}>
          <ChartBar
            color={selectedChart === "columnChart" ? "white" : "#1F5E95"}
            weight="light"
            size={24}
            onClick={() => changeSelectedChart("columnChart")}
          />
        </SelectChartOption>
        <SelectChartOption selected={selectedChart === "donutChart"}>
          <ChartPie
            color={selectedChart === "donutChart" ? "white" : "#1F5E95"}
            weight="light"
            size={24}
            onClick={() => changeSelectedChart("donutChart")}
          />
        </SelectChartOption>
      </ButtonGroup>
    </div>
  );
}
