import styled from "styled-components";

import { Tag } from "@synerise/ds-tags";

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ProfileAvatarRow = styled.div`
  display: flex;
  padding-bottom: 20px;
  flex-direction: row;
`;

export const FlagSelectOption = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  p {
    margin: 0;
  }
`;

export const TagAddon = styled(Tag)`
  height: 32px;
  width: 32px;
  margin: 0;
  opacity: 0.7;
`;

export const TenantUpdatedInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: right;
`;

export const TenantProfileInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
