import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 7fr 4fr;
  flex-direction: column;
  gap: 1rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
