import { Form, Field } from "react-final-form";
import * as Yup from "yup";

import Button from "@synerise/ds-button";
import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";
import Switch from "@synerise/ds-switch";

import { useValidationSchema } from "@/hooks/useValidationSchema";

import { useKpiGroups } from "../library/hooks/useKpiGroups";

type KPIGroupCreateModalProps = {
  visible: boolean;
  onClose: () => void;
};

type KPIGroupCreateModalFormValues = {
  title: string;
  description?: string;
  is_shared: boolean;
};

const validateSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string(),
  is_shared: Yup.boolean(),
});

const initialValues: KPIGroupCreateModalFormValues = {
  title: "",
  description: "",
  is_shared: false,
};

export function KPIGroupCreateModal({ visible, onClose }: KPIGroupCreateModalProps) {
  const { createKpiGroup, createKpiLoading } = useKpiGroups();

  const validate = useValidationSchema(validateSchema);

  const handleSubmit = async (values: KPIGroupCreateModalFormValues) => {
    const onError = (text: string) => {
      message.error(text);
    };

    const onSuccess = () => {
      message.success("KPI Group created");
      onClose();
    };

    await createKpiGroup({
      onError,
      onSuccess,
      payload: values,
    });
  };

  return (
    <Modal
      title="Create a new KPI Group"
      visible={visible}
      onCancel={() => {
        onClose();
      }}
      size="small"
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          form="kpi-group-create"
          htmlType="submit"
          loading={createKpiLoading}
        >
          Create
        </Button>,
      ]}
    >
      <Form<KPIGroupCreateModalFormValues>
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="kpi-group-create">
            <Field name="title">
              {({ input, meta }) => (
                <Input {...input} label="Title" errorText={meta.touched && meta.error} />
              )}
            </Field>

            <Field name="description">
              {({ input, meta }) => (
                <Input {...input} label="Description" errorText={meta.touched && meta.error} />
              )}
            </Field>
            <Field name="is_shared">
              {({ input, meta }) => (
                <Switch
                  {...input}
                  checked={input.value}
                  label="Shared Group"
                  errorText={meta.touched && meta.error}
                />
              )}
            </Field>
          </form>
        )}
      </Form>
    </Modal>
  );
}
