import { BudgetValue } from "../useKPICreateBudget";

export function calculateAverageMonthsBudgetValues(
  kpiValues: number[],
  monthsQuantity: number,
  averageMonths: number,
  initialDate: Date
) {
  const lastValuesFromKPI = kpiValues.slice(-averageMonths);

  const average =
    lastValuesFromKPI.reduce((acc, value) => acc + value, 0) / lastValuesFromKPI.length;

  const averageFormatted = Number(average.toFixed(2));

  const averageMonthsBudgetValues: BudgetValue[] = [...Array(monthsQuantity)].map((_, index) => {
    const budgetValue = {
      amount: averageFormatted,
      date: new Date(initialDate.getFullYear(), initialDate.getMonth() + index - 1, 1),
    };

    return budgetValue;
  });

  return averageMonthsBudgetValues;
}
