import { useRef, useState } from "react";

import Button from "@synerise/ds-button";
import DSDropdown from "@synerise/ds-dropdown";
import Icon, { BookM } from "@synerise/ds-icon";
import DSMenu from "@synerise/ds-menu";
import { useOnClickOutside } from "@synerise/ds-utils";

export function ReferenceItem() {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setShowDropdown(false);
  });
  return (
    <DSDropdown
      overlayStyle={{ borderRadius: "3px" }}
      visible={showDropdown}
      placement="bottomLeft"
      overlay={
        <DSDropdown.Wrapper ref={ref}>
          <DSMenu asDropdownMenu={true} style={{ padding: "8px 2px 8px 8px" }}>
            <DSMenu.Item prefixel={<Icon component={<BookM />} />} onClick={() => {}}>
              Current Module Reference
            </DSMenu.Item>
            <DSMenu.Item prefixel={<Icon component={<BookM />} />}>Finance Reference</DSMenu.Item>
            <DSMenu.Item prefixel={<Icon component={<BookM />} />}>
              Accounting Reference
            </DSMenu.Item>
          </DSMenu>
        </DSDropdown.Wrapper>
      }
    >
      <Button
        mode="single-icon"
        key={5}
        type="ghost-white"
        onClick={() => setShowDropdown((prevState) => !prevState)}
      >
        <Icon color="#ffffff" component={<BookM />} />
      </Button>
    </DSDropdown>
  );
}
