import * as Styled from "./StatisticCard.styles";
import { StatisticCardChart } from "./StatisticCardChart/StatisticCardChart";

type StatisticCardProps = {
  title: string;
  subtitle: string;
  total: number;
  goal: number;
  isSelected: boolean;
};

const formatAmount = (amount: number) => {
  const formatter = Intl.NumberFormat("en", {
    notation: "compact",
    currency: "USD",
    currencyDisplay: "symbol",
    style: "currency",
  });
  return `${formatter.format(amount)}`;
};

export function StatisticCard({ title, subtitle, total, goal, isSelected }: StatisticCardProps) {
  const formattedTotal = formatAmount(total);
  const formattedGoal = formatAmount(goal);
  const percentage = Math.round((total / goal) * 100);

  return (
    <Styled.Container isSelected={isSelected}>
      <Styled.MainInfoContainer>
        <header>
          <span className="title">{title}</span>
          <span className="subtitle">{subtitle}</span>
        </header>
        <div className="amounts">
          <span className="amounts--total">{formattedTotal}</span>
          <span className="amounts--goal">Goal: {formattedGoal}</span>
        </div>
      </Styled.MainInfoContainer>
      <aside>
        <StatisticCardChart percentage={percentage} />
      </aside>
    </Styled.Container>
  );
}
