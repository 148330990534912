import { Navigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Result from "@synerise/ds-result";

import * as Styled from "../login.styles";

export function InvitationStatusMembershipNotActive() {
  return (
    <>
      <Result
        type="info"
        title="Yout membership is not active"
        description="Your membership is not active. Please contact your administrator."
      />

      <Styled.FooterButtonsContainer>
        <Button
          onClick={() =>
            Navigate({
              to: "/login",
            })
          }
        >
          Back to Login
        </Button>
      </Styled.FooterButtonsContainer>
    </>
  );
}
