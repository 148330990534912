import styled from "styled-components";

import Button from "@synerise/ds-button";
import Icon, { InboxNoResultsL } from "@synerise/ds-icon";

import { useAccountsMappingContext } from "./hooks/useAccountsMappingContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export function EmptyTable() {
  const { setAddMappingModalOpen } = useAccountsMappingContext();

  return (
    <Container>
      <Icon component={<InboxNoResultsL />} color={"#AEAEAE"} size={50} />
      <h3>No mapping found</h3>
      <Button type="primary" onClick={() => setAddMappingModalOpen(true)}>
        Add a new mapping
      </Button>
    </Container>
  );
}
