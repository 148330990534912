import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #ededed;
  padding: 0.5rem;
  color: #8f8f8f;
  border-radius: 3px;
  color: black;

  & header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  & .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.span`
  font-weight: bold;
  color: black;
`;
export const Date = styled.span`
  color: #a7a7a7;
`;

export const Attendees = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Actions = styled.div`
  display: flex;
  font-weight: bold;
  gap: 0.5rem;
`;
