import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Result from "@synerise/ds-result";

import * as Styled from "../login.styles";
import { useConfirmReset } from "../useLogin";

type ValidateResetEmailTokenParams = {
  token: string;
};

export function ValidateResetEmailToken() {
  const navigate = useNavigate();
  const { token } = useParams<ValidateResetEmailTokenParams>();

  const onSuccess = () => {
    navigate("/reset-password?token=" + token);
  };

  const onError = (text: string) => {
    message.error(text);
    navigate("/login");
  };

  const { confirmResetLoading } = useConfirmReset({ token: token ?? "", onSuccess, onError });

  return (
    <Styled.CardContainer>
      <Card title="Email sent" withHeader>
        <Result type="progress" title="Validating token" />
        <Styled.FooterButtonsContainer>
          <Button onClick={() => navigate("/login")}>Back to login</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={confirmResetLoading}
            disabled={confirmResetLoading}
          >
            Resend Email
          </Button>
        </Styled.FooterButtonsContainer>
      </Card>
    </Styled.CardContainer>
  );
}
