import { api } from "@/utils/http";

import {
  Periodicity,
  TrialBalanceCreatePayload,
  TrialBalanceResponse,
  TrialBalanceUpdatePayload,
} from "./trialbalance.types";

export type FetchAllParams = {
  entity_id?: number;
  chart_id?: number;
  periodicity?: Periodicity;
  date?: string;
  is_deleted?: boolean;
  is_consistent?: boolean;
  is_reviewed?: boolean;
  review_status?: string;
};

export type FetchOneParams = {
  version_id?: number;
  dimension_value_id?: number[];
  version_number?: string;
  is_latest_version?: number;
  is_version_deleted?: boolean;
  show_lines?: boolean;
};

export type DeleteParams = {
  force?: boolean;
};

export class TrialBalanceServices {
  static async fetchAll(params: FetchAllParams): Promise<TrialBalanceResponse[]> {
    const response = await api.get<TrialBalanceResponse[]>("/trialbalances", {
      params,
    });
    return response.data;
  }

  static async fetchOne(id: number, params: FetchOneParams): Promise<TrialBalanceResponse> {
    const response = await api.get<TrialBalanceResponse>(`/trialbalances/${id}`, {
      params: {
        ...params,
        dimension_value_id:
          params && params.dimension_value_id && params.dimension_value_id.length > 0
            ? params.dimension_value_id.toString()
            : null,
      },
    });
    return response.data;
  }

  static async create(payload: TrialBalanceCreatePayload): Promise<TrialBalanceResponse> {
    const response = await api.post<TrialBalanceResponse>("/trialbalances", payload);
    return response.data;
  }

  static async update(
    id: number,
    payload: TrialBalanceUpdatePayload
  ): Promise<TrialBalanceResponse> {
    const response = await api.patch<TrialBalanceResponse>(`/trialbalances/${id}`, payload);
    return response.data;
  }

  static async delete(id: number, params: DeleteParams): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(`/trialbalances/${id}`, {
      params,
    });
    return response.data;
  }

  static async uploadFile(file: File): Promise<ApiStatusResponse> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await api.post<ApiStatusResponse>(`/upload/trialbalances`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
}
