import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";

import Avatar from "@synerise/ds-avatar";
import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { LockM } from "@synerise/ds-icon";
import InlineEdit from "@synerise/ds-inline-edit";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";
import Typography from "@synerise/ds-typography";

import { StarRate } from "@/components/StarRate";
import { useEntities } from "@/hooks/useEntities";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { useKpi } from "../hooks/useKpi";
import { useKpiGroups } from "../hooks/useKpiGroups";
import { kpiFormEditSchema } from "./KpiFormEdit.schema";
import { KPIFormEditValues } from "./KpiFormEdit.types";

const { Title, Text } = Typography;

// TODO: is calculated
export function KPIFormEdit() {
  const navigate = useNavigate();
  const { groups } = useKpiGroups();
  const { id } = useParams<{ id: string }>();
  const validate = useValidationSchema(kpiFormEditSchema);
  const { units } = useSupportInfos();
  const { allEntities } = useEntities();
  const { kpi, kpiLoading, updateKpi } = useKpi(Number(id));

  const updateKPIMetaLoading = false;

  async function onSubmit(values: KPIFormEditValues) {
    const onSuccess = () => {
      message.success("KPI updated");
      navigate("/kpis/library");
    };

    const onError = (msg: string) => {
      message.error(msg);
    };

    await updateKpi(Number(id), values, onSuccess, onError);
  }

  const kPIFormEditInitialValues = Object.assign({}, kpi);

  return (
    <Form<KPIFormEditValues>
      onSubmit={onSubmit}
      initialValues={kPIFormEditInitialValues}
      validate={validate}
    >
      {({ handleSubmit, values }) => (
        <Layout
          header={
            <PageHeader
              onGoBack={() => navigate("/kpis/library")}
              title="Update KPI"
              rightSide={
                <>
                  <Button
                    type="tertiary"
                    onClick={() => navigate("/kpis/library")}
                    disabled={updateKPIMetaLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={updateKPIMetaLoading}
                    loading={updateKPIMetaLoading}
                  >
                    Save Changes
                  </Button>
                </>
              }
            />
          }
        >
          <ModuleStyled.MaxWidthContent>
            <ModuleStyled.CardsWrapper>
              {kpiLoading ? (
                <Loader labelPosition="bottom" label="Loading KPI Meta..." />
              ) : (
                <>
                  <ModuleStyled.AvatarContainer>
                    <Avatar
                      shape="square"
                      size="large"
                      backgroundColor="purple"
                      backgroundColorHue="500"
                    >
                      AS
                    </Avatar>
                    <ModuleStyled.AvatarInputsWrapper>
                      <Field name="title">
                        {({ input, meta }) => (
                          <InlineEdit
                            input={{ ...input, placeholder: "KPI Title" }}
                            size="normal"
                            disabled={false}
                            hideIcon={false}
                            error={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                      <Field name="description">
                        {({ input, meta }) => (
                          <InlineEdit
                            input={{ ...input, placeholder: "KPI Description" }}
                            size="small"
                            disabled={false}
                            hideIcon={false}
                            error={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </ModuleStyled.AvatarInputsWrapper>
                  </ModuleStyled.AvatarContainer>
                  <Card
                    background="white-shadow"
                    title="Main Information"
                    withHeader
                    description="The KPIs main information"
                    icon={<LockM />}
                    iconColor={"#AEAEAE"}
                  >
                    <ModuleStyled.InputWrapper>
                      <Field
                        name="periodicity"
                        render={({ input, meta }) => (
                          <Select
                            {...input}
                            label="Periodicity"
                            description="Periodic interval for the KPI values"
                            errorText={meta.touched && meta.error}
                          >
                            <Select.Option value="day">Day</Select.Option>
                            <Select.Option value="week">Week</Select.Option>
                            <Select.Option value="month">Month</Select.Option>
                            <Select.Option value="quarter">Quarter</Select.Option>
                            <Select.Option value="year">Year</Select.Option>
                          </Select>
                        )}
                      />
                      <Field
                        name="group_id"
                        render={({ input, meta }) => (
                          <Select
                            {...input}
                            label="Group"
                            description="Select the group this KPI will be a part of"
                            errorText={meta.touched && meta.error}
                            allowClear
                          >
                            {groups.map((group) => (
                              <Select.Option key={group.id} value={group.id}>
                                {group.title}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      />

                      <Field
                        name="units_id"
                        render={({ input, meta }) => (
                          <Select
                            {...input}
                            label="Unit"
                            description="Select the unit type that will be used in this KPI"
                            errorText={meta.touched && meta.error}
                          >
                            {units.map((unit) => (
                              <Select.Option key={unit.id} value={unit.id}>
                                {unit.title}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      />

                      <Field name="aggregation">
                        {({ input, meta }) => (
                          <Select
                            {...input}
                            label="Aggregation"
                            description="How to aggregate the KPI for different periods"
                            errorText={meta.touched && meta.error}
                          >
                            <Select.Option value="sum">Sum</Select.Option>
                            <Select.Option value="average">Average</Select.Option>
                            <Select.Option value="balance">Balance</Select.Option>
                          </Select>
                        )}
                      </Field>

                      <Field name="version">
                        {({ input, meta }) => (
                          <Input
                            {...input}
                            type="number"
                            min={0}
                            step={1}
                            label="Version"
                            description="Set a custom version to this KIP"
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      </Field>

                      <Title level={4}>Importance</Title>
                      <Field name="importance">
                        {({ input, meta }) => (
                          <StarRate rate={input.value} setRate={input.onChange} />
                        )}
                      </Field>
                      <Text style={{ marginBottom: "15px" }}>Set the importance of this KPI</Text>

                      <Field name="is_higher">
                        {({ input, meta }) => (
                          <Switch
                            {...input}
                            label="Higher is better"
                            description="Select if the values of this KPI are meant to be interpreted as higher values better performance"
                            checked={input.value}
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      </Field>

                      <Field name="shared">
                        {({ input, meta }) => (
                          <Switch
                            {...input}
                            label="Shared"
                            description="Select if this KPI will be shared across entities"
                            checked={input.value}
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      </Field>

                      <Field name="is_entity">
                        {({ input, meta }) => (
                          <Switch
                            {...input}
                            label="Is a entity"
                            description="Select if this KPI is a entity"
                            checked={input.value}
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      </Field>

                      {values.is_entity && (
                        <Field name="entity_id">
                          {({ input, meta }) => (
                            <Select
                              {...input}
                              label="Entity"
                              errorText={meta.touched && meta.error}
                            >
                              {allEntities.map((entity) => (
                                <Select.Option key={entity.id} value={entity.id}>
                                  {entity.name}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Field>
                      )}
                    </ModuleStyled.InputWrapper>
                  </Card>
                </>
              )}
            </ModuleStyled.CardsWrapper>
          </ModuleStyled.MaxWidthContent>
        </Layout>
      )}
    </Form>
  );
}
