import styled from "styled-components";

export const GridItemContainer = styled.div<{ isSelected: boolean }>`
  height: 100%;
  padding: 5px;
  padding-bottom: 50px;
  background-color: #fff;
  border-radius: 2px;
  border: ${({ isSelected, theme }) =>
    isSelected ? `2px solid ${theme.palette["blue-600"]}` : "2px solid transparent"};

  & h3 {
    height: 35px;
    text-align: center;
    font-size: 1.1rem;
  }
`;
