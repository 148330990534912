import { api } from "@/utils/http";

import { AccountCreatePayload, AccountResponse, AccountUpdatePayload } from "./accounts.types";

export class AccountsServices {
  static async fetchAll(chartId: number): Promise<AccountResponse[]> {
    const response = await api.get<AccountResponse[]>(`/charts/${chartId}/accounts`);
    return response.data;
  }

  static async fetchOne(chartId: number, accountId: number): Promise<AccountResponse> {
    const response = await api.get<AccountResponse>(`/charts/${chartId}/accounts/${accountId}`);
    return response.data;
  }

  static async create(chartId: number, payload: AccountCreatePayload): Promise<AccountResponse> {
    const response = await api.post<AccountResponse>(`/charts/${chartId}/accounts`, payload);
    return response.data;
  }

  static async update(
    chartId: number,
    accountId: number,
    payload: AccountUpdatePayload
  ): Promise<AccountResponse> {
    const response = await api.patch<AccountResponse>(
      `/charts/${chartId}/accounts/${accountId}`,
      payload
    );
    return response.data;
  }

  static async delete(chartId: number, accountId: number): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(
      `/charts/${chartId}/accounts/${accountId}`
    );
    return response.data;
  }
}
