import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #a7a7a7;
  cursor: pointer;
  padding: 20px;

  &:hover {
    & .card-actions {
      visibility: visible;
      display: block;
    }
  }

  & .card-line {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6rem;

  & .card-title {
    font-weight: bold;
    color: black;
    font-size: 1.05rem;
  }
`;
