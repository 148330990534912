import { Link } from "react-router-dom";

import Icon, { MegaphoneM } from "@synerise/ds-icon";

import * as Styled from "./styles";

export function RecentActivityItem() {
  return (
    <Styled.Container>
      <div className="activity">
        <Icon component={<MegaphoneM />} color="#8f8f8f" />
        <span>
          New <Link to="#">Post</Link> by <Link to="#">John Smith</Link>
        </span>
      </div>
      <div className="date">
        <span>05/Oct</span>
        <span className="date--hour">08h26</span>
      </div>
    </Styled.Container>
  );
}
