import { Navigate, Route, Routes } from "react-router-dom";

import { ActivateEmail } from "@/core/login/ActivateEmail/ActiveEmail";
import { ActivateStatus } from "@/core/login/ActivateStatus/ActiveStatus";
import { ForgotPassword } from "@/core/login/ForgotPassword/ForgotPassword";
import { ForgotPasswordEmailSent } from "@/core/login/ForgotPassword/ForgotPasswordEmailSent";
import { InvitationStatus } from "@/core/login/InvitationStatus/InvitationStatus";
import { Login } from "@/core/login/Login/Login";
import { Register } from "@/core/login/Register/Register";
import { ResetPassword } from "@/core/login/ResetPassword/ResetPassword";
import { SelectTenant } from "@/core/login/SelectTenant/SelectTenant";
import { ValidateResetEmailToken } from "@/core/login/ValidateResetEmailToken/ValidateResetEmailToken";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/email-sent/:email" element={<ForgotPasswordEmailSent />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/tenants" element={<SelectTenant />} />
      <Route path="/register/:token" element={<Register />} />
      <Route path="/validate-token/:token" element={<ValidateResetEmailToken />} />
      <Route path="/activate-email/:token" element={<ActivateEmail />} />
      <Route path="/invite" element={<InvitationStatus />} />
      <Route path="/activate" element={<ActivateStatus />} />

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
