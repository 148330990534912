import { Link } from "react-router-dom";
import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { EditM, TrashM, TagM, BookM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as TableStyled from "@/components/Table/styles";

import { dataManagementData } from "../../data-management.data";

export type ChartOfAccountsTableData = {
  id: number;
  name: string;
  type: string;
  currency: string;
};

type TableColumnsProps = {
  onEditChartOfAccounts: (id: number) => void;
  onRemoveChartOfAccounts: (id: number, name: string) => Promise<void>;
};

export function tableColumns({
  onEditChartOfAccounts,
  onRemoveChartOfAccounts,
}: TableColumnsProps): Column<ChartOfAccountsTableData>[] {
  return [
    {
      Header: "Name",
      accessor: "name",
      sortDescFirst: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <Link to={dataManagementData.chartOfAccounts.path + `/${row.original.id}`}>
          <UserTypeCell type={row.original.name} />
        </Link>
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <div style={{ display: "flex" }}>
          <Icon component={<BookM />} color="grey" />
          <UserTypeCell type={row.original.type} />
        </div>
      ),
    },
    {
      Header: "Currency",
      accessor: "currency",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (!row.original.currency) return null;
        return <UserTypeCell type={row.original.currency} />;
      },
    },
    {
      Header: "",
      accessor: "id",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <TableStyled.RowCellActionButtons>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => {
              console.log("Tagging Chart of Accounts");
            }}
          >
            <Icon color="#C4C4C4" component={<TagM />} />
          </Button>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => {
              onEditChartOfAccounts(row.original.id);
            }}
          >
            <Icon color="#C4C4C4" component={<EditM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ${row.original.name}?`}
            onConfirm={() => onRemoveChartOfAccounts(row.original.id, row.original.name)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button mode="single-icon" type="ghost-white">
              <Icon color="#C4C4C4" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </TableStyled.RowCellActionButtons>
      ),
    },
  ];
}
