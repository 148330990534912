import styled, { css } from "styled-components";

function selectBadgeStyle(color: string) {
  return css`
    border: 1px solid ${color};
    color: ${color};
  `;
}

export const StatusBadge = styled.div<{ active: boolean }>`
  width: 100px;
  border-radius: 5px;
  padding: 3px 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  ${({ active, theme }) =>
    active
      ? selectBadgeStyle(theme.palette["green-600"])
      : selectBadgeStyle(theme.palette["red-600"])};
`;
