import { useMutation } from "react-query";

import { DimensionsTableData } from "@/modules/data-management/dimensions/dimensions.columns";
import { DimensionsService } from "@/modules/data-management/dimensions/dimensions.services";
import {
  DimensionValueCreatePayload,
  DimensionValueResponse,
  DimensionValueShortResponse,
  DimensionValueUpdatePayload,
} from "@/modules/data-management/dimensions/dimensions.types";
import { queryClient } from "@/utils/queryClient";

import { DIMENSION_TYPES_QUERY_KEY, useDimensionTypes } from "./useDimensionTypes";

const DIMENSIONS_QUERY_KEY = "dimensions";

type RemoveDimensionProps = {
  dimensionId: number;
  dimensionValueId: number;
  onSuccess: () => void;
  onError: (text: string) => void;
};

export const useDimensions = () => {
  const { allDimensionTypes } = useDimensionTypes();

  function mapDimensionToTable(dimension: {
    dimensionId: number;
    dimensionValueId: number;
    name: string;
    type: string;
  }): DimensionsTableData {
    const dimensionType = allDimensionTypes.find(({ id }) => id === dimension.dimensionId);

    if (!dimensionType) {
      throw new Error("Dimension is not found");
    }

    return {
      dimensionId: dimension.dimensionId,
      dimensionValueId: dimension.dimensionValueId,
      name: dimension.name,
      type: dimensionType.title,
    };
  }

  function mapDimensionsListToTable(
    dimensions: {
      dimensionId: number;
      dimensionValueId: number;
      name: string;
      type: string;
    }[] = []
  ): DimensionsTableData[] {
    return dimensions.map(mapDimensionToTable);
  }

  const createDimension = useMutation(
    async ({
      dimensionId,
      dimensionValuePayload,
    }: {
      dimensionId: number;
      dimensionValuePayload: DimensionValueCreatePayload;
      onSuccess: () => void;
    }) => {
      const data = await DimensionsService.create(dimensionId, dimensionValuePayload);
      return data;
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(DIMENSIONS_QUERY_KEY);
        queryClient.invalidateQueries(DIMENSION_TYPES_QUERY_KEY);
        onSuccess();
      },
    }
  );

  const removeDimensionMutation = useMutation(
    async ({ dimensionId, dimensionValueId }: RemoveDimensionProps) => {
      await DimensionsService.delete(dimensionId, dimensionValueId);
    },
    {
      onMutate: async ({ dimensionId, onSuccess }) => {
        onSuccess();
        await queryClient.cancelQueries(DIMENSIONS_QUERY_KEY);

        const previousDimensions = queryClient.getQueryData<DimensionValueResponse[]>([
          DIMENSIONS_QUERY_KEY,
        ]);

        if (!previousDimensions) {
          return;
        }

        const newDimensions = previousDimensions.filter(
          (dimension) => dimension.id !== dimensionId
        );

        queryClient.setQueryData([DIMENSIONS_QUERY_KEY], newDimensions);

        return {
          previousDimensions,
        };
      },
      onError: (e: any, { onError }, context: any) => {
        queryClient.setQueryData(DIMENSIONS_QUERY_KEY, context.previousDimensions);

        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(DIMENSIONS_QUERY_KEY);
        queryClient.invalidateQueries(DIMENSION_TYPES_QUERY_KEY);
      },
    }
  );

  const removeDimension = async (props: RemoveDimensionProps) => {
    await removeDimensionMutation.mutateAsync(props);
  };

  const updateDimension = useMutation(
    ({
      dimensionId,
      dimensionValueId,
      payload,
    }: {
      dimensionId: number;
      dimensionValueId: number;
      payload: DimensionValueUpdatePayload;
      onSuccess: () => void;
      onError: (text: string) => void;
    }) => {
      return DimensionsService.update(dimensionId, dimensionValueId, payload);
    },
    {
      onSuccess: (_, { onSuccess }) => {
        onSuccess();
      },
      onError: (err: any, { onError }, context: any) => {
        queryClient.setQueryData(DIMENSIONS_QUERY_KEY, context.previousDimensions);
        if (err && err.response && err.response.data && err.response.data.detail) {
          onError(err.response.data.detail);
        } else {
          onError("Something went wrong");
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(DIMENSIONS_QUERY_KEY);
        queryClient.invalidateQueries(DIMENSION_TYPES_QUERY_KEY);
      },
    }
  );

  const getDimensionValueByType = (dimensionId: number) => {
    const dimensionType = allDimensionTypes.find(
      (dimensionType) => dimensionType.id === dimensionId
    );
    if (!dimensionType) return [];
    return dimensionType.values;
  };

  const getDimensionById = (
    dimensionId: number,
    dimensionValueId: number
  ): DimensionValueShortResponse | null => {
    const dimension = allDimensionTypes.find((dimension) => dimension.id === dimensionId);
    if (!dimension) return null;
    const dimensionValue = dimension.values.find((value) => value.id === dimensionValueId);
    if (!dimensionValue) return null;
    return dimensionValue;
  };

  return {
    createDimension,
    updateDimension,
    removeDimension,
    getDimensionById,
    getDimensionValueByType,
    mapDimensionsListToTable,
  };
};
