import { default as DSDropdown } from "@synerise/ds-dropdown";
import Icon, { Add3M } from "@synerise/ds-icon";
import { default as DSMenu } from "@synerise/ds-menu";

import { KPITableData } from "./kpi-table.types";
import { useKPIsTable } from "./useKPIsTable";

type KPIDropdownActionsProps = {
  kpi: KPITableData;
};

export function KPIDropdownActions({ kpi }: KPIDropdownActionsProps) {
  const { openCreateBudgetModal } = useKPIsTable();

  return (
    <DSDropdown.Wrapper style={{ boxShadow: "5px 5px 5px 3px red !important" }}>
      <DSMenu style={{ padding: "8px 2px 8px 8px" }}>
        <DSMenu.Item
          prefixel={<Icon component={<Add3M />} />}
          onClick={() => openCreateBudgetModal(kpi)}
        >
          Create Budget
        </DSMenu.Item>
        <DSMenu.Item prefixel={<Icon component={<Add3M />} />}>Add comment</DSMenu.Item>
        <DSMenu.Item prefixel={<Icon component={<Add3M />} />}>Flag</DSMenu.Item>
        <DSMenu.Item prefixel={<Icon component={<Add3M />} />}>View</DSMenu.Item>
      </DSMenu>
    </DSDropdown.Wrapper>
  );
}
