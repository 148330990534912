import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTable, useExpanded } from "react-table";

import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import * as Styled from "@/components/Table/styles";
import { CHART_OF_ACCOUNTS_QUERY_KEY, useSingleChartOfAccounts } from "@/hooks/useChartOfAccounts";
import { queryClient } from "@/utils/queryClient";

import { dataManagementData } from "../../data-management.data";
import { leftLayoutContent } from "../../DataManagementLeftLayoutContent";
import { tableColumns } from "./account-mapping-details-columns";
import { AccountMappingServices } from "./account-mapping.services";
import { AccountMapCreatePayload, AccountMapUpdatePayload } from "./account-mapping.types";
import { AccountMappingDetailsEmptyTable, BodyContainer } from "./AccountMappingDetailsEmptyTable";
import { MAP_ACCOUNT_QUERY_KEY, useMapAccountsByChartMap } from "./hooks/useMapAccountsByChartMap";
import { mapDataToAccountMappingDetailsTable } from "./utils/mapDataToAccountMappingDetailsTable";

export function AccountMappingDetails() {
  const navigate = useNavigate();
  const {
    id: mapId,
    targetid,
    sourceid,
  } = useParams<{
    id: string;
    targetid: string;
    sourceid: string;
  }>();

  const onError = (msg: string) => {
    message.error(msg);
  };

  const { chartOfAccounts: targetChartOfAccounts, chartOfAccountsLoading: isTargetChartLoading } =
    useSingleChartOfAccounts(Number(targetid), onError);
  const { chartOfAccounts: sourceTargetOfAccounts, chartOfAccountsLoading: isSourceChartLoading } =
    useSingleChartOfAccounts(Number(sourceid), onError);

  const { mappedAccounts, isMappedAccountsLoading } = useMapAccountsByChartMap(Number(mapId));

  const handleUpdateAccountMap = async (
    targetAccountId: number,
    sourceAccountId: number,
    accountMapId: number
  ) => {
    const payload: AccountMapUpdatePayload = {
      source_id: sourceAccountId,
      mapped_id: targetAccountId,
    };
    try {
      await AccountMappingServices.updateAccountMap(Number(mapId), accountMapId, payload);
      message.success("Account mapped updated");
    } catch (error: any) {
      onError(error.response.data.detail ? error.response.data.detail : "Something went wrong");
    }
  };

  const handleCreateAccountMap = async (targetAccountId: number, sourceAccountId: number) => {
    const payload: AccountMapCreatePayload = {
      source_id: sourceAccountId,
      mapped_id: targetAccountId,
    };
    try {
      await AccountMappingServices.createAccountMap(Number(mapId), payload);
      message.success("Account mapped created");
    } catch (error: any) {
      onError(error.response.data.detail ? error.response.data.detail : "Something went wrong");
    }
  };

  const handleMapAccount = async (
    targetAccountId: number,
    sourceAccountId: number,
    accountMapId: number | null
  ) => {
    if (accountMapId) {
      await handleUpdateAccountMap(targetAccountId, sourceAccountId, accountMapId);
    } else {
      await handleCreateAccountMap(targetAccountId, sourceAccountId);
    }
    queryClient.invalidateQueries(CHART_OF_ACCOUNTS_QUERY_KEY);
    queryClient.invalidateQueries(MAP_ACCOUNT_QUERY_KEY);
  };

  const handleRemoveMapAccount = async (accountMapId: number) => {
    try {
      await AccountMappingServices.removeAccountMap(Number(mapId), accountMapId);
      message.success("Account mapped removed");
      queryClient.invalidateQueries(CHART_OF_ACCOUNTS_QUERY_KEY);
      queryClient.invalidateQueries(MAP_ACCOUNT_QUERY_KEY);
    } catch (err: any) {
      message.error(
        err && err.response && err.response.data && err.response.data.msg
          ? err.response.data.msg
          : "Error deleting account mapping"
      );
    }
  };

  const columns = useMemo(() => {
    return tableColumns({
      onMapAccount: handleMapAccount,
      sourceAccounts: sourceTargetOfAccounts?.accounts ?? [],
      onRemoveMapAccount: handleRemoveMapAccount,
    });
  }, [sourceTargetOfAccounts?.accounts]);

  const data = useMemo(() => {
    return mapDataToAccountMappingDetailsTable(
      targetChartOfAccounts?.accounts ?? [],
      mappedAccounts
    );
  }, [targetChartOfAccounts?.accounts, mappedAccounts]);

  const expandedRows = useMemo(() => {
    if (data) {
      const hash: Record<string, boolean> = {};
      const d = data;
      if (d.length > 0) {
        d.forEach((_: any, index: number) => {
          hash[index] = true;
        });
      }
      return hash;
    }
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultCanSort: false,
      defaultCanFilter: false,
      initialState: {
        expanded: expandedRows,
      },
      autoResetExpanded: false,
    },
    useExpanded
  );

  return (
    <Layout
      left={leftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate(dataManagementData.accountsMapping.path)}
          title="Account Mapping"
        />
      }
    >
      <>
        <Card title={`Mapping: ${targetChartOfAccounts?.title}`} withHeader>
          <Styled.Table {...getTableProps()}>
            <Styled.TableHead>
              {headerGroups.map((headerGroup) => (
                <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Styled.TableHeader {...column.getHeaderProps()}>
                      <Styled.TableCellWithFiltersWrapper>
                        {column.render("Header")}
                      </Styled.TableCellWithFiltersWrapper>
                    </Styled.TableHeader>
                  ))}
                </Styled.TableHeaderRow>
              ))}
            </Styled.TableHead>
            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Styled.TableBodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Styled.TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Styled.TableCell>
                      );
                    })}
                  </Styled.TableBodyRow>
                );
              })}
            </Styled.TableBody>
          </Styled.Table>
        </Card>
        {(isTargetChartLoading || isSourceChartLoading || isMappedAccountsLoading) &&
        data.length < 1 ? (
          <BodyContainer>
            <Loader label="Loading mapped chart. Just a second" labelPosition="bottom" />
          </BodyContainer>
        ) : (
          !targetChartOfAccounts?.accounts ||
          (targetChartOfAccounts.accounts.length < 1 && (
            <BodyContainer>
              <AccountMappingDetailsEmptyTable />
            </BodyContainer>
          ))
        )}
      </>
    </Layout>
  );
}
