import { BoardMeetingsSection } from "../BoardMeetingsSection";
import { DocumentsSection } from "../DocumentsSection";
import { RecentActivitySection } from "../RecentActivitySection";
import { SummarySection } from "../SummarySection";
import * as Styled from "./styles";

export function RightDrawer() {
  return (
    <Styled.Container>
      <SummarySection title="Recent Activity">
        <RecentActivitySection />
      </SummarySection>
      <SummarySection title="Board Meetings">
        <BoardMeetingsSection />
      </SummarySection>
      <SummarySection title="Documents &amp; Files">
        <DocumentsSection />
      </SummarySection>
    </Styled.Container>
  );
}
