import BoringAvatar from "boring-avatars";
import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import DSFlag from "@synerise/ds-flag";
import { CompanyM } from "@synerise/ds-icon";
import InlineEdit from "@synerise/ds-inline-edit";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";

import { StatusBadge } from "@/components/StatusBadge";
import { TenantPatch } from "@/core/tenants/tenants.types";
import { useAuth } from "@/hooks/useAuth";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import { useTenant } from "@/hooks/useTenant";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";
import { formatHexSymbolsToUnicode } from "@/utils/formatHexSymbolsToUnicode";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";
import * as Styled from "./organization.styles";

type OrganizationGlobalSettingsFormValues = {
  name?: string;
  namespace?: string;
  country?: string;
  language?: string;
  currency?: string;
  fiscal_year?: FiscalYear;
  industry_code?: string;
  isic_code?: string;
};

const organizationGlobalSettingsSchema = yup.object().shape({
  language: yup.string(),
  date_format: yup.string(),
  number_format: yup.string(),
});

const formatDate = (date: Date) => {
  return formatDistance(date, new Date());
};

export function OrganizationGlobalSettings() {
  const navigate = useNavigate();
  const { currentTenant } = useAuth();

  const onGetTenantError = (errorMessage: any) => {
    message.error(errorMessage);
    navigate("/settings/home");
  };

  const { tenant, updateTenant, updateTenantLoading } = useTenant(
    onGetTenantError,
    currentTenant?.id
  );

  const validate = useValidationSchema(organizationGlobalSettingsSchema);
  const { countries, currencies, languages } = useSupportInfos();

  const [inEditModeProfile, setInEditModeProfile] = useState(false);
  const [inEditModeSettings, setInEditModeSettings] = useState(false);
  const [tenantName, setTenantName] = useState(tenant?.name ?? "");

  useEffect(() => {
    setTenantName(tenant?.name ?? "");
  }, [tenant]);

  async function updateTenantName(name: string) {
    const onSuccess = () => {};
    const onError = (errorMessage: any) => {
      message.error(errorMessage);
    };

    const payload: TenantPatch = {
      name,
    };

    await updateTenant({
      payload,
      onError,
      onSuccess,
    });
  }

  async function onSubmit(values: OrganizationGlobalSettingsFormValues) {
    const onSuccess = () => {
      message.success("Organization settings updated successfully");
      setInEditModeSettings(false);
    };

    const onError = (errorMessage: string) => {
      message.error(errorMessage);
    };

    const payload: TenantPatch = {
      name: values.name,
      namespace: values.namespace,
      country: values.country,
      language: values.language,
      currency: values.currency,
      preferences: {
        fiscal_year: values.fiscal_year,
      },
    };

    await updateTenant({
      payload,
      onError,
      onSuccess,
    });
  }

  const initialFormValues = {
    name: tenant?.name,
    namespace: tenant?.namespace,
    country: tenant?.country_code,
    language: tenant?.language_code,
    currency: tenant?.currency_code,
    fiscal_year: tenant?.preferences?.fiscal_year,
  };

  return (
    <Layout
      left={LeftLayoutContent}
      header={<PageHeader onGoBack={() => navigate("/settings/home")} title="Organization" />}
    >
      <ModuleStyled.MaxWidthContent>
        <Form<OrganizationGlobalSettingsFormValues>
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialFormValues}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModuleStyled.CardsWrapper>
                <ModuleStyled.AvatarContainer>
                  <BoringAvatar
                    size={60}
                    name={tenantName ?? ""}
                    variant="bauhaus"
                    colors={["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"]}
                  />
                  <Styled.TenantProfileInfos>
                    <InlineEdit
                      input={{
                        value: tenantName ?? "",
                        onChange: (event) => {
                          const { value } = event.target;
                          setTenantName(value);
                        },
                        onBlur: (event) => {
                          const { value } = event.target;

                          if (value !== tenant?.name) {
                            updateTenantName(value);
                          }
                        },
                      }}
                      size="normal"
                    />

                    <StatusBadge active={true} />
                  </Styled.TenantProfileInfos>
                  <Styled.TenantUpdatedInfo>
                    <p>Last updated</p>
                    <p>
                      {tenant?.updated_at
                        ? formatDate(new Date(tenant?.updated_at))
                        : tenant?.created_at
                        ? formatDate(new Date(tenant?.created_at))
                        : 0}{" "}
                      ago
                    </p>
                  </Styled.TenantUpdatedInfo>
                </ModuleStyled.AvatarContainer>

                <Card
                  background="white-shadow"
                  lively
                  title="Profile"
                  withHeader
                  icon={<CompanyM />}
                  iconColor={"#AEAEAE"}
                  headerSideChildren={
                    !inEditModeProfile ? (
                      <Styled.SideButtonsContainer>
                        <Button onClick={() => setInEditModeProfile(true)} type="secondary">
                          Edit
                        </Button>
                      </Styled.SideButtonsContainer>
                    ) : (
                      <Styled.SideButtonsContainer>
                        <Button onClick={() => setInEditModeProfile(false)} type="secondary">
                          Cancel
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          type="primary"
                          loading={updateTenantLoading}
                          disabled={updateTenantLoading}
                        >
                          Save
                        </Button>
                      </Styled.SideButtonsContainer>
                    )
                  }
                >
                  <ModuleStyled.InputWrapper>
                    <Field name="namespace">
                      {({ input }) => (
                        <Input
                          {...input}
                          label="Namespace"
                          disabled={!inEditModeProfile}
                          description="Your organization's unique namespace"
                        />
                      )}
                    </Field>
                    <Field name="country">
                      {({ input, meta }) => (
                        <Select
                          {...input}
                          showSearch
                          label="Country"
                          disabled={!inEditModeProfile}
                          description="The organization's headquarters country"
                          placeholder={"Country"}
                          errorText={meta.touched && meta.error}
                          options={countries.map((country) => ({
                            value: country.code,
                            name: country.lang_en,
                            label: (
                              <Styled.FlagSelectOption>
                                <DSFlag country={country.alpha2} />
                                <p>{country.lang_en}</p>
                              </Styled.FlagSelectOption>
                            ),
                          }))}
                          filterOption={(inputValue, option) => {
                            return (
                              option!.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                            );
                          }}
                        />
                      )}
                    </Field>
                    <Field name="language">
                      {({ input, meta }) => (
                        <Select
                          {...input}
                          showSearch
                          label="Language"
                          disabled={!inEditModeProfile}
                          description="Main language adopted by the organization"
                          placeholder={"Language"}
                          errorText={meta.touched && meta.error}
                          options={languages.map((language) => ({
                            value: language.code,
                            name: language.lang_en,
                            label: language.lang_en,
                          }))}
                          filterOption={(inputValue, option) => {
                            return (
                              option!.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                            );
                          }}
                        />
                      )}
                    </Field>
                    <Field name="currency">
                      {({ input, meta }) => (
                        <Select
                          {...input}
                          showSearch
                          label="Currency"
                          disabled={!inEditModeProfile}
                          description="The main currency used by the organization"
                          placeholder={"Currency"}
                          errorText={meta.touched && meta.error}
                          options={currencies.map((currency) => ({
                            value: currency.code,
                            name: currency.name,
                            label: (
                              <p>
                                {currency.name} (
                                {currency &&
                                  currency.symbol_hex &&
                                  formatHexSymbolsToUnicode(currency.symbol_hex)}
                                )
                              </p>
                            ),
                          }))}
                          filterOption={(inputValue, option) => {
                            return (
                              option!.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                            );
                          }}
                        ></Select>
                      )}
                    </Field>
                    <Field name="fiscal_year">
                      {({ input }) => (
                        <Select
                          {...input}
                          label="Fiscal Year"
                          disabled={!inEditModeSettings}
                          description="This is the default number precision for the whole organization"
                        >
                          <Select.Option value={"jan"}>January</Select.Option>
                          <Select.Option value={"feb"}>February</Select.Option>
                          <Select.Option value={"mar"}>March</Select.Option>
                          <Select.Option value={"apr"}>April</Select.Option>
                          <Select.Option value={"may"}>May</Select.Option>
                          <Select.Option value={"jun"}>June</Select.Option>
                          <Select.Option value={"jul"}>July</Select.Option>
                          <Select.Option value={"aug"}>August</Select.Option>
                          <Select.Option value={"sep"}>September</Select.Option>
                          <Select.Option value={"oct"}>October</Select.Option>
                          <Select.Option value={"nov"}>November</Select.Option>
                          <Select.Option value={"dec"}>December</Select.Option>
                        </Select>
                      )}
                    </Field>
                  </ModuleStyled.InputWrapper>
                </Card>
              </ModuleStyled.CardsWrapper>
            </form>
          )}
        </Form>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
