import { useState } from "react";
import { Field, Form } from "react-final-form";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Checkbox from "@synerise/ds-checkbox";
import { LockM } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";
import { Title } from "@synerise/ds-typography";

import { useAuth } from "@/hooks/useAuth";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";
import * as Styled from "./account.styles";

export type UserPreferencesType = {
  language?: string;
  date_format?: string;
  number_format?: string;
};

const UserPreferencesObject = yup.object().shape({
  language: yup.string(),
  date_format: yup.string(),
  number_format: yup.string(),
});

export function AccountNotifications() {
  const { user } = useAuth();
  const validate = useValidationSchema(UserPreferencesObject);
  const [isDisabledEvents, setIsDisabledEvents] = useState(true);
  const [isDisabledChannel, setIsDisabledChannel] = useState(true);

  function onSubmit() {
    console.log("Submitted");
  }

  return (
    <Layout
      left={LeftLayoutContent}
      header={<PageHeader onGoBack={() => console.log("back")} title="Notifications" />}
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Title level={3}>User Notification Settings</Title>
          <Form<UserPreferencesType> onSubmit={onSubmit} validate={validate}>
            {(handleSubmit: any, values: any) => (
              <Card
                background="white-shadow"
                lively
                title="Event Notifications"
                withHeader
                icon={<LockM />}
                iconColor={"#AEAEAE"}
                headerSideChildren={
                  isDisabledEvents ? (
                    <Styled.SideButtonsContainer>
                      <Button onClick={() => setIsDisabledEvents(false)} type="secondary" disabled>
                        Edit
                      </Button>
                    </Styled.SideButtonsContainer>
                  ) : (
                    <Styled.SideButtonsContainer>
                      <Button onClick={() => setIsDisabledEvents(true)} type="secondary">
                        Cancel
                      </Button>
                      <Button onClick={handleSubmit} type="primary">
                        Save
                      </Button>
                    </Styled.SideButtonsContainer>
                  )
                }
              >
                <form onSubmit={handleSubmit}>
                  <ModuleStyled.InputWrapper>
                    <Field name="language" initialValue={user?.preferences?.language}>
                      {(input: any, meta: any) => (
                        <Checkbox
                          description="Get notified when mentioned in a new comment"
                          {...input}
                          disabled={isDisabledEvents}
                        >
                          New mention
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="language" initialValue={user?.preferences?.language}>
                      {(input: any, meta: any) => (
                        <Checkbox
                          description="Get notified if an owned resource is updated/edited"
                          {...input}
                          disabled={isDisabledEvents}
                        >
                          Edited Owned Resource
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="language" initialValue={user?.preferences?.language}>
                      {(input: any, meta: any) => (
                        <Checkbox
                          description="Your primary email address. Will be used for notifications and password recovery"
                          {...input}
                          disabled={isDisabledEvents}
                        >
                          New Entity Creation
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="language" initialValue={user?.preferences?.language}>
                      {(input: any, meta: any) => (
                        <Checkbox
                          description="Your primary email address. Will be used for notifications and password recovery"
                          {...input}
                          disabled={isDisabledEvents}
                        >
                          New Ledger / Trial Balance Upload
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="language" initialValue={user?.preferences?.language}>
                      {(input: any, meta: any) => (
                        <Checkbox
                          description="Your primary email address. Will be used for notifications and password recovery"
                          {...input}
                          disabled={isDisabledEvents}
                        >
                          New Dashboard Created
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="language" initialValue={user?.preferences?.language}>
                      {(input: any, meta: any) => (
                        <Checkbox
                          description="Your primary email address. Will be used for notifications and password recovery"
                          {...input}
                          disabled={isDisabledEvents}
                        >
                          New Dashboard Created
                        </Checkbox>
                      )}
                    </Field>
                  </ModuleStyled.InputWrapper>
                </form>
              </Card>
            )}
          </Form>
          <Form<UserPreferencesType> onSubmit={onSubmit} validate={validate}>
            {(handleSubmit: any, values: any) => (
              <Card
                background="white-shadow"
                lively
                title="Notification Channel"
                withHeader
                icon={<LockM />}
                iconColor={"#AEAEAE"}
                headerSideChildren={
                  isDisabledChannel ? (
                    <Styled.SideButtonsContainer>
                      <Button onClick={() => setIsDisabledChannel(false)} type="secondary" disabled>
                        Edit
                      </Button>
                    </Styled.SideButtonsContainer>
                  ) : (
                    <Styled.SideButtonsContainer>
                      <Button onClick={() => setIsDisabledChannel(true)} type="secondary">
                        Cancel
                      </Button>
                      <Button onClick={handleSubmit} type="primary">
                        Save
                      </Button>
                    </Styled.SideButtonsContainer>
                  )
                }
              >
                <form onSubmit={handleSubmit}>
                  <ModuleStyled.InputWrapper>
                    <Field name="language" initialValue={user?.preferences?.language}>
                      {(input: any, meta: any) => (
                        <Checkbox
                          description="Get notified if an owned resource is updated/edited"
                          {...input}
                          disabled={isDisabledEvents}
                        >
                          In App Notification
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="language" initialValue={user?.preferences?.language}>
                      {(input: any, meta: any) => (
                        <Checkbox
                          description="Get notified if an owned resource is updated/edited"
                          {...input}
                          disabled={isDisabledEvents}
                        >
                          Email Notification
                        </Checkbox>
                      )}
                    </Field>
                  </ModuleStyled.InputWrapper>
                </form>
              </Card>
            )}
          </Form>
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
