import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";

import { useValidationSchema } from "@/hooks/useValidationSchema";

import * as Styled from "../login.styles";
import { useLogin } from "../useLogin";

type ForgotPasswordFormValues = {
  email: string;
};

const forgotPasswordFormSchema = yup.object().shape({
  email: yup.string().required("E-mail required").email("Invalid e-mail format"),
});

export function ForgotPassword() {
  const navigate = useNavigate();
  const validate = useValidationSchema(forgotPasswordFormSchema);
  const { forgotPassword, forgotPasswordLoading } = useLogin();

  async function onSubmit(values: ForgotPasswordFormValues) {
    const onSuccess = () => {
      message.success("E-mail sent successfully");
      navigate(`/email-sent/${values.email}`);
    };

    const onError = (text: string) => {
      message.error(text);
    };

    await forgotPassword({
      email: values.email,
      onSuccess,
      onError,
    });
  }

  return (
    <Form<ForgotPasswordFormValues> onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit }) => (
        <Styled.Form onSubmit={handleSubmit}>
          <Styled.CardContainer>
            <Card title="Forgot password" withHeader>
              <Field name="email">
                {({ input, meta }) => (
                  <Input
                    {...input}
                    label="Email"
                    placeholder="Email"
                    errorText={meta.touched && meta.error}
                  />
                )}
              </Field>

              <Styled.FooterButtonsContainer>
                <Button onClick={() => navigate("/login")}>Back to login</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={forgotPasswordLoading}
                  disabled={forgotPasswordLoading}
                >
                  Reset Password
                </Button>
              </Styled.FooterButtonsContainer>
            </Card>
          </Styled.CardContainer>
        </Styled.Form>
      )}
    </Form>
  );
}
