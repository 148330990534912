import { useLocation, useNavigate } from "react-router-dom";

import Divider from "@synerise/ds-divider";
import DSMenu from "@synerise/ds-menu";

import { governanceRoutes } from "./governance.routes";

export function GovernanceInnerMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <DSMenu
      style={{
        padding: "4px 10px",
      }}
    >
      {governanceRoutes.sections.map((section) => (
        <>
          <DSMenu.ItemGroup key={section.sectionName} title={section.sectionName}>
            {section.links.map((link) => (
              <DSMenu.Item
                key={link.fullPath}
                onClick={() => navigate(link.fullPath)}
                text={link.name}
                style={{ color: pathname === link.fullPath ? "#1890ff" : "#384356" }}
              />
            ))}
          </DSMenu.ItemGroup>

          <Divider style={{ padding: "5px 10px", margin: "10px 0px 5px 0px" }} dashed={false} />
        </>
      ))}
    </DSMenu>
  );
}
