import { useState } from "react";

import PageHeader from "@synerise/ds-page-header";
import Tabs from "@synerise/ds-tabs";

import GoalSeek from "./pages/ForecastGoalSeek";
import { ForecastOverview } from "./pages/ForecastOverview";
import Peers from "./pages/ForecastPeers";
import Studies from "./pages/ForecastStudies";
import "./styles.css";

export function Forecast() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Overview",
    },
    {
      label: "Peers",
    },
    {
      label: "Goal Seek",
    },
    {
      label: "Studies",
    },
  ];

  function getFinancialComponent(activeTab: number) {
    switch (activeTab) {
      case 0:
        return <ForecastOverview />;
      case 1:
        return <Peers />;
      case 2:
        return <GoalSeek />;
      case 3:
        return <Studies />;
    }
  }

  return (
    <>
      <PageHeader
        title="Forecast"
        tabs={
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            handleTabClick={(index: number) => setActiveTab(index)}
          />
        }
      />
      {getFinancialComponent(activeTab)}
    </>
  );
}
