import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import ButtonGroup from "@synerise/ds-button-group";
import Dropdown from "@synerise/ds-dropdown";
import Icon, { AngleDownS, FolderArchiveM } from "@synerise/ds-icon";
import Menu from "@synerise/ds-menu";

import { dataManagementData } from "@/modules/data-management/data-management.data";

import * as Styled from "./page-header-actions.styles";

type PageHeaderActionsProps = {
  onImportTrialBalanceFile: () => void;
};

const dropdownItems = (onImportTrialBalanceFile: () => void) => {
  return (
    <Dropdown.Wrapper style={{ width: "220px" }}>
      <Menu style={{ width: "100%" }} asDropdownMenu>
        <Menu.Item
          onClick={onImportTrialBalanceFile}
          prefixel={<Icon component={<FolderArchiveM />} />}
        >
          Import TrialBalance
        </Menu.Item>
      </Menu>
    </Dropdown.Wrapper>
  );
};

export function PageHeaderActions({ onImportTrialBalanceFile }: PageHeaderActionsProps) {
  const navigate = useNavigate();
  return (
    <Styled.PageHeaderActionsContainer>
      <ButtonGroup buttonsPosition="center" splitMode>
        <Button type="primary" onClick={() => navigate(dataManagementData.ledgers.importPath)}>
          Create TrialBalance
        </Button>
        <Button mode="single-icon" type="primary">
          <Dropdown
            overlay={() => dropdownItems(onImportTrialBalanceFile)}
            overlayStyle={{
              borderRadius: "3px",
            }}
            placement="bottomLeft"
          >
            <Icon component={<AngleDownS />} />
          </Dropdown>
        </Button>
      </ButtonGroup>
      <ButtonGroup buttonsPosition="center" splitMode></ButtonGroup>
    </Styled.PageHeaderActionsContainer>
  );
}
