import { UserAvatar } from "@synerise/ds-avatar";
import { Tag, TagShape } from "@synerise/ds-tags";

import * as Styled from "./styles";

export function MeetingMemberItem() {
  return (
    <Styled.Container>
      <Styled.Member>
        <UserAvatar />
        <Styled.MemberInfo>
          <Styled.MemberName>Julia Locke</Styled.MemberName>
          <Styled.MemberRole>Marketing Director</Styled.MemberRole>
        </Styled.MemberInfo>
      </Styled.Member>
      <Tag name="Confirmed" shape={TagShape.STATUS_SUCCESS} />
    </Styled.Container>
  );
}
