import styled from "styled-components";

import DSIcon from "@synerise/ds-icon";

import { StarFillSVG } from "./StarFillSVG";
import { StarSVG } from "./StarSVG";

export const Container = styled.div`
  display: flex;
`;

export const StarFillS = styled(StarFillSVG)`
  fill: #f9b52e;
  &.star-rate__icon-component--selectable {
    &:hover {
      fill: #046efa;
    }
  }
`;

export const StarS = styled(StarSVG)`
  fill: #bac4c9;
  &.star-rate__icon-component--selectable {
    &:hover {
      fill: #046efa;
    }
  }
`;

export const Icon = styled(DSIcon)`
  display: flex;
  align-items: center;
`;
