import { useState } from "react";

import Button from "@synerise/ds-button";
import DSDropdown from "@synerise/ds-dropdown";
import Icon, { OptionVerticalM, Settings2S } from "@synerise/ds-icon";
import DSMenu from "@synerise/ds-menu";
import Select from "@synerise/ds-select";

import { DateRangePicker } from "@/components/DateRangePicker/DateRangePicker";

import { dataSourceOptions } from "../../financials-is.config";
import * as Styled from "./table-filters.styles";

type TableFiltersProps = {
  periodicity: string;
  onChangePeriodicity: (periodicity: string) => void;
  dataSources: string[];
  onChangeDataSources: (dataSources: string[]) => void;
  openAdjustmentsSettingsDrawer: () => void;
};

export function TableFilters({
  periodicity,
  onChangePeriodicity,
  dataSources,
  onChangeDataSources,
  openAdjustmentsSettingsDrawer,
}: TableFiltersProps) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  function handleRangeDateFilter(newStartDate: Date | null, newEndDate: Date | null) {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }

  return (
    <Styled.TableFiltersContainer>
      <Select
        style={{ minWidth: "120px" }}
        defaultValue="time-series"
        dropdownMatchSelectWidth={true}
      >
        <Select.Option value="time-series">Time Series</Select.Option>
        <Select.Option value="waterfall">Waterfall</Select.Option>
        <Select.Option value="static">Static</Select.Option>
      </Select>
      <DateRangePicker
        onRangeDateChange={handleRangeDateFilter}
        endDate={endDate}
        startDate={startDate}
      />
      <Select style={{ minWidth: "120px" }} defaultValue={1} dropdownMatchSelectWidth={true}>
        <Select.Option value={1}>Acme Corp</Select.Option>
      </Select>
      <Select
        allowClear={false}
        mode="multiple"
        value={dataSources}
        onChange={(value: any) => {
          if (value.length > 0) onChangeDataSources(value);
        }}
        style={{ minWidth: "150px" }}
      >
        {dataSourceOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={periodicity}
        onChange={(value: any) => onChangePeriodicity(value)}
        style={{ minWidth: "120px" }}
      >
        <Select.Option value="month">Month</Select.Option>
        <Select.Option value="quarter">Quarter</Select.Option>
        <Select.Option value="year">Year</Select.Option>
      </Select>
      <DSDropdown
        overlay={
          <DSDropdown.Wrapper style={{ boxShadow: "5px 5px 5px 3px red !important" }}>
            <DSMenu style={{ padding: "8px 2px 8px 8px" }}>
              <DSMenu.Item
                prefixel={<Icon component={<Settings2S />} />}
                onClick={openAdjustmentsSettingsDrawer}
              >
                Adjustment Settings
              </DSMenu.Item>
            </DSMenu>
          </DSDropdown.Wrapper>
        }
        overlayStyle={{
          boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.25)",
        }}
      >
        <Button mode="single-icon" type="ghost" icon={<Icon component={<OptionVerticalM />} />} />
      </DSDropdown>
    </Styled.TableFiltersContainer>
  );
}
