import { api } from "@/utils/http";

import { KpiGroupCreatePayload, KpiGroupResponse, KpiGroupUpdatePayload } from "./kpi-group.types";

export class KpiGroupService {
  static async fetchAll(): Promise<KpiGroupResponse[]> {
    const response = await api.get<KpiGroupResponse[]>("/kpisGroups");
    return response.data;
  }

  static async fetchOne(id: number, loadKpis: boolean): Promise<KpiGroupResponse> {
    const response = await api.get<KpiGroupResponse>(`/kpisGroups/${id}`, {
      params: {
        kpis_meta: loadKpis,
      },
    });
    return response.data;
  }

  static async create(payload: KpiGroupCreatePayload): Promise<KpiGroupResponse> {
    const response = await api.post<KpiGroupResponse>("/kpisGroups", payload);
    return response.data;
  }

  static async update(id: number, payload: KpiGroupUpdatePayload): Promise<KpiGroupResponse> {
    const response = await api.patch<KpiGroupResponse>(`/kpisGroups/${id}`, payload);
    return response.data;
  }

  static async delete(id: number): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(`/kpisGroups/${id}`);
    return response.data;
  }
}
