import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Member = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MemberName = styled.span`
  font-weight: bold;
`;

export const MemberRole = styled.span`
  color: #a7a7a7;
`;
