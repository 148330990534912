import Button from "@synerise/ds-button";

import { Container } from "./styles";

export type KPISourcesCardProps = {
  imsSrc: string;
  title: string;
  description: string;
  onRequest: (title: string) => void;
};

export function KPISourcesCard({ description, imsSrc, title, onRequest }: KPISourcesCardProps) {
  return (
    <Container>
      <div className="card-content">
        <img src={imsSrc} alt={title} className="card-logo" />
        <span className="card-title">{title}</span>
        <p className="card-description">{description}</p>
      </div>
      <Button onClick={() => onRequest(title)}>Request</Button>
    </Container>
  );
}
