import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Column, useRowSelect, useSortBy, useTable, useFilters } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { FilterM } from "@synerise/ds-icon";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";

import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import { EmptyTable } from "@/components/Table/EmptyTable";
import { SortIcon } from "@/components/Table/SortIcon";
import * as Styled from "@/components/Table/styles";
import { useDimensions } from "@/hooks/useDimensions";
import { useDimensionTypes } from "@/hooks/useDimensionTypes";

import { dataManagementData } from "../data-management.data";
import { DimensionsTableData, tableColumns } from "./dimensions.columns";

type DimensionsTableProps = {
  dimensions: DimensionsTableData[];
};

export function DimensionsTable({ dimensions }: DimensionsTableProps) {
  const navigate = useNavigate();
  const { removeDimension } = useDimensions();
  const { isDimensionTypesLoading } = useDimensionTypes();

  async function handleRemoveDimension(
    dimensionId: number,
    dimensionValueId: number,
    name: string
  ) {
    function onSuccess() {
      message.success(`Dimension ${name} removed successfully`);
    }

    function onError() {
      message.error(`Dimension ${name} could not be removed`);
    }

    await removeDimension({
      dimensionId,
      dimensionValueId,
      onError,
      onSuccess,
    });
  }

  function handleEditDimension(dimensionId: number, dimensionValueId: number) {
    navigate(`/data/dimensions/edit/${dimensionId}/${dimensionValueId}`);
  }

  const columns = useMemo(() => {
    return tableColumns({
      onRemoveDimension: handleRemoveDimension,
      onEditDimension: handleEditDimension,
    }) as Column<DimensionsTableData>[];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: dimensions,
      defaultCanSort: false,
      defaultCanFilter: false,
    },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  return (
    <Card
      title="Dimensions"
      withHeader
      headerSideChildren={
        <Button mode="single-icon" type="ghost-white">
          <Icon color="#C4C4C4" component={<FilterM />} />
        </Button>
      }
    >
      {isDimensionTypesLoading ? (
        <Loader label="Loading dimension value" labelPosition="bottom" />
      ) : rows.length < 1 ? (
        <EmptyTable
          path={dataManagementData.dimensions.createPath}
          text="No dimensions values"
          buttonText="New dimension value"
        />
      ) : (
        <Styled.Table {...getTableProps()}>
          <Styled.TableHead>
            {headerGroups.map((headerGroup) => (
              <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Styled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                      {column.canFilter ? column.render("Filter") : null}
                      {column.canSort && (
                        <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                      )}
                    </Styled.TableCellWithFiltersWrapper>
                  </Styled.TableHeader>
                ))}
              </Styled.TableHeaderRow>
            ))}
          </Styled.TableHead>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Styled.TableBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Styled.TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Styled.TableCell>
                    );
                  })}
                </Styled.TableBodyRow>
              );
            })}
          </Styled.TableBody>
        </Styled.Table>
      )}
    </Card>
  );
}
