import { useMemo } from "react";
import { useQuery } from "react-query";

import { AccountMappingServices } from "../account-mapping.services";
import { AccountMapResponse } from "../account-mapping.types";
export const MAP_ACCOUNT_QUERY_KEY = "map-account";

type UseMapAccount = {
  mappedAccounts: AccountMapResponse[];
  isMappedAccountsLoading: boolean;
};

export function useMapAccountsByChartMap(mapId: number): UseMapAccount {
  const { data, isLoading } = useQuery([MAP_ACCOUNT_QUERY_KEY, mapId], async () => {
    return await AccountMappingServices.fetchAllMappedAccounts(mapId);
  });

  const mappedAccounts = useMemo(() => {
    return data ?? [];
  }, [data]);

  const isMappedAccountsLoading = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  return {
    mappedAccounts,
    isMappedAccountsLoading,
  };
}
