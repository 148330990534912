import { BoardMeetingsItem } from "../BoardMeetingsItem";
import * as Styled from "./styles";

export function BoardMeetingsSection() {
  return (
    <Styled.Container>
      <BoardMeetingsItem />
      <BoardMeetingsItem />
      <BoardMeetingsItem />
    </Styled.Container>
  );
}
