import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import EmptyStates from "@synerise/ds-empty-states";
import { InboxNoResultsL } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

import { BoardModal } from "../BoardModal";
import { useGetBoards } from "../hooks/useGetBoards";
import { leftDrawerConfig } from "../LeftDrawerConfig";
import { BoardCard } from "./components/BoardCard";
import { BoardCardSkeleton } from "./components/BoardCardSkeleton";
import * as Styled from "./styles";

export function Boards() {
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const navigate = useNavigate();
  const { boards, isBoardsLoading } = useGetBoards();

  const handleCloseBoardModal = () => {
    setIsBoardModalOpen(false);
  };

  const handleOpenBoardModal = () => {
    setIsBoardModalOpen(true);
  };

  return (
    <>
      <Layout
        left={leftDrawerConfig()}
        header={
          <PageHeader
            onGoBack={() => navigate(-1)}
            title="Company Boards"
            rightSide={
              <Button type="primary" onClick={handleOpenBoardModal}>
                Create Board
              </Button>
            }
          />
        }
      >
        {!isBoardsLoading && boards.length < 1 ? (
          <Card>
            <EmptyStates
              text="No boards found"
              label="You can create a new board"
              labelPosition="bottom"
              customIcon={<InboxNoResultsL />}
              button={
                <Button type="primary" onClick={handleOpenBoardModal}>
                  Create Board
                </Button>
              }
            />
          </Card>
        ) : (
          <Styled.CardsContainer>
            {isBoardsLoading ? (
              <>
                <BoardCardSkeleton />
                <BoardCardSkeleton />
                <BoardCardSkeleton />
              </>
            ) : (
              boards.map((board) => (
                <BoardCard
                  key={board.id}
                  id={board.id}
                  title={board.title}
                  periodicity="monthly"
                  membersCount={board.members ? board.members.length : 0}
                />
              ))
            )}
          </Styled.CardsContainer>
        )}
      </Layout>
      <BoardModal isVisible={isBoardModalOpen} onClose={handleCloseBoardModal} />
    </>
  );
}
