import { api } from "@/utils/http";

import {
  TrialBalanceVersionCreatePayload,
  TrialBalanceVersionResponse,
  TrialBalanceVersionUpdatePayload,
} from "./trialbalance-version.types";

type FetchOneParams = {
  show_lines?: boolean;
};

export class TrialBalanceVersionServices {
  static async fetchAll(trialBalanceId: number): Promise<TrialBalanceVersionResponse[]> {
    const response = await api.get<TrialBalanceVersionResponse[]>(
      `/trialbalances/${trialBalanceId}/verrsions`
    );
    return response.data;
  }

  static async fetchOne(
    trialBalanceId: number,
    versionId: number,
    params: FetchOneParams
  ): Promise<TrialBalanceVersionResponse> {
    const response = await api.get<TrialBalanceVersionResponse>(
      `/trialbalances/${trialBalanceId}/versions/${versionId}`,
      { params }
    );
    return response.data;
  }

  static async create(
    trialBalanceId: number,
    payload: TrialBalanceVersionCreatePayload
  ): Promise<TrialBalanceVersionResponse> {
    const response = await api.post<TrialBalanceVersionResponse>(
      `/trialbalances/${trialBalanceId}/versions`,
      payload
    );
    return response.data;
  }

  static async update(
    trialBalanceId: number,
    versionId: number,
    payload: TrialBalanceVersionUpdatePayload
  ): Promise<TrialBalanceVersionResponse> {
    const response = await api.patch<TrialBalanceVersionResponse>(
      `/trialbalances/${trialBalanceId}/versions/${versionId}`,
      payload
    );
    return response.data;
  }

  static async delete(trialBalanceId: number, versionId: number): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(
      `/trialbalances/${trialBalanceId}/versions/${versionId}`
    );
    return response.data;
  }

  static async upload(
    trialBalanceId: number,
    versionId: number,
    file: File
  ): Promise<ApiStatusResponse> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await api.post<ApiStatusResponse>(
      `/trialbalances/${trialBalanceId}/versions/${versionId}/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
}
