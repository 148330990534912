import BoringAvatar from "boring-avatars";
import React from "react";

import Icon from "@synerise/ds-icon";

import * as Styled from "@/components/Table/styles";

type UserTypeCellProps = {
  type: string | number;
  name?: string;
  showAvatar?: boolean;
  avatarKind?: "marble" | "beam" | "ring" | "pixel" | "sunset" | "bauhaus";
  icon?: React.ReactElement;
  fontWeight?: number;
  fontStyle?: string;
} & React.HTMLAttributes<HTMLDivElement>;

// TODO: Change to TextCell
export function UserTypeCell({
  type,
  icon,
  name,
  showAvatar,
  avatarKind,
  fontWeight = 500,
  fontStyle = "normal",
  ...rest
}: UserTypeCellProps) {
  if (showAvatar) {
    return (
      <Styled.TableCellWithIcon {...rest}>
        <BoringAvatar
          size={25}
          name={name ?? type.toString()}
          variant={avatarKind ?? "marble"}
          colors={["#58706D", "#4B5757", "#7C8A6E", "#B0B087", "#E3E3D1"]}
        />
        {name ?? type.toString()}
      </Styled.TableCellWithIcon>
    );
  }
  return (
    <Styled.TableCellWithIcon {...rest}>
      {icon && <Icon component={icon} color="#006BF8" />}
      <div
        style={{
          fontWeight,
          fontStyle,
        }}
      >
        {type}
      </div>
    </Styled.TableCellWithIcon>
  );
}
