import { CommentsDrawer } from "@/components/CommentsDrawer";

import { formatDateToColumnHeader } from "../../utils/formatDateToColumnHeader";
import { useKPIComments } from "./useKPIComments";

type KPICommentsDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  kpiId: number;
  valueId: number;
};

export function KPICommentsDrawer({ isVisible, onClose, kpiId, valueId }: KPICommentsDrawerProps) {
  const { comments, handleCreateComment, handleRemoveComment, isLoadingComments, kpi, kpiValue } =
    useKPIComments({ kpiId, valueId });

  return (
    <CommentsDrawer
      onRemoveComment={handleRemoveComment}
      onCreateComment={handleCreateComment}
      comments={comments}
      isLoadingComments={isLoadingComments}
      isVisible={isVisible}
      noCommentsDescription="No comments in this KPI Value"
      onClose={onClose}
      subtitle={
        kpiValue &&
        kpiValue.period &&
        kpiValue.date &&
        kpi &&
        kpi.title && (
          <>
            <div className="kpi-description">
              {kpi?.title} @ {formatDateToColumnHeader(kpiValue.period, new Date(kpiValue.date))}
            </div>
            <div className="kpi-description">Total: {kpiValue?.value}</div>
          </>
        )
      }
    />
  );
}
