import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import PageHeader from "@synerise/ds-page-header";

import { kpiRoutes } from "@/modules/kpis/kpi.routes";

type KPISourcesHeaderProps = {
  onRequestNewDataSource: () => void;
};

export function KPISourcesHeader({ onRequestNewDataSource }: KPISourcesHeaderProps) {
  const navigate = useNavigate();

  return (
    <PageHeader
      title="Data Sources"
      onGoBack={() => navigate(kpiRoutes.home)}
      rightSide={
        <Button type="primary" onClick={() => onRequestNewDataSource()}>
          Request new Data Source
        </Button>
      }
    />
  );
}
