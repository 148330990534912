import { Descendant } from "slate";

import { EditItemDrawerRichTextContent } from "../../EditItemDrawer/contents/EditItemDrawerRichTextContent";
import { ItemConfig, ItemLayout } from "../../LayoutBuilder.types";

type RichTextData = {
  title: string;
  content: Descendant[];
};

type GetRichTextConfigProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>) => void;
};

export function getRichTextConfig({
  updateItem,
  editingItem,
}: GetRichTextConfigProps): ItemConfig<RichTextData> {
  return {
    name: "richText",
    editDrawerComponent: (
      <EditItemDrawerRichTextContent editingItem={editingItem} updateItem={updateItem} />
    ),
    resizeHandles: ["s", "sw", "se"],
    initialLayout: {
      w: 5,
      h: 5,
      type: "richText",
      minW: 3,
      minH: 5,
      resizeHandles: [],
      data: {
        title: "Rich Text Title",
        content: [
          {
            type: "paragraph",
            children: [
              { text: "This is a editable " },
              { text: "rich", bold: true },
              { text: " text. " },
            ],
          },
        ],
      },
    },
  };
}
