import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { EditM, TrashM, TagM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { CountryCell, DSCountryCodeType } from "@/components/Table/Cells/CountryCell";
import { EntityTypeCell, EntityTypes } from "@/components/Table/Cells/EntityTypeCell";
import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as TableStyled from "@/components/Table/styles";

import { TypeColumnFilter } from "./TypeColumnFilter";

export type EntitiesTableData = {
  id: number;
  name: string;
  type: EntityTypes | null;
  country?: DSCountryCodeType;
  currency: string;
  createdAt: string;
};

type TableColumnsProps = {
  onRemove: (id: number, text: string) => void;
  onEdit: (id: number) => void;
};

export const tableColumns = ({ onEdit, onRemove }: TableColumnsProps) => {
  const columns: Column<EntitiesTableData>[] = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: true,
      Cell: ({ row }) => <UserTypeCell showAvatar type={row.original.name} />,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) => <EntityTypeCell type={row.original.type} />,
      disableSortBy: true,
      Filter: TypeColumnFilter,
      filter: (rows, columnIds, filterValue) => {
        const newRows = rows.filter((row) => {
          const rowValue = row.original.type;
          return rowValue === filterValue || filterValue === "";
        });
        return newRows;
      },
    },
    {
      Header: "Country",
      accessor: "country",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        if (!row.original.country) return null;
        return <CountryCell country={row.original.country} />;
      },
    },
    {
      Header: "Currency",
      accessor: "currency",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <TableStyled.RowCellActionButtons>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => {
              console.log("Adding tags...");
            }}
          >
            <Icon color="#C4C4C4" component={<TagM />} />
          </Button>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => {
              onEdit(row.original.id);
            }}
          >
            <Icon color="#C4C4C4" component={<EditM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ${row.original.name}?`}
            onConfirm={() => onRemove(row.original.id, row.original.name)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button mode="single-icon" type="ghost-white">
              <Icon color="#C4C4C4" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </TableStyled.RowCellActionButtons>
      ),
    },
  ];

  return columns;
};
