import { Column } from "react-table";

import { UserAvatar } from "@synerise/ds-avatar";
import Button from "@synerise/ds-button";
import Icon, { TrashM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { DynamicTags } from "@/components/Table/Cells/DynamicTags";
import { StatusCell } from "@/components/Table/Cells/StatusCell";
import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as TableStyled from "@/components/Table/styles";

export type MembersTableTableData = {
  id: number;
  name: string;
  avatar?: string;
  email: string;
  roles: string[];
  status: boolean;
  created_at: string;
};

type TableColumnsProps = {
  onRemoveMember: (id: number, name: string) => Promise<void>;
  canRemove: boolean;
};

export function tableColumns({
  canRemove,
  onRemoveMember,
}: TableColumnsProps): Column<MembersTableTableData>[] {
  return [
    {
      Header: "User",
      accessor: "name",
      sortDescFirst: true,
      Cell: ({ row }) => (
        <UserAvatar
          user={{
            firstName: row.original.name,
            email: row.original.email,
            avatar: row.original.avatar,
          }}
        />
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      sortDescFirst: true,
      Cell: ({ row }) => <UserTypeCell type={row.original.email} />,
    },
    {
      Header: "Roles",
      accessor: "roles",
      disableSortBy: true,
      Cell: ({ row }) => {
        return <DynamicTags tags={row.original.roles} noContentMessage="No Roles" />;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => <StatusCell status={row.original.status} />,
    },
    {
      Header: "Created At",
      accessor: "created_at",
      disableSortBy: true,
      Cell: ({ row }) => <UserTypeCell type={row.original.created_at} />,
    },
    {
      Header: "",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row }) => {
        if (!canRemove) return null;

        return (
          <TableStyled.RowCellActionButtons>
            <Popconfirm
              title={`Are you sure you want to remove ${row.original.name}?`}
              onConfirm={() => onRemoveMember(row.original.id, row.original.name)}
              cancelText="No"
              okText="Yes"
              okButtonProps={{
                danger: true,
              }}
            >
              <Button mode="single-icon" type="ghost-white">
                <Icon color="#C4C4C4" component={<TrashM />} />
              </Button>
            </Popconfirm>
          </TableStyled.RowCellActionButtons>
        );
      },
    },
  ];
}
