import { Style } from "@synerise/ds-layout";

export const DASHBOARD_LAYOUT_KEY = "@compass-dashboard-layout";

export const gridItemsLayoutStyles: Style<React.CSSProperties> = {
  left: {
    width: "250px",
  },
  right: {
    width: "250px",
  },
  leftInner: {
    padding: "0",
  },
  rightInner: {
    padding: "0",
  },
};

export const DASHBOARDS_QUERY_KEY = "dashboards"