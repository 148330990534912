import Chart from "react-apexcharts";

import { mapChartSeries } from "./mapChartSeries";
import { options } from "./StatisticCardChart.options";

type StatisticCardChartProps = {
  percentage: number;
};

export function StatisticCardChart({ percentage }: StatisticCardChartProps) {
  const series = mapChartSeries(percentage);

  return <Chart options={options} series={series} type="radialBar" width={90} />;
}
