import {
  ChartLine,
  ChartBar,
  ChartPie,
  TextT,
  Browser,
  ArrowsOutLineVertical,
  TextAa,
  Table,
} from "phosphor-react";

import * as Styled from "./grid-items-drawer.styles";
import { GridItemsDrawerDrawerItem } from "./GridItemsDrawerDrawerItem";

export function GridItemsDrawer() {
  const iconColor = "#0C1727";

  return (
    <Styled.ComponentsListContainer>
      <GridItemsDrawerDrawerItem
        Icon={<ChartLine color={iconColor} weight="light" size={32} />}
        text="Line Chart"
        elementType="lineChart"
        description="Line Chart Element"
      />
      <GridItemsDrawerDrawerItem
        Icon={<ChartBar color={iconColor} weight="light" size={32} />}
        text="Column Chart"
        elementType="columnChart"
        description="Column Element"
      />
      <GridItemsDrawerDrawerItem
        Icon={<ChartPie color={iconColor} weight="light" size={32} />}
        text="Donut Chart"
        elementType="donutChart"
        description="Donut Chart Element"
      />
      <GridItemsDrawerDrawerItem
        Icon={<TextAa color={iconColor} weight="light" size={32} />}
        text="Rich Text"
        elementType="richText"
        description="Rich Text Element"
      />
      <GridItemsDrawerDrawerItem
        Icon={<Browser color={iconColor} weight="light" size={32} />}
        text="Statistic Card"
        elementType="statisticCard"
        description="Statistic Card Element"
      />
      <GridItemsDrawerDrawerItem
        Icon={<ArrowsOutLineVertical color={iconColor} weight="light" size={32} />}
        text="Divider"
        elementType="divider"
        description="Divider Element"
      />
      <GridItemsDrawerDrawerItem
        Icon={<TextT color={iconColor} weight="light" size={32} />}
        text="Title"
        elementType="title"
        description="Title Element"
      />
      <GridItemsDrawerDrawerItem
        Icon={<Table color={iconColor} weight="light" size={32} />}
        text="Table"
        elementType="table"
        description="Table Element"
      />
      {/*      <GridItemsDrawerDrawerItem
        Icon={<Image color={iconColor} weight="light" size={32} />}
        text="Image"
        elementType="image"
        description="Image Element"
      /> */}
    </Styled.ComponentsListContainer>
  );
}
