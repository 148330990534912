import { createContext, useContext, useState } from "react";

type AccountsMappingContextData = {
  addMappingModalOpen: boolean;
  setAddMappingModalOpen: (open: boolean) => void;
};

type AccountsMappingProps = {
  children: React.ReactNode;
};

export const AccountsMappingContext = createContext({} as AccountsMappingContextData);

export function AccountsMappingProvider({ children }: AccountsMappingProps) {
  const [addMappingModalOpen, setAddMappingModalOpen] = useState(false);

  return (
    <AccountsMappingContext.Provider value={{ addMappingModalOpen, setAddMappingModalOpen }}>
      {children}
    </AccountsMappingContext.Provider>
  );
}

export function useAccountsMappingContext() {
  const context = useContext(AccountsMappingContext);
  if (!context) {
    throw new Error("useAccountsMappingContext must be used within a AccountsMappingProvider");
  }
  return context;
}
