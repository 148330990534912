import { Column } from "react-table";

import Icon, { ArrowDownCircleM, ArrowRightCircleM, ChildRowLeftDownM } from "@synerise/ds-icon";
import Select from "@synerise/ds-select";

import { StatusBadge } from "@/components/StatusBadge";

import { AccountResponse } from "../Accounts/accounts.types";
import { AccountMappingDetailsTableData } from "./account-mapping-details.types";

type TableColumnsProps = {
  sourceAccounts: AccountResponse[];
  onMapAccount: (
    targetAccountId: number,
    sourceAccountId: number,
    accountMapId: number | null
  ) => void;
  onRemoveMapAccount: (accountMapId: number) => void;
};

export function tableColumns({
  sourceAccounts,
  onMapAccount,
  onRemoveMapAccount,
}: TableColumnsProps): Column<AccountMappingDetailsTableData>[] {
  return [
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Classification",
      accessor: "classification",
    },
    {
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? (
            <Icon component={<ArrowDownCircleM />} color="#ccc" />
          ) : (
            <Icon component={<ArrowRightCircleM />} color="#ccc" />
          )}
          Target Account Name
        </span>
      ),
      id: "target-account-name",
      accessor: "targetAccount",
      Cell: ({ row, value }) => {
        return (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
                fontWeight: row.original.level === 1 ? "bold" : "normal",
              },
            })}
          >
            {!row.canExpand ? (
              row.original.level === 1 ? (
                ""
              ) : (
                <Icon component={<ChildRowLeftDownM />} color="#ccc" />
              )
            ) : row.isExpanded ? (
              <Icon component={<ArrowDownCircleM />} color="#ccc" />
            ) : (
              <Icon component={<ArrowRightCircleM />} color="#ccc" />
            )}
            {value.name}
          </span>
        );
      },
    },
    {
      Header: "Source Account",
      accessor: "sourceAccountId",
      Cell: ({ value, row }) => {
        return (
          <Select
            value={value ? value : undefined}
            style={{ maxWidth: "300px" }}
            onSelect={(v: any) => {
              onMapAccount(row.original.targetAccount.id, v, row.original.mapAccountId);
            }}
            showSearch
            optionFilterProp="children"
            allowClear
            onClear={() => {
              if (!row.original.mapAccountId) return;
              onRemoveMapAccount(row.original.mapAccountId);
            }}
          >
            {sourceAccounts.map((sourceAccount) => (
              <Select.Option key={sourceAccount.id} value={sourceAccount.id}>
                {sourceAccount.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      Header: "Status",
      id: "target-account-status",
      accessor: "targetAccount",
      Cell: ({ value }) => {
        return <StatusBadge active={value.isActive} />;
      },
    },
  ];
}
