import BoringAvatar from "boring-avatars";
import { Link } from "react-router-dom";

import { UserAvatar } from "@synerise/ds-avatar";

import * as Styled from "@/components/Table/styles";

type UserCellProps = {
  name: string;
  avatar?: string;
  link?: string;
  avatarKind?: "marble" | "beam" | "ring" | "pixel" | "sunset" | "bauhaus";
  avatarSize?: "small" | "medium" | "large" | "extraLarge";
  fontStyle?: string;
};

// TODO: change to AvatarCell

export function UserCell({ name, avatar, avatarSize, avatarKind, link }: UserCellProps) {
  return (
    <Styled.TableCellWithIcon>
      {(avatar && <UserAvatar src={avatar} size={avatarSize} />) ?? (
        <BoringAvatar
          size={avatarSize}
          name={name}
          variant={avatarKind ?? "beam"}
          colors={["#58706D", "#4B5757", "#7C8A6E", "#B0B087", "#E3E3D1"]}
        />
      )}
      {link ? <Link to={link}>{name}</Link> : name}
    </Styled.TableCellWithIcon>
  );
}
