import { api } from "@/utils/http";

import {
  DimensionValueCreatePayload,
  DimensionValueResponse,
  DimensionValueUpdatePayload,
} from "./dimensions.types";

export class DimensionsService {
  static async fetchOne(dimensionId: number, dimensionValueId: number) {
    const response = await api.get<DimensionValueResponse>(
      `/dimensions/${dimensionId}/values/${dimensionValueId}`
    );
    return response.data;
  }

  static async create(dimensionId: number, payload: DimensionValueCreatePayload) {
    const response = await api.post<DimensionValueResponse>(
      `/dimensions/${dimensionId}/values`,
      payload
    );
    return response.data;
  }

  static async update(
    dimensionId: number,
    dimensionValueId: number,
    payload: DimensionValueUpdatePayload
  ) {
    const response = await api.patch<DimensionValueResponse>(
      `/dimensions/${dimensionId}/values/${dimensionValueId}`,
      payload
    );
    return response.data;
  }

  static async delete(dimensionsId: number, dimensionValueId: number) {
    const response = await api.delete<ApiStatusResponse>(
      `/dimensions/${dimensionsId}/values/${dimensionValueId}`
    );
    return response.data;
  }
}
