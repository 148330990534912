import { DotsSixVertical  } from 'phosphor-react'

import * as Styled from "./item-drag-handle.styles";

export function ItemDragHandle() {
  return (
    <Styled.Container className="drag-handle">
        <DotsSixVertical size={32} color="white" />
    </Styled.Container>
  );
}
