import { ChatCentered } from "phosphor-react";

import { EmptyState } from "@/components/EmptyState";

type EmptyCommentsProps = {
  description: string;
};

export function EmptyComments({ description }: EmptyCommentsProps) {
  return (
    <EmptyState
      title="No comments"
      text={description}
      showButton={false}
      icon={<ChatCentered color="#000" />}
    />
  );
}
