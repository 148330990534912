import { createContext, useContext, useState } from "react";

import { Periodicity } from "../kpi.types";
import { KPITabsTableData } from "./kpi-tabs.types";

type KPIsTableContextData = {
  isCreateBudgetModalVisible: boolean;
  kpiSelected: KPITabsTableData | null;
  kpiSelectedActualValues: KPITabsTableData | null;
  kpiSelectedBudgetValues: KPITabsTableData | null;
  periodicitySelected: Periodicity;
  setPeriodicitySelected: (periodicity: Periodicity) => void;
  openCreateBudgetModal: (kpi: KPITabsTableData) => void;
  closeCreateBudgetModal: () => void;
  changeKpiSelected: (kpi: KPITabsTableData) => void;
};

const KPIsTableContext = createContext({} as KPIsTableContextData);

export function KpisTabTableProvider({ children }: { children: React.ReactNode }) {
  const [isCreateBudgetModalVisible, setIsCreateBudgetModalVisible] = useState(false);
  const [kpiSelected, setKpiSelected] = useState<KPITabsTableData | null>(null);

  const kpiSelectedActualValues: KPITabsTableData | null = kpiSelected
    ? { ...kpiSelected, values: kpiSelected.values.filter((value) => value.type === "actual") }
    : null;
  const kpiSelectedBudgetValues: KPITabsTableData | null = kpiSelected
    ? { ...kpiSelected, values: kpiSelected.values.filter((value) => value.type === "budget") }
    : null;

  const [periodicitySelected, setPeriodicitySelected] = useState<Periodicity>("monthly");

  function openCreateBudgetModal(kpi: KPITabsTableData) {
    setIsCreateBudgetModalVisible(true);
    setKpiSelected(kpi);
  }

  function closeCreateBudgetModal() {
    setIsCreateBudgetModalVisible(false);
  }

  function changeKpiSelected(kpi: KPITabsTableData) {
    setKpiSelected(kpi);
  }

  return (
    <KPIsTableContext.Provider
      value={{
        isCreateBudgetModalVisible,
        kpiSelected,
        kpiSelectedActualValues,
        kpiSelectedBudgetValues,
        periodicitySelected,
        setPeriodicitySelected,
        openCreateBudgetModal,
        closeCreateBudgetModal,
        changeKpiSelected,
      }}
    >
      {children}
    </KPIsTableContext.Provider>
  );
}

export function useKpisTabTable() {
  const context = useContext(KPIsTableContext);

  if (!context) {
    throw new Error("useKPIsTable must be used within KPIsTableProvider");
  }

  return context;
}
