import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1rem;

  & .header {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    justify-content: space-between;

    & .label {
      font-weight: bold;
      display: flex;
      gap: 0.3rem;
      align-items: center;
    }
  }

  & .text-area {
    resize: none !important;
  }

  & .footer {
    display: flex;
    justify-content: flex-end;
  }
`;
