import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";

import Button from "@synerise/ds-button";
import Drawer from "@synerise/ds-drawer";
import Icon, { CloseM } from "@synerise/ds-icon";
import Tabs from "@synerise/ds-tabs";
import { Title } from "@synerise/ds-typography";

import { useAuth } from "@/hooks/useAuth";
import { queryClient } from "@/utils/queryClient";

import { DASHBOARDS_QUERY_KEY } from "../../dashboard.config";
import { DashboardService } from "../../dashboard.services";
import { DashboardResponseShort, DashboardUpdatePayload } from "../../dashboard.types";
import { useDashboard } from "../../hooks/useDashboard";
import { useGridLayout } from "../../hooks/useGridLayout";
import * as Styled from "./dashboard-views-drawer.styles";
import { mapDashboardResponseToItem } from "./DashboardViewsDrawerItemUtils";
import { DashboardViewsDrawerList } from "./DashboardViewsDrawerList";

type EditDashboardProps = {
  id: number;
  payload: DashboardUpdatePayload;
};

export function DashboardViewsDrawer() {
  const { currentDashboard, loadDashboardView } = useGridLayout();
  const { selectedMembership } = useAuth();
  const { dashboardViewsDrawerOpened, toggleDashboardViewsDrawer, toggleCreateDashboardViewModal } =
    useDashboard();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const { data: allDashboards, isLoading: isDashboardsLoading } = useQuery(
    DASHBOARDS_QUERY_KEY,
    DashboardService.fetchAll
  );

  const dashboardsFilteredByCurrentMembership = useMemo(() => {
    if (!allDashboards) return [];
    return allDashboards.filter((dashboard) => dashboard.owner_id === selectedMembership?.id);
  }, [allDashboards]);

  const removeDashboardMutation = useMutation(
    DASHBOARDS_QUERY_KEY,
    (id: number) => DashboardService.delete(id),
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries(DASHBOARDS_QUERY_KEY);
        const previousDashboards =
          queryClient.getQueryData<DashboardResponseShort[]>(DASHBOARDS_QUERY_KEY) ?? [];
        if (previousDashboards) {
          const dashboardsFiltered = previousDashboards.filter((dashboard) => dashboard.id !== id);
          queryClient.setQueriesData(DASHBOARDS_QUERY_KEY, dashboardsFiltered);
        }

        return {
          previousDashboards,
        };
      },
      onError: (
        err,
        _,
        context:
          | {
              previousDashboards: DashboardResponseShort[];
            }
          | undefined
      ) => {
        if (context?.previousDashboards) {
          queryClient.setQueryData(DASHBOARDS_QUERY_KEY, context.previousDashboards);
        }
      },
    }
  );

  const updateDashboardMutation = useMutation(
    DASHBOARDS_QUERY_KEY,
    async ({ id, payload }: EditDashboardProps) => await DashboardService.update(id, payload),
    {
      onMutate: async ({ id, payload }) => {
        await queryClient.cancelQueries(DASHBOARDS_QUERY_KEY);
        const previousDashboards =
          queryClient.getQueryData<DashboardResponseShort[]>(DASHBOARDS_QUERY_KEY) ?? [];
        if (previousDashboards) {
          const dashboardsFiltered = previousDashboards.map((dashboard) => {
            if (dashboard.id !== id) return dashboard;
            return { ...dashboard, ...payload };
          });
          queryClient.setQueriesData(DASHBOARDS_QUERY_KEY, dashboardsFiltered);
        }

        return {
          previousDashboards,
        };
      },
      onError: (
        err,
        _,
        context:
          | {
              previousDashboards: DashboardResponseShort[];
            }
          | undefined
      ) => {
        if (context?.previousDashboards) {
          queryClient.setQueryData(DASHBOARDS_QUERY_KEY, context.previousDashboards);
        }
      },
    }
  );

  const removeDashboard = async (id: number) => {
    await removeDashboardMutation.mutateAsync(id);
  };

  const addDashboard = () => {
    toggleCreateDashboardViewModal();
  };

  const selectDashboard = (id: number) => {
    loadDashboardView(id);
  };

  const editDashboard = async (id: number, payload: DashboardUpdatePayload) => {
    await updateDashboardMutation.mutateAsync({ id, payload });
  };

  return (
    <Drawer
      onClose={() => toggleDashboardViewsDrawer()}
      placement="right"
      visible={dashboardViewsDrawerOpened}
      width={400}
    >
      <Drawer.DrawerHeader>
        <Styled.DrawerHeaderWrapper>
          <Title level={4}>Dashboard Views</Title>
          <Button type="ghost" mode="single-icon" onClick={() => toggleDashboardViewsDrawer()}>
            <Icon component={<CloseM />} />
          </Button>
        </Styled.DrawerHeaderWrapper>
      </Drawer.DrawerHeader>

      <Drawer.DrawerBody>
        <Drawer.DrawerContent>
          <Tabs
            tabs={[{ label: "All Views" }, { label: "My Views" }]}
            activeTab={selectedTabIndex}
            handleTabClick={(index) => {
              setSelectedTabIndex(index);
            }}
          />
          {selectedTabIndex === 0 && (
            <DashboardViewsDrawerList
              dashboards={mapDashboardResponseToItem(allDashboards || [])}
              isDashboardsLoading={isDashboardsLoading}
              selectedDashboardId={currentDashboard ? currentDashboard.id : null}
              removeDashboard={removeDashboard}
              addDashboard={addDashboard}
              selectDashboard={selectDashboard}
              editDashboard={editDashboard}
            />
          )}
          {selectedTabIndex === 1 && (
            <DashboardViewsDrawerList
              dashboards={mapDashboardResponseToItem(dashboardsFilteredByCurrentMembership)}
              isDashboardsLoading={isDashboardsLoading}
              selectedDashboardId={currentDashboard ? currentDashboard.id : null}
              removeDashboard={removeDashboard}
              addDashboard={addDashboard}
              selectDashboard={selectDashboard}
              editDashboard={editDashboard}
            />
          )}
        </Drawer.DrawerContent>
      </Drawer.DrawerBody>
    </Drawer>
  );
}
