import { FinancialsCFTableData, FinancialsCFValueTableData } from "./financials-cf.types";

const formatter = Intl.NumberFormat("en", { notation: "compact" });

export const mockFinancialsCFValues: FinancialsCFValueTableData[] = [
  {
    id: 1,
    value: formatter.format(351000),
    datetime: "2020-01-01",
  },
  {
    id: 2,
    value: formatter.format(351000),
    datetime: "2020-02-01",
  },
  {
    id: 3,
    value: formatter.format(351000),
    datetime: "2020-03-01",
  },
  {
    id: 4,
    value: formatter.format(351000),
    datetime: "2020-04-01",
  },
  {
    id: 5,
    value: formatter.format(351000),
    datetime: "2020-05-01",
  },
  {
    id: 6,
    value: formatter.format(351000),
    datetime: "2020-06-01",
  },
  {
    id: 7,
    value: formatter.format(351000),
    datetime: "2020-07-01",
  },
  {
    id: 8,
    value: formatter.format(351000),
    datetime: "2020-08-01",
  },
  {
    id: 9,
    value: formatter.format(351000),
    datetime: "2020-09-01",
  },
  {
    id: 10,
    value: formatter.format(351000),
    datetime: "2020-10-01",
  },
  {
    id: 11,
    value: formatter.format(351000),
    datetime: "2020-11-01",
  },
  {
    id: 12,
    value: formatter.format(351000),
    datetime: "2020-12-01",
  },
];

const mockGroups = {
  cfo: {
    id: 1,
    title: "Cash From Operations",
  },
  cfi: {
    id: 2,
    title: "Cash From Investing",
  },
  cff: {
    id: 3,
    title: "Cash From Financing",
  },
  balance: {
    id: 4,
    title: "Cash Balance",
  },
};

export const mockFinancialsIsData: FinancialsCFTableData[] = [
  {
    id: 1,
    title: "Cash Inflow",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cfo,
  },
  {
    id: 2,
    title: "Working Capital",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cfo,
  },
  {
    id: 3,
    title: "Professional Services",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cfo,
  },
  {
    id: 4,
    title: "Customer Support",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cfo,
  },
  {
    id: 5,
    title: "Cloud Services",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cfo,
  },
  {
    id: 6,
    title: "Other direct costs",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cff,
  },
  {
    id: 7,
    title: "% Gross Margin",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cff,
  },
  {
    id: 8,
    title: "General Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsCFValues,
    group: mockGroups.cff,
  },
  {
    id: 9,
    title: "Administrative Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsCFValues,
    group: mockGroups.cff,
  },
  {
    id: 10,
    title: "Sales Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsCFValues,
    group: mockGroups.cfi,
  },
  {
    id: 11,
    title: "Other Operational Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsCFValues,
    group: mockGroups.cfi,
  },
  {
    id: 12,
    title: "Depreciation & Amortization",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cfi,
  },
  {
    id: 13,
    title: "Ebitda Margin",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cfi,
  },
  {
    id: 14,
    title: "Non Operational Earnings",
    periodicity: "month",
    values: mockFinancialsCFValues,
    group: mockGroups.cfi,
  },
  {
    id: 15,
    title: "Non Operational Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsCFValues,
    group: mockGroups.balance,
  },
  {
    id: 16,
    title: "% EBT",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsCFValues,
    group: mockGroups.balance,
  },
  {
    id: 16,
    title: "Taxes 1",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsCFValues,
    group: mockGroups.balance,
  },
  {
    id: 17,
    title: "Taxes 2",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsCFValues,
    group: mockGroups.balance,
  },
];
