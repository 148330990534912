import { useEffect, useState } from "react";

import Button from "@synerise/ds-button";
import { TextArea, Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";

type KPIRequestIntegrationModalProps = {
  initialDataSourceTitle?: string;
  isVisible: boolean;
  onClose: () => void;
};

export function KPIRequestIntegrationModal({
  initialDataSourceTitle = "",
  isVisible,
  onClose,
}: KPIRequestIntegrationModalProps) {
  const [dataSourceTitle, setDataSourceTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setDataSourceTitle(initialDataSourceTitle);
  }, [initialDataSourceTitle]);

  const resetForm = () => {
    setDataSourceTitle("");
    setDescription("");
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = () => {
    message.success("Integration Requested");
    resetForm();
    onClose();
  };

  return (
    <Modal
      onCancel={handleClose}
      title="Use Case"
      visible={isVisible}
      footer={
        <Button type="primary" disabled={!dataSourceTitle || !description} onClick={handleSubmit}>
          Request Connection
        </Button>
      }
    >
      <Input
        label="Data Source"
        color="#666"
        value={dataSourceTitle}
        onChange={(e: any) => {
          setDataSourceTitle(e.target.value);
        }}
      />
      <TextArea
        label="Please describe your use case"
        color="#666"
        value={description}
        onChange={(e: any) => setDescription(e.target.value)}
      />
    </Modal>
  );
}
