import { SidebarProps } from "@synerise/ds-layout";

import { InnerMenuContent } from "./InnerMenuContent";

export const innerMenu: SidebarProps = {
  content: <InnerMenuContent />,
  opened: true,
  onChange: () => {},
  width: 259,
};
