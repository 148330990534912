import { Layout } from "react-grid-layout";

import { EditItemDrawerTableContent } from "../../EditItemDrawer/contents/EditItemDrawerTableContent";
import { ItemConfig, ItemLayout } from "../../LayoutBuilder.types";
import { TableData } from "../GridItemsTable.mock.data";

export type TableComponentData = {
  title?: string;
  tableData: TableData | null;
};

type GetTableConfigProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>, layout?: Partial<Layout>) => void;
};

export function getTableConfig({
  editingItem,
  updateItem,
}: GetTableConfigProps): ItemConfig<TableComponentData> {
  return {
    name: "table",
    editDrawerComponent: (
      <EditItemDrawerTableContent editingItem={editingItem} updateItem={updateItem} />
    ),
    resizeHandles: ["e", "s", "se"],
    initialLayout: {
      w: 5,
      h: 5,
      type: "table",
      minW: 4,
      minH: 4,
      maxW: 12,
      maxH: 12,
      resizeHandles: [],
      data: {
        title: "Table Title",
        tableData: null,
      },
    },
  };
}
