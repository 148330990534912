import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

import { leftDrawerConfig } from "../LeftDrawerConfig";
import { MeetingsTable } from "./MeetingsTable";

export function Meetings() {
  const navigate = useNavigate();

  return (
    <Layout
      left={leftDrawerConfig()}
      header={
        <PageHeader
          onGoBack={() => navigate(-1)}
          title="Board Meeting List"
          rightSide={
            <Button type="primary" onClick={() => console.log("New board meeting")}>
              New Board Meeting
            </Button>
          }
        />
      }
    >
      <MeetingsTable />
    </Layout>
  );
}
