import * as Styled from "./entities.styles";

export function TypeColumnFilter({ column }: any) {
  const { filterValue, setFilter } = column;

  // TODO: turn this into a global select compnent
  return (
    <Styled.Select value={filterValue} onChange={(event) => setFilter(event.target.value)}>
      <option value="">All</option>
      <option value="Company">Company</option>
      <option value="Group">Group</option>
      <option value="Collection">Collection</option>
    </Styled.Select>
  );
}
