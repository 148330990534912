import { MeetingTimelineItem } from "../MeetingTimelineItem";
import * as Styled from "./styles";

export function MeetingTimeline() {
  return (
    <Styled.Container>
      <MeetingTimelineItem />
      <MeetingTimelineItem />
      <MeetingTimelineItem />
    </Styled.Container>
  );
}
