import { Sparklines, SparklinesBars } from "react-sparklines";
import { CellProps, Column } from "react-table";
import { v4 as uuidV4 } from "uuid";

import { StarRate } from "@/components/StarRate";

import { Periodicity } from "../kpi.types";
import * as Styled from "../table/kpi-table.styles";
import { formatDateToColumnHeader } from "../table/utils/formatDateToColumnHeader";
import { ColumnValueType, KPITabsTableData, KPITabsValueTableData } from "./kpi-tabs.types";

const hasCommentsStyles: React.CSSProperties = {
  cursor: "pointer",
  border: "3px solid transparent",
  backgroundImage:
    "linear-gradient(0deg, white 0%, white 100%), linear-gradient(0deg, red, 0%, red 100%)",
  backgroundOrigin: "padding-box, border-box",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%, 6px 6px",
  backgroundPosition: "top left, top right",
};

function getValueColumns(
  values: KPITabsValueTableData[],
  periodicity: Periodicity,
  valueTypes: ColumnValueType[],
  onKpiValueClick: (kpiId: number, valueId: number) => void,
  kpiValueSelected: number | null
) {
  const mainColumns = values.filter((value) => valueTypes[0] === value.type);

  const valueColumns: Column<KPITabsTableData>[] = mainColumns.map((value) => {
    let allValueColumns: any[] = [];

    valueTypes.forEach((valueType) => {
      allValueColumns.push({
        Header: <Styled.CenteredHeaderContent>{valueType}</Styled.CenteredHeaderContent>,
        defaultCanGroupBy: false,
        id: uuidV4(),
        accessor: ({ values }: any) => {
          const valueFound = values.find(
            (v: any) => v.datetime === value.datetime && v.type === valueType
          );

          if (!valueFound) return <Styled.CenteredCellContent>{"-"}</Styled.CenteredCellContent>;

          return (
            <Styled.CenteredCellContent
              style={{
                padding: "3px",
                ...(valueFound.hasComments ? hasCommentsStyles : { cursor: "pointer" }),
                border: kpiValueSelected === valueFound.id ? "3px solid #0029FF" : "",
              }}
              onClick={() => onKpiValueClick(valueFound.kpi_id, valueFound.id)}
            >
              {valueFound.value}
            </Styled.CenteredCellContent>
          );
        },
      });
    });

    allValueColumns.push({
      Header: <Styled.CenteredHeaderContent>vs. Budget</Styled.CenteredHeaderContent>,
      defaultCanGroupBy: false,
      id: uuidV4(),
      isBudgetVariationColumn: true,
      accessor: ({ values }: any) => {
        return (
          <Styled.CenteredCellContent>
            {values.find((v: any) => v.datetime === value.datetime && v.type === "budget-variation")
              ?.value || "-"}
          </Styled.CenteredCellContent>
        );
      },
    });

    return {
      Header: (
        <Styled.CenteredHeaderContent>
          {formatDateToColumnHeader(periodicity, new Date(value.datetime))}
        </Styled.CenteredHeaderContent>
      ),
      // TODO: change this logic to show/hide columns based on the valueTypes instead of recreate the columns - for performance reasons
      columns: [...allValueColumns],
      id: String(value.id),
      show: false,
      defaultCanGroupBy: false,
    };
  });

  return valueColumns;
}

export function kpiTabsColumns(
  values: KPITabsValueTableData[],
  periodicity: Periodicity,
  valueTypes: ColumnValueType[],
  onKpiValueClick: (kpiId: number, valueId: number) => void,
  kpiValueSelected: number | null
): Column<KPITabsTableData>[] {
  return [
    {
      Header: "Kpi",
      accessor: "title",
      id: "title",
    },
    {
      Header: "Importance",
      id: "importance",
      accessor: "importance",
      Cell: ({ value }) => {
        if (!value) return null;
        return <StarRate rate={value} />;
      },
    },
    {
      Header: "Sparkline",
      id: "sparkline",
      accessor: "id",
      disableFilters: true,
      defaultCanSort: false,
      defaultCanGroupBy: false,
      Cell: ({ row }: CellProps<KPITabsTableData>) => {
        if (row.isGrouped) return null;
        const mainValues: KPITabsValueTableData[] = row.original.values.filter(
          (value) => valueTypes[0] === value.type
        );
        const data = mainValues.map((v) => {
          return v.value ?? 0;
        });
        return (
          <div style={{ width: "150px", height: "50px" }}>
            <Sparklines data={data} limit={Math.min(data.length, 50)}>
              <SparklinesBars style={{ fill: "#7CBA86" }} />
            </Sparklines>
          </div>
        );
      },
    },
    ...getValueColumns(values, periodicity, valueTypes, onKpiValueClick, kpiValueSelected),
  ];
}
