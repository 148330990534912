import { useCallback, useMemo } from "react";
import { useMutation } from "react-query";

import { AccountsServices } from "@/modules/data-management/accounts/Accounts/accounts.services";
import {
  AccountResponse,
  AccountUpdatePayload,
} from "@/modules/data-management/accounts/Accounts/accounts.types";
import { queryClient } from "@/utils/queryClient";

const ACCOUNTS_QUERY_KEY = "accounts";

async function handleRemoveAccount(chartId: number, accountId: number) {
  try {
    await AccountsServices.delete(chartId, accountId);
  } catch (error) {
    throw new Error("Error while removing account");
  }
}

type RemoveAccountProps = {
  chartId: number;
  accountId: number;
  onSuccess?: () => void;
  onError: (errorMessage: string) => void;
};

type UseAccountsReturn = {
  removeAccount: (props: RemoveAccountProps) => Promise<void>;
  isRemoveAccountLoading: boolean;
};

export function useAccounts(): UseAccountsReturn {
  const removeAccountMutation = useMutation(
    ACCOUNTS_QUERY_KEY,
    async ({ chartId, accountId }: RemoveAccountProps) => {
      return await handleRemoveAccount(chartId, accountId);
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(ACCOUNTS_QUERY_KEY);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (_, { onError }) => {
        if (onError) {
          onError("Error while removing account");
        }
      },
    }
  );

  const removeAccount = useCallback(async (props: RemoveAccountProps) => {
    return await removeAccountMutation.mutateAsync(props);
  }, []);

  const isRemoveAccountLoading = useMemo(() => {
    return removeAccountMutation.isLoading;
  }, [removeAccountMutation.isLoading]);

  return {
    removeAccount,
    isRemoveAccountLoading,
  };
}

async function handleUpdateAccount(
  chartId: number,
  accountId: number,
  payload: AccountUpdatePayload
) {
  try {
    return await AccountsServices.update(chartId, accountId, payload);
  } catch (error) {
    throw new Error("Error while updating account");
  }
}

type UpdateAccountProps = {
  chartId: number;
  accountId: number;
  payload: AccountUpdatePayload;
  onSuccess?: () => void;
  onError: (errorMessage: string) => void;
};

type UseAccountReturn = {
  updateAccount: (props: UpdateAccountProps) => Promise<AccountResponse>;
  isUpdateAccountLoading: boolean;
};

export function useAccount(): UseAccountReturn {
  const updateAccountMutation = useMutation(
    [ACCOUNTS_QUERY_KEY],
    async ({ chartId, accountId, payload }: UpdateAccountProps) =>
      handleUpdateAccount(chartId, accountId, payload),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(ACCOUNTS_QUERY_KEY);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (error: any, { onError }) => {
        onError(error?.message || "Error while updating account");
      },
    }
  );

  const updateAccount = useCallback(async (props: UpdateAccountProps) => {
    return await updateAccountMutation.mutateAsync(props);
  }, []);

  const isUpdateAccountLoading = useMemo(() => {
    return updateAccountMutation.isLoading;
  }, [updateAccountMutation.isLoading]);

  return {
    updateAccount,
    isUpdateAccountLoading,
  };
}
