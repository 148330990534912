import { createContext, useContext, useEffect, useState } from "react";

type DashboardContextData = {
  inEditMode: boolean;
  gridItemsDrawerOpened: boolean;
  editItemDrawerOpened: boolean;
  dashboardViewsDrawerOpened: boolean;
  createDashboardViewModalOpened: boolean;
  toggleEditMode: (editing?: boolean) => void;
  toggleGridItemsDrawer: (opened?: boolean) => void;
  toggleEditItemDrawer: (opened?: boolean) => void;
  toggleDashboardViewsDrawer: (opened?: boolean) => void;
  toggleCreateDashboardViewModal: () => void;
};

const DashboardContext = createContext({} as DashboardContextData);

type DashboardProviderProps = {
  children: React.ReactNode;
};

export function DashboardProvider({ children }: DashboardProviderProps) {
  const [inEditMode, setEditMode] = useState(false);
  const [gridItemsDrawerOpened, setGridItemsDrawerOpened] = useState(true);
  const [editItemDrawerOpened, setEditItemDrawerOpened] = useState(false);
  const [dashboardViewsDrawerOpened, setDashboardViewsDrawerOpened] = useState(false);
  const [createDashboardViewModalOpened, setCreateDashboardViewModalOpened] = useState(false);

  const toggleEditMode = (editing?: boolean) => {
    if (editing === undefined) {
      setEditMode(!inEditMode);
    } else {
      setEditMode(editing);
    }
  };

  const toggleGridItemsDrawer = (opened?: boolean) => {
    if (opened === undefined) {
      setGridItemsDrawerOpened(!gridItemsDrawerOpened);
      return;
    }
    setGridItemsDrawerOpened(opened);
  };

  const toggleEditItemDrawer = (opened?: boolean) => {
    if (opened === undefined) {
      setEditItemDrawerOpened(!editItemDrawerOpened);
      return;
    }
    setEditItemDrawerOpened(opened);
  };

  const toggleDashboardViewsDrawer = (opened?: boolean) => {
    if (opened === undefined) {
      setDashboardViewsDrawerOpened(!dashboardViewsDrawerOpened);
    } else {
      setDashboardViewsDrawerOpened(opened);
    }
  };

  const toggleCreateDashboardViewModal = () => {
    setCreateDashboardViewModalOpened(!createDashboardViewModalOpened);
  };

  // it fix <ResponsiveReactGridLayout /> don't getting resized when <ComponentsListDrawer /> or <EditItemDrawer /> is toggled
  useEffect(() => {
    for (let t = 0; t <= 300; t += 10) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, t);
    }
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [gridItemsDrawerOpened, editItemDrawerOpened, inEditMode]);

  return (
    <DashboardContext.Provider
      value={{
        inEditMode,
        gridItemsDrawerOpened,
        editItemDrawerOpened,
        dashboardViewsDrawerOpened,
        createDashboardViewModalOpened,
        toggleEditMode,
        toggleGridItemsDrawer,
        toggleEditItemDrawer,
        toggleDashboardViewsDrawer,
        toggleCreateDashboardViewModal,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export const useDashboard = () => {
  const context = useContext(DashboardContext);

  if (context === undefined) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }

  return context;
};
