import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";

import { ChartType } from "./types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

const labels = [1949, 1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961];

export const data = {
  labels,

  datasets: [
    {
      label: "AE Meetings Conversion Growth Rate",
      data: [1.17, 1.64, 1.74, 1.93, 1.9, 1.98, 2],
      borderColor: "rgba(101, 88, 91, 0.5)",
      backgroundColor: "rgba(101, 88, 91, 0.5)",
      tension: 0.2,
      fill: false,
    },
    {
      label: "Forecast Optmistic",
      fill: 3,
      data: [null, null, null, null, null, null, 2, 2.2, 2.4, 2.6, 2.5, 2.9, 3.1],
      borderColor: "rgba(76, 146, 104, 0.5)",
      backgroundColor: "rgba(76, 146, 104, 0.5)",
      borderDash: [2, 2],
      tension: 0.2,
      segment: {
        borderDash: (ctx: any) => {
          console.log(ctx);
          return ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.p1DataIndex
            ? [2, 2]
            : undefined;
        },
      },
    },
    {
      label: "Forecast",
      data: [null, null, null, null, null, null, 2, 2.1, 2.3, 2.5, 2.4, 2.7, 2.9],
      borderColor: "rgba(53, 162, 235, 0.5)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      borderDash: [2, 2],
      fill: false,
      tension: 0.2,
    },
    {
      label: "Forecast Pesimistic",
      data: [null, null, null, null, null, null, 2, 2.05, 2.2, 2.4, 2.3, 2.5, 2.7],
      borderColor: "rgba(178, 50, 50, 0.5)",
      borderDash: [2, 2],
      backgroundColor: "rgba(178, 50, 50, 0.5)",
      fill: false,
      tension: 0.2,
    },
  ],
};

type ForecastChartProps = {
  type: ChartType;
};

export function ForecastChart({ type }: ForecastChartProps) {
  const style = {
    maxHeight: "300px",
  };

  const charts = {
    line: <Line options={options} data={data} style={style} />,
    bar: <Bar options={options} data={data} style={style} />,
  };

  return charts[type];
}
