import styled, { css } from "styled-components";

export const Container = styled.div<{
  showControls: boolean;
  isSelected: boolean;
  height?: number;
}>`
  ${({ height }) =>
    height &&
    css`
      height: ${height}px !important;
    `}
  z-index: ${({ isSelected }) => (isSelected ? "2" : "1")};
  &:hover .item-controls {
    visibility: ${({ showControls }) => (showControls ? "visible" : "hidden")};
  }

  &:hover .react-resizable-handle {
    visibility: ${({ showControls }) => (showControls ? "visible" : "hidden")};
  }


  &:hover {
    z-index: 3;
  }
`;
