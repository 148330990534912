import { Users } from "phosphor-react";
import { Link } from "react-router-dom";

import * as Styled from "./styles";

export function BoardMeetingsItem() {
  return (
    <Styled.Container>
      <header>
        <Styled.Title>3Q2022</Styled.Title>
        <Styled.Date>15/09/2022</Styled.Date>
      </header>
      <div className="content">
        <Styled.Attendees>
          <Users size={20} color="#c7c7c7" weight="fill" />
          <span>4/5</span>
        </Styled.Attendees>
        <Styled.Actions>
          <Link to="/governance/meetings/1">View Meeting</Link>
        </Styled.Actions>
      </div>
    </Styled.Container>
  );
}
