import Chart from "react-apexcharts";

import { ChartData } from "../../LayoutBuilder.types";
import * as Styled from "../GridItems.styles";
import { donutChartOptions } from "./DonutChart.options";

type DonutChartProps = ChartData & {
  isSelected: boolean;
};

export function DonutChart({ title, series, isSelected }: DonutChartProps) {
  const firstSeries = series && series.length > 0 ? series[0] : null;

  return (
    <Styled.GridItemContainer isSelected={isSelected}>
      <h3>{title}</h3>
      <Chart
        type="donut"
        series={firstSeries ? firstSeries.data : []}
        options={donutChartOptions(firstSeries ? firstSeries.labels : [])}
        height="100%"
      />
    </Styled.GridItemContainer>
  );
}
