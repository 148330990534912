import styled from "styled-components";

export const PostHeader = styled.header`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const PostHeaderInfo = styled.div`
  width: 100%;
  max-width: 150px;
`;
