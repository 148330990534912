import DSFlag from "@synerise/ds-flag";
import Select from "@synerise/ds-select";
import { Props } from "@synerise/ds-select/dist/Select.types";

import { Country } from "@/core/support-infos/support-infos.types";

import * as Styled from "../entities.styles";

type SelectCountryProps = {
  countries: Country[];
  description?: string;
  showErrorText: boolean;
  errorText?: string;
} & Props;

export function SelectCountry({
  countries,
  description,
  showErrorText,
  errorText,
  ...rest
}: SelectCountryProps) {
  return (
    <Select
      {...rest}
      label="Country"
      showSearch
      description={description}
      placeholder={"Country"}
      errorText={showErrorText ? errorText : undefined}
      options={countries.map((country) => ({
        value: country.code,
        name: country.lang_en,
        label: (
          <Styled.FlagSelectOption>
            <DSFlag country={country.alpha2} />
            <p>{country.lang_en}</p>
          </Styled.FlagSelectOption>
        ),
      }))}
      filterOption={(inputValue, option) => {
        return option!.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
      }}
    />
  );
}
