import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

import { dataManagementData } from "../../data-management.data";
import { leftLayoutContent } from "../../DataManagementLeftLayoutContent";
import { ChartOfAccountsTable } from "./ChartOfAccountsTable";


export function ChartOfAccounts() {
  const navigate = useNavigate();
  return (
    <Layout
      left={leftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate(dataManagementData.home.path)}
          title="Chart of Accounts"
          rightSide={
            <Button
              type="primary"
              onClick={() => navigate(dataManagementData.chartOfAccounts.createPath)}
            >
              New chart of accounts
            </Button>
          }
        />
      }
    >
      <ChartOfAccountsTable />
    </Layout>
  );
}
