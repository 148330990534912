import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const PeriodicityIcon = styled.div<{
  active: boolean;
}>`
  width: 25px;
  height: 25px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? "#006BF8" : "#C4C4C4")};
  color: ${({ active }) => (active ? "#FFF" : "#A3A3A3")};
`;
