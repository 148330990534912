import { Hooks } from "react-table";

import { IndeterminateCheckbox } from "./Checkbox";

export function addCheckboxToTable<D extends object>(hooks: Hooks<D>) {
  hooks.visibleColumns.push((columns) => [
    {
      id: "checkbox",
      width: 0,
      Header: ({ getToggleAllRowsSelectedProps }: any) => (
        <IndeterminateCheckbox
          {...getToggleAllRowsSelectedProps()}
          style={{
            margin: "0",
            padding: "0",
          }}
        />
      ),
      Cell: ({ row }: any) => (
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps()}
          style={{
            margin: "0",
            padding: "0",
          }}
        />
      ),
    },
    ...columns,
  ]);
}
