import { format } from "date-fns";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { EditM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useChartOfAccounts } from "@/hooks/useChartOfAccounts";
import { useDimensionTypes } from "@/hooks/useDimensionTypes";
import { useEntities } from "@/hooks/useEntities";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { Periodicity, TrialBalanceCreatePayload } from "../trialbalance.types";
import { useLedgers } from "../useLedgers";
import * as Styled from "./ledger.styles";

type LedgerImportFormValues = {
  company_id: number | null;
  month: number;
  year: number;
  periodicity: Periodicity;
  is_closed: boolean;
  chart_id: number | null;
  dimension_values: number[];
};

const formInitialValues: LedgerImportFormValues = {
  company_id: null,
  month: 1,
  year: 2020,
  periodicity: "monthly",
  is_closed: false,
  chart_id: null,
  dimension_values: [],
};

const validationSchema = Yup.object().shape({
  month: Yup.number().required("Month is required"),
  year: Yup.number().required("Year is required"),
  periodicity: Yup.string().required("Periodicity is required"),
  company_id: Yup.number().required("Company is required"),
  is_closed: Yup.boolean(),
  chart_id: Yup.number().required("Chart of accounts is required"),
  dimension_values: Yup.array().of(Yup.number()),
});

export function LedgerImportForm() {
  const validate = useValidationSchema(validationSchema);
  const navigate = useNavigate();
  const { allChartOfAccounts } = useChartOfAccounts();
  const { allDimensionTypes } = useDimensionTypes();
  const { allCompanies } = useEntities();
  const { createLedger, isCreateLedgerLoading } = useLedgers({
    fetchParams: {},
  });
  const [importLedgerLoading, setImportLedgerLoading] = useState(false);

  const importLedger = async (values: LedgerImportFormValues) => {
    setImportLedgerLoading(true);

    const onError = (text: string) => {
      message.error(text);
    };

    const payload: TrialBalanceCreatePayload = {
      date: format(new Date(values.year, values.month - 1), "yyyy-MM-dd"),
      entity_id: values.company_id!,
      periodicity: values.periodicity,
      is_closed: values.is_closed,
      chart_id: values.chart_id!,
      dimension_values: values.dimension_values,
    };

    try {
      await createLedger({ payload, onError });
      message.success("Ledger created successfully");
      setImportLedgerLoading(false);
      navigate("/data/ledgers");
    } catch (error: any) {
      onError("Ledger creation failed");
    }
  };

  return (
    <Layout
      header={<PageHeader onGoBack={() => navigate("/data/ledgers")} title="Import new ledger" />}
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Form<LedgerImportFormValues>
            onSubmit={importLedger}
            initialValues={formInitialValues}
            validate={validate}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Card
                  background="white-shadow"
                  lively
                  title="Basic Information"
                  withHeader
                  icon={<EditM />}
                  iconColor={"#AEAEAE"}
                  headerSideChildren={
                    <Styled.SideButtonsContainer>
                      <Button
                        onClick={() => navigate("/data/ledgers")}
                        disabled={isCreateLedgerLoading || importLedgerLoading}
                      >
                        Cancel
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={isCreateLedgerLoading || importLedgerLoading}
                        disabled={isCreateLedgerLoading || importLedgerLoading}
                      >
                        Import
                      </Button>
                    </Styled.SideButtonsContainer>
                  }
                >
                  <ModuleStyled.InputWrapper>
                    <Styled.SelectFieldRow>
                      <Field name="company_id">
                        {({ input, meta }) => (
                          <Select {...input} label="Company" errorText={meta.touched && meta.error}>
                            {allCompanies.map((company) => (
                              <Select.Option key={company.id} value={company.id}>
                                {company.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Styled.SelectFieldRow>

                    <Styled.SelectFieldRow>
                      <Field name="periodicity">
                        {({ input, meta }) => (
                          <Select
                            {...input}
                            label="Periodicity"
                            errorText={meta.touched && meta.error}
                            options={[
                              { label: "Daily", value: "daily" },
                              { label: "Monthly", value: "monthly" },
                              { label: "Weekly", value: "weekly" },
                              { label: "Quarterly", value: "quarterly" },
                              { label: "Yearly", value: "yearly" },
                            ]}
                          />
                        )}
                      </Field>
                    </Styled.SelectFieldRow>
                    <Styled.SelectFieldRow>
                      <Field name="chart_id">
                        {({ input, meta }) => (
                          <Select
                            {...input}
                            label="Chart of Accounts"
                            errorText={meta.touched && meta.error}
                          >
                            {allChartOfAccounts.map((chartOfAccount) => (
                              <Select.Option key={chartOfAccount.id} value={chartOfAccount.id}>
                                {chartOfAccount.title}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Styled.SelectFieldRow>

                    <Styled.SelectFieldRow>
                      <Field name="dimension_values">
                        {({ input, meta }) => (
                          <Select
                            {...input}
                            label="Dimension"
                            mode="multiple"
                            errorText={meta.touched && meta.error}
                          >
                            {allDimensionTypes
                              .filter((dimensionType) => dimensionType.values.length > 0)
                              .map((dimensionType) => {
                                return (
                                  <Select.OptGroup
                                    key={dimensionType.id}
                                    label={dimensionType.title}
                                  >
                                    {dimensionType.values.map((dimensionValue) => (
                                      <Select.Option
                                        key={dimensionValue.id}
                                        value={dimensionValue.id}
                                      >
                                        {dimensionValue.title}
                                      </Select.Option>
                                    ))}
                                  </Select.OptGroup>
                                );
                              })}
                          </Select>
                        )}
                      </Field>
                    </Styled.SelectFieldRow>
                    <Styled.SelectFieldRow>
                      <Styled.DateFieldsRow>
                        <div>
                          <Field name="month">
                            {({ input, meta }) => (
                              <Select
                                {...input}
                                label="Month"
                                errorText={meta.touched && meta.error}
                              >
                                <Select.Option value={1}>January</Select.Option>
                                <Select.Option value={2}>February</Select.Option>
                                <Select.Option value={3}>March</Select.Option>
                                <Select.Option value={4}>April</Select.Option>
                                <Select.Option value={5}>May</Select.Option>
                                <Select.Option value={6}>June</Select.Option>
                                <Select.Option value={7}>July</Select.Option>
                                <Select.Option value={8}>August</Select.Option>
                                <Select.Option value={9}>September</Select.Option>
                                <Select.Option value={10}>October</Select.Option>
                                <Select.Option value={11}>November</Select.Option>
                                <Select.Option value={12}>December</Select.Option>
                              </Select>
                            )}
                          </Field>
                        </div>

                        <Field name="year">
                          {({ input, meta }) => (
                            <Input
                              {...input}
                              maxLength={4}
                              min={1900}
                              defaultValue={2020}
                              label="Year"
                              type="number"
                              errorText={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Styled.DateFieldsRow>
                    </Styled.SelectFieldRow>

                    <ModuleStyled.SwitchWrapper>
                      <Field name="is_closed">
                        {({ input, meta }) => (
                          <Switch
                            {...input}
                            label="Closed"
                            checked={input.value}
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </ModuleStyled.SwitchWrapper>
                  </ModuleStyled.InputWrapper>
                </Card>
              </form>
            )}
          </Form>
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
