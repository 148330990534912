import styled from "styled-components";

export const Container = styled.div<{ showControls: boolean }>`
  position: absolute;
  top: -32px;
  right: 0;

  display: flex;
  gap: 5px;
  border-radius: 5px 5px 0 0;

  background-color: ${({ theme }) => theme.palette["blue-600"]};
  font-size: 1rem;

  visibility: ${({ showControls }) => (showControls ? "visible" : "hidden")};
`;

export const Control = styled.span`
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.palette["blue-700"]};
  }
`;
