import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import AccessGroupsService from "@/modules/settings/access-groups/access-groups.services";
import { AccessGroup, AccessGroupPost } from "@/modules/settings/access-groups/access-groups.types";
import { queryClient } from "@/utils/queryClient";

const ACCESS_GROUP_QUERY = "accessGroup";

type AccessGroupsReturn = {
  allAccessGroups: AccessGroup[];
  allAccessGroupsLoading: boolean;
  createAccessGroup: (props: CreateAccessGroupProps) => Promise<void>;
  createAccessGroupLoading: boolean;
  removeAccessGroup: (props: RemoveAccessGroupProps) => Promise<void>;
  removeAccessGroupLoading: boolean;
  getAccessGroupById: (id: number) => AccessGroup;
};

type CreateAccessGroupProps = {
  payload: AccessGroupPost;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

type RemoveAccessGroupProps = {
  id: number;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

async function getAllAccessGroups() {
  return await AccessGroupsService.getAllAccessGroups();
}

async function handleCreateAccessGroup(accessGroup: AccessGroupPost) {
  try {
    return await AccessGroupsService.postAccessGroup(accessGroup);
  } catch (err: any) {
    throw new Error(err.message);
  }
}

async function handleRemoveAccessGroup(id: number) {
  try {
    return await AccessGroupsService.deleteAccessGroup(id);
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export function useAccessGroups(): AccessGroupsReturn {
  const allAccessGroupsFromQuery = useQuery(ACCESS_GROUP_QUERY, () => getAllAccessGroups());

  const allAccessGroups = useMemo(() => {
    return allAccessGroupsFromQuery.data ?? [];
  }, [allAccessGroupsFromQuery.data]);

  const allAccessGroupsLoading = useMemo(() => {
    return allAccessGroupsFromQuery.isLoading;
  }, [allAccessGroupsFromQuery.isLoading]);

  const createAccessGroupMutation = useMutation(
    ACCESS_GROUP_QUERY,
    async ({ payload }: CreateAccessGroupProps) => await handleCreateAccessGroup(payload),
    {
      onSuccess: (_, { onSuccess }) => {
        allAccessGroupsFromQuery.refetch();
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        onError(err.message);
      },
    }
  );

  function getAccessGroupById(id: number): AccessGroup {
    const accessGroup = allAccessGroups.find((accessGroup) => accessGroup.id === id);

    if (!accessGroup) {
      throw new Error(`Access group with id ${id} not found`);
    }

    return accessGroup;
  }

  async function createAccessGroup(props: CreateAccessGroupProps) {
    await createAccessGroupMutation.mutateAsync(props);
  }

  const createAccessGroupLoading = useMemo(() => {
    return createAccessGroupMutation.isLoading;
  }, [createAccessGroupMutation.isLoading]);

  const removeAccessGroupMutation = useMutation(
    ACCESS_GROUP_QUERY,
    async ({ id }: RemoveAccessGroupProps) => {
      await handleRemoveAccessGroup(id);
    },
    {
      onMutate: async ({ id, onSuccess }) => {
        await queryClient.cancelQueries(ACCESS_GROUP_QUERY);
        const previousAccessGroups = queryClient.getQueryData<AccessGroup[]>(ACCESS_GROUP_QUERY);

        if (previousAccessGroups) {
          const newAccessGroups = previousAccessGroups.filter(
            (accessGroup) => accessGroup.id !== id
          );
          queryClient.setQueryData(ACCESS_GROUP_QUERY, newAccessGroups);
        }

        onSuccess();

        return {
          previousAccessGroups,
        };
      },
      onError: (err: any, { onError }, context: any) => {
        queryClient.setQueryData(ACCESS_GROUP_QUERY, context.previousAccessGroups);
        onError(err.message);
      },
    }
  );

  async function removeAccessGroup(props: RemoveAccessGroupProps) {
    await removeAccessGroupMutation.mutateAsync(props);
  }

  const removeAccessGroupLoading = useMemo(() => {
    return removeAccessGroupMutation.isLoading;
  }, [removeAccessGroupMutation.isLoading]);

  return {
    allAccessGroups,
    allAccessGroupsLoading,
    createAccessGroup,
    createAccessGroupLoading,
    removeAccessGroup,
    removeAccessGroupLoading,
    getAccessGroupById,
  };
}
