import Card from "@synerise/ds-card";

import * as Styled from "./styles";

type MeetingSectionProps = {
  title: string;
  children: React.ReactNode;
};

export function MeetingSection({ title, children }: MeetingSectionProps) {
  return (
    <Styled.Container>
      <Styled.Title>{title}</Styled.Title>
      <Card>{children}</Card>
    </Styled.Container>
  );
}
