import { FinancialsISTableData, FinancialsISValueTableData } from "./financials-ratios.types";

const formatter = Intl.NumberFormat("en", { notation: "compact" });

export const mockFinancialsISValues: FinancialsISValueTableData[] = [
  {
    id: 1,
    value: formatter.format(351000),
    datetime: "2020-01-01",
  },
  {
    id: 2,
    value: formatter.format(351000),
    datetime: "2020-02-01",
  },
  {
    id: 3,
    value: formatter.format(351000),
    datetime: "2020-03-01",
  },
  {
    id: 4,
    value: formatter.format(351000),
    datetime: "2020-04-01",
  },
  {
    id: 5,
    value: formatter.format(351000),
    datetime: "2020-05-01",
  },
  {
    id: 6,
    value: formatter.format(351000),
    datetime: "2020-06-01",
  },
  {
    id: 7,
    value: formatter.format(351000),
    datetime: "2020-07-01",
  },
  {
    id: 8,
    value: formatter.format(351000),
    datetime: "2020-08-01",
  },
  {
    id: 9,
    value: formatter.format(351000),
    datetime: "2020-09-01",
  },
  {
    id: 10,
    value: formatter.format(351000),
    datetime: "2020-10-01",
  },
  {
    id: 11,
    value: formatter.format(351000),
    datetime: "2020-11-01",
  },
  {
    id: 12,
    value: formatter.format(351000),
    datetime: "2020-12-01",
  },
];

const mockGroups = {
  sales: {
    id: 1,
    title: "Sales",
  },
  cogs: {
    id: 2,
    title: "(-) COGS",
  },
  grossProfit: {
    id: 3,
    title: "(=) Gross Profit",
  },
  opex: {
    id: 4,
    title: "(-) SG&A",
  },
  ebitda: {
    id: 5,
    title: "(=) EBITDA",
  },
  nonop: {
    id: 6,
    title: "(+/-) Non Operational Result",
  },
  pretax: {
    id: 7,
    title: "(=) Pre-Tax Earnings",
  },
  tax: {
    id: 8,
    title: "(-) Tax",
  },
  profit: {
    id: 9,
    title: "(=) Net Earnings",
  },
};

export const mockFinancialsIsData: FinancialsISTableData[] = [
  {
    id: 1,
    title: "MRR",
    periodicity: "month",
    values: mockFinancialsISValues,
    group: mockGroups.sales,
  },
  {
    id: 2,
    title: "Setup",
    periodicity: "month",
    values: mockFinancialsISValues,
    group: mockGroups.sales,
  },
  {
    id: 3,
    title: "Professional Services",
    periodicity: "month",
    values: mockFinancialsISValues,
    group: mockGroups.sales,
  },
  {
    id: 4,
    title: "Customer Support",
    periodicity: "month",
    values: mockFinancialsISValues,
    group: mockGroups.cogs,
  },
  {
    id: 5,
    title: "Cloud Services",
    periodicity: "month",
    values: mockFinancialsISValues,
    group: mockGroups.cogs,
  },
  {
    id: 6,
    title: "Other direct costs",
    periodicity: "month",
    values: mockFinancialsISValues,
    group: mockGroups.cogs,
  },
  {
    id: 7,
    title: "% Gross Margin",
    periodicity: "month",
    calculatedRow: true,
    values: mockFinancialsISValues,
    group: mockGroups.grossProfit,
  },
  {
    id: 8,
    title: "General Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.opex,
  },
  {
    id: 9,
    title: "Administrative Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.opex,
  },
  {
    id: 10,
    title: "Sales Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.opex,
  },
  {
    id: 11,
    title: "Other Operational Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.opex,
  },
  {
    id: 12,
    title: "Depreciation & Amortization",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.opex,
  },
  {
    id: 13,
    title: "Ebitda Margin",
    periodicity: "month",
    calculatedRow: true,
    values: mockFinancialsISValues,
    group: mockGroups.ebitda,
  },
  {
    id: 14,
    title: "Non Operational Earnings",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.nonop,
  },
  {
    id: 15,
    title: "Non Operational Expenses",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.nonop,
  },
  {
    id: 16,
    title: "% EBT",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.pretax,
  },
  {
    id: 16,
    title: "Taxes 1",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.tax,
  },
  {
    id: 17,
    title: "Taxes 2",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.tax,
  },
  {
    id: 18,
    title: "% Net Profit Margin",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsISValues,
    group: mockGroups.profit,
  },
];
