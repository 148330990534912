import { useMutation } from "react-query";

import { User } from "@/core/users/users.types";
import AccountService from "@/modules/settings/account/account.services";
import {
  PatchUserAccountPayload,
  UserChangePasswordPayload,
} from "@/modules/settings/account/account.types";
import { MEMBERS_QUERY_KEY } from "@/modules/settings/users/useMembers";
import { queryClient } from "@/utils/queryClient";

import { useAuth } from "./useAuth";

const ACCOUNT_USER_QUERY_KEY = "account-user";

type UseAccountUserReturn = {
  updateAccountUser: (props: UpdateUserProps) => Promise<void>;
  changePassword: (props: ChangePasswordProps) => Promise<void>;
  updateAccountUserLoading: boolean;
  changePasswordLoading: boolean;
};

type UpdateUserProps = {
  id: number;
  payload: PatchUserAccountPayload;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type ChangePasswordProps = {
  id: number;
  payload: UserChangePasswordPayload;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

async function handleUpdateAccountUser(
  id: number,
  payload: PatchUserAccountPayload
): Promise<User> {
  return await AccountService.patchUser(id, payload);
}

async function handleChangePassword(id: number, payload: UserChangePasswordPayload) {
  return await AccountService.changePassword(id, payload);
}

export function useAccountUser(): UseAccountUserReturn {
  const { getUser } = useAuth();

  const updateUserMutation = useMutation(
    async ({
      id,
      payload,
    }: {
      id: number;
      payload: PatchUserAccountPayload;
      onSuccess: () => void;
      onError: (text: string) => void;
    }) => {
      await handleUpdateAccountUser(id, payload);
    },
    {
      onSuccess: (_, { id, onSuccess }) => {
        queryClient.invalidateQueries([ACCOUNT_USER_QUERY_KEY, id]);
        queryClient.invalidateQueries(MEMBERS_QUERY_KEY);
        onSuccess();
        getUser(id);
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const changePasswordMutation = useMutation(
    async ({ id, payload }: ChangePasswordProps) => {
      return await handleChangePassword(id, payload);
    },
    {
      onSuccess: (_, { onSuccess }) => {
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  async function changePassword(props: ChangePasswordProps) {
    await changePasswordMutation.mutateAsync(props);
  }

  async function updateAccountUser(props: UpdateUserProps) {
    updateUserMutation.mutateAsync(props);
  }

  return {
    updateAccountUser,
    updateAccountUserLoading: updateUserMutation.isLoading,
    changePassword,
    changePasswordLoading: changePasswordMutation.isLoading,
  };
}
