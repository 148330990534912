import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import ButtonGroup from "@synerise/ds-button-group";
import Dropdown from "@synerise/ds-dropdown";
import Icon, { AngleDownS, CompanyM, GroupM, FolderArchiveM } from "@synerise/ds-icon";
import Menu from "@synerise/ds-menu";

import { dataManagementData } from "../data-management.data";
import * as Styled from "./entities.styles";

export function EntitiesPageHeaderActions() {
  const navigate = useNavigate();

  const dropdownItems = () => {
    return (
      <Styled.DropdownWrapper onKeyDown={function noRefCheck() {}} style={{ width: "220px" }}>
        <Menu style={{ width: "100%" }} asDropdownMenu>
          <Menu.Item
            onClick={() => navigate(dataManagementData.companies.createPath)}
            prefixel={<Icon component={<CompanyM />} />}
          >
            Company
          </Menu.Item>
          <Menu.Item
            onClick={() => navigate(dataManagementData.groups.createPath)}
            prefixel={<Icon component={<GroupM />} />}
          >
            Group
          </Menu.Item>
          <Menu.Item
            onClick={() => navigate(dataManagementData.collections.createPath)}
            prefixel={<Icon component={<FolderArchiveM />} />}
          >
            Collection
          </Menu.Item>
        </Menu>
      </Styled.DropdownWrapper>
    );
  };

  return (
    <Styled.PageHeaderActionsContainer>
      <ButtonGroup buttonsPosition="center" splitMode>
        <Button type="primary" onClick={() => navigate(dataManagementData.entities.createPath)}>
          Create new entity
        </Button>
        <Button mode="single-icon" type="primary">
          <Dropdown
            overlay={dropdownItems}
            overlayStyle={{
              borderRadius: "3px",
            }}
            placement="bottomLeft"
          >
            <Icon component={<AngleDownS />} />
          </Dropdown>
        </Button>
      </ButtonGroup>
    </Styled.PageHeaderActionsContainer>
  );
}
