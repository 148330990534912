import { useRef, useState } from "react";
import { ChromePicker } from "react-color";

import { useOnClickOutside } from "@/hooks/useOnClickOutside";

type ColorPickerProps = {
  label: string;
  colorPicked: string;
  description?: string;
  disabled?: boolean;
  handleColorPicked: (hexColor: string) => void;
};

export function ColorPicker({
  label,
  colorPicked,
  description,
  disabled = false,
  handleColorPicked,
}: ColorPickerProps) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef(null);
  useOnClickOutside(colorPickerRef, () => setShowColorPicker(false));

  return (
    <div style={{ position: "relative" }}>
      <p style={{ marginBottom: "5px" }}>{label}</p>
      <div
        onClick={() => {
          if (disabled) return;
          if (!showColorPicker) {
            setShowColorPicker(true);
          }
        }}
        style={{
          border: "3px solid #EEEEEE",
          backgroundColor: colorPicked,
          height: "30px",
          width: "80px",
          cursor: disabled ? "default" : "pointer",
        }}
      />

      {description && (
        <p style={{ color: "#949EA6", fontSize: "0.8rem", marginTop: "10px" }}>{description}</p>
      )}

      {showColorPicker && (
        <div ref={colorPickerRef} style={{ position: "absolute", zIndex: 10, left: 100, top: 0 }}>
          <ChromePicker
            color={colorPicked}
            onChange={(color) => {
              handleColorPicked(color.hex);
            }}
            disableAlpha={true}
          />
        </div>
      )}
    </div>
  );
}
