import { api } from "@/utils/http";

import {
  TrialBalanceCommentCreatePayload,
  TrialBalanceCommentResponse,
  TrialBalanceCommentUpdatePayload,
} from "./trialbalance-version-line-comments.types";

export class TrialBalanceVersionLineCommentsServices {
  private static getEndpoint(
    trialBalanceId: number,
    versionId: number,
    lineId: number,
    commentId?: number
  ): string {
    return `/trialbalances/${trialBalanceId}/versions/${versionId}/lines/${lineId}/comments${
      commentId ? `/${commentId}` : ""
    }`;
  }

  static async fetchAll(
    trialBalanceId: number,
    versionId: number,
    lineId: number
  ): Promise<TrialBalanceCommentResponse[]> {
    const endPoint = this.getEndpoint(trialBalanceId, versionId, lineId);
    const response = await api.get<TrialBalanceCommentResponse[]>(endPoint);
    return response.data;
  }

  static async fetchOne(
    trialBalanceId: number,
    versionId: number,
    lineId: number,
    commentId: number
  ): Promise<TrialBalanceCommentResponse> {
    const endPoint = this.getEndpoint(trialBalanceId, versionId, lineId, commentId);
    const response = await api.get<TrialBalanceCommentResponse>(endPoint);
    return response.data;
  }

  static async create(
    trialBalanceId: number,
    versionId: number,
    lineId: number,
    payload: TrialBalanceCommentCreatePayload
  ): Promise<TrialBalanceCommentResponse> {
    const endPoint = this.getEndpoint(trialBalanceId, versionId, lineId);
    const response = await api.post<TrialBalanceCommentResponse>(endPoint, payload);
    return response.data;
  }

  static async update(
    trialBalanceId: number,
    versionId: number,
    lineId: number,
    commentId: number,
    payload: TrialBalanceCommentUpdatePayload
  ): Promise<TrialBalanceCommentResponse> {
    const endPoint = this.getEndpoint(trialBalanceId, versionId, lineId, commentId);
    const response = await api.patch<TrialBalanceCommentResponse>(endPoint, payload);
    return response.data;
  }

  static async delete(
    trialBalanceId: number,
    versionId: number,
    lineId: number,
    commentId: number
  ): Promise<ApiStatusResponse> {
    const endPoint = this.getEndpoint(trialBalanceId, versionId, lineId, commentId);
    const response = await api.delete<ApiStatusResponse>(endPoint);
    return response.data;
  }
}
