import { TrashM, CloseM, EditM } from "@synerise/ds-icon";

import * as Styled from "./item-controls.styles";
import { ItemControlsControl } from "./ItemControlsControl";

type ItemControlsProps = {
  removeItem: () => void;
  unselectItem: () => void;
  editItem: () => void;
  showControls: boolean;
};

export function ItemControls({ removeItem, unselectItem, editItem, showControls }: ItemControlsProps) {
  return (
    <Styled.Container className="item-controls" showControls={showControls}>
      <ItemControlsControl onClick={editItem} icon={<EditM />} />
      <ItemControlsControl onClick={removeItem} icon={<TrashM />} />
      <ItemControlsControl onClick={unselectItem} icon={<CloseM />} />
    </Styled.Container>
  );
}
