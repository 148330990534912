import { useMutation, useQuery } from "react-query";

import { queryClient } from "@/utils/queryClient";

import loginService from "./login.services";
import { RegisterUserPayload } from "./login.types";

type ForgotPasswordProps = {
  email: string;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type ResetPasswordPayload = {
  token: string;
  password: string;
};

type ResetPasswordProps = {
  payload: ResetPasswordPayload;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type RegisterUserProps = {
  payload: RegisterUserPayload;
  token: string;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type ConfirmResetProps = {
  token: string;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type ActivateEmailProps = {
  email: string;
  token: string;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type UseLoginReturn = {
  forgotPassword: (props: ForgotPasswordProps) => Promise<void>;
  forgotPasswordLoading: boolean;
  resetPassword: (props: ResetPasswordProps) => Promise<void>;
  resetPasswordLoading: boolean;
  registerUser: (props: RegisterUserProps) => Promise<void>;
  registerUserLoading: boolean;
  activateEmail: (props: ActivateEmailProps) => Promise<void>;
  activateEmailLoading: boolean;
};

const LOGIN_QUERY_KEY = "login";

async function handleForgotPassword(email: string) {
  try {
    const response = await loginService.forgotPassword(email);

    if (!response.status) {
      throw new Error(response.detail);
    }

    return response;
  } catch (err: any) {
    if (err.response?.status === 404) {
      throw new Error("Email not found");
    }

    throw new Error("Something went wrong");
  }
}

async function handleResetPassword(token: string, password: string) {
  try {
    return await loginService.resetPassword(token, password);
  } catch (err: any) {
    if (!err.response || !err.response.status) {
      throw new Error("Something went wrong");
    }

    if (err.response.status === 404) {
      throw new Error("Email not found");
    }

    if (err.response.status === 422) {
      throw new Error("Invalid token");
    }

    if (err.response.status === 500) {
      throw new Error("Token expired");
    }

    throw new Error("Something went wrong");
  }
}

async function handleRegisterUser(payload: RegisterUserPayload, token: string) {
  try {
    return await loginService.registerUser(payload, token);
  } catch (err: any) {
    if (!err.response || !err.response.status) {
      throw new Error("Something went wrong");
    }

    if (err.response.status === 404) {
      throw new Error("Email not found");
    }

    throw new Error("Something went wrong");
  }
}

async function handleConfirmReset(token: string) {
  try {
    const data = await loginService.confirmReset(token);

    if (!data.status) {
      throw new Error(data.msg);
    }

    return data;
  } catch (err: any) {
    if (!err.response || !err.response.status) {
      throw new Error("Something went wrong");
    }

    if (err.response.status === 401) {
      throw new Error("Token invalid");
    }

    if (err.response.status === 404) {
      throw new Error("User not found");
    }

    throw new Error("Something went wrong");
  }
}

async function handleActivateEmail(token: string, email: string) {
  try {
    const response = await loginService.activateEmail(token, email);

    if (!response.status) {
      throw new Error(response.msg);
    }

    return response;
  } catch (err: any) {
    if (!err.response || !err.response.status) {
      throw new Error("Something went wrong");
    }

    if (err.response.status === 403) {
      throw new Error("User not found");
    }

    throw new Error("Something went wrong");
  }
}

export function useLogin(): UseLoginReturn {
  const forgotPasswordMutation = useMutation(
    async ({ email }: ForgotPasswordProps) => await handleForgotPassword(email),
    {
      onSuccess: (_, { email, onSuccess }) => {
        queryClient.invalidateQueries([LOGIN_QUERY_KEY, email]);
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        onError(err.message);
      },
    }
  );

  const resetPasswordMutation = useMutation(
    async ({ payload }: ResetPasswordProps) => handleResetPassword(payload.token, payload.password),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries([LOGIN_QUERY_KEY]);
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        onError(err.message);
      },
    }
  );

  const registerUserMutation = useMutation(
    async ({ payload, token }: RegisterUserProps) => handleRegisterUser(payload, token),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries([LOGIN_QUERY_KEY]);
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        onError(err.message);
      },
    }
  );

  const activateEmailMutation = useMutation(
    async (props: ActivateEmailProps) => handleActivateEmail(props.email, props.token),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries([LOGIN_QUERY_KEY]);
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        onError(err.message);
      },
    }
  );

  async function forgotPassword(props: ForgotPasswordProps) {
    await forgotPasswordMutation.mutateAsync(props);
  }

  async function resetPassword(props: ResetPasswordProps) {
    await resetPasswordMutation.mutateAsync(props);
  }

  async function registerUser(props: RegisterUserProps) {
    await registerUserMutation.mutateAsync(props);
  }

  async function activateEmail(props: ActivateEmailProps) {
    await activateEmailMutation.mutateAsync(props);
  }

  return {
    forgotPassword,
    forgotPasswordLoading: forgotPasswordMutation.isLoading,
    resetPassword,
    resetPasswordLoading: resetPasswordMutation.isLoading,
    registerUser,
    registerUserLoading: registerUserMutation.isLoading,
    activateEmail,
    activateEmailLoading: activateEmailMutation.isLoading,
  };
}

type ConfirmResetReturn = {
  confirmResetLoading: boolean;
};

type UseConfirmReset = (props: ConfirmResetProps) => ConfirmResetReturn;

export const useConfirmReset: UseConfirmReset = ({ token, onSuccess, onError }) => {
  const confirmResetQuery = useQuery(
    [LOGIN_QUERY_KEY, token],
    async () => {
      return await handleConfirmReset(token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([LOGIN_QUERY_KEY]);
        onSuccess();
      },
      onError: (err: any) => {
        onError(err.message);
      },
      enabled: token !== "",
    }
  );

  return {
    confirmResetLoading: confirmResetQuery.isLoading,
  };
};
