import { Link, useLocation } from "react-router-dom";

import AppMenu from "@synerise/ds-app-menu";
import { AssetsColorM, AssetsGreyM } from "@synerise/ds-icon";

import { dataManagementData } from "./data-management.data";

export function DataManagementMenu() {
  const { pathname } = useLocation();

  return (
    <AppMenu.Item
      name="Data Management"
      id="data"
      className="app-menu-item"
      subMenu={
        <AppMenu.SubMenu>
          <Link to="/data/home">
            <AppMenu.SubMenu.Title>Data Management</AppMenu.SubMenu.Title>
          </Link>

          <Link to={dataManagementData.entities.path}>
            <AppMenu.SubMenu.SubTitle>Entities</AppMenu.SubMenu.SubTitle>
          </Link>
          <Link to={dataManagementData.companies.path}>
            <AppMenu.SubMenu.Item active={pathname === dataManagementData.companies.path}>
              Companies
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to={dataManagementData.groups.path}>
            <AppMenu.SubMenu.Item active={pathname === dataManagementData.groups.path}>
              Groups
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to={dataManagementData.collections.path}>
            <AppMenu.SubMenu.Item active={pathname === dataManagementData.collections.path}>
              Collections
            </AppMenu.SubMenu.Item>
          </Link>

          <AppMenu.SubMenu.SubTitle>Dimensions</AppMenu.SubMenu.SubTitle>
          <Link to={dataManagementData.dimensionTypes.path}>
            <AppMenu.SubMenu.Item
              active={pathname.startsWith(dataManagementData.dimensionTypes.path)}
            >
              Dimensions
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to={dataManagementData.dimensions.path}>
            <AppMenu.SubMenu.Item active={pathname === dataManagementData.dimensions.path}>
              Dimensions Values
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to={dataManagementData.dimensions.createPath}>
            <AppMenu.SubMenu.Item
              active={pathname.startsWith(dataManagementData.dimensions.createPath)}
            >
              New dimension
            </AppMenu.SubMenu.Item>
          </Link>

          <AppMenu.SubMenu.SubTitle>Accounts</AppMenu.SubMenu.SubTitle>
          <Link to={dataManagementData.chartOfAccounts.path}>
            <AppMenu.SubMenu.Item
              active={pathname.startsWith(dataManagementData.chartOfAccounts.path)}
            >
              Chart of Accounts
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to={dataManagementData.accountsMapping.path}>
            <AppMenu.SubMenu.Item
              active={pathname.startsWith(dataManagementData.accountsMapping.path)}
            >
              Accounts Mapping
            </AppMenu.SubMenu.Item>
          </Link>

          <Link to={dataManagementData.ledgers.path}>
            <AppMenu.SubMenu.SubTitle>Ledgers</AppMenu.SubMenu.SubTitle>
          </Link>

          <Link to={dataManagementData.ledgers.path}>
            <AppMenu.SubMenu.Item>Trial Balance</AppMenu.SubMenu.Item>
          </Link>

          <Link to={dataManagementData.reviews.path}>
            <AppMenu.SubMenu.Item>Reviews</AppMenu.SubMenu.Item>
          </Link>

          <AppMenu.SubMenu.SubTitle>Integrations</AppMenu.SubMenu.SubTitle>
          <Link to={dataManagementData.erp.path}>
            <AppMenu.SubMenu.Item>ERP</AppMenu.SubMenu.Item>
          </Link>
          <Link to={dataManagementData.databases.path}>
            <AppMenu.SubMenu.Item>Databases</AppMenu.SubMenu.Item>
          </Link>
        </AppMenu.SubMenu>
      }
    >
      <AppMenu.Item.Icon active={<AssetsColorM />} inActive={<AssetsGreyM />} />
    </AppMenu.Item>
  );
}
