import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { FileWithContent } from "@synerise/ds-file-uploader/dist/FileUploader.types";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Stepper from "@synerise/ds-stepper";

import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { KPIService } from "../../kpi.services";
import { KPIImportMapFileStep, KPIImportMapFileStepValues } from "./KPIImportMapFileStep";
import { KPIImportValueFileStep } from "./KPIImportValuesFIleStep";

export function KPIFormImport() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [valuesFile, setValuesFile] = useState<FileWithContent | null>(null);
  const [stepNumber, setStepNumber] = useState(1);
  const [isImportLoading, setIsImportLoading] = useState(false);

  const numberId = Number(id);

  const handleSetValuesFile = (file: FileWithContent | null) => {
    setValuesFile(file);
    if (file) {
      setStepNumber(2);
    }
  };

  async function handleImport(kpiMappedFileColumnsPayload: KPIImportMapFileStepValues) {
    if (!numberId) {
      message.error("KPI id is not defined");
      return;
    }
    if (!valuesFile) {
      message.error("Values file is not defined");
      setStepNumber(1);
      return;
    }
    if (!kpiMappedFileColumnsPayload) {
      message.error("KPI mapped file columns payload is not defined");
      setStepNumber(2);
      return;
    }
    setIsImportLoading(true);
    try {
      await KPIService.uploadFile(valuesFile);
      message.success("KPI imported");
      navigate("kpis/library");
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setIsImportLoading(false);
    }
  }

  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate("/kpis/library")}
          title="Import KPI File"
          rightSide={
            <Button type="tertiary" onClick={() => navigate("/kpis/library")}>
              Cancel
            </Button>
          }
        />
      }
    >
      <ModuleStyled.MaxWidthContent>
        <Card withHeader>
          <ModuleStyled.CardsWrapper>
            <Stepper orientation="horizontal" style={{ margin: "0 auto" }}>
              <Stepper.Step
                stepNumber={1}
                label="Import file"
                active={stepNumber === 1}
                done={stepNumber > 1}
                onClick={() => setStepNumber(1)}
              />
              <Stepper.Step
                stepNumber={2}
                label="Map columns"
                active={stepNumber === 2}
                done={stepNumber > 2}
                onClick={() => {
                  if (valuesFile) {
                    setStepNumber(2);
                  }
                }}
              />
            </Stepper>

            {stepNumber === 1 && (
              <KPIImportValueFileStep valuesFile={valuesFile} setValuesFile={handleSetValuesFile} />
            )}
            {stepNumber === 2 && (
              <KPIImportMapFileStep
                onFinish={(payload) => handleImport(payload)}
                isImportLoading={isImportLoading}
              />
            )}
          </ModuleStyled.CardsWrapper>
        </Card>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
