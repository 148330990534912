import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useBlockLayout,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useTable,
} from "react-table";
import { VariableSizeList } from "react-window";

import Card from "@synerise/ds-card";

import * as TableStyled from "@/components/Table/styles";

import { DataSource, Periodicity } from "../financials.types";
import { formatDateToColumnHeader } from "../utils/formatDateToColumnHeader";
import { AdjustmentModalCreate } from "./components/AdjustmentModalCreate";
import { AdjustmentsDrawer } from "./components/AdjustmentsDrawer";
import { TableFilters } from "./components/TableFilters/TableFilters";
import { TableHeaderLeftContent } from "./components/TableHeaderLeftContent/TableHeaderLeftContent";
import { getFinancialsIncomeStatementsColumns } from "./financials-is.columns";
import {
  FinancialsISMockLine,
  financialsISMockLines,
  FinancialsISMockValue,
} from "./mock/mockLines";
import { scrollbarWidth } from "./utils/scrollbarWidth";

export function FinancialsIncomeStatements() {
  const [periodicity, setPeriodicity] = useState<Periodicity>("month");
  const [dataSources, setDataSources] = useState<DataSource[]>(["actual"]);
  const [isAdjustmentSettingsDrawerOpen, setIsAdjustmentSettingsDrawerOpen] = useState(false);
  const [isAdjustmentModalCreateOpen, setIsAdjustmentModalCreateOpen] = useState(false);
  const [valueAdjusting, setValueAdjusting] = useState<FinancialsISMockValue | null>(null);
  const [lineAdjusting, setLineAdjusting] = useState<FinancialsISMockLine | null>(null);
  const [showBudgetVariationColumn, setShowBudgetVariationColumn] = useState(false);

  const handleOpenAdjustmentSettingsDrawer = () => {
    setIsAdjustmentSettingsDrawerOpen(true);
  };

  const handleCloseAdjustmentSettingsDrawer = () => {
    setIsAdjustmentSettingsDrawerOpen(false);
  };

  const handleOpenAdjustmentModalCreate = (
    line: FinancialsISMockLine,
    value: FinancialsISMockValue
  ) => {
    setValueAdjusting(value);
    setLineAdjusting(line);
    setIsAdjustmentModalCreateOpen(true);
  };

  const handleCloseAdjustmentModalCreate = () => {
    setValueAdjusting(null);
    setLineAdjusting(null);
    setIsAdjustmentModalCreateOpen(false);
  };

  const data = useMemo(() => {
    return financialsISMockLines;
  }, []);

  const columns = useMemo(() => {
    const periodColumnsSchema = financialsISMockLines[0].values;
    const columns = getFinancialsIncomeStatementsColumns(
      periodColumnsSchema,
      handleOpenAdjustmentModalCreate
    );
    return columns;
  }, []);

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    allColumns,
    toggleHideColumn,
  } = useTable<FinancialsISMockLine>(
    {
      columns,
      data,
      autoResetExpanded: false,
      autoResetHiddenColumns: false,
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
    useBlockLayout
  );

  const hideBudgetVariationColumn = useCallback((hide: boolean) => {
    allColumns.forEach((column: any) => {
      if (column.isBudgetVariationColumn) toggleHideColumn(column.id, hide);
    });
  }, []);

  useEffect(() => {
    hideBudgetVariationColumn(true);
  }, []);

  const RenderRow = useCallback(
    ({ index, style }: any) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <TableStyled.TableBodyRow
          {...row.getRowProps({
            style: {
              ...style,
              alignItems: row.original.is_calculated
                ? "flex-end"
                : row.original.is_metric
                ? "flex-start"
                : "center",
              borderTop: row.original.is_calculated ? "1px solid #ECECEC" : undefined,
              borderBottom: row.original.is_metric ? "1px solid #ECECEC" : undefined,
              height: row.original.is_calculated || row.original.is_metric ? "30px" : undefined,
              marginTop: row.original.is_calculated ? "25px" : undefined,
              marginBottom: row.original.is_metric ? "25px" : undefined,
              fontStyle: row.original.is_metric ? "italic" : undefined,
              fontWeight: row.original.level === 0 ? "bold" : "normal",
            },
          })}
        >
          {row.cells.map((cell: any) => {
            return (
              <TableStyled.TableCell
                {...cell.getCellProps()}
                darker={cell.column.isBudgetVariationColumn && !cell.isPlaceholder}
              >
                {cell.render("Cell")}
              </TableStyled.TableCell>
            );
          })}
        </TableStyled.TableBodyRow>
      );
    },
    [prepareRow, rows]
  );

  return (
    <>
      <Card
        withoutPadding
        title={
          <TableHeaderLeftContent
            showBudgetVariation={showBudgetVariationColumn}
            onShowBudgetVariationChange={(show) => {
              setShowBudgetVariationColumn(show);
              hideBudgetVariationColumn(!show);
            }}
          />
        }
        withHeader
        headerSideChildren={
          <TableFilters
            dataSources={dataSources}
            periodicity={periodicity}
            onChangeDataSources={(data) => setDataSources(data)}
            onChangePeriodicity={(periodicitySelected) => setPeriodicity(periodicitySelected)}
            openAdjustmentsSettingsDrawer={handleOpenAdjustmentSettingsDrawer}
          />
        }
      />
      <Card style={{ overflow: "auto" }}>
        <TableStyled.Table {...getTableProps()}>
          <TableStyled.TableHead
            style={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              border: 0,
              flexDirection: "column",
              borderBottom: "1px solid #ececec",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <TableStyled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableStyled.TableHeader {...column.getHeaderProps()}>
                    <TableStyled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                    </TableStyled.TableCellWithFiltersWrapper>
                  </TableStyled.TableHeader>
                ))}
              </TableStyled.TableHeaderRow>
            ))}
          </TableStyled.TableHead>
          <TableStyled.TableBody {...getTableBodyProps()}>
            <VariableSizeList
              style={{ marginTop: "1rem" }}
              height={600}
              itemCount={rows.length}
              itemSize={(index) =>
                rows[index].original.is_calculated || rows[index].original.is_metric ? 55 : 25
              }
              overscanCount={40}
              width={totalColumnsWidth + scrollBarSize}
            >
              {RenderRow}
            </VariableSizeList>
          </TableStyled.TableBody>
        </TableStyled.Table>
      </Card>
      <AdjustmentsDrawer
        isOpen={isAdjustmentSettingsDrawerOpen}
        onClose={handleCloseAdjustmentSettingsDrawer}
      />
      {valueAdjusting && lineAdjusting && (
        <AdjustmentModalCreate
          isOpen={isAdjustmentModalCreateOpen}
          onClose={handleCloseAdjustmentModalCreate}
          accountName={lineAdjusting.line_name}
          period={formatDateToColumnHeader("month", new Date(valueAdjusting.date))}
          value={valueAdjusting.value}
        />
      )}
    </>
  );
}
