import { Slider } from "antd";
import { useState } from "react";

import Dropdown from "@synerise/ds-dropdown";

import { theme } from "@/styles/theme";

import { ID } from "../../types";
import * as Styled from "./styles";

type ForecastMetricProps = {
  id: ID;
  label: string;
  onMetricChange: (metricId: ID, oldPercentValue: number, newPercentValue: number) => void;
};

export function ForecastMetric({ id, label, onMetricChange }: ForecastMetricProps) {
  const [percentValue, setPercentValue] = useState(Math.floor(Math.random() * 100));

  const handleChangeValue = (newPercentValue: number) => {
    onMetricChange(id, percentValue, newPercentValue);
    setPercentValue(newPercentValue);
  };

  return (
    <Styled.Container>
      <span className="label">{label}</span>
      <Dropdown
        overlay={
          <Dropdown.Wrapper style={{ padding: theme.spacing.s }}>
            <Slider
              defaultValue={percentValue}
              onChange={(v) => handleChangeValue(v)}
              tooltipVisible={false}
              trackStyle={{ backgroundColor: theme.colors.blue_500 }}
              handleStyle={{
                backgroundColor: theme.colors.white,
                borderColor: theme.colors.blue_500,
              }}
            />
          </Dropdown.Wrapper>
        }
      >
        <span className="value">{percentValue}%</span>
      </Dropdown>
    </Styled.Container>
  );
}
