import { StatusBadge } from "@/components/StatusBadge";

type StatusCellProps = {
  status: boolean;
  activeText?: string;
  inactiveText?: string;
};

export function StatusCell({ status, activeText, inactiveText }: StatusCellProps) {
  return <StatusBadge active={status} activeText={activeText} inactiveText={inactiveText} />;
}
