import { useMemo } from "react";
import styled from "styled-components";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { ArrowUpM, ArrowDownM, FormulaMinusM } from "@synerise/ds-icon";
import Modal from "@synerise/ds-modal";
import Typography from "@synerise/ds-typography";

import { StarRate } from "@/components/StarRate";

import { KPIOverviewChart } from "../table/KPIOverviewChart";
import { KPITabsTableData, KPITabsValueTableData } from "./kpi-tabs.types";
import { useKpisTabTable } from "./useKpiTabsTable";

const { Title } = Typography;

type KPIOverviewModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

const StatisticCardsWrapper = styled.div`
  display: flex;
  gap: 5px;

  .statistic-card-content {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
`;

function GrowingIcon() {
  return <Icon component={<ArrowUpM />} color="#26d25a" />;
}

function DecreasingIcon() {
  return <Icon component={<ArrowDownM />} color="#f5222d" />;
}

function StationaryIcon() {
  return <Icon component={<FormulaMinusM />} color="#888" />;
}

function ValueIcon(value: number, previousValue: number) {
  if (value > previousValue) {
    return <GrowingIcon />;
  } else if (value < previousValue) {
    return <DecreasingIcon />;
  } else {
    return <StationaryIcon />;
  }
}

function getCurrentValue(kpi: KPITabsTableData): KPITabsValueTableData | null {
  return kpi.values.length > 0 ? kpi.values[kpi.values.length - 1] : null;
}
function getLastValue(kpi: KPITabsTableData) {
  return kpi.values.length > 1 ? kpi.values[kpi.values.length - 2] : null;
}
function getNextToLastValue(kpi: KPITabsTableData) {
  return kpi.values.length > 2 ? kpi.values[kpi.values.length - 3] : null;
}

export function KPIOverviewModal({ visible, onCancel }: KPIOverviewModalProps) {
  const { kpiSelected, kpiSelectedActualValues, kpiSelectedBudgetValues } = useKpisTabTable();

  const valuesToChart = useMemo(() => {
    if (!kpiSelectedActualValues) return [];
    return kpiSelectedActualValues.values.slice(-10);
  }, [kpiSelectedActualValues]);

  if (!kpiSelectedActualValues || !kpiSelected) return null;

  const currentValue = getCurrentValue(kpiSelectedActualValues);
  const lastValue = getLastValue(kpiSelectedActualValues);
  const nextToLastValue = getNextToLastValue(kpiSelectedActualValues);

  const matchBudgetWithCurrentValue = kpiSelectedBudgetValues?.values.find(
    (value) => value.datetime === currentValue?.datetime
  );

  return (
    <Modal
      title={`${kpiSelected.title} Overview`}
      visible={visible}
      onCancel={() => onCancel()}
      size="large"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" onClick={onCancel} type="primary">
          Ok
        </Button>,
      ]}
    >
      <ContentWrapper>
        <KPIOverviewChart values={valuesToChart} periodicity={kpiSelected.periodicity} />
        <InfosWrapper>
          <StatisticCardsWrapper>
            <Card title="Current" compactHeader withHeader>
              <div className="statistic-card-content">
                {currentValue && currentValue.value && lastValue && lastValue.value ? (
                  <>{ValueIcon(currentValue.value, lastValue.value)}</>
                ) : (
                  <StationaryIcon />
                )}
                {currentValue?.value ?? "N/A"}
              </div>
            </Card>
            <Card title="Last" compactHeader withHeader>
              <div className="statistic-card-content">
                {lastValue && lastValue.value && nextToLastValue && nextToLastValue.value ? (
                  <>{ValueIcon(lastValue.value, nextToLastValue.value)}</>
                ) : (
                  <StationaryIcon />
                )}
                {lastValue?.value ?? "N/A"}
              </div>
            </Card>
            <Card title="Budget" compactHeader withHeader>
              <div className="statistic-card-content">
                {matchBudgetWithCurrentValue ? matchBudgetWithCurrentValue.value : "No Budget"}
              </div>
            </Card>
          </StatisticCardsWrapper>

          <DescriptionWrapper>
            <div className="title">
              <Title level={4}>KPI Description</Title>
              <StarRate rate={kpiSelected.importance} />
            </div>
            <p>{kpiSelected.description}</p>
          </DescriptionWrapper>
        </InfosWrapper>
      </ContentWrapper>
    </Modal>
  );
}
