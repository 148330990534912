import { Navigate, Route, Routes } from "react-router-dom";

import { Dashboard } from "@/modules/dashboard/Dashboard";
import { Accounts } from "@/modules/data-management/accounts/Accounts/Accounts";
import { AccountsMapping } from "@/modules/data-management/accounts/AccountsMapping";
import { AccountMappingDetails } from "@/modules/data-management/accounts/AccountsMapping/AccountMappingDetails";
import { ChartOfAccounts } from "@/modules/data-management/accounts/ChartOfAccounts/ChartOfAccounts";
import { CreateChartOfAccounts } from "@/modules/data-management/accounts/ChartOfAccounts/ChartOfAccountsCreate";
import { EditChartOfAccounts } from "@/modules/data-management/accounts/ChartOfAccounts/ChartOfAccountsEdit";
import { DataManagement } from "@/modules/data-management/DataManagement";
import { DataManagementHome } from "@/modules/data-management/DataManagementHome";
import { DimensionTypes } from "@/modules/data-management/dimension-types/DimensionTypes";
import { DimensionTypesDetail } from "@/modules/data-management/dimension-types/DimensionTypesDetail";
import { DimensionTypesFormCreate } from "@/modules/data-management/dimension-types/DimensionTypesFormCreate";
import { DimensionTypesFormEdit } from "@/modules/data-management/dimension-types/DimensionTypesFormEdit";
import { Dimensions } from "@/modules/data-management/dimensions/Dimensions";
import { DimensionsFormCreate } from "@/modules/data-management/dimensions/DimensionsFormCreate";
import { DimensionsFormEdit } from "@/modules/data-management/dimensions/DimensionsFormEdit";
import { Entities } from "@/modules/data-management/entities/Entities";
import { EntityCreate } from "@/modules/data-management/entities/EntitiesFormCreate";
import { EntityEdit } from "@/modules/data-management/entities/EntitiesFormEdit";
import { LedgerEditForm } from "@/modules/data-management/ledgers/Ledger/LedgerEditForm";
import { LedgerImportForm } from "@/modules/data-management/ledgers/Ledger/LedgerImportForm";
import { LedgerDetails } from "@/modules/data-management/ledgers/LedgerDetails/LedgerDetails";
import { Ledgers } from "@/modules/data-management/ledgers/Ledgers";
import { ReviewsHome } from "@/modules/data-management/reviews/ReviewsHome";
import { ReviewsList } from "@/modules/data-management/reviews/ReviewsList";
import { Databases } from "@/modules/databases/databases";
import { ERP } from "@/modules/erp/ERP";
import { Financials } from "@/modules/financials/Financials";
import { Forecast } from "@/modules/forecast";
import { ForecastOutlet } from "@/modules/forecast/ForecastOutlet";
import { ForecastView } from "@/modules/forecast/pages/ForecastView";
import { Board } from "@/modules/governance/Board";
import { governanceRoutes } from "@/modules/governance/governance.routes";
import { GovernanceHome } from "@/modules/governance/GovernanceHome";
import { Meeting } from "@/modules/governance/Meeting";
import { KPIDashboard } from "@/modules/kpis/dashboard/KPIDashboard";
import { KPIGroups } from "@/modules/kpis/groups/KPIGroups";
import { KPIHome } from "@/modules/kpis/KPIHome";
import { KPIs } from "@/modules/kpis/KPIs";
import { KPICreate } from "@/modules/kpis/library/KPIFormCreate";
import { KPIFormEdit } from "@/modules/kpis/library/KpiFormEdit/KPIFormEdit";
import { KPIFormImport } from "@/modules/kpis/library/KpiFormImport/KPIFormImport";
import { KPILibrary } from "@/modules/kpis/library/KPILibrary";
import { KPIRequests } from "@/modules/kpis/requests/KPIRequests";
import { KPISources } from "@/modules/kpis/sources/KPISources";
import { KPITable } from "@/modules/kpis/table";
import { KpiTabs } from "@/modules/kpis/tabs";
import { ReportBuilder } from "@/modules/reports/ReportBuilder/ReportBuilder";
import { Reports } from "@/modules/reports/Reports";
import { ReportsList } from "@/modules/reports/ReportsList/ReportsList";
import { AccessGroupCreate } from "@/modules/settings/access-groups/AccessGroupCreate";
import { AccessGroupList } from "@/modules/settings/access-groups/AccessGroupList";
import { AccessGroupDetails } from "@/modules/settings/access-groups/AcessGroupDetails";
import { AccountNotifications } from "@/modules/settings/account/AccountNotifications";
import { AccountProfile } from "@/modules/settings/account/AccountProfile";
import { AccountSecurityPolicy } from "@/modules/settings/account/AccountSecurity";
import { OrganizationAppearance } from "@/modules/settings/organization/OrganizationAppearance";
import { OrganizationGlobalSettings } from "@/modules/settings/organization/OrganizationGlobalSettings";
import { OrganizationPreferences } from "@/modules/settings/organization/OrganizationPreferences";
import { Settings } from "@/modules/settings/Settings";
import { SettingsHome } from "@/modules/settings/settings.home";
import { AccessProviders } from "@/modules/settings/users/UsersIdentityProviders";
import { Users } from "@/modules/settings/users/UsersList";
import { InvalidToken } from "@/pages/Auth/InvalidToken";

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/invalid-token" element={<InvalidToken />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="/financials" element={<Financials />} />

      <Route path="/kpis" element={<KPIs />}>
        <Route index element={<KPIHome />} />
        <Route path="dashboard" element={<KPIDashboard />} />
        <Route path="table" element={<KPITable />} />
        <Route path="tabs" element={<KpiTabs />} />
        <Route path="groups" element={<KPIGroups />} />
        <Route path="library/create" element={<KPICreate />} />
        <Route path="library/:id/edit" element={<KPIFormEdit />} />
        <Route path="library/:id/import" element={<KPIFormImport />} />
        <Route path="library" element={<KPILibrary />} />
        <Route path="sources" element={<KPISources />} />
        <Route path="requests" element={<KPIRequests />} />
      </Route>

      {/* <Route path="/analytics" element={<Analytics />} /> */}
      <Route path="/forecast" element={<ForecastOutlet />}>
        <Route path=":id" element={<ForecastView />} />
        <Route index element={<Forecast />} />
      </Route>

      <Route path="/reports" element={<Reports />}>
        <Route path="list" element={<ReportsList />} />
        <Route path="/reports/builder/:id" element={<ReportBuilder />} />
        <Route path="/reports/builder" element={<ReportBuilder />} />
      </Route>

      {/* <Route path="/portal" element={<Portal />} /> */}
      <Route path={governanceRoutes.main.path} element={governanceRoutes.main.element}>
        <Route path={governanceRoutes.home.path} element={<GovernanceHome />} />
        {governanceRoutes.sections
          .flatMap((section) => section.links)
          .map((link) => (
            <Route key={link.fullPath} path={link.path} element={link.element} />
          ))}
        <Route path={"boards/:id"} element={<Board />} />
        <Route path={"meetings/:id"} element={<Meeting />} />
      </Route>

      <Route path="/data" element={<DataManagement />}>
        <Route path="home" element={<DataManagementHome />} />
        <Route path="entities" element={<Entities />} />
        <Route path="entities/create/:type" element={<EntityCreate />} />
        <Route path="entities/create" element={<EntityCreate />} />
        <Route path="entities/edit/:id" element={<EntityEdit />} />
        <Route path="entities/:type" element={<Entities />} />

        <Route path="dimension-types" element={<DimensionTypes />} />
        <Route path="dimension-types/create" element={<DimensionTypesFormCreate />} />
        <Route path="dimension-types/edit/:id" element={<DimensionTypesFormEdit />} />
        <Route path="dimension-types/:id" element={<DimensionTypesDetail />} />

        <Route path="dimensions" element={<Dimensions />} />
        <Route path="dimensions/create" element={<DimensionsFormCreate />} />
        <Route path="dimensions/edit/:typeid/:id" element={<DimensionsFormEdit />} />
        <Route path="dimensions/create/:typeid" element={<DimensionsFormCreate />} />

        <Route path="chart-of-accounts" element={<ChartOfAccounts />} />
        <Route path="chart-of-accounts/create" element={<CreateChartOfAccounts />} />
        <Route path="chart-of-accounts/edit/:id" element={<EditChartOfAccounts />} />
        <Route path="chart-of-accounts/:id" element={<Accounts />} />

        <Route path="accounts/mapping" element={<AccountsMapping />} />
        <Route
          path="accounts/mapping/:id/:targetid/:sourceid"
          element={<AccountMappingDetails />}
        />

        <Route path="ledgers" element={<Ledgers />} />
        <Route path="ledgers/import" element={<LedgerImportForm />} />
        <Route path="ledgers/edit/:id" element={<LedgerEditForm />} />
        <Route path="ledgers/:trialbalanceid" element={<LedgerDetails />} />

        <Route path="reviews" element={<ReviewsList />}>
          <Route index element={<ReviewsHome />} />
          <Route path="list" element={<ReviewsList />} />
        </Route>

        <Route path="erp" element={<ERP />} />

        <Route path="databases" element={<Databases />} />
      </Route>

      <Route path="/settings" element={<Settings />}>
        <Route path="home" element={<SettingsHome />} />
        <Route path="account" element={<AccountProfile />} />
        <Route path="account/security" element={<AccountSecurityPolicy />} />
        <Route path="account/notifications" element={<AccountNotifications />} />
        <Route path="organization" element={<OrganizationGlobalSettings />} />
        <Route path="organization/preferences" element={<OrganizationPreferences />} />
        <Route path="organization/appearance" element={<OrganizationAppearance />} />
        <Route path="users" element={<Users />} />
        <Route path="access" element={<AccessGroupList />} />
        <Route path="access/:id" element={<AccessGroupDetails />} />
        <Route path="access/create" element={<AccessGroupCreate />} />
        <Route path="providers" element={<AccessProviders />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
