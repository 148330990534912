import { createColumnHelper } from "@tanstack/react-table";

import { TitleCell } from "@/components/Table/TitleCell";

import { formatPeriod } from "../../utils/formatPeriod";
import { Group, Period } from "./types";

const columnHelper = createColumnHelper<Group>();

type ColumnsProps = {
  periods?: Period[];
};

export function columns({ periods }: ColumnsProps) {
  return [
    columnHelper.accessor("title", {
      header: () => "Series",
      cell: ({ getValue }) => <TitleCell>{getValue()}</TitleCell>,
    }),
    columnHelper.group({
      id: "periods",
      header: () => "Periods",
      columns: periods
        ? [
            ...periods.map((period) =>
              columnHelper.accessor("periods", {
                id: period.date,
                header: () => formatPeriod(new Date(period.date)),
                cell: ({ row }) => {
                  const periodMatch = row.original.periods.find((p) => p.date === period.date);
                  return periodMatch ? periodMatch.value : "-";
                },
                meta: {
                  isForecast: period.isForecast,
                },
              })
            ),
          ]
        : [],
    }),
  ];
}
