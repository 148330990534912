import { useEffect, useState } from "react";

import { PermissionsData } from "@/contexts/auth/auth.types";
import { useAuth } from "@/hooks/useAuth";
import { AppActions } from "@/modules/settings/access-groups/permissions.types";

import { validateUserAccess } from "./validateUserAccess";

type UseCanProps = {
  permissions: AppActions[];
};

function mapAllUserPermissionsToAppActions(permissions: PermissionsData[]) {
  return permissions.reduce(
    (acc, permission) => {
      return [...acc, ...permission.resources];
    },

    [] as AppActions[]
  );
}

export function useCan({ permissions }: UseCanProps) {
  const [can, setCan] = useState(false);
  const { userPermissions } = useAuth();

  useEffect(() => {
    setCan(
      validateUserAccess({
        user: {
          permissions: mapAllUserPermissionsToAppActions(userPermissions),
        },
        permissions,
      })
    );
  }, [userPermissions, permissions, mapAllUserPermissionsToAppActions]);

  return can;
}
