import { Field, Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";

import { useValidationSchema } from "@/hooks/useValidationSchema";

import * as Styled from "../login.styles";
import { useLogin } from "../useLogin";

type ActivateEmailFormValues = {
  email: string;
};

const activeEmailFormSchema = yup.object().shape({
  email: yup.string().required("E-mail required").email("Invalid e-mail format"),
});

type ActiveEmailParams = {
  token: string;
};

export function ActivateEmail() {
  const validate = useValidationSchema(activeEmailFormSchema);
  const navigate = useNavigate();
  const { token } = useParams<ActiveEmailParams>();
  const { activateEmail, activateEmailLoading } = useLogin();

  async function onSubmit(values: ActivateEmailFormValues) {
    if (!token) {
      message.error("Token not found");
      navigate("/login");
      return;
    }

    const onSuccess = () => {
      message.success("Email successfully activated");
      navigate("/login");
    };

    const onError = (text: string) => {
      message.error(text);
      navigate("/login");
    };

    await activateEmail({ email: values.email, token, onSuccess, onError });
  }

  return (
    <Form<ActivateEmailFormValues>
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        return (
          <Styled.Form onSubmit={handleSubmit}>
            <Styled.CardContainer>
              <Card title="Activate Email" withHeader>
                <Field
                  name="email"
                  render={({ input, meta }) => (
                    <Input {...input} label="Email" errorText={meta.touched && meta.error} />
                  )}
                />

                <Styled.FooterButtonsContainer>
                  <Button onClick={() => navigate("/login")}>Back to Login</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={activateEmailLoading}
                    disabled={activateEmailLoading}
                  >
                    Send Activation Link
                  </Button>
                </Styled.FooterButtonsContainer>
              </Card>
            </Styled.CardContainer>
          </Styled.Form>
        );
      }}
    />
  );
}
