import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import InlineEdit from "@synerise/ds-inline-edit";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { CancelEditModal } from "@/components/LayoutBuilder/CancelEditModal/CancelEditModal";
import { EditItemDrawer } from "@/components/LayoutBuilder/EditItemDrawer/EditItemDrawer";
import { getItemsConfig } from "@/components/LayoutBuilder/GridItems/GridItems.config";

import { ReportResponse } from "../reports.types";
import { LeftPanel } from "./components/LeftPanel/LeftPanel";
import { ReportBuilderModalForm } from "./components/ReportBuilderModalForm/ReportBuilderModalForm";
import { useGridLayout } from "./hooks/useGridLayout";
import { useReportBuilder } from "./hooks/useReportBuilder";
import { gridItemsLayoutStyles } from "./report-builder.config";
import { ReportBuilderGridLayout } from "./ReportBuilderGridLayout";

type ReportBuilderLayoutProps = {
  editingReport?: ReportResponse;
};

export function ReportBuilderLayout({ editingReport }: ReportBuilderLayoutProps) {
  const navigate = useNavigate();
  const {
    gridItemsDrawerOpened,
    editItemDrawerOpened,
    toggleGridItemsDrawer,
    toggleEditItemDrawer,
  } = useReportBuilder();
  const {
    indexItemSelected,
    configItemSelected,
    saveLayout,
    showCancelEditModal,
    cancelEdit,
    editingItem,
    updateEditingItem,
    saveTemporaryEditChanges,
    itemHasBeenModified,
    closeCancelEditModal,
    confirmCancelEdit,
    inPreviewMode,
    toggleInPreviewMode,
  } = useGridLayout();

  const [title, setTitle] = useState("Compass Inc.");

  function handleSaveTemporaryLayout() {
    saveTemporaryEditChanges();
    message.success("Saved");
  }

  function handleCancelTemporaryEdit() {
    cancelEdit();
  }

  const itemsConfig = getItemsConfig({
    updateItem: updateEditingItem,
    editingItem,
  });

  return (
    <Layout
      renderLeftSidebarControls={true}
      renderRightSidebarControls={true}
      fullPage={true}
      styles={gridItemsLayoutStyles}
      left={
        !inPreviewMode
          ? {
              content: <LeftPanel />,
              opened: gridItemsDrawerOpened,
              onChange: toggleGridItemsDrawer,
            }
          : undefined
      }
      right={
        indexItemSelected !== null && configItemSelected?.editDrawerComponent && !inPreviewMode
          ? {
              content: (
                <EditItemDrawer
                  onSave={handleSaveTemporaryLayout}
                  onCancel={handleCancelTemporaryEdit}
                  cancelButtonDisabled={!itemHasBeenModified}
                  saveButtonDisabled={!itemHasBeenModified}
                >
                  {editingItem && (itemsConfig[editingItem.type].editDrawerComponent as any)}
                </EditItemDrawer>
              ),
              opened: editItemDrawerOpened,
              onChange: toggleEditItemDrawer,
            }
          : undefined
      }
      header={
        <PageHeader
          onGoBack={() => navigate(-1)}
          title={
            <InlineEdit
              input={{
                name: "title",
                value: title,
                onChange: (event) => setTitle(event.target.value),
              }}
              size={"normal"}
              error={false}
              disabled={false}
              hideIcon={false}
            />
          }
          rightSide={
            <>
              <Button type="secondary" onClick={() => toggleInPreviewMode()}>
                {inPreviewMode ? "Edit" : "Preview"}
              </Button>
              <Button type="primary" onClick={() => saveLayout()}>
                Save Report
              </Button>
            </>
          }
        />
      }
    >
      <ReportBuilderGridLayout />
      <ReportBuilderModalForm editingReport={editingReport} />
      <CancelEditModal
        visible={showCancelEditModal}
        onCancel={closeCancelEditModal}
        onConfirm={confirmCancelEdit}
      />
    </Layout>
  );
}
