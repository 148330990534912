import Card from "@synerise/ds-card";
import Icon from "@synerise/ds-icon";

import * as Styled from "./styles";

type ModuleCardProps = {
  title: string;
  description?: string;
  icon: JSX.Element;
  onClick: () => void;
};

export function ModuleCard({ title, description, icon, onClick }: ModuleCardProps) {
  return (
    <Styled.Container onClick={onClick}>
      <Card
        title={title}
        lively
        description={description}
        withHeader
        withoutPadding
        icon={<Icon component={icon} color="#006BF8" />}
        style={{ height: "100%" }}
      />
    </Styled.Container>
  );
}
