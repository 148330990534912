import BoringAvatar from "boring-avatars";

import DSSelect from "@synerise/ds-select";
import { Props } from "@synerise/ds-select/dist/Select.types";

type Item = {
  id: string | number;
  value: string | number;
  name: string;
};

type SelectProps = { items: Item[] } & Props;

export function Select({ items, ...rest }: SelectProps) {
  return (
    <DSSelect
      {...rest}
      label="Entity"
      showSearch
      optionFilterProp="name"
      options={items.map((item) => ({
        id: item.id,
        value: item.value,
        name: item.name,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <BoringAvatar
              size={20}
              name={item.name}
              variant="bauhaus"
              colors={["#58706D", "#4B5757", "#7C8A6E", "#B0B087", "#E3E3D1"]}
            />
            {item.name}
          </div>
        ),
      }))}
    />
  );
}
