import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

export function KPIDashboard() {
  const navigate = useNavigate();

  return (
    <Layout
      header={
        <PageHeader
          title="KPIs Dashboard"
          rightSide={
            <>
              <Button type="secondary" onClick={() => navigate("/kpis/library")}>
                Go To Library
              </Button>
            </>
          }
        />
      }
    >
      KPI Dashboard
    </Layout>
  );
}
