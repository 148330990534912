import { useCallback } from "react";
import { useMutation } from "react-query";

import message from "@synerise/ds-message";

import { GovernanceServices } from "../governance.services";
import { BoardPostCreatePayload } from "../governance.services.types";
import { useRefreshPosts } from "./useRefreshPosts";

type UseCreatePostProps = {
  boardId: number;
};

export const useCreatePost = ({ boardId }: UseCreatePostProps) => {
  const refreshPosts = useRefreshPosts();

  const createPostMutation = useMutation(
    (payload: BoardPostCreatePayload) => GovernanceServices.createPost(boardId, payload),
    {
      onSuccess: () => {
        message.success("Post created");
        refreshPosts();
      },
      onError: (error: any) => {
        message.error(error.message);
      },
    }
  );

  const createPost = useCallback(async (payload: BoardPostCreatePayload) => {
    createPostMutation.mutate(payload);
  }, []);

  return {
    createPost,
    isCreatePostLoading: createPostMutation.isLoading,
  };
};
