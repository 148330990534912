import { Field, Form } from "react-final-form";
import styled from "styled-components";
import * as yup from "yup";

import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";
import Select from "@synerise/ds-select";

import { useAccessGroups } from "@/hooks/useAccessGroups";
import { useValidationSchema } from "@/hooks/useValidationSchema";

import { useMembers } from "./useMembers";
import { MemberInvitationPayload } from "./users.types";

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

type UserInvitationFormValues = {
  name: string;
  email: string;
  role: number;
};

type UserInvitationModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: () => void;
};

const inviteUserSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  role: yup.number().required("Role is required"),
});

export function UserInvitationModal({ visible, setVisible, onSuccess }: UserInvitationModalProps) {
  const validate = useValidationSchema(inviteUserSchema);
  const { allAccessGroups } = useAccessGroups();

  function onGetMembersError(errorMessage: string) {
    message.error(errorMessage);
  }

  const { inviteMember, inviteMemberLoading } = useMembers({
    onError: onGetMembersError,
  });

  const onSubmit = async (values: UserInvitationFormValues) => {
    const onError = (errorMessage: string) => {
      message.error(errorMessage);
    };

    const payload: MemberInvitationPayload = {
      name: values.name,
      email: values.email,
      access_id: values.role,
    };

    await inviteMember({
      payload,
      onError,
      onSuccess,
    });
  };

  return (
    <Form<UserInvitationFormValues> onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            visible={visible}
            onOk={handleSubmit}
            onCancel={() => setVisible(false)}
            confirmLoading={inviteMemberLoading}
            title="Invite Users"
            texts={{
              okButton: "Invite",
              cancelButton: "Cancel",
            }}
          >
            <Field name="name">
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="Name"
                  placeholder="Name"
                  errorText={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="email">
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="Email"
                  placeholder="Email"
                  errorText={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="role">
              {({ input, meta }) => (
                <Select
                  {...input}
                  placeholder="Access Group"
                  defaultValue={[]}
                  label="Access Group"
                  errorText={meta.touched && meta.error}
                  options={allAccessGroups.map((role) => ({
                    value: role.id,
                    label: role.title,
                    key: role.id,
                  }))}
                />
              )}
            </Field>
          </Modal>
        </form>
      )}
    </Form>
  );
}
