import { useRef, useState } from "react";

import Button from "@synerise/ds-button";
import Dropdown from "@synerise/ds-dropdown";
import Icon, { OptionHorizontalM, TrashM } from "@synerise/ds-icon";
import Menu from "@synerise/ds-menu";
import { useOnClickOutside, focusWithArrowKeys } from "@synerise/ds-utils";

type ActionsDropdownProps = {
  onRemove: () => void;
};

export function ActionsDropdown({ onRemove }: ActionsDropdownProps) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setDropdownVisible(false);
  });

  return (
    <div>
      <Dropdown
        overlayStyle={{ borderRadius: "3px" }}
        visible={dropdownVisible}
        placement="bottomLeft"
        overlay={
          <Dropdown.Wrapper
            style={{ width: "220px" }}
            onKeyDown={(e: any) => focusWithArrowKeys(e, "ds-menu-item", () => {})}
            ref={ref}
          >
            <Menu asDropdownMenu={true} style={{ width: "100%" }}>
              <Menu.Item
                onClick={() => {
                  onRemove();
                  setDropdownVisible(false);
                }}
                style={{ width: "100%" }}
                type="danger"
              >
                <div style={{ display: "flex", gap: "15px" }}>
                  <Icon color="#4a3c3c" component={<TrashM />} size={20} />
                  Delete
                </div>
              </Menu.Item>
            </Menu>
          </Dropdown.Wrapper>
        }
      >
        <Button
          mode="single-icon"
          type="ghost-white"
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          <Icon color="#4a3c3c" component={<OptionHorizontalM />} />
        </Button>
      </Dropdown>
    </div>
  );
}
