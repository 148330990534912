import styled from "styled-components";

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 1rem;
  height: 100%;
`;

export const DrawerTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .kpi-description {
    color: #b8b8b8;
  }
`;

export const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`;

export const CommentContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CommentMainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & .comment-text {
    margin-top: 0.1rem;
  }

  & .comment-date {
    margin-top: 0.3rem;
    font-size: 0.7rem;
    color: #8b8b8b;
    font-weight: 300;
  }
`;

export const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NewCommentContainer = styled.div``;

export const NewCommentFooter = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
