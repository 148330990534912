import { kpiRoutes } from "./kpi.routes";

type KpiMenuItem = {
  title: string;
  link: string;
};

type KpiMenuGroup = {
  title: string;
  items: KpiMenuItem[];
};

type KpiMenu = {
  title: string;
  link: string;
  groups: KpiMenuGroup[];
};

const kpiMenu: KpiMenu = {
  title: "KPIs",
  link: kpiRoutes.home,
  groups: [
    {
      title: "Main",
      items: [
        {
          title: "Dashboard",
          link: kpiRoutes.dashboard,
        },
        {
          title: "KPIs",
          link: kpiRoutes.table,
        },
        {
          title: "Tabs",
          link: kpiRoutes.tabs,
        },
      ],
    },
    {
      title: "Views",
      items: [
        {
          title: "By Group",
          link: kpiRoutes.groups,
        },
        {
          title: "By Importance",
          link: kpiRoutes.tableImportance,
        },
        {
          title: "By Dimension",
          link: kpiRoutes.tableDimension,
        },
      ],
    },
    {
      title: "Repository",
      items: [
        {
          title: "Library",
          link: kpiRoutes.library,
        },
        {
          title: "Groups",
          link: kpiRoutes.groups,
        },
      ],
    },
    {
      title: "Integration & Requests",
      items: [
        {
          title: "Data Sources",
          link: kpiRoutes.source,
        },
        {
          title: "Data Request",
          link: kpiRoutes.request,
        },
      ],
    },
  ],
};

export default kpiMenu;
