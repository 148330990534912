import styled from "styled-components";

export const AccessModulesSwitchTable = styled.table`
  margin: 0 auto;
  width: 100%;
  max-width: 500px;

  tr:not(:last-child) {
    border-bottom: 0px solid #eaeaea;
  }

  td:not(:first-child) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
`;

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const AvatarInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const dropdownStyle = {
  paddingRight: "0",
  boxShadow: "0px 0px 0px 1px rgba(0,0,0,0.1)",
};
