import { forwardRef, PropsWithChildren, Ref } from "react";

import "./RichText.styles.css";

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

export const Button = forwardRef(function buttonComponent(
  {
    className,
    active,
    reversed,
    ...props
  }: PropsWithChildren<
    {
      active: boolean;
      reversed: boolean;
    } & BaseProps
  >,
  ref: Ref<HTMLSpanElement>
) {
  return (
    <span
      {...props}
      ref={ref}
      className={className}
      style={{
        cursor: "pointer",
        color: reversed ? (active ? "white" : "#aaa") : active ? "black" : "#ccc",
      }}
    />
  );
});

export const Icon = forwardRef(function buttonComponent(
  { className, ...props }: PropsWithChildren<BaseProps>,
  ref: Ref<HTMLSpanElement>
) {
  return (
    <span
      {...props}
      ref={ref}
      className={className}
      style={{ fontSize: "18px", verticalAlign: "text-bottom" }}
    >
      {props.children}
    </span>
  );
});

export const Menu = forwardRef(function menuComponent(
  { className, ...props }: PropsWithChildren<BaseProps>,
  ref: Ref<HTMLDivElement>
) {
  return <div {...props} ref={ref} className={`${className} rich-text-menu`} />;
});

export const Toolbar = forwardRef(function toolBarComponent(
  { className, ...props }: PropsWithChildren<BaseProps>,
  ref: Ref<HTMLDivElement>
) {
  return (
    <Menu
      {...props}
      ref={ref}
      className={className}
      style={{
        position: "relative",
        padding: "1px 18px 18px",
        margin: "0 -20px",
        borderBottom: "2px solid #eee",
        marginBottom: "20px",
      }}
    />
  );
});
