export type GridItemSeriesData = {
  name: string;
  data: number[];
  labels: string[];
};

export type GridItemChartData = {
  title: string;
  series: GridItemSeriesData[];
};

function generateRandomData(length: number): number[] {
  return Array(length)
    .fill(0)
    .map(() => Math.floor(Math.random() * 1000));
}

export const mockChartGroups = [
  {
    groupName: "KPIs",
    groupOptions: [
      {
        optionName: "Total Sales Meetings",
        optionValue: "TSM001",
      },
      {
        optionName: "Calculated Series",
        optionValue: "TSM002",
      },
      {
        optionName: "Leads generated",
        optionValue: "LG001",
      },
      {
        optionName: "Total Leads",
        optionValue: "TL001",
      },
      {
        optionName: "Leads Converted",
        optionValue: "LC001",
      },
      {
        optionName: "Leads Converted Rate",
        optionValue: "LCR001",
      },
      {
        optionName: "Website Visits",
        optionValue: "WV001",
      },
      {
        optionName: "Ebook downloads",
        optionValue: "ED001",
      },
      {
        optionName: "Total Sales",
        optionValue: "TS001",
      },
      {
        optionName: "FTE",
        optionValue: "FTE001",
      },
      {
        optionName: "Sales per FTE",
        optionValue: "SPFTE001",
      },
      {
        optionName: "SDR per FTE",
        optionValue: "SDRPFTE001",
      },
      {
        optionName: "Total SDR",
        optionValue: "TSDR001",
      },
    ],
  },
  {
    groupName: "Financials: P&L",
    groupOptions: [
      {
        optionName: "Revenue",
        optionValue: "REV001",
      },
      {
        optionName: "COGS",
        optionValue: "COGS01",
      },
      {
        optionName: "Gross Profit",
        optionValue: "GP001",
      },
      {
        optionName: "SG&A",
        optionValue: "SGA01",
      },
      {
        optionName: "EBITDA",
        optionValue: "EBITDA01",
      },
      {
        optionName: "Net Income",
        optionValue: "NI01",
      },
    ],
  },
  {
    groupName: "Financials: Balance Sheet",
    groupOptions: [
      {
        optionName: "Total Assets",
        optionValue: "TA01",
      },
      {
        optionName: "Current Assets",
        optionValue: "CA01",
      },
      {
        optionName: "Non Current Assets",
        optionValue: "NCA01",
      },
      {
        optionName: "Total Liabilities",
        optionValue: "TL01",
      },
      {
        optionName: "Current Liabilities",
        optionValue: "CL01",
      },
      {
        optionName: "Non Current Liabiliies",
        optionValue: "NCL01",
      },
      {
        optionName: "Equity",
        optionValue: "EQ01",
      },
    ],
  },
  {
    groupName: "Financials: Cash Flow",
    groupOptions: [
      {
        optionName: "Operating Cash Flow",
        optionValue: "OCF01",
      },
      {
        optionName: "Investing Cash Flow",
        optionValue: "ICF01",
      },
      {
        optionName: "Financing Cash Flow",
        optionValue: "FCF01",
      },
      {
        optionName: "Cash Balance",
        optionValue: "CB01",
      },
    ],
  },
  {
    groupName: "Ratios",
    groupOptions: [
      {
        optionName: "Gross Profit Margin",
        optionValue: "GPM",
      },
      {
        optionName: "Ebitda Margin",
        optionValue: "EBITDAMG",
      },
      {
        optionName: "Debt / Equity",
        optionValue: "DE",
      },
      {
        optionName: "DSO",
        optionValue: "DSO",
      },
    ],
  },
];

export const mockChartData = {
  title: "Chart",
  series: [
    {
      name: "TSM001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "TSM002",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "LG001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "TL001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "LC001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "LCR001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "WV001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "ED001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "TS001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "FTE001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "SPFTE001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "SDRPFTE001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "TSDR001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "REV001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "COGS01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "GP001",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "SGA01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "EBITDA01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "NI01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "TA01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "CA01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "NCA01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "TL01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "CL01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "NCL01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "EQ01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "OCF01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "ICF01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "FCF01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "CB01",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "GPM",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "EBITDAMG",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "DE",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
    {
      name: "DSO",
      data: generateRandomData(16),
      labels: [
        "Jan/21",
        "Feb/21",
        "Mar/21",
        "Apr/21",
        "May/21",
        "Jun/21",
        "Jul/21",
        "Aug/21",
        "Sep/21",
        "Oct/21",
        "Nov/21",
        "Dec/21",
        "Jan/22",
        "Feb/22",
        "Mar/22",
        "Abr/22",
      ],
    },
  ],
};
