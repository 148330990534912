import { useMemo } from "react";
import { useQuery, useMutation } from "react-query";

import TenantsService from "@/core/tenants/tenants.services";
import { Tenant, TenantPatch } from "@/core/tenants/tenants.types";
import { queryClient } from "@/utils/queryClient";

const TENANT_QUERY_KEY = "tenant";

type UseTenantReturn = {
  tenant: Tenant | null;
  tenantLoading: boolean;
  updateTenant: (props: UpdateTenantProps) => Promise<void>;
  updateTenantLoading: boolean;
};

type UpdateTenantProps = {
  payload: TenantPatch;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

const handleGetTenant = async (id: number) => {
  const response = await TenantsService.getTenant(id);
  return response;
};

const handleUpdateTenant = async (id: number, payload: TenantPatch) => {
  return await TenantsService.updateTenant(id, payload);
};

export function useTenant(onError: (text: string) => void, id?: number): UseTenantReturn {
  const tenantFromQuery = useQuery(
    TENANT_QUERY_KEY,
    async () => {
      if (id) {
        return await handleGetTenant(id);
      }
    },
    {
      onError(error: any) {
        if (error.response && error.response.data && error.response.data.detail) {
          onError(error.response.data.detail);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const updateTenantMutation = useMutation(
    async ({ payload }: UpdateTenantProps) => {
      if (id) {
        return await handleUpdateTenant(id, payload);
      }
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(TENANT_QUERY_KEY);
        onSuccess();
      },
      onError: (error: any, { onError }) => {
        if (error.response && error.response.data && error.response.data.detail) {
          onError(error.response.data.detail);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const updateTenant = async (props: UpdateTenantProps) => {
    await updateTenantMutation.mutateAsync(props);
  };

  const tenant = useMemo(() => {
    return tenantFromQuery.data ?? null;
  }, [tenantFromQuery.data]);

  return {
    tenant,
    tenantLoading: tenantFromQuery.isLoading,
    updateTenant,
    updateTenantLoading: updateTenantMutation.isLoading,
  };
}
