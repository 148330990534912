import { Periodicity } from "./trialbalance.types";

export const LEDGERS_QUERY_KEY = "ledgers";
export const LEDGERS_LINE_COMMENTS_QUERY_KEY = "ledgers-line-comments";

type PeriodicityOptions = {
  label: string;
  value: Periodicity | "";
};

type ReviewedOptions = {
  label: string;
  value: "all" | "true" | "false";
};

type ConsistencyOptions = {
  label: string;
  value: "all" | "true" | "false";
};

type FlaggedOptions = {
  label: string;
  value: "all" | "true" | "false";
};

export const periodicityOptions: PeriodicityOptions[] = [
  {
    label: "All Periodicity",
    value: "",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

export const reviewedOptions: ReviewedOptions[] = [
  {
    label: "Reviewed/Not Reviewed",
    value: "all",
  },
  {
    label: "Reviewed",
    value: "true",
  },
  {
    label: "Not Reviewed",
    value: "false",
  },
];

export const consistencyOptions: ConsistencyOptions[] = [
  {
    label: "All Consistency",
    value: "all",
  },
  {
    label: "Consistent",
    value: "true",
  },
  {
    label: "Not Consistent",
    value: "false",
  },
];

export const flaggedOptions: FlaggedOptions[] = [
  {
    label: "No Flag Filter",
    value: "all",
  },
  {
    label: "Only flagged",
    value: "true",
  },
  {
    label: "Only unflagged",
    value: "false",
  },
];
