import { useState } from "react";

import Button from "@synerise/ds-button";
import { Input, TextArea } from "@synerise/ds-input";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";
import Select from "@synerise/ds-select";

type AdjustmentModalCreateProps = {
  isOpen: boolean;
  onClose: () => void;
  value: number;
  period: string;
  accountName: string;
};

export function AdjustmentModalCreate({
  isOpen,
  onClose,
  value,
  period,
  accountName,
}: AdjustmentModalCreateProps) {
  const [valueInput, setValueInput] = useState(value);
  const [comment, setComment] = useState("");

  const handleClose = () => {
    setValueInput(value);
    setComment("");
    onClose();
  };

  const handleSubmit = () => {
    message.success("Adjustment created");
    onClose();
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={handleClose}
      title="Add adjustment"
      description={`Add an adjustment for account ${accountName} in ${period}`}
      footer={
        <Button type="primary" onClick={handleSubmit}>
          Add
        </Button>
      }
    >
      <Input
        label="Value"
        type="number"
        value={valueInput}
        onChange={(e: any) => setValueInput(e.target.value)}
      />
      <TextArea label="Comment" value={comment} onChange={(e: any) => setComment(e.target.value)} />
      <Select label="Add to set"></Select>
    </Modal>
  );
}
