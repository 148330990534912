import CompassWhiteLogo from "@/assets/LogoWhite.png";
import { AuthRoutes } from "@/routes/Auth.routes";

import * as Styled from "./styles";

export function Auth() {
  return (
    <Styled.Container>
      <Styled.ImageSection>
        <img src={CompassWhiteLogo} width={250} alt="Compass Inc. Financial Intelligence" />
      </Styled.ImageSection>
      <Styled.MainSection>
        <AuthRoutes />
      </Styled.MainSection>
    </Styled.Container>
  );
}
