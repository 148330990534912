import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, useExpanded, useFilters, useGroupBy, useRowSelect, useTable } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { DateRangePicker } from "@/components/DateRangePicker/DateRangePicker";
import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import { EmptyTable } from "@/components/Table/EmptyTable";
import * as TableStyled from "@/components/Table/styles";

import { DataSource } from "../kpi.types";
import { useKpis } from "../library/hooks/useKpis";
import { kpiPeriodicityToRangeDatePickerHelper } from "../utils/kpiPeriodicityToRangeDatePickerHelper";
import { KPICommentsDrawer } from "./components/KPICommentsDrawer";
import { tableColumns } from "./kpi-table.columns";
import { groupByColumnIDOptions, periodicityOptions, valueTypesOptions } from "./kpi-table.config";
import * as KPITableStyled from "./kpi-table.styles";
import * as Styled from "./kpi-table.styles";
import { KPITableData } from "./kpi-table.types";
import { KPICreateBudgetModal } from "./KPICreateBudgetModal";
import { KPIOverviewModal } from "./KPIOverviewModal";
import { KPICreateBudgetProvider } from "./useKPICreateBudget";
import { useKPIsTable } from "./useKPIsTable";
import { addOptionsButtonToTable } from "./utils/addOptionsButtonToTable";
import { addComparationColumnsToTableData } from "./utils/mapKPIsToTableData";

export function KPITableContent() {
  const { periodicitySelected, setPeriodicitySelected, changeKpiSelected } = useKPIsTable();
  const navigate = useNavigate();
  const [kpiOverviewModalVisible, setKpiOverviewModalVisible] = useState(false);

  const [showBudgetVariationColumn, setShowBudgetVariationColumn] = useState(false);
  const [showHorizontalAnalysisColumn, setShowHorizontalAnalysisColumn] = useState(false);
  const [valueTypeFilter, setValueTypeFilter] = useState<DataSource[]>(["actual"]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [isKpiValueCommentDrawerOpen, setIsKpiValueCommentDrawerOpen] = useState(false);

  const [kpiIdCommentSelected, setKpiIdCommentSelected] = useState<number | null>(null);
  const [valueIdCommentSelected, setValueIdCommentSelected] = useState<number | null>(null);

  const { kpisToTableData, kpisLoading } = useKpis({
    show_values: true,
    periodicity: periodicitySelected,
    start_date: startDate?.toISOString() || undefined,
    end_date: endDate?.toISOString() || undefined,
    data_sources: valueTypeFilter,
  });

  function closeKPIOverviewModal() {
    setKpiOverviewModalVisible(false);
  }

  async function openKpiValueCommentDrawer(kpiId: number, valueId: number) {
    setIsKpiValueCommentDrawerOpen(true);
    setKpiIdCommentSelected(kpiId);
    setValueIdCommentSelected(valueId);
  }

  function closeKpiValueCommentDrawer() {
    setIsKpiValueCommentDrawerOpen(false);
    setKpiIdCommentSelected(null);
    setValueIdCommentSelected(null);
  }

  function handleKPIClick(kpi: KPITableData) {
    changeKpiSelected(kpi);
    setKpiOverviewModalVisible(true);
  }

  const tableData = useMemo(() => {
    return addComparationColumnsToTableData(kpisToTableData);
  }, [kpisToTableData]);

  const columns = useMemo(() => {
    const kpiWithValues = kpisToTableData.find((kpi) => kpi.values && kpi.values.length > 1);
    const values = kpiWithValues ? (kpiWithValues.values ? kpiWithValues.values : []) : [];
    return tableColumns(
      values,
      periodicitySelected,
      valueTypeFilter,
      openKpiValueCommentDrawer,
      valueIdCommentSelected
    ) as Column<KPITableData>[];
  }, [kpisToTableData, valueIdCommentSelected]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleGroupBy,
    toggleAllRowsExpanded,
    toggleHideColumn,
    allColumns,
    state: { groupBy },
  } = useTable(
    {
      columns,
      data: tableData,
      defaultCanGroupBy: false,
      autoResetExpanded: false,
      initialState: {
        groupBy: ["group.title"],
      },
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      addOptionsButtonToTable(hooks);
      addCheckboxToTable(hooks);
    }
  );

  function handleHideBudgetVariationColumns(hide: boolean) {
    allColumns.forEach((column: any) => {
      if (column.isBudgetVariationColumn) {
        toggleHideColumn(column.id, hide);
      }
    });
  }

  useEffect(() => {
    handleHideBudgetVariationColumns(!showBudgetVariationColumn);
  }, [columns]);

  function handleHideHorizontalAnalysisColumns(hide: boolean) {
    allColumns.forEach((column: any) => {
      if (column.isHorizontalAnalysisColumn) {
        toggleHideColumn(column.id, hide);
      }
    });
  }

  useEffect(() => {
    handleHideHorizontalAnalysisColumns(!showHorizontalAnalysisColumn);
  }, [columns]);

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [groupBy]);

  function handleGroupByFilter(columnID: string) {
    groupByColumnIDOptions.forEach((option) => {
      if (option.value === columnID) {
        toggleGroupBy(option.value, true);
      } else if (option.value !== columnID) {
        toggleGroupBy(option.value, false);
      }
    });
  }

  function handleRangeDateFilter(newStartDate: Date | null, newEndDate: Date | null) {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }

  return (
    <Layout
      header={
        <PageHeader
          title="KPIs"
          onGoBack={() => navigate("/kpis/dashboard")}
          rightSide={
            <>
              <Button type="secondary" onClick={() => navigate("/kpis/library")}>
                Go To Library
              </Button>
              <Button type="primary" onClick={() => navigate("/kpis/library/create")}>
                Create New KPI
              </Button>
            </>
          }
        />
      }
    >
      <>
        <Card>
          <Styled.TableOptionsContainer>
            <div>
              <Switch
                label="Horizontal Analysis"
                checked={showHorizontalAnalysisColumn}
                onChange={(checked) => {
                  setShowHorizontalAnalysisColumn(checked);
                  handleHideHorizontalAnalysisColumns(!checked);
                }}
              />
              <Switch
                label="vs. Budget"
                checked={showBudgetVariationColumn}
                onChange={(checked) => {
                  setShowBudgetVariationColumn(checked);
                  handleHideBudgetVariationColumns(!checked);
                }}
              />
            </div>
            <div>
              <DateRangePicker
                onRangeDateChange={handleRangeDateFilter}
                endDate={endDate}
                startDate={startDate}
                periodicity={kpiPeriodicityToRangeDatePickerHelper[periodicitySelected]}
              />
              <Select
                placeholder="Value Type"
                allowClear={false}
                mode="multiple"
                value={valueTypeFilter}
                onChange={(value: any) => {
                  if (value.length > 0) {
                    setValueTypeFilter(value);
                  }
                }}
                style={{ minWidth: "150px" }}
              >
                {valueTypesOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>

              <Select
                value={periodicitySelected}
                onChange={(value: any) => setPeriodicitySelected(value)}
                style={{ minWidth: "150px" }}
              >
                {periodicityOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>

              <Select
                style={{ minWidth: "150px" }}
                onChange={(value: any) => handleGroupByFilter(value)}
                defaultValue={groupByColumnIDOptions[0].value}
              >
                {groupByColumnIDOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Styled.TableOptionsContainer>
        </Card>

        <Card style={{ overflowX: "scroll" }}>
          <TableStyled.Table {...getTableProps()}>
            <TableStyled.TableHead>
              {headerGroups.map((headerGroup) => (
                <TableStyled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <TableStyled.TableHeader
                      {...column.getHeaderProps()}
                      style={{ width: column.width, padding: "0 5px" }}
                    >
                      <TableStyled.TableCellWithFiltersWrapper>
                        {column.render("Header")}
                      </TableStyled.TableCellWithFiltersWrapper>
                    </TableStyled.TableHeader>
                  ))}
                </TableStyled.TableHeaderRow>
              ))}
            </TableStyled.TableHead>
            {!kpisLoading && rows.length > 1 && (
              <TableStyled.TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <KPITableStyled.TableBodyRow
                      {...row.getRowProps()}
                      isGrouped={row.isGrouped}
                      isSelected={row.isSelected}
                      className={row.isGrouped ? "group-row" : ""}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <KPITableStyled.TableCell
                            {...cell.getCellProps()}
                            darker={
                              cell.column.isBudgetVariationColumn &&
                              !cell.isPlaceholder &&
                              !cell.isAggregated
                            }
                          >
                            {cell.isGrouped ? (
                              <div {...row.getToggleRowExpandedProps()}>{cell.render("Cell")}</div>
                            ) : cell.isAggregated ? (
                              cell.render("Aggregated")
                            ) : cell.isPlaceholder ? (
                              <KPITableStyled.PlaceHolderCell
                                onClick={() => handleKPIClick(row.original)}
                              >
                                {row.original.title}
                              </KPITableStyled.PlaceHolderCell>
                            ) : (
                              cell.render("Cell")
                            )}
                          </KPITableStyled.TableCell>
                        );
                      })}
                    </KPITableStyled.TableBodyRow>
                  );
                })}
              </TableStyled.TableBody>
            )}
          </TableStyled.Table>
          {kpisLoading ? (
            <Loader labelPosition="bottom" label="Loading KPIs, please wait a moment" />
          ) : rows.length < 1 ? (
            <EmptyTable text="Empty KPIs Data" />
          ) : null}
        </Card>
        {kpiIdCommentSelected && valueIdCommentSelected && (
          <KPICommentsDrawer
            isVisible={isKpiValueCommentDrawerOpen}
            onClose={closeKpiValueCommentDrawer}
            kpiId={kpiIdCommentSelected}
            valueId={valueIdCommentSelected}
          />
        )}

        <KPIOverviewModal visible={kpiOverviewModalVisible} onCancel={closeKPIOverviewModal} />
        <KPICreateBudgetProvider>
          <KPICreateBudgetModal />
        </KPICreateBudgetProvider>
      </>
    </Layout>
  );
}
