import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";

import { useAccount } from "@/hooks/useAccounts";

import { AccountFormUpdate } from "./AccountFormUpdate";
import { AccountModalUpdateFooter } from "./AccountModalUpdateFooter";
import { AccountResponse } from "./accounts.types";

type AccountModalUpdateProps = {
  chartId: number;
  account: AccountResponse | null;
  isVisible: boolean;
  onClose: () => void;
};

const formId = "account-modal-update-form";

export function AccountModalUpdate({
  account,
  chartId,
  isVisible,
  onClose,
}: AccountModalUpdateProps) {
  const { isUpdateAccountLoading } = useAccount();

  if (!account) return null;

  const handleSuccessUpdate = () => {
    message.success("Account updated successfully");
    onClose();
  };

  const handleErrorUpdate = (errorMessage: string) => {
    message.error(errorMessage);
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={() => onClose()}
      title="Update account"
      footer={
        <AccountModalUpdateFooter
          onClose={() => onClose()}
          formId={formId}
          isSubmitting={isUpdateAccountLoading}
        />
      }
    >
      <AccountFormUpdate
        chartId={chartId}
        account={account}
        formId={formId}
        onSuccess={handleSuccessUpdate}
        onError={handleErrorUpdate}
      />
    </Modal>
  );
}
