export const dataManagementData = {
  home: {
    path: "/data/home",
  },
  entities: {
    path: "/data/entities",
    createPath: "/data/entities/create",
  },
  companies: {
    path: "/data/entities/companies",
    createPath: "/data/entities/create/company",
  },
  groups: {
    path: "/data/entities/groups",
    createPath: "/data/entities/create/group",
  },
  collections: {
    path: "/data/entities/collections",
    createPath: "/data/entities/create/collection",
  },
  dimensions: {
    path: "/data/dimensions",
    createPath: "/data/dimensions/create",
  },
  dimensionTypes: {
    path: "/data/dimension-types",
    createPath: "/data/dimension-types/create",
  },
  chartOfAccounts: {
    path: "/data/chart-of-accounts",
    createPath: "/data/chart-of-accounts/create",
    editPath: (id: number) => "/data/chart-of-accounts/edit/" + id,
  },
  ledgers: {
    path: "/data/ledgers",
    importPath: "/data/ledgers/import",
  },
  reviews: {
    path: "/data/reviews",
  },
  databases: {
    path: "/data/databases",
  },
  erp: {
    path: "/data/erp",
  },
  accountsMapping: {
    path: "/data/accounts/mapping",
  },
};
