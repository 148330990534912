import { useMemo, useState } from "react";
import { useTable, useExpanded, Column } from "react-table";

import Card from "@synerise/ds-card";
import message from "@synerise/ds-message";

import * as Styled from "@/components/Table/styles";
import { useAccounts } from "@/hooks/useAccounts";
import { useAccountsByChartID } from "@/hooks/useAccountsByChartID";

import { AccountModalUpdate } from "./AccountModalUpdate";
import { accountsTableColumns } from "./accounts-table-columns";
import { AccountsTableData } from "./accounts-table.types";
import { AccountResponse } from "./accounts.types";

type AccountsTableProps = {
  chartId: number;
  data: AccountsTableData[];
};

export function AccountsTable({ data, chartId }: AccountsTableProps) {
  const { removeAccount, isRemoveAccountLoading } = useAccounts();
  const { chartOfAccounts } = useAccountsByChartID(chartId);

  const [showUpdateAccountModal, setShowUpdateAccountModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<AccountResponse | null>(null);

  const accounts = useMemo(() => {
    if (!chartOfAccounts) return [];

    return chartOfAccounts.accounts ?? [];
  }, [chartOfAccounts]);

  const closeUpdateAccountModal = () => {
    setShowUpdateAccountModal(false);
    setSelectedAccount(null);
  };

  const openUpdateAccountModal = (accountId: number) => {
    const account = accounts.find((account) => account.id === accountId);

    if (!account) {
      message.warning("Account not found");
      return;
    }

    setSelectedAccount(account);
    setShowUpdateAccountModal(true);
  };

  const handleRemoveAccount = async (id: number, accountName: string) => {
    const onSuccess = () => {
      message.success(`${accountName} has been removed`);
    };

    const onError = (errorMessage: string) => {
      message.error(errorMessage);
    };

    await removeAccount({
      chartId: chartId,
      accountId: id,
      onSuccess,
      onError,
    });
  };

  const columns = useMemo(() => {
    return accountsTableColumns({
      onUpdateAccount: openUpdateAccountModal,
      onRemoveAccount: handleRemoveAccount,
      isRemoveAccountLoading,
    }) as Column<AccountsTableData>[];
  }, [accounts]);

  const expandedRows = useMemo(() => {
    if (data) {
      const hash: Record<string, boolean> = {};
      const d = data;
      if (d.length > 0) {
        d.forEach((_: any, index: number) => {
          hash[index] = true;
        });
      }
      return hash;
    }
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<AccountsTableData>(
      {
        columns,
        data,
        initialState: {
          expanded: expandedRows,
        },
      },
      useExpanded
    );

  return (
    <Card title="Accounts" withHeader>
      <Styled.Table {...getTableProps()}>
        <Styled.TableHead>
          {headerGroups.map((headerGroup) => (
            <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Styled.TableHeader {...column.getHeaderProps()}>
                  <Styled.TableCellWithFiltersWrapper>
                    {column.render("Header")}
                  </Styled.TableCellWithFiltersWrapper>
                </Styled.TableHeader>
              ))}
            </Styled.TableHeaderRow>
          ))}
        </Styled.TableHead>
        <Styled.TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Styled.TableBodyRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Styled.TableCell
                      {...cell.getCellProps()}
                      style={{ fontWeight: row.depth > 0 ? "normal" : "bold" }}
                    >
                      {cell.render("Cell")}
                    </Styled.TableCell>
                  );
                })}
              </Styled.TableBodyRow>
            );
          })}
        </Styled.TableBody>
      </Styled.Table>
      <AccountModalUpdate
        chartId={chartId}
        isVisible={showUpdateAccountModal}
        onClose={closeUpdateAccountModal}
        account={selectedAccount}
      />
    </Card>
  );
}
