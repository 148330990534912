import { SidebarProps } from "@synerise/ds-layout";

import { GovernanceInnerMenu } from "./GovernanceInnerMenu";

export function leftDrawerConfig(): SidebarProps {
  return {
    content: <GovernanceInnerMenu />,
    opened: true,
    onChange: () => {},
    width: 259,
  };
}
