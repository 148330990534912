export const theme = {
  colors: {
    link: "#0b68ff",
    black: "#000000",
    blue_800: "#0C1727",
    blue_700: "#1D3349",
    blue_500: "#1F5E95",
    blue_300: "#7893B2",
    blue_200: "#B6BFDD",
    green_700: "#468B65",
    green_500: "#4DB27B",
    green_300: "#00CC83",
    green_200: "#CCE9DE",
    red_500: "#E36E6E",
    gray_800: "#384350",
    gray_700: "#3D4552",
    gray_400: "#8E8E8E",
    gray_300: "#DADADA",
    gray_200: "#E0E0E0",
    gray_100: "#EFEFEF",
    white: "#FFFFFF",
  },
  fontWeight: {
    regular: 400,
    normal: 500,
    semi_bold: 600,
    bold: 700,
    black: 900,
  },
  fontSize: {
    xs: "0.6rem",
    s: "0.8rem",
    m: "1rem",
    lg: "1.2rem",
    xl: "1.4rem",
  },
  spacing: {
    s: "0.5rem",
    m: "1rem",
    l: "2rem",
    xl: "3rem",
  },
};
