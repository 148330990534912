import Button from "@synerise/ds-button";
import Icon, { EditM, ShowM, ShowRemoveM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { useDashboard } from "../hooks/useDashboard";
import { useGridLayout } from "../hooks/useGridLayout";

export function DashboardPageHeaderRightSideContent() {
  const { inEditMode, toggleEditMode, toggleDashboardViewsDrawer } = useDashboard();
  const { saveLayout, resetLayout, inPreviewMode, toggleInPreviewMode } = useGridLayout();

  if (inEditMode) {
    return (
      <>
        <Popconfirm
          title={`Are you sure you want to reset the changes made in dashboard?`}
          onConfirm={() => resetLayout()}
          cancelText="No"
          okText="Yes"
          okButtonProps={{
            danger: true,
          }}
        >
          <Button>Cancel changes</Button>
        </Popconfirm>
        <Button
          mode="icon-label"
          type="secondary"
          icon={<Icon size={20} component={inPreviewMode ? <ShowRemoveM /> : <ShowM />} />}
          onClick={() => toggleInPreviewMode()}
        >
          {inPreviewMode ? "Close Preview" : "Preview"}
        </Button>
        <Button type="primary" onClick={() => saveLayout()}>
          Save View
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        mode="icon-label"
        icon={<Icon component={<EditM />} size={20} />}
        onClick={() => toggleEditMode()}
      >
        Edit Dashboard
      </Button>
      <Button type="primary" onClick={() => toggleDashboardViewsDrawer()}>
        Load Views
      </Button>
    </>
  );
}
