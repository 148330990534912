import {  useState } from "react";

import Checkbox from "@synerise/ds-checkbox";
import Icon, { TagM} from "@synerise/ds-icon";
import DSMenu from "@synerise/ds-menu";

import { CurrentReportTag } from "../../hooks/useReportBuilder";
import * as Styled from './ReportSettingsDrawer.styles'

type ReportsSettingsTagsSectionProps = {
  tags: CurrentReportTag[];
  onTagSelectChange: (id: number, checked: boolean) => void;
};

export function ReportsSettingsTagsSection({
  tags,
  onTagSelectChange,
}: ReportsSettingsTagsSectionProps) {
  const [tagsOnHover, setTagsOnHover] = useState<CurrentReportTag[]>([]);

  const handleTagClick = (item: CurrentReportTag) => {
    onTagSelectChange(item.id, !item.checked);
  };


  const handleMouseOverItem = (item: CurrentReportTag) => {
    setTagsOnHover((prevState) => [...prevState, item]);
  };

  const handleMouseOutItem = (item: CurrentReportTag) => {
    setTagsOnHover((prevState) => prevState.filter((i) => i.id !== item.id));
  };


  return (
    <Styled.Container>
      <DSMenu>
        {tags.map((tag) => (
          <DSMenu.Item
            key={tag.id}
            prefixel={
              tag.checked ? (
                <Checkbox checked />
              ) : tagsOnHover.includes(tag) ? (
                <Checkbox />
              ) : (
                <Icon component={<TagM />} />
              )
            }
            text={tag.title}
            onMouseOver={() => handleMouseOverItem(tag)}
            onMouseOut={() => handleMouseOutItem(tag)}
            onClick={() => handleTagClick(tag)}
          />
        ))}
      </DSMenu>
    </Styled.Container>
  );
}
