import { DatePicker } from "antd";
import moment from "moment";
import "./antd-datepicker.css";

const { RangePicker } = DatePicker;

export type DateRangePickerPeriodicity = "daily" | "month" | "week" | "quarter" | "year";

type DateRangePickerProps = {
  startDate: Date | null;
  endDate: Date | null;
  onRangeDateChange: (startDate: Date | null, endDate: Date | null) => void;
  periodicity?: DateRangePickerPeriodicity;
};

export function DateRangePicker({
  startDate,
  endDate,
  periodicity,
  onRangeDateChange,
}: DateRangePickerProps) {
  return (
    <RangePicker
      picker={!periodicity || periodicity === "daily" ? undefined : periodicity}
      value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
      onChange={(dates) => {
        if (dates && dates[0] && dates[1]) {
          onRangeDateChange(new Date(dates[0].toDate()), new Date(dates[1].toDate()));
        } else {
          onRangeDateChange(null, null);
        }
      }}
    />
  );
}
