import Icon, { FolderM } from "@synerise/ds-icon";
import { ItemProps } from "@synerise/ds-manageable-list/dist/Item/Item.types";

import { DashboardResponseShort } from "../../dashboard.types";

export function mapDashboardResponseToItem(
  dashboardResponse: DashboardResponseShort[]
): ItemProps[] {
  return dashboardResponse.map((dashboard) => ({
    id: String(dashboard.id),
    name: dashboard.name,
    canAdd: false,
    canUpdate: true,
    canDelete: true,
    icon: <Icon component={<FolderM />} />,
    created: dashboard.created_at,
    user: {
      firstname: "fake name",
    },
  }));
}
