import Button from "@synerise/ds-button";

import * as Styled from "./ReportEditModalFooter.styles";

type ReportEditModalFooterProps = {
  onCancel: () => void;
  formId: string;
};

export function ReportEditModalFooter({ onCancel, formId }: ReportEditModalFooterProps) {
  return (
    <Styled.Container>
      <Button type="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" htmlType="submit" form={formId}>
        Save Edit
      </Button>
    </Styled.Container>
  );
}
