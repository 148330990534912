import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { REPORTS_QUERY_KEY } from "../reports.config";
import { ReportService } from "../reports.services";
import { GridLayoutProvider } from "./hooks/useGridLayout";
import { ReportBuilderProvider } from "./hooks/useReportBuilder";
import { ReportBuilderLayout } from "./ReportBuilderLayout";
import { mapReportResponseToReportLayout } from "./ReportBuilderUtils";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";  
import "./react-grid-layout.css";

export function ReportBuilder() {
  const { id } = useParams<{ id?: string }>();
  const { data: loadedReport } = useQuery(
    [REPORTS_QUERY_KEY, `report-${id}`],
    async () => {
      if (id) {
        try {
          return await ReportService.fetchOne(id);
        } catch (e) {
          return undefined;
        }
      }
    },
    {
      enabled: !!id,
    }
  );

  return (
    <ReportBuilderProvider loadedReport={loadedReport} >
      <GridLayoutProvider
        loadedReport={loadedReport && mapReportResponseToReportLayout(loadedReport)}
      >
        <ReportBuilderLayout editingReport={loadedReport} />
      </GridLayoutProvider>
    </ReportBuilderProvider>
  );
}
