import { SidebarProps } from "@synerise/ds-layout";

import { DataManagementInnerMenu } from "./DataManagementInnerMenu";

export const leftLayoutContent: SidebarProps = {
  content: <DataManagementInnerMenu />,
  opened: true,
  onChange: () => {},
  width: 259,
};
