import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, useRowSelect, useTable } from "react-table";

import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";

import { EmptyState } from "@/components/EmptyState";
import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import * as Styled from "@/components/Table/styles";

import { KPIGroupCreateModal } from "../groups/KPIGroupCreateModal";
import { KPIEditModal } from "./components/KPIEditModal";
import { KPILibraryHeader } from "./components/KPILibraryHeader";
import { KPIModalImport } from "./components/KPIModalImport";
import { useKpi } from "./hooks/useKpi";
import { useKpis } from "./hooks/useKpis";
import { tableColumns } from "./kpi-library.columns";
import { KPIsLibraryTableData } from "./kpi-library.types";
import { mapKpiResponseToTable } from "./utils/mapKpiResponseToTable";

export function KPILibrary() {
  const [isKPIGroupModalOpen, setIsKPIGroupModalOpen] = useState(false);
  const [isKpiImportModalOpen, setIsKpiImportModalOpen] = useState(false);
  const [isKpiEditModalOpen, setIsKpiEditModalOpen] = useState(true);
  const [ḱpiEditingSelected, setKpiEditingSelected] = useState<KPIsLibraryTableData | null>(null);
  const { kpis, kpisLoading } = useKpis({
    show_values: false,
  });
  const { removeKpi, removeKpiLoading } = useKpi();

  function handleCloseKpiImportModal() {
    setIsKpiImportModalOpen(false);
  }
  function handleOpenKpiImportModal() {
    setIsKpiImportModalOpen(true);
  }

  function handleCloseKpiEditModal() {
    setIsKpiEditModalOpen(false);
    setKpiEditingSelected(null);
  }

  async function handleDeleteKPIMeta(id: number) {
    const onError = (msg: string) => {
      message.error(msg);
    };
    const onSuccess = () => {
      message.success("KPI deleted");
    };
    await removeKpi(id, onSuccess, onError);
  }

  function handleEditKPIMeta(kpi: KPIsLibraryTableData) {
    setKpiEditingSelected(kpi);
    setIsKpiEditModalOpen(true);
  }

  const tableData = useMemo(() => {
    return mapKpiResponseToTable(kpis);
  }, [kpis]);

  const columns = useMemo(() => {
    return tableColumns({
      isRemovingKPI: removeKpiLoading,
      onRemoveKPI: handleDeleteKPIMeta,
      onEditKPI: handleEditKPIMeta,
    }) as Column<KPIsLibraryTableData>[];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: tableData,
    },
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  const navigate = useNavigate();

  return (
    <>
      <Layout
        header={
          <KPILibraryHeader
            onToggleKPIGroupModal={() => setIsKPIGroupModalOpen((prevState) => !prevState)}
            onImportKpiClick={handleOpenKpiImportModal}
          />
        }
      >
        <Card>
          {kpisLoading ? (
            <Loader label="Loading KPIs. Just a second" labelPosition="bottom" />
          ) : kpis.length < 1 ? (
            <>
              <EmptyState
                title="Create a new KPI"
                text="Currently you have no KPI. You can create a new KPI Meta by clicking on the button below."
                buttonLabel="Create new KPI"
                onClick={() => {
                  navigate("/kpis/library/create");
                }}
              />
            </>
          ) : (
            <Styled.Table {...getTableProps()}>
              <Styled.TableHead>
                {headerGroups.map((headerGroup) => (
                  <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <Styled.TableHeader {...column.getHeaderProps()}>
                        <Styled.TableCellWithFiltersWrapper>
                          {column.render("Header")}
                        </Styled.TableCellWithFiltersWrapper>
                      </Styled.TableHeader>
                    ))}
                  </Styled.TableHeaderRow>
                ))}
              </Styled.TableHead>
              <Styled.TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <Styled.TableBodyRow {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <Styled.TableCell {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </Styled.TableCell>
                        );
                      })}
                    </Styled.TableBodyRow>
                  );
                })}
              </Styled.TableBody>
            </Styled.Table>
          )}
        </Card>
      </Layout>
      {ḱpiEditingSelected && (
        <KPIEditModal
          isVisible={isKpiEditModalOpen}
          onClose={handleCloseKpiEditModal}
          kpi={ḱpiEditingSelected}
        />
      )}
      <KPIGroupCreateModal
        visible={isKPIGroupModalOpen}
        onClose={() => setIsKPIGroupModalOpen((prevState) => !prevState)}
      />
      <KPIModalImport isVisible={isKpiImportModalOpen} onClose={handleCloseKpiImportModal} />
    </>
  );
}
