import styled from "styled-components";

export const Container = styled.div`
  padding: 12px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CommentInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #a7a7a7;
  font-size: 0.75rem;
`;

export const Content = styled.div`
  margin-top: 0.5rem;
`;
