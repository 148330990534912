import { BoardPostCommentShortResponse } from "@/modules/governance/governance.services.types";

import { PostComment } from "../PostComment";
import * as Styled from "./styles";

type PostCommentsProps = {
  comments: BoardPostCommentShortResponse[];
};

export function PostComments({ comments }: PostCommentsProps) {
  return (
    <Styled.Container>
      <Styled.Heading>Comments</Styled.Heading>
      {comments.map((comment) => (
        <PostComment key={comment.id} data={comment} />
      ))}
    </Styled.Container>
  );
}
