import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & .post-details {
    margin-top: 0.5rem;
    display: flex;
    gap: 1rem;
    color: #a7a7a7;
    align-items: center;
    font-size: 0.75rem;
  }

  & .post-details__text {
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .post-title {
    font-size: 0.9rem;
    color: black;
    font-weight: bold;
  }

  & .tag {
    pointer-events: none !important;
  }
`;
