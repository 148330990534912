import Card from "@synerise/ds-card";
import Skeleton from "@synerise/ds-skeleton";
import SkeletonAvatar from "@synerise/ds-skeleton/dist/SkeletonAvatar/SkeletonAvatar";

import * as Styled from "./styles";

export function PostSkeleton() {
  return (
    <Card>
      <Styled.PostHeader>
        <SkeletonAvatar />
        <Styled.PostHeaderInfo>
          <Skeleton width="M" size="S" />
        </Styled.PostHeaderInfo>
      </Styled.PostHeader>
      <Skeleton />
    </Card>
  );
}
