import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { TrashM, EditM, ShareM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { StarRate } from "@/components/StarRate";
import { TextCell } from "@/components/Table/Cells/HeaderCell";
import { StatusCell } from "@/components/Table/Cells/StatusCell";
import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as Styled from "@/components/Table/styles";

import { KPIsLibraryTableData } from "./kpi-library.types";

type TableColumnsProps = {
  onEditKPI: (kpi: KPIsLibraryTableData) => void;
  onRemoveKPI: (id: number) => Promise<void>;
  isRemovingKPI: boolean;
};

export const tableColumns = ({ onEditKPI, onRemoveKPI, isRemovingKPI }: TableColumnsProps) => {
  const columns: Column<KPIsLibraryTableData>[] = [
    {
      Header: "KPI",
      accessor: "title",
      Cell: ({ row }) => <UserTypeCell type={row.original.title} showAvatar avatarKind="sunset" />,
    },
    {
      Header: <TextCell textAlign="center">GROUP</TextCell>,
      accessor: "group",
      Cell: ({ row }) => <TextCell textAlign="center">{row.original.group}</TextCell>,
    },
    {
      Header: "IMPORTANCE",
      accessor: "importance",
      id: "importance",
      disableFilters: true,
      defaultCanSort: false,
      defaultCanGroupBy: false,
      Cell: ({ value }) => {
        if (!value) return null;
        return <StarRate rate={value} />;
      },
    },
    {
      Header: <TextCell textAlign="center">PERIODICITY</TextCell>,
      accessor: "periodicity",
      Cell: ({ row }) => <TextCell textAlign="center">{row.original.periodicity}</TextCell>,
    },
    {
      Header: "SHARED",
      accessor: "shared",
      Cell: ({ row }) => (
        <StatusCell status={row.original.shared} activeText="Shared" inactiveText="Not Shared" />
      ),
    },
    {
      Header: "ACTIVE",
      accessor: "active",
      Cell: ({ value }) => (
        <StatusCell status={value} activeText="Active" inactiveText="Not Active" />
      ),
    },
    {
      Header: <TextCell textAlign="center">CREATED AT</TextCell>,
      accessor: "createdAt",
      Cell: ({ value }) => <TextCell textAlign="center">{value}</TextCell>,
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => (
        <Styled.RowActionButtons>
          <Button mode="single-icon" disabled>
            <Icon color="#C4C4C4" component={<ShareM />} />
          </Button>
          <Button mode="single-icon" type="ghost-white" onClick={() => onEditKPI(row.original)}>
            <Icon color="#C4C4C4" component={<EditM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete this KPI?`}
            onConfirm={() => onRemoveKPI(row.original.id)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button mode="single-icon" type="ghost-white" loading={isRemovingKPI}>
              <Icon color="#C4C4C4" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </Styled.RowActionButtons>
      ),
    },
  ];

  return columns;
};
