import Button from "@synerise/ds-button";
import Modal from "@synerise/ds-modal";

import * as Styled from "./styles";

type KPIDataSourceStatusModalProps = {
  isVisible: boolean;
  onOk: () => void;
};

export function KPIDataSourceStatusModal({ isVisible, onOk }: KPIDataSourceStatusModalProps) {
  return (
    <Modal
      visible={isVisible}
      footer={null}
      title={<Styled.Title>Data Sources &amp; Integrations</Styled.Title>}
      closable={false}
    >
      <Styled.Content>
        <p>
          Currently, integrations &amp; data source connections are custom built. Let us know which
          integrations your organization is interested in.
        </p>
        <Button type="primary" onClick={onOk}>
          Understood
        </Button>
      </Styled.Content>
    </Modal>
  );
}
