import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: -32px;
  left: 0;
  cursor: pointer;
  visibility: visible;

  display: flex;
  gap: 5px;
  border-radius: 5px 5px 0 0;

  background-color: ${({ theme }) => theme.palette["blue-600"]};
  font-size: 1rem;
`;
