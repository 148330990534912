import { v4 as uuidV4 } from "uuid";

import { ForecastData } from "../pages/ForecastOverview/types";

export const inputsMockData: ForecastData = {
  id: uuidV4(),
  periodicity: "monthly",
  groups: [
    {
      id: uuidV4(),
      title: "Inflation",
      periods: [
        {
          date: "2022-06-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: false,
        },
        {
          date: "2022-07-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: false,
        },
        {
          date: "2022-08-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: false,
        },
        {
          date: "2022-09-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: false,
        },
        {
          date: "2022-10-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: false,
        },
        {
          date: "2022-11-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: false,
        },
        {
          date: "2022-12-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: false,
        },
        {
          date: "2023-01-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: true,
        },
        {
          date: "2023-02-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: true,
        },
        {
          date: "2023-03-01",
          value: `${(Math.random() * 100).toFixed(2)}%`,
          isForecast: true,
        },
      ],
    },
    {
      id: uuidV4(),
      title: "USD/EUR",
      periods: [
        {
          date: "2022-06-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-07-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-08-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-09-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-10-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-11-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-12-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2023-01-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: true,
        },
        {
          date: "2023-02-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: true,
        },
        {
          date: "2023-03-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: true,
        },
      ],
    },
    {
      id: uuidV4(),
      title: "USD/YEN",
      periods: [
        {
          date: "2022-06-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-07-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-08-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-09-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-10-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-11-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2022-12-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: false,
        },
        {
          date: "2023-01-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: true,
        },
        {
          date: "2023-02-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: true,
        },
        {
          date: "2023-03-01",
          value: (Math.random() * 10).toFixed(2),
          isForecast: true,
        },
      ],
    },
  ],
};
