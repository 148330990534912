import Button from "@synerise/ds-button";
import Divider from "@synerise/ds-divider";

import * as Styled from "./EditItemDrawer.styles";

type EditItemDrawerProps = {
  onCancel: () => void;
  onSave: () => void;
  children: React.ReactNode;
  cancelButtonDisabled?: boolean;
  saveButtonDisabled?: boolean;
};

export function EditItemDrawer({
  onSave,
  onCancel,
  cancelButtonDisabled = false,
  saveButtonDisabled = false,
  children,
}: EditItemDrawerProps) {
  return (
    <Styled.Container>
      {children}
      <Divider />
      <Styled.Footer>
        <Button type="secondary" onClick={() => onCancel()} disabled={cancelButtonDisabled}>
          Cancel Changes
        </Button>
        <Button type="primary" onClick={() => onSave()} disabled={saveButtonDisabled}>
          Save Changes
        </Button>
      </Styled.Footer>
    </Styled.Container>
  );
}
