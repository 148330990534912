import { SidebarProps } from "@synerise/ds-layout";

import { SettingsInnerMenu } from "./SettingsInnerMenu";

export const LeftLayoutContent: SidebarProps = {
  content: <SettingsInnerMenu />,
  opened: true,
  onChange: () => {},
  width: 259,
};
