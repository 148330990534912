import { RecentActivityItem } from "../RecentActivityItem";
import * as Styled from "./styles";

export function RecentActivitySection() {
  return (
    <Styled.Container>
      <RecentActivityItem />
      <RecentActivityItem />
      <RecentActivityItem />
      <RecentActivityItem />
    </Styled.Container>
  );
}
