import { AxiosRequestConfig, AxiosResponse } from "axios";

import { api } from "@/utils/http";

import { User, UserPatch } from "../users/users.types";
import { UserChangePasswordPayload, PatchUserAccountPayload } from "./account.types";

class Account {
  async getUser(userID: number) {
    const response: AxiosResponse = await api.get(`/users/${userID}`);
    const user: User = response.data;
    return user;
  }

  async createUser(userID: number, userPayload: UserPatch) {
    const response: AxiosResponse = await api.post(`/users/${userID}`, userPayload);
    const user: User = response.data;
    return user;
  }

  async patchUser(userID: number, payload: PatchUserAccountPayload) {
    const response: AxiosResponse = await api.patch(`/users/${userID}`, payload);
    const user: User = response.data;
    return user;
  }

  async deleteUser(userID: number) {
    const response: AxiosResponse = await api.delete(`/users/${userID}`);
    const user: User = response.data;
    return user;
  }

  async changePassword(userID: number, payload: UserChangePasswordPayload) {
    const response: AxiosResponse = await api.patch(`/users/${userID}/password`, payload);
    const data = response.data;
    return data;
  }

  async uploadAvatar(memberId: number, payload: FormData, config?: AxiosRequestConfig) {
    const response: AxiosResponse = await api.post(`/members/${memberId}/avatars`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...config,
    });
    const data = response.data;
    return data;
  }
}

const AccountService = new Account();
export default AccountService;
