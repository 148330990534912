import styled from "styled-components";

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const DateFieldsRow = styled.div`
  display: flex;
  gap: 10px;

  > div {
    width: 150px;
  }
`;

export const SelectFieldRow = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

export const ImportedLedgerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  justify-content: space-between;

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;
