export const kpiRoutes = {
  home: "/kpis",
  main: "/kpis/main",
  dashboard: "/kpis/dashboard",
  table: "/kpis/table",
  tabs: "/kpis/tabs",
  groups: "/kpis/groups",
  tableImportance: "/kpis/table?split=score",
  tableDimension: "/kpis/table?split=dimension",
  library: "/kpis/library",
  group: "/kpis/groups",
  source: "/kpis/sources",
  request: "/kpis/requests",
};
