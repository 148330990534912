import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import message from "@synerise/ds-message";
import Result from "@synerise/ds-result";

import * as Styled from "../login.styles";

export function ActivateStatus() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tokenQuery = searchParams.get("token");
  const statusQuery = searchParams.get("status");
  const confirmedQuery = searchParams.get("confirmed");
  const registeredQuery = searchParams.get("registered");

  const tokenExists = tokenQuery !== null;
  const status = statusQuery !== null && statusQuery.toLowerCase() === "true";
  const isConfirmed = confirmedQuery !== null && confirmedQuery.toLocaleLowerCase() === "true";
  const isRegistered = registeredQuery !== null && registeredQuery.toLocaleLowerCase() === "true";

  useEffect(() => {
    if (!tokenExists) {
      message.error("Token not found");
      navigate("/");
      return;
    }

    if (!status) {
      message.error("Invalid email validation");
      navigate("/");
      return;
    }

    if (!isConfirmed) {
      message.info("You still need to confirm your email address");
      navigate("/activate-email/" + tokenQuery);
      return;
    }

    if (!isRegistered) {
      message.info("You invite has been accepted. You need to register your account");
      navigate("/register/" + tokenQuery);
      return;
    }
  }, [tokenQuery, statusQuery, registeredQuery, confirmedQuery]);

  return (
    <Styled.CardContainer>
      <Card title="Activation" withHeader>
        <Result
          type="success"
          title="Activated"
          description="You have successfully activated your account"
        />
        <Styled.FooterButtonsContainer>
          <Button onClick={() => navigate("/login")}>Back to Login</Button>
        </Styled.FooterButtonsContainer>
      </Card>
    </Styled.CardContainer>
  );
}
