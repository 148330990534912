import styled, { css } from "styled-components";

type TextCellContainerProps = {
  textAlign: "center" | "right" | "left";
};

const TextCellContainer = styled.div<TextCellContainerProps>`
  ${({ textAlign }) => css`
    text-align: ${textAlign};
    flex: 1;
  `}
`;

type TextCellProps = {
  children: React.ReactNode;
  textAlign?: "center" | "right" | "left";
} & Partial<React.CSSProperties>;

export function TextCell({ textAlign = "left", children, ...rest }: TextCellProps) {
  return (
    <TextCellContainer textAlign={textAlign} style={rest}>
      {children}
    </TextCellContainer>
  );
}
