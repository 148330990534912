import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ededed;
  padding: 0.5rem;
  color: #8f8f8f;
  border-radius: 3px;

  & .activity {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  & .date {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;

    & .date--hour {
      color: #a7a7a7;
    }
  }
`;
