// reports sidebar menu

import Divider from "@synerise/ds-divider";
import Icon, {
  UserS,
  FolderAddM,
  FolderM,
  FileTypeTextM,
  ReportM,
  ClockM,
  CheckboxDeafultM,
} from "@synerise/ds-icon";
import DSMenu from "@synerise/ds-menu";

// TODO: Replace divider by solid grey line (instead of dotted)
export function ReviewsSidebarMenu() {
  return (
    <DSMenu style={{ padding: "15px" }}>
      <DSMenu.Item
        prefixel={<Icon component={<UserS />} />}
        onClick={() => {
          console.log("Click!");
        }}
        text="All"
      />

      <DSMenu.Item
        focusable={false}
        prefixel={<Icon component={<ClockM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Recent
      </DSMenu.Item>
      <DSMenu.Item
        prefixel={<Icon component={<ReportM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Draft
      </DSMenu.Item>
      <DSMenu.Item
        prefixel={<Icon component={<ReportM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Private
      </DSMenu.Item>
      <DSMenu.Item
        prefixel={<Icon component={<ReportM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Public
      </DSMenu.Item>
      <DSMenu.Item
        prefixel={<Icon component={<CheckboxDeafultM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Saved
      </DSMenu.Item>
      <Divider style={{ padding: "5px 10px", margin: "7px 0px 0px 0px" }} dashed={false} />
      <DSMenu.Item
        prefixel={<Icon component={<FileTypeTextM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Templates
      </DSMenu.Item>
      <Divider style={{ padding: "5px 10px", margin: "7px 0px 0px 0px" }} dashed={false} />
      <DSMenu.Item
        prefixel={<Icon component={<FolderAddM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Add folder
      </DSMenu.Item>
      <DSMenu.Item
        prefixel={<Icon component={<FolderM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Folder A
      </DSMenu.Item>
      <DSMenu.Item
        prefixel={<Icon component={<FolderM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Folder B
      </DSMenu.Item>
      <DSMenu.Item
        prefixel={<Icon component={<FolderM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Folder C
      </DSMenu.Item>
    </DSMenu>
  );
}
