import { AxiosResponse } from "axios";

import { api } from "../../utils/http";
import { SupportInfosResponse } from "./support-infos.types";

class SupportInfos {
  async list() {
    const response: AxiosResponse = await api.get("/support");
    const data: SupportInfosResponse = response.data;
    return data;
  }
}

export const SupportInfosService = new SupportInfos();
