import { v4 as uuidV4 } from "uuid";

import { JournalTableTableData } from "./types";

const fakeData: JournalTableTableData[] = [
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "Equipment purchase for new plan",
    value: "164.842,01",
    document_id: "#PO-432058",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "Sales lunch with representatives",
    value: "299,23",
    document_id: "#EX-4224",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "New computer for backend developer",
    value: "164.841,01",
    document_id: "#CX-43214",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "Sales lunch with representatives",
    value: "299,23",
    document_id: "#EX-4224",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "New computer for backend developer",
    value: "164.841,01",
    document_id: "#CX-43214",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "Sales lunch with representatives",
    value: "299,23",
    document_id: "#EX-4224",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "New computer for backend developer",
    value: "164.841,01",
    document_id: "#CX-43214",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "Sales lunch with representatives",
    value: "299,23",
    document_id: "#EX-4224",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "New computer for backend developer",
    value: "164.841,01",
    document_id: "#CX-43214",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "Sales lunch with representatives",
    value: "299,23",
    document_id: "#EX-4224",
  },
  {
    id: uuidV4(),
    date: "15/Jan/2021",
    description: "New computer for backend developer",
    value: "164.841,01",
    document_id: "#CX-43214",
  },
];

export default fakeData;
