import { api } from "@/utils/http";

import {
  ReportResponse,
  ReportCreatePayload,
  ReportUpdatePayload,
  ReportResponseShort,
  ReportTagAddPayload,
} from "./reports.types";

export class ReportService {
  static async fetchAll() {
    const response = await api.get<ReportResponseShort[]>("/reports");
    return response.data;
  }

  static async fetchOne(id: string) {
    const response = await api.get<ReportResponse>(`/reports/${id}`);
    return response.data;
  }

  static async create(payload: ReportCreatePayload) {
    const response = await api.post<ReportResponseShort>("/reports", payload);
    return response.data;
  }

  static async update(id: number, payload: ReportUpdatePayload) {
    const response = await api.patch<ReportResponseShort>(`/reports/${id}`, payload);
    return response.data;
  }

  static async delete(id: number) {
    const response = await api.delete<ApiStatusResponse>(`/reports/${id}`);
    return response.data;
  }

  static async postReportTags(reportId: number, payload: ReportTagAddPayload) {
    const response = await api.post<ApiStatusResponse>(`/reports/${reportId}/tags`, payload);
    return response.data;
  }

  static async deleteReportTag(reportId: number, tagId: number) {
    const response = await api.delete<ApiStatusResponse>(`/reports/${reportId}/tags/${tagId}`);
    return response.data;
  }
}
