import styled from "styled-components";

export const TableOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
`;

export const TableCell = styled.td<{ darker?: boolean }>`
  color: #616161;
  padding: 5px 5px 0 5px;
  font-weight: 400;
  background-color: ${({ darker }) => (darker ? "#f5f5f5" : "none")};
`;
