import Button from "@synerise/ds-button";
import Modal from "@synerise/ds-modal";

type CancelEditModalProps = {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function CancelEditModal({ visible, onConfirm, onCancel }: CancelEditModalProps) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onConfirm}
      size="small"
      width={100}
      closable
      footer={
        <div>
          <Button onClick={onCancel} type="secondary">
            Keep Changes
          </Button>
          <Button onClick={onConfirm} type="custom-color" color="red">
            Discard Changes
          </Button>
        </div>
      }
    >
      <p style={{ fontSize: "18px" }}>The current changes has not been saved</p>
      <p style={{ fontSize: "16px" }}>Are you sure you want to discard the changes?</p>
    </Modal>
  );
}
