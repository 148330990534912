import styled from "styled-components";

import Icon, { InboxNoResultsL } from "@synerise/ds-icon";

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
`;

export function AccountMappingDetailsEmptyTable() {
  return (
    <>
      <Icon component={<InboxNoResultsL />} color={"#AEAEAE"} size={50} />
      <h3>No account in target chart of accounts found</h3>
    </>
  );
}
