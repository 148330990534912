import styled from "styled-components";

export const DrawerHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;

export const ViewItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ViewItemContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
