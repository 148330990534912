export const REPORTS_QUERY_KEY = 'reports';
export const REPORTS_TAGS_QUERY_KEY = 'reports-tags';

export const reportsData = {
  home: {
    path: "/reports",
  },
  list: {
    path: "/reports/list",
    createPath: "/reports/builder",
  },
};
