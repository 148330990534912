import { DataSource, Periodicity } from "../kpi.types";

type PeriodicityOption = {
  label: string;
  value: Periodicity;
};

export const periodicityOptions: PeriodicityOption[] = [
  {
    label: "Quarter",
    value: "quarterly",
  },
  {
    label: "Month",
    value: "monthly",
  },
  {
    label: "Year",
    value: "yearly",
  },
];

export const groupByColumnIDOptions = [
  {
    value: "group.title",
    label: "Group",
  },
  {
    value: "importance",
    label: "Importance",
  },
];

type ValueTypeOption = {
  label: string;
  value: DataSource;
};

export const valueTypesOptions: ValueTypeOption[] = [
  {
    label: "Actual",
    value: "actual",
  },
  {
    label: "Budget",
    value: "budget",
  },
  {
    label: "Forecast",
    value: "forecast",
  },
  {
    label: "Target",
    value: "target",
  },
];
