import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

export function KPIRequests() {
  return (
    <>
      <PageHeader
        title="Data Requests"
        onGoBack={() => console.log("Back!")}
        rightSide={
          <>
            <Button type="secondary">Go To Library</Button>
            <Button type="primary">Create new request</Button>
          </>
        }
      />
      <Layout>Content</Layout>
    </>
  );
}
