import { UserAvatar } from "@synerise/ds-avatar";

import * as Styled from "./styles";

export function MeetingTimelineItem() {
  return (
    <Styled.Container>
      <Styled.Event>
        <UserAvatar />
        <Styled.EventDetail>
          <Styled.EventAuthor>Julia Locke</Styled.EventAuthor>{" "}
          <Styled.EventDescription>confirmed presence on this meeting</Styled.EventDescription>
        </Styled.EventDetail>
      </Styled.Event>
      <Styled.EventDate>Aug/10th @ 10h32</Styled.EventDate>
    </Styled.Container>
  );
}
