import { UserAvatar } from "@synerise/ds-avatar";

import * as Styled from "./styles";

export function MeetingDescriptionHeader() {
  return (
    <Styled.Container>
      <UserAvatar size="medium" />
      <div className="post-details">
        <span>John Smith, CEO</span>
        <span>Created At Oct, 12 @ 12h32</span>
      </div>
    </Styled.Container>
  );
}
