const settingsPrefix = "/settings";

type SettingsMenuRoute = {
  id: string;
  title: string;
  path: string;
};

type SettingsMenuGroup = {
  id: string;
  title: string;
  routes: SettingsMenuRoute[];
};

type SettingsMenuData = {
  title: string;
  homePath: string;
  menuGroups: SettingsMenuGroup[];
};

export const settingsMenuData: SettingsMenuData = {
  title: "Settings",
  homePath: `${settingsPrefix}/home`,
  menuGroups: [
    {
      id: "settings-account",
      title: "MY ACCOUNT",
      routes: [
        {
          id: "settings-account-profile",
          title: "Profile",
          path: `${settingsPrefix}/account`,
        },
        {
          id: "settings-account-security",
          title: "Security",
          path: `${settingsPrefix}/account/security`,
        },
        {
          id: "settings-accountnotifications",
          title: "Notifications",
          path: `${settingsPrefix}/account/notifications`,
        },
      ],
    },
    {
      id: "settings-organization",
      title: "ORGANIZATION",
      routes: [
        {
          id: "settings-organization-global",
          title: "Profile",
          path: `${settingsPrefix}/organization`,
        },
        {
          id: "settings-organization-preferences",
          title: "Preferences",
          path: `${settingsPrefix}/organization/preferences`,
        },
        {
          id: "settings-organization-appearance",
          title: "Appearance",
          path: `${settingsPrefix}/organization/appearance`,
        },
      ],
    },
    {
      id: "settings-users",
      title: "USERS",
      routes: [
        {
          id: "settings-users-list",
          title: "Users",
          path: `${settingsPrefix}/users`,
        },
        {
          id: "settings-users-access",
          title: "Access Groups",
          path: `${settingsPrefix}/access`,
        },
        {
          id: "settings-users-providers",
          title: "Identity Providers",
          path: `${settingsPrefix}/providers`,
        },
      ],
    },
  ],
};
