import { api } from "@/utils/http";

import {
  DataSource,
  KpiCreatePayload,
  KpiResponse,
  KpiUpdatePayload,
  Periodicity,
} from "./kpi.types";

export type FetchParams = {
  show_values?: boolean;
  entity_id?: number;
  min_importance?: number;
  is_active?: boolean;
  is_calculated?: boolean;
  version?: number;
  data_sources?: DataSource[];
  series_ids?: number[];
  periodicity?: Periodicity;
  start_date?: string;
  group_id?: number;
  end_date?: string;
  has_comments?: boolean;
};

export class KPIService {
  static async fetchAll(params?: FetchParams): Promise<KpiResponse[]> {
    const response = await api.get<KpiResponse[]>("/kpis", {
      params: {
        ...params,
        data_sources: params && params.data_sources ? params.data_sources.toString() : undefined,
      },
    });
    return response.data;
  }

  static async fetchOne(id: number, params?: FetchParams): Promise<KpiResponse> {
    const response = await api.get<KpiResponse>(`/kpis/${id}`, {
      params,
    });
    return response.data;
  }

  static async create(payload: KpiCreatePayload): Promise<KpiResponse> {
    const response = await api.post<KpiResponse>("/kpis", payload);
    return response.data;
  }

  static async update(id: number, payload: KpiUpdatePayload): Promise<KpiResponse> {
    const response = await api.patch<KpiResponse>(`/kpis/${id}`, payload);
    return response.data;
  }

  static async delete(id: number): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(`/kpis/${id}`);
    return response.data;
  }

  static async uploadFile(file: File): Promise<ApiStatusResponse> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await api.post<ApiStatusResponse>(`/kpisUpload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }

  //   static async uploadManyFiles(id: number, files: File[]): Promise<void> {
  //     const formData = new FormData();
  //     files.forEach((file) => formData.append("files[]", file));
  //     await api.post(`/kpis/${id}/upload`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //   }
}
