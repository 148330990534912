import { MeetingAttachmentItem } from "../MeetingAttachmentsItem";
import * as Styled from "./styles";

export function MeetingAttachments() {
  return (
    <Styled.Container>
      <MeetingAttachmentItem />
      <MeetingAttachmentItem />
      <MeetingAttachmentItem />
    </Styled.Container>
  );
}
