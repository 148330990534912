import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #a7a7a7;
  gap: 0.5rem;
`;

export const Header = styled.header`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Title = styled.span`
  color: black;
  font-weight: bold;
  font-size: 0.8rem;
`;

export const Description = styled.p``;
