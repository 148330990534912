import { createColumnHelper } from "@tanstack/react-table";

import { TitleCell } from "@/components/Table/TitleCell";

import { formatPeriod } from "../../utils/formatPeriod";
import { Group, ID, Period } from "./types";

const columnHelper = createColumnHelper<Group>();

type ColumnsProps = {
  periods?: Period[];
  onTitleClick: (rowId: ID) => void;
};

export function columns({ periods, onTitleClick }: ColumnsProps) {
  return [
    columnHelper.accessor("title", {
      header: () => "Series",
      cell: ({ getValue, row }) => (
        <TitleCell onClick={() => onTitleClick(row.id)}>{getValue()}</TitleCell>
      ),
    }),
    columnHelper.group({
      id: "periods",
      header: () => "Periods",
      columns: periods
        ? [
            ...periods.map((period, index) =>
              columnHelper.accessor("periods", {
                id: period.date,
                header: () => formatPeriod(new Date(period.date)),
                cell: ({ row }) => {
                  const periodMatch = row.original.periods[index];
                  return periodMatch ? periodMatch.value : "-";
                },
                meta: {
                  isForecast: period.isForecast,
                },
              })
            ),
          ]
        : [],
    }),
  ];
}
