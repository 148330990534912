import React from "react";

import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { CancelEditModal } from "@/components/LayoutBuilder/CancelEditModal/CancelEditModal";
import { EditItemDrawer } from "@/components/LayoutBuilder/EditItemDrawer/EditItemDrawer";
import { getItemsConfig } from "@/components/LayoutBuilder/GridItems/GridItems.config";

import { DashboardPageHeaderRightSideContent } from "./components/DashboardPageHeaderRightSideContent";
import { DashboardViewModalFormCreate } from "./components/DashboardViewModalFormCreate/DashboardViewModalFormCreate";
import { DashboardViewsDrawer } from "./components/DashboardViewsDrawer/DashboardViewsDrawer";
import { GridItemsDrawer } from "./components/GridItemsDrawer/GridItemsDrawer";
import { gridItemsLayoutStyles } from "./dashboard.config";
import { useDashboard } from "./hooks/useDashboard";
import { useGridLayout } from "./hooks/useGridLayout";

type DashboardLayoutProps = {
  children: React.ReactNode;
};

export function DashboardLayout({ children }: DashboardLayoutProps) {
  const {
    gridItemsDrawerOpened,
    editItemDrawerOpened,
    toggleEditItemDrawer,
    toggleGridItemsDrawer,
    inEditMode,
  } = useDashboard();
  const {
    indexItemSelected,
    cancelEdit,
    editingItem,
    updateEditingItem,
    saveTemporaryEditChanges,
    itemHasBeenModified,
    showCancelEditModal,
    closeCancelEditModal,
    confirmCancelEdit,
    inPreviewMode,
  } = useGridLayout();

  const itemsConfig = getItemsConfig({
    updateItem: updateEditingItem,
    editingItem,
  });

  function handleSaveTemporaryLayout() {
    saveTemporaryEditChanges();
    message.success("Saved");
  }

  function handleCancelTemporaryEdit() {
    cancelEdit();
  }

  return (
    <Layout
      renderLeftSidebarControls={inEditMode}
      renderRightSidebarControls={inEditMode}
      fullPage={true}
      header={<PageHeader title="Dashboard" rightSide={<DashboardPageHeaderRightSideContent />} />}
      left={
        inEditMode && !inPreviewMode
          ? {
              content: <GridItemsDrawer />,
              opened: gridItemsDrawerOpened,
              onChange: toggleGridItemsDrawer,
            }
          : undefined
      }
      right={
        inEditMode && indexItemSelected && !inPreviewMode
          ? {
              content: (
                <EditItemDrawer
                  onSave={handleSaveTemporaryLayout}
                  onCancel={handleCancelTemporaryEdit}
                  cancelButtonDisabled={!itemHasBeenModified}
                  saveButtonDisabled={!itemHasBeenModified}
                >
                  {editingItem && (itemsConfig[editingItem.type].editDrawerComponent as any)}
                </EditItemDrawer>
              ),
              opened: editItemDrawerOpened,
              onChange: toggleEditItemDrawer,
            }
          : undefined
      }
      styles={{
        ...gridItemsLayoutStyles,
      }}
    >
      {children}
      <DashboardViewsDrawer />
      <DashboardViewModalFormCreate />
      <CancelEditModal
        visible={showCancelEditModal}
        onCancel={closeCancelEditModal}
        onConfirm={confirmCancelEdit}
      />
    </Layout>
  );
}
