import { format } from "date-fns";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { DSCountryCodes } from "@/components/Table/Cells/CountryCell";
import { Country } from "@/core/support-infos/support-infos.types";
import { EntitiesTableData } from "@/modules/data-management/entities/entities.columns";
import { EntityService } from "@/modules/data-management/entities/entities.services";
import {
  EntityCreatePayload,
  EntityUpdatePayload,
} from "@/modules/data-management/entities/entities.types";
import { Entity } from "@/modules/data-management/entities/entities.types";
import { queryClient } from "@/utils/queryClient";

const ENTITIES_QUERY_KEY = "entities";

const mapEntityToTableData = (entity: Entity, countries: Country[]) => {
  const findCountryCode = (countryCode?: string) => {
    const country = countries.find((country) => country.code === countryCode);

    return DSCountryCodes.find((code) => country?.alpha2 === code);
  };

  const getEntityType = (isGroup?: boolean, isCollection?: boolean) => {
    if (isGroup) return "Group";
    if (isCollection) return "Collection";
    return "Company";
  };

  const entityToTableData: EntitiesTableData = {
    id: entity.id,
    name: entity.name ?? "",
    type: getEntityType(entity.is_group, entity.is_collection),
    country: findCountryCode(entity.country_code),
    currency: entity.currency_code ?? "",
    createdAt: format(new Date(entity.created_at), "MM/dd/yyyy"),
  };

  return entityToTableData;
};

export const mapEntitiesToTableData = (entities: Entity[], countries: Country[]) => {
  return entities.map((entity) => mapEntityToTableData(entity, countries));
};

type UseEntitiesReturn = {
  allEntities: Entity[];
  allCompanies: Entity[];
  allEntitiesLoading: boolean;
  createEntity: (payload: CreateEntityProps) => Promise<void>;
  createEntityLoading: boolean;
  updateEntity: (payload: UpdateEntityProps) => Promise<void>;
  updateEntityLoading: boolean;
  removeEntity: (payload: RemoveEntityProps) => Promise<void>;
  getEntityById: (id: number) => Entity | null;
};

type CreateEntityProps = {
  payload: EntityCreatePayload;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type UpdateEntityProps = {
  id: number;
  payload: EntityUpdatePayload;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type RemoveEntityProps = {
  id: number;
  onSuccess: () => void;
  onError: (text: string) => void;
};

export function useEntities(): UseEntitiesReturn {
  const allEntitiesFromQuery = useQuery(ENTITIES_QUERY_KEY, () => EntityService.list());

  const createEntityMutation = useMutation(
    async ({
      payload,
    }: {
      payload: EntityCreatePayload;
      onSuccess: () => void;
      onError: (text: string) => void;
    }) => {
      const data = await EntityService.create(payload);
      return data;
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(ENTITIES_QUERY_KEY);
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const createEntity = async ({ payload, onSuccess, onError }: CreateEntityProps) => {
    await createEntityMutation.mutateAsync({ payload, onSuccess, onError });
  };

  const updateEntityMutation = useMutation(
    async ({
      id,
      payload,
    }: {
      id: number;
      payload: EntityUpdatePayload;
      onSuccess: () => void;
      onError: (text: string) => void;
    }) => {
      await EntityService.update(id, payload);
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(ENTITIES_QUERY_KEY);
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const updateEntity = async ({ id, payload, onSuccess, onError }: UpdateEntityProps) => {
    await updateEntityMutation.mutateAsync({ id, payload, onSuccess, onError });
  };

  const removeEntityMutation = useMutation(
    async ({ id }: { id: number; onSuccess: () => void; onError: (text: string) => void }) => {
      await EntityService.delete(id);
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(ENTITIES_QUERY_KEY);
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const removeEntity = async ({ id, onSuccess, onError }: RemoveEntityProps) => {
    await removeEntityMutation.mutateAsync({ id, onSuccess, onError });
  };

  const getEntityById = (id: number) => {
    if (!allEntitiesFromQuery.data) return null;
    return allEntitiesFromQuery.data.find((entity) => entity.id === id) || null;
  };

  const allEntities = useMemo(() => {
    return allEntitiesFromQuery.data ?? [];
  }, [allEntitiesFromQuery.data]);

  const allEntitiesLoading = useMemo(() => {
    return allEntitiesFromQuery.isLoading;
  }, [allEntitiesFromQuery.isLoading]);

  const allCompanies = useMemo(() => {
    return allEntities.filter((entity) => entity.is_company);
  }, [allEntities]);

  return {
    allEntities,
    allCompanies,
    allEntitiesLoading,
    createEntity,
    createEntityLoading: createEntityMutation.isLoading,
    updateEntity,
    updateEntityLoading: updateEntityMutation.isLoading,
    removeEntity,
    getEntityById,
  };
}
