import styled from "styled-components";

import { theme } from "@/styles/theme";

export const Container = styled.div`
  cursor: pointer;
  transition: all 0.2s ease-in;
  &:hover {
    color: ${theme.colors.link};
  }
`;
