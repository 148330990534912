import { useEffect, useMemo, useState } from "react";
import { Column, useExpanded, useFilters, useGroupBy, useRowSelect, useTable } from "react-table";

import Card from "@synerise/ds-card";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import * as TableStyled from "@/components/Table/styles";


import { tableColumns } from "./financials-ratios.columns";
import {
  periodicityOptions,
  incomeStatementTypeOptions,
  incomeStatementOpticsOptions,
} from "./financials-ratios.config";
import { mockFinancialsIsData, mockFinancialsISValues } from "./financials-ratios.mock.data";
import * as Styled from "./financials-ratios.styles";
import * as FinancialsISTableStyled from "./financials-ratios.styles";
import { FinancialsISTableData, Periodicity } from "./financials-ratios.types";

export function FinancialsRatios() {
  const [periodicity, setPeriodicity] = useState<Periodicity>("month");
  const [incomeStatementType, setIncomeStatementType] = useState("consolidated");
  const [incomeStatementOptic, setIncomeStatementOptic] = useState("time-series");

  const columns = useMemo(() => {
    return tableColumns(mockFinancialsISValues, periodicity) as Column<FinancialsISTableData>[];
  }, [mockFinancialsISValues, periodicity]);

  const tableData = useMemo(() => {
    return mockFinancialsIsData;
  }, [mockFinancialsIsData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    state: { groupBy },
  } = useTable(
    {
      columns,
      data: tableData,
      defaultCanGroupBy: false,
      autoResetExpanded: false,
      initialState: {
        groupBy: ["group.title"],
      },
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [mockFinancialsIsData, groupBy]);

  useMemo(() => toggleAllRowsExpanded(true), [toggleAllRowsExpanded]);

  return (
    <>
      <Card style={{ marginTop: "15px" }}>
        <Styled.TableOptionsContainer>
          <div>
            <Switch label="Adjustments" defaultChecked={true} />
            <Switch label="Vertical Analysis" />
            <Switch label="vs. Budget" />
          </div>
          <div>
            <Select
              defaultValue="time-series"
              value={incomeStatementOptic}
              onChange={(value: any) => setIncomeStatementOptic(value)}
              style={{ minWidth: "150px" }}
            >
              {incomeStatementOpticsOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>

            <Select
              defaultValue="consolidated"
              value={incomeStatementType}
              onChange={(value: any) => setIncomeStatementType(value)}
              style={{ minWidth: "150px" }}
            >
              {incomeStatementTypeOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>

            <Select
              value={periodicity}
              onChange={(value: any) => setPeriodicity(value)}
              style={{ minWidth: "150px" }}
            >
              {periodicityOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Styled.TableOptionsContainer>
      </Card>
      <Card style={{ overflow: "scroll" }}>
        <TableStyled.Table {...getTableProps()}>
          <TableStyled.TableHead>
            {headerGroups.map((headerGroup) => (
              <TableStyled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableStyled.TableHeader
                    {...column.getHeaderProps()}
                    style={{ width: column.width }}
                  >
                    <TableStyled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                    </TableStyled.TableCellWithFiltersWrapper>
                  </TableStyled.TableHeader>
                ))}
              </TableStyled.TableHeaderRow>
            ))}
          </TableStyled.TableHead>
          <TableStyled.TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <FinancialsISTableStyled.TableBodyRow
                  {...row.getRowProps()}
                  isGrouped={row.isGrouped}
                  className={row.isGrouped ? "group-row" : ""}
                >
                  {row.cells.map((cell) => {
                    return (
                      <FinancialsISTableStyled.TableCell {...cell.getCellProps()}>
                        {cell.isGrouped ? (
                          <div {...row.getToggleRowExpandedProps()}>{cell.render("Cell")}</div>
                        ) : cell.isAggregated ? (
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? (
                          <FinancialsISTableStyled.PlaceHolderCell>
                            {row.original.title}
                          </FinancialsISTableStyled.PlaceHolderCell>
                        ) : (
                          cell.render("Cell")
                        )}
                      </FinancialsISTableStyled.TableCell>
                    );
                  })}
                </FinancialsISTableStyled.TableBodyRow>
              );
            })}
          </TableStyled.TableBody>
        </TableStyled.Table>
      </Card>
    </>
  );
}
