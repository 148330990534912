import axios from "axios";

import { api } from "@/utils/http";

import {
  BoardCreatePayload,
  BoardPostCommentCreatePayload,
  BoardPostCommentResponse,
  BoardPostCreatePayload,
  BoardPostResponse,
  BoardResponse,
} from "./governance.services.types";

export class GovernanceServices {
  static async fetchAll() {
    try {
      const response = await api.get<BoardResponse[]>("/boards");
      return response.data;
    } catch (error: any) {
      GovernanceServices.handleError(error);
    }
  }

  static async create(payload: BoardCreatePayload) {
    const response = await api.post<BoardResponse>("/boards", payload);
    return response.data;
  }

  static async remove(boardId: number) {
    const response = await api.delete<ApiStatusResponse>(`/boards/${boardId}`);
    return response.data;
  }

  static async fetchAllPostsByBoard(boardId: number) {
    try {
      const response = await api.get<BoardPostResponse[]>(`/boards/${boardId}/posts`);
      return response.data;
    } catch (error: any) {
      GovernanceServices.handleError(error);
    }
  }

  static async createPost(boardId: number, payload: BoardPostCreatePayload) {
    const response = await api.post<BoardPostResponse>(`/boards/${boardId}/posts`, payload);
    return response.data;
  }

  static async removePost(boardId: number, postId: number) {
    const response = await api.delete<ApiStatusResponse>(`/boards/${boardId}/posts/${postId}`);
    return response.data;
  }

  static async createPostComment(
    boardId: number,
    postId: number,
    payload: BoardPostCommentCreatePayload
  ) {
    const response = await api.post<BoardPostCommentResponse>(
      `/boards/${boardId}/posts/${postId}/comments`,
      payload
    );
    return response.data;
  }

  static handleError(error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return [];
      } else {
        throw new Error(error.response?.data.msg);
      }
    } else {
      throw new Error("Something went wrong");
    }
  }
}
