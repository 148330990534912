import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useRowSelect, useSortBy, Column } from "react-table";
import styled from "styled-components";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { EmptyTable } from "@/components/Table/EmptyTable";
import { SortIcon } from "@/components/Table/SortIcon";
import * as Styled from "@/components/Table/styles";
import { useAccessGroups } from "@/hooks/useAccessGroups";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";
import { tableColumns, RolesTableData } from "./access-groups.table.columns";
import { ModalRemoveAccessGroup } from "./AccessGroupRemoveModal";
import { mapAccessGroupsToTableData } from "./AccessGroupsDataToTableMap";

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

// TODO: Adicionar Avatar para o Grupo de Acesso
// TODO: Adiciona botões de ação para os Grupos de Acesso

export function AccessGroupList() {
  const { allAccessGroups, allAccessGroupsLoading, removeAccessGroup } = useAccessGroups();
  const [deletingAccessGroupName, setDeletingAccessGroupName] = useState<string | null>(null);
  const [deletingAccessGroupID, setDeletingAccessGroupID] = useState<number | null>(null);
  const [modalRemoveAccessGroupVisible, setModalRemoveAccessGroupVisible] = useState(false);

  const navigate = useNavigate();

  const onConfirmRemoveAccessGroup = async () => {
    if (!deletingAccessGroupID) {
      message.info("None access group selected");
      return;
    }

    const onError = (errorMessage: string) => {
      message.error(errorMessage);
    };

    const onSuccess = () => {
      message.success("Access group successfully removed");
      onCloseRemoveAccessGroup();
    };

    await removeAccessGroup({
      id: deletingAccessGroupID,
      onSuccess,
      onError,
    });

    onCloseRemoveAccessGroup();
  };

  const onCloseRemoveAccessGroup = () => {
    setModalRemoveAccessGroupVisible(false);
    setDeletingAccessGroupName(null);
    setDeletingAccessGroupID(null);
  };

  const handleRemoveAccessGroup = async (id: number, name: string) => {
    setDeletingAccessGroupID(id);
    setDeletingAccessGroupName(name);
    setModalRemoveAccessGroupVisible(true);
  };

  const handleViewAccessGroup = (id: number) => {
    navigate(`/settings/access/${id}`);
  };

  const data = useMemo(() => {
    return mapAccessGroupsToTableData(allAccessGroups);
  }, [allAccessGroups]);

  const columns = useMemo(() => {
    return tableColumns({
      onRemove: handleRemoveAccessGroup,
      onViewAccessGroup: handleViewAccessGroup,
    }) as Column<RolesTableData>[];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy,
    useRowSelect
  );

  return (
    <Layout
      left={LeftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate("/settings/home")}
          title="Access Groups"
          rightSide={
            <>
              <SideButtonsContainer>
                <Button onClick={() => navigate("/settings/access/create")} type="primary">
                  Create New Access Group
                </Button>
              </SideButtonsContainer>
            </>
          }
        />
      }
    >
      <Card title="Access Groups List" withHeader>
        {allAccessGroupsLoading ? (
          <Loader label="Loading Access Groups..." labelPosition="bottom" />
        ) : rows.length < 1 ? (
          <EmptyTable
            path={`/settings/access/create`}
            text="No access group found"
            buttonText="Create a new Access Group"
          />
        ) : (
          <Styled.Table {...getTableProps()}>
            <Styled.TableHead>
              {headerGroups.map((headerGroup) => (
                <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <Styled.TableCellWithFiltersWrapper>
                        {column.render("Header")}
                        {column.canSort && (
                          <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                        )}
                      </Styled.TableCellWithFiltersWrapper>
                    </Styled.TableHeader>
                  ))}
                </Styled.TableHeaderRow>
              ))}
            </Styled.TableHead>
            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Styled.TableBodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Styled.TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Styled.TableCell>
                      );
                    })}
                  </Styled.TableBodyRow>
                );
              })}
            </Styled.TableBody>
          </Styled.Table>
        )}
      </Card>
      <ModalRemoveAccessGroup
        visible={modalRemoveAccessGroupVisible}
        accessGroupName={deletingAccessGroupName ?? ""}
        onConfirm={onConfirmRemoveAccessGroup}
        onCancel={onCloseRemoveAccessGroup}
      />
    </Layout>
  );
}
