import Icon, { FolderM, MergeM } from "@synerise/ds-icon";

type TypeIconProps = {
  isDroppable: boolean;
};

export function TypeIcon({ isDroppable }: TypeIconProps) {
  if (isDroppable) {
    return <Icon component={<FolderM />} color={"#CDCDCD"} size={20} />;
  }
  return <Icon component={<MergeM />} color={"#CDCDCD"} size={20} />;
}
