import { Column, SortByFn, FilterProps } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { BookM, Book2M, TrashM, EditM, SearchM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { CheckCell } from "@/components/Table/Cells/CheckCell";
import { TextCell } from "@/components/Table/Cells/HeaderCell";
import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as Styled from "@/components/Table/styles";

import { PeriodicityCell } from "./components/PeriodicityCell/PeriodicityCell";
import { LedgerListTableData } from "./ledger-list.types";

type TableColumnsProps = {
  SelectFilter: (props: FilterProps<LedgerListTableData>) => any;
  dateSort: SortByFn<LedgerListTableData>;
  onViewLedger: (id: number) => Promise<void>;
  onRemoveLedger: (id: number) => Promise<void>;
  onEditLedger: (id: number) => void;
  isRemoveLedgerLoading: boolean;
};

export const tableColumns = ({
  onViewLedger,
  onRemoveLedger,
  onEditLedger,
  SelectFilter,
  isRemoveLedgerLoading,
}: TableColumnsProps) => {
  const columns: Column<LedgerListTableData>[] = [
    {
      Header: "Company",
      Filter: SelectFilter,
      accessor: "company",
      filter: "includes",
      disableSortBy: true,
      Cell: ({ row }) => {
        const company = row.original.company;

        if (!company) {
          return <UserTypeCell type="No Company" />;
        }
        return <UserTypeCell name={company} showAvatar type={"marble"} />;
      },
    },
    {
      Header: "Date",
      id: "date",
      accessor: "dateRaw",
      sortType: "datetime",
      sortDescFirst: true,
      disableFilters: true,
      Cell: ({ row }) => <>{row.original.date}</>,
    },
    {
      Header: "Type",
      accessor: "type",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        if (row.original.type === "Ledger") {
          return <UserTypeCell icon={<BookM />} type={row.original.type} />;
        }

        if (row.original.type === "TrialBalance") {
          return <UserTypeCell icon={<Book2M />} type={row.original.type} />;
        }

        return null;
      },
    },
    {
      Header: <TextCell textAlign="center">Consistency</TextCell>,
      accessor: "consistency",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => <CheckCell checked={row.original.consistency} />,
    },
    {
      Header: <TextCell textAlign="center">Reviewed</TextCell>,
      accessor: "isReviewed",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => <CheckCell checked={row.original.isReviewed} />,
    },
    {
      Header: <TextCell textAlign="center">Periodicity</TextCell>,
      accessor: "periodicity",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => <PeriodicityCell periodicity={row.original.periodicity} />,
    },
    {
      Header: "",
      accessor: "id",
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (
        <Styled.RowActionButtons>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => onViewLedger(row.original.id)}
          >
            <Icon color="#C4C4C4" component={<SearchM />} />
          </Button>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => {
              onEditLedger(row.original.id);
            }}
          >
            <Icon color="#C4C4C4" component={<EditM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete this ledger?`}
            onConfirm={() => onRemoveLedger(row.original.id)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button mode="single-icon" type="ghost-white" loading={isRemoveLedgerLoading}>
              <Icon color="#C4C4C4" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </Styled.RowActionButtons>
      ),
    },
  ];

  return columns;
};
