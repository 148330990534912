import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserAvatar } from "@synerise/ds-avatar";
import DSDropdown from "@synerise/ds-dropdown";
import Icon, { UserS, ArrowLeftRightS, LogoutM, Settings2S } from "@synerise/ds-icon";
import DSMenu from "@synerise/ds-menu";
import { useOnClickOutside } from "@synerise/ds-utils";

import { useAuth } from "@/hooks/useAuth";

export function ProfileItem() {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { selectedMembership } = useAuth();
  const { logoutUser } = useAuth();

  function goToProfile() {
    navigate("/settings/account");
  }

  function close() {
    setShowDropdown(false);
  }

  function open() {
    setShowDropdown(true);
  }

  useOnClickOutside(ref, () => {
    setShowDropdown(false);
  });

  return (
    <DSDropdown
      overlayStyle={{ borderRadius: "3px" }}
      visible={showDropdown}
      placement="bottomLeft"
      overlay={
        <DSDropdown.Wrapper ref={ref}>
          <DSMenu asDropdownMenu={true} style={{ padding: "8px 2px 8px 8px" }}>
            <DSMenu.Item
              prefixel={<Icon component={<UserS />} />}
              onClick={() => {
                goToProfile();
                close();
              }}
            >
              Profile
            </DSMenu.Item>
            <DSMenu.Item prefixel={<Icon component={<Settings2S />} />}>
              Account Settings
            </DSMenu.Item>
            <DSMenu.Item prefixel={<Icon component={<ArrowLeftRightS />} />}>
              Switch Organization
            </DSMenu.Item>
            <DSMenu.Item type="divider" />
            <DSMenu.Item
              prefixel={<Icon component={<LogoutM />} />}
              onClick={() => {
                logoutUser();
                close();
              }}
            >
              Logout
            </DSMenu.Item>
          </DSMenu>
        </DSDropdown.Wrapper>
      }
    >
      <UserAvatar size="small" src={selectedMembership?.preferences?.logo_url} onClick={open} />
    </DSDropdown>
  );
}
