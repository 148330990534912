import { format } from "date-fns";

import { Periodicity } from "../FinancialsRatios/financials-ratios.types";

const formatDateMonth = (date: Date) => {
  return date.toLocaleDateString("en-US", { month: "short", year: "2-digit" });
};

const formatDateQuarter = (date: Date) => {
  return `Q${Math.floor(date.getMonth() / 3) + 1} ${date.getFullYear()}`;
};

const formatDateYear = (date: Date) => {
  return date.getFullYear().toString();
};

const formatDateDay = (date: Date) => {
  return format(date, "dd MMM yyyy");
};
const formatDateWeek = (date: Date) => {
  return `Week ${format(date, "w")}-${format(date, "yyyy")}`;
};

export const formatDateToColumnHeader = (periodicity: Periodicity, date: Date) => {
  switch (periodicity) {
    case "day":
      return formatDateDay(date);
    case "week":
      return formatDateWeek(date);
    case "month":
      return formatDateMonth(date);
    case "quarter":
      return formatDateQuarter(date);
    case "year":
      return formatDateYear(date);
    default:
      return "";
  }
};
