import { useCallback } from "react";
import { useMutation } from "react-query";

import message from "@synerise/ds-message";

import { GovernanceServices } from "../governance.services";
import { BoardPostCommentCreatePayload } from "../governance.services.types";
import { useRefreshPosts } from "./useRefreshPosts";

type UseCreatePostProps = {
  boardId: number;
  postId: number;
};

export const useCreatePostComment = ({ boardId, postId }: UseCreatePostProps) => {
  const refreshPosts = useRefreshPosts();

  const createPostCommentMutation = useMutation(
    (payload: BoardPostCommentCreatePayload) =>
      GovernanceServices.createPostComment(boardId, postId, payload),
    {
      onSuccess: () => {
        message.success("Comment created");
        refreshPosts();
      },
      onError: (error: any) => {
        message.error(error.message);
      },
    }
  );

  const createPostComment = useCallback(async (payload: BoardPostCommentCreatePayload) => {
    createPostCommentMutation.mutate(payload);
  }, []);

  return {
    createPostComment,
    isCreatePostCommentLoading: createPostCommentMutation.isLoading,
  };
};
