import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import CardSelect from "@synerise/ds-card-select";
import Icon, { CompanyM, EditM } from "@synerise/ds-icon";
import InlineEdit from "@synerise/ds-inline-edit";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";

import { useEntities } from "@/hooks/useEntities";
import { useEntityTypes } from "@/hooks/useEntityTypes";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { dataManagementData } from "../data-management.data";
import { SelectCountry } from "./components/SelectCountry";
import { SelectCurrency } from "./components/SelectCurrency";
import * as Styled from "./entities.styles";

type EntityCreateParams = {
  type: string;
};

type EntityCreateFormValues = {
  name: string;
  description: string;
  legal_name?: string;
  document_id?: string;
  fiscal_year?: string;
  isic_code?: number;
  industry_code?: number;
  entity_type_id: number;
  country_code: string;
  currency_code: string;
};

const createEntitySchema = yup.object().shape({
  name: yup.string().required("Title required"),
  description: yup.string().required("Description required"),
  country_code: yup.string().required("Country required"),
  currency_code: yup.string().required("Currency required"),
});

export const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-left: 8px;
  }
`;

export function EntityCreate() {
  const navigate = useNavigate();
  const validate = useValidationSchema(createEntitySchema);
  const { type } = useParams<EntityCreateParams>();
  const [basicInformationsCardOpened, setBasicInformationsCardOpened] = useState(true);
  const [classificationCardOpened, setClassificationCardOpened] = useState(false);
  const [financialInformationCardOpened, setFinancialInformationCardOpened] = useState(false);
  const { allEntityTypes, allEntityTypesLoading } = useEntityTypes();
  const { createEntity, createEntityLoading } = useEntities();
  const {
    countries,
    industriesISIC,
    industriesISICGroups,
    industriesGICS,
    industriesGICSGroups,
    currencies,
  } = useSupportInfos();

  const entityTypeInitialSelected = allEntityTypes.find((entityType) => {
    if (!type) return false;
    return entityType.title.toLocaleLowerCase() === type.toLocaleLowerCase();
  });

  const entityTypeInitialSelectedId = entityTypeInitialSelected
    ? entityTypeInitialSelected.id
    : undefined;

  const onSubmit = async (values: EntityCreateFormValues) => {
    if (!values.entity_type_id) {
      message.warning("Please, choose a entity type");
      return;
    }

    const entityTypeSelected = allEntityTypes.find(
      (entityType) => entityType.id === values.entity_type_id
    );

    const onError = (text: string) => message.error(text);

    const onSuccess = () => {
      message.success("Entity has been created");
      navigate(dataManagementData.entities.path);
    };

    await createEntity({
      payload: {
        ...values,
        is_collection: entityTypeSelected?.title === "Collection" ? true : false,
        is_group: entityTypeSelected?.title === "Group" ? true : false,
        is_company: entityTypeSelected?.title === "Company" ? true : false,
      },
      onSuccess,
      onError,
    });
  };

  return (
    <Form<EntityCreateFormValues>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        entity_type_id: entityTypeInitialSelectedId,
      }}
    >
      {({ handleSubmit }) => (
        <Layout
          header={
            <PageHeader
              onGoBack={() => navigate(dataManagementData.entities.path)}
              title="Create new entity"
              rightSide={
                <Styled.PageHeaderRightSideContainer>
                  <Button
                    onClick={() => navigate(dataManagementData.entities.path)}
                    disabled={createEntityLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    loading={createEntityLoading}
                    disabled={createEntityLoading}
                  >
                    Finish and create
                  </Button>
                </Styled.PageHeaderRightSideContainer>
              }
            />
          }
        >
          <ModuleStyled.MaxWidthContent>
            <ModuleStyled.CardsWrapper>
              <ModuleStyled.AvatarContainer>
                <Icon component={<CompanyM />} size={50} color={"#006BF8"} />
                <ModuleStyled.AvatarInputsWrapper>
                  <Field name="name">
                    {({ input, meta }) => (
                      <InlineEdit
                        input={{
                          ...input,
                          placeholder: "Entity name",
                        }}
                        size="normal"
                        error={meta.touched && meta.error}
                        disabled={false}
                        hideIcon={false}
                      />
                    )}
                  </Field>
                  <Field name="description">
                    {({ input, meta }) => (
                      <InlineEdit
                        input={{
                          ...input,
                          placeholder: "Entity description",
                        }}
                        style={{ maxWidth: "100%" }}
                        size="small"
                        error={meta.touched && meta.error}
                        disabled={false}
                        hideIcon={false}
                      />
                    )}
                  </Field>
                </ModuleStyled.AvatarInputsWrapper>
              </ModuleStyled.AvatarContainer>

              {allEntityTypesLoading ? (
                <Loader />
              ) : (
                <Field
                  name="entity_type_id"
                  render={({ input }) => (
                    <>
                      <Styled.EntityTypesContainer>
                        {allEntityTypes?.map((entityType) => (
                          <CardSelect
                            key={entityType.id}
                            title={entityType.title}
                            description={entityType.description}
                            value={input.value === entityType.id}
                            onClick={() => {
                              input.onChange(entityType.id);
                            }}
                          />
                        ))}
                      </Styled.EntityTypesContainer>
                    </>
                  )}
                />
              )}

              <Card
                background="white-shadow"
                lively
                hideContent={!basicInformationsCardOpened}
                onHeaderClick={() => setBasicInformationsCardOpened(!basicInformationsCardOpened)}
                title="Basic Information"
                withHeader
                icon={<EditM />}
                iconColor="#AEAEAE"
              >
                <ModuleStyled.InputWrapper>
                  <Field name="legal_name">
                    {({ input }) => (
                      <Input
                        {...input}
                        label="Legal Name"
                        description="The entity legal name according to legal records"
                      />
                    )}
                  </Field>

                  <Field name="document_id">
                    {({ input }) => (
                      <Input
                        {...input}
                        label="Document ID"
                        description="The official document ID"
                      />
                    )}
                  </Field>

                  <Field
                    name="country_code"
                    render={({ input, meta }) => (
                      <SelectCountry
                        description="Home country of the entity"
                        countries={countries}
                        showErrorText={meta.touched && meta.error}
                        errorText={meta.error}
                        {...input}
                      />
                    )}
                  />

                  {/* TODO: load states from support infos */}
                  {/* TODO: address should be saved as a string? */}
                  {/* <Input label="Province" description="The entity state/province" /> */}
                  {/* <Input label="Address" description="The entity full address" /> */}
                </ModuleStyled.InputWrapper>
              </Card>

              <Card
                background="white-shadow"
                lively
                hideContent={!classificationCardOpened}
                onHeaderClick={() => setClassificationCardOpened(!classificationCardOpened)}
                title="Classification"
                withHeader
                icon={<EditM />}
                iconColor="#AEAEAE"
              >
                <ModuleStyled.InputWrapper>
                  <Field
                    name="isic_code"
                    render={({ input, meta }) => (
                      <Select
                        {...input}
                        label="Sector"
                        showSearch
                        optionFilterProp="children"
                        description="The entity sector according to ISIC standards"
                        placeholder="Industry ISIC Sector"
                        errorText={meta.touched && meta.error}
                      >
                        {industriesISICGroups.map((group) => (
                          <>
                            <Select.OptGroup key={group.code} label={group.name}>
                              {industriesISIC
                                .filter((industry) => {
                                  return industry.section_code === group.code;
                                })
                                .map((industry) => (
                                  <Select.Option key={industry.id} value={industry.id}>
                                    {industry.class_name}
                                  </Select.Option>
                                ))}
                            </Select.OptGroup>
                          </>
                        ))}
                      </Select>
                    )}
                  />

                  <Field
                    name="industry_code"
                    render={({ input, meta }) => (
                      <Select
                        {...input}
                        showSearch
                        label="Industry"
                        description="The entity industry according to GICS standards"
                        placeholder="Industry"
                        errorText={meta.touched && meta.error}
                        optionFilterProp="children"
                      >
                        {industriesGICSGroups.map((group) => (
                          <>
                            <Select.OptGroup key={group.code} label={group.name}>
                              {industriesGICS
                                .filter((industry) => {
                                  return industry.sector_code === group.code;
                                })
                                .map((industry) => (
                                  <Select.Option key={industry.id} value={industry.id}>
                                    {industry.subindustry_name}
                                  </Select.Option>
                                ))}
                            </Select.OptGroup>
                          </>
                        ))}
                      </Select>
                    )}
                  />
                </ModuleStyled.InputWrapper>
              </Card>

              <Card
                background="white-shadow"
                lively
                hideContent={!financialInformationCardOpened}
                onHeaderClick={() =>
                  setFinancialInformationCardOpened(!financialInformationCardOpened)
                }
                title="Financial Information"
                withHeader
                icon={<EditM />}
                iconColor="#AEAEAE"
              >
                <ModuleStyled.InputWrapper>
                  <Field
                    name="fiscal_year"
                    render={({ input }) => (
                      <Select
                        {...input}
                        showSearch
                        label="Fiscal Year"
                        description="Month of the fiscal year start"
                        filterOption={(inputValue, option) => {
                          return (
                            option!.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                          );
                        }}
                      >
                        <Select.Option value={"jan"}>January</Select.Option>
                        <Select.Option value={"feb"}>February</Select.Option>
                        <Select.Option value={"mar"}>March</Select.Option>
                        <Select.Option value={"aprl"}>April</Select.Option>
                        <Select.Option value={"may"}>May</Select.Option>
                        <Select.Option value={"jun"}>June</Select.Option>
                        <Select.Option value={"jul"}>July</Select.Option>
                        <Select.Option value={"aug"}>August</Select.Option>
                        <Select.Option value={"sep"}>September</Select.Option>
                        <Select.Option value={"oct"}>October</Select.Option>
                        <Select.Option value={"nov"}>November</Select.Option>
                        <Select.Option value={"dec"}>December</Select.Option>
                      </Select>
                    )}
                  />

                  <Field
                    name="currency_code"
                    render={({ input, meta }) => (
                      <SelectCurrency
                        description="The entity currency"
                        currencies={currencies}
                        showErrorText={meta.touched && meta.error}
                        errorText={meta.error}
                        {...input}
                      />
                    )}
                  />
                </ModuleStyled.InputWrapper>
              </Card>
            </ModuleStyled.CardsWrapper>
          </ModuleStyled.MaxWidthContent>
        </Layout>
      )}
    </Form>
  );
}
