import { FinancialsBSTableData, FinancialsBSValueTableData } from "./financials-bs.types";

const formatter = Intl.NumberFormat("en", { notation: "compact" });

export const mockFinancialsBSValues: FinancialsBSValueTableData[] = [
  {
    id: 1,
    value: formatter.format(351000),
    datetime: "2020-01-01",
  },
  {
    id: 2,
    value: formatter.format(351000),
    datetime: "2020-02-01",
  },
  {
    id: 3,
    value: formatter.format(351000),
    datetime: "2020-03-01",
  },
  {
    id: 4,
    value: formatter.format(351000),
    datetime: "2020-04-01",
  },
  {
    id: 5,
    value: formatter.format(351000),
    datetime: "2020-05-01",
  },
  {
    id: 6,
    value: formatter.format(351000),
    datetime: "2020-06-01",
  },
  {
    id: 7,
    value: formatter.format(351000),
    datetime: "2020-07-01",
  },
  {
    id: 8,
    value: formatter.format(351000),
    datetime: "2020-08-01",
  },
  {
    id: 9,
    value: formatter.format(351000),
    datetime: "2020-09-01",
  },
  {
    id: 10,
    value: formatter.format(351000),
    datetime: "2020-10-01",
  },
  {
    id: 11,
    value: formatter.format(351000),
    datetime: "2020-11-01",
  },
  {
    id: 12,
    value: formatter.format(351000),
    datetime: "2020-12-01",
  },
];

const mockGroups = {
  assets: {
    id: 1,
    title: "Total Assets",
  },
  liabilities: {
    id: 2,
    title: "Total Liabilities",
  },
  equity: {
    id: 3,
    title: "Equity",
  },
};

export const mockFinancialsIsData: FinancialsBSTableData[] = [
  {
    id: 1,
    title: "Cash & Equivalents",
    periodicity: "month",
    values: mockFinancialsBSValues,
    group: mockGroups.assets,
  },
  {
    id: 2,
    title: "Accounts Receivable",
    periodicity: "month",
    values: mockFinancialsBSValues,
    group: mockGroups.assets,
  },
  {
    id: 3,
    title: "Inventory",
    periodicity: "month",
    values: mockFinancialsBSValues,
    group: mockGroups.assets,
  },
  {
    id: 4,
    title: "Plant, Property & Equipment",
    periodicity: "month",
    values: mockFinancialsBSValues,
    group: mockGroups.assets,
  },
  {
    id: 5,
    title: "Intangible Assets",
    periodicity: "month",
    values: mockFinancialsBSValues,
    group: mockGroups.assets,
  },
  {
    id: 6,
    title: "Accounts Payable",
    periodicity: "month",
    values: mockFinancialsBSValues,
    group: mockGroups.liabilities,
  },
  {
    id: 7,
    title: "ST Taxes Payable",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsBSValues,
    group: mockGroups.liabilities,
  },
  {
    id: 8,
    title: "Short Term Debt",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsBSValues,
    group: mockGroups.liabilities,
  },
  {
    id: 9,
    title: "Lease",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsBSValues,
    group: mockGroups.liabilities,
  },
  {
    id: 10,
    title: "Long Term Debt",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsBSValues,
    group: mockGroups.liabilities,
  },
  {
    id: 11,
    title: "Convertible Debt",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsBSValues,
    group: mockGroups.liabilities,
  },
  {
    id: 12,
    title: "Equity",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsBSValues,
    group: mockGroups.equity,
  },
  {
    id: 13,
    title: "Accumulated Profit/Loss",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsBSValues,
    group: mockGroups.equity,
  },
  {
    id: 14,
    title: "Net Equity",
    periodicity: "month",
    calculatedRow: false,
    values: mockFinancialsBSValues,
    group: mockGroups.equity,
  },
];
