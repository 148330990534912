import { v4 as uuidV4 } from "uuid";

import { ForecastData, Group, Period } from "../types";

const subRowsNamesData = ["Europe", "United States", "Asia"];

const groupsNamesData = [
  "AE Meetings",
  "AE Meetings Conversion",
  "Proposals",
  "Conversion Rate",
  "Avarage Contract Size",
];

const periodsData = [
  { date: "2022-06-01", isForecast: false },
  { date: "2022-07-01", isForecast: false },
  { date: "2022-08-01", isForecast: false },
  { date: "2022-09-01", isForecast: false },
  { date: "2022-10-01", isForecast: false },
  { date: "2022-11-01", isForecast: false },
  { date: "2022-12-01", isForecast: false },
  { date: "2023-01-01", isForecast: true },
  { date: "2023-02-01", isForecast: true },
  { date: "2023-03-01", isForecast: true },
];

const generatePeriodsData = (periods: typeof periodsData): Period[] => {
  return periods.map((period) => ({
    date: period.date,
    isForecast: period.isForecast,
    value: Math.floor(Math.random() * 300),
  }));
};

const generateGroupData = (
  groupsNames: string[],
  periods: typeof periodsData,
  subRowsNames: string[]
): Group[] => {
  return groupsNames.map((group) => ({
    id: uuidV4(),
    title: group,
    periods: generatePeriodsData(periods),
    subRows: generateGroupData(subRowsNames, periods, []),
  }));
};

export const generateTableData = (): ForecastData => {
  return {
    id: uuidV4(),
    periodicity: "monthly",
    groups: generateGroupData(groupsNamesData, periodsData, subRowsNamesData),
  };
};
