import { useNavigate } from "react-router-dom";

import {
  CompanyM,
  GroupM,
  FolderArchiveM,
  FileAddM,
  AddM,
  BookM,
  Book2M,
  BookmarkM,
  DynamicContentAddM,
  DynamicContentM,
} from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import { Title } from "@synerise/ds-typography";

import { Divider } from "@/components/Divider";
import { ModuleCard } from "@/components/ModuleCard";

import { dataManagementData } from "./data-management.data";
import * as Styled from "./data-management.styles";

export function DataManagementHome() {
  const navigate = useNavigate();

  return (
    <Layout>
      <Styled.MaxWidthContent>
        <Styled.TitleContainer>
          <Title level={1}>Data Management</Title>
        </Styled.TitleContainer>

        <Styled.ModulesContainer>
          <Divider text="Shortcuts" />
          <Styled.CardsGroup>
            <ModuleCard
              title="Create new company"
              description="Create new company"
              icon={<AddM />}
              onClick={() => navigate(dataManagementData.companies.createPath)}
            />
            <ModuleCard
              title="Import Ledger"
              description="Import a new ledger from a file"
              icon={<FileAddM />}
              onClick={() => {}}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>

        <Styled.ModulesContainer>
          <Divider text="Entities" />
          <Styled.CardsGroup>
            <ModuleCard
              title="Companies"
              description="Manage all your companies"
              icon={<CompanyM />}
              onClick={() => navigate(dataManagementData.companies.path)}
            />
            <ModuleCard
              title="Groups"
              description="Manage and create conglomerates"
              icon={<GroupM />}
              onClick={() => navigate(dataManagementData.groups.path)}
            />
            <ModuleCard
              title="Collections"
              description="Organize companies in collections"
              icon={<FolderArchiveM />}
              onClick={() => navigate(dataManagementData.collections.path)}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>
        <Styled.ModulesContainer>
          <Divider text="Dimensions" />
          <Styled.CardsGroup>
            <ModuleCard
              title="Dimension"
              description="Classify all the dimensions with types"
              icon={<BookM />}
              onClick={() => navigate(dataManagementData.dimensionTypes.path)}
            />
            <ModuleCard
              title="Dimensions"
              description="Manage dimensions"
              icon={<Book2M />}
              onClick={() => navigate(dataManagementData.dimensions.path)}
            />
            <ModuleCard
              title="Create new dimension"
              icon={<BookmarkM />}
              onClick={() => navigate(dataManagementData.dimensions.createPath)}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>

        <Styled.ModulesContainer>
          <Divider text="Accounts" />
          <Styled.CardsGroup>
            <ModuleCard
              title="Chart of Accounts"
              description="Manage all your charts and accounts"
              icon={<DynamicContentM />}
              onClick={() => navigate(dataManagementData.chartOfAccounts.path)}
            />
            <ModuleCard
              title="Create Chart of Account"
              icon={<DynamicContentAddM />}
              onClick={() => navigate(dataManagementData.chartOfAccounts.createPath)}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>
      </Styled.MaxWidthContent>
    </Layout>
  );
}
