import { createContext, useContext, useState } from "react";

import { calculateLastMonthFromKPIValues } from "./utils/calculateLastMonthFromKPIValues";
import { calculateLastYearFromKPIValues } from "./utils/calculateLastYearFromKPIValues";

export type BudgetType = "amount" | "constant" | "average" | "linear-percentage" | "linear-amount";

export type BudgetValue = {
  amount: number;
  date: Date;
};

type KPICreateBudgetContextData = {
  budgetType: BudgetType;
  changeBudgetType: (budgetType: BudgetType) => void;
  initialMonthSelected: number;
  changeInitialMonthSelected: (month: number) => void;
  finalMonthSelected: number;
  changeFinalMonthSelected: (month: number) => void;
  initialYearSelected: number;
  changeInitialYearSelected: (year: number) => void;
  budgetValues: BudgetValue[];
  changeBudgetValues: (budgetValues: BudgetValue[]) => void;
  finalYearSelected: number;
  changeFinalYearSelected: (year: number) => void;
};

type KPICreateBudgetProviderProps = {
  children: React.ReactNode;
};

const KpiCreateBudgetContext = createContext({} as KPICreateBudgetContextData);

export const KPICreateBudgetProvider = ({ children }: KPICreateBudgetProviderProps) => {
  const [budgetType, setBudgetType] = useState<BudgetType>("amount");
  const [initialMonthSelected, setInitialMonthSelected] = useState<number>(() => {
    return calculateLastMonthFromKPIValues();
  });
  const [finalMonthSelected, setFinalMonthSelected] = useState<number>(initialMonthSelected);
  const [initialYearSelected, setInitialYearSelected] = useState<number>(() => {
    const lastYear = calculateLastYearFromKPIValues();

    return initialMonthSelected === 1 ? lastYear + 1 : lastYear;
  });
  const [finalYearSelected, changeFinalYearSelected] = useState(initialYearSelected);

  const [budgetValues, setBudgetValues] = useState<BudgetValue[]>([]);

  return (
    <KpiCreateBudgetContext.Provider
      value={{
        budgetType,
        changeBudgetType: setBudgetType,
        initialMonthSelected,
        changeInitialMonthSelected: setInitialMonthSelected,
        initialYearSelected,
        changeInitialYearSelected: setInitialYearSelected,
        finalMonthSelected,
        changeFinalMonthSelected: setFinalMonthSelected,
        finalYearSelected,
        changeFinalYearSelected,
        budgetValues,
        changeBudgetValues: setBudgetValues,
      }}
    >
      {children}
    </KpiCreateBudgetContext.Provider>
  );
};

export function useKPICreateBudget() {
  const context = useContext(KpiCreateBudgetContext);

  if (!context) {
    throw new Error("useKPICreateBudget must be used within a KpiCreateBudgetProvider");
  }

  return context;
}
