import { Form, Field } from "react-final-form";
import styled from "styled-components";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";
import Select from "@synerise/ds-select";

import { useValidationSchema } from "@/hooks/useValidationSchema";

import { ChartResponse } from "../ChartOfAccounts/chart.types";
import { useAccountsMapping } from "./hooks/useAccountsMapping";

type ModalAddAccountMappingProps = {
  visible: boolean;
  charts: ChartResponse[];
  closeModal: () => void;
};

type ModalAddAccountMappingFormData = {
  base_id: number;
  mapped_id: number;
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const formId = "add-account-mapping-form";

const formValidationSchema = yup.object().shape({
  base_id: yup.number().required("Base account is required"),
  mapped_id: yup.number().required("Mapped account is required"),
});

export function ModalAddAccountMapping({
  visible,
  charts,
  closeModal,
}: ModalAddAccountMappingProps) {
  const validate = useValidationSchema(formValidationSchema);
  const { createAccountMapping, createAccountMappingLoading } = useAccountsMapping();

  const onSubmit = async (values: ModalAddAccountMappingFormData) => {
    const onSuccess = () => {
      message.success("Accounts mapping created successfully");
    };

    const onError = (errorMessage: string) => {
      message.error(errorMessage);
    };

    await createAccountMapping({
      payload: {
        source_chart_id: values.base_id,
        mapped_chart_id: values.mapped_id,
      },
      onSuccess,
      onError,
    });

    closeModal();
  };

  return (
    <Modal
      visible={visible}
      title="Add new mapping"
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          form={formId}
          type="primary"
          loading={createAccountMappingLoading}
          className="font-bold"
        >
          Add
        </Button>,
      ]}
      width={600}
    >
      <Form<ModalAddAccountMappingFormData> onSubmit={onSubmit} validate={validate}>
        {({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit} id={formId}>
            <div>
              <Field name="mapped_id">
                {({ input, meta }) => (
                  <Select
                    {...input}
                    label="Target Chart of Accounts"
                    errorText={meta.touched && meta.error}
                  >
                    {charts.map((chart) => (
                      <Select.Option key={chart.id} value={chart.id}>
                        {chart.title}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Field>
            </div>
            <div>
              <Field name="base_id">
                {({ input, meta }) => (
                  <Select
                    {...input}
                    label="Base Chart of Accounts"
                    errorText={meta.touched && meta.error}
                  >
                    {charts.map((chart) => (
                      <Select.Option key={chart.id} value={chart.id}>
                        {chart.title}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Field>
            </div>
          </StyledForm>
        )}
      </Form>
    </Modal>
  );
}
