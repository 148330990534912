const dataManagementPrefix = "/data";

type DataManagementMenuRoute = {
  id: string;
  title: string;
  path: string;
};

type DataManagementMenuGroup = {
  id: string;
  path?: string;
  title: string;
  routes: DataManagementMenuRoute[];
};

type DataManagementMenuData = {
  title: string;
  homePath: string;
  menuGroups: DataManagementMenuGroup[];
};

export const dataManagementMenuData: DataManagementMenuData = {
  title: "Data Management",
  homePath: `${dataManagementPrefix}/home`,
  menuGroups: [
    {
      id: "entities",
      title: "ENTITIES",
      path: `${dataManagementPrefix}/entities`,
      routes: [
        {
          id: "entities-companies",
          title: "Companies",
          path: `${dataManagementPrefix}/entities/companies`,
        },
        {
          id: "entities-groups",
          title: "Groups",
          path: `${dataManagementPrefix}/entities/groups`,
        },
        {
          id: "entities-collections",
          title: "Collections",
          path: `${dataManagementPrefix}/entities/collections`,
        },
      ],
    },
    {
      id: "dimensions",
      title: "DIMENSIONS",
      path: `${dataManagementPrefix}/dimensions`,
      routes: [
        {
          id: "dimension",
          title: "Dimensions",
          path: `${dataManagementPrefix}/dimension-types`,
        },
        {
          id: "dimensions",
          title: "Dimensions Values",
          path: `${dataManagementPrefix}/dimensions`,
        },
        {
          id: "dimension-new",
          title: "New Dimension",
          path: `${dataManagementPrefix}/dimensions/create`,
        },
      ],
    },
    {
      id: "accounts",
      title: "ACCOUNTS",
      routes: [
        {
          id: "chart-of-accounts",
          title: "Chart of Accounts",
          path: `${dataManagementPrefix}/chart-of-accounts`,
        },
        {
          id: "accounts-mapping",
          title: "Accounts Mapping",
          path: `${dataManagementPrefix}/accounts/mapping`,
        },
      ],
    },
    {
      id: "ledgers",
      title: "LEDGERS",
      routes: [
        {
          id: "trial-balance",
          title: "Trial Balance",
          path: `${dataManagementPrefix}/ledgers`,
        },
        {
          id: "reviews",
          title: "Reviews",
          path: `${dataManagementPrefix}/reviews`,
        },
      ],
    },
    {
      id: "integrations",
      title: "INTEGRATIONS",
      routes: [
        {
          id: "ERP",
          title: "ERP",
          path: `${dataManagementPrefix}/erp`,
        },
        {
          id: "databases",
          title: "Databases",
          path: `${dataManagementPrefix}/databases`,
        },
      ],
    },
  ],
};
