import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { LockM } from "@synerise/ds-icon";
import Tags, { TagShape } from "@synerise/ds-tags";
import Tooltip from "@synerise/ds-tooltip";

import { DescriptionCell } from "@/components/Table/Cells/DescriptionCell";
import { DynamicTags } from "@/components/Table/Cells/DynamicTags";
import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as TableStyled from "@/components/Table/styles";

import { ActionsDropdown } from "./AccessGroupsActionsDropdown";

export type RolesTableData = {
  id: number;
  title: string;
  description: string;
  membersCount: number;
  allowedModules: string[];
  createdAt: string;
  isSystem: boolean;
};

type TableColumnsProps = {
  onRemove: (id: number, name: string) => Promise<void>;
  onViewAccessGroup: (id: number) => void;
};

export function tableColumns({
  onRemove,
  onViewAccessGroup,
}: TableColumnsProps): Column<RolesTableData>[] {
  return [
    {
      Header: "Title",
      accessor: "title",
      sortDescFirst: true,
      Cell: ({ row }) => (
        <UserTypeCell
          showAvatar
          type={row.original.title}
          onClick={() => onViewAccessGroup(row.original.id)}
          style={{
            cursor: "pointer",
          }}
        />
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      sortDescFirst: true,
      Cell: ({ row }) => <DescriptionCell description={row.original.description} />,
    },
    {
      Header: "Members",
      accessor: "membersCount",
      sortDescFirst: true,

      Cell: ({ row }) => {
        return (
          <Tags
            style={{ margin: 0 }}
            tagShape={TagShape.DEFAULT_ROUND}
            asPill
            selected={[
              {
                id: row.original.membersCount,
                name: String(row.original.membersCount),
                color: "#655A7C",
              },
            ]}
          />
        );
      },
    },
    {
      Header: "Allowed Modules",
      accessor: "allowedModules",
      disableSortBy: true,
      Cell: ({ row }) => (
        <DynamicTags noContentMessage="No Modules" tags={row.original.allowedModules} />
      ),
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      disableSortBy: true,
      Cell: ({ row }) => <UserTypeCell type={row.original.createdAt} />,
    },
    {
      Header: "",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row }) => (
        <TableStyled.RowActionButtons>
          {row.original.isSystem && (
            <Tooltip title="System roles cannot be edited or deleted">
              <Button mode="single-icon" type="ghost-white">
                <Icon color="#C4C4C4" component={<LockM />} />
              </Button>
            </Tooltip>
          )}

          {!row.original.isSystem && (
            <ActionsDropdown onRemove={() => onRemove(row.original.id, row.original.title)} />
          )}
        </TableStyled.RowActionButtons>
      ),
    },
  ];
}
