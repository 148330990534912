import { createContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import TenantsService from "@/core/tenants/tenants.services";
import { Tenant } from "@/core/tenants/tenants.types";
import UsersService from "@/core/users/users.services";
import { api } from "@/utils/http";
import { queryClient } from "@/utils/queryClient";

import { errorNotification, warningNotification } from "../../utils/notifications";
import authService from "./auth.services";
import {
  AuthContextData,
  AuthProviderProps,
  MembershipData,
  PermissionsData,
  UserData,
} from "./auth.types";

export const AuthContext = createContext({} as AuthContextData);

// TODO: move cookies related to utils

export function removeCredentials() {
  cookies.remove("@compass-tenant-id", { path: "/", sameSite: "strict" });
  cookies.remove("@compass-app-token", { path: "/", sameSite: "strict" });
  cookies.remove("@compass-user-id", { path: "/", sameSite: "strict" });
  delete api.defaults.headers.common["X-Compass-Tenant-Id"];
  delete api.defaults.headers.common["Authorization"];
}

export function handleInvalidToken() {
  removeCredentials();
  window.history.pushState({}, "", "/invalid-token");
  window.location.reload();
}

const cookies = new Cookies();

const setCompassUserIDCookie = (compassUserID: number) => {
  cookies.set("@compass-user-id", compassUserID, { path: "/", sameSite: "strict" });
};

const setCompassAppTokenCookie = (compassAppToken: string) => {
  cookies.set("@compass-app-token", compassAppToken, {
    path: "/",
    sameSite: "strict",
    secure: true,
    maxAge: 60 * 60 * 1, // 1 hour
  });
};

const getSelectedMembershipFromTenantID = (
  tenantID: number,
  user: UserData
): MembershipData | null => {
  const selectedMembership =
    user.memberships.find((membership) => membership.tenant_id === tenantID) ?? null;
  return selectedMembership;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserData | null>(null);
  const [currentTenant, setCurrentTenant] = useState<Tenant>();
  const [selectedMembership, setSelectedMembership] = useState<MembershipData | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userPermissions, setUserPermissions] = useState<PermissionsData[]>([]);
  const [isAuthenticationLoading, setAuthenticationLoading] = useState(false);
  const [isUserLoading, setUserLoading] = useState(true);

  const navigate = useNavigate();

  async function authenticateUser(authTokenRequest: FormData) {
    setAuthenticationLoading(true);
    authService
      .getToken(authTokenRequest)
      .then((auth) => {
        setUser(auth.user);
        setCompassUserIDCookie(auth.user.id);
        setCompassAppTokenCookie(auth.access_token);
        api.defaults.headers.common["Authorization"] = `${auth.token_type} ${auth.access_token}`;

        setIsAuthenticated(true);
        return UsersService.getTenantsFromUser(auth.user.id);
      })
      .then((tenants) => {
        if (tenants.length === 1 && tenants[0].is_active) {
          return loginUser(tenants[0].id);
        } else {
          return navigate("/tenants");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          warningNotification("Invalid Credentials");
        } else if (error.response.status === 404) {
          warningNotification("User not found");
        } else if (error.response.status === 406) {
          warningNotification("Email not confirmed");
          navigate("/activate-email");
        } else if (error.response.status === 409) {
          warningNotification("User not registered");
          navigate("/register");
        } else if (error.response.status === 422) {
          warningNotification("Null password");
        } else {
          errorNotification("Something wrong happened");
        }
      })
      .finally(() => {
        setAuthenticationLoading(false);
      });
  }

  useEffect(() => {
    if (user && currentTenant) {
      const membership = getSelectedMembershipFromTenantID(currentTenant.id, user);
      if (!membership) {
        errorNotification("Membership not found");
        logoutUser();
        return;
      }
      setSelectedMembership(membership);
      setAllUserPermissions(membership);
    }
  }, [user, currentTenant]);

  async function loginUser(tenantID: number) {
    setUserLoading(true);

    try {
      authService.setTenantIDCookie(tenantID);
      authService.setTenantIDInHeader(tenantID);

      const tenant = await TenantsService.getTenant(tenantID);

      setCurrentTenant(tenant);

      setIsLoggedIn(true);
    } catch (error) {
      throw new Error("Login User Error");
    }
    setUserLoading(false);
  }

  const logoutUser = useCallback(() => {
    setUser(null);
    setSelectedMembership(null);
    setUserPermissions([]);
    setIsAuthenticated(false);
    queryClient.removeQueries();
    setIsLoggedIn(false);
    setUserLoading(false);
    removeCredentials();
  }, []);

  const getUser = useCallback(async (userID: number) => {
    setUserLoading(true);
    try {
      const userData = await authService.getUser(userID);
      setUser(userData);
      return userData;
    } catch (error) {
      logoutUser();
    } finally {
      setUserLoading(false);
    }
  }, []);

  function setAllUserPermissions(membership: MembershipData) {
    // TODO: refactor
    /*   membership?.access_groups.forEach((access_group) => {
      setUserPermissions((prevState) => prevState.concat(access_group.permissions));
    }); */
  }

  useEffect(() => {
    // async function setTenantSelected(tenantID: number) {
    //   const tenant = await TenantsService.getTenant(tenantID);
    //   setCurrentTenant(tenant);

    //   if (user) {
    //     const membership = getSelectedMembershipFromTenantID(tenantID, user);
    //     if (membership) {
    //       setSelectedMembership(membership);
    //       setAllUserPermissions(membership);
    //     }
    //   }
    //   setIsLoggedIn(true);
    // }
    const tenantID = cookies.get("@compass-tenant-id");
    if (!tenantID) {
      logoutUser();
      return;
    }

    loginUser(tenantID);
  }, []);

  useEffect(() => {
    const appToken = cookies.get("@compass-app-token");
    const userID = cookies.get("@compass-user-id");

    async function setUserAndTenants(userID: number) {
      setUserLoading(true);
      const userData = await getUser(userID);
      if (userData) {
        setIsAuthenticated(true);
      }
      setUserLoading(false);
    }

    if (appToken && userID) {
      setUserAndTenants(userID);
    } else {
      logoutUser();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoggedIn,
        user,
        userPermissions,
        currentTenant,
        selectedMembership,
        isAuthenticationLoading,
        isUserLoading,
        authenticateUser,
        loginUser,
        logoutUser,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
