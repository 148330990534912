import BoringAvatar from "boring-avatars";

import Loader from "@synerise/ds-loader";
import Tooltip from "@synerise/ds-tooltip";

import * as Styled from "./avatar-upload.styles";

type AvatarUploadProps = {
  imageUrl?: string;
  uploadingAvatar: boolean;
  name: string;
  onSelectAvatar: (file: File) => void;
};

export function AvatarUpload({
  name,
  imageUrl,
  uploadingAvatar,
  onSelectAvatar,
}: AvatarUploadProps) {
  return (
    <Tooltip title={uploadingAvatar ? "" : "Upload a avatar"} offset="small">
      <Styled.Container>
        {uploadingAvatar ? (
          <Loader />
        ) : (
          <Styled.UploadContainer>
            {imageUrl ? (
              <Styled.Image src={imageUrl} alt="Avatar" />
            ) : (
              <BoringAvatar
                size={60}
                name={name}
                variant="beam"
                colors={["#58706D", "#4B5757", "#7C8A6E", "#B0B087", "#E3E3D1"]}
              />
            )}
            <input
              type="file"
              accept=".png,.jpg"
              multiple={false}
              style={{
                display: "none",
              }}
              onChange={(event) => {
                if (!event.target.files?.length || event.target.files.length === 0) {
                  return;
                }

                const file = event.target.files[0];

                onSelectAvatar(file);
              }}
            />
          </Styled.UploadContainer>
        )}
      </Styled.Container>
    </Tooltip>
  );
}
