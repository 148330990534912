import { EditItemDrawerDividerContent } from "../../EditItemDrawer/contents/EditItemDrawerDividerContent";
import { ItemConfig, ItemLayout } from "../../LayoutBuilder.types";

export type DividerData = {
  title: string;
};

type GetDividerConfigProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>) => void;
};

export function getDividerConfig({
  editingItem,
  updateItem,
}: GetDividerConfigProps): ItemConfig<DividerData> {
  return {
    name: "divider",
    height: 20,
    editDrawerComponent: (
      <EditItemDrawerDividerContent editingItem={editingItem} updateItem={updateItem} />
    ),
    resizeHandles: ["e"],
    initialLayout: {
      w: 12,
      h: 1,
      type: "divider",
      minW: 1,
      minH: 2,
      maxW: 12,
      maxH: 2,
      resizeHandles: [],
      data: {
        title: "Divider Name",
      },
    },
  };
}
