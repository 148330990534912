import styled from "styled-components";

export const CardContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 24px;
`;

export const FooterButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 14px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
`;
