import styled from "styled-components";

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ProfileInfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
`;

export const ProfileUserInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UserUpdatedInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: right;
`;

export const PasswordRequirementsContainer = styled.ul`
  margin: 0 0 20px 20px;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
