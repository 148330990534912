import Button from "@synerise/ds-button";
import Icon, { Close2S } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

type RemoveTagButtonProps = {
    tagId: number;
    tagIsBeenUsed: boolean | null;
    onRemoveTag: (id: number) => void;
}

export function RemoveTagButton({tagId, tagIsBeenUsed, onRemoveTag} : RemoveTagButtonProps) {
    if (tagIsBeenUsed) {
       return (
        <Popconfirm
        title="This tag is been used by some report, delete it anyway?"
        onConfirm={(e) => {
          e?.stopPropagation();
          if (!tagIsBeenUsed != null) {
              onRemoveTag(tagId);
          }
        }}
        onCancel={(e) => e?.stopPropagation()}
        cancelText="No"
        okText="Yes"
        okButtonProps={{
          danger: true,
        }}
      >
        <Button
          type="custom-color-ghost"
          htmlType="button"
          mode="single-icon"
          color="primary"
          onClick={(e) => e.stopPropagation()}
          icon={<Icon component={<Close2S />} color="#e66" />}
        />
      </Popconfirm>
       )
    } else {
        return (
            <Button
            type="custom-color-ghost"
            htmlType="button"
            mode="single-icon"
            color="primary"
            onClick={(e) => {
                e?.stopPropagation();
                if (!tagIsBeenUsed != null) {
                    onRemoveTag(tagId);
                }
            }}
            icon={<Icon component={<Close2S />} color="#e66" />}
          />
        )
    }
}

