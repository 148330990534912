import styled from "styled-components";

type DescriptionCellProps = {
  description: string;
};

const Container = styled.div`
  color: ${({ theme }) => theme.palette["grey-600"]};
  font-weight: 400;
`;

export function DescriptionCell({ description }: DescriptionCellProps) {
  return <Container>{description}</Container>;
}
