import { api } from "@/utils/http";

import {
  KpiValueCommentCreatePayload,
  KpiValueCommentResponse,
  KpiValueCommentUpdatePayload,
} from "./kpi-value-comment.types";

export class KPIValueCommentService {
  static async fetchAll(kpiId: number, valuesId: number): Promise<KpiValueCommentResponse[]> {
    const response = await api.get<KpiValueCommentResponse[]>(
      `/kpis/${kpiId}/values/${valuesId}/comments`
    );
    return response.data;
  }

  static async create(
    kpiId: number,
    valueId: number,
    payload: KpiValueCommentCreatePayload
  ): Promise<KpiValueCommentResponse> {
    const response = await api.post<KpiValueCommentResponse>(
      `/kpis/${kpiId}/values/${valueId}/comments`,
      payload
    );
    return response.data;
  }

  static async update(
    kpiId: number,
    valueId: number,
    commentId: number,
    payload: KpiValueCommentUpdatePayload
  ): Promise<KpiValueCommentResponse> {
    const response = await api.patch<KpiValueCommentResponse>(
      `/kpis/${kpiId}/values/${valueId}/comments/${commentId}`,
      payload
    );
    return response.data;
  }

  static async delete(
    kpiId: number,
    valueId: number,
    commentId: number
  ): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(
      `/kpis/${kpiId}/values/${valueId}/comments/${commentId}`
    );
    return response.data;
  }
}
