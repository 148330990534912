import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    body,
    th,
    td,
    input,
    textarea,
    select,
    button {
     font-family: "Open sans", sans-serif;
    }

    body {
        color: #6a7580;
    }
`;
