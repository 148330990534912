import { Responsive, WidthProvider } from "react-grid-layout";

import { ColumnChart } from "@/components/LayoutBuilder/GridItems/ColumnChart/ColumnChart";
import { Divider } from "@/components/LayoutBuilder/GridItems/Divider/Divider";
import { DonutChart } from "@/components/LayoutBuilder/GridItems/DonutChart/DonutChart";
import { getItemsConfig } from "@/components/LayoutBuilder/GridItems/GridItems.config";
import { Image } from "@/components/LayoutBuilder/GridItems/Image/Image";
import { LineChart } from "@/components/LayoutBuilder/GridItems/LineChart/LineChart";
import { StatisticCard } from "@/components/LayoutBuilder/GridItems/StatisticCard/StatisticCard";
import { Table } from "@/components/LayoutBuilder/GridItems/Table/Table";
import { Title } from "@/components/LayoutBuilder/GridItems/Title/Title";
import { rowHeight } from "@/components/LayoutBuilder/LayoutBuilder.config";
import { ItemLayout } from "@/components/LayoutBuilder/LayoutBuilder.types";

import { ItemControls } from "./components/ItemControls/ItemControls";
import { ItemDragHandle } from "./components/ItemDragHandle/ItemDragHandle";
import * as Styled from "./DashboardGridLayout.styles";
import { useDashboard } from "./hooks/useDashboard";
import { useGridLayout } from "./hooks/useGridLayout";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export function DashboardGridLayout() {
  const {
    items,
    indexItemSelected,
    selectItem,
    removeItem,
    unselectItem,
    handleLayoutChange,
    handleDrop,
    editingItem,
    updateEditingItem,
    inPreviewMode,
  } = useGridLayout();
  const { inEditMode } = useDashboard();

  const itemsConfig = getItemsConfig({
    updateItem: updateEditingItem,
    editingItem,
  });

  function getGridItem(item: ItemLayout<any>) {
    switch (item.type) {
      case "lineChart": {
        return (
          <LineChart
            title={item.data.title}
            series={item.data.series}
            isSelected={item.i === indexItemSelected && !inPreviewMode}
          />
        );
      }
      case "columnChart": {
        return (
          <ColumnChart
            title={item.data.title}
            series={item.data.series}
            isSelected={item.i === indexItemSelected && !inPreviewMode}
          />
        );
      }
      case "donutChart": {
        return (
          <DonutChart
            title={item.data.title}
            series={item.data.series}
            isSelected={item.i === indexItemSelected && !inPreviewMode}
          />
        );
      }
      case "statisticCard": {
        return (
          <StatisticCard
            {...item.data}
            isSelected={item.i === indexItemSelected && !inPreviewMode}
          />
        );
      }
      case "divider": {
        return (
          <Divider {...item.data} isSelected={item.i === indexItemSelected && !inPreviewMode} />
        );
      }
      case "title": {
        return <Title {...item.data} isSelected={item.i === indexItemSelected && !inPreviewMode} />;
      }
      case "table": {
        return <Table {...item.data} isSelected={item.i === indexItemSelected && !inPreviewMode} />;
      }
      case "image": {
        return <Image {...item.data} isSelected={item.i === indexItemSelected && !inPreviewMode} />;
      }
      default: {
        throw new Error(`Item type not handled ${item.type}`);
      }
    }
  }

  function generateLayoutDOM(items: ItemLayout<any>[]) {
    if (!items) return null;

    return items.map((item) => {
      const itemLayoutFromConfig = itemsConfig[item.type];
      if (!itemLayoutFromConfig) return null;

      function handleEditItem(layoutItem: ItemLayout<any>) {
        selectItem(layoutItem.i);
      }

      return (
        <Styled.Container
          key={item.i}
          data-grid={item}
          onClick={() => selectItem(item.i)}
          isSelected={item.i === indexItemSelected && !inPreviewMode}
          showControls={!inPreviewMode}
          height={itemLayoutFromConfig.height}
        >
          {getGridItem(item)}
          {!inPreviewMode && inEditMode && (
            <ItemControls
              removeItem={() => removeItem(item.i)}
              unselectItem={unselectItem}
              editItem={() => handleEditItem(item)}
              showControls={item.i === indexItemSelected}
            />
          )}
          {!inPreviewMode && indexItemSelected === item.i && <ItemDragHandle />}
        </Styled.Container>
      );
    });
  }

  return (
    <ResponsiveReactGridLayout
      droppingItem={{
        w: 5,
        h: 5,
        i: "__dropping-elem__",
      }}
      // TODO(Responsiveness) : Save each layout separately based on screen width and manipulate it accordingly
      layouts={{
        lg: items,
        md: items,
        sm: items,
        xs: items,
      }}
      onLayoutChange={(currentLayout) => {
        handleLayoutChange(currentLayout);
      }}
      isDroppable={inEditMode}
      isDraggable={inEditMode}
      resizeHandle={inEditMode ? null : <div></div>}
      rowHeight={rowHeight}
      margin={[15, 15]}
      draggableHandle=".drag-handle"
      compactType="vertical"
      onDrop={(_, item) => {
        handleDrop(item);
      }}
      autoSize
      style={{
        minHeight: "60vh",
        paddingTop: "25px",
      }}
      isBounded={true}
    >
      {generateLayoutDOM(items)}
    </ResponsiveReactGridLayout>
  );
}
