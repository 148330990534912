import styled from "styled-components";

import { theme } from "@/styles/theme";

export const Container = styled.div<{ isSelected: boolean }>`
  padding: 12px;
  background-color: #fff;
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 2px;
  border: ${({ isSelected, theme }) =>
    isSelected ? `2px solid ${theme.palette["blue-600"]}` : "2px solid transparent"};
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  > header {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1rem;
      font-weight: bold;
    }

    .subtitle {
      font-size: 0.8rem;
    }
  }

  .amounts {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }

  .amounts--total {
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.bold};
  }

  .amounts--goal {
    font-size: ${theme.fontSize.s};
  }
`;
