import { useState } from "react";

import Button from "@synerise/ds-button";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";
import Tabs from "@synerise/ds-tabs";

import { useAuth } from "@/hooks/useAuth";

import { useKpiValues } from "../library/hooks/useKpiValues";
import { KPISimpleBudget } from "./components/KPISimpleBudget";
import * as Styled from "./kpi-create-budget-modal.styles";
import { useKPICreateBudget } from "./useKPICreateBudget";
import { useKPIsTable } from "./useKPIsTable";

export function KPICreateBudgetModal() {
  function onError(error: string) {
    message.error(error);
  }
  const { currentTenant } = useAuth();
  const { isCreateBudgetModalVisible, closeCreateBudgetModal, kpiSelected, periodicitySelected } =
    useKPIsTable();

  const [activeTab, setActiveTab] = useState(0);
  const { addNewKpiValue, addNewKpiValueLoading } = useKpiValues({ onError });
  const { budgetValues } = useKPICreateBudget();

  async function handleSubmit() {
    if (!currentTenant) {
      message.error("Tenant not found!");
      return;
    }

    if (!kpiSelected) {
      message.error("Unknown KPI");
      return;
    }

    const onSuccess = () => {
      message.success("Budget created!");
    };
    await addNewKpiValue({
      kpiId: kpiSelected.id,
      onSuccess,
      payload: {
        periodicity: periodicitySelected,
        values: budgetValues.map((budgetValue) => ({
          amount: budgetValue.amount,
          date: budgetValue.date,
        })),
      },
    });
  }

  return (
    <Modal
      visible={isCreateBudgetModalVisible}
      title="Create Budget"
      onCancel={() => closeCreateBudgetModal()}
      size="small"
      bodyStyle={{
        padding: "0 30px",
      }}
      footer={[
        <Button key="cancel" onClick={closeCreateBudgetModal} disabled={addNewKpiValueLoading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          onClick={() => handleSubmit()}
          type="primary"
          loading={addNewKpiValueLoading}
          disabled={addNewKpiValueLoading}
        >
          Add Budget
        </Button>,
      ]}
    >
      <Styled.Content>
        <Tabs
          underscore={false}
          activeTab={activeTab}
          handleTabClick={(index) => setActiveTab(index)}
          tabs={[{ label: "Simple" }]}
        />
        {activeTab === 0 && <KPISimpleBudget />}
      </Styled.Content>
    </Modal>
  );
}
