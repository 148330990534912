import styled from "styled-components";

import { theme } from "@/styles/theme";

export const Container = styled.div``;

export const Title = styled.span`
  display: block;
  padding: ${theme.spacing.s} ${theme.spacing.m};
  font-size: ${theme.fontSize.lg};
  font-weight: ${theme.fontWeight.semi_bold};
  margin-bottom: ${theme.spacing.m};
  color: ${theme.colors.gray_800};
`;
