import Icon, { TagM } from "@synerise/ds-icon";

import { TagResponse } from "./reports-tags.types";
import { Item } from "./ReportsSidebarMenuTagsListSection";

export function mapTagsResponseToItem(tags: TagResponse[]): Item[] {
  return tags.map((tag) => ({
    id: tag.id,
    text: tag.title,
    prefixel: <Icon component={<TagM />} />,
    checked: false,
  }));
}
