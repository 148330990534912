import { AccountResponse } from "../../Accounts/accounts.types";
import { AccountMappingDetailsTableData } from "../account-mapping-details.types";
import { AccountMapResponse } from "../account-mapping.types";

function mapAccountsToFlatData(
  allAccounts: AccountResponse[],
  mappedAccounts: AccountMapResponse[]
): AccountMappingDetailsTableData[] {
  const tableData: AccountMappingDetailsTableData[] = [];
  allAccounts.forEach((account) => {
    const accountMapped = mappedAccounts.find(
      (mappedAccount) => mappedAccount.mapped_id === account.id
    );
    tableData.push({
      code: account.ref,
      classification: account.code,
      level: account.level,
      mapAccountId: accountMapped ? accountMapped.id : null,
      targetAccount: {
        id: account.id,
        name: account.name,
        isActive: account.is_active ?? false,
      },
      sourceAccountId: accountMapped ? accountMapped.source_id : null,
      parentId: account.parent_id,
    });
  });
  return tableData;
}

function createTableTree(
  flatData: AccountMappingDetailsTableData[]
): AccountMappingDetailsTableData[] {
  const accountHashTable = Object.create(null);

  flatData.forEach((account) => (accountHashTable[account.targetAccount.id] = { ...account }));

  let accountsTableDataTree: AccountMappingDetailsTableData[] = [];

  flatData.forEach((account) => {
    if (account.parentId) {
      if (!accountHashTable[account.parentId].subRows) {
        accountHashTable[account.parentId].subRows = [];
      }

      accountHashTable[account.parentId].subRows.push(accountHashTable[account.targetAccount.id]);
    } else accountsTableDataTree.push(accountHashTable[account.targetAccount.id]);
  });

  return accountsTableDataTree;
}

export function mapDataToAccountMappingDetailsTable(
  targetAccounts: AccountResponse[],
  mappedAccounts: AccountMapResponse[]
) {
  const flatData = mapAccountsToFlatData(targetAccounts, mappedAccounts);
  const tableDataTree = createTableTree(flatData);
  return tableDataTree;
}
