import axios from "axios";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { queryClient } from "@/utils/queryClient";

import { TrialBalanceVersionLineServices } from "./trialbalance-version-line.services";
import { TrialBalanceLineResponse } from "./trialbalance-version-line.types";
import { TrialBalanceVersionServices } from "./trialbalance-version.services";

export const TRIAL_BALANCE_VERSION_LINE_QUERY_KEY = "trialBalanceVersionLine";

type UseTrialBalanceLinesReturn = {
  trialBalanceLines: TrialBalanceLineResponse[];
  isTrialBalanceLinesLoading: boolean;
  markAsReviewed: (props: MarkAsReviewedProps) => Promise<void>;
  isMarkAsReviwedLoading: boolean;
  openReview: (props: OpenReviewProps) => Promise<void>;
  isOpenReviewLoading: boolean;
};

type MarkAsReviewedProps = {
  reviewNote: string;
  onSuccess: () => void;
};

type OpenReviewProps = {
  reviewNote: string;
  flaggedLineIds: number[];
  onSuccess: () => void;
  onError: (error: string) => void;
};

const handleGetAllTrialBalanceLines = async (trialBalanceId: number, versionId: number) => {
  return await TrialBalanceVersionLineServices.fetchAll(trialBalanceId, versionId);
};

const handleMarkAsReviewed = async (
  trialBalanceId: number,
  versionId: number,
  reviewNote: string
) => {
  return await TrialBalanceVersionServices.update(trialBalanceId, versionId, {
    is_reviewed: true,
    review_message: reviewNote,
  });
};

const handleOpenReview = async (
  trialBalanceId: number,
  versionId: number,
  reviewNote: string,
  flaggedLines: number[]
): Promise<void> => {
  const updateTrialBalancePromise = new Promise((resolve, reject) => {
    TrialBalanceVersionServices.update(trialBalanceId, versionId, {
      is_reviewed: false,
      review_message: reviewNote,
    })
      .then(resolve)
      .catch(reject);
  });

  const updateTrialBalanceLinesPromise = new Promise((resolve, reject) => {
    Promise.all(
      flaggedLines.map((lineId) => {
        return TrialBalanceVersionLineServices.update(trialBalanceId, versionId, lineId, {
          is_flagged: true,
        });
      })
    )
      .then(resolve)
      .catch(reject);
  });

  await Promise.all([updateTrialBalancePromise, updateTrialBalanceLinesPromise]);
};

export function useTrialBalanceLines(
  trialBalanceId: number,
  versionId?: number
): UseTrialBalanceLinesReturn {
  const trialBalanceQuery = useQuery(
    [TRIAL_BALANCE_VERSION_LINE_QUERY_KEY, trialBalanceId, versionId],
    async () => {
      if (!versionId) return [];
      return await handleGetAllTrialBalanceLines(trialBalanceId, versionId);
    }
  );

  const openReviewMutation = useMutation(
    TRIAL_BALANCE_VERSION_LINE_QUERY_KEY,
    async ({ reviewNote, flaggedLineIds }: OpenReviewProps) => {
      if (versionId) {
        return await handleOpenReview(trialBalanceId, versionId, reviewNote, flaggedLineIds);
      }
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(TRIAL_BALANCE_VERSION_LINE_QUERY_KEY);
        queryClient.invalidateQueries("ledgers");
        onSuccess();
      },
      onError: (error: any, { onError }) => {
        if (axios.isAxiosError(error)) {
          onError(error.response?.data.detail);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const markAsReviewedMutation = useMutation(
    TRIAL_BALANCE_VERSION_LINE_QUERY_KEY,
    async ({ reviewNote }: MarkAsReviewedProps) => {
      if (versionId) {
        return await handleMarkAsReviewed(trialBalanceId, versionId, reviewNote);
      }
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(TRIAL_BALANCE_VERSION_LINE_QUERY_KEY);
        queryClient.invalidateQueries("ledgers");
        onSuccess();
      },
    }
  );

  const markAsReviewed = async (props: MarkAsReviewedProps) => {
    await markAsReviewedMutation.mutateAsync(props);
    await trialBalanceQuery.refetch();
  };

  const openReview = async (props: OpenReviewProps) => {
    await openReviewMutation.mutateAsync(props);
    await trialBalanceQuery.refetch();
  };

  const trialBalanceLines = useMemo(() => {
    return trialBalanceQuery.data || [];
  }, [trialBalanceQuery.data]);

  return {
    trialBalanceLines,
    isTrialBalanceLinesLoading: trialBalanceQuery.isLoading,
    markAsReviewed,
    isMarkAsReviwedLoading: markAsReviewedMutation.isLoading,
    openReview,
    isOpenReviewLoading: openReviewMutation.isLoading,
  };
}
