import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { EditM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";

import { useDimensions } from "@/hooks/useDimensions";
import { useDimensionTypes } from "@/hooks/useDimensionTypes";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { leftLayoutContent } from "../DataManagementLeftLayoutContent";
import * as Styled from "./dimensions.styles";

type CreateDimensionParams = {
  typeid: string;
};

type CreateDimensionFormValues = {
  title: string;
  description: string;
  type_id: number;
};

const createDimensionSchema = yup.object().shape({
  title: yup.string().required("Title required"),
  description: yup.string(),
  type_id: yup.number().required("Type required"),
});

export function DimensionsFormCreate() {
  const validate = useValidationSchema(createDimensionSchema);
  const { allDimensionTypes } = useDimensionTypes();
  const { createDimension } = useDimensions();
  const [collapseContent, setCollapseContent] = useState(false);
  const navigate = useNavigate();
  const { typeid } = useParams<CreateDimensionParams>();

  const dimensionTypeId = typeid ? Number(typeid) : undefined;

  async function onSubmit(values: CreateDimensionFormValues) {
    createDimension.mutateAsync({
      dimensionId: values.type_id,
      dimensionValuePayload: {
        title: values.title,
        description: values.description,
      },
      onSuccess: () => {
        message.success("Dimension has been created");
        navigate(-1);
      },
    });
  }

  const initialValues = {
    title: "",
    description: "",
    type_id: dimensionTypeId,
  };

  return (
    <Layout
      left={leftLayoutContent}
      header={<PageHeader onGoBack={() => navigate(-1)} title="New dimension value" />}
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Card
                    background="white-shadow"
                    lively
                    hideContent={collapseContent}
                    onHeaderClick={() => setCollapseContent(!collapseContent)}
                    title="Basic Information"
                    withHeader
                    icon={<EditM />}
                    iconColor={"#AEAEAE"}
                    headerSideChildren={
                      <Styled.CardHeaderSideChildrenContainer>
                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                        <Button
                          onClick={handleSubmit}
                          type="primary"
                          loading={createDimension.isLoading}
                          disabled={createDimension.isLoading}
                        >
                          Save
                        </Button>
                      </Styled.CardHeaderSideChildrenContainer>
                    }
                  >
                    <ModuleStyled.InputWrapper>
                      <Field
                        name="title"
                        render={({ input, meta }) => (
                          <Input {...input} label="Title" errorText={meta.touched && meta.error} />
                        )}
                      />
                      <Field
                        name="description"
                        render={({ input, meta }) => (
                          <Input
                            {...input}
                            label="Description"
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      />

                      <Field
                        name="type_id"
                        render={({ input, meta }) => (
                          <Select
                            onChange={input.onChange}
                            label="Dimension"
                            defaultValue={initialValues.type_id}
                            errorText={meta.touched && meta.error}
                          >
                            {allDimensionTypes.map((dimensionType) => (
                              <Select.Option key={dimensionType.id} value={dimensionType.id}>
                                {dimensionType.title}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      />
                    </ModuleStyled.InputWrapper>
                  </Card>
                </form>
              );
            }}
          />
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
