import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { EditM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";

import { useDimension, useDimensionTypes } from "@/hooks/useDimensionTypes";
import { useEntities } from "@/hooks/useEntities";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { dataManagementData } from "../data-management.data";

type EditDimensionTypeFormValues = {
  title: string;
  description?: string;
  entity_id?: number;
};

const editDimensionTypeSchema = yup.object().shape({
  title: yup.string().required("Title required"),
  description: yup.string(),
  entity_id: yup.number().nullable(true),
});

type EditDimensionTypeParams = {
  id: string;
};

export function DimensionTypesFormEdit() {
  const navigate = useNavigate();
  const { updateDimensionType } = useDimensionTypes();
  const { id } = useParams<EditDimensionTypeParams>();
  const validate = useValidationSchema(editDimensionTypeSchema);
  const { createDimensionType } = useDimensionTypes();
  const { allEntities } = useEntities();

  const { dimensionType } = useDimension(Number(id));

  async function onSubmit(values: EditDimensionTypeFormValues) {
    function onSuccess() {
      message.success("Dimension has been updated");
      navigate(dataManagementData.dimensionTypes.path);
    }

    function onError(text: string) {
      message.error(text);
    }

    await updateDimensionType.mutateAsync({
      payload: values,
      id: Number(id),
      onSuccess,
      onError,
    });
  }

  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate(dataManagementData.dimensionTypes.path)}
          title={`Editing ${dimensionType?.title}`}
        />
      }
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Form<EditDimensionTypeFormValues>
            onSubmit={onSubmit}
            initialValues={{
              title: dimensionType?.title,
              description: dimensionType?.description,
              entity_id: dimensionType?.entity_id,
            }}
            validate={validate}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Card
                    background="white-shadow"
                    lively
                    title="Basic information"
                    withHeader
                    icon={<EditM />}
                    iconColor={"#AEAEAE"}
                    headerSideChildren={
                      <ModuleStyled.HeaderSideChildrenContainer>
                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                        <Button
                          onClick={handleSubmit}
                          type="primary"
                          disabled={createDimensionType.isLoading}
                          loading={createDimensionType.isLoading}
                        >
                          Save
                        </Button>
                      </ModuleStyled.HeaderSideChildrenContainer>
                    }
                  >
                    <ModuleStyled.InputWrapper>
                      <Field
                        name="title"
                        render={({ input, meta }) => (
                          <Input {...input} label="Title" errorText={meta.touched && meta.error} />
                        )}
                      />

                      <Field
                        name="description"
                        render={({ input, meta }) => (
                          <Input
                            {...input}
                            label="Description"
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      />

                      <Field
                        name="entity_id"
                        render={({ input, meta }) => (
                          <Select
                            onChange={input.onChange}
                            value={input.value}
                            label="Entity"
                            errorText={meta.touched && meta.error}
                          >
                            {allEntities.map((entity) => (
                              <Select.Option key={entity.id} value={entity.id}>
                                {entity.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      />
                    </ModuleStyled.InputWrapper>
                  </Card>
                </form>
              );
            }}
          />
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
