import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

import { useChartOfAccounts } from "@/hooks/useChartOfAccounts";

import { dataManagementData } from "../../data-management.data";
import { leftLayoutContent } from "../../DataManagementLeftLayoutContent";
import { ModalAddAccountMapping } from "./AccountMappingCreateModal";
import { AccountsMappingTable } from "./AccountsMappingTable";
import { useAccountsMappingContext } from "./hooks/useAccountsMappingContext";

export function AccountsMappingContent() {
  const navigate = useNavigate();
  const { setAddMappingModalOpen, addMappingModalOpen } = useAccountsMappingContext();
  const { allChartOfAccounts } = useChartOfAccounts();

  return (
    <>
      <Layout
        left={leftLayoutContent}
        header={
          <PageHeader
            onGoBack={() => navigate(dataManagementData.home.path)}
            title="Accounts Mapping"
            rightSide={
              <Button type="primary" onClick={() => setAddMappingModalOpen(true)}>
                Add new mapping
              </Button>
            }
          />
        }
      >
        <AccountsMappingTable />
      </Layout>
      <ModalAddAccountMapping
        visible={addMappingModalOpen}
        closeModal={() => setAddMappingModalOpen(false)}
        charts={allChartOfAccounts}
      />
    </>
  );
}
