import styled from "styled-components";

import { theme } from "@/styles/theme";

export const Header = styled.div`
  padding-bottom: ${theme.spacing.m};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .right-side {
    display: flex;
    gap: ${theme.spacing.m};
  }
`;

export const Title = styled.span`
  font-weight: ${theme.fontWeight.bold};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: ${theme.spacing.m};
`;

export const InputsWrapper = styled.div`
  display: grid;
  height: max-content;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing.s};
  min-height: 0;
`;
