import { MeetingDescriptionHeader } from "../MeetingDescriptionHeader";
import * as Styled from "./styles";

export function MeetingDescription() {
  return (
    <Styled.Container>
      <MeetingDescriptionHeader />
      <Styled.Description>
        <p>Hi everyone.</p>
        <p>
          First quarter is closed and we will review our performance up until now. The year has
          started with plenty of opportunities and we are confident we can hit our goal of 100%
          growth in ARR.
        </p>
        <p>
          I'll leave the presentations we will use during the meeting, as well as a live scenario
          planning that we should walk through together.
        </p>
        <p>Thank you!</p>
      </Styled.Description>
    </Styled.Container>
  );
}
