import { api } from "@/utils/http";

import { KpiSeriesResponse } from "./kpi-series.types";
import {
  KpiValueCommentCreatePayload,
  KpiValueCommentResponse,
  KpiValueCommentUpdatePayload,
} from "./kpi-value-comment.types";
import {
  KpiValueCreateManyPayload,
  KpiValueResponse,
  KpiValueUpdatePayload,
} from "./kpi-value.types";
import { DataSource, Periodicity } from "./kpi.types";

type FetchParams = {
  entity_id?: number;
  data_sources?: DataSource[];
  series_ids?: number[];
  periodicity?: Periodicity;
  start_date?: string;
  end_date?: string;
  has_comments?: boolean;
};

export class KPIValueService {
  static async fetchAll(kpiId: number, params?: FetchParams): Promise<KpiSeriesResponse[]> {
    const response = await api.get<KpiSeriesResponse[]>(`/kpis/${kpiId}/values`, {
      params,
    });
    return response.data;
  }

  static async fetchOne(kpiId: number, valueId: number): Promise<KpiValueResponse> {
    const response = await api.get<KpiValueResponse>(`/kpis/${kpiId}/values/${valueId}`);
    return response.data;
  }

  static async create(
    kpiId: number,
    payload: KpiValueCreateManyPayload
  ): Promise<KpiValueResponse[]> {
    const response = await api.post<KpiValueResponse[]>(`/kpis/${kpiId}/values`, payload);
    return response.data;
  }

  static async update(
    kpiId: number,
    valueId: number,
    payload: KpiValueUpdatePayload
  ): Promise<KpiValueResponse> {
    const response = await api.patch<KpiValueResponse>(`/kpis/${kpiId}/values/${valueId}`, payload);
    return response.data;
  }

  static async delete(kpiId: number, valueId: number): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(`/kpis/${kpiId}/values/${valueId}`);
    return response.data;
  }

  static async fetchComments(kpiId: number, valueId: number): Promise<KpiValueCommentResponse[]> {
    const response = await api.get<KpiValueCommentResponse[]>(
      `/kpis/${kpiId}/values/${valueId}/comments`
    );
    return response.data;
  }

  static async createComment(
    kpiId: number,
    valueId: number,
    payload: KpiValueCommentCreatePayload
  ): Promise<KpiValueCommentResponse> {
    const response = await api.post<KpiValueCommentResponse>(
      `/kpis/${kpiId}/values/${valueId}/comments`,
      payload
    );
    return response.data;
  }

  static async updateComment(
    kpiId: number,
    valueId: number,
    commentId: number,
    payload: KpiValueCommentUpdatePayload
  ): Promise<KpiValueCommentResponse> {
    const response = await api.put<KpiValueCommentResponse>(
      `/kpis/${kpiId}/values/${valueId}/comments/${commentId}`,
      payload
    );
    return response.data;
  }

  static async deleteComment(
    kpiId: number,
    valueId: number,
    commentId: number
  ): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(
      `/kpis/${kpiId}/values/${valueId}/comments/${commentId}`
    );
    return response.data;
  }
}
