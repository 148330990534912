type PeriodicityOption = {
  label: string;
  value: string;
};

export const periodicityOptions: PeriodicityOption[] = [
  {
    label: "Quarter",
    value: "quarter",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Year",
    value: "year",
  },
];

type ValueTypeOption = {
  label: string;
  value: string;
};

export const incomeStatementTypeOptions: ValueTypeOption[] = [
  {
    value: "consolidated",
    label: "Consolidated",
  },
];

export const incomeStatementOpticsOptions: ValueTypeOption[] = [
  {
    value: "time-series",
    label: "Time Series",
  },
  {
    value: "waterfall",
    label: "Waterfall",
  },
  {
    value: "static",
    label: "Static",
  },
];

type DataSourceOption = {
  value: string;
  label: string;
};

export const dataSourceOptions: DataSourceOption[] = [
  {
    value: "actual",
    label: "Actual",
  },
  {
    value: "budget",
    label: "Budget",
  },
  {
    value: "forecast",
    label: "Forecast",
  },
];
