import { CellProps, Column } from "react-table";

import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";

import { formatDateToColumnHeader } from "../utils/formatDateToColumnHeader";
import { FinancialsBSTableData, FinancialsBSValueTableData } from "./financials-bs.types";
import { Periodicity } from "./financials-bs.types";

function getValueColumns(values: FinancialsBSValueTableData[], periodicity: Periodicity) {
  const valueColumns: Column<FinancialsBSTableData>[] = values.map((value) => ({
    Header: formatDateToColumnHeader(periodicity, new Date(value.datetime)),
    id: String(value.id),
    defaultCanGroupBy: false,
    accessor: (originalRow: any) => {
      return (
        <div
          style={{
            color: "#848484",
            fontWeight: "400",
            fontStyle: originalRow.calculatedRow ? "italic" : "normal",
          }}
        >
          {originalRow.values.find((v: any) => v.datetime === value.datetime)?.value}
        </div>
      );
    },
  }));

  return valueColumns;
}

export function tableColumns(values: FinancialsBSValueTableData[], periodicity: Periodicity) {
  const columns = [
    {
      Header: "",
      accessor: "group.title",
      disableFilters: true,
      defaultCanSort: false,
      defaultCanGroupBy: false,
      Cell: ({ value }: CellProps<FinancialsBSTableData>) => {
        if (!value) return null;

        return <UserTypeCell type={value} />;
      },
    },

    ...getValueColumns(values, periodicity),
  ];

  return columns;
}
