import { useMemo } from "react";
import { useTable } from "react-table";

import * as Styled from "@/components/Table/styles";

import { tableColumns } from "./columns";
import fakeData from "./fake-data";
import { JournalTableTableData } from "./types";

export function JournalTable() {
  const data = useMemo(() => {
    return fakeData;
  }, [fakeData]);

  const columns = useMemo(() => {
    return tableColumns();
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<JournalTableTableData>({
      columns,
      data,
    });

  return (
    <div>
      <Styled.Table {...getTableProps()}>
        <Styled.TableHead>
          {headerGroups.map((headerGroup) => (
            <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Styled.TableHeader {...column.getHeaderProps()}>
                  <Styled.TableCellWithFiltersWrapper>
                    {column.render("Header")}
                  </Styled.TableCellWithFiltersWrapper>
                </Styled.TableHeader>
              ))}
            </Styled.TableHeaderRow>
          ))}
        </Styled.TableHead>
        <Styled.TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Styled.TableBodyRow {...row.getRowProps()} isSelected={row.isSelected}>
                {row.cells.map((cell) => {
                  return (
                    <Styled.TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </Styled.TableCell>
                  );
                })}
              </Styled.TableBodyRow>
            );
          })}
        </Styled.TableBody>
      </Styled.Table>
    </div>
  );
}
