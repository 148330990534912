import { useState } from "react";

import Button from "@synerise/ds-button";
import Checkbox from "@synerise/ds-checkbox";
import { TextArea } from "@synerise/ds-input";
import Modal from "@synerise/ds-modal";

type DialogProps = {
  isVisible: boolean;
  setDialogVisible: (isVisible: boolean) => void;
  onSubmitReview: (reviewNote: string) => Promise<void>;
  isSubmitLoading: boolean;
};

export function LedgerDetailsReviewCloseDialog({
  isVisible,
  isSubmitLoading,
  setDialogVisible,
  onSubmitReview,
}: DialogProps) {
  const [reviewNote, setReviewNote] = useState("");

  function closeModal() {
    setReviewNote("");
    setDialogVisible(false);
  }

  function submitReview() {
    onSubmitReview(reviewNote);
    setReviewNote("");
    closeModal();
  }

  return (
    <div>
      <Modal
        confirmLoading={isSubmitLoading}
        title="Mark Trial Balance as Reviewed"
        visible={isVisible}
        onOk={() => setDialogVisible(false)}
        onCancel={() => setDialogVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => closeModal()}>
            Cancel
          </Button>,
          <Button key="submit" onClick={() => submitReview()} type="primary">
            Mark as reviewed
          </Button>,
        ]}
      >
        This will mark this Trial Balance as Reviewed and completed. You can choose to leave a note
        to this action, and to notify your accountant.
        <div style={{ marginTop: "20px" }}>
          <TextArea
            value={reviewNote}
            onChange={(e: any) => setReviewNote(e.target.value)}
            type="area"
            counterLimit={100}
            label="Review Note"
            description="Leave a note for the review"
            rows={3}
          />
          <Checkbox>Notify accountant</Checkbox>
        </div>
      </Modal>
    </div>
  );
}
