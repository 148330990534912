import { ItemType } from "@/components/LayoutBuilder/LayoutBuilder.types";

import { useGridLayout } from "../../hooks/useGridLayout";
import * as Styled from "./grid-items-drawer.styles";

type DrawerItemProps = {
  Icon: React.ReactNode;
  text: string;
  description: string;
  elementType: ItemType;
};

export function GridItemsDrawerDrawerItem({
  Icon,
  elementType,
  text,
  description,
}: DrawerItemProps) {
  const { handleDragStart } = useGridLayout();

  return (
    <div
      draggable={true}
      unselectable="on"
      onDragStart={(e) => {
        handleDragStart(e, elementType);
      }}
    >
      <Styled.DrawerItemContainer>
        {Icon}
        <div className="right-content">
          <span style={{ fontWeight: "bold" }}>{text}</span>
          <span>{description}</span>
        </div>
      </Styled.DrawerItemContainer>
    </div>
  );
}
