import { BudgetValue } from "../useKPICreateBudget";

export function calculateLinearAmountBudgetValues(
  kpiValues: number[],
  monthsQuantity: number,
  linearAmount: number,
  initialDate: Date
) {
  const lastValueFromKPI = kpiValues[kpiValues.length - 1] || 0;

  const linearAmountBudgetValues: BudgetValue[] = [...Array(monthsQuantity)].map((_, index) => {
    const budgetValue = {
      amount: lastValueFromKPI + (index + 1) * linearAmount,
      date: new Date(initialDate.getFullYear(), initialDate.getMonth() + index - 1, 1),
    };

    return budgetValue;
  });

  return linearAmountBudgetValues;
}
