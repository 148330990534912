import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { queryClient } from "@/utils/queryClient";

import { reportsData, REPORTS_QUERY_KEY } from "../reports.config";
import { ReportService } from "../reports.services";
import { ReportResponseShort } from "../reports.types";
import { ReportsSidebarMenu } from "../ReportsSidebarMenu";
import { ReportEditModal } from "./ReportEditModal";
import { ReportsListTable } from "./ReportsLibraryTable";
import { FilterOption } from "./ReportsList.types";
import { ReportListUtils } from "./ReportsListUtils";

export function ReportsList() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState<FilterOption[]>([]);
  const [tagsFilter, setTagsFilter] = useState<number[]>([]);
  const [editingReport, setEditingReport] = useState<ReportResponseShort | null>(null);
  const [reports, setReports] = useState<ReportResponseShort[]>([]);
  const navigate = useNavigate();
  const { data: reportsResponse, isLoading } = useQuery(
    [REPORTS_QUERY_KEY],
    ReportService.fetchAll
  );

  const handleAddFilter = useCallback((filter: FilterOption) => {
    setFiltersSelected([filter]);
  }, []);

  const handleAddTagFilter = (id: number) => {
    setTagsFilter((prevState) => [...prevState, id]);
  };

  const handleRemoveTagFilter = (id: number) => {
    setTagsFilter((prevState) => prevState.filter((tag) => tag !== id));
  };

  useEffect(() => {
    if (!reportsResponse) return;
    const filteredReports = ReportListUtils.filterReports(
      reportsResponse,
      filtersSelected,
      tagsFilter
    );
    setReports(filteredReports);
  }, [tagsFilter, filtersSelected]);

  useEffect(() => {
    if (reportsResponse) setReports(reportsResponse);
  }, [reportsResponse]);

  function handleToggleEditModal() {
    setIsEditModalOpen(!isEditModalOpen);
    setEditingReport(null);
  }

  const handleEditReport = useCallback(
    (id: number) => {
      const report = reports?.find((report) => report.id === id);
      if (!report) return;
      setEditingReport(report);
      setIsEditModalOpen(true);
    },
    [reports]
  );

  const handleViewReport = useCallback((id: number) => {
    navigate(`/reports/builder/${id}`);
  }, []);

  const removeReportMutation = useMutation(
    [REPORTS_QUERY_KEY],
    async (id: number) => ReportService.delete(id),
    {
      onMutate: async (id) => {
        await queryClient.invalidateQueries([REPORTS_QUERY_KEY]);
        const previousReports = queryClient.getQueryData<ReportResponseShort[]>(REPORTS_QUERY_KEY);
        if (previousReports) {
          const newReports = previousReports.filter((report) => report.id !== id);
          queryClient.setQueryData(REPORTS_QUERY_KEY, newReports);
        }

        return {
          previousReports: previousReports,
        };
      },
      onError(err, _, context: { previousReports?: ReportResponseShort[] } | undefined) {
        queryClient.setQueryData(REPORTS_QUERY_KEY, context?.previousReports);
      },
    }
  );

  const handleRemoveReport = useCallback(async (id: number) => {
    await removeReportMutation.mutateAsync(id);
    message.success("Report has been removed");
  }, []);

  const handleTagSelectChange = useCallback((id: number, checked: boolean) => {
    if (checked) {
      handleAddTagFilter(id);
    } else {
      handleRemoveTagFilter(id);
    }
  }, []);

  return (
    <Layout
      left={{
        content: (
          <ReportsSidebarMenu
            selectedFilters={filtersSelected}
            onAddFilter={handleAddFilter}
            onResetFilters={() => setFiltersSelected([])}
            tagsSelected={tagsFilter}
            onTagSelectChange={handleTagSelectChange}
          />
        ),
        opened: true,
        onChange: () => {},
        width: 259,
      }}
      header={
        <PageHeader
          onGoBack={() => navigate(-1)}
          title="Report List"
          rightSide={
            <Button type="primary" onClick={() => navigate(reportsData.list.createPath)}>
              New Report
            </Button>
          }
        />
      }
    >
      <ReportsListTable
        reports={ReportListUtils.mapReportsResponseToList(reports || [])}
        isLoading={isLoading}
        onRemoveReport={handleRemoveReport}
        onEditReport={handleEditReport}
        onViewReport={handleViewReport}
      />
      {editingReport && (
        <ReportEditModal
          isOpen={isEditModalOpen}
          onToggle={handleToggleEditModal}
          editingReport={editingReport}
        />
      )}
    </Layout>
  );
}
