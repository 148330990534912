import { useMemo } from "react";
import { Column, useTable } from "react-table";

import Typography from "@synerise/ds-typography";

import { EmptyState } from "@/components/EmptyState";
import * as TableStyled from "@/components/Table/styles";

import { TableData } from "../GridItemsTable.mock.data";
import * as Styled from "./Table.styles";

const { Text } = Typography;

type TableProps = {
  title?: string;
  tableData: TableData | null;
  isSelected: boolean;
};
export function Table({ title, tableData, isSelected }: TableProps) {
  const columns: Column[] = useMemo(() => {
    return tableData && tableData.columns ? tableData.columns : [];
  }, [tableData]);

  const data = useMemo(() => {
    return tableData && tableData ? tableData.data : [];
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns as Column<any>[],
    data,
  });

  return (
    <Styled.Container isSelected={isSelected}>
      {title && (
        <Text style={{ fontWeight: "bold", fontSize: "18px", marginBottom: "4px" }}>{title}</Text>
      )}
      <TableStyled.Table {...getTableProps()}>
        <TableStyled.TableHead>
          {headerGroups.map((headerGroup) => (
            <TableStyled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableStyled.TableHeader {...column.getHeaderProps()}>
                  <TableStyled.TableCellWithFiltersWrapper>
                    {column.render("Header")}
                  </TableStyled.TableCellWithFiltersWrapper>
                </TableStyled.TableHeader>
              ))}
            </TableStyled.TableHeaderRow>
          ))}
        </TableStyled.TableHead>
        <TableStyled.TableBody {...getTableBodyProps()}>
          {rows.length < 1 ? (
            <tr>
              <td>
                <EmptyState
                  style={{ marginTop: "16px" }}
                  title="No data"
                  text="You can add data on the right panel"
                  showButton={false}
                />
              </td>
            </tr>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableStyled.TableBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableStyled.TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableStyled.TableCell>
                    );
                  })}
                </TableStyled.TableBodyRow>
              );
            })
          )}
        </TableStyled.TableBody>
      </TableStyled.Table>
    </Styled.Container>
  );
}
