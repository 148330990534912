import { useState } from "react";

import Layout from "@synerise/ds-layout";

import { innerMenu } from "../components/innerMenu";
import { KPIDataSourceStatusModal } from "./components/KPIDataSourceStatusModal.tsx";
import { KPIRequestIntegrationModal } from "./components/KPIRequestIntegrationModal.tsx";
import { KPISourcesHeader } from "./components/KPISourcesHeader";
import { KPISourcesSection } from "./components/KPISourcesSection";
import KPISourcesData from "./KPISources.data";

export function KPISources() {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(true);
  const [isRequestIntegrationModalOpen, setIsRequestIntegrationModalOpen] = useState(false);
  const [dataSourceSelected, setDataSourceSelected] = useState<string>("");

  const handleOkStatus = () => {
    setIsStatusModalOpen(false);
  };

  const handleRequestNewDataSource = (title?: string) => {
    setDataSourceSelected(title || "");
    setIsRequestIntegrationModalOpen(true);
  };

  const handleCancelRequestNewDataSource = () => {
    setDataSourceSelected("");
    setIsRequestIntegrationModalOpen(false);
  };

  return (
    <>
      <Layout
        left={innerMenu}
        header={<KPISourcesHeader onRequestNewDataSource={handleRequestNewDataSource} />}
      >
        {KPISourcesData.map((sourceGroup) => (
          <KPISourcesSection
            title={sourceGroup.groupTitle}
            cards={sourceGroup.items}
            onRequestNewDataSource={handleRequestNewDataSource}
          />
        ))}
      </Layout>
      <KPIDataSourceStatusModal isVisible={isStatusModalOpen} onOk={handleOkStatus} />
      <KPIRequestIntegrationModal
        initialDataSourceTitle={dataSourceSelected}
        isVisible={isRequestIntegrationModalOpen}
        onClose={handleCancelRequestNewDataSource}
      />
    </>
  );
}
