import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { FlagM, ChatM } from "@synerise/ds-icon";
import Tooltip from "@synerise/ds-tooltip";

import { CheckCell } from "@/components/Table/Cells/CheckCell";
import { TextCell } from "@/components/Table/Cells/HeaderCell";
import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as Styled from "@/components/Table/styles";

import { LedgerDetailsTableData } from "./ledger-details.types";

type ColumnType = Column<LedgerDetailsTableData> & { align: "right" | "center" | "left" };

type TableColumnsProps = {
  handleFlagLine: (lineId: number, isFlagged: boolean) => Promise<void>;
  openLineCommentsDrawer: (lineId: number) => void;
  onAccountClick: () => void;
};

export const tableColumns = ({
  handleFlagLine,
  openLineCommentsDrawer,
  onAccountClick,
}: TableColumnsProps) => {
  const columns: ColumnType[] = [
    {
      Header: "Code",
      accessor: "code",
      align: "left",
      Cell: ({ row }) => <UserTypeCell type={row.original.code} fontWeight={400} />,
    },
    {
      Header: "Classification",
      accessor: "classification",
      align: "left",
      Cell: ({ row }) => <UserTypeCell type={row.original.classification} fontWeight={400} />,
    },
    {
      Header: "Account",
      accessor: "account",
      align: "left",
      Cell: ({ row }) => (
        <UserTypeCell
          type={row.original.account}
          fontWeight={400}
          className="account-cell"
          onClick={onAccountClick}
        />
      ),
    },
    {
      Header: "Initial Balance",
      accessor: "initial_balance",
      align: "right",
      Cell: ({ row }) => <TextCell textAlign="right">{row.original.initial_balance}</TextCell>,
    },
    {
      Header: "Credit",
      accessor: "credit",
      align: "right",
      Cell: ({ row }) => <TextCell textAlign="right">{row.original.credit}</TextCell>,
    },
    {
      Header: "Debit",
      accessor: "debit",
      align: "right",
      Cell: ({ row }) => <TextCell textAlign="right">{row.original.debit}</TextCell>,
    },
    {
      Header: "Final Balance",
      accessor: "final_balance",
      align: "right",
      Cell: ({ row }) => <TextCell textAlign="right">{row.original.final_balance}</TextCell>,
    },
    {
      Header: "Inconsistency Value",
      accessor: "inconsistency_value",
      align: "center",
      Cell: ({ row }) => (
        <TextCell textAlign="center">{row.original.inconsistency_value || "-"}</TextCell>
      ),
    },
    {
      Header: "Consistency",
      accessor: "consistency",
      align: "center",
      Cell: ({ row }) => <CheckCell checked={row.original.consistency} />,
    },
    {
      Header: "Actions",
      accessor: "id",
      align: "center",
      Cell: ({ row }) => {
        return (
          <Styled.RowActionButtons>
            <Button
              mode="single-icon"
              type="ghost-white"
              onClick={() => openLineCommentsDrawer(row.original.id)}
            >
              <Icon component={<ChatM />} color="#C4C4C4" />
            </Button>
            <Tooltip title="Flag Account" offset="small">
              <Button
                mode="single-icon"
                type="ghost-white"
                onClick={() => handleFlagLine(row.original.id, !row.original.isFlagged)}
              >
                <Icon color={row.original.isFlagged ? "red" : "#C4C4C4"} component={<FlagM />} />
              </Button>
            </Tooltip>
          </Styled.RowActionButtons>
        );
      },
    },
  ];

  return columns;
};
