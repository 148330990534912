import { Tree, MultiBackend, getBackendOptions } from "@minoru/react-dnd-treeview";
import { useState } from "react";
import { DndProvider } from "react-dnd";

import { theme } from "@/styles/theme";

import { CustomNode } from "./CustomNode";
import SampleData from "./data.json";
import * as Styled from "./styles";

import "./styles.css";

export function ForecastLeftSidebar() {
  const [treeData, setTreeData] = useState(SampleData);
  const handleDrop = (newTree: any) => setTreeData(newTree);

  return (
    <Styled.Container>
      <Styled.Title>Forecasting</Styled.Title>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <div style={{ height: "100%", paddingLeft: theme.spacing.m }}>
          <Tree
            classes={{
              listItem: "forecast-left-sidebar--list forecast-left-sidebar--bordered",
              root: "forecast-left-sidebar--root",
              dropTarget: "forecast-left-sidebar--droptarget",
            }}
            tree={treeData}
            rootId={0}
            render={(node, { depth, isOpen, onToggle }) => (
              <CustomNode node={node} depth={depth} isOpen={isOpen} onToggle={onToggle} />
            )}
            // dragPreviewRender={(monitorProps) => <CustomDragPreview monitorProps={monitorProps} />}
            onDrop={handleDrop}
            initialOpen={true}
          />
        </div>
      </DndProvider>
    </Styled.Container>
  );
}
