import styled from "styled-components";

export const Container = styled.div`
  overflow-y: scroll;
`;

export const MaxWidthContent = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  padding: 3rem 0;
`;

export const CardsGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin: 1rem 0;
`;

export const ModulesContainer = styled.div`
  margin-bottom: 2rem;
`;
