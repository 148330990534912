import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, useFilters, useRowSelect, useSortBy, useTable } from "react-table";

import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";

import { EmptyState } from "@/components/EmptyState";
import { SortDateIcon } from "@/components/SortIcons/SortDateIcon";
import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import * as Styled from "@/components/Table/styles";

import { leftLayoutContent } from "../../DataManagementLeftLayoutContent";
import { TrialBalanceImportFileModal } from "../Ledger/TrialBalanceImportFileModal";
import { periodicityOptions, reviewedOptions } from "../ledgers.config";
import * as LedgerStyles from "../ledgers.styles";
import { Periodicity } from "../trialbalance.types";
import { useLedgers } from "../useLedgers";
import { mapTrialBalancesToTable } from "../useLedgers";
import { LedgerListDetails } from "./components/LedgerListDetails";
import { PageHeaderActions } from "./components/PageHeaderActions/PageHeaderActions";
import { tableColumns } from "./ledger-list.columns";

function transformReviewedFilterValue(value: string) {
  if (value === "all") return undefined;
  return value === "true";
}

function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
  const options = useMemo(() => {
    const options: any[] = [];
    preFilteredRows.forEach((row: any) => {
      if (!options.includes(row.values[id])) {
        options.push(row.values[id]);
      }
    });
    return options;
  }, [id, preFilteredRows]);

  return (
    <Select
      placeholder="All"
      style={{ minWidth: "100px" }}
      dropdownMatchSelectWidth={false}
      value={filterValue}
      onChange={(value: any) => {
        setFilter(value || undefined);
      }}
    >
      <Select.Option value="">All</Select.Option>
      {options.map((option, i) => (
        <Select.Option key={i} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  );
}

export function LedgerList() {
  const navigate = useNavigate();
  const [isImportTrialBalanceFileModalOpen, setIsImportTrialBalanceFileModalOpen] = useState(false);
  const [periodicitySelected, setPeriodicitySelected] = useState<Periodicity | "">("");
  const [reviewedFilter, setReviewedFilter] = useState<"all" | "true" | "false">("all");
  const { allLedgers, removeLedger, isRemoveLedgerLoading, loadingAllLedgers } = useLedgers({
    fetchParams: {
      periodicity: periodicitySelected || undefined,
      is_reviewed: transformReviewedFilterValue(reviewedFilter),
    },
  });

  const tableData = useMemo(() => {
    return mapTrialBalancesToTable(allLedgers);
  }, [allLedgers]);

  async function handleViewLedgerDetails(id: number) {
    navigate(`/data/ledgers/${id}`);
  }

  async function handleRemoveLedger(id: number) {
    function onSuccess() {
      message.success("Ledger has been removed");
    }

    function onError(text: string) {
      message.error(text);
    }

    await removeLedger(id, onSuccess, onError);
  }

  function handleEditLedger(id: number) {
    navigate(`/data/ledgers/edit/${id}`);
  }

  const sortByDate = useCallback(() => {
    return 1;
  }, []);

  const defaultSortedColumns = useMemo(() => {
    return [
      {
        id: "date",
        desc: true,
      },
    ];
  }, [tableData]);

  const columns = useMemo(() => {
    return tableColumns({
      SelectFilter: SelectColumnFilter,
      dateSort: sortByDate,
      onViewLedger: handleViewLedgerDetails,
      onRemoveLedger: handleRemoveLedger,
      onEditLedger: handleEditLedger,
      isRemoveLedgerLoading,
    }) as Column[];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: defaultSortedColumns,
      },
    },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  function handleOpenImportTrialBalanceModal() {
    setIsImportTrialBalanceFileModalOpen(true);
  }

  function handleCloseImportTrialBalanceModal() {
    setIsImportTrialBalanceFileModalOpen(false);
  }

  return (
    <Layout
      left={leftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate("/data/home")}
          title="Trial Balance & Ledgers"
          rightSide={
            <PageHeaderActions onImportTrialBalanceFile={handleOpenImportTrialBalanceModal} />
          }
        />
      }
    >
      <LedgerListDetails total={tableData.length} />
      <Card
        title="Trial Balance & Ledgers"
        withHeader
        headerSideChildren={
          <>
            <LedgerStyles.TableOptionsContainer>
              <Select
                value={reviewedFilter}
                onChange={(value: any) => setReviewedFilter(value)}
                style={{ minWidth: "150px" }}
              >
                {reviewedOptions.map((option) => (
                  <Select.Option key={option.label} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              <Select
                value={periodicitySelected}
                onChange={(value: any) => setPeriodicitySelected(value)}
                style={{ minWidth: "150px" }}
              >
                {periodicityOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </LedgerStyles.TableOptionsContainer>
          </>
        }
      >
        {loadingAllLedgers ? (
          <Loader label="Loading ledgers. Just a second" labelPosition="bottom" />
        ) : !allLedgers || allLedgers.length < 1 ? (
          <>
            <EmptyState
              title="Import new ledger"
              text="Currently you have no ledger in your trial balance. You can import a new ledger by clicking on the button below."
              buttonLabel="Import new ledger"
              onClick={() => {
                navigate("/data/ledgers/import");
              }}
            />
          </>
        ) : (
          <Styled.Table {...getTableProps()}>
            <Styled.TableHead>
              {headerGroups.map((headerGroup) => (
                <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <Styled.TableCellWithFiltersWrapper>
                        {column.render("Header")}
                        {column.canSort && (
                          <SortDateIcon
                            isSorted={column.isSorted}
                            isSortedDesc={column.isSortedDesc}
                          />
                        )}
                        <div>{column.canFilter ? column.render("Filter") : null}</div>
                      </Styled.TableCellWithFiltersWrapper>
                    </Styled.TableHeader>
                  ))}
                </Styled.TableHeaderRow>
              ))}
            </Styled.TableHead>
            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Styled.TableBodyRow {...row.getRowProps()} isSelected={row.isSelected}>
                    {row.cells.map((cell) => {
                      return (
                        <Styled.TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Styled.TableCell>
                      );
                    })}
                  </Styled.TableBodyRow>
                );
              })}
            </Styled.TableBody>
          </Styled.Table>
        )}
      </Card>
      <TrialBalanceImportFileModal
        isVisible={isImportTrialBalanceFileModalOpen}
        onClose={handleCloseImportTrialBalanceModal}
      />
    </Layout>
  );
}
