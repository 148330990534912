import * as Styled from "./styles";

type StatusBadgeProps = {
  active: boolean;
  activeText?: string;
  inactiveText?: string;
};

export function StatusBadge({ active, activeText, inactiveText }: StatusBadgeProps) {
  if (active) {
    return <Styled.StatusBadge active>{activeText ?? "Active"}</Styled.StatusBadge>;
  }

  return <Styled.StatusBadge active={active}>{inactiveText ?? "Inactive"}</Styled.StatusBadge>;
}
