import Typography from "@synerise/ds-typography";

import * as Styled from "./Divider.styles";

const { Text } = Typography;

type DividerProps = {
  isSelected: boolean;
  title: string;
};
export function Divider({ title, isSelected }: DividerProps) {
  return (
    <Styled.Container isSelected={isSelected}>
      <Text style={{ margin: 0, padding: 0, fontWeight: "bold", fontSize: "16px" }}>{title}</Text>
      <div
        id="divider"
        style={{
          backgroundColor: "#ccc",
          height: "2px",
          width: "100%",
        }}
      />
    </Styled.Container>
  );
}
