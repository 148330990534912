import { formatDistanceToNow } from "date-fns";

import { KpiResponse } from "../../kpi.types";
import { KPIsLibraryTableData } from "../kpi-library.types";

export function mapKpiResponseToTable(kpisResponse: KpiResponse[]): KPIsLibraryTableData[] {
  return kpisResponse.map((kpi) => ({
    id: kpi.id,
    description: kpi.description,
    active: kpi.is_active || false,
    createdAt: `${formatDistanceToNow(new Date(kpi.created_at))} ago`,
    group_id: kpi.group_id,
    group: kpi.group.title,
    importance: kpi.importance || 1,
    title: kpi.title,
    periodicity: kpi.periodicity || "-",
    shared: kpi.is_shared || false,
  }));
}
