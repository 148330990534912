import { useRef, useState } from "react";

import Button from "@synerise/ds-button";
import DSDropdown from "@synerise/ds-dropdown";
import Icon, { Add3M } from "@synerise/ds-icon";
import DSMenu from "@synerise/ds-menu";

import { useOnClickOutside } from "@/hooks/useOnClickOutside";

export function NewItemAdd() {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  function close() {
    setShowDropdown(false);
  }

  function open() {
    setShowDropdown(true);
  }

  useOnClickOutside(ref, () => {
    setShowDropdown(false);
  });

  return (
    <DSDropdown
      overlayStyle={{ borderRadius: "3px" }}
      visible={showDropdown}
      placement="bottomLeft"
      overlay={
        <DSDropdown.Wrapper ref={ref}>
          <DSMenu asDropdownMenu={true} style={{ padding: "8px 2px 8px 8px" }}>
            <DSMenu.Item prefixel={<Icon component={<Add3M />} />} onClick={close}>
              Import Trial Balance
            </DSMenu.Item>
            <DSMenu.Item prefixel={<Icon component={<Add3M />} />} onClick={close}>
              Import KPI
            </DSMenu.Item>
            <DSMenu.Item prefixel={<Icon component={<Add3M />} />} onClick={close}>
              New Dashboard
            </DSMenu.Item>
            <DSMenu.Item prefixel={<Icon component={<Add3M />} />} onClick={close}>
              Add Entity
            </DSMenu.Item>
          </DSMenu>
        </DSDropdown.Wrapper>
      }
    >
      <Button mode="single-icon" key={5} type="ghost-white" onClick={open}>
        <Icon color="#ffffff" component={<Add3M />} />
      </Button>
    </DSDropdown>
  );
}
