import { createContext, useState } from "react";

import { AppContextData, Languages } from "./App.types";

export const AppContext = createContext({} as AppContextData);

export function AppProvider({ children }: { children: React.ReactNode }) {
  const [selectedLanguage, setSelectedLanguage] = useState<Languages>("en");
  const [selectedDashboardId, setSelectedDashboardId] = useState<number | null>(null);

  function changeSelectedDashboardId (id: number | null) {
    setSelectedDashboardId(id);
  }

  return (
    <AppContext.Provider
      value={{
        selectedLanguage,
        changeLanguage: setSelectedLanguage,
        selectedDashboardId,
        changeSelectedDashboardId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
