import styled from "styled-components";

import DSSelect from "@synerise/ds-select";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 16px;
  width: 100%;

  label {
    font-weight: bold;
  }
`;

export const StyledDSSelect = styled(DSSelect)`
  flex: 1;
  margin-bottom: 0;
`;
