import { DashboardGridLayout } from "./DashboardGridLayout";
import { DashboardLayout } from "./DashboardLayout";
import { DashboardProvider } from "./hooks/useDashboard";
import { GridLayoutProvider } from "./hooks/useGridLayout";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles/react-grid-layout.css";

export function Dashboard() {
  return (
    <DashboardProvider>
      <GridLayoutProvider>
        <DashboardLayout>
          <DashboardGridLayout />
        </DashboardLayout>
      </GridLayoutProvider>
    </DashboardProvider>
  );
}
