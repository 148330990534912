import { Field, Form } from "react-final-form";
import * as Yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { Input } from "@synerise/ds-input";

import { useValidationSchema } from "@/hooks/useValidationSchema";

type KPIImportMapFileStepProps = {
  onFinish: (values: KPIImportMapFileStepValues) => void;
  isImportLoading: boolean;
};

export type KPIImportMapFileStepValues = {
  header_row: number;
  timestamp: string;
  datetime: string;
  value: string;
  value_type: string;
  sheet: string;
};

const kpiImportMapFileStepSchema = Yup.object().shape({
  header_row: Yup.number().required("Header row number is required"),
  timestamp: Yup.string().required("Timestamp column is required"),
  datetime: Yup.string().required("Datetime column is required"),
  value: Yup.string().required("Value column is required"),
  value_type: Yup.string().required("Value type column is required"),
  sheet: Yup.string().required("Sheet column is required"),
});

export function KPIImportMapFileStep({ isImportLoading, onFinish }: KPIImportMapFileStepProps) {
  const validate = useValidationSchema(kpiImportMapFileStepSchema);

  async function submitMapping(values: KPIImportMapFileStepValues) {
    onFinish(values);
  }
  return (
    <Card
      style={{ maxWidth: "600px", margin: "0 auto" }}
      background="white-shadow"
      title="Map all columns from the file"
      withHeader
      iconColor={"#AEAEAE"}
      headerSideChildren={
        <Button
          htmlType="submit"
          form="form-columns-map"
          type="primary"
          disabled={isImportLoading}
          loading={isImportLoading}
        >
          Import
        </Button>
      }
    >
      <Form<KPIImportMapFileStepValues> onSubmit={submitMapping} validate={validate}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="form-columns-map">
            <Field name="header_row">
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="Header Row"
                  errorText={meta.touched && meta.error}
                  type="number"
                />
              )}
            </Field>
            <Field name="timestamp">
              {({ input, meta }) => (
                <Input {...input} label="Timestamp Column" errorText={meta.touched && meta.error} />
              )}
            </Field>
            <Field name="datetime">
              {({ input, meta }) => (
                <Input {...input} label="Datetime Column" errorText={meta.touched && meta.error} />
              )}
            </Field>
            <Field name="value">
              {({ input, meta }) => (
                <Input {...input} label="Value Column" errorText={meta.touched && meta.error} />
              )}
            </Field>
            <Field name="value_type">
              {({ input, meta }) => (
                <Input
                  {...input}
                  label="Value Type Column"
                  errorText={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="sheet">
              {({ input, meta }) => (
                <Input {...input} label="Sheet Column" errorText={meta.touched && meta.error} />
              )}
            </Field>
          </form>
        )}
      </Form>
    </Card>
  );
}
