import { AxiosResponse } from "axios";
import Cookies from "universal-cookie";

import { api } from "../../utils/http";
import { AuthTokenResponse, UserData } from "./auth.types";

const cookies = new Cookies();
class AuthService {
  async getUser(userID: number) {
    const response: AxiosResponse<UserData> = await api.get(`/users/${userID}`);
    const user = response.data;
    return user;
  }

  async getToken(authTokenRequest: FormData) {
    const response: AxiosResponse = await api.post("/auth/token", authTokenRequest);
    const auth: AuthTokenResponse = response.data;
    return auth;
  }

  setTenantIDCookie(tenantID: number) {
    cookies.set("@compass-tenant-id", tenantID, { path: "/", sameSite: "strict" });
  }

  setTenantIDInHeader(tenantID: number) {
    api.defaults.headers.common["x-compass-tenant-id"] = tenantID;
  }
}

const authService = new AuthService();
export default authService;
