import { DASHBOARD_LAYOUT_KEY } from "../dashboard.config";
import { LayoutInSessionStorage } from "../dashboard.types";

export function loadAllLayoutsFromSessionStorage() {
  if (!sessionStorage) return [];

  const layoutFromStorage = sessionStorage.getItem(DASHBOARD_LAYOUT_KEY);
  if (!layoutFromStorage) return [];

  return JSON.parse(layoutFromStorage) as LayoutInSessionStorage[];
}
