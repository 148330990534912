import { useMemo, useState } from "react";
import { Column, useFilters, useRowSelect, useSortBy, useTable } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { FilterM } from "@synerise/ds-icon";
import Loader from "@synerise/ds-loader";

import { EmptyState } from "@/components/EmptyState";
import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import { SortIcon } from "@/components/Table/SortIcon";
import * as Styled from "@/components/Table/styles";

import { meetingsTableColumns, MeetingsTableData } from "./MeetingsTableColumns";

export function MeetingsTable() {
  const [loading] = useState(false);

  async function handleRemoveReport(id: number, name: string) {
    console.log("handleRemoveReport", id, name);
  }

  function handleEditReport(id: number) {
    console.log("editing board meeting with id: ", id);
  }

  const columns = useMemo(() => {
    return meetingsTableColumns({
      onRemove: handleRemoveReport,
      onEdit: handleEditReport,
    }) as Column<MeetingsTableData>[];
  }, []);

  const data: MeetingsTableData[] = useMemo(() => {
    return [];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultCanSort: false,
      defaultCanFilter: false,
    },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  return (
    <Card
      title="All Board Meetings"
      withHeader
      headerSideChildren={
        <Button mode="single-icon" type="ghost-white">
          <Icon color="#C4C4C4" component={<FilterM />} />
        </Button>
      }
    >
      {loading ? (
        <Loader label="Loading Meetings" labelPosition="bottom" />
      ) : rows.length < 1 ? (
        <EmptyState
          title="Create new Board Meeting"
          text="Currently you have no meetings saved. Get started with a new one"
          buttonLabel="New Board Meeting"
          onClick={() => console.log("Create new meeting")}
        />
      ) : (
        <Styled.Table {...getTableProps()}>
          <Styled.TableHead>
            {headerGroups.map((headerGroup) => (
              <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Styled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                      {column.canFilter ? column.render("Filter") : null}
                      {column.canSort && (
                        <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                      )}
                    </Styled.TableCellWithFiltersWrapper>
                  </Styled.TableHeader>
                ))}
              </Styled.TableHeaderRow>
            ))}
          </Styled.TableHead>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Styled.TableBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Styled.TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Styled.TableCell>
                    );
                  })}
                </Styled.TableBodyRow>
              );
            })}
          </Styled.TableBody>
        </Styled.Table>
      )}
    </Card>
  );
}
