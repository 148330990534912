import { UserAvatar } from "@synerise/ds-avatar";
import Button from "@synerise/ds-button";
import Icon, { TrashS } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { BoardPostResponse } from "@/modules/governance/governance.services.types";
import { formatPostDate } from "@/modules/governance/utils/formatPostDate";
import { makeFullName } from "@/modules/governance/utils/makeFullName";

import * as Styled from "./styles";

type PostHeaderProps = {
  data: BoardPostResponse;
  canRemove: boolean;
  onRemove: () => void;
};

export function PostHeader({ data, onRemove, canRemove }: PostHeaderProps) {
  return (
    <Styled.Container>
      <Styled.Header>
        <span className="post-title">{data.title}</span>
        {/* TODO: Post Location */}
        {/* <Tag name="Meetings" className="tag" /> */}
        {canRemove && (
          <Popconfirm
            title="Are you sure you want to delete this post?"
            onConfirm={() => onRemove()}
            cancelText="No"
            okText="Delete"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button
              mode="single-icon"
              type="custom-color-ghost"
              color="grey"
              icon={<Icon component={<TrashS />} />}
            />
          </Popconfirm>
        )}
      </Styled.Header>
      <div className="post-details">
        <UserAvatar
          size="medium"
          user={{
            firstName: data.author.fname,
            lastName: data.author.lname,
            avatar: data.author.avatar,
          }}
        />
        <div className="post-details__text">
          <span>{makeFullName(data.author.fname, data.author.lname)}</span>
          <span>{formatPostDate(data.created_at)}</span>
        </div>
      </div>
    </Styled.Container>
  );
}
