import { AxiosResponse } from "axios";

import { api } from "@/utils/http";

import {
  MemberInvitationPayload,
  Member,
  MemberPatch,
  MemberCreate,
  User,
  UserPatch,
} from "./users.types";

class UsersAPI {
  async getUser(userID: number) {
    const response: AxiosResponse = await api.get(`/users/${userID}`);
    const user: User = response.data;
    return user;
  }

  async createUser(userID: number, userPayload: UserPatch) {
    const response: AxiosResponse = await api.post(`/users/${userID}`, userPayload);
    const user: User = response.data;
    return user;
  }

  async patchUser(userID: number, userPatch: UserPatch) {
    const response: AxiosResponse = await api.patch(`/users/${userID}`, userPatch);
    const user: User = response.data;
    return user;
  }

  async deleteUser(userID: number) {
    const response: AxiosResponse = await api.delete(`/users/${userID}`);
    const user: User = response.data;
    return user;
  }
}

class MembersAPI {
  async getMembers() {
    const response: AxiosResponse = await api.get(`/members`);
    const member: Member[] = response.data;
    return member;
  }

  async getMember(memberID: number) {
    const response: AxiosResponse = await api.get(`/members/${memberID}`);
    const member: Member = response.data;
    return member;
  }

  async createMember(memberID: number, memberPayload: MemberCreate) {
    const response: AxiosResponse = await api.post(`/members/${memberID}`, memberPayload);
    const member: Member = response.data;
    return member;
  }

  async patchMember(memberID: number, memberPatch: MemberPatch) {
    const response: AxiosResponse = await api.patch(`/members/${memberID}`, memberPatch);
    const member: Member = response.data;
    return member;
  }

  async deleteMember(memberID: number) {
    const response: AxiosResponse = await api.delete(`/members/${memberID}`);
    const statusResponse: ApiStatusResponse = response.data;
    return statusResponse;
  }

  async inviteMembers(invitationPayload: MemberInvitationPayload) {
    const response: AxiosResponse = await api.post(`/members/invite/invite`, invitationPayload);
    const statusResponse: ApiStatusResponse = response.data;
    return statusResponse;
  }
}

export const UsersService = new UsersAPI();
export const MembersService = new MembersAPI();
