import styled from "styled-components";

import { theme } from "@/styles/theme";

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: ${theme.spacing.m};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
`;
