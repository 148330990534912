import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "universal-cookie";

import { handleInvalidToken } from "@/contexts/auth/AuthProvider";

const cookies = new Cookies();
const appToken = cookies.get("@compass-app-token");
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

if (appToken) {
  api.defaults.headers.common["Authorization"] = `Bearer ${appToken}`;
}

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  console.info(`[Request] [${JSON.stringify(config)}]`);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[Request Error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  console.info(`[Response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: any): Promise<AxiosError> => {
  console.error(`[Response Error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};



const setupInterceptors = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
};

setupInterceptors(api);


api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      if (error.response.data?.detail === "Invalid Token.") {
        handleInvalidToken();
      }
    }
    return Promise.reject(error);
  }
);

export { api };
