import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { TrashM, EditM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { UserCell } from "@/components/Table/Cells/UserCell";
import * as TableStyled from "@/components/Table/styles";

export type MeetingsTableData = {
  id: number;
  name: string;
};
type TableColumnProps = {
  onRemove: (id: number, name: string) => Promise<void>;
  onEdit: (id: number) => void;
};

export const meetingsTableColumns = ({ onRemove, onEdit }: TableColumnProps) => {
  const columns: Column<MeetingsTableData>[] = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: true,
      Cell: ({ row }) => <UserCell name={row.original.name} />,
    },
    {
      Header: "Actions",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        <TableStyled.RowCellActionButtons>
          <Button mode="single-icon" type="ghost-white" onClick={() => onEdit(row.original.id)}>
            <Icon color="#C4C4C4" component={<EditM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ${row.original.name}?`}
            onConfirm={() => onRemove(row.original.id, row.original.name)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button mode="single-icon" type="ghost-white">
              <Icon color="#C4C4C4" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </TableStyled.RowCellActionButtons>
      ),
    },
  ];

  return columns;
};
