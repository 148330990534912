import Icon, { FileTypePdf } from "@synerise/ds-icon";

import * as Styled from "./styles";

export function MeetingAttachmentItem() {
  return (
    <Styled.Container>
      <Icon component={<FileTypePdf />} color="#a7a7a7" size={32} />
      <Styled.FileInfo>
        <Styled.Title>Acme Corp 1Q22 BM</Styled.Title>
        <Styled.FileMetadata>2.4 Mb</Styled.FileMetadata>
      </Styled.FileInfo>
    </Styled.Container>
  );
}
