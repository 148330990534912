export function Header() {
  return (
    <header>
      <p>
        <strong>Account: </strong>Assets &gt; Current Assets &gt; Cash &amp; Equivalents
      </p>
      <p>
        <strong>Code: </strong> [1-01-01-0005]
      </p>
    </header>
  );
}
