import { default as DSDivider } from "@synerise/ds-divider";

import { Text } from "./styles";

type DividerProps = {
  text?: string;
} & React.CSSProperties;

export function Divider({ text, ...rest }: DividerProps) {
  return (
    <>
      {text && <Text style={{ ...rest }}>{text}</Text>}
      <DSDivider style={{ ...rest }} />
    </>
  );
}
