import { AxiosResponse } from "axios";

import { api } from "@/utils/http";

import {
  AccountMapCreatePayload,
  AccountMapResponse,
  AccountMapUpdatePayload,
  ChartMapCreatePayload,
  ChartMapResponse,
  ChartMapUpdatePayload,
} from "./account-mapping.types";

export class AccountMappingServices {
  static async fetchAllMappedCharts(): Promise<ChartMapResponse[]> {
    const response = await api.get<ChartMapResponse[]>("/maps/charts");
    return response.data;
  }

  static async fetchOneMappedChart(mapId: number): Promise<ChartMapResponse> {
    const response = await api.get<ChartMapResponse>(`/maps/charts/${mapId}`);
    return response.data;
  }

  static async createMappedChart(payload: ChartMapCreatePayload): Promise<ChartMapResponse> {
    const arrayPayload: ChartMapCreatePayload[] = [];
    arrayPayload.push(payload);
    const response = await api.post<ChartMapResponse>("/maps/charts", arrayPayload);
    return response.data;
  }

  static async updateMappedChart(
    mapId: number,
    payload: ChartMapUpdatePayload
  ): Promise<ChartMapResponse> {
    const response: AxiosResponse = await api.patch<ChartMapResponse>(
      `/maps/charts/${mapId}`,
      payload
    );
    return response.data;
  }

  static async deleteMappedChart(mapId: number): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(`/maps/charts/${mapId}`);
    return response.data;
  }

  static async fetchAllMappedAccounts(mapId: number): Promise<AccountMapResponse[]> {
    const response = await api.get<AccountMapResponse[]>(`/maps/charts/${mapId}/accounts`);
    return response.data;
  }

  static async fetchOneMappedAccount(
    mapId: number,
    mappedAccountId: number
  ): Promise<AccountMapResponse> {
    const response = await api.get<AccountMapResponse>(
      `/maps/charts/${mapId}/accounts/${mappedAccountId}`
    );
    return response.data;
  }

  static async createAccountMap(
    mapId: number,
    payload: AccountMapCreatePayload
  ): Promise<AccountMapResponse> {
    const response = await api.post<AccountMapResponse>(`/maps/charts/${mapId}/accounts`, payload);
    return response.data;
  }

  static async updateAccountMap(
    mapId: number,
    accountMapId: number,
    payload: AccountMapUpdatePayload
  ): Promise<AccountMapResponse> {
    const response = await api.patch<AccountMapResponse>(
      `/maps/charts/${mapId}/accounts/${accountMapId}`,
      payload
    );
    return response.data;
  }

  static async removeAccountMap(mapId: number, accountMapId: number): Promise<ApiStatusResponse> {
    const response = await api.delete<ApiStatusResponse>(
      `/maps/charts/${mapId}/accounts/${accountMapId}`
    );
    return response.data;
  }
}
