import { useAuth } from "./hooks/useAuth";
import { AppMenu } from "./layout/AppMenu/AppMenu";
import { Navbar } from "./layout/Navbar/Navbar";
import { Page } from "./layout/Page/Page";
import { Auth } from "./pages/Auth";
import { LoadingApp } from "./pages/LoadingApp";
import { AppRoutes } from "./routes/App.routes";

import "./styles/innerMenu.css";
import "./styles/grid-layout.css";
import "./styles/ant-slider.css";

// TODO: Reduzir "zoom" do App. Algo equivalente à 80% do browser parece ser o ideal (usando 13" como ref)
// TODO: Adicionar "loading" em todas as páginas: como fazer isso de forma global?
// https://design.synerise.com/storybook-static/?path=/story/components-loader--loadercomplex
// TODO: Add object avatar to resources
// https://design.synerise.com/storybook-static/?path=/story/components-avatar-avatar--objectavatar

function App() {
  const { isLoggedIn, isUserLoading, isAuthenticationLoading } = useAuth();

  if (isUserLoading || isAuthenticationLoading) {
    return <LoadingApp />;
  }

  if (!isLoggedIn) {
    return <Auth />;
  }

  return (
    <Page appMenu={<AppMenu />} navBar={<Navbar />}>
      <AppRoutes />
    </Page>
  );
}

export default App;
