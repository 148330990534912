import { useState } from "react";

import { UserAvatar } from "@synerise/ds-avatar";
import Button from "@synerise/ds-button";
import { TextArea } from "@synerise/ds-input";

import * as Styled from "./styles";

type NewCommentProps = {
  avatarSrc?: string;
  onSubmit: (text: string) => Promise<void>;
  isSubmitting: boolean;
};

export function NewComment({ avatarSrc, isSubmitting, onSubmit }: NewCommentProps) {
  const [textInput, setTextInput] = useState("");

  const handleSubmit = async () => {
    try {
      await onSubmit(textInput);
      setTextInput("");
    } catch (err) {}
  };

  return (
    <Styled.NewCommentContainer>
      <TextArea value={textInput} onChange={(e: any) => setTextInput(e.target.value)} />
      <Styled.NewCommentFooter>
        <UserAvatar size="small" src={avatarSrc} />
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting || !textInput}
        >
          Comment
        </Button>
      </Styled.NewCommentFooter>
    </Styled.NewCommentContainer>
  );
}
