import styled from "styled-components";

export const KPIImportValueFileStepContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  > div {
    display: flex;
    flex-grow: 1;
    max-width: 400px;
  }
`;
