import { MeetingMemberItem } from "../MeetingMemberItem";
import * as Styled from "./styles";

export function MeetingMembers() {
  return (
    <Styled.Container>
      <MeetingMemberItem />
      <MeetingMemberItem />
      <MeetingMemberItem />
      <MeetingMemberItem />
      <MeetingMemberItem />
    </Styled.Container>
  );
}
