import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & .title {
    font-weight: bold;
    font-size: 0.8rem;
    color: black;
    margin-bottom: 0.5rem;
  }
`;
