import AwsRedshiftLogo from "./assets/logos/aws-redshift.png";
import GoogleBigQueryLogo from "./assets/logos/google-big-query.png";
import HubspotLogo from "./assets/logos/hubspot.png";
import MariaDbLogo from "./assets/logos/mariadb.png";
import Netsuite from "./assets/logos/netsuite.png";
import OracleLogo from "./assets/logos/oracle.png";
import PostgreSqlLogo from "./assets/logos/postgres.png";
import SalesforceLogo from "./assets/logos/salesforce.png";
import SapLogo from "./assets/logos/sap.png";
import SnowflakeLogo from "./assets/logos/snowflake.png";
import SqlServerLogo from "./assets/logos/sql-server.png";
import TotvsLogo from "./assets/logos/totvs.png";
import PipedriveLogo from "./assets/logos/totvs.png";

type SourceItem = {
  title: string;
  description: string;
  imgSrc: string;
};

type Source = {
  groupTitle: string;
  items: SourceItem[];
};

const Sources: Source[] = [
  {
    groupTitle: "ERP Systems",
    items: [
      {
        title: "SAP",
        description: "Request integration with PostgreSQL. Load data from personalized queries",
        imgSrc: SapLogo,
      },
      {
        title: "TOTVS",
        description: "Request integration with TOTVS. Load data from personalized queries",
        imgSrc: TotvsLogo,
      },
      {
        title: "Netsuite",
        description: "Request integration with Netsuite. Load data from personalized queries",
        imgSrc: Netsuite,
      },
    ],
  },
  {
    groupTitle: "CRM",
    items: [
      {
        title: "Salesforce",
        description: "Request integration with Salesforce. Load data from personalized queries",
        imgSrc: SalesforceLogo,
      },
      {
        title: "Pipedrive",
        description: "Request integration with Pipedrive. Load data from personalized queries",
        imgSrc: PipedriveLogo,
      },
      {
        title: "Hubspot",
        description: "Request integration with Hubspot. Load data from personalized queries",
        imgSrc: HubspotLogo,
      },
    ],
  },
  {
    groupTitle: "Relational Databases",
    items: [
      {
        title: "PostgreSQL",
        description: "Request integration with PostgreSQL. Load data from personalized queries",
        imgSrc: PostgreSqlLogo,
      },
      {
        title: "SQL Server",
        description: "Request integration with SQL Server. Load data from personalized queries",
        imgSrc: SqlServerLogo,
      },
      {
        title: "MariaDB",
        description: "Request integration with MariaDB. Load data from personalized queries",
        imgSrc: MariaDbLogo,
      },
      {
        title: "Oracle",
        description: "Request integration with Oracle. Load data from personalized queries",
        imgSrc: OracleLogo,
      },
    ],
  },
  {
    groupTitle: "Data Lake",
    items: [
      {
        title: "Snowflake",
        description: "Request integration with Snowflake. Load data from personalized queries",
        imgSrc: SnowflakeLogo,
      },
      {
        title: "AWS Redshift",
        description: "Request integration with AWS Redshift. Load data from personalized queries",
        imgSrc: AwsRedshiftLogo,
      },
      {
        title: "Google Big Query",
        description:
          "Request integration with Google Big Query. Load data from personalized queries",
        imgSrc: GoogleBigQueryLogo,
      },
    ],
  },
];

export default Sources;
