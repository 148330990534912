import Card from "@synerise/ds-card";
import Skeleton from "@synerise/ds-skeleton";

export function BoardCardSkeleton() {
  return (
    <Card style={{ width: 310 }} withoutPadding>
      <div style={{ padding: "20px" }}>
        <Skeleton numberOfSkeletons={3} width="M" />
      </div>
    </Card>
  );
}
