import styled from "styled-components";

import Tags, { TagShape } from "@synerise/ds-tags";
import Tooltip from "@synerise/ds-tooltip";

type DynamicTagsProps = {
  tags: string[];
  noContentMessage: string;
};

const Container = styled.div`
  display: flex;
  height: 100%;
`;

export function DynamicTags({ tags, noContentMessage }: DynamicTagsProps) {
  if (tags.length === 0) {
    return <div>{noContentMessage}</div>;
  }

  const firstTag = tags[0];

  const otherTags = tags.slice(1);
  return (
    <Container>
      <Tags
        style={{ margin: 0 }}
        tagShape={TagShape.DEFAULT_ROUND}
        asPill
        selected={[
          {
            id: firstTag,
            name: firstTag,
            color: "#00B5AD",
          },
        ]}
      />

      {otherTags.length === 0 ? null : otherTags.length === 1 ? (
        <Tags
          style={{ margin: 0 }}
          tagShape={TagShape.DEFAULT_ROUND}
          asPill
          selected={[
            {
              id: otherTags[0],
              name: otherTags[0],
              color: "#00B5AD",
            },
          ]}
        />
      ) : (
        <Tooltip
          placement="top"
          type="default"
          title={
            <div>
              <Tags
                style={{ margin: 0 }}
                tagShape={TagShape.DEFAULT_ROUND}
                asPill
                selected={otherTags.map((tag) => ({
                  id: tag,
                  name: tag,
                  color: "#00B5AD",
                }))}
              />
            </div>
          }
        >
          <div>
            <Tags
              style={{ margin: 0 }}
              tagShape={TagShape.DEFAULT_ROUND}
              asPill
              selected={[
                {
                  name: `+ ${otherTags.length}`,
                  color: "#00B5AD",
                },
              ]}
            />
          </div>
        </Tooltip>
      )}
    </Container>
  );
}
