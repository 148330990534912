import { useEffect, useState } from "react";

import { Input } from "@synerise/ds-input";

import * as Styled from "../kpi-create-budget-modal.styles";
import { BudgetValue, useKPICreateBudget } from "../useKPICreateBudget";
import { DatePicker } from "./DatePicker";

export function KPISimpleBudgetAmount() {
  const [budgetAmount, setBudgetAmount] = useState(0);
  const {
    initialMonthSelected,
    initialYearSelected,
    changeInitialMonthSelected,
    changeInitialYearSelected,
    changeBudgetValues,
  } = useKPICreateBudget();

  useEffect(() => {
    const budgetData = new Date(initialYearSelected, initialMonthSelected - 1);

    const budgetValue: BudgetValue = {
      amount: budgetAmount,
      date: budgetData,
    };

    const budgetValues: BudgetValue[] = [];
    budgetValues.push(budgetValue);

    changeBudgetValues(budgetValues);
  }, [initialMonthSelected, initialYearSelected, budgetAmount]);

  const handleChangeInitialDate = (month: number, year: number) => {
    changeInitialMonthSelected(month);
    changeInitialYearSelected(year);
  };

  return (
    <>
      <Styled.DateFieldsRow>
        <DatePicker
          month={initialMonthSelected}
          year={initialYearSelected}
          onChangeYear={(year) => handleChangeInitialDate(initialMonthSelected, year)}
          onChangeMonth={(month) => handleChangeInitialDate(month, initialYearSelected)}
        />
      </Styled.DateFieldsRow>
      <Input
        prefixel={<span className="input-prefix">$</span>}
        value={budgetAmount}
        onChange={(event: any) => setBudgetAmount(Number(event.target.value))}
        type="number"
        style={{ fontSize: "1.2rem", maxWidth: "200px" }}
        placeholder={"500.00"}
      />
    </>
  );
}
