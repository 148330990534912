import * as Styled from "./styles";

type SummarySectionProps = {
  title: string;
  children: React.ReactNode;
};

export function SummarySection({ title, children }: SummarySectionProps) {
  return (
    <Styled.Container>
      <span className="title">{title}</span>
      {children}
    </Styled.Container>
  );
}
