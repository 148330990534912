import { DocumentsItem } from "../DocumentsItem";
import * as Styled from "./styles";

export function DocumentsSection() {
  return (
    <Styled.Container>
      <DocumentsItem />
      <DocumentsItem />
      <DocumentsItem />
    </Styled.Container>
  );
}
