import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRowSelect, useTable } from "react-table";

import Card from "@synerise/ds-card";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";

import * as Styled from "@/components/Table/styles";
import { useChartOfAccounts } from "@/hooks/useChartOfAccounts";

import { dataManagementData } from "../../data-management.data";
import { EmptyTable } from "./AccountMappingEmptyTable";
import { useAccountsMapping } from "./hooks/useAccountsMapping";
import { tableColumns } from "./table-columns";
import { mapMappedChartsToTable } from "./utils/mapAccountsMappingToTable";

export function AccountsMappingTable() {
  const navigate = useNavigate();
  const { allChartOfAccounts, chartOfAccountsLoading } = useChartOfAccounts();
  const {
    allAccountsMapping,
    accountsMappingLoading,
    removeAccountMapping,
    removeAccountMappingLoading,
  } = useAccountsMapping();

  const handleRemoveChartMapping = async (chartMappingId: number) => {
    const onSuccess = () => {
      message.success("Mapping deleted successfully");
    };

    const onError = (errorMessage: string) => {
      message.error(errorMessage);
    };

    await removeAccountMapping({
      chartMappingId,
      onError,
      onSuccess,
    });
  };

  const handleEditChartMapping = (chartMappingId: number, targetId: number, baseId: number) => {
    navigate(`${dataManagementData.accountsMapping.path}/${chartMappingId}/${targetId}/${baseId}`);
  };

  const columns = useMemo(() => {
    return tableColumns({
      onRemoveChartMapping: handleRemoveChartMapping,
      removeAccountMappingLoading,
      onEditChartMapping: handleEditChartMapping,
    });
  }, []);

  const data = useMemo(() => {
    return mapMappedChartsToTable(allAccountsMapping, allChartOfAccounts);
  }, [allAccountsMapping, allChartOfAccounts]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useRowSelect
  );

  return (
    <Card title="Accounts Mapping" withHeader>
      {accountsMappingLoading || chartOfAccountsLoading ? (
        <Loader label="Loading accounts mapping. Just a second" labelPosition="bottom" />
      ) : rows.length < 1 ? (
        <EmptyTable />
      ) : (
        <Styled.Table {...getTableProps()}>
          <Styled.TableHead>
            {headerGroups.map((headerGroup) => (
              <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <Styled.TableHeader {...column.getHeaderProps()}>
                    <Styled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                    </Styled.TableCellWithFiltersWrapper>
                  </Styled.TableHeader>
                ))}
              </Styled.TableHeaderRow>
            ))}
          </Styled.TableHead>
          <Styled.TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Styled.TableBodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Styled.TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Styled.TableCell>
                    );
                  })}
                </Styled.TableBodyRow>
              );
            })}
          </Styled.TableBody>
        </Styled.Table>
      )}
    </Card>
  );
}
