import Icon, { Check2M, Close2M, CircleShapeM } from "@synerise/ds-icon";

import * as Styled from "../styles";

type CheckCellProps = {
  checked: boolean | null;
};

export function CheckCell({ checked }: CheckCellProps) {
  return (
    <Styled.CheckCell>
      {checked === null ? (
        <Icon component={<CircleShapeM />} color="#c7c7c7" />
      ) : checked ? (
        <Icon component={<Check2M />} color="#00CC83" />
      ) : (
        <Icon component={<Close2M />} color="#d0746e" />
      )}
    </Styled.CheckCell>
  );
}
