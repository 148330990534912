import Card from "@synerise/ds-card";
import Icon, {
  TagM,
  DatabaseDownloadM,
  Check2M,
  BookM,
  DatabaseRemoveM,
  Close2M,
} from "@synerise/ds-icon";
import Select from "@synerise/ds-select";

import { Dimension } from "@/hooks/useDimensionTypes";

import * as Styled from "./ledger-details.styles";
import { InfoDetailComponent } from "./LedgerDetailsInfoDetail";

type LedgerDetailsInfosProps = {
  company: string;
  isConsistent: boolean;
  periodicity: string;
  isReviewed: boolean;
  dimensionsOptions: Dimension[];
  dimensionsSelected: number[];
  setDimensionsSelected: (dimensionsSelected: number[]) => void;
  versions: string[];
  versionSelected?: string;
  setVersionSelected: (versionSelected?: string) => void;
};

function LedgerDetailsInfos({
  company,
  isConsistent,
  periodicity,
  isReviewed,
  dimensionsOptions,
  dimensionsSelected,
  versions,
  versionSelected,
  setVersionSelected,
  setDimensionsSelected,
}: LedgerDetailsInfosProps) {
  return (
    <div style={{ marginBottom: "20px" }}>
      <Card>
        <Styled.Container>
          <Styled.InfosWrapper>
            <InfoDetailComponent icon={<Icon color="blue" component={<BookM />} />} title="Type">
              Trial Balance
            </InfoDetailComponent>
            <InfoDetailComponent icon={<Icon color="blue" component={<TagM />} />} title="Company">
              {company}
            </InfoDetailComponent>
            <InfoDetailComponent icon={<Icon color="blue" component={<TagM />} />} title="Period">
              {periodicity}
            </InfoDetailComponent>
            <InfoDetailComponent
              icon={
                isConsistent ? (
                  <Icon color="green" component={<DatabaseDownloadM />} />
                ) : (
                  <Icon color="red" component={<DatabaseRemoveM />} />
                )
              }
              title="Consistency"
            >
              {isConsistent ? "Check" : "Inconsistent"}
            </InfoDetailComponent>
            <InfoDetailComponent
              icon={
                isReviewed ? (
                  <Icon color="green" component={<Check2M />} />
                ) : (
                  <Icon color="red" component={<Close2M />} />
                )
              }
              title="Reviewed"
            >
              {isReviewed ? "Check" : "Not reviewed"}
            </InfoDetailComponent>
          </Styled.InfosWrapper>
          <div style={{ display: "flex", gap: "8px" }}>
            <InfoDetailComponent title="Dimensions">
              <div className="version-value--container">
                <Select
                  style={{ minWidth: "150px" }}
                  mode="multiple"
                  placeholder="None dimension"
                  value={dimensionsSelected}
                  onChange={(value: any) => setDimensionsSelected(value)}
                >
                  {dimensionsOptions.map((dimension) => (
                    <Select.Option
                      key={dimension.dimensionValueId}
                      value={dimension.dimensionValueId}
                    >
                      {dimension.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </InfoDetailComponent>
            <InfoDetailComponent title="Versions">
              <div className="version-value--container">
                <Select
                  style={{ minWidth: "150px" }}
                  placeholder="None Version"
                  allowClear
                  onClear={() => setVersionSelected()}
                  value={versionSelected}
                  onSelect={(value: any) => setVersionSelected(value)}
                >
                  {versions.map((version) => (
                    <Select.Option key={version} value={version}>
                      {version}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </InfoDetailComponent>
          </div>
        </Styled.Container>
      </Card>
    </div>
  );
}

export default LedgerDetailsInfos;
