import { AxiosResponse } from "axios";

import { api } from "@/utils/http";

import { Company, CompanyCreatePayload, CompanyUpdatePayload } from "./companies.types";

// class Companies implements EntityServiceInterface {
class Companies {
  async index() {
    const response: AxiosResponse = await api.get("/companies");
    const data: Company[] = response.data;
    return data;
  }

  async show(id: number) {
    const response: AxiosResponse = await api.get(`/companies/${id}`);
    const data: Company = response.data;
    return data;
  }

  async create(payload: CompanyCreatePayload) {
    const response: AxiosResponse = await api.post("/companies", payload);
    const data: Company = response.data;
    return data;
  }

  async delete(id: number) {
    const response: AxiosResponse = await api.delete(`/companies/${id}`);
    const status: ApiStatusResponse = response.data;
    return status;
  }

  async update(id: number, payload: CompanyUpdatePayload) {
    const response: AxiosResponse = await api.patch(`/companies/${id}`, payload);
    const data: Company = response.data;
    return data;
  }
}

export const CompaniesService = new Companies();
