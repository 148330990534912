import { api } from "@/utils/http";

import { TagResponse, TagCreatePayload, TagUpdatePayload } from "./reports-tags.types";

export class ReportsTagService {
  static async fetchAll() {
    const response = await api.get<TagResponse[]>("/reportsTags");
    return response.data;
  }

  static async fetchOne(id: string) {
    const response = await api.get<TagResponse>(`/reportsTags/${id}`);
    return response.data;
  }

  static async create(payload: TagCreatePayload[]) {
    const response = await api.post<TagResponse>("/reportsTags", payload);
    return response.data;
  }

  static async update(id: string, payload: TagUpdatePayload) {
    const response = await api.patch<TagResponse>(`/reportsTags/${id}`, payload);
    return response.data;
  }

  static async delete(id: number) {
    const response = await api.delete<ApiStatusResponse>(`/reportsTags/${id}`);
    return response.data;
  }
}
