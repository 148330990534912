import { Form } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { CircleShapeM, Check3M } from "@synerise/ds-icon";
import Loader from "@synerise/ds-loader";
import dsMessage from "@synerise/ds-message";
import { Text } from "@synerise/ds-typography";

import { useAuth } from "@/hooks/useAuth";
import { useUser } from "@/hooks/useUser";

import * as LoginStyled from "../login.styles";
import * as Styled from "./select-tenant.styles";
import "./select-tenant.css";

type SelectTenantData = {
  tenant_id: number;
};

export function SelectTenant() {
  const navigate = useNavigate();
  const { logoutUser, isAuthenticationLoading, loginUser, user } = useAuth();
  const [form] = Form.useForm();
  const [tenantIDSelected, setTenantIDSelected] = useState<number | null>(null);
  const { tenants, tenantsLoading } = useUser(user?.id);

  async function handleBack() {
    logoutUser();
    navigate("/");
  }

  async function handleSelectTenant() {
    if (!tenantIDSelected) {
      dsMessage.warning("Please, select a tenant", () => {
        dsMessage.destroy();
      });
      return;
    }

    loginUser(tenantIDSelected);
  }

  // Automatically select the tenant when there is only one tenant that is active
  useEffect(() => {
    if (tenants.length === 1 && tenants[0].is_active) {
      setTenantIDSelected(tenants[0].id);
      loginUser(tenants[0].id);
    }
  }, [tenants]);

  return (
    <LoginStyled.CardContainer>
      <Card title="Select a organization" withHeader>
        {tenantsLoading ? (
          <Loader label="Loading your organizations, just a second" labelPosition="bottom" />
        ) : (
          <Form<SelectTenantData> onFinish={handleSelectTenant} form={form}>
            <Styled.TenantsGroup>
              {!tenants || (tenants.length === 0 && <Text>No organization found</Text>)}

              {tenants &&
                tenants.map((tenant) => {
                  const disabled = !tenant.is_active;

                  return (
                    <Styled.Card
                      key={tenant.id}
                      disabled={disabled}
                      selected={tenantIDSelected === tenant.id}
                      onClick={() => {
                        if (!disabled) {
                          setTenantIDSelected(tenant.id);
                        }
                      }}
                    >
                      <h1>{tenant.name}</h1>

                      <Styled.TenantCardContent>
                        <span>{tenant.namespace}</span>
                        <div>
                          <span className="status">{tenant.is_active ? "Active" : "Inactive"}</span>
                          {tenant.owner_id && tenant.owner_id === user?.id ? (
                            <p className="status">Activate this tenant in your email.</p>
                          ) : (
                            <p className="status">
                              The owner has not activated this organization yet.
                            </p>
                          )}
                        </div>
                      </Styled.TenantCardContent>

                      {tenant.id === tenantIDSelected ? (
                        <Icon component={<Check3M />} className="check-button checked" size={32} />
                      ) : !disabled ? (
                        <Icon
                          component={<CircleShapeM />}
                          className="check-button unchecked"
                          size={32}
                        />
                      ) : null}
                    </Styled.Card>
                  );
                })}
            </Styled.TenantsGroup>
            <LoginStyled.FooterButtonsContainer>
              <Button onClick={() => handleBack()}>Back</Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isAuthenticationLoading}
                disabled={!tenantIDSelected}
              >
                Enter
              </Button>
            </LoginStyled.FooterButtonsContainer>
          </Form>
        )}
      </Card>
    </LoginStyled.CardContainer>
  );
}
