import { EditItemDrawerChartContent } from "@/components/LayoutBuilder/EditItemDrawer/contents/EditItemDrawerChartContent/EditItemDrawerChartContent";
import { ChartData, ItemConfig, ItemLayout } from "@/components/LayoutBuilder/LayoutBuilder.types";

type ColumnChartConfigProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>) => void;
};

export function getColumnChartConfig({
  updateItem,
  editingItem,
}: ColumnChartConfigProps): ItemConfig<ChartData> {
  return {
    name: "columnChart",
    editDrawerComponent: (
      <EditItemDrawerChartContent updateItem={updateItem} editingItem={editingItem} />
    ),
    resizeHandles: ["s", "sw", "se"],
    initialLayout: {
      w: 5,
      h: 5,
      type: "columnChart",
      minW: 3,
      minH: 5,
      resizeHandles: [],
      data: {
        title: "Chart Title",
        series: [],
      },
    },
  };
}
