import {
  AddM,
  Book2M,
  BookM,
  BookmarkM,
  CompanyM,
  FileAddM,
  FolderArchiveM,
  GroupM,
} from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import { Title } from "@synerise/ds-typography";

import { Divider } from "@/components/Divider";
import { ModuleCard } from "@/components/ModuleCard";

import * as Styled from "./governance.styles";

export function GovernanceHome() {
  return (
    <Layout>
      <Styled.MaxWidthContent>
        <Styled.TitleContainer>
          <Title level={1}>Governance</Title>
        </Styled.TitleContainer>

        <Styled.ModulesContainer>
          <Divider text="Shortcuts" />
          <Styled.CardsGroup>
            <ModuleCard
              title="All boards"
              description="See all the boards you have access to"
              icon={<AddM />}
              onClick={() => {}}
            />
            <ModuleCard
              title="Create new meeting"
              description="Start a new meetings and invite members"
              icon={<FileAddM />}
              onClick={() => {}}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>

        <Styled.ModulesContainer>
          <Divider text="Boards" />
          <Styled.CardsGroup>
            <ModuleCard
              title="Boards"
              description="See all your boards"
              icon={<CompanyM />}
              onClick={() => {}}
            />
            <ModuleCard
              title="Documents"
              description="See all of the documents"
              icon={<GroupM />}
              onClick={() => {}}
            />
            <ModuleCard
              title="Files"
              description="See all of your shared files"
              icon={<FolderArchiveM />}
              onClick={() => {}}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>
        <Styled.ModulesContainer>
          <Divider text="Meetings" />
          <Styled.CardsGroup>
            <ModuleCard
              title="All meetings"
              description="See all your meetings"
              icon={<BookM />}
              onClick={() => {}}
            />
            <ModuleCard
              title="Future meetings"
              description="Manage dimensions"
              icon={<Book2M />}
              onClick={() => {}}
            />
            <ModuleCard
              title="Create new meeting"
              description="Create a new meeting and invite members"
              icon={<BookmarkM />}
              onClick={() => {}}
            />
          </Styled.CardsGroup>
        </Styled.ModulesContainer>
      </Styled.MaxWidthContent>
    </Layout>
  );
}
