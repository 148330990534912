import { useState } from "react";

import Tabs from "@synerise/ds-tabs";

import { GridItemsDrawer } from "../GridItemsDrawer/GridItemsDrawer";
import { ReportSettingsDrawer } from "../ReportSettingsDrawer/ReportSettingsDrawer";
import * as Styled from "./LeftPanel.styles";

export function LeftPanel() {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  return (
    <>
      <Styled.Container>
        <Tabs
          tabs={[{ label: "Items" }, { label: "Settings" }]}
          activeTab={selectedTabIndex}
          handleTabClick={(index) => {
            setSelectedTabIndex(index);
          }}
        />
      </Styled.Container>
      {selectedTabIndex === 0 && <GridItemsDrawer />}

      {selectedTabIndex === 1 && <ReportSettingsDrawer />}
    </>
  );
}
