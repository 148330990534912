import { Link } from "react-router-dom";

import AppMenu from "@synerise/ds-app-menu";
import { DashboardColorM, DashboardGreyM } from "@synerise/ds-icon";

export function DashboardMenu() {
  return (
    <AppMenu.Item id="dashboards" name="Dashboards" className="app-menu-item">
      <Link to="/">
        <AppMenu.Item.Icon active={<DashboardColorM />} inActive={<DashboardGreyM />} />
      </Link>
    </AppMenu.Item>
  );
}
