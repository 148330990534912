import { useMemo } from "react";
import { useQuery } from "react-query";

import { KPIService } from "../../kpi.services";
import { KPIS_QUERY_KEY } from "./useKpisApi.config";
import { UseKpisApiProps, UseKpisApiReturn } from "./useKpisApi.types";

export function useKpisApi({ fetchParams, enabled }: UseKpisApiProps): UseKpisApiReturn {
  const { data, isLoading } = useQuery(
    [KPIS_QUERY_KEY, fetchParams],
    async () => await KPIService.fetchAll(fetchParams),
    {
      enabled,
    }
  );

  const kpis = useMemo(() => {
    return data || [];
  }, [data]);

  return {
    kpis: kpis,
    isLoading,
  };
}
