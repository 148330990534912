import {  addMonths } from "date-fns";

import { Periodicity } from "../../kpi.types";

const formatDateMonth = (date: Date) => {
  return date.toLocaleDateString("en-US", { month: "short", year: "2-digit" });
};

const formatDateQuarter = (date: Date) => {
  return `Q${Math.ceil(date.getMonth() / 3)} ${date.getFullYear()}`;
};

const formatDateYear = (date: Date) => {
  return date.getFullYear();
};

export const formatDateToColumnHeader = (periodicity: Periodicity, date: Date) => {
  const newDate = addMonths(date, 1);

  switch (periodicity) {
    case "monthly":
      return formatDateMonth(newDate);
    case "quarterly":
      return formatDateQuarter(newDate);
    case "yearly":
      return formatDateYear(newDate);
    default:
      return "";
  }
};
