import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useFilters, useSortBy, useRowSelect, Column } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { FilterM } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import { EmptyTable } from "@/components/Table/EmptyTable";
import { SortIcon } from "@/components/Table/SortIcon";
import * as TableStyled from "@/components/Table/styles";
import { mapDimensionTypesListToTable, useDimensionTypes } from "@/hooks/useDimensionTypes";

import { dataManagementData } from "../data-management.data";
import { leftLayoutContent } from "../DataManagementLeftLayoutContent";
import { DimensionTypesTableData, tableColumns } from "./dimension-types.columns";
import { CardTitleContainer } from "./dimension-types.styles";

type CardTitleProps = {
  title: string;
  loading: boolean;
};

function CardTitle({ title, loading }: CardTitleProps) {
  return (
    <CardTitleContainer>
      <h2>{title}</h2>
      {loading && <Loader size="S" />}
    </CardTitleContainer>
  );
}

export function DimensionTypes() {
  const navigate = useNavigate();
  const { allDimensionTypes, removeDimensionType, isDimensionTypesLoading } = useDimensionTypes();

  function onSuccess() {
    message.success("Dimension has been removed");
  }

  function onError(text: string) {
    message.error(text);
  }

  async function handleRemoveDimensionType(id: number) {
    await removeDimensionType.mutateAsync({ id, onSuccess, onError });
  }

  function handleEditDimensionType(id: number) {
    navigate(`/data/dimension-types/edit/${id.toString()}`);
  }

  const dimensionTypes = useMemo(() => {
    return mapDimensionTypesListToTable(allDimensionTypes);
  }, [allDimensionTypes]);

  const columns = useMemo(() => {
    return tableColumns({
      onRemoveDimensionType: handleRemoveDimensionType,
      onEditDimensionType: handleEditDimensionType,
    }) as Column<DimensionTypesTableData>[];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: dimensionTypes,
      defaultCanSort: false,
      defaultCanFilter: false,
    },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  return (
    <Layout
      left={leftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate(dataManagementData.home.path)}
          title="Dimensions"
          rightSide={
            <Button
              type="primary"
              onClick={() => navigate(dataManagementData.dimensionTypes.createPath)}
            >
              New dimension
            </Button>
          }
        />
      }
    >
      <Card
        title={<CardTitle title="Dimensions" loading={isDimensionTypesLoading} />}
        withHeader
        headerSideChildren={
          <Button mode="single-icon" type="ghost-white">
            <Icon color="#C4C4C4" component={<FilterM />} />
          </Button>
        }
      >
        {isDimensionTypesLoading ? (
          <Loader label="Loading dimensions. Just a second" labelPosition="bottom" />
        ) : rows.length < 1 ? (
          <EmptyTable
            path={dataManagementData.dimensionTypes.createPath}
            text="No dimensions"
            buttonText="New dimension"
          />
        ) : (
          <TableStyled.Table {...getTableProps()}>
            <TableStyled.TableHead>
              {headerGroups.map((headerGroup) => (
                <TableStyled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <TableStyled.TableHeader
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <TableStyled.TableCellWithFiltersWrapper>
                        {column.render("Header")}
                        {column.canFilter ? column.render("Filter") : null}
                        {column.canSort && (
                          <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                        )}
                      </TableStyled.TableCellWithFiltersWrapper>
                    </TableStyled.TableHeader>
                  ))}
                </TableStyled.TableHeaderRow>
              ))}
            </TableStyled.TableHead>

            <TableStyled.TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableStyled.TableBodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableStyled.TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableStyled.TableCell>
                      );
                    })}
                  </TableStyled.TableBodyRow>
                );
              })}
            </TableStyled.TableBody>
          </TableStyled.Table>
        )}
      </Card>
    </Layout>
  );
}
