import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { EditM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import DSSelect from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useChartOfAccounts, useSingleChartOfAccounts } from "@/hooks/useChartOfAccounts";
import { useEntities } from "@/hooks/useEntities";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { dataManagementData } from "../../data-management.data";
import { ChartType } from "./chart.types";
import { Select } from "./components/Select";

const CardHeaderSideChildrenContainer = styled.div`
  display: flex;
  gap: 10px;
`;

type EditChartOfAccountsFormValues = {
  title: string;
  description: string;
  chart_type: ChartType;
  language: string;
  entity_id: number;
  is_complete: boolean;
  is_default_entity: boolean;
  is_default_tenant: boolean;
  is_tenant: boolean;
  is_mapped: boolean;
};

const formId = "create-chart-of-accounts";

type EditChartOfAccountsParams = {
  id: string;
};

export function EditChartOfAccounts() {
  const { id } = useParams<EditChartOfAccountsParams>();
  const chartOfAccountsId = Number(id);

  const onGetChartOfAccountsError = (text: string) => {
    message.error(text);
    navigate(dataManagementData.chartOfAccounts.path);
  };

  const { chartOfAccounts, chartOfAccountsLoading } = useSingleChartOfAccounts(
    chartOfAccountsId,
    onGetChartOfAccountsError
  );

  const [basicContentCollapse, setBasicContentCollapse] = useState(false);
  const [detailsContentCollapse, setDetailsContentCollapse] = useState(true);
  const { chartOfAccountsTypes, updateChartOfAccounts, updateChartOfAccountsLoading } =
    useChartOfAccounts();
  const { languages } = useSupportInfos();
  const { allEntities } = useEntities();

  const onSubmit = async (values: EditChartOfAccountsFormValues) => {
    const onSuccess = () => {
      message.success("Chart of accounts has been updated successfully.");
      navigate(dataManagementData.chartOfAccounts.path);
    };

    const onError = (text: string) => {
      message.error(text);
    };

    await chartOfAccounts;

    await updateChartOfAccounts({
      id: chartOfAccountsId,
      payload: values,
      onSuccess,
      onError,
    });
  };

  const initialFormValues = {
    title: chartOfAccounts?.title ?? "",
    description: chartOfAccounts?.description ?? "",
    chart_type: chartOfAccounts?.chart_type ?? "",
    language: chartOfAccounts?.language_code ?? "",
    entity_id: chartOfAccounts?.entity_id ?? undefined,
    is_complete: chartOfAccounts?.is_complete ?? false,
    is_consolidation: chartOfAccounts?.is_consolidation ?? false,
    is_default_entity: chartOfAccounts?.is_default_entity ?? false,
    is_default_tenant: chartOfAccounts?.is_default_tenant ?? false,
    is_tenant: chartOfAccounts?.is_tenant ?? false,
    is_mapped: chartOfAccounts?.is_mapped ?? false,
  };

  const navigate = useNavigate();
  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate(-1)}
          title="Editing chart of account"
          rightSide={
            <CardHeaderSideChildrenContainer>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                form={formId}
                loading={updateChartOfAccountsLoading}
                disabled={updateChartOfAccountsLoading}
              >
                Update
              </Button>
            </CardHeaderSideChildrenContainer>
          }
        />
      }
    >
      {chartOfAccountsLoading ? (
        <Loader label="Loading chart of accounts. Just a second" labelPosition="bottom" />
      ) : (
        <Form onSubmit={onSubmit} initialValues={initialFormValues}>
          {({ handleSubmit }) => (
            <form id={formId} onSubmit={handleSubmit}>
              <ModuleStyled.MaxWidthContent>
                <ModuleStyled.CardsWrapper>
                  <Card
                    background="white-shadow"
                    lively
                    hideContent={basicContentCollapse}
                    onHeaderClick={() => setBasicContentCollapse((prevState) => !prevState)}
                    title="Basic Information"
                    withHeader
                    icon={<EditM />}
                    iconColor={"#AEAEAE"}
                  >
                    <ModuleStyled.InputWrapper style={{ gap: "8px" }}>
                      <Field name="title">
                        {({ input }) => <Input {...input} label="Title" />}
                      </Field>

                      <div>
                        <Field name="chart_type">
                          {({ input }) => (
                            <DSSelect {...input} label="Type">
                              {Object.entries(chartOfAccountsTypes).map(([key, value]) => (
                                <DSSelect.Option key={key} value={key}>
                                  {value}
                                </DSSelect.Option>
                              ))}
                            </DSSelect>
                          )}
                        </Field>
                      </div>
                      <div>
                        <Field name="entity_id">
                          {({ input }) => (
                            <Select
                              items={allEntities.map((entity) => ({
                                id: entity.id,
                                value: entity.id,
                                name: entity.name,
                              }))}
                              {...input}
                            />
                          )}
                        </Field>
                      </div>
                      <div>
                        <Field name="language">
                          {({ input }) => (
                            <DSSelect
                              {...input}
                              showSearch
                              label="Language"
                              placeholder="Language"
                              optionFilterProp="children"
                            >
                              {languages.map((language) => (
                                <DSSelect.Option key={language.code} value={language.code}>
                                  {language.lang_en}
                                </DSSelect.Option>
                              ))}
                            </DSSelect>
                          )}
                        </Field>
                      </div>
                    </ModuleStyled.InputWrapper>
                  </Card>

                  <Card
                    background="white-shadow"
                    lively
                    hideContent={detailsContentCollapse}
                    onHeaderClick={() => setDetailsContentCollapse((prevState) => !prevState)}
                    title="Details"
                    withHeader
                    icon={<EditM />}
                    iconColor={"#AEAEAE"}
                  >
                    <ModuleStyled.InputWrapper>
                      <Field name="description">
                        {({ input }) => <Input {...input} label="Description" />}
                      </Field>

                      <ModuleStyled.SwitchWrapper>
                        <Field name="is_complete">
                          {({ input }) => (
                            <Switch {...input} checked={input.value} label="Complete Chart" />
                          )}
                        </Field>
                        <Field name="is_consolidation">
                          {({ input }) => (
                            <Switch {...input} checked={input.value} label="Consolidation Chart" />
                          )}
                        </Field>
                        <Field name="is_default_tenant">
                          {({ input }) => (
                            <Switch {...input} checked={input.value} label="Default for Tenant" />
                          )}
                        </Field>
                        <Field name="is_default_entity">
                          {({ input }) => (
                            <Switch {...input} checked={input.value} label="Default for Entity" />
                          )}
                        </Field>
                        <Field name="is_mapped">
                          {({ input }) => (
                            <Switch {...input} checked={input.value} label="Mapped Chart" />
                          )}
                        </Field>
                        <Field name="is_tenant">
                          {({ input }) => (
                            <Switch {...input} checked={input.value} label="Tenant Chart" />
                          )}
                        </Field>
                      </ModuleStyled.SwitchWrapper>
                    </ModuleStyled.InputWrapper>
                  </Card>
                </ModuleStyled.CardsWrapper>
              </ModuleStyled.MaxWidthContent>
            </form>
          )}
        </Form>
      )}
    </Layout>
  );
}
