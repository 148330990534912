import Divider from "@synerise/ds-divider";
import Icon, { UserS, ReportM, ClockM } from "@synerise/ds-icon";
import DSMenu from "@synerise/ds-menu";

import { FilterOption } from "./ReportsList/ReportsList.types";
import { ReportsSidebarMenuTagsListSection } from "./ReportsSidebarMenuTagsListSection";

// TODO: Replace divider by solid grey line (instead of dotted)

type ReportsSidebarMenuProps = {
  selectedFilters: FilterOption[];
  onAddFilter: (filter: FilterOption) => void;
  onResetFilters: () => void;
  tagsSelected: number[];
  onTagSelectChange: (id: number, checked: boolean) => void;
};

export function ReportsSidebarMenu({
  tagsSelected,
  selectedFilters,
  onAddFilter,
  onResetFilters,
  onTagSelectChange,
}: ReportsSidebarMenuProps) {
  return (
    <DSMenu
      style={{ padding: "15px" }}
      selectedKeys={selectedFilters.length < 1 ? ["all"] : selectedFilters}
    >
      <DSMenu.Item
        key="all"
        prefixel={<Icon component={<UserS />} />}
        onClick={onResetFilters}
        text="All"
      />

      <DSMenu.Item
        key="recent"
        prefixel={<Icon component={<ClockM />} />}
        onClick={() => onAddFilter("recent")}
      >
        Recent
      </DSMenu.Item>
      {/* TODO: Draft Feature */}
      {/* <DSMenu.Item
        prefixel={<Icon component={<ReportM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Draft
      </DSMenu.Item> */}
      <DSMenu.Item
        key="private"
        prefixel={<Icon component={<ReportM />} />}
        onClick={() => {
          onAddFilter("private");
        }}
      >
        Private
      </DSMenu.Item>
      <DSMenu.Item
        key="public"
        prefixel={<Icon component={<ReportM />} />}
        onClick={() => {
          onAddFilter("public");
        }}
      >
        Public
      </DSMenu.Item>
      {/* TODO: Saved Filter */}
      {/* <DSMenu.Item
        prefixel={<Icon component={<CheckboxDeafultM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Saved
      </DSMenu.Item>
      */}
      {/* TODO: Templates */}
      {/*   <Divider style={{ padding: "5px 10px", margin: "7px 0px 0px 0px" }} dashed={false} />
      <DSMenu.Item
        prefixel={<Icon component={<FileTypeTextM />} />}
        onClick={() => {
          console.log("Click!");
        }}
      >
        Templates
      </DSMenu.Item>  */}
      <Divider style={{ padding: "5px 10px", margin: "7px 0px 0px 0px" }} dashed={false} />
      <ReportsSidebarMenuTagsListSection onTagSelectChange={onTagSelectChange} tagsSelected={tagsSelected.map(tag => ({id: tag, canDelete: true}))} />
    </DSMenu>
  );
}
