import { useState } from "react";
import { FaMicrosoft } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { LockM } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";
import { Title } from "@synerise/ds-typography";

import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const AvatarInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export function AccessProviders() {
  const navigate = useNavigate();
  const [showFirstCard, setShowFirstCard] = useState(true);
  const [showSecondCard, setShowSecondCard] = useState(true);
  const [showThirdCard, setShowThirdCard] = useState(true);

  return (
    <Layout
      left={LeftLayoutContent}
      header={
        <PageHeader onGoBack={() => navigate("/settings/access")} title="Identity Providers" />
      }
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Title level={3}>Configure Identity Providers</Title>
          <Card
            background="white-shadow"
            title="Single Sign On"
            withHeader
            description="Select access options"
            hideContent={showFirstCard}
            icon={<LockM />}
            iconColor={"#AEAEAE"}
            headerSideChildren={
              <SideButtonsContainer>
                <Button
                  onClick={() => setShowFirstCard(!showFirstCard)}
                  type="secondary"
                  disabled={true}
                >
                  Coming soon
                </Button>
              </SideButtonsContainer>
            }
          >
            No-Content
          </Card>
          <Card
            background="white-shadow"
            title="Google"
            description="Allow users to login with Google"
            withHeader
            hideContent={showSecondCard}
            icon={<FcGoogle />}
            iconColor={"#AEAEAE"}
            headerSideChildren={
              <SideButtonsContainer>
                <Button
                  onClick={() => setShowSecondCard(!showSecondCard)}
                  type="secondary"
                  disabled={true}
                >
                  Coming soon
                </Button>
              </SideButtonsContainer>
            }
          >
            No-Content
          </Card>
          <Card
            background="white-shadow"
            title="Microsoft"
            withHeader
            description="Allow Microsoft account login"
            hideContent={showThirdCard}
            icon={<FaMicrosoft />}
            iconColor={"#AEAEAE"}
            headerSideChildren={
              <SideButtonsContainer>
                <Button
                  onClick={() => setShowThirdCard(!showThirdCard)}
                  type="secondary"
                  disabled={true}
                >
                  Coming soon
                </Button>
              </SideButtonsContainer>
            }
          >
            No-Content
          </Card>
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
