import { useNavigate, useParams } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Result from "@synerise/ds-result";

import * as Styled from "../login.styles";

type EmailSentParams = {
  email: string;
};

export function ForgotPasswordEmailSent() {
  const { email } = useParams<EmailSentParams>();
  const navigate = useNavigate();

  if (!email) {
    navigate("/login");
  }

  return (
    <Styled.CardContainer>
      <Card title="Email sent" withHeader>
        <Result
          type="success"
          title="Check your email"
          description={`We have sent a password recover instructions to ${email}`}
        />
        <Styled.FooterButtonsContainer>
          <Button onClick={() => navigate("/login")}>Back to login</Button>
        </Styled.FooterButtonsContainer>
      </Card>
    </Styled.CardContainer>
  );
}
