import styled, { css } from "styled-components";

export const TableOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;

  & > div {
    display: flex;
    gap: 15px;
  }
`;

export const DateRangePickerContainer = styled.div`
  display: flex;
  gap: 5px;

  .clear-range-button {
    cursor: pointer;
  }
`;

export const KPICell = styled.div`
  cursor: pointer;
`;

type TableBodyRowProps = {
  isGrouped: boolean;
  isSelected?: boolean;
};

export const TableBodyRow = styled.tr`
  ${({ isGrouped, isSelected = false }: TableBodyRowProps) => css`
    border-bottom: ${isGrouped ? "1px solid #f2f2f2" : "none"};
    background-color: ${isSelected ? "#EBF2FF" : "none"};
  `}

  &.group-row {
    td {
      padding: 5px 5px 5px 5px;
    }
  }

  & + &.group-row {
    td {
      padding: 64px 5px 5px 5px;
    }
  }
`;

export const TableCell = styled.td<{ darker: boolean }>`
  padding: 5px;
  color: #616161;
  font-weight: 500;
  background-color: ${({ darker }) => (darker ? "#f5f5f5" : "none")};
`;

export const PlaceHolderCell = styled.div`
  padding-left: 5px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: #006bf8;
  }
`;

export const CenteredCellContent = styled.div`
  width: 100%;
  text-align: center;
`;

export const CenteredHeaderContent = styled.span`
  margin: 0 5px;
  width: 100%;
  text-align: center;
`;
