import { formatDistance } from "date-fns";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { UserPrivateAccessM } from "@synerise/ds-icon";
import InlineEdit from "@synerise/ds-inline-edit";
import { Input, InputMultivalue } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";

import { AvatarUpload } from "@/components/AvatarUpload/AvatarUpload";
import { StatusBadge } from "@/components/StatusBadge";
import { useAccountUser } from "@/hooks/useAccountUser";
import { useAuth } from "@/hooks/useAuth";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { LeftLayoutContent } from "../SettingsLeftLayoutContent";
import AccountService from "./account.services";
import * as Styled from "./account.styles";

// Schemas & Types
export type PatchUserAccountFormValues = {
  email: string;
  username?: string;
  phone?: string;
  language?: string;
};

const editUserAccountSchema = yup.object().shape({
  username: yup.string(),
});

const getFirstAndLastNameFromFullName = (fullName: string) => {
  const [firstName, ...lastName] = fullName.split(" ");
  return { firstName, lastName };
};

const formatDate = (date: Date) => {
  return formatDistance(date, new Date());
};

export function AccountProfile() {
  const validate = useValidationSchema(editUserAccountSchema);
  const navigate = useNavigate();

  const { user, selectedMembership, getUser } = useAuth();
  const [userFullName, setUserFullName] = useState(`${user?.fname} ${user?.lname}`);
  const [inEditMode, setInEditMode] = useState(false);
  const { updateAccountUser } = useAccountUser();
  const { languages } = useSupportInfos();
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(
    selectedMembership?.preferences?.logo_url
  );
  const [uploadingAvatar, setUploadingAvatar] = useState(false);

  const allRoles =
    user?.memberships.reduce((acc, membership) => {
      const accessGroupsFromMembership =
        membership.access_groups.map((accessGroup) => accessGroup.title) || [];

      return [...acc, ...accessGroupsFromMembership];
    }, [] as string[]) || [];

  async function updateUserName(fname: string, lname: string) {
    if (!user?.id) return;

    function onSuccess() {}

    function onError(errorMessage: string) {
      message.error(errorMessage);
    }

    await updateAccountUser({
      id: user.id,
      onError,
      onSuccess,
      payload: {
        fname,
        lname,
      },
    });
  }

  async function onSubmit(values: PatchUserAccountFormValues) {
    if (!user?.id) return;

    function onSuccess() {
      message.success("Account updated");
      setInEditMode(false);
    }

    function onError(text: string) {
      message.error(text);
    }

    await updateAccountUser({
      id: user.id,
      onError,
      onSuccess,
      payload: {
        username: values.username,
        phone: values.phone,
        preferences: {
          language: values.language,
        },
      },
    });
  }

  async function handleAvatarUpload(file: File) {
    if (!selectedMembership || !user) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      setUploadingAvatar(true);
      const response = await AccountService.uploadAvatar(selectedMembership.id, formData);
      await getUser(user.id);
      setAvatarUrl(response);
      setUploadingAvatar(false);
    } catch (err) {
      message.error("Could not upload avatar");
    }
  }

  const initialFormValues = {
    username: user?.username,
    email: user?.email,
    phone: user?.phone,
    language: user?.preferences?.language,
  };

  return (
    <Layout
      left={LeftLayoutContent}
      header={<PageHeader onGoBack={() => navigate("/settings/home")} title="Account Details" />}
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Form<PatchUserAccountFormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialFormValues}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Styled.ProfileInfoRow>
                  <AvatarUpload
                    name={userFullName}
                    onSelectAvatar={handleAvatarUpload}
                    imageUrl={avatarUrl}
                    uploadingAvatar={uploadingAvatar}
                  />

                  <Styled.ProfileUserInfos>
                    <InlineEdit
                      input={{
                        value: userFullName,
                        onChange: (event) => {
                          const { value } = event.target;
                          setUserFullName(value);
                        },
                        onBlur: (event) => {
                          const { value } = event.target;

                          const { firstName, lastName } = getFirstAndLastNameFromFullName(
                            value.trim()
                          );

                          updateUserName(firstName, lastName.join(" "));
                        },
                      }}
                      size="normal"
                    />
                    <StatusBadge active={user?.is_confirmed ?? false} />
                  </Styled.ProfileUserInfos>

                  <Styled.UserUpdatedInfo>
                    <p>Last updated</p>
                    <p>
                      {user?.updated_at
                        ? formatDate(new Date(user.updated_at))
                        : user?.created_at
                        ? formatDate(new Date(user.created_at))
                        : 0}{" "}
                      ago
                    </p>
                  </Styled.UserUpdatedInfo>
                </Styled.ProfileInfoRow>
                <Card
                  background="white-shadow"
                  title="Basic Information"
                  withHeader
                  icon={<UserPrivateAccessM />}
                  iconColor="#AEAEAE"
                  headerSideChildren={
                    !inEditMode ? (
                      <Styled.SideButtonsContainer>
                        <Button onClick={() => setInEditMode(true)} type="secondary">
                          Edit
                        </Button>
                      </Styled.SideButtonsContainer>
                    ) : (
                      <Styled.SideButtonsContainer>
                        <Button onClick={() => setInEditMode(false)} type="secondary">
                          Cancel
                        </Button>
                        <Button onClick={handleSubmit} type="primary">
                          Save
                        </Button>
                      </Styled.SideButtonsContainer>
                    )
                  }
                >
                  <ModuleStyled.InputWrapper>
                    <Field name="email">
                      {({ input }) => (
                        <Input
                          label="Email"
                          disabled
                          description="Your primary email address. Will be used for notifications and password recovery"
                          {...input}
                        />
                      )}
                    </Field>
                    <Field name="username">
                      {({ input }) => (
                        <Input
                          label="Username"
                          disabled={!inEditMode}
                          description="If you prefer you can always use a username to login."
                          {...input}
                        />
                      )}
                    </Field>
                    <Field name="phone">
                      {({ input }) => (
                        <Input
                          label="Phone Number"
                          disabled={!inEditMode}
                          description="Your primary phone number (optional)"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="language">
                      {({ input, meta }) => (
                        <Select
                          {...input}
                          showSearch
                          disabled={!inEditMode}
                          label="Language"
                          description="This will change the language of the entire application"
                          placeholder={"Language"}
                          errorText={meta.touched && meta.error}
                          options={languages.map((language) => ({
                            value: language.code,
                            name: language.lang_en,
                            label: language.lang_en,
                          }))}
                          filterOption={(inputValue, option) => {
                            return (
                              option!.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                            );
                          }}
                        />
                      )}
                    </Field>
                    <Field name="roles">
                      {({ input }) => (
                        <InputMultivalue
                          label="Assigned Roles"
                          description="Your currently assigned access groups (roles)"
                          disabled
                          values={allRoles}
                          {...input}
                        />
                      )}
                    </Field>
                  </ModuleStyled.InputWrapper>
                </Card>
              </form>
            )}
          </Form>
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
