import { Style } from "@synerise/ds-layout";

export const gridItemsLayoutStyles: Style<React.CSSProperties> = {
  left: {
    width: "250px",
  },
  right: {
    width: "250px",
  },
  leftInner: {
    padding: "0",
  },
  rightInner: {
    padding: "0",
  },
  mainInner: {
    padding: "30px",
  },
};

export const reportBuilderGridLayoutStylesInitialConfig: React.CSSProperties = {
  minHeight: "60vh",
  background: "#fff",
  width: "100%",
  margin: "0 auto",
};
