import { useMemo } from "react";
import { useQuery } from "react-query";

import { SupportInfosService } from "@/core/support-infos/support-infos.services";
import {
  Country,
  Currency,
  IndustryGICS,
  IndustryISIC,
  Language,
  Unit,
} from "@/core/support-infos/support-infos.types";
import { DSCountryCodes } from "@/utils/synerise";

const SUPPORT_INFOS_QUERY_KEY = "support-infos";

async function getSupportInfos() {
  return await SupportInfosService.list();
}

export const filterCountriesByDSAvailablesFlags = (countries: Country[]) => {
  return countries.filter((country) => DSCountryCodes.includes(country.alpha2));
};

type IndustriesISICGroup = {
  code: string;
  name: string;
};

type IndustriesGICSGroup = {
  code: string;
  name: string;
};

type UseSupportInfoTypeReturn = {
  supportInfosLoading: boolean;
  countries: Country[];
  currencies: Currency[];
  languages: Language[];
  industriesISIC: IndustryISIC[];
  industriesISICGroups: IndustriesISICGroup[];
  industriesGICS: IndustryGICS[];
  industriesGICSGroups: IndustriesGICSGroup[];
  units: Unit[];
};

export function useSupportInfos(): UseSupportInfoTypeReturn {
  const supportInfosFromQuery = useQuery(SUPPORT_INFOS_QUERY_KEY, () => getSupportInfos());

  const languages = supportInfosFromQuery.data?.languages ?? [];
  const currencies = supportInfosFromQuery.data?.currencies ?? [];
  const industriesISIC = supportInfosFromQuery.data?.industries_isic ?? [];

  const industriesISICGroups = industriesISIC
    .map((industry) => ({
      code: industry.section_code,
      name: industry.section_name,
    }))
    .reduce((acc: IndustriesISICGroup[], curr) => {
      if (!acc.find((group) => group.code === curr.code)) {
        acc.push(curr);
      }
      return acc;
    }, []);

  const industriesGICS = supportInfosFromQuery.data?.industries_gics ?? [];

  const industriesGICSGroups = industriesGICS
    .map((industry) => ({
      code: industry.sector_code,
      name: industry.sector_name,
    }))
    .reduce((acc: IndustriesISICGroup[], curr) => {
      if (!acc.find((group) => group.code === curr.code)) {
        acc.push(curr);
      }
      return acc;
    }, []);

  const units = useMemo(() => {
    return supportInfosFromQuery.data?.units ?? [];
  }, [supportInfosFromQuery.data]);

  const countries = useMemo(() => {
    if (supportInfosFromQuery.data?.countries) {
      return filterCountriesByDSAvailablesFlags(supportInfosFromQuery.data.countries);
    }

    return [];
  }, [supportInfosFromQuery.data]);

  return {
    supportInfosLoading: supportInfosFromQuery.isLoading,
    countries,
    industriesISIC,
    industriesISICGroups,
    industriesGICS,
    industriesGICSGroups,
    currencies,
    languages,
    units,
  };
}
