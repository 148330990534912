import { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";
import { queryClient } from "@/utils/queryClient";

import { kpiRoutes } from "../kpi.routes";
import { Aggregation, Importance, Periodicity } from "../kpi.types";
import { KPIS_QUERY_KEY } from "./hooks/kpi.config";
import { useKpis } from "./hooks/useKpis";
import { KPIFormCreateDetailsStep } from "./KPIFormCreateDetailsStep";

export const SideButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const AvatarInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type KPIMetaCreateFormValues = {
  title: string;
  description: string;
  is_shared: boolean;
  group_id?: number;
  units_id: number;
  periodicity: Periodicity;
  version: number;
  aggregation: Aggregation;
  is_calculated: boolean;
  is_higher: boolean;
  is_entity: boolean;
  entity_id?: number;
};

const createKPIMetaSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  shared: yup.boolean(),
  group_id: yup.number(),
  // units_id: yup.number().required("Units is required"),
  periodicity: yup.string().required("Periodicity is required"),
  aggregation: yup.string().required("Aggregation is required"),
  is_calculated: yup.boolean(),
  is_higher: yup.boolean(),
  is_entity: yup.boolean(),
  entity_id: yup.number().when("is_entity", {
    is: true,
    then: yup.number().required("Entity required"),
  }),
});

const createKPIFormInitialValues = {
  version: 1,
  shared: false,
  is_calculated: false,
  is_higher: false,
  is_entity: false,
};

// TODO: add formula and is_calculated
export function KPICreate() {
  const navigate = useNavigate();
  const validate = useValidationSchema(createKPIMetaSchema);
  const { createKpi } = useKpis({
    show_values: false,
  });
  const [importance, setImportance] = useState<Importance>(1);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function onSubmit(values: KPIMetaCreateFormValues) {
    setSubmitLoading(true);
    const onKPIMetaError = () => {
      message.error("KPI Meta failed");
      setSubmitLoading(false);
    };
    try {
      await createKpi({
        payload: { ...values, importance },
        onError: onKPIMetaError,
      });
      message.success("KPI imported");
      queryClient.invalidateQueries(KPIS_QUERY_KEY);
      navigate(kpiRoutes.library);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate("/kpis/library")}
          title="Create New KPI"
          rightSide={
            <>
              <Button type="tertiary" onClick={() => navigate("/kpis/library")}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                form="kpi-form-create"
                loading={submitLoading}
                disabled={submitLoading}
              >
                Create
              </Button>
            </>
          }
        />
      }
    >
      <Form<KPIMetaCreateFormValues>
        onSubmit={onSubmit}
        validate={validate}
        initialValues={createKPIFormInitialValues}
      >
        {({ handleSubmit, values }) => (
          <form id="kpi-form-create" onSubmit={handleSubmit}>
            <ModuleStyled.MaxWidthContent>
              <ModuleStyled.CardsWrapper>
                <KPIFormCreateDetailsStep
                  isEntity={values.is_entity}
                  importance={importance}
                  setImportance={setImportance}
                  isCalculated={values.is_calculated}
                />
              </ModuleStyled.CardsWrapper>
            </ModuleStyled.MaxWidthContent>
          </form>
        )}
      </Form>
    </Layout>
  );
}
