import Switch from "@synerise/ds-switch";

import * as Styled from "./styles";

type TableHeaderLeftContentProps = {
  showBudgetVariation: boolean;
  onShowBudgetVariationChange: (showBudgetVariation: boolean) => void;
};

export function TableHeaderLeftContent({
  showBudgetVariation,
  onShowBudgetVariationChange,
}: TableHeaderLeftContentProps) {
  return (
    <Styled.Container>
      <Switch label="Adjustments" checked={false} onChange={() => {}} />
      <Switch label="Vertical Analysis" checked={false} onChange={() => {}} />
      <Switch
        label="vs. Budget"
        checked={showBudgetVariation}
        onChange={(checked: boolean) => onShowBudgetVariationChange(checked)}
      />
    </Styled.Container>
  );
}
