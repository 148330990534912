import { formatDistanceToNow } from "date-fns";

import { RolesTableData } from "./access-groups.table.columns";
import { AccessGroup } from "./access-groups.types";

function mapAccessGroupToTableData(accessGroup: AccessGroup): RolesTableData {
  return {
    id: accessGroup.id,
    title: accessGroup.title,
    description: accessGroup.description,
    membersCount: accessGroup.members.length,
    allowedModules: accessGroup.modules,
    createdAt: `${formatDistanceToNow(new Date(accessGroup.created_at))} ago`,
    isSystem: accessGroup.is_system,
  };
}

export function mapAccessGroupsToTableData(accessGroups: AccessGroup[]): RolesTableData[] {
  return accessGroups.map(mapAccessGroupToTableData);
}
