import { AppModules } from "./permissions.types";

type AccessModulesPermissionsResources = {
  [key in AppModules]: {
    name: string;
    permissions: Record<string, string>;
  };
};

export const accessModulesPermissionsResources: AccessModulesPermissionsResources = {
  tenants: {
    name: "Organization",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  plans: {
    name: "Plan",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  segments: {
    name: "Segment",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  users: {
    name: "Users",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  members: {
    name: "Members",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  access: {
    name: "Access Groups",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  permissions: {
    name: "Permissions",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  entities_type: {
    name: "Entities Type",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  entities_relations: {
    name: "Entities Relations",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  collections: {
    name: "Collections",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  groups: {
    name: "Groups",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  companies: {
    name: "Companies",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  captable: {
    name: "Captable",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  dimensions: {
    name: "Dimensions",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  kpis: {
    name: "KPIs",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  kpis_group: {
    name: "KPIs Group",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  kpi_datas: {
    name: "KPIs Data",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  kpi_targets: {
    name: "KPIs Targets",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  trialbalances: {
    name: "Trial Balances",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  accounts: {
    name: "Accounts",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  ledgers: {
    name: "Ledgers",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  charts: {
    name: "Charts",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  statements: {
    name: "Statements",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  ratios: {
    name: "Ratios",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  ratios_targets: {
    name: "Ratios Targets",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  adjustments: {
    name: "Adjustments",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  consolidation: {
    name: "Consolidation",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  budgets: {
    name: "Budgets",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  benchmarks: {
    name: "Benchmarks",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  forecast: {
    name: "Forecast",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  portal: {
    name: "Portal",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  reports: {
    name: "Reports",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
  dashboards: {
    name: "Dashboard",
    permissions: {
      read: "Read",
      create: "Create",
      update: "Update",
      delete: "Delete",
    },
  },
};
