import { CommentsDrawer } from "@/components/CommentsDrawer";

import { useTrialBalanceComments } from "./useTrialBalanceComments";

type LedgerCommentsDrawerProps = {
  isVisible: boolean;
  trialBalanceId: number;
  versionId: number;
  lineId: number;
  onClose: () => void;
};

export function LedgerCommentsDrawer({
  isVisible,
  trialBalanceId,
  versionId,
  lineId,
  onClose,
}: LedgerCommentsDrawerProps) {
  const { handleCreateComment, handleRemoveComment, comments, isLoadingComments } =
    useTrialBalanceComments({ lineId, trialBalanceId, versionId });

  return (
    <CommentsDrawer
      onClose={onClose}
      onCreateComment={handleCreateComment}
      onRemoveComment={handleRemoveComment}
      isVisible={isVisible}
      noCommentsDescription="No comments in this trial balance line"
      comments={comments}
      isLoadingComments={isLoadingComments}
    />
  );
}
