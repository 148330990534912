import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 3fr 2fr;
`;

export const ImageSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c1727;
  color: #fff;
  font-size: 3rem;
`;

export const MainSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;
