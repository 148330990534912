import styled, { css } from "styled-components";

export const Table = styled.table`
  width: 100%;
  white-space: nowrap;
`;

export const TableHead = styled.thead`
  color: #bebebe;
  border-bottom: 1px solid #f2f2f2;
`;

export const TableHeaderRow = styled.tr``;

export const TableHeaderRowLeft = styled.tr`
  text-align: left;
  align-items: left;
`;

export const TableHeaderRowRight = styled.tr`
  text-align: right;
  align-items: right;
`;

export const TableHeader = styled.th``;

export const TableHeaderLeft = styled.th`
  text-align: left;
`;

export const TableHeaderRight = styled.th`
  text-align: right;
  align-items: center;
`;

export const TableHeaderCenter = styled.th`
  text-align: center;
  align-items: center;
`;

export const TableCellWithFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr<{ isSelected?: boolean }>`
  ${({ isSelected = false }) => css`
    background-color: ${isSelected ? "#EBF2FF" : "none"};
  `}
`;

export const TableCell = styled.td<{ darker?: boolean }>`
  padding: 4px;
  color: #6a7580;
  font-weight: 500;
  background-color: ${({ darker }) => (darker ? "#f5f5f5" : "none")};
`;

export const TableCellWithIcon = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TableCellWithNumbers = styled.div`
  text-align: right;
  gap: 10px;
  align-items: right;
`;

export const RowActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export const RowCellActionButtons = styled.div`
  display: flex;
  justify-content: end;
  gap: 15px;
`;

export const BadgeCell = styled.div`
  width: 80px;
  border-radius: 5px;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palette["grey-500"]};
  background-color: ${({ theme }) => theme.palette["grey-200"]};
`;

export const CheckCell = styled.div`
  display: flex;
  justify-content: center;
`;
