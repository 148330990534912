import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "@synerise/ds-button";
import Icon, { InboxNoResultsL } from "@synerise/ds-icon";

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
`;

type EmptyTableProps = {
  text: string;
  path?: string;
  buttonText?: string;
};

export function EmptyTable({ text, path, buttonText }: EmptyTableProps) {
  const navigate = useNavigate();

  return (
    <BodyContainer>
      <Icon component={<InboxNoResultsL />} color={"#AEAEAE"} size={50} />
      <h3>{text}</h3>
      {buttonText && (
        <Button
          type="primary"
          onClick={() => {
            if (!path) return;
            navigate(path);
          }}
        >
          {buttonText}
        </Button>
      )}
    </BodyContainer>
  );
}
