import { Layout } from "react-grid-layout";

import { EditItemDrawerTitleContent } from "../../EditItemDrawer/contents/EditItemDrawerTitleContent";
import { ItemConfig, ItemLayout } from "../../LayoutBuilder.types";

export type TitleData = {
  title: string;
  fontSize: number;
};

type GetTitleConfigProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>, layout?: Partial<Layout>) => void;
};

export function getTitleConfig({
  editingItem,
  updateItem,
}: GetTitleConfigProps): ItemConfig<TitleData> {
  return {
    name: "title",
    editDrawerComponent: (
      <EditItemDrawerTitleContent editingItem={editingItem} updateItem={updateItem} />
    ),
    resizeHandles: ["e"],
    initialLayout: {
      w: 12,
      h: 1,
      type: "title",
      minW: 1,
      minH: 1,
      maxW: 12,
      maxH: 12,
      resizeHandles: [],
      data: {
        title: "Title",
        fontSize: 16,
      },
    },
  };
}
