import Card from "@synerise/ds-card";
import Icon, { TextM, ListNumbersM, FileM } from "@synerise/ds-icon";

import * as Styled from "./accounts-header-summary.styles";
import { AccountsHeaderSummaryItem } from "./AccountsHeaderSummaryItem";

type ChartOfAccountsDetailProps = {
  accountsType: string;
  accountsCount: number;
  isDefault: boolean;
};

export function AccountsHeaderSummary({
  accountsType,
  accountsCount,
  isDefault,
}: ChartOfAccountsDetailProps) {
  return (
    <Card>
      <Styled.Container>
        <AccountsHeaderSummaryItem
          icon={<Icon component={<TextM />} color="#004ff8" />}
          title="TYPE"
          text={accountsType}
        />
        <AccountsHeaderSummaryItem
          icon={<Icon component={<ListNumbersM />} color="#004ff8" />}
          title="ACCOUNTS"
          text={accountsCount}
        />
        <AccountsHeaderSummaryItem
          icon={<Icon component={<FileM />} color="#004ff8" />}
          title="DEFAULT"
          text={isDefault ? "Yes" : "No"}
        />
      </Styled.Container>
    </Card>
  );
}
