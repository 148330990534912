import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import PageHeader from "@synerise/ds-page-header";

import { useDimensions } from "@/hooks/useDimensions";
import { useDimension, useDimensionTypes } from "@/hooks/useDimensionTypes";

import { dataManagementData } from "../data-management.data";
import { DimensionsTable } from "../dimensions/DimensionsTable";

export function DimensionTypesDetail() {
  const { mapDimensionsListToTable } = useDimensions();
  const { isDimensionTypesLoading } = useDimensionTypes();

  const navigate = useNavigate();
  const { id } = useParams();
  const { dimensionType } = useDimension(Number(id));

  useEffect(() => {
    if (!id) {
      navigate(dataManagementData.dimensionTypes.path);
    }
  }, [id, navigate]);

  const dimensions =
    dimensionType?.values.map((dimension) => {
      return {
        dimensionId: dimensionType.id,
        dimensionValueId: dimension.id,
        name: dimension.title,
        type: dimensionType.title,
      };
    }) || [];
  const dimensionsMapped = mapDimensionsListToTable(dimensions);

  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate(dataManagementData.dimensionTypes.path)}
          title="Dimension"
          rightSide={
            <Button
              type="primary"
              onClick={() => navigate(dataManagementData.dimensions.createPath + `/${id}`)}
            >
              New dimension
            </Button>
          }
        />
      }
    >
      {isDimensionTypesLoading ? (
        <Card>
          <Loader label="Loading dimensions values. Just a second" labelPosition="bottom" />
        </Card>
      ) : (
        <>
          <Card title={dimensionType?.title} withHeader description={dimensionType?.description} />
          <DimensionsTable dimensions={dimensionsMapped} />
        </>
      )}
    </Layout>
  );
}
