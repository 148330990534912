import styled from "styled-components";

export const TableOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;

  & > div {
    display: flex;
    gap: 15px;
  }
`;
