import { Link, useLocation } from "react-router-dom";

import AppMenu from "@synerise/ds-app-menu";
import { SettingsColorM, SettingsGreyM } from "@synerise/ds-icon";

export function SettingsMenu() {
  const { pathname } = useLocation();
  return (
    <AppMenu.Item
      name="Settings"
      id="settings"
      className="app-menu-item"
      subMenu={
        <AppMenu.SubMenu>
          <Link to="/settings/home">
            <AppMenu.SubMenu.Title>Settings</AppMenu.SubMenu.Title>
          </Link>

          <AppMenu.SubMenu.SubTitle>My Account</AppMenu.SubMenu.SubTitle>
          <Link to="/settings/account">
            <AppMenu.SubMenu.Item active={pathname === "/settings/account"}>
              Profile
            </AppMenu.SubMenu.Item>
          </Link>

          <Link to="/settings/account/security">
            <AppMenu.SubMenu.Item active={pathname === "/settings/account/security"}>
              Security
            </AppMenu.SubMenu.Item>
          </Link>

          <Link to="/settings/account/notifications">
            <AppMenu.SubMenu.Item active={pathname === "/settings/account/notifications"}>
              Notifications
            </AppMenu.SubMenu.Item>
          </Link>

          <AppMenu.SubMenu.SubTitle>Organization</AppMenu.SubMenu.SubTitle>
          <Link to="/settings/organization">
            <AppMenu.SubMenu.Item active={pathname === "/settings/organization"}>
              Global
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to="/settings/organization/preferences">
            <AppMenu.SubMenu.Item active={pathname === "/settings/organization/preferences"}>
              Preferences
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to="/settings/organization/appearance">
            <AppMenu.SubMenu.Item active={pathname === "/settings/organization/appearance"}>
              Appearance
            </AppMenu.SubMenu.Item>
          </Link>

          <AppMenu.SubMenu.SubTitle>Users</AppMenu.SubMenu.SubTitle>
          <Link to="/settings/users">
            <AppMenu.SubMenu.Item active={pathname === "/settings/users"}>
              Users
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to="/settings/access">
            <AppMenu.SubMenu.Item active={pathname === "/settings/access"}>
              Access Groups
            </AppMenu.SubMenu.Item>
          </Link>
          <Link to="/settings/providers">
            <AppMenu.SubMenu.Item active={pathname === "/settings/providers"}>
              Identity Providers
            </AppMenu.SubMenu.Item>
          </Link>
        </AppMenu.SubMenu>
      }
    >
      <AppMenu.Item.Icon active={<SettingsColorM />} inActive={<SettingsGreyM />} />
    </AppMenu.Item>
  );
}
