import Button from "@synerise/ds-button";
import Drawer from "@synerise/ds-drawer";
import Icon, { CloseM } from "@synerise/ds-icon";
import Loader from "@synerise/ds-loader";
import Typography from "@synerise/ds-typography";

import { useAuth } from "@/hooks/useAuth";

import { Comment } from "./Comment";
import { EmptyComments } from "./EmptyComments";
import { NewComment } from "./NewComment";
import * as Styled from "./styles";

type CommentType = {
  id: number;
  author_id: number;
  author: {
    avatar?: string;
    fname: string;
    lname?: string;
  };
  comment: {
    text: string;
  };
  updated_at?: string;
  created_at: string;
};

type CommentsDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  subtitle?: React.ReactNode;
  isLoadingComments: boolean;
  noCommentsDescription: string;
  comments: CommentType[];
  onRemoveComment: (commentId: number) => void;
  onCreateComment: (text: string) => Promise<void>;
};

export function CommentsDrawer({
  isVisible,
  onClose,
  isLoadingComments,
  noCommentsDescription,
  subtitle,
  comments,
  onRemoveComment,
  onCreateComment,
}: CommentsDrawerProps) {
  const { selectedMembership } = useAuth();
  return (
    <Drawer visible={isVisible} placement="right" width={400}>
      <Drawer.DrawerHeader>
        <Drawer.DrawerHeaderBar style={{ alignItems: "flex-start" }}>
          <Styled.DrawerTitleContainer>
            <Typography.Title style={{ margin: 0 }} level={3}>
              Comments
            </Typography.Title>
            {!!subtitle ? subtitle : null}
          </Styled.DrawerTitleContainer>
          <>
            <Button type="ghost" onClick={onClose} mode="single-icon">
              <Icon component={<CloseM />} />
            </Button>
          </>
        </Drawer.DrawerHeaderBar>
      </Drawer.DrawerHeader>
      <Drawer.DrawerBody style={{ height: "100%" }}>
        <Drawer.DrawerContent style={{ height: "100%" }}>
          <Styled.DrawerContent>
            <Styled.CommentList>
              {isLoadingComments ? (
                <Loader size="M" text="Loading comments..." labelPosition="bottom" />
              ) : comments.length < 1 ? (
                <EmptyComments description={noCommentsDescription} />
              ) : (
                comments.map((comment) => (
                  <Comment
                    canRemove={comment.author_id === selectedMembership?.id}
                    onRemove={() => onRemoveComment(comment.id)}
                    key={comment.id}
                    avatarSrc={comment.author.avatar}
                    userName={`${comment.author.fname} ${comment.author.lname || ""}`}
                    commentText={comment.comment.text}
                    date={
                      comment.updated_at
                        ? new Date(comment.updated_at)
                        : new Date(comment.created_at)
                    }
                  />
                ))
              )}
            </Styled.CommentList>
            <NewComment
              avatarSrc={selectedMembership?.preferences?.logo_url}
              onSubmit={onCreateComment}
              isSubmitting={false}
            />
          </Styled.DrawerContent>
        </Drawer.DrawerContent>
      </Drawer.DrawerBody>
    </Drawer>
  );
}
