import { useState } from "react";

import Button from "@synerise/ds-button";
import Drawer from "@synerise/ds-drawer";
import Icon, { CloseM } from "@synerise/ds-icon";
import { RawInput } from "@synerise/ds-input";
import Select from "@synerise/ds-select";

import { ForecastChart } from "./ForecastChart";
import * as Styled from "./styles";
import { ChartType } from "./types";

type ForecastDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function ForecastDrawer({ isOpen, onClose }: ForecastDrawerProps) {
  const [chartType, setChartType] = useState<ChartType>("line");

  return (
    <Drawer
      visible={isOpen}
      onClose={onClose}
      placement="bottom"
      height={470}
      maskClosable
      mask={true}
      maskStyle={{
        background: "transparent",
      }}
      closeIcon={<Icon component={<CloseM />} size={20} />}
      getContainer={false}
      style={{ position: "absolute" }}
    >
      <Drawer.DrawerHeader>
        <Styled.Header>
          <Styled.Title>AE Meetings Conversion</Styled.Title>
          <div className="right-side">
            <Select
              value={chartType}
              onSelect={(value: any) => setChartType(value)}
              dropdownMatchSelectWidth={100}
            >
              <Select.Option value="line">Line</Select.Option>
              <Select.Option value="bar">Bar</Select.Option>
            </Select>
            <Button
              mode="single-icon"
              type="ghost"
              icon={<Icon component={<CloseM />} size={20} />}
              onClick={onClose}
            />
          </div>
        </Styled.Header>
      </Drawer.DrawerHeader>
      <Drawer.DrawerBody style={{ height: "100%" }}>
        <Drawer.DrawerContent style={{ height: "100%" }}>
          <Styled.Content>
            <Styled.InputsWrapper>
              <label>Method</label>
              <Select defaultValue={1}>
                <Select.Option value={1}>Linear Growth</Select.Option>
              </Select>
              <label>Period</label>
              <Select defaultValue={1}>
                <Select.Option value={1}>Monthly</Select.Option>
              </Select>
              <label>Value</label>
              <RawInput prefix={<div>#</div>} resetMargin defaultValue={4} />
            </Styled.InputsWrapper>
            <div style={{ width: "100%", height: "300px" }}>
              <ForecastChart type={chartType} />
            </div>
          </Styled.Content>
        </Drawer.DrawerContent>
      </Drawer.DrawerBody>
    </Drawer>
  );
}
