import Switch from "@synerise/ds-switch";

import * as Styled from "./styles";

type AdjustmentDrawerItemProps = {
  label: string;
  description: string;
  checked: boolean;
};

export function AdjustmentDrawerItem({ label, description, checked }: AdjustmentDrawerItemProps) {
  return (
    <Styled.Container>
      <div className="content">
        <label>{label}</label>
        <p>{description}</p>
      </div>
      <Switch label="" defaultChecked={checked} />
    </Styled.Container>
  );
}
