import { Layout } from "react-grid-layout";

import { EditItemDrawerImageContent } from "../../EditItemDrawer/contents/EditItemDrawerImageContent";
import { ItemConfig, ItemLayout } from "../../LayoutBuilder.types";

export type ImageData = {
  url: string | null;
};

type GetImageConfigProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>, layout?: Partial<Layout>) => void;
};

export function getImageConfig({
  editingItem,
  updateItem,
}: GetImageConfigProps): ItemConfig<ImageData> {
  return {
    name: "image",
    editDrawerComponent: (
      <EditItemDrawerImageContent editingItem={editingItem} updateItem={updateItem} />
    ),
    resizeHandles: ["e", "s", "se", "w", "sw"],
    initialLayout: {
      w: 5,
      h: 5,
      type: "image",
      minW: 1,
      minH: 2,
      maxW: 12,
      maxH: 12,
      resizeHandles: [],
      data: {
        url: null,
      },
    },
  };
}
