import { format } from "date-fns";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { queryClient } from "@/utils/queryClient";

import { LedgerListTableData } from "./LedgerList/ledger-list.types";
import { LEDGERS_QUERY_KEY } from "./ledgers.config";
import { FetchAllParams, TrialBalanceServices } from "./trialbalance.services";
import { TrialBalanceCreatePayload, TrialBalanceResponse } from "./trialbalance.types";

export function mapTrialBalancesToTable(
  trialBalances: TrialBalanceResponse[]
): LedgerListTableData[] {
  return trialBalances.map((trialBalance) => {
    return {
      id: trialBalance.id,
      type: "TrialBalance",
      date: format(new Date(trialBalance.date), "MMM / yyyy"),
      dateRaw: new Date(trialBalance.date),
      company: trialBalance.entity?.name || null,
      consistency: trialBalance.is_consistent || false,
      periodicity: trialBalance.periodicity || "monthly",
      isReviewed: trialBalance.is_reviewed === undefined ? null : trialBalance.is_reviewed,
    };
  });
}

async function getAllLedgers(fetchParams: FetchAllParams): Promise<TrialBalanceResponse[]> {
  return await TrialBalanceServices.fetchAll(fetchParams);
}

async function handleCreateLedger(data: TrialBalanceCreatePayload) {
  return await TrialBalanceServices.create(data);
}

type CreateLedgerProps = {
  onSuccess?: () => void;
  onError?: (text: string) => void;
  payload: TrialBalanceCreatePayload;
};

type UseLedgersReturn = {
  allLedgers: TrialBalanceResponse[];
  loadingAllLedgers: boolean;
  removeLedger: (
    id: number,
    onSuccess: () => void,
    onError: (text: string) => void
  ) => Promise<void>;
  isRemoveLedgerLoading: boolean;
  isCreateLedgerLoading: boolean;
  createLedger: (props: CreateLedgerProps) => Promise<TrialBalanceResponse>;
};

type UseLedgersProps = {
  fetchParams: FetchAllParams;
};

export function useLedgers({ fetchParams }: UseLedgersProps): UseLedgersReturn {
  const allLedgersFromQuery = useQuery([LEDGERS_QUERY_KEY, fetchParams], () =>
    getAllLedgers(fetchParams)
  );

  const removeLedgerMutation = useMutation(
    async ({ id }: { id: number; onSuccess: () => void; onError: (text: string) => void }) => {
      await TrialBalanceServices.delete(id, { force: false });
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(LEDGERS_QUERY_KEY);
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const createLedgerMutation = useMutation(
    [LEDGERS_QUERY_KEY],
    async ({ payload }: CreateLedgerProps) => await handleCreateLedger(payload),
    {
      onError: (error: any, { onError }) => {
        if (onError) {
          onError(error.message);
        }
      },
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(LEDGERS_QUERY_KEY);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );

  async function removeLedger(id: number, onSuccess: () => void, onError: (text: string) => void) {
    await removeLedgerMutation.mutateAsync({ id, onSuccess, onError });
  }

  async function createLedger(props: CreateLedgerProps) {
    return await createLedgerMutation.mutateAsync(props);
  }

  const allLedgers = useMemo(() => {
    return allLedgersFromQuery.data ?? [];
  }, [allLedgersFromQuery.data]);

  const isCreateLedgerLoading = useMemo(() => {
    return createLedgerMutation.isLoading;
  }, [createLedgerMutation.isLoading]);

  return {
    allLedgers,
    loadingAllLedgers: allLedgersFromQuery.isLoading,
    removeLedger,
    isRemoveLedgerLoading: removeLedgerMutation.isLoading,
    isCreateLedgerLoading,
    createLedger,
  };
}
