import { KeyboardEvent, useEffect, useState } from "react";
import "./styles.css";
import { v4 as uuidV4 } from "uuid";

import Button from "@synerise/ds-button";
import Icon, { Close2S } from "@synerise/ds-icon";

import { KpiResponse } from "@/modules/kpis/kpi.types";

type SuggestionsListProps = {
  activeSuggestionId: number;
  suggestions: KpiResponse[];
  onClick: (suggestion: KpiResponse) => void;
};

export type Token = {
  id: number | string;
  name: string;
};

function SuggestionsList({ suggestions, activeSuggestionId, onClick }: SuggestionsListProps) {
  return (
    <ul className="suggestions">
      {suggestions.map((suggestion) => (
        <li
          className={activeSuggestionId === suggestion.id ? "suggestion-active" : ""}
          key={suggestion.id}
          onClick={() => onClick(suggestion)}
        >
          {suggestion.title}
        </li>
      ))}
    </ul>
  );
}

type InputFormulaProps = {
  label: string;
  onChange: (expression: string) => void;
  suggestions: KpiResponse[];
};

export function InputFormula({ label, suggestions, onChange }: InputFormulaProps) {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<KpiResponse[]>([]);
  const [inputFormulaContent, setInputFormulaContent] = useState<string>("");

  const expression = inputFormulaContent
    .replace(/(<span (.)*?>)/gm, "")
    .replace(/(<\/span>)/gm, "")
    .replace(/&nbsp;/gm, "")
    .replace(/;/gm, "");

  const tokensNamesRegexp = /[^+%*-\/0-9\s]*/gim;
  const tokensNamesRegexMatch = expression.match(tokensNamesRegexp);
  const tokensName = tokensNamesRegexMatch?.filter((token) => !!token);

  const replaceExpressionTokensNameWithIds = () => {
    let expressionReplaced = String(expression).slice();
    suggestions.forEach((suggestion) => {
      expressionReplaced = expressionReplaced.replaceAll(
        suggestion.title,
        `{a${suggestion.id.toString(16)}}`
      );
    });

    return "=" + expressionReplaced;
  };

  const expressionWithTokensIds = !tokensName ? expression : replaceExpressionTokensNameWithIds();

  useEffect(() => {
    onChange(expressionWithTokensIds);
  }, [expressionWithTokensIds]);

  // const [isExpressionValid, setIsExpressionValid] = useState(false);

  // useEffect(() => {
  //   if (inputFormulaContent) {
  // const inputWithoutTags = inputFormulaContent
  //   .replace("&nbsp;", "")
  //   .replace(/(<span (.)*?>)/gm, "")
  //   .replace(/(<\/span>)/gm, "")
  //   .replace(/&nbsp;/gm, "")
  //   .replace(/;/gm, "");

  //     const isValid = validateFormula({
  //       formula: inputWithoutTags,
  //       data: suggestions,
  //     });
  //     setIsExpressionValid(isValid);
  //   }
  // }, [inputFormulaContent]);

  // const inputWithoutTagsWithPrefixAndSuffix = inputFormulaContent
  //   .replace(/(<span (.)*?>)/gm, "VAR_")
  //   .replace(/(<\/span>&nbsp;)/gm, "_VAR")
  //   .replace("&nbsp;", "");

  // const inputVariablesNamesWithPrefixAndSufix = inputWithoutTags.match(/VAR_(.)*?_VAR/gm);

  // const inputVariablesNames =
  //   inputVariablesNamesWithPrefixAndSufix?.map((name: string) => {
  //     return name.replace(/(VAR_|_VAR)/gm, "");
  //   }) || [];

  // const inputVariables: Token[] = inputVariablesNames
  //   .filter((inputVariable: string) =>
  //     suggestions.some((suggestion) => suggestion.name === inputVariable)
  //   )
  //   .map((inputVariable: string) => {
  //     const match = suggestions.find((suggestion) => suggestion.name === inputVariable)!;
  //     return {
  //       id: match.id,
  //       name: match.name,
  //       data: match.values,
  //     };
  //   });

  // Show suggestions on user input
  useEffect(() => {
    const formulaContainerElement = document.getElementById("formula-container");

    if (formulaContainerElement) {
      formulaContainerElement.addEventListener(
        "input",
        function (this) {
          let range, selection;
          let lastWord =
            this.innerHTML
              .replaceAll(/<[^>]*>/g, "")
              .split(" ")
              .pop() || "";

          const suggestionsFiltered = suggestions.filter((suggestion) => {
            if (lastWord === " ") return true;
            return suggestion.title.toLowerCase().indexOf(lastWord.toLowerCase()) > -1;
          });
          setActiveSuggestionIndex(0);
          setFilteredSuggestions(suggestionsFiltered);
          if (suggestionsFiltered.length > 0) setShowSuggestions(true);
          setInputFormulaContent(this.innerHTML);
          range = document.createRange();
          range.selectNodeContents(formulaContainerElement);
          range.collapse(false);
          selection = window.getSelection();
          selection!.removeAllRanges();
          selection!.addRange(range);
        },
        false
      );
    }

    return () => {
      if (formulaContainerElement) {
        formulaContainerElement.removeEventListener("input", () => {});
      }
    };
  }, []);

  function removeLastWordFromText(text: string): string {
    const words = text.split(" ");
    if (words.length > 2) {
      words.pop();
      return words.join(" ");
    } else {
      return "";
    }
  }

  const addTokenInFormulaContent = (suggestionName: string) => {
    setActiveSuggestionIndex(0);
    setFilteredSuggestions(suggestions);
    setShowSuggestions(false);
    setInputFormulaContent((prevState: any) => {
      const text = removeLastWordFromText(prevState);
      const uniqueId = uuidV4();
      return (
        text +
        " " +
        `<span contenteditable="false" class="token" data-unique-id="${uniqueId}">${suggestionName}</span>&nbsp;`
      );
    });
  };

  const onClick = (suggestion: KpiResponse) => {
    addTokenInFormulaContent(suggestion.title);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    // User pressed enter key
    if (e.keyCode === 13) {
      addTokenInFormulaContent(filteredSuggestions[activeSuggestionIndex].title);
    }
    // User pressed the up arrow key
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow key
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  const onClearInput = () => {
    setInputFormulaContent("");
  };

  return (
    <>
      <span style={{ fontWeight: "bold", fontSize: "12px", color: "#384350" }}>{label}</span>
      <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
        <div
          id="formula-container"
          contentEditable={true}
          onKeyDown={onKeyDown}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRight: "none",
            minWidth: "200px",
            padding: "5px",
            minHeight: "30px",
            width: "100%",
            height: "38px",
            lineHeight: "1.5",
          }}
          data-ph="Ex: KPI A * KPI B"
          dangerouslySetInnerHTML={{
            __html: inputFormulaContent,
          }}
        ></div>
        <div
          contentEditable={false}
          style={{
            display: "flex",
            height: "38px",
            border: "1px solid #ccc",
            borderRadius: "0 0.25rem 0.25rem 0",
          }}
        >
          <Button mode="single-icon" type="ghost" style={{ height: "100%" }} onClick={onClearInput}>
            <Icon component={<Close2S />} color="#aaa" />
          </Button>
          <Button
            type="ghost"
            style={{ height: "100%" }}
            onClick={() => alert(expressionWithTokensIds)}
          >
            Test
          </Button>
        </div>
      </div>

      {/* Valid or Invalid Formula Indicator */}
      {/*       {inputFormulaContent && !inputFormulaContent.includes("Type a formula") && (
        <div
          style={{
            marginTop: "5px",
            fontWeight: "bold",
            color: isExpressionValid ? "#399039" : "#c63d3d",
          }}
        >
          {isExpressionValid ? "Expression is valid" : "Expression is not valid"}
        </div>
      )}
 */}
      {showSuggestions && inputFormulaContent !== "" && (
        <SuggestionsList
          suggestions={filteredSuggestions}
          activeSuggestionId={
            filteredSuggestions.length > 0 ? filteredSuggestions[activeSuggestionIndex].id : 0
          }
          onClick={onClick}
        />
      )}
    </>
  );
}
