import { api } from "@/utils/http";

import { ChartCreatePayload, ChartResponse, ChartUpdatePayload } from "./chart.types";

export class ChartServices {
  static async fetchAll(entityID?: number) {
    const response = await api.get<ChartResponse[]>("/charts", {
      params: {
        entity_id: entityID,
      },
    });
    return response.data;
  }

  static async fetchOne(chartID: number) {
    const response = await api.get<ChartResponse>(`/charts/${chartID}`, {
      params: {
        show_accounts: true,
        nested: false,
      },
    });
    return response.data;
  }

  static async create(payload: ChartCreatePayload) {
    const response = await api.post<ChartResponse>("/charts", payload);
    return response.data;
  }

  static async delete(chartID: number) {
    const response = await api.delete<ApiStatusResponse>(`/charts/${chartID}`);
    return response.data;
  }

  static async update(chartID: number, payload: ChartUpdatePayload) {
    const response = await api.patch<ChartResponse>(`/charts/${chartID}`, payload);
    return response.data;
  }
}
