import { useEffect, useState } from "react";

import { Input } from "@synerise/ds-input";
import message from "@synerise/ds-message";

import * as Styled from "../kpi-create-budget-modal.styles";
import { useKPICreateBudget } from "../useKPICreateBudget";
import { useKPIsTable } from "../useKPIsTable";
import { calculateMonthsQuantityBetweenDates } from "../utils/calculateMonthsBetweenDates";
import { calculatePercentageAmountBudgetValues } from "../utils/calculatePercentageAmountBudgerValues";
import { DatePicker } from "./DatePicker";

export function KPISimpleBudgetPercentageAmount() {
  const [percentageAmount, setPercentageAmount] = useState(0);
  const {
    initialMonthSelected,
    finalMonthSelected,
    initialYearSelected,
    finalYearSelected,
    changeInitialMonthSelected,
    changeInitialYearSelected,
    changeFinalYearSelected,
    changeFinalMonthSelected,
    changeBudgetValues,
  } = useKPICreateBudget();
  const { kpiSelected } = useKPIsTable();

  function handleChangePercentageAmount(value: number) {
    setPercentageAmount(value);
  }

  useEffect(() => {
    if (!kpiSelected) return;

    const initialDate = new Date(initialYearSelected, initialMonthSelected);
    const finalDate = new Date(finalYearSelected, finalMonthSelected);

    if (initialDate > finalDate) return;

    const monthsQuantity = calculateMonthsQuantityBetweenDates(initialDate, finalDate);

    const linearAmountBudgetValues = calculatePercentageAmountBudgetValues(
      kpiSelected.values?.map((value) => value.value || 0) || [],
      monthsQuantity,
      percentageAmount,
      initialDate
    );

    changeBudgetValues(linearAmountBudgetValues);
  }, [
    kpiSelected,
    percentageAmount,
    initialMonthSelected,
    initialYearSelected,
    finalMonthSelected,
    finalYearSelected,
  ]);

  const handleChangeInitialDate = (month: number, year: number) => {
    const initialDate = new Date(year, month);
    const finalDate = new Date(finalYearSelected, finalMonthSelected);

    if (initialDate > finalDate) {
      message.error("Initial date can not be greater than final date");
      return;
    }

    changeInitialMonthSelected(month);
    changeInitialYearSelected(year);
  };

  const handleChangeFinalDate = (month: number, year: number) => {
    const initialDate = new Date(initialYearSelected, initialMonthSelected);
    const finalDate = new Date(year, month);

    if (initialDate > finalDate) {
      message.error("Initial date can not be greater than final date");
      return;
    }

    changeFinalMonthSelected(month);
    changeFinalYearSelected(year);
  };

  return (
    <>
      <Styled.DateFieldsRow>
        <label className="date-fields-label">From</label>
        <DatePicker
          month={initialMonthSelected}
          year={initialYearSelected}
          onChangeYear={(year) => handleChangeInitialDate(initialMonthSelected, year)}
          onChangeMonth={(month) => handleChangeInitialDate(month, initialYearSelected)}
        />
      </Styled.DateFieldsRow>
      <Styled.DateFieldsRow>
        <label className="date-fields-label">To</label>
        <DatePicker
          month={finalMonthSelected}
          year={finalYearSelected}
          onChangeYear={(year) => handleChangeFinalDate(finalMonthSelected, year)}
          onChangeMonth={(month) => handleChangeFinalDate(month, finalYearSelected)}
        />
      </Styled.DateFieldsRow>
      <Styled.AverageBudgetRow>
        <Input
          prefixel={<span className="input-prefix prefix-text">PERCENTAGE AMOUNT</span>}
          value={percentageAmount}
          suffix={<span className="suffix-text">%</span>}
          onChange={(event: any) => handleChangePercentageAmount(Number(event.target.value))}
          type="number"
          min={-100}
          max={100}
          step={1}
          style={{ fontSize: "1.2rem" }}
          placeholder={"50"}
        />
      </Styled.AverageBudgetRow>
    </>
  );
}
