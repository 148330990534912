import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Layout } from "react-grid-layout";
import { useMutation, useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";

import message from "@synerise/ds-message";

import { getItemsConfig } from "@/components/LayoutBuilder/GridItems/GridItems.config";
import { ItemLayout, ItemType } from "@/components/LayoutBuilder/LayoutBuilder.types";
import { useApp } from "@/contexts/app/useApp";
import { queryClient } from "@/utils/queryClient";

import { DASHBOARDS_QUERY_KEY } from "../dashboard.config";
import { DashboardService } from "../dashboard.services";
import {
  DashboardCreatePayload,
  DashboardLayout,
  DashboardResponseShort,
  DashboardUpdatePayload,
} from "../dashboard.types";
import { loadAllLayoutsFromSessionStorage } from "../utils/loadAllLayoutsFromSessionStorage";
import { loadLayoutByIdFromSessionStorage } from "../utils/loadLayoutByIdFromSessionStorage";
import { removeLayoutInSessionStorage } from "../utils/removeLayoutInSessionStorage";
import { saveLayoutInSessionStorage } from "../utils/saveLayoutInSessionStorage";
import { useDashboard } from "./useDashboard";

export type UpdateDashboardViewProps = {
  id: number;
  name: string;
  isDefault: boolean;
};

type GridLayoutContextData = {
  currentDashboard: DashboardLayout | null;
  items: ItemLayout<any>[];
  indexItemSelected: string | null;
  draggingElementType: ItemType | null;
  allDashboardViews: DashboardResponseShort[];
  layoutToCreate: ItemLayout<any>[];
  removeItem: (i: string) => void;
  selectItem: (i: string) => void;
  saveLayout: () => void;
  resetLayout: () => void;
  addNewDashboardView: (layoutToCreate: ItemLayout<any>[]) => void;
  updateDashboardView: ({ id, name, isDefault }: UpdateDashboardViewProps) => void;
  loadDashboardView: (id: number) => Promise<void>;
  removeDashboardView: (id: number) => void;
  updateLayoutItem: (data: Record<any, any>, index: string, type: ItemType) => void;
  unselectItem: () => void;
  findItem: (i: string) => ItemLayout<any> | null;
  handleDragStart: (event: React.DragEvent<HTMLDivElement>, elementType: ItemType) => void;
  handleDrop: (item: ReactGridLayout.Layout) => void;
  handleLayoutChange: (layout: ReactGridLayout.Layout[]) => void;
  createDashboardView: (name: string, isDefault: boolean) => Promise<void>;
  editingItem: ItemLayout<any> | null;
  itemHasBeenModified: boolean;
  showCancelEditModal: boolean;
  cancelEdit: () => void;
  confirmCancelEdit: () => void;
  updateEditingItem: (newItem: ItemLayout<any>) => void;
  saveTemporaryEditChanges: () => void;
  closeCancelEditModal: () => void;
  inPreviewMode: boolean;
  toggleInPreviewMode: (inPreview?: boolean) => void;
};

export const GridLayoutContext = createContext({} as GridLayoutContextData);

type GridLayoutProviderProps = {
  children: React.ReactNode;
};

export function GridLayoutProvider({ children }: GridLayoutProviderProps) {
  const { data: dashboards } = useQuery(DASHBOARDS_QUERY_KEY, DashboardService.fetchAll);

  const createDashboardMutation = useMutation(
    DASHBOARDS_QUERY_KEY,
    (payload: DashboardCreatePayload) => DashboardService.create(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DASHBOARDS_QUERY_KEY);
      },
    }
  );

  const updateDashboardMutation = useMutation(
    ({ id, payload }: { id: number; payload: DashboardUpdatePayload }) =>
      DashboardService.update(id, payload)
  );

  const [layoutToCreate, setLayoutToCreate] = useState<ItemLayout<any>[]>([]);

  const editingItemBackup = useRef<ItemLayout<any> | null>(null);
  const [editingItem, setEditingItem] = useState<ItemLayout<any> | null>(null);
  const [itemHasBeenModified, setItemHasBeenModified] = useState(false);
  const [showCancelEditModal, setShowCancelEditModal] = useState(false);
  const [currentDashboard, setCurrentDashboard] = useState<DashboardLayout | null>(null);
  const [inPreviewMode, setInPreviewMode] = useState(false);
  const [items, setItems] = useState<ItemLayout<any>[]>([]);
  const { selectedDashboardId, changeSelectedDashboardId } = useApp();

  useEffect(() => {
    async function fetchSelectedDashboard() {
      if (selectedDashboardId) {
        try {
          const dashboard = await DashboardService.fetchOne(selectedDashboardId);
          setCurrentDashboard(
            DashboardService.mapDashboardResponseToDashboardLayout(dashboard)
          );
          setItems(dashboard.components);
        } catch (e) {
          message.error("Could not load default dashboard");
        } finally {
          return;
        }
      }

      const defaultDashboardID = dashboards?.find((dashboard) => dashboard.is_default)?.id || null;
      if (!defaultDashboardID) return;

      try {
        const fullDefaultDashboard = await DashboardService.fetchOne(defaultDashboardID);
        if (!fullDefaultDashboard) return;
        setCurrentDashboard(
          DashboardService.mapDashboardResponseToDashboardLayout(fullDefaultDashboard)
        );
        setItems(fullDefaultDashboard.components);
        changeSelectedDashboardId(defaultDashboardID);

      } catch (e) {
        message.error("Could not load default dashboard");
      }
    }

    if (dashboards && !currentDashboard) {
      fetchSelectedDashboard();
    }
  }, [dashboards]);

  const [indexItemSelected, setIndexItemSelected] = useState<string | null>(null);
  const [draggingElementType, setDraggingElementType] = useState<ItemType | null>(null);
  const {
    inEditMode,
    toggleEditMode,
    toggleCreateDashboardViewModal,
    toggleDashboardViewsDrawer,
    toggleEditItemDrawer,
  } = useDashboard();

  const itemsConfig = getItemsConfig({ updateItem: updateEditingItem, editingItem });

  useEffect(() => {
    if (!indexItemSelected) {
      setEditingItem(null);
      return;
    }
    const item = findItem(indexItemSelected);
    if (!item) return;
    setEditingItem({ ...item });
    editingItemBackup.current = { ...item };
  }, [indexItemSelected]);

  function updateEditingItem(newItem: ItemLayout<any>, layout?: Partial<Layout>) {
    updateLayoutItem({ ...newItem.data }, newItem.i, newItem.type, layout);
    setEditingItem({ ...newItem });
    setItemHasBeenModified(true);
  }

  function saveTemporaryEditChanges() {
    editingItemBackup.current = editingItem;
    setItemHasBeenModified(false);
  }

  function closeCancelEditModal() {
    saveTemporaryEditChanges();
    setShowCancelEditModal(false);
  }

  function confirmCancelEdit() {
    if (!editingItemBackup.current) return;
    updateLayoutItem(
      { ...editingItemBackup.current.data },
      editingItemBackup.current.i,
      editingItemBackup.current.type
    );
    setEditingItem(editingItemBackup.current);
    setShowCancelEditModal(false);
    setItemHasBeenModified(false);
    message.info("Canceled");
  }

  function cancelEdit() {
    if (JSON.stringify(editingItemBackup.current) === JSON.stringify(editingItem)) return;
    setShowCancelEditModal(true);
  }

  function addItem(item: ItemLayout<any>) {
    const newItems = [...items, item];
    setItems(newItems);
  }

  function removeItem(i: string) {
    const newItems = items.filter((item) => item.i !== i);
    setItems(newItems);
    setIndexItemSelected(null);
  }

  function selectItem(i: string) {
    if (!inEditMode) return;
    if (indexItemSelected !== i && !inPreviewMode) {
      setIndexItemSelected(i);
    }
    toggleEditItemDrawer(true);
  }

  function unselectItem() {
    cancelEdit();
    setIndexItemSelected(null);
  }

  async function createDashboardView(name: string, isDefault: boolean) {
    const newDashboardView: DashboardCreatePayload = {
      components: layoutToCreate,
      name,
      is_default: isDefault,
    };

    const dashboardResponse = await createDashboardMutation.mutateAsync(newDashboardView);
    const dashboardParsed =
      DashboardService.mapDashboardResponseToDashboardLayout(dashboardResponse);
    dashboardParsed["layout"] = layoutToCreate;
    saveLayoutInSessionStorage(dashboardParsed);
    setCurrentDashboard(dashboardParsed);
    updateAllDashboardViews();
    setItems(layoutToCreate);
    message.success(`${name} view created!`);
    toggleDashboardViewsDrawer(false);
    toggleEditMode(false);
  }

  function updateAllDashboardViews() {
    loadAllLayoutsFromSessionStorage();
  }

  function addNewDashboardView(layoutToCreate: ItemLayout<any>[]) {
    setLayoutToCreate(layoutToCreate);
    toggleCreateDashboardViewModal();
  }

  async function loadDashboardView(id: number) {
    try {
      const dashboard = await DashboardService.fetchOne(id);
      if (!dashboard) return;

      const dashboardMapped = DashboardService.mapDashboardResponseToDashboardLayout(dashboard);

      changeSelectedDashboardId(dashboard.id);
      setCurrentDashboard(dashboardMapped);
      setItems(dashboardMapped.layout);
      message.info("Dashboard view loaded");
      toggleDashboardViewsDrawer();
    } catch (e) {
      message.error("Error loading dashboard view");
    }
  }

  function removeDashboardView(id: number) {
    if (selectedDashboardId === id) {
      changeSelectedDashboardId(null);
      setCurrentDashboard(null);
      setItems([]);
    }
    removeLayoutInSessionStorage(id);
    updateAllDashboardViews();
    message.info("Dashboard view removed");
  }

  function updateDashboardView({ id, name, isDefault }: UpdateDashboardViewProps) {
    const dashboard = loadLayoutByIdFromSessionStorage(id);
    if (!dashboard) return;

    const dashboardUpdated = {
      ...dashboard,
      name,
      isDefault,
    };

    saveLayoutInSessionStorage(dashboardUpdated);
    updateAllDashboardViews();
    message.info("Dashboard view updated");
  }

  async function saveLayout() {
    if (!currentDashboard) {
      setLayoutToCreate(items);
      toggleCreateDashboardViewModal();
      return;
    }

    await updateDashboardMutation.mutateAsync({
      id: currentDashboard.id,
      payload: {
        components: items,
      },
    });

    saveLayoutInSessionStorage({
      ...currentDashboard,
      layout: items,
    });

    setCurrentDashboard({
      ...currentDashboard,
      layout: items,
    });

    message.success("Dashboard layout saved!");
    updateAllDashboardViews();
    toggleEditMode();
  }

  function resetLayout() {
    unselectItem();
    toggleEditMode();
    message.info("Layout reseted");
    if (!currentDashboard) return;
    setItems(currentDashboard.layout);
  }

  function updateLayoutItem(
    data: Record<string, any>,
    index: string,
    type: ItemType,
    layout?: Partial<Layout>
  ) {
    const newItems = items.map((item) => {
      if (item.i === index) {
        let newItem = {
          ...item,
          type,
          data,
        };

        if (layout) {
          newItem = {
            ...newItem,
            ...layout,
          };
        }

        return newItem;
      }
      return item;
    });
    setItems(newItems);
  }

  function findItem(i: string) {
    return items.find((item) => item.i === i) || ({} as ItemLayout<any>);
  }

  function handleDragStart(event: React.DragEvent<HTMLDivElement>, elementType: ItemType) {
    event.dataTransfer.setData("text/plain", ""); // For Firefox
    setDraggingElementType(elementType);
  }

  function handleDrop(item: ReactGridLayout.Layout) {
    if (!draggingElementType) return;

    const itemFromConfig = itemsConfig[draggingElementType];
    if (!itemFromConfig) return;

    const initialItemLayout = itemFromConfig.initialLayout;

    if (!initialItemLayout) return;

    const newItem = {
      ...initialItemLayout,
      i: uuidv4(),
      x: item.x,
      y: item.y,
    };

    addItem(newItem);
  }

  function handleLayoutChange(layout: ReactGridLayout.Layout[]) {
    const newLayout = [...layout];
    const itemsFilteredByState = newLayout.filter((item) => !!findItem(item.i).i);

    const itemsMapped = itemsFilteredByState.map((item) => {
      const itemMatched = findItem(item.i);

      return {
        ...item,
        data: itemMatched.data,
        type: itemMatched.type,
      };
    });

    setItems(itemsMapped);
  }

  useEffect(() => {
    if (!inEditMode) {
      unselectItem();
    }
  }, [inEditMode]);

  useEffect(() => {
    if (!indexItemSelected) {
      const newItems = items.map((item) => ({
        ...item,
        resizeHandles: [],
      }));
      setItems(newItems);
      return;
    }

    const item = findItem(indexItemSelected);
    if (!item) return;

    const itemConfigMatch = itemsConfig[item.type];
    if (!itemConfigMatch) return;

    const newItems = items.map((item) => {
      if (item.i === indexItemSelected) {
        return {
          ...item,
          resizeHandles: itemConfigMatch.resizeHandles,
        };
      }
      return {
        ...item,
        resizeHandles: [],
      };
    });

    setItems(newItems);
  }, [indexItemSelected]);

  function toggleInPreviewMode(inPreview?: boolean) {
    if (inPreview === undefined) {
      setInPreviewMode((prevState) => {
        if (!prevState) {
          setIndexItemSelected(null);
        }
        return !prevState;
      });
      return;
    }
    if (!inPreview) {
      setIndexItemSelected(null);
    }
    setInPreviewMode(inPreview);
  }

  useEffect(() => {
    for (let t = 0; t <= 300; t += 10) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, t);
    }
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [inPreviewMode, indexItemSelected]);

  return (
    <GridLayoutContext.Provider
      value={{
        currentDashboard,
        items,
        indexItemSelected,
        draggingElementType,
        allDashboardViews: dashboards || [],
        layoutToCreate,
        removeItem,
        selectItem,
        saveLayout,
        resetLayout,
        addNewDashboardView,
        updateDashboardView,
        loadDashboardView,
        removeDashboardView,
        updateLayoutItem,
        unselectItem,
        findItem,
        handleDragStart,
        handleDrop,
        handleLayoutChange,
        createDashboardView,
        editingItem,
        itemHasBeenModified,
        showCancelEditModal,
        cancelEdit,
        confirmCancelEdit,
        updateEditingItem,
        saveTemporaryEditChanges,
        closeCancelEditModal,
        inPreviewMode,
        toggleInPreviewMode,
      }}
    >
      {children}
    </GridLayoutContext.Provider>
  );
}

export const useGridLayout = () => {
  const context = useContext(GridLayoutContext);

  if (context === undefined) {
    throw new Error("useGridLayout must be used within a GridLayoutProvider");
  }

  return context;
};
