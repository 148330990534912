import { useState } from "react";

import Button from "@synerise/ds-button";
import Icon, { ChatM } from "@synerise/ds-icon";
import { TextArea } from "@synerise/ds-input";

import { useCreatePostComment } from "@/modules/governance/hooks/useCreatePostComment";
import { getCharactersCount } from "@/modules/governance/utils/getCharactersCount";

import * as Styled from "./styles";

const MAX_COMMENT_LENGTH = 300;

type PostCommentCreateProps = {
  boardId: number;
  postId: number;
};

export function PostCommentCreate({ boardId, postId }: PostCommentCreateProps) {
  const [commentContent, setCommentContent] = useState("");
  const { createPostComment } = useCreatePostComment({ boardId, postId });

  const handleCreateComment = async () => {
    await createPostComment({
      comment: {
        content: commentContent,
      },
    });
    setCommentContent("");
  };

  return (
    <Styled.Container>
      <div className="header">
        <div className="label">
          <Icon component={<ChatM />} size={22} color="#A7A7A7" />
          <span>Comment</span>
        </div>
        <span>
          {getCharactersCount(commentContent)}/{MAX_COMMENT_LENGTH}
        </span>
      </div>
      <TextArea
        maxLength={MAX_COMMENT_LENGTH}
        value={commentContent}
        onChange={(event: any) => setCommentContent(event.target.value)}
      />
      <div className="footer">
        <Button type="primary" disabled={!commentContent} onClick={() => handleCreateComment()}>
          Comment
        </Button>
      </div>
    </Styled.Container>
  );
}
