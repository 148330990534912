import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
  color: black;
  margin-bottom: 0.5rem;
`;
