import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, useExpanded, useFilters, useRowSelect, useTable } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import PageHeader from "@synerise/ds-page-header";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";
import Tabs from "@synerise/ds-tabs";

import { DateRangePicker } from "@/components/DateRangePicker/DateRangePicker";
import { BodyContainer } from "@/components/Table/EmptyTable";
import * as TableStyled from "@/components/Table/styles";

import { useKpiGroupsApi } from "../hooks/useKpiGroupsApi/useKpiGroupsApi";
import { useKpisApi } from "../hooks/useKpisApi/useKpisApi";
import { KpiGroupResponse } from "../kpi-group.types";
import { DataSource } from "../kpi.types";
import { KPICommentsDrawer } from "../table/components/KPICommentsDrawer";
import { periodicityOptions, valueTypesOptions } from "../table/kpi-table.config";
import * as KPITableStyled from "../table/kpi-table.styles";
import { kpiPeriodicityToRangeDatePickerHelper } from "../utils/kpiPeriodicityToRangeDatePickerHelper";
import { kpiTabsColumns } from "./kpi-tabs.columns";
import * as KPITabsStyled from "./kpi-tabs.styles";
import { KPITabsTableData } from "./kpi-tabs.types";
import { KPIOverviewModal } from "./KPIOverviewModal";
import { useKpisTabTable } from "./useKpiTabsTable";
import { addDataVariationColumnsToTableData, mapKPISToTableData } from "./utils/mapKpiListToTable";

export function KPITabsTable() {
  const { periodicitySelected, changeKpiSelected, setPeriodicitySelected } = useKpisTabTable();
  const navigate = useNavigate();
  const [activeGroupTabIndex, setActiveGroupTabIndex] = useState(0);
  const [kpiOverviewModalVisible, setKpiOverviewModalVisible] = useState(false);
  const { kpiGroups } = useKpiGroupsApi();
  const groupSelected: KpiGroupResponse | undefined = kpiGroups[activeGroupTabIndex];
  const [valueTypeFilter, setValueTypeFilter] = useState<DataSource[]>(["actual"]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showBudgetVariationColumn, setShowBudgetVariationColumn] = useState(false);
  const [isKpiValueCommentDrawerOpen, setIsKpiValueCommentDrawerOpen] = useState(false);
  const [kpiIdCommentSelected, setKpiIdCommentSelected] = useState<number | null>(null);
  const [valueIdCommentSelected, setValueIdCommentSelected] = useState<number | null>(null);
  const { kpis, isLoading: isKpisLoading } = useKpisApi({
    fetchParams: {
      group_id: groupSelected ? groupSelected.id : undefined,
      show_values: true,
      data_sources: valueTypeFilter,
      periodicity: periodicitySelected,
      start_date: startDate?.toISOString() || undefined,
      end_date: endDate?.toISOString() || undefined,
    },
    enabled: kpiGroups.length > 0 && !!groupSelected,
  });

  function handleHideBudgetVariationColumns(hide: boolean) {
    allColumns.forEach((column: any) => {
      if (column.isBudgetVariationColumn) {
        toggleHideColumn(column.id, hide);
      }
    });
  }

  async function openKpiValueCommentDrawer(kpiId: number, valueId: number) {
    setIsKpiValueCommentDrawerOpen(true);
    setKpiIdCommentSelected(kpiId);
    setValueIdCommentSelected(valueId);
  }

  function closeKpiValueCommentDrawer() {
    setIsKpiValueCommentDrawerOpen(false);
    setKpiIdCommentSelected(null);
    setValueIdCommentSelected(null);
  }

  function handleRangeDateFilter(newStartDate: Date | null, newEndDate: Date | null) {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }

  function closeKPIOverviewModal() {
    setKpiOverviewModalVisible(false);
  }

  function handleKPIClick(kpi: KPITabsTableData) {
    changeKpiSelected(kpi);
    setKpiOverviewModalVisible(true);
  }

  const tableData = useMemo(() => {
    const mappedKpis = mapKPISToTableData(kpis, valueTypeFilter);
    return addDataVariationColumnsToTableData(mappedKpis);
  }, [kpis, valueTypeFilter]);

  const columns = useMemo(() => {
    const kpiWithValues = tableData.find((kpi) => kpi.values && kpi.values.length > 1);
    const values = kpiWithValues ? (kpiWithValues.values ? kpiWithValues.values : []) : [];
    return kpiTabsColumns(
      values,
      periodicitySelected,
      valueTypeFilter,
      openKpiValueCommentDrawer,
      valueIdCommentSelected
    ) as Column<KPITabsTableData>[];
  }, [tableData, valueIdCommentSelected]);

  useEffect(() => {
    handleHideBudgetVariationColumns(!showBudgetVariationColumn);
  }, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    toggleHideColumn,
    toggleAllRowsExpanded,
  } = useTable<KPITabsTableData>(
    {
      columns,
      data: tableData,
      defaultCanGroupBy: false,
      autoResetExpanded: false,
    },
    useFilters,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [tableData]);

  return (
    <Layout
      header={
        <PageHeader
          title="KPIs"
          onGoBack={() => navigate("/kpis/dashboard")}
          rightSide={
            <>
              <Button type="secondary" onClick={() => navigate("/kpis/library")}>
                Go To Library
              </Button>
              <Button type="primary" onClick={() => navigate("/kpis/library/create")}>
                Create New KPI
              </Button>
            </>
          }
        />
      }
    >
      <Card
        withHeader
        title={
          <Switch
            label="vs. Budget"
            checked={showBudgetVariationColumn}
            onChange={(checked) => {
              setShowBudgetVariationColumn(checked);
              handleHideBudgetVariationColumns(!checked);
            }}
          />
        }
        style={{ overflow: "auto" }}
        headerSideChildren={
          <KPITableStyled.TableOptionsContainer>
            <div>
              <DateRangePicker
                onRangeDateChange={handleRangeDateFilter}
                endDate={endDate}
                startDate={startDate}
                periodicity={kpiPeriodicityToRangeDatePickerHelper[periodicitySelected]}
              />
              <Select
                placeholder="Value Type"
                allowClear={false}
                mode="multiple"
                value={valueTypeFilter}
                onChange={(value: any) => {
                  if (value.length > 0) {
                    setValueTypeFilter(value);
                  }
                }}
                style={{ minWidth: "150px" }}
              >
                {valueTypesOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>

              <Select
                value={periodicitySelected}
                onChange={(value: any) => setPeriodicitySelected(value)}
                style={{ minWidth: "150px" }}
              >
                {periodicityOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </KPITableStyled.TableOptionsContainer>
        }
      >
        <Tabs
          tabs={kpiGroups.map((group) => ({
            label: group.title,
          }))}
          activeTab={activeGroupTabIndex}
          handleTabClick={(index) => setActiveGroupTabIndex(index)}
        />

        <TableStyled.Table {...getTableProps()} style={{ marginTop: "1rem" }}>
          <TableStyled.TableHead>
            {headerGroups.map((headerGroup) => (
              <TableStyled.TableHeaderRow>
                {headerGroup.headers.map((column: any) => (
                  <TableStyled.TableHeader
                    {...column.getHeaderProps()}
                    style={{ width: column.width }}
                  >
                    <TableStyled.TableCellWithFiltersWrapper>
                      {column.render("Header")}
                    </TableStyled.TableCellWithFiltersWrapper>
                  </TableStyled.TableHeader>
                ))}
              </TableStyled.TableHeaderRow>
            ))}
          </TableStyled.TableHead>

          <TableStyled.TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableStyled.TableBodyRow {...row.getRowProps()} isSelected={row.isSelected}>
                  {row.cells.map((cell: any) => {
                    return (
                      <KPITabsStyled.TableCell
                        {...cell.getCellProps()}
                        darker={
                          cell.column.isBudgetVariationColumn &&
                          !cell.isPlaceholder &&
                          !cell.isAggregated
                        }
                      >
                        {cell.isPlaceholder ? (
                          <KPITableStyled.PlaceHolderCell>
                            {row.original.title}
                          </KPITableStyled.PlaceHolderCell>
                        ) : cell.column.id === "title" ? (
                          <KPITableStyled.PlaceHolderCell
                            onClick={() => handleKPIClick(row.original)}
                          >
                            {cell.render("Cell")}
                          </KPITableStyled.PlaceHolderCell>
                        ) : (
                          cell.render("Cell")
                        )}
                      </KPITabsStyled.TableCell>
                    );
                  })}
                </TableStyled.TableBodyRow>
              );
            })}
          </TableStyled.TableBody>
        </TableStyled.Table>
      </Card>
      {kpiIdCommentSelected && valueIdCommentSelected && (
        <KPICommentsDrawer
          isVisible={isKpiValueCommentDrawerOpen}
          onClose={closeKpiValueCommentDrawer}
          kpiId={kpiIdCommentSelected}
          valueId={valueIdCommentSelected}
        />
      )}
      {isKpisLoading && (
        <BodyContainer>
          <Loader label="Loading kpis. Just a second" labelPosition="bottom" />
        </BodyContainer>
      )}
      <KPIOverviewModal visible={kpiOverviewModalVisible} onCancel={closeKPIOverviewModal} />
    </Layout>
  );
}
