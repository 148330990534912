export function donutChartOptions(labels: string[]): ApexCharts.ApexOptions {
  return {
    labels,
    chart: {
      width: 380,
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    legend: {
      position: "right",
      offsetY: 0,
      height: 230,
    },
  };
}
