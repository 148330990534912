import { Form, Field } from "react-final-form";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";

import { Input } from "@synerise/ds-input";
import Modal from "@synerise/ds-modal";
import Select from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";
import { queryClient } from "@/utils/queryClient";

import { ReportsTagService } from "../reports-tags.services";
import { REPORTS_QUERY_KEY, REPORTS_TAGS_QUERY_KEY } from "../reports.config";
import { ReportService } from "../reports.services";
import { ReportResponseShort, ReportUpdatePayload } from "../reports.types";
import { ReportEditModalFooter } from "./ReportEditModalFooter";

type UpdateReportFormValues = {
  name: string;
  description?: string;
  tags: number[];
  is_private: boolean;
};

type ReportEditModalProps = {
  isOpen: boolean;
  editingReport: ReportResponseShort;
  onToggle: () => void;
};

type UpdateReportProps = {
  id: number;
  payload: ReportUpdatePayload;
};

const updateReportSchema = Yup.object().shape({
  name: Yup.string().required("Name required"),
  description: Yup.string(),
});

const formId = "report-edit-form";

export function ReportEditModal({ isOpen, editingReport, onToggle }: ReportEditModalProps) {
  const validate = useValidationSchema(updateReportSchema);
  const { data: allTags, isLoading: isTagsLoading } = useQuery(
    [REPORTS_TAGS_QUERY_KEY],
    ReportsTagService.fetchAll
  );


  const removeTagMutation = useMutation(
    [REPORTS_TAGS_QUERY_KEY],
    async ({ reportId, tagId }: { reportId: number, tagId: number }) =>
      await ReportService.deleteReportTag(reportId, tagId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REPORTS_QUERY_KEY);
        queryClient.invalidateQueries(REPORTS_TAGS_QUERY_KEY);
      },
    }
    );
    
    const updateReportMutation = useMutation(
      [REPORTS_QUERY_KEY],
      async ({ id, payload }: UpdateReportProps) => ReportService.update(id, payload),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([REPORTS_QUERY_KEY]);
          queryClient.invalidateQueries(REPORTS_TAGS_QUERY_KEY);
      },
    }
  );

  const handleCancel = () => {
    onToggle();
  };

  const onSubmit = async ({ name, description, is_private, tags }: UpdateReportFormValues) => {
    await updateReportMutation.mutateAsync({
      id: editingReport.id,
      payload: {
        name,
        description,
        is_private,
        tags,
      },
    });
    if(tags.length < 1 && editingReport.tags.length > 0) {
      editingReport.tags.forEach(async (tag) => {
        await removeTagMutation.mutateAsync({
          reportId: editingReport.id,
          tagId: tag.id
        });
      })
    }

    onToggle();
  };



  const initialValues = {
    name: editingReport.name,
    description: editingReport.description,
    tags: editingReport.tags.map((tag) => tag.id),
    is_private: editingReport.is_private,
  };

  return (
    <Form<UpdateReportFormValues>
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={formId}>
          <Modal
            title="Edit Report"
            destroyOnClose={true}
            visible={isOpen}
            onCancel={handleCancel}
            okText="Save"
            footer={<ReportEditModalFooter onCancel={handleCancel} formId={formId} />}
          >
            <Field
              name="name"
              render={({ input, meta }) => (
                <Input {...input} label="Name" errorText={meta.touched && meta.error} />
              )}
            />
            <Field
              name="description"
              render={({ input, meta }) => (
                <Input {...input} label="Description" errorText={meta.touched && meta.error} />
              )}
            />

            <Field
              name="tags"
              disabled={true}
              render={({ input, meta }) => (
                <Select
                  {...input}
                  label="Tags"
                  errorText={meta.touched && meta.error}
                  mode="tags"
                  loading={isTagsLoading}
                  disabled={isTagsLoading}
                >
                  {allTags?.map((tag) => (
                    <Select.Option key={tag.id} value={tag.id}>
                      {tag.title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />

            <ModuleStyled.SwitchWrapper>
              <Field
                name="is_private"
                render={({ input }) => (
                  <Switch {...input} label="Private Report" checked={input.value} />
                )}
              />
            </ModuleStyled.SwitchWrapper>
          </Modal>
        </form>
      )}
    />
  );
}
