import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Column, useRowSelect, useSortBy, useTable, useFilters } from "react-table";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Icon, { FilterM } from "@synerise/ds-icon";
import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { addCheckboxToTable } from "@/components/Table/addCheckboxToTable";
import { EmptyTable } from "@/components/Table/EmptyTable";
import { SortIcon } from "@/components/Table/SortIcon";
import * as Styled from "@/components/Table/styles";
import { mapEntitiesToTableData, useEntities } from "@/hooks/useEntities";
import { useSupportInfos } from "@/hooks/useSupportInfos";

import { dataManagementData } from "../data-management.data";
import { leftLayoutContent } from "../DataManagementLeftLayoutContent";
import { EntitiesTableData, tableColumns } from "./entities.columns";
import { EntitiesPageHeaderActions } from "./EntitiesPageHeaderActions";

type Params = {
  type: string;
};

// TODO: Tem alguma outra solução de UI para filtro de tabela?
// Queria dar uma olhada nas opções, não achei que essa funcionou bem (apesar de ser conveniente)
// Talvez aquela da Synerise que tem o ícone de filtro no canto superior direito da tabela
// https://design.synerise.com/storybook-static/?path=/story/components-table-empty-table--default
// Esse componente tem um exemplo legal de comportamento, com seleção de coluna, filtro e busca

// TODO: Nova solução de filtro.
// Outra referência de Filtro perfeita: um ícone pequeno, que quando o filtro é selecionado fica um descritivo
// Claro para o usuário, UI limpa e fácil de exlcuir. Funciona bem para filtros complexos
// por que dá a opção de deixar a descrição do filtro
// https://design.synerise.com/storybook-static/?path=/story/components-filter-completedwithin--default

// TODO: Avaliar também a flexibilidade ao manipular a tabela.
// Synerise tem um "Column Manager" que é excelente:
// você consegue mudar a ordem das colunas, adicionar colunas, remover colunas, fixar colunas e etc.
// Isso nem é tão relevante neste caso, mas é fundamental para os KPIs e Financials, vale olhar.
// Ainda a possibilidade de salvar views é animal.
// Provavelmente isso vai precisar de mudança no back, então vai deixando comentário sobre o que
// vamos precisar fazer na API para acomodar.
// A usabilidade ao lidar com dados complexos é muito importante.

// FIXME: Os dados de companies/groups/collections estão separados.
// Isso funcoinaria se a gente fizesse 3 requests diferentes para a API, mas não faz muito sentido.
// O ideal é que todos os dados vissem juntos.
// Aí fica a dúvida se vale a pena ter pastas específicas para Company/Group/Collection ou se
// a gente só monta um filtro por exemplo em cima de Entity para esconder/mostrar campos específicos
// O mesmo vai acontecer na hora de fazer os profiles.
function mapParamTypeToFilterValue(type?: string) {
  switch (type) {
    case "companies":
      return "Company";
    case "groups":
      return "Group";
    case "collections":
      return "Collection";
    default:
      return "";
  }
}

export function Entities() {
  const navigate = useNavigate();
  const { countries } = useSupportInfos();
  const { allEntities, allEntitiesLoading, removeEntity } = useEntities();
  const { type } = useParams<Params>();

  const tableData = useMemo(() => {
    return mapEntitiesToTableData(allEntities, countries);
  }, [allEntities, countries]);

  async function handleRemoveEntity(id: number) {
    function onSuccess() {
      message.success("Dimension has been removed");
    }

    function onError(text: string) {
      message.error(text);
    }

    await removeEntity({ id, onSuccess, onError });
  }

  function handleEditEntity(id: number) {
    navigate(`/data/entities/edit/${id.toString()}`);
  }

  const columns = useMemo(() => {
    return tableColumns({
      onRemove: handleRemoveEntity,
      onEdit: handleEditEntity,
    }) as Column<EntitiesTableData>[];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setFilter } = useTable(
    {
      columns,
      data: tableData,
      defaultCanSort: false,
      defaultCanFilter: false,
    },
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      addCheckboxToTable(hooks);
    }
  );

  useEffect(() => {
    setFilter("type", mapParamTypeToFilterValue(type));
  }, [type, setFilter]);

  return (
    <Layout
      left={leftLayoutContent}
      header={
        <PageHeader
          onGoBack={() => navigate(dataManagementData.home.path)}
          title="Entities"
          rightSide={<EntitiesPageHeaderActions />}
        />
      }
    >
      <Card
        title="Entities"
        withHeader
        headerSideChildren={
          <Button mode="single-icon" type="ghost-white">
            <Icon color="#C4C4C4" component={<FilterM />} />
          </Button>
        }
      >
        {allEntitiesLoading ? (
          <Loader label="Loading entities. Just a second" labelPosition="bottom" />
        ) : rows.length < 1 ? (
          <EmptyTable
            path={dataManagementData.entities.createPath}
            text="No entities found"
            buttonText="Create a new entity"
          />
        ) : (
          <Styled.Table {...getTableProps()}>
            <Styled.TableHead>
              {headerGroups.map((headerGroup) => (
                <Styled.TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <Styled.TableHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <Styled.TableCellWithFiltersWrapper>
                        {column.render("Header")}
                        {column.canFilter ? column.render("Filter") : null}
                        {column.canSort && (
                          <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                        )}
                      </Styled.TableCellWithFiltersWrapper>
                    </Styled.TableHeader>
                  ))}
                </Styled.TableHeaderRow>
              ))}
            </Styled.TableHead>
            <Styled.TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Styled.TableBodyRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Styled.TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Styled.TableCell>
                      );
                    })}
                  </Styled.TableBodyRow>
                );
              })}
            </Styled.TableBody>
          </Styled.Table>
        )}
      </Card>
    </Layout>
  );
}
