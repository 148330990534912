import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { formatCurrency } from "@/utils/formatCurrency";
import { queryClient } from "@/utils/queryClient";

import { LedgerDetailsTableData } from "./LedgerDetails/ledger-details.types";
import { LEDGERS_QUERY_KEY } from "./ledgers.config";
import { TrialBalanceLineResponse } from "./trialbalance-version-line.types";
import { TrialBalanceServices } from "./trialbalance.services";
import { TrialBalanceResponse, TrialBalanceUpdatePayload } from "./trialbalance.types";

// TODO: consitencyFilter should be a backend responsability
// TODO: flaggedFilter should be a backend responsability
export function mapTrialBalanceDetailsToTable(
  lines: TrialBalanceLineResponse[],
  consistencyFilter: "all" | "true" | "false",
  flaggedFilter: "all" | "true" | "false"
): LedgerDetailsTableData[] {
  const linesFiltered = lines.filter((line) => {
    if (consistencyFilter === "all" && flaggedFilter === "all") return true;
    if (flaggedFilter === "all") return line.is_consistent === (consistencyFilter === "true");
    if (consistencyFilter === "all") return !!line.is_flagged === (flaggedFilter === "true");
    return (
      line.is_consistent === (consistencyFilter === "true") &&
      line.is_flagged === (flaggedFilter === "true")
    );
  });

  return linesFiltered.map((line) => {
    return {
      id: line.id,
      code: line.account?.ref || "--",
      classification: line.account?.code || "--",
      account: line.account?.name || "--",
      initial_balance: line.initial_value ? formatCurrency(line.initial_value) : "--",
      debit: line.debit ? formatCurrency(line.debit) : "--",
      credit: line.credit ? formatCurrency(line.credit) : "--",
      final_balance: line.final_value ? formatCurrency(line.final_value) : "--",
      consistency: line.is_consistent !== undefined ? line.is_consistent : false,
      inconsistency_value: line.inconsistency_value,
      isFlagged: line.is_flagged ?? false,
    };
  });
}

async function showLedger(trialBalanceId: number) {
  return await TrialBalanceServices.fetchOne(trialBalanceId, {});
}

type UpdateLedgerProps = {
  payload: TrialBalanceUpdatePayload;
  onSuccess: () => void;
  onError: (text: string) => void;
};

type UseLedgersReturn = {
  trialBalance: TrialBalanceResponse | undefined;
  isTrialBalanceLoading: boolean;
  ledgerLoading: boolean;
  updateLedger: ({ payload, onSuccess, onError }: UpdateLedgerProps) => Promise<void>;
};

export function useLedger(id: number): UseLedgersReturn {
  const ledgerFromQuery = useQuery([LEDGERS_QUERY_KEY, id], async () => await showLedger(id));

  const updateLedgerMutation = useMutation(
    async ({
      payload,
    }: {
      id: number;
      payload: TrialBalanceUpdatePayload;
      onSuccess: () => void;
      onError: (text: string) => void;
    }) => {
      await TrialBalanceServices.update(id, payload);
    },
    {
      onSuccess: (_, { onSuccess }) => {
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.detail) {
          onError(e.response.data.detail);
        } else {
          onError("Something went wrong");
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(LEDGERS_QUERY_KEY);
        queryClient.refetchQueries(LEDGERS_QUERY_KEY);
      },
    }
  );

  async function updateLedger({ payload, onSuccess, onError }: UpdateLedgerProps) {
    await updateLedgerMutation.mutateAsync({
      id,
      payload,
      onSuccess,
      onError,
    });
  }

  const ledgerLoading = useMemo(() => {
    return ledgerFromQuery.isLoading;
  }, [ledgerFromQuery.isLoading]);

  const isTrialBalanceLoading = useMemo(() => {
    return ledgerFromQuery.isLoading;
  }, [ledgerFromQuery.isLoading]);

  return {
    trialBalance: ledgerFromQuery.data,
    isTrialBalanceLoading,
    ledgerLoading,
    updateLedger,
  };
}
