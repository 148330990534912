import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";

import { queryClient } from "@/utils/queryClient";

import { KpiGroupService } from "../../kpi-group.services";
import { KpiGroupCreatePayload, KpiGroupResponse } from "../../kpi-group.types";
import { KPIS_QUERY_KEY, KPI_GROUPS_QUERY_KEY } from "./kpi.config";

type CreateKpiGroupProps = {
  payload: KpiGroupCreatePayload;
  onSuccess: () => void;
  onError: (message: string) => void;
};

type RemoveKpiGroupProps = {
  id: number;
  onSuccess: () => void;
  onError: (message: string) => void;
};

type UseKpiGroupsReturn = {
  groups: KpiGroupResponse[];
  groupsLoading: boolean;
  createKpiGroup: (props: CreateKpiGroupProps) => Promise<void>;
  createKpiLoading: boolean;
  removeKpiGroup: (props: RemoveKpiGroupProps) => Promise<void>;
  isRemoveKpiGroupLoading: boolean;
};

export function useKpiGroups(): UseKpiGroupsReturn {
  const { data, isLoading } = useQuery(KPI_GROUPS_QUERY_KEY, () => KpiGroupService.fetchAll());

  const createKpiGroupMutation = useMutation(
    KPI_GROUPS_QUERY_KEY,
    async ({ payload }: CreateKpiGroupProps) => KpiGroupService.create(payload),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(KPI_GROUPS_QUERY_KEY);
        queryClient.invalidateQueries(KPIS_QUERY_KEY);
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        if (err instanceof Error) {
          onError(err.message);
          return;
        }
        onError("Failed to create KPI Group");
      },
    }
  );

  const removeKpiGroupMutation = useMutation(
    KPI_GROUPS_QUERY_KEY,
    async ({ id }: RemoveKpiGroupProps) => KpiGroupService.delete(id),
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(KPI_GROUPS_QUERY_KEY);
        queryClient.invalidateQueries(KPIS_QUERY_KEY);
        onSuccess();
      },
      onError: (err: any, { onError }) => {
        if (err instanceof Error) {
          onError(err.message);
          return;
        }
        onError("Failed to create KPI Group");
      },
    }
  );

  const createKpiGroup = useCallback(async (props: CreateKpiGroupProps) => {
    await createKpiGroupMutation.mutateAsync(props);
  }, []);

  const removeKpiGroup = useCallback(async (props: RemoveKpiGroupProps) => {
    await removeKpiGroupMutation.mutateAsync(props);
  }, []);

  return {
    groups: data ?? [],
    groupsLoading: isLoading,
    createKpiGroup,
    createKpiLoading: createKpiGroupMutation.isLoading,
    removeKpiGroup,
    isRemoveKpiGroupLoading: removeKpiGroupMutation.isLoading,
  };
}
