import { KpiValueResponse } from "../../kpi-value.types";

export function calculateLastMonthFromKPIValues(kpiValues?: KpiValueResponse[]) {
  if (!kpiValues || kpiValues.length === 0) {
    return new Date().getMonth() + 1;
  }

  const lastValue = kpiValues[kpiValues.length - 1];
  if (!lastValue.date) {
    return new Date().getMonth() + 1;
  }
  return new Date(lastValue.date).getMonth() + 1;
}
