import { useNavigate, useParams } from "react-router-dom";

import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

import { useGetPostsByBoard } from "../hooks/useGetPostsByBoard";
import { leftDrawerConfig } from "../LeftDrawerConfig";
import { NewPost } from "./components/NewPost";
import { Post } from "./components/Post";
import { PostSkeleton } from "./components/PostSkeleton";
import { rightDrawerConfig } from "./components/RightDrawer/RightDrawerConfig";
import * as Styled from "./styles";

type BoardParams = {
  id: string;
};

export function Board() {
  const navigate = useNavigate();
  const { id } = useParams<BoardParams>();
  const boardId = Number(id);
  const { posts, isPostsLoading } = useGetPostsByBoard({ boardId });

  return (
    <Layout
      left={leftDrawerConfig()}
      right={rightDrawerConfig()}
      header={
        <PageHeader onGoBack={() => navigate("/governance/boards")} title="Board of Directors" />
      }
    >
      <Styled.Container>
        <NewPost boardId={boardId} />
        {isPostsLoading ? (
          <PostSkeleton />
        ) : (
          posts.map((post) => <Post key={post.id} data={post} />)
        )}
      </Styled.Container>
    </Layout>
  );
}
