import { KpiValueShortResponse } from "../../kpi-value.types";
import { DataSource, KpiResponse } from "../../kpi.types";
import { KPITableData, KPIGroupTableData, KPIValueTableData, ValueType } from "../kpi-table.types";

export function mapKPIValuesToTableData(
  values: KpiValueShortResponse[],
  valueType: ValueType,
  kpiId: number
): KPIValueTableData[] {
  return values.map((value) => ({
    id: value.id ?? Math.floor(Math.random() * 100000),
    value: value.value ?? null,
    type: valueType,
    datetime: value.date ?? "",
    kpi_id: kpiId,
    hasComments: value.has_comments,
  }));
}

function mapKPIToTableData(kpi: KpiResponse, dataSourcesSelected: DataSource[]): KPITableData {
  const group: KPIGroupTableData = kpi.group
    ? {
        id: kpi.group.id,
        description: kpi.group.description ?? "",
        title: kpi.group.title,
      }
    : {
        id: Math.floor(Math.random() * 100000),
        description: "",
        title: "-",
      };

  const selectedKpiSeries =
    kpi.series && kpi.series.length > 0
      ? kpi.series.filter((serie) => dataSourcesSelected.includes(serie.data_source))
      : null;

  const kpiValues = selectedKpiSeries?.reduce((acc, serie) => {
    return [
      ...acc,
      ...(serie.values ? mapKPIValuesToTableData(serie.values, serie.data_source, kpi.id) : []),
    ];
  }, [] as KPIValueTableData[]);

  return {
    id: kpi.id,
    description: kpi.description ?? "",
    importance: kpi.importance ?? 1,
    title: kpi.title,
    periodicity: kpi.periodicity ?? "monthly",
    group,
    values: kpiValues ?? [],
  };
}

export function mapKPISToTableData(
  kpis: KpiResponse[],
  dataSourcesSelected: DataSource[]
): KPITableData[] {
  return kpis.map((kpi) => mapKPIToTableData(kpi, dataSourcesSelected));
}

export function addComparationColumnsToTableData(kpiTableData: KPITableData[]): KPITableData[] {
  const kpiTableDataWithComparationColumns = kpiTableData.map((kpi) => {
    const actualValues = kpi.values.filter((value) => value.type === "actual");
    const budgetValues = kpi.values.filter((value) => value.type === "budget");

    let budgetVariations: KPIValueTableData[] = [];

    actualValues.forEach((actualValue) => {
      const budgetValue = budgetValues.find(
        (budgetValue) => budgetValue.datetime === actualValue.datetime
      );

      budgetVariations.push({
        id: actualValue.id,
        value:
          budgetValue && actualValue.value && budgetValue.value
            ? Number((actualValue.value - budgetValue?.value).toFixed(2))
            : null,
        type: "budget-variation",
        datetime: actualValue.datetime,
        kpi_id: actualValue.kpi_id,
        hasComments: false,
      });
    });

    return {
      ...kpi,
      values: [...kpi.values, ...budgetVariations],
    };
  });

  return kpiTableDataWithComparationColumns;
}
export function addMockKPIValueTypeColumnsToData(
  actualTableData: KPITableData[],
  valueTypes: ValueType[]
): KPITableData[] {
  const newTableData = actualTableData.map((kpi) => {
    const mappedKPIValuesType: KPIValueTableData[] = kpi.values.map((value) => {
      return {
        ...value,
        type: value.type || "actual",
      };
    });

    let newValues: KPIValueTableData[] = [...mappedKPIValuesType];

    mappedKPIValuesType.forEach((value) => {
      valueTypes.forEach((valueType) => {
        if (valueType === "actual") return;

        const newValueColumnByType: KPIValueTableData = {
          id: Math.floor(Math.random() * 100000000),
          value: Math.floor(Math.random() * 100),
          datetime: value.datetime,
          kpi_id: value.kpi_id,
          type: valueType,
          hasComments: value.hasComments,
        };

        newValues.push(newValueColumnByType);
      });
    });

    return {
      ...kpi,
      values: newValues,
    };
  });

  return newTableData;
}
