import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { TrashM, EditM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { TextCell } from "@/components/Table/Cells/HeaderCell";
import { StatusCell } from "@/components/Table/Cells/StatusCell";
import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as Styled from "@/components/Table/styles";

export type KPIGroupsTableData = {
  id: number;
  title: string;
  description: string;
  is_shared: boolean;
};

type TableColumnsProps = {
  onEditKPIGroup: (id: number) => void;
  onRemoveKPIGroup: (id: number) => Promise<void>;
  isRemovingKPIGroup: boolean;
};

export const tableColumns = ({
  onEditKPIGroup,
  onRemoveKPIGroup,
  isRemovingKPIGroup,
}: TableColumnsProps) => {
  const columns: Column<KPIGroupsTableData>[] = [
    {
      Header: "KPI GROUP",
      accessor: "title",
      Cell: ({ row }) => <UserTypeCell type={row.original.title} showAvatar avatarKind="sunset" />,
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
      Cell: ({ row }) => <UserTypeCell type={row.original.description} />,
    },
    {
      Header: "SHARED",
      accessor: "is_shared",
      Cell: ({ value }) => (
        <StatusCell status={value} activeText="Shared" inactiveText="Not Shared" />
      ),
    },
    {
      Header: <TextCell textAlign="center">ACTIONS</TextCell>,
      accessor: "id",
      Cell: ({ row }) => (
        <Styled.RowActionButtons>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => onEditKPIGroup(row.original.id)}
          >
            <Icon color="#C4C4C4" component={<EditM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete this KPI Group?`}
            onConfirm={() => onRemoveKPIGroup(row.original.id)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button mode="single-icon" type="ghost-white" loading={isRemovingKPIGroup}>
              <Icon color="#C4C4C4" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </Styled.RowActionButtons>
      ),
    },
  ];

  return columns;
};
