import { Layout } from "react-grid-layout";

import FileUploader from "@synerise/ds-file-uploader";

import { ItemLayout } from "../../LayoutBuilder.types";
import * as Styled from "./EditItemDrawerImageContent.styles";

type EditItemDrawerImageContentProps = {
  editingItem: ItemLayout<any> | null;
  updateItem: (newItem: ItemLayout<any>, layout?: Partial<Layout>) => void;
};

export function EditItemDrawerImageContent({
  editingItem,
  updateItem,
}: EditItemDrawerImageContentProps) {
  function handleUpdateUrl(url: string) {
    if (!editingItem) return;
    const newItem = {
      ...editingItem,
      data: {
        ...editingItem.data,
        url,
      },
    };

    updateItem(newItem);
  }

  return (
    <>
      <Styled.ImageUploadContainer>
        <FileUploader
          mode="single"
          filesAmount={1}
          files={[]}
          texts={{
            buttonLabel: "Upload new image",
            buttonDescription: "or drag one here",
            size: "Size:",
            fileWeight: "Weight:",
            cancelText: "Cancel",
            okText: "OK",
            removeConfirmTitle: "Remove image",
            percent: 0,
            retryLabel: "Retry",
            buttonLabelLarge: "Upload new image",
          }}
          onUpload={(files: any) => {
            if (files && files.length > 0) {
              var reader = new FileReader();
              reader.readAsDataURL(files[0]);
              reader.onload = function (e: any) {
                handleUpdateUrl(e.target.result);
              };
            }
          }}
        />
      </Styled.ImageUploadContainer>
    </>
  );
}
