import { Link } from "react-router-dom";
import { Column } from "react-table";

import Button from "@synerise/ds-button";
import Icon, { TrashM, DirectionM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as TableStyled from "@/components/Table/styles";

import { dataManagementData } from "../../data-management.data";

export type AccountsMappingTableData = {
  chartMappingId: number;
  baseAccount: {
    id: number;
    name: string;
  };
  mappedAccount: {
    id: number;
    name: string;
  };
};

type CellProps = {
  row: {
    original: AccountsMappingTableData;
  };
};

type TableColumnsProps = {
  onRemoveChartMapping: (chartMappingId: number) => Promise<void>;
  removeAccountMappingLoading: boolean;
  onEditChartMapping: (chartMappingId: number, targetId: number, baseId: number) => void;
};

export function tableColumns({
  onRemoveChartMapping,
  removeAccountMappingLoading,
  onEditChartMapping,
}: TableColumnsProps): Column<AccountsMappingTableData>[] {
  return [
    {
      Header: "Target Chart of Accounts",
      accessor: "mappedAccount",
      Cell: ({ row }) => (
        <Link
          to={`${dataManagementData.accountsMapping.path}/${row.original.chartMappingId}/${row.original.mappedAccount.id}/${row.original.baseAccount.id}`}
        >
          <UserTypeCell type={row.original.mappedAccount.name} />
        </Link>
      ),
    },
    {
      Header: "Source Chart of Accounts",
      accessor: "baseAccount",
      Cell: ({ row }) => <UserTypeCell type={row.original.baseAccount.name} />,
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row }: CellProps) => (
        <TableStyled.RowCellActionButtons>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() =>
              onEditChartMapping(
                row.original.chartMappingId,
                row.original.mappedAccount.id,
                row.original.baseAccount.id
              )
            }
          >
            <Icon color="#C4C4C4" component={<DirectionM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete this mapping?`}
            onConfirm={() => onRemoveChartMapping(row.original.chartMappingId)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button loading={removeAccountMappingLoading} mode="single-icon" type="ghost-white">
              <Icon color="#C4C4C4" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </TableStyled.RowCellActionButtons>
      ),
    },
  ];
}
