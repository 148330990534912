import { useState } from "react";

import Button from "@synerise/ds-button";
import FileUploader from "@synerise/ds-file-uploader";
import { FileWithContent } from "@synerise/ds-file-uploader/dist/FileUploader.types";
import Icon, { FileM, CloseM } from "@synerise/ds-icon";
import message from "@synerise/ds-message";
import Modal from "@synerise/ds-modal";

import { KPIService } from "@/modules/kpis/kpi.services";

import * as Styled from "./styles";

type KPIModalImportProps = {
  isVisible: boolean;
  onClose: () => void;
};

export function KPIModalImport({ isVisible, onClose }: KPIModalImportProps) {
  const [kpiFile, setKpiFile] = useState<FileWithContent | null>(null);
  const [isImportLoading, setIsImportLoading] = useState(false);

  async function handleImport() {
    if (!kpiFile) {
      message.error("Values file is not defined");
      return;
    }

    setIsImportLoading(true);
    try {
      await KPIService.uploadFile(kpiFile);
      message.success("KPI imported");
      onClose();
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setIsImportLoading(false);
    }
  }
  return (
    <Modal
      title="Import a new KPI form file"
      visible={isVisible}
      onCancel={onClose}
      footer={
        <Button
          type="primary"
          onClick={handleImport}
          disabled={!kpiFile || isImportLoading}
          loading={isImportLoading}
        >
          Import
        </Button>
      }
    >
      <Styled.Content>
        {!kpiFile && (
          <div>
            <FileUploader
              mode="single"
              filesAmount={1}
              files={[]}
              texts={{
                buttonLabel: "Import Ledger",
                buttonDescription: "or drag one here",
                size: "Size:",
                fileWeight: "Weight:",
                cancelText: "Cancel",
                okText: "OK",
                removeConfirmTitle: "Remove file",
                percent: 0,
                retryLabel: "Retry",
                buttonLabelLarge: "Import Ledger",
              }}
              onUpload={(files) => {
                if (files && files.length > 0) {
                  setKpiFile(files[0]);
                }
              }}
            />
          </div>
        )}

        {kpiFile && (
          <Styled.ImportedFileContainer>
            <div>
              <Icon component={<FileM />} size={24} color="#888" />
              {kpiFile.name}
            </div>
            <div className="actions">
              <Button
                mode="single-icon"
                type="custom-color-ghost"
                color="red"
                onClick={() => setKpiFile(null)}
              >
                <Icon component={<CloseM />} size={24} color="#888" />
              </Button>
            </div>
          </Styled.ImportedFileContainer>
        )}
      </Styled.Content>
    </Modal>
  );
}
