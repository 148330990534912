import styled from "styled-components";

export const PostContent = styled.div`
  p {
    margin-bottom: 0.3rem;
  }

  & .like-button {
    cursor: pointer;
  }
`;
