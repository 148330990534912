import { Column } from "react-table";

export type TableData = {
  id: string;
  name: string;
  columns: Column[];
  data: any[];
};

const tableMockData1: TableData = {
  id: "1",
  name: "Table Mock 1",
  columns: [
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Value",
      accessor: "value",
    },
  ],
  data: [
    {
      code: "1",
      name: "John",
      value: 111,
    },
    {
      code: "2",
      name: "Sara",
      value: 222,
    },
    {
      code: "3",
      name: "Jane",
      value: 333,
    },
    {
      code: "4",
      name: "Gil",
      value: 444,
    },
    {
      code: "5",
      name: "Lock",
      value: 555,
    },
    {
      code: "6",
      name: "Ward",
      value: 666,
    },
  ],
};
const tableMockData2: TableData = {
  id: "2",
  name: "Table Mock 2",
  columns: [
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Company Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
    },
  ],
  data: [
    {
      code: "1",
      name: "Apple",
      type: "Electronic",
    },
    {
      code: "2",
      name: "Google",
      type: "Electronic",
    },
    {
      code: "3",
      name: "Samsung",
      type: "Electronic",
    },
    {
      code: "4",
      name: "Petrobras",
      type: "Oil",
    },
  ],
};

export const mockOptions: TableData[] = [tableMockData1, tableMockData2];
