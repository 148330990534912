import { useState } from "react";

import Button from "@synerise/ds-button";
import Checkbox from "@synerise/ds-checkbox";
import { TextArea } from "@synerise/ds-input";
import Modal from "@synerise/ds-modal";

type DialogProps = {
  isVisible: boolean;
  isSubmitLoading: boolean;
  setDialogVisible: (isVisible: boolean) => void;
  onSubmit: (reviewNote: string) => void;
};

export function LedgerDetailsReviewOpenDialog({
  isVisible,
  isSubmitLoading,
  setDialogVisible,
  onSubmit,
}: DialogProps) {
  const [reviewNote, setReviewNote] = useState("");

  return (
    <div>
      <Modal
        title="Open Review"
        visible={isVisible}
        onOk={() => setDialogVisible(false)}
        onCancel={() => setDialogVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setDialogVisible(false)}>
            Cancel
          </Button>,
          <Button
            loading={isSubmitLoading}
            disabled={!reviewNote || isSubmitLoading}
            key="submit"
            onClick={() => {
              onSubmit(reviewNote);
              setDialogVisible(false);
              setReviewNote("");
            }}
            type="primary"
          >
            Open Review
          </Button>,
        ]}
      >
        Open a review for this Trial Balance. You can choose to leave a note to this action, and to
        notify your accountant. All flagged entries will be marked and included in the review.
        <div style={{ marginTop: "20px" }}>
          <TextArea
            placeholder="Leave a note to this review"
            value={reviewNote}
            onChange={(e: any) => setReviewNote(e.target.value)}
            type="area"
            counterLimit={100}
            label="Review Note"
            rows={3}
          />
          <Checkbox>Notify accountant</Checkbox>
        </div>
      </Modal>
    </div>
  );
}
