import { Boards } from "./Boards/Boards";
import { Governance } from "./Governance";
import { GovernanceHome } from "./GovernanceHome";
import { Meetings } from "./Meetings/Meetings";

type GovernanceRoutes = {
  main: {
    path: string;
    name: string;
    element: React.ReactNode;
  };
  home: {
    path: string;
    fullPath: string;
    name: string;
    element: React.ReactNode;
  };
  sections: GovernanceRoutesSection[];
};

type GovernanceRoutesSection = {
  sectionName: string;
  links: GovernanceRoutesSectionLink[];
};

type GovernanceRoutesSectionLink = {
  name: string;
  fullPath: string;
  path: string;
  element: React.ReactNode;
};

export const governanceRoutes: GovernanceRoutes = {
  main: {
    path: "/governance",
    name: "Governance",
    element: <Governance />,
  },
  home: {
    fullPath: "/governance/home",
    path: "home",
    name: "Governance",
    element: <GovernanceHome />,
  },
  sections: [
    {
      sectionName: "Main",
      links: [
        {
          name: "Boards",
          fullPath: "/governance/boards",
          path: "boards",
          element: <Boards />,
        },
        {
          name: "Meetings",
          path: "meetings",
          fullPath: "/governance/meetings",
          element: <Meetings />,
        },
      ],
    },
  ],
};
