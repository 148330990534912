import { useCallback } from "react";
import { useMutation } from "react-query";

import message from "@synerise/ds-message";

import { GovernanceServices } from "../governance.services";
import { useRefreshBoards } from "./useRefreshBoards";

export const useRemoveBoard = () => {
  const refreshBoards = useRefreshBoards();

  const removeBoardMutation = useMutation(GovernanceServices.remove, {
    onSuccess: () => {
      message.success("Board removed");
      refreshBoards();
    },
    onError: (error: any) => {
      message.error(error.message);
    },
  });

  const removeBoard = useCallback(async (boardId: number) => {
    removeBoardMutation.mutate(boardId);
  }, []);

  return {
    removeBoard,
    isRemoveBoardLoading: removeBoardMutation.isLoading,
  };
};
