import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 0.3rem;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span``;

export const FileMetadata = styled.span`
  color: #9b9b9b;
`;
