import { DASHBOARD_LAYOUT_KEY } from "../dashboard.config";
import { loadAllLayoutsFromSessionStorage } from "./loadAllLayoutsFromSessionStorage";

export function removeLayoutInSessionStorage(id: number) {
  if (!sessionStorage) return;

  const allLayouts = loadAllLayoutsFromSessionStorage();
  const newLayouts = allLayouts.filter((layout) => layout.id !== id);

  sessionStorage.setItem(DASHBOARD_LAYOUT_KEY, JSON.stringify(newLayouts));
}
