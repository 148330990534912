import { Star } from "./Star";
import * as Styled from "./styles";

type StarRateProps = {
  rate: 1 | 2 | 3 | 4 | 5;
  setRate?: (rate: 1 | 2 | 3 | 4 | 5) => void;
};

export function StarRate({ rate, setRate }: StarRateProps) {
  return (
    <Styled.Container>
      <Star
        isActive={rate >= 1}
        canSelect={!!setRate}
        onClick={!!setRate ? () => setRate(1) : undefined}
      />
      <Star
        isActive={rate >= 2}
        canSelect={!!setRate}
        onClick={!!setRate ? () => setRate(2) : undefined}
      />
      <Star
        isActive={rate >= 3}
        canSelect={!!setRate}
        onClick={!!setRate ? () => setRate(3) : undefined}
      />
      <Star
        isActive={rate >= 4}
        canSelect={!!setRate}
        onClick={!!setRate ? () => setRate(4) : undefined}
      />
      <Star
        isActive={rate >= 5}
        canSelect={!!setRate}
        onClick={!!setRate ? () => setRate(5) : undefined}
      />
    </Styled.Container>
  );
}
