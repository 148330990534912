import * as Yup from "yup";

export const kpiFormEditSchema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  shared: Yup.boolean(),
  group_id: Yup.number().nullable(),
  // units_id: Yup.number().nullable(),
  periodicity: Yup.string(),
  aggregation: Yup.string(),
  is_higher: Yup.boolean(),
  is_entity: Yup.boolean(),
});
