import Button from "@synerise/ds-button";
import Result from "@synerise/ds-result";

function Peers() {
  return (
    <>
      <div style={{ margin: "auto" }}>
        <Result
          buttons={
            <>
              <Button onClick={function noRefCheck() {}} type="primary">
                Let me know
              </Button>
            </>
          }
          description="This module is under construction. Interested on using it? Let us know!"
          title="Peers module under development…"
          type="progress"
        />
      </div>
    </>
  );
}

export default Peers;
