import { Form, Field } from "react-final-form";
import * as Yup from "yup";

import { Input } from "@synerise/ds-input";
import Switch from "@synerise/ds-switch";

import { useAccount } from "@/hooks/useAccounts";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { SelectCurrency } from "../../entities/components/SelectCurrency";
import { AccountUpdatePayload } from "./accounts.types";

type AccountUpdateFormValues = {
  name: string;
  description?: string;
  currency_code?: string;
  is_active?: boolean;
  is_flow?: boolean;
};

type AccountFormUpdateProps = {
  account: {
    id: number;
    name: string;
    description?: string;
    currency_code?: string;
    is_active?: boolean;
    is_flow?: boolean;
  };
  chartId: number;
  formId: string;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
};

const accountUpdateFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().nullable(),
  currency_code: Yup.string().nullable(),
  is_active: Yup.boolean(),
  is_flow: Yup.boolean(),
});

export function AccountFormUpdate({
  account,
  formId,
  chartId,
  onSuccess,
  onError,
}: AccountFormUpdateProps) {
  const validate = useValidationSchema(accountUpdateFormSchema);
  const { updateAccount } = useAccount();
  const { currencies } = useSupportInfos();

  const onSubmit = async (values: AccountUpdateFormValues) => {
    const payload: AccountUpdatePayload = {
      name: values.name,
      description: values.description || "",
      currency_code: values.currency_code,
      is_active: values.is_active,
      is_flow: values.is_flow,
    };

    await updateAccount({
      chartId,
      accountId: account.id,
      payload,
      onSuccess,
      onError,
    });
  };

  const initialValues: AccountUpdateFormValues = {
    name: account.name,
    description: account.description,
    currency_code: account.currency_code,
    is_active: account.is_active,
    is_flow: account.is_flow,
  };

  return (
    <Form<AccountUpdateFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={formId}>
          <Field name="name">
            {({ input, meta }) => (
              <Input {...input} label="Name" errorText={meta.touched && meta.error} />
            )}
          </Field>
          <Field name="description">
            {({ input, meta }) => (
              <Input {...input} label="Description" errorText={meta.touched && meta.error} />
            )}
          </Field>
          <Field
            name="currency_code"
            render={({ input, meta }) => (
              <SelectCurrency
                description="The entity currency"
                currencies={currencies}
                showErrorText={meta.touched && meta.error}
                errorText={meta.error}
                {...input}
              />
            )}
          />
          <ModuleStyled.SwitchWrapper>
            <Field name="is_active">
              {({ input }) => <Switch {...input} checked={input.value} label="Active" />}
            </Field>
            <Field name="is_flow">
              {({ input }) => <Switch {...input} checked={input.value} label="Flow" />}
            </Field>
          </ModuleStyled.SwitchWrapper>
        </form>
      )}
    </Form>
  );
}
