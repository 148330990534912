import { Link } from "react-router-dom";

import AppMenu from "@synerise/ds-app-menu";
import { PredictionsA, PredictionsN } from "@synerise/ds-icon";

export function ForecastMenu() {
  return (
    <AppMenu.Item id="forecast" name="Forecast" className="app-menu-item">
      <Link to="/forecast">
        <AppMenu.Item.Icon active={<PredictionsA />} inActive={<PredictionsN />} />
      </Link>
    </AppMenu.Item>
  );
}
