
import { ChartType } from "@/components/LayoutBuilder/LayoutBuilder.types";

import { SelectChart } from "../../components/SelectChart/SelectChart";

type EditItemDrawerChartContentSettingsTabProps = {
  selectedChartType: ChartType;
  onChangeChartType: (chartType: ChartType) => void;
};

export function EditItemDrawerChartContentSettingsTab({
  selectedChartType,
  onChangeChartType,
}: EditItemDrawerChartContentSettingsTabProps) {
  return (
    <>
      <span style={{ fontWeight: "bold" }}>Settings Chart</span>
      <SelectChart selectedChart={selectedChartType} changeSelectedChart={onChangeChartType} />

    </>
  );
}
