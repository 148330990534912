import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";

import { DimensionTypesTableData } from "@/modules/data-management/dimension-types/dimension-types.columns";
import { DimensionTypesService } from "@/modules/data-management/dimension-types/dimension-types.services";
import {
  DimensionType,
  DimensionTypeCreatePayload,
  DimensionTypeUpdatePayload,
} from "@/modules/data-management/dimension-types/dimension-types.types";
import { queryClient } from "@/utils/queryClient";

export const DIMENSION_TYPES_QUERY_KEY = "dimension-types";

function mapDimensionTypeToTable(dimensionType: DimensionType): DimensionTypesTableData {
  return {
    id: dimensionType.id,
    name: dimensionType.title,
    description: dimensionType.description || "",
  };
}

export type Dimension = {
  dimensionId: number;
  dimensionValueId: number;
  name: string;
  type: string;
};

export function mapDimensionTypesListToTable(
  dimensionTypes: DimensionType[] = []
): DimensionTypesTableData[] {
  return dimensionTypes.map(mapDimensionTypeToTable);
}

async function getAllDimensionTypes(): Promise<DimensionType[]> {
  return await DimensionTypesService.list();
}

export function useDimension(dimensionId: number) {
  const dimensionTypeQuery = useQuery([DIMENSION_TYPES_QUERY_KEY, dimensionId], () =>
    DimensionTypesService.show(dimensionId)
  );

  return {
    dimensionType: dimensionTypeQuery.data || null,
  };
}

export function useDimensionTypes() {
  const allDimensionTypesQuery = useQuery(DIMENSION_TYPES_QUERY_KEY, () => getAllDimensionTypes());

  const createDimensionType = useMutation(
    async ({
      dimensionType,
    }: {
      dimensionType: DimensionTypeCreatePayload;
      onSuccess: () => void;
      onError: (text: string) => void;
    }) => {
      const data = await DimensionTypesService.create(dimensionType);
      return data;
    },
    {
      onSuccess: (_, { onSuccess }) => {
        queryClient.invalidateQueries(DIMENSION_TYPES_QUERY_KEY);
        onSuccess();
      },
      onError: (e: any, { onError }) => {
        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
    }
  );

  const removeDimensionType = useMutation(
    async ({ id }: { id: number; onSuccess: () => void; onError: (text: string) => void }) => {
      await DimensionTypesService.delete(id);
    },
    {
      onMutate: ({ id, onSuccess }) => {
        queryClient.cancelQueries(DIMENSION_TYPES_QUERY_KEY);

        const previousDimensionTypes = queryClient.getQueryData<DimensionType[]>([
          DIMENSION_TYPES_QUERY_KEY,
        ]);

        if (!previousDimensionTypes) {
          return;
        }

        const updatedDimensionTypes = previousDimensionTypes.filter(
          (dimensionType) => dimensionType.id !== id
        );

        queryClient.setQueryData(DIMENSION_TYPES_QUERY_KEY, updatedDimensionTypes);
        onSuccess();

        return {
          previousDimensionTypes,
        };
      },
      onError: (e: any, { onError }, context: any) => {
        queryClient.setQueryData(DIMENSION_TYPES_QUERY_KEY, context.previousDimensionTypes);

        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(DIMENSION_TYPES_QUERY_KEY);
      },
    }
  );

  const updateDimensionType = useMutation(
    async ({
      id,
      payload,
    }: {
      id: number;
      payload: DimensionTypeUpdatePayload;
      onSuccess: () => void;
      onError: (text: string) => void;
    }) => {
      await DimensionTypesService.update(id, payload);
    },
    {
      onMutate: ({ id, payload, onSuccess }) => {
        queryClient.cancelQueries(DIMENSION_TYPES_QUERY_KEY);

        const previousDimensionTypes = queryClient.getQueryData<DimensionType[]>([
          DIMENSION_TYPES_QUERY_KEY,
        ]);

        if (!previousDimensionTypes) {
          return;
        }

        const newDimensionTypes = previousDimensionTypes.map((dimensionType) => {
          if (dimensionType.id === id) {
            return {
              ...dimensionType,
              ...payload,
            };
          }

          return dimensionType;
        });

        queryClient.setQueryData([DIMENSION_TYPES_QUERY_KEY], newDimensionTypes);

        onSuccess();

        return {
          previousDimensionTypes,
        };
      },
      onError: (e: any, { onError }, context: any) => {
        queryClient.setQueryData(DIMENSION_TYPES_QUERY_KEY, context.previousDimensions);

        if (e.response && e.response.data && e.response.data.msg) {
          onError(e.response.data.msg);
        } else {
          onError("Something went wrong");
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(DIMENSION_TYPES_QUERY_KEY);
      },
    }
  );

  const allDimensionValues: Dimension[] = useMemo(() => {
    let dimensionsValues: Dimension[] = [];
    if (allDimensionTypesQuery.data) {
      allDimensionTypesQuery?.data.forEach((dimensionType) => {
        dimensionType.values.forEach((dimensionValue) => {
          dimensionsValues.push({
            dimensionId: dimensionType.id,
            type: dimensionType.title,
            dimensionValueId: dimensionValue.id,
            name: dimensionValue.title,
          });
        });
      });
    }
    return dimensionsValues;
  }, [allDimensionTypesQuery.data]);

  const allDimensionTypes: DimensionType[] = useMemo(() => {
    return allDimensionTypesQuery.data || [];
  }, [allDimensionTypesQuery.data]);

  return {
    isDimensionTypesLoading: allDimensionTypesQuery.isLoading,
    allDimensionValues,
    allDimensionTypes,
    removeDimensionType,
    updateDimensionType,
    createDimensionType,
  };
}
