import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "@synerise/ds-layout";
import Loader from "@synerise/ds-loader";
import PageHeader from "@synerise/ds-page-header";

import { useAccountsByChartID } from "@/hooks/useAccountsByChartID";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import { AccountsTable } from "../Accounts/AccountsTable";
import { AccountsHeaderSummary } from "./AccountsHeaderSummary";
import { mapAccountsResponseToTable } from "./utils/mapAccountsResponseToTable";

type ChartOfAccountsDetailParams = {
  id: string;
};

export function Accounts() {
  const navigate = useNavigate();
  const { id } = useParams<ChartOfAccountsDetailParams>();
  const chartOfAccountsId = Number(id);
  const { chartOfAccounts, chartOfAccountsLoading } = useAccountsByChartID(chartOfAccountsId);

  const accounts = chartOfAccounts && chartOfAccounts.accounts ? chartOfAccounts.accounts : [];

  const accountsMappedToTable = useMemo(() => {
    return mapAccountsResponseToTable(accounts, chartOfAccountsId);
  }, [accounts]);

  return (
    <Layout
      header={<PageHeader onGoBack={() => navigate(-1)} title={chartOfAccounts?.title} rightSide />}
    >
      <ModuleStyled.CardsWrapper>
        <AccountsHeaderSummary
          isDefault={chartOfAccounts?.is_default_tenant || false}
          accountsCount={accounts.length}
          accountsType={chartOfAccounts?.chart_type ?? "-"}
        />

        {chartOfAccountsLoading ? (
          <Loader label="Loading accounts. Just a second" labelPosition="bottom" />
        ) : (
          <AccountsTable data={accountsMappedToTable} chartId={Number(id)} />
        )}
      </ModuleStyled.CardsWrapper>
    </Layout>
  );
}
