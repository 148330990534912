import styled from "styled-components";

export const Container = styled.div<{ isSelected: boolean }>`
  padding: 12px 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: ${({ isSelected, theme }) =>
    isSelected ? `2px solid ${theme.palette["blue-600"]}` : "2px solid transparent"};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
