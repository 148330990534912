import { SidebarProps } from "@synerise/ds-layout";

import { RightDrawer } from "./RightDrawer";

export function rightDrawerConfig(): SidebarProps {
  return {
    content: <RightDrawer />,
    opened: true,
    onChange: () => {},
    width: 290,
  };
}
