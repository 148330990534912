import { Link } from "react-router-dom";

import AppMenu from "@synerise/ds-app-menu";
import { AiSearchColorM, AiSearchGreyM } from "@synerise/ds-icon";

export function FinancialsMenu() {
  return (
    <AppMenu.Item id="financials" name="Financials" className="app-menu-item">
      <Link to="/financials">
        <AppMenu.Item.Icon active={<AiSearchColorM />} inActive={<AiSearchGreyM />} />
      </Link>
    </AppMenu.Item>
  );
}
