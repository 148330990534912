import { AccountsTableData } from "../accounts-table.types";
import { AccountResponse } from "../accounts.types";

export function mapAccountsToFlatData(
  response: AccountResponse[],
  chartId: number
): AccountsTableData[] {
  return response.map((account) => ({
    id: account.id,
    code: account.ref,
    level: account.level,
    classification: account.code,
    description: account.description || "No Description",
    isActive: account.is_active ?? false,
    name: account.name,
    chartId: chartId,
    parentId: account.parent_id,
  }));
}

export function createTableDataTree(flatData: AccountsTableData[]): AccountsTableData[] {
  const accountHashTable = Object.create(null);
  flatData.forEach((account) => (accountHashTable[account.id] = { ...account, subRows: [] }));
  let accountsTableDataTree: AccountsTableData[] = [];
  flatData.forEach((account) => {
    if (account.parentId) {
      accountHashTable[account.parentId].subRows.push(accountHashTable[account.id]);
    } else {
      accountsTableDataTree.push(accountHashTable[account.id]);
    }
  });
  return accountsTableDataTree;
}

export function mapAccountsResponseToTable(
  response: AccountResponse[],
  chartId: number
): AccountsTableData[] {
  const flatData = mapAccountsToFlatData(response, chartId);
  const tableDataTree = createTableDataTree(flatData);
  return tableDataTree;
}
