import { LOCALES } from "../locales";

const enUS = {
  [LOCALES.ENGLISH]: {
    login: {
      email: "E-mail",
      password: "Password",
      forgotPassword: "Forgot Password",
      signIn: "SignIn",
      validation: {
        emailRequired: "E-mail is required",
        emailInvalid: "E-mail is invalid",
        passwordRequired: "Password is required",
      },
    },
  },
};

export default enUS;
