import { Link } from "react-router-dom";

import Button from "@synerise/ds-button";
import Icon, { TrashM, EditM, TagM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as TableStyled from "@/components/Table/styles";

import { dataManagementData } from "../data-management.data";

export type DimensionTypesTableData = {
  id: number;
  name: string;
  description: string;
};

type CellProps<T> = {
  row: {
    original: T;
  };
};

type TableColumnsProps = {
  onRemoveDimensionType: (id: number, name: string) => Promise<void>;
  onEditDimensionType: (id: number) => void;
};

export const tableColumns = ({ onRemoveDimensionType, onEditDimensionType }: TableColumnsProps) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: true,
      canSort: true,
      Cell: ({ row }: CellProps<DimensionTypesTableData>) => (
        <Link to={`${dataManagementData.dimensionTypes.path}/${row.original.id}`}>
          <UserTypeCell type={row.original.name} />
        </Link>
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      disableFilters: true,
      canSort: true,
      Cell: ({ row }: CellProps<DimensionTypesTableData>) => (
        <UserTypeCell type={row.original.description} />
      ),
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }: CellProps<DimensionTypesTableData>) => (
        <TableStyled.RowCellActionButtons>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => {
              console.log("Adding Tags");
            }}
          >
            <Icon color="#C4C4C4" component={<TagM />} />
          </Button>
          <Button
            mode="single-icon"
            type="ghost-white"
            onClick={() => {
              onEditDimensionType(row.original.id);
            }}
          >
            <Icon color="#C4C4C4" component={<EditM />} />
          </Button>
          <Popconfirm
            title={`Are you sure you want to delete ${row.original.name}?`}
            onConfirm={() => onRemoveDimensionType(row.original.id, row.original.name)}
            cancelText="No"
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button mode="single-icon" type="ghost-white">
              <Icon color="#C4C4C4" component={<TrashM />} />
            </Button>
          </Popconfirm>
        </TableStyled.RowCellActionButtons>
      ),
    },
  ];
};
