import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

import { reviewsData } from "./reviews.config";
import { ReviewsListTable } from "./ReviewsListTable";
import { ReviewsSidebarMenu } from "./ReviewsSidebarMenu";

export function ReviewsList() {
  const navigate = useNavigate();

  return (
    <Layout
      left={{
        content: <ReviewsSidebarMenu />,
        opened: true,
        onChange: () => {},
        width: 259,
      }}
      header={
        <PageHeader
          onGoBack={() => navigate(-1)}
          title="Review List"
          rightSide={
            <Button type="primary" onClick={() => navigate(reviewsData.list.createPath)}>
              New Review
            </Button>
          }
        />
      }
    >
      <ReviewsListTable />
    </Layout>
  );
}
