import React from "react";

import Button from "@synerise/ds-button";
import Result from "@synerise/ds-result";

function GoalSeek() {
  return (
    <>
      <div style={{ margin: "auto" }}>
        <Result
          buttons={
            <>
              <Button onClick={function noRefCheck() {}} type="primary">
                Let me know
              </Button>
            </>
          }
          // customIcon={null}
          description="This module is under construction. Interested on using it? Let us know!"
          title="Goal Seek module under development…"
          type="progress"
        />
      </div>
    </>
  );
}

export default GoalSeek;
