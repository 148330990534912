import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";
import Result from "@synerise/ds-result";

import { dataManagementData } from "../data-management/data-management.data";
import { leftLayoutContent } from "../data-management/DataManagementLeftLayoutContent";

export function ERP() {
  const navigate = useNavigate();
  return (
    <Layout
      left={leftLayoutContent}
      header={<PageHeader onGoBack={() => navigate(dataManagementData.home.path)} title="ERP" />}
    >
      <Card>
        <Result
          buttons={
            <>
              <Button onClick={() => {}} type="primary">
                Let me know
              </Button>
            </>
          }
          title="The ERP module is under development…"
          description="Interested on using it? Let us know!"
          type="progress"
        />
      </Card>
    </Layout>
  );
}
