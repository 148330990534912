import { useLocation } from "react-router-dom";

import DSAppMenu from "@synerise/ds-app-menu";

import { DashboardMenu } from "@/modules/dashboard/DashboardMenu";
import { DataManagementMenu } from "@/modules/data-management/DataManagementMenu";
import { FinancialsMenu } from "@/modules/financials/FinancialsMenu";
import { ForecastMenu } from "@/modules/forecast/pages/ForecastMenu";
import { GovernanceMenu } from "@/modules/governance/GovernanceMenu";
import { KPIMenu } from "@/modules/kpis/kpis.menu";
import { ReportMenu } from "@/modules/reports/ReportsMenu";
import { SettingsMenu } from "@/modules/settings/settings.menu";

import "./app-menu.styles.css";
import "./app-menu-item.styles.css";

export function AppMenu() {
  const { pathname } = useLocation();
  return (
    <DSAppMenu
      className="app-menu"
      activeItem={pathname === "/" ? "dashboards" : pathname.split("/")[1]}
      top={56}
      footer={
        <div style={{ marginBottom: "8px" }}>
          <DataManagementMenu />
          <SettingsMenu />
        </div>
      }
    >
      <DashboardMenu />
      <FinancialsMenu />
      <KPIMenu />
      {/* <AnalyticsMenu /> */}
      <ForecastMenu />
      <ReportMenu />
      <GovernanceMenu />
      {/* <PortalMenu /> */}
    </DSAppMenu>
  );
}
