import { useQuery } from "react-query";

import { CompaniesService } from "@/modules/data-management/companies/companies.service";
import { Company } from "@/modules/data-management/companies/companies.types";

const COMPANIES_QUERY_KEY = "companies";

async function getAllCompanies() {
  return await CompaniesService.index();
}

type UseCompaniesReturn = {
  allCompanies: Company[];
};

export function useCompanies(): UseCompaniesReturn {
  const allCompanies = useQuery(COMPANIES_QUERY_KEY, getAllCompanies);

  return {
    allCompanies: allCompanies.data || [],
  };
}
