import { useLocation, useNavigate } from "react-router-dom";

import Divider from "@synerise/ds-divider";
import DSMenu from "@synerise/ds-menu";

import kpiMenu from "../kpi-menu-options";

export function InnerMenuContent() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <DSMenu
      style={{
        padding: "4px 10px",
      }}
    >
      {kpiMenu.groups.map((group) => (
        <>
          <DSMenu.ItemGroup key={group.title} title={group.title}>
            {group.items.map((route) => (
              <DSMenu.Item
                key={route.link}
                onClick={() => navigate(route.link)}
                text={route.title}
                style={{ color: pathname.includes(route.link) ? "#1890ff" : "#384356" }}
              />
            ))}
          </DSMenu.ItemGroup>

          <Divider style={{ padding: "5px 10px", margin: "10px 0px 5px 0px" }} dashed={false} />
        </>
      ))}
    </DSMenu>
  );
}
