import { useNavigate } from "react-router-dom";

import Layout from "@synerise/ds-layout";
import PageHeader from "@synerise/ds-page-header";

import { leftDrawerConfig } from "../LeftDrawerConfig";
import { MeetingAttachments } from "./components/MeetingAttachments";
import { MeetingDescription } from "./components/MeetingDescription";
import { MeetingMatters } from "./components/MeetingMatters";
import { MeetingMembers } from "./components/MeetingMembers";
import { MeetingSection } from "./components/MeetingSection";
import { MeetingTimeline } from "./components/MeetingTimeline";
import * as Styled from "./styles";

export function Meeting() {
  const navigate = useNavigate();

  return (
    <Layout
      left={leftDrawerConfig()}
      header={<PageHeader onGoBack={() => navigate("/governance/meetings")} title="Meeting " />}
    >
      <Styled.Container>
        <Styled.Column>
          <MeetingSection title="Description">
            <MeetingDescription />
          </MeetingSection>
          <MeetingSection title="Timeline">
            <MeetingTimeline />
          </MeetingSection>
        </Styled.Column>
        <Styled.Column>
          <MeetingSection title="Members">
            <MeetingMembers />
          </MeetingSection>
          <MeetingSection title="Votes &amp; Matters">
            <MeetingMatters />
          </MeetingSection>
          <MeetingSection title="Files &amp; Attachments">
            <MeetingAttachments />
          </MeetingSection>
        </Styled.Column>
      </Styled.Container>
    </Layout>
  );
}
