import Button from "@synerise/ds-button";
import Icon, { TrashM, EditM } from "@synerise/ds-icon";
import Popconfirm from "@synerise/ds-popconfirm";

import { UserTypeCell } from "@/components/Table/Cells/UserTypeCell";
import * as TableStyled from "@/components/Table/styles";

export type DimensionsTableData = {
  dimensionId: number;
  dimensionValueId: number;
  name: string;
  type: string;
};

type CellProps<T> = {
  row: {
    original: T;
  };
};

type TableColumnProps = {
  onRemoveDimension: (dimensionId: number, dimensionValueId: number, name: string) => Promise<void>;
  onEditDimension: (dimensionId: number, dimensionValueId: number) => void;
};

export const tableColumns = ({ onRemoveDimension, onEditDimension }: TableColumnProps) => [
  {
    Header: "Name",
    accessor: "name",
    disableFilters: true,
    canSort: true,
    Cell: ({ row }: CellProps<DimensionsTableData>) => <UserTypeCell type={row.original.name} />,
  },
  {
    Header: "Type",
    accessor: "type",
    disableFilters: true,
    canSort: true,
    Cell: ({ row }: CellProps<DimensionsTableData>) => <UserTypeCell type={row.original.type} />,
  },
  {
    Header: "",
    accessor: "actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }: CellProps<DimensionsTableData>) => (
      <TableStyled.RowCellActionButtons>
        <Button
          mode="single-icon"
          type="ghost-white"
          onClick={() => onEditDimension(row.original.dimensionId, row.original.dimensionValueId)}
        >
          <Icon color="#C4C4C4" component={<EditM />} />
        </Button>
        <Popconfirm
          title={`Are you sure you want to delete ${row.original.name}?`}
          onConfirm={() =>
            onRemoveDimension(
              row.original.dimensionId,
              row.original.dimensionValueId,
              row.original.name
            )
          }
          cancelText="No"
          okText="Yes"
          okButtonProps={{
            danger: true,
          }}
        >
          <Button mode="single-icon" type="ghost-white">
            <Icon color="#C4C4C4" component={<TrashM />} />
          </Button>
        </Popconfirm>
      </TableStyled.RowCellActionButtons>
    ),
  },
];
