import { useRef, useState } from "react";

import Button from "@synerise/ds-button";
import DSDropdown from "@synerise/ds-dropdown";
import Icon, { OptionVerticalM, TrashS } from "@synerise/ds-icon";
import DSMenu from "@synerise/ds-menu";

import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { useRemoveBoard } from "@/modules/governance/hooks/useRemoveBoard";

type BoardCardActionsProps = {
  boardId: number;
};

export function BoardCardActions({ boardId }: BoardCardActionsProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const { removeBoard } = useRemoveBoard();
  const ref = useRef<HTMLDivElement>(null);

  function handleCloseDropdown() {
    setShowDropdown(false);
  }

  function handleOpenDropdown() {
    setShowDropdown(true);
  }

  useOnClickOutside(ref, () => {
    setShowDropdown(false);
  });

  return (
    <DSDropdown
      overlayStyle={{ borderRadius: "3px" }}
      visible={showDropdown}
      placement="bottomLeft"
      overlay={
        <DSDropdown.Wrapper ref={ref} style={{ display: "relative" }}>
          <DSMenu asDropdownMenu={true} style={{ padding: "8px 2px 8px 8px" }}>
            <DSMenu.Item
              type="danger"
              prefixel={<Icon component={<TrashS />} />}
              onClick={(event) => {
                event.domEvent.stopPropagation();
                handleCloseDropdown();
                removeBoard(boardId);
              }}
            >
              Remove Board
            </DSMenu.Item>
          </DSMenu>
        </DSDropdown.Wrapper>
      }
    >
      <Button
        mode="single-icon"
        type="ghost"
        onClick={(event) => {
          event.stopPropagation();
          handleOpenDropdown();
        }}
      >
        <Icon component={<OptionVerticalM />} />
      </Button>
    </DSDropdown>
  );
}
