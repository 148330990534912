import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { EditM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";

import { useDimensions } from "@/hooks/useDimensions";
import { useValidationSchema } from "@/hooks/useValidationSchema";
import * as ModuleStyled from "@/layout/Modules/modules.styles";

import * as Styled from "./dimensions.styles";

type EditDimensionParams = {
  typeid: string;
  id: string;
};

type EditDimensionFormValues = {
  title: string;
  description: string;
};

const editDimensionSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string(),
});

export function DimensionsFormEdit() {
  const validate = useValidationSchema(editDimensionSchema);
  const { getDimensionById, updateDimension } = useDimensions();
  const navigate = useNavigate();
  const { id, typeid } = useParams<EditDimensionParams>();
  const dimension = getDimensionById(Number(typeid), Number(id));

  async function onSubmit(values: EditDimensionFormValues) {
    function onSuccess() {
      message.success("Dimension value has been updated");
      navigate(-1);
    }

    function onError(text: string) {
      message.error(text);
    }

    await updateDimension.mutateAsync({
      dimensionId: Number(typeid),
      dimensionValueId: Number(id),
      payload: values,
      onSuccess,
      onError,
    });
  }

  const initialValues: EditDimensionFormValues = {
    title: dimension?.title || "",
    description: dimension?.description || "",
  };

  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate(-1)}
          title={`Editing dimension value: ${dimension?.title}`}
        />
      }
    >
      <ModuleStyled.MaxWidthContent>
        <ModuleStyled.CardsWrapper>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Card
                    background="white-shadow"
                    lively
                    title="Basic Information"
                    withHeader
                    icon={<EditM />}
                    iconColor={"#AEAEAE"}
                    headerSideChildren={
                      <Styled.CardHeaderSideChildrenContainer>
                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                        <Button
                          onClick={handleSubmit}
                          type="primary"
                          loading={updateDimension.isLoading}
                          disabled={updateDimension.isLoading}
                        >
                          Save
                        </Button>
                      </Styled.CardHeaderSideChildrenContainer>
                    }
                  >
                    <ModuleStyled.InputWrapper>
                      <Field
                        name="title"
                        render={({ input, meta }) => (
                          <Input {...input} label="Title" errorText={meta.touched && meta.error} />
                        )}
                      />
                      <Field
                        name="description"
                        render={({ input, meta }) => (
                          <Input
                            {...input}
                            label="Description"
                            errorText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </ModuleStyled.InputWrapper>
                  </Card>
                </form>
              );
            }}
          />
        </ModuleStyled.CardsWrapper>
      </ModuleStyled.MaxWidthContent>
    </Layout>
  );
}
