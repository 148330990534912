import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";

import Button from "@synerise/ds-button";
import Card from "@synerise/ds-card";
import { EditM } from "@synerise/ds-icon";
import { Input } from "@synerise/ds-input";
import Layout from "@synerise/ds-layout";
import message from "@synerise/ds-message";
import PageHeader from "@synerise/ds-page-header";
import DSSelect from "@synerise/ds-select";
import Switch from "@synerise/ds-switch";

import { useChartOfAccounts } from "@/hooks/useChartOfAccounts";
import { useEntities } from "@/hooks/useEntities";
import { useSupportInfos } from "@/hooks/useSupportInfos";
import * as ModuleStyled from "@/layout/Modules/modules.styles";
import { formatHexSymbolsToUnicode } from "@/utils/formatHexSymbolsToUnicode";

import { dataManagementData } from "../../data-management.data";
import * as Styled from "./chart.styles";
import { ChartType } from "./chart.types";
import { Select } from "./components/Select";

type CreateChartOfAccountsFormValues = {
  title: string;
  description: string;
  chart_type: ChartType;
  currency_code: string;
  language_code: string;
  entity_id: number;
  is_complete: boolean;
  is_default_entity: boolean;
  is_default_tenant: boolean;
  is_tenant: boolean;
  is_mapped: boolean;
};

const formId = "create-chart-of-accounts";

export function CreateChartOfAccounts() {
  const [basicContentCollapse, setBasicContentCollapse] = useState(false);
  const [detailsContentCollapse, setDetailsContentCollapse] = useState(true);
  const { chartOfAccountsTypes, createChartOfAccounts } = useChartOfAccounts();
  const { currencies, languages } = useSupportInfos();
  const { allEntities } = useEntities();

  const onSubmit = async (values: CreateChartOfAccountsFormValues) => {
    const onSuccess = () => {
      message.success("Chart of accounts has been created successfully.");
      navigate(dataManagementData.chartOfAccounts.path);
    };

    const onError = (text: string) => {
      message.error(text);
    };

    await createChartOfAccounts({
      payload: values,
      onSuccess,
      onError,
    });
  };

  const initialFormValues = {
    is_complete: false,
    is_consolidation: false,
    is_default_entity: false,
    is_default_tenant: false,
    is_tenant: false,
    is_mapped: false,
  };

  const navigate = useNavigate();
  return (
    <Layout
      header={
        <PageHeader
          onGoBack={() => navigate(-1)}
          title="Create new chart of account"
          rightSide={
            <Styled.CardHeaderSideChildrenContainer>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button type="primary" htmlType="submit" form={formId}>
                Create
              </Button>
            </Styled.CardHeaderSideChildrenContainer>
          }
        />
      }
    >
      <Form onSubmit={onSubmit} initialValues={initialFormValues}>
        {({ handleSubmit }) => (
          <form id={formId} onSubmit={handleSubmit}>
            <ModuleStyled.MaxWidthContent>
              <ModuleStyled.CardsWrapper>
                <Card
                  background="white-shadow"
                  lively
                  hideContent={basicContentCollapse}
                  onHeaderClick={() => setBasicContentCollapse((prevState) => !prevState)}
                  title="Basic Information"
                  withHeader
                  icon={<EditM />}
                  iconColor={"#AEAEAE"}
                >
                  <ModuleStyled.InputWrapper
                    style={{
                      gap: "8px",
                    }}
                  >
                    <Field name="title">
                      {({ input }) => (
                        <Input {...input} label="Title" style={{ marginBottom: "0 !important" }} />
                      )}
                    </Field>
                    <div>
                      <Field name="chart_type">
                        {({ input }) => (
                          <DSSelect {...input} label="Type">
                            {Object.entries(chartOfAccountsTypes).map(([key, value]) => (
                              <DSSelect.Option key={key} value={key}>
                                {value}
                              </DSSelect.Option>
                            ))}
                          </DSSelect>
                        )}
                      </Field>
                    </div>

                    <div>
                      <Field name="entity_id">
                        {({ input }) => (
                          <Select
                            items={allEntities.map((entity) => ({
                              id: entity.id,
                              value: entity.id,
                              name: entity.name,
                            }))}
                            {...input}
                          />
                        )}
                      </Field>
                    </div>

                    <div>
                      <Field name="language_code">
                        {({ input }) => (
                          <DSSelect
                            {...input}
                            showSearch
                            label="Language"
                            placeholder="Language"
                            optionFilterProp="children"
                          >
                            {languages.map((language) => (
                              <DSSelect.Option key={language.code} value={language.code}>
                                {language.lang_en}
                              </DSSelect.Option>
                            ))}
                          </DSSelect>
                        )}
                      </Field>
                    </div>
                  </ModuleStyled.InputWrapper>
                </Card>

                <Card
                  background="white-shadow"
                  lively
                  hideContent={detailsContentCollapse}
                  onHeaderClick={() => setDetailsContentCollapse((prevState) => !prevState)}
                  title="Details"
                  withHeader
                  icon={<EditM />}
                  iconColor={"#AEAEAE"}
                >
                  <ModuleStyled.InputWrapper>
                    <Field name="description">
                      {({ input }) => <Input {...input} label="Description" />}
                    </Field>

                    <Field
                      name="currency_code"
                      render={({ input }) => (
                        <DSSelect
                          {...input}
                          showSearch
                          optionFilterProp="children"
                          label="Currency"
                          description="Currency"
                          placeholder="Currency"
                          options={currencies.map((currency) => ({
                            value: currency.code,
                            name: currency.name,
                            label: (
                              <p>
                                {currency.name} (
                                {currency &&
                                  currency.symbol_hex &&
                                  formatHexSymbolsToUnicode(currency.symbol_hex)}
                                )
                              </p>
                            ),
                          }))}
                          filterOption={(inputValue, option) => {
                            return (
                              option!.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                            );
                          }}
                        />
                      )}
                    />

                    <ModuleStyled.SwitchWrapper>
                      <Field name="is_complete">
                        {({ input }) => (
                          <Switch
                            {...input}
                            label="Complete Chart"
                            description="Mark if this chart includes Balance Sheet accounts."
                          />
                        )}
                      </Field>
                      <Field name="is_consolidation">
                        {({ input }) => (
                          <Switch
                            {...input}
                            label="Consolidation Chart"
                            description="If this chart will be used for consolidation"
                          />
                        )}
                      </Field>
                      <Field name="is_default_tenant">
                        {({ input }) => (
                          <Switch
                            {...input}
                            label="Default for Organization"
                            description="Mark to make this chart the default for the Organization"
                          />
                        )}
                      </Field>
                      <Field name="is_default_entity">
                        {({ input }) => (
                          <Switch
                            {...input}
                            label="Default for Entity"
                            description="Make default for an entity"
                          />
                        )}
                      </Field>
                      <Field name="is_mapped">
                        {({ input }) => <Switch {...input} label="Mapped Chart" />}
                      </Field>
                      <Field name="is_tenant">
                        {({ input }) => <Switch {...input} label="Tenant Chart" />}
                      </Field>
                    </ModuleStyled.SwitchWrapper>
                  </ModuleStyled.InputWrapper>
                </Card>
              </ModuleStyled.CardsWrapper>
            </ModuleStyled.MaxWidthContent>
          </form>
        )}
      </Form>
    </Layout>
  );
}
