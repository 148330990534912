import { useMemo } from "react";
import { useQuery } from "react-query";

import { Tenant } from "@/core/tenants/tenants.types";
import UsersService from "@/core/users/users.services";

type UseUserReturn = {
  tenants: Tenant[];
  tenantsLoading: boolean;
};

const USER_QUERY_KEY = "user";

async function getTenantsFromUser(userID: number) {
  return await UsersService.getTenantsFromUser(userID);
}

export function useUser(userID?: number): UseUserReturn {
  const tenantsFromQuery = useQuery(
    [USER_QUERY_KEY, userID],
    async () => {
      if (userID) {
        return await getTenantsFromUser(userID);
      }
    },
    {
      enabled: !!userID,
    }
  );

  const tenants = useMemo(() => {
    return tenantsFromQuery?.data ?? [];
  }, [tenantsFromQuery.data]);

  const tenantsLoading = useMemo(() => {
    return tenantsFromQuery.isLoading;
  }, [tenantsFromQuery.isLoading]);

  return {
    tenants,
    tenantsLoading,
  };
}
