import { useNavigate } from "react-router-dom";

import AppMenu from "@synerise/ds-app-menu";
import { RetailColorM, RetailGreyM } from "@synerise/ds-icon";
import "./reports.styles.css";

export function ReportMenu() {
  const navigate = useNavigate();

  return (
    <div
      className="reports-app-menu"
      onClick={() => navigate("/reports/list")}
      style={{ cursor: "pointer" }}
    >
      <AppMenu.Item id="reports" name="Reports" className="app-menu-item">
        <AppMenu.Item.Icon active={<RetailColorM />} inActive={<RetailGreyM />} />
      </AppMenu.Item>
    </div>
  );
}
