import { KpiValueShortResponse } from "../../kpi-value.types";
import { DataSource, KpiResponse } from "../../kpi.types";
import { ColumnValueType, KPITabsTableData, KPITabsValueTableData } from "../kpi-tabs.types";

export function mapKPIValuesToTableData(
  values: KpiValueShortResponse[],
  valueType: ColumnValueType,
  kpiId: number
): KPITabsValueTableData[] {
  return values.map((value) => ({
    id: value.id ?? Math.floor(Math.random() * 100000),
    value: value.value ?? null,
    type: valueType,
    datetime: value.date ?? "",
    kpi_id: kpiId,
    hasComments: value.has_comments,
  }));
}

function mapKPIToTableData(kpi: KpiResponse, dataSourcesSelected: DataSource[]): KPITabsTableData {
  const selectedKpiSeries =
    kpi.series && kpi.series.length > 0
      ? kpi.series.filter((serie) => dataSourcesSelected.includes(serie.data_source))
      : null;

  const kpiValues = selectedKpiSeries?.reduce((acc, serie) => {
    return [
      ...acc,
      ...(serie.values ? mapKPIValuesToTableData(serie.values, serie.data_source, kpi.id) : []),
    ];
  }, [] as KPITabsValueTableData[]);

  return {
    id: kpi.id,
    description: kpi.description ?? "",
    importance: kpi.importance ?? 1,
    title: kpi.title,
    periodicity: kpi.periodicity ?? "monthly",
    values: kpiValues ?? [],
  };
}

export function mapKPISToTableData(
  kpis: KpiResponse[],
  dataSourcesSelected: DataSource[]
): KPITabsTableData[] {
  return kpis.map((kpi) => mapKPIToTableData(kpi, dataSourcesSelected));
}

export function addDataVariationColumnsToTableData(
  kpiTableData: KPITabsTableData[]
): KPITabsTableData[] {
  const kpiTableDataWithDataVariation = kpiTableData.map((kpi) => {
    const actualValues = kpi.values.filter((value) => value.type === "actual");
    const budgetValues = kpi.values.filter((value) => value.type === "budget");

    let budgetVariations: KPITabsValueTableData[] = [];

    actualValues.forEach((actualValue) => {
      const budgetValue = budgetValues.find(
        (budgetValue) => budgetValue.datetime === actualValue.datetime
      );

      budgetVariations.push({
        id: actualValue.id,
        value:
          budgetValue && actualValue.value && budgetValue.value
            ? Number((actualValue.value - budgetValue?.value).toFixed(2))
            : null,
        type: "budget-variation",
        datetime: actualValue.datetime,
        kpi_id: actualValue.kpi_id,
        hasComments: false,
      });
    });

    return {
      ...kpi,
      values: [...kpi.values, ...budgetVariations],
    };
  });

  return kpiTableDataWithDataVariation;
}
