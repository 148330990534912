import Typography from "@synerise/ds-typography";

import * as Styled from "./Title.styles";

const { Text } = Typography;

type TitleProps = {
  isSelected: boolean;
  title: string;
  fontSize: number;
};
export function Title({ title, fontSize, isSelected }: TitleProps) {
  return (
    <Styled.Container isSelected={isSelected} id="title-grid-item">
      <Text style={{ margin: 0, padding: 0, fontWeight: "bold", fontSize: `${fontSize}px` }}>
        {title}
      </Text>
    </Styled.Container>
  );
}
