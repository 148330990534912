import * as Styled from "./Image.styles";

type ImageProps = {
  url: string | null;
  isSelected: boolean;
};
export function Image({ url, isSelected }: ImageProps) {
  return (
    <Styled.Container isSelected={isSelected}>{url && <Styled.Image src={url} />}</Styled.Container>
  );
}
